import { Article } from '../../articles/classes/Article.class';
import { InfoBlock } from '../../../components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from '../../../components/info-block/info-block.component';
import { InfoBlockField } from '../../../components/info-block/classes/InfoBlockField.class';
import { Order } from './Order.class';
import { Page } from '../../printing/classes/Page.class';
import { PrintableElement } from '../../printing/classes/PrintableElement.class';
import { PrintingModel } from '../../printing/classes/PrintingModel.class';
import { Sale } from '../../sales/classes/Sale.class';
import { StockMove } from '../../stocks/classes/StockMove.class';
import { config } from '../../../classes/config';

// import { Price } from "../../sales/classes/Price.class";

export class StickersPrintingModel extends PrintingModel {
  public optionsBlock: InfoBlock = {
    fields: [
      {
        title: 'Langue',
        field: 'lang',
        type: 'select',
        selectOptions: [
          { text: '(Selon client)', value: '' },
          { text: 'Français', value: 'fr' },
          { text: 'Anglais', value: 'en' },
          { text: 'Néerlandais', value: 'nl' },
        ],
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
          this.regenerate();
        },
      },
    ],
  };

  public obj: Order | StockMove | Sale = null;
  public lang: string = '';
  public stickerCell: PrintableElement = null;
  public stickersRow: PrintableElement = null;
  public stickersTable: PrintableElement = null;
  // public delivery_address: string = null;
  // public delivery_address: string = null;
  // public invoiceing_address: string = null;
  // public send_address: string = null;
  //public hide_customer: boolean = false;

  public static generate(obj: Order | StockMove | Sale): PrintingModel {
    //if (order instanceof StockMove) order = order.order;
    let model: StickersPrintingModel = new StickersPrintingModel();
    model.obj = obj;
    return model.regenerate();
  }

  public formatAddress(address: string, firstLine: boolean = true, vat_number: string = null, lang: string = 'fr') {
    let lines: string[] = address.split('\n');
    while (lines.length < 5) lines.push('');
    lines.push(
      vat_number
        ? {
            fr: 'N° de T.V.A. : ',
            nl: 'B.T.W. Nr : ',
            en: 'VAT No : ',
          }[lang] + vat_number
        : ''
    );
    if (firstLine == true) return lines[0];
    else if (firstLine == false) return lines.slice(1).join('\n');
    else if (firstLine == null) return lines.join('\n');
  }

  public regenerate(): PrintingModel {
    let obj: Order | StockMove | Sale = this.obj;
    let order: Order = null;
    let sale: Sale = null;
    if (obj instanceof Order) {
      order = obj as Order;
      sale = order.sale;
    } else if (obj instanceof StockMove) {
      order = (obj as StockMove).order;
      sale = order.sale;
    } else if (obj instanceof Sale) {
      order = null;
      sale = obj as Sale;
    }

    let pageWidth = 210;
    let pageHeight = 295;

    let pageLeftMargin = 7;
    let pageRightMargin = 7;
    let pageTopMargin = 12;
    let pageBottomMargin = 10;

    let quantities: string[] = [];
    let prices: string[] = [];
    let totals: string[] = [];

    // if (!this.delivery_address && order.delivery_address) this.delivery_address = order.delivery_address;
    // if (!this.invoiceing_address && order.invoiceing_address) this.invoiceing_address = order.invoiceing_address;
    // if (!this.send_address && order.send_address) this.send_address = order.send_address;

    this.pages = [];

    let lang: string = this.lang;
    if (!lang || lang == '') {
      if (this.obj.customer.lang) lang = this.obj.customer.lang.code;
      else lang = 'fr';
    }

    this.saveFilename =
      'Etiquettes ' + (obj instanceof StockMove ? obj.fullNumber : obj.number).replace(/\//g, '-') + '.pdf';
    this.mailFilename =
      {
        fr: `Etiquettes ${config.companyName}`,
        nl: `${config.companyName} Verzendingslabels`,
        en: `${config.companyName} labels`,
      }[lang] + '.pdf';

    this.stickerCell = {
      style: {
        display: 'table-cell', // bloc général
        width: '93mm',
        height: '93mm',
        position: 'relative',
        'z-index': '1',
      },
      children: [
        {
          style: {
            display: 'block',
            position: 'absolute',
            left: '0mm',
            top: '17mm',
            width: '15mm',
            height: '55mm',
            'background-image': 'url(assets/img/qr_code_url_' + lang + '.png)',
            'background-size': '15mm 55mm',
            'z-index': '-1',
          },
          html: '',
        },
        {
          style: {
            display: 'block',
            position: 'absolute',
            left: '-1mm',
            top: '56mm',
            width: '17mm',
            height: '17mm',
            'background-image':
              'url(' +
              config.baseUrl +
              'qrcode.php?number=' +
              (obj instanceof StockMove ? obj.order.number : obj.number).replace(/[\/\-\s]/g, '') +
              ')',
            'background-size': '17mm 17mm',
            'z-index': '-2',
          },
          html: '',
        },
        {
          style: {
            // Premier bloc
            display: 'flex',
            border: '.25mm solid rgba(0,0,0,1)',
            'margin-left': '36mm',
            padding: '0 1mm',
            width: '58mm',
          },
          children: [
            {
              style: {
                display: 'block',
                'line-height': '4mm',
                'text-align': 'center',
                'white-space': 'pre',
                'vertical-align': 'top',
              },
              html: (order
                ? {
                    fr: 'Notre\ncommande :',
                    nl: 'Onze be-\nstelling :',
                    en: 'Our\norder :',
                  }
                : {
                    fr: 'Notre\nréférence :',
                    nl: 'Onze\nreferentie :',
                    en: 'Our\nreference :',
                  })[lang],
            },
            {
              style: {
                display: 'block',
                'line-height': '8mm',
                'text-align': 'center',
                'font-weight': 'bold',
                'vertical-align': 'top',
                flex: '1',
                'white-space': 'nowrap',
                'font-size': '9pt',
              },
              html: order
                ? obj instanceof Order
                  ? obj.number
                  : (obj as StockMove).fullNumber
                : sale.numberWithVersion,
            },
          ],
        },
        {
          style: {
            // 2eme bloc
            display: 'flex',
            border: '.25mm solid rgba(0,0,0,1)',
            'margin-left': '36mm',
            padding: '0 1mm',
            width: '58mm',
            'margin-top': '1.5mm',
          },
          children: [
            {
              style: {
                display: 'block',
                'line-height': '4mm',
                'text-align': 'center',
                'white-space': 'pre',
                'vertical-align': 'top',
              },
              html: (order
                ? {
                    fr: 'Votre\ncommande :',
                    nl: 'Uw be-\nstelling :',
                    en: 'Your\norder :',
                  }
                : {
                    fr: 'Votre\nréférence :',
                    nl: 'Uw\nreferentie :',
                    en: 'Your\nreference :',
                  })[lang],
            },
            {
              style: {
                display: 'block',
                'line-height': '8mm',
                'text-align': 'center',
                'font-weight': 'bold',
                'vertical-align': 'top',
                flex: '1',
                'white-space': 'nowrap',
                'font-size': '9pt',
              },
              html: obj.customer_reference,
            },
          ],
        },
        {
          style: {
            display: 'table',
            'margin-top': '2mm',
            'line-height': '4mm',
          },
          children: [
            {
              style: {
                display: 'table-row',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    width: '35mm',
                    'white-space': 'pre',
                    'vertical-align': 'top',
                    'text-align': 'right',
                    'padding-top': '1.5mm',
                  },
                  html: {
                    fr: 'Destinataire :',
                    nl: 'Bestemmeling :',
                    en: 'Recipient :',
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    'vertical-align': 'middle',
                    'font-weight': 'bold',
                    'white-space': 'pre-wrap',
                    width: '58mm',
                    'padding-left': '2mm',
                    'padding-top': '1.5mm',
                  },
                  children: [
                    {
                      style: {
                        display: order && !order.show_name_on_stickers ? 'none' : 'block',
                        'font-size': '11pt',
                        // 'line-height': '8mm'
                      },
                      html: obj.customer.name,
                    },
                    {
                      style: {
                        display: order && !order.show_name_on_stickers ? 'none' : 'block',
                      },
                      html: obj.delivery_address_text,
                    },
                  ],
                },
              ],
            },
            {
              style: {
                display: 'table-row',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    width: '35mm',
                    'white-space': 'pre',
                    'vertical-align': 'top',
                    'text-align': 'right',
                    'padding-top': '1.5mm',
                  },
                  html: {
                    fr: 'Produit :',
                    nl: 'Product :',
                    en: 'Product :',
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    'vertical-align': 'middle',
                    'white-space': 'pre-wrap',
                    width: '58mm',
                    'padding-left': '2mm',
                    'padding-top': '1.5mm',
                  },
                  html: sale.article && sale.article.designation ? sale.article.designation : '',
                },
              ],
            },
            sale.article_reference && sale.article_reference.length
              ? {
                  style: {
                    display: 'table-row',
                  },
                  children: [
                    {
                      style: {
                        display: 'table-cell',
                        width: '35mm',
                        'white-space': 'pre',
                        'vertical-align': 'top',
                        'text-align': 'right',
                        'padding-top': '1.5mm',
                      },
                      html: {
                        fr: 'N° article :',
                        nl: 'Artikel nr. :',
                        en: 'Article no. :',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'table-cell',
                        'vertical-align': 'middle',
                        'white-space': 'pre-wrap',
                        width: '58mm',
                        'padding-left': '2mm',
                        'padding-top': '1.5mm',
                      },
                      html: sale.article_reference,
                    },
                  ],
                }
              : {},
            {
              style: {
                display: 'table-row',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    width: '35mm',
                    'white-space': 'pre',
                    'vertical-align': 'top',
                    'text-align': 'right',
                    'padding-top': '1.5mm',
                  },
                  html: {
                    fr: 'Format :',
                    nl: 'Formaat :',
                    en: 'Format :',
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    'vertical-align': 'middle',
                    'white-space': 'pre-wrap',
                    width: '58mm',
                    'padding-left': '2mm',
                    'padding-top': '1.5mm',
                  },
                  html: sale.article && sale.article.field1 ? sale.article.field1 : '', // format
                },
              ],
            },
            obj.article instanceof Article
              ? {
                  style: {
                    display: 'table-row',
                  },
                  children: [
                    {
                      style: {
                        display: 'table-cell',
                        width: '35mm',
                        'white-space': 'pre',
                        'vertical-align': 'top',
                        'text-align': 'right',
                        'padding-top': '1.5mm',
                      },
                      html: {
                        fr: 'Quantité :',
                        nl: 'Antaal :',
                        en: 'Quantity :',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'table-cell',
                        'vertical-align': 'middle',
                        'white-space': 'pre-wrap',
                        width: '58mm',
                        'padding-left': '2mm',
                        'padding-top': '1.5mm',
                      },
                      html: obj.article.packaging ? obj.article.packaging : '',
                    },
                  ],
                }
              : {},
          ],
        },
      ],
    };
    this.stickersRow = {
      style: {
        display: 'table-row',
        'margin-bottom': '10mm',
      },
      children: [
        this.stickerCell,
        {
          style: {
            display: 'table-cell',
            width: '10mm',
          },
          html: '',
        },
        this.stickerCell,
      ],
    };
    this.stickersTable = {
      style: {
        display: 'table',
        width: '100%',
      },
      children: [this.stickersRow, this.stickersRow, this.stickersRow],
    };

    // for(let i=0; i<this.order.suppliers.length; ++i)
    // {

    let page: Page = {
      width: pageWidth,
      height: pageHeight,

      leftMargin: pageLeftMargin,
      rightMargin: pageRightMargin,
      topMargin: pageTopMargin,
      bottomMargin: pageBottomMargin,

      style: {
        'font-family': 'Calibri',
        'font-size': '10pt',
        width: pageWidth - pageLeftMargin - pageRightMargin + 'mm',
        height: pageHeight - pageTopMargin - pageBottomMargin + 'mm',
        'margin-top': pageTopMargin + 'mm',
        'margin-left': pageLeftMargin + 'mm',
        'margin-right': pageRightMargin + 'mm',
        'margin-bottom': pageBottomMargin + 'mm',
      },

      headerSize: 0,
      headerElements: [],

      bodyElements: [this.stickersTable],

      footerSize: 0,
      footerElements: [],
    };

    this.pages.push(page);

    //}
    return this;
  }
}
