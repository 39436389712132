import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrashViewComponent } from './views/trash-view/trash-view.component';
import { ComponentsModule } from '../../components/components.module';
import { ModuleBase } from '../module-base.class';
import { Toolbar } from '../../components/toolbar/classes/Toolbar.class';

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule
    ],
    declarations: [
        TrashViewComponent
    ],
    exports: [
        TrashViewComponent
    ],
    entryComponents: [
        TrashViewComponent
    ]
})
export class TrashModule extends ModuleBase {
    
    public static moduleName = 'trash';
    
    public ngOnInit()
    {
        Toolbar.registerMenuItem({
            name: 'Corbeille',
            icon: 'trash',
            viewComponent: TrashViewComponent
        });
        return super.ngOnInit();
    }
    
}


