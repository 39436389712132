<div class="orders-type-selection">
    <label for="ordersType_0" class="ordersType_0" [ngClass]="{'active': ordersType == 0}">
        <input type="radio" name="ordersType" id="ordersType_0" [(ngModel)]="ordersType" [value]="0" />
        <span>Commandes commerciales</span>
    </label>
    <label for="ordersType_1" class="ordersType_1" [ngClass]="{'active': ordersType == 1}">
        <input type="radio" name="ordersType" id="ordersType_1" [(ngModel)]="ordersType" [value]="1" />
        <span>Commandes logistiques</span>
    </label>
    <label for="ordersType_2" class="ordersType_2" [ngClass]="{'active': ordersType == 2}">
        <input type="radio" name="ordersType" id="ordersType_2" [(ngModel)]="ordersType" [value]="2" />
        <span>Appels sur stock</span>
    </label>
    <label for="ordersType_3" class="ordersType_3" [ngClass]="{'active': ordersType == 3}">
        <input type="radio" name="ordersType" id="ordersType_3" [(ngModel)]="ordersType" [value]="3" />
        <span>Pour rappel</span>
    </label>
</div>
<div [hidden]="ordersType != 0">
    <data-grid #grid0 [pageSize]="20" id="orders-followup-0"
        [items]="orders[0]" [sortArray]="orders[0]" [columns]="ordersGridColumns"
        [resizable]="true" [editable]="writeAccess" [selectable]="writeAccess" [fixedColumnsCount]="2"
        (itemDblClick)="orderDblClick($event, $event.item)"
        [headerBackColor]="headersBackColor[0]" [rowStyleFunction]="gridRowStyle">
    </data-grid>
</div>
<div [hidden]="ordersType != 1">
    <data-grid #grid1 [pageSize]="20" id="orders-followup-1"
        [items]="orders[1]" [sortArray]="orders[1]" [columns]="ordersGridColumns"
        [resizable]="true" [editable]="writeAccess" [selectable]="writeAccess" [fixedColumnsCount]="2"
        (itemDblClick)="orderDblClick($event, $event.item)"
        [headerBackColor]="headersBackColor[1]" [rowStyleFunction]="gridRowStyle">
    </data-grid>
</div>
<div [hidden]="ordersType != 2">
    <data-grid #grid2 [pageSize]="20" id="orders-followup-2"
        [items]="orders[2]" [sortArray]="orders[2]" [columns]="movesGridColumns"
        [resizable]="true" [editable]="writeAccess" [selectable]="writeAccess" [fixedColumnsCount]="2"
        (itemDblClick)="orderDblClick($event, $event.item)"
        [headerBackColor]="headersBackColor[2]" [rowStyleFunction]="gridRowStyle">
    </data-grid>
</div>
<div [hidden]="ordersType != 3">
    <data-grid #grid3 [pageSize]="20" id="orders-followup-3"
        [items]="orders[3]" [sortArray]="orders[3]" [columns]="reminderGridColumns"
        [resizable]="true" [editable]="writeAccess" [selectable]="writeAccess" [fixedColumnsCount]="2"
        (itemDblClick)="orderDblClick($event, $event.item)"
        [headerBackColor]="headersBackColor[3]" [rowStyleFunction]="gridRowStyle">
    </data-grid>
</div>
