import { HttpHeaders, HttpParams } from '@angular/common/http';

import { HttpUtil } from 'src/app/modules/utils/classes/HttpUtil.class';
import { Injectable } from '@angular/core';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { config } from '../../classes/config';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor() {}

  public static sendDataCommand(command: string, data: any, message: string = null) {
    let commandName: string = command.toLowerCase().trim();
    return ApiService.callModule('api', commandName, data, message);
  }

  public static callModule(moduleName: string, funcName: string, data: any, message: string = null) {
    if (typeof data !== 'string') data = JSON.stringify(data);
    return ApiService.sendData('modules/' + moduleName + '/' + funcName + '.php', data, message);
  }

  public static sendData(url: string, data: any, message: string = null) {
    return LoadingPromise.create<any>((resolve, reject) => {
      HttpUtil.post(config.apiUrl + url, data, ApiService.getOptions()).subscribe(
        (result: any) => {
          if (result.result === 'success') resolve(result);
          else reject(result);
        },
        (err) => {
          reject(err);
        }
      );
    }, message);
  }

  public static getOptions(): {
    headers?:
      | HttpHeaders
      | {
          [header: string]: string | string[];
        };
    observe: 'body';
    params?:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  } {
    const headers: {
      [header: string]: string | string[];
    } = {};
    return { headers, observe: 'body' };
  }
}
