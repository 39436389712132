import { Component, ViewChild } from '@angular/core';

import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { ArrayUtil } from '../../../utils/classes/ArrayUtil.class';
import { DataGridComponent } from 'src/app/components/data-grid/data-grid.component';
import { DeviceInstance } from '../../classes/DeviceInstance.class';
import { DialogButton } from '../../../../components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from '../../../../components/dialogs/dialogs.component';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { RMA } from '../../classes/RMA.class';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';

@Component({
  selector: 'app-rma-list-view',
  templateUrl: './rma-list-view.component.html',
  styleUrls: ['./rma-list-view.component.css'],
})
export class RmaListViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_materiel_listerma';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Liste des RMA',
    data: this,
    elements: [
      { type: 'separator' },
      {
        type: 'button',
        name: 'saveButton',
        text: 'Enregistrer',
        icon: 'save',
        click: function (view: RmaListViewComponent) {
          view.save();
        },
        options: { visible: true },
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Ajouter<br/>un RMA',
        icon: ' fas fa-tools',
        click: function (view: RmaListViewComponent) {
          view.addRMA();
        },
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Supprimer',
        icon: 'times',
        click: function (view: RmaListViewComponent) {
          view.deleteRMA();
        },
        access: this.writeAccess,
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Archiver',
        icon: 'archive',
        click: function (view: RmaListViewComponent) {
          view.archiveRMA();
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Désarchiver',
        icon: 'archive',
        click: function (view: RmaListViewComponent) {
          view.archiveRMA(false);
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'toggle-button',
        text: 'Afficher les<br/>éléments archivés',
        icon: 'filter',
        value: false,
        click: function (view: RmaListViewComponent) {
          view.showArchived = this.value;
          view.refreshList();
          // if (view.filterOrders) view.grid.clearSelection();
        },
      },
      { type: 'spacing' },
    ],
  };

  public rmas: RMA[] = [];
  public devicesData: any = { items: [] };
  public showArchived = false;

  public rmasGridColumns = [
    {
      title: 'Appareil',
      field: 'device',
      type: 'foreign-list',
      listItems: this.devicesData,
      listField: 'serialBrandAndModel',
      multiSelect: false,
      nullValue: '(Aucun)',
      width: 200,
    },
    { title: 'Marque et modèle', field: 'device.brandAndModel', readonly: true, width: 200 },
    { title: 'Client', field: 'order.customer.name', readonly: true, width: 200 },
    { title: 'Référence client du produit', field: 'device.product_reference', readonly: true },
    { title: 'N° de série', field: 'device.serial', readonly: true },
    { title: 'Date de fabrication', field: 'device.manufacture_date', type: 'date', readonly: true, width: 90 },
    { title: 'Toujours sous garantie', field: 'is_under_warranty', type: 'checkbox', readonly: true, width: 90 },
    { title: 'Date de demande RMA', field: 'request_date', type: 'date', width: 130 },
    { title: 'N° du RMA', field: 'number' },
    { title: 'Date de dépôt chez Eutronix', field: 'deposit_date', type: 'date', width: 130 },
    { title: "Date d'expédition", field: 'shipping_date', type: 'date', width: 130 },
    { title: 'Date de livraison', field: 'delivery_date', type: 'date', width: 130 },
    { title: 'Panne / défectuosité', field: 'problem', width: 200 },
    { title: 'Fournisseur', field: 'order.supplier.name', readonly: true, width: 200 },
    { title: "Référence de l'offre / devis fournisseur", field: 'supplier_reference' },
    { title: 'Prix', field: 'price', type: 'number', unit: '€', decimalsCount: 2, width: 90 },
    { title: 'Remarques', field: 'remarks', width: 400 },
  ];

  @ViewChild('grid') grid: DataGridComponent;

  public ngOnInit() {
    super.ngOnInit();
    this.refreshList();
  }

  public async refreshList() {
    let conditions: string[] = [];
    if (!this.showArchived) conditions.push('archived != 1');
    RMA.load(null, ['~request_date'], null, false, conditions.join(' AND ') || null, true).then(
      (result) => {
        this.rmas = result;
      },
      (err) => {
        console.error(err);
      }
    );
    DeviceInstance.load().then(
      (result) => {
        this.devicesData.items = result;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  save() {
    let seq = Date.now();
    let promises: Promise<any>[] = [];
    for (let i = 0; i < this.rmas.length; ++i) {
      if (this.rmas[i].changed === true) promises.push(this.rmas[i].save2(seq));
    }
    if (promises.length > 0) {
      Promise.all(promises).then(
        (result) => {
          NotificationsComponent.push({
            title: 'Sauvegarde effectuée',
            summary: '<b>' + promises.length + ' RMA</b> ont été sauvegardés avec succès.',
            type: 'success',
          });
        },
        (err) => {
          NotificationsComponent.push({
            title: 'Erreur',
            summary: "Une erreur s'est produite lors de la sauvegarde.",
            type: 'error',
            content: err,
          });
        }
      );
    }
  }

  addRMA() {
    this.rmas.push(new RMA());
    setTimeout(() => {
      this.grid.lastPage();
    }, 100);
  }

  gridRowStyle(item: RMA, selectColumn: boolean = false) {
    if (!item) return null;
    return item.archived
      ? {
          color: 'gray',
          'font-style': 'italic',
        }
      : {};
  }

  async archiveRMA(archive = true) {
    try {
      let rmas: RMA[] = this.grid.selectedItems as RMA[];
      if (Array.isArray(rmas) && rmas.length > 0) {
        await Promise.all(
          rmas.map((rma) => {
            rma.archived = archive === false ? 0 : 1;
            return rma.save3(false);
          })
        );

        NotificationsComponent.push({
          type: 'success',
          title: 'RMA archivés',
          summary: 'Les RMA ont été ' + (archive === false ? 'dés' : '') + 'archivés avec succès',
        });
        this.grid.clearSelection();
      }
    } catch (err) {
      NotificationsComponent.push({
        type: 'error',
        title: "Erreur lors de l'archivage",
        summary: "Une erreur s'est produite lors " + (archive === false ? 'du dés' : "de l'") + 'archivage des RMA',
        content: err,
      });
      console.error(err);
    }
  }

  async deleteRMA() {
    if (
      (await DialogsComponent.display({
        icon: 'warning',
        title: 'Supprimer les RMA ?',
        message: 'Êtes-vous sûr(e) de vouloir supprimer les RMA sélectionnés ?',
        buttons: DialogButton.yesNoButtons,
      })) !== DialogButton.RESULT_YES
    )
      return;

    try {
      let rmas: RMA[] = this.grid.selectedItems as RMA[];
      if (Array.isArray(rmas) && rmas.length > 0) {
        await Promise.all(rmas.map((rma) => rma.delete()));
        NotificationsComponent.push({
          type: 'success',
          title: 'Articles supprimés',
          summary: 'Les RMA ont été supprimées avec succès',
        });
        ArrayUtil.removeElements(this.rmas, rmas);
      }
    } catch (err) {
      NotificationsComponent.push({
        type: 'error',
        title: 'Erreur lors de la suppression',
        summary: "Une erreur s'est produite lors de la suppression",
        content: err,
      });
      console.error(err);
    }
  }
}
