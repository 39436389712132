import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'data-tree-file',
  templateUrl: './data-tree-file.component.html',
  styleUrls: ['./data-tree-file.component.css']
})
export class DataTreeFileComponent implements OnInit {

    @Input() item: any = null;
    @Input() selectedObjects: any[] = [];
    @Input() level: number = 0;
    @Input() nameField: string = "name";
    
    @Output() itemClick: EventEmitter<any> = new EventEmitter<any>();

    public editing: boolean = false;

    constructor() { }

    ngOnInit() {
    }

    nameClick(event, item)
    {
        if (event.detail == 2) this.editing = true;
    }
    nameBlur(event, item)
    {
        this.editing = false;
    }
    nameKeyDown(event, item)
    {
        if (event.key == 'Enter') this.editing = false;
    }

    get image()
    {
        if (this.item.currentClass.definition.icon) return this.item.currentClass.definition.icon;
        return 'assets/img/icons/20x20/light-blue/file.png';
    }

}
