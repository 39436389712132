import { ApiService } from '../api/api.service';
import { DataCommand } from './classes/DataCommand.class';
import { ExternalService } from '../external/external.service';
import { Injectable } from '@angular/core';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { SelectCommandData } from './classes/SelectCommandData.class';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private static available: boolean = false;
  private static waiting: DataCommand[] = [];

  private static useExternal: boolean = false;
  private static useSocket: boolean = false;
  private static useApi: boolean = true;

  constructor() {}

  public static init() {
    console.log('data service init...');
    DataService.available = true;
    DataService.sendWaitingCommands();
  }

  public static createCommand(command: string, data: any) {
    if (ExternalService.available) ExternalService.createRows(data);
    if (typeof data !== 'string') data = JSON.stringify(data);
    let cmd: DataCommand = new DataCommand(command, data);
    return cmd;
  }

  public static sendCommand(command: string | DataCommand, data: any) {
    return LoadingPromise.create<any>((resolve, reject) => {
      if (!DataService.available) {
        DataService.waiting.push(
          command instanceof DataCommand ? command : new DataCommand(command, data, resolve, reject)
        );
      } else {
        if (command instanceof DataCommand) {
          data = command.data;
          command = command.command;
        }
        let data_string: string = typeof data === 'string' ? data : JSON.stringify(data);
        // if (ExternalService.available && command.toLowerCase().trim() == 'create') {
        //     ExternalService.call('executeCommand', command, data_string).then(
        //         (result: any) => {
        //             result = JSON.parse(result);
        //             if (result.result === 'success') resolve(result);
        //             else reject(result);
        //         },
        //         (err) => { reject(err); }
        //     );
        // }
        // else
        // {
        // }
        // else if (DataService.useApi) {
        ApiService.sendDataCommand(command, data).then(
          (result) => {
            resolve(result);
          },
          (err) => {
            reject(err);
          }
        );
        // }
        // else reject();
      }
    });
  }

  public static sendWaitingCommands() {
    // for (const className in ClassManager.instances) {
    //     let definition: ObjectDefinition = ClassManager.getClass(className).definition;
    //     DataService.createCommand('REGISTER_CLASS', { name: className, definition: definition }).send();
    // }
    for (let i = 0; i < DataService.waiting.length; ++i) {
      let command: DataCommand = DataService.waiting[i];
      command.send().then(
        (result) => {
          if (command.resolve) command.resolve(result);
        },
        (err) => {
          if (command.reject) command.reject(err);
        }
      );
    }
    DataService.waiting = [];
  }

  public static select(data: SelectCommandData) {
    return DataService.sendCommand('SELECT', data);
  }
}
