import { Component, OnInit } from '@angular/core';
import { DialogComponent } from 'src/app/components/dialogs/dialog/dialog.component';

@Component({
  selector: 'app-change-sale-number',
  templateUrl: './change-sale-number.component.html',
  styleUrls: ['./change-sale-number.component.css']
})
export class ChangeSaleNumberComponent extends DialogComponent {

    public newNumber: string = null;

}
