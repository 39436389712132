import { ObjectModel2 } from "../../../classes/objects/ObjectModel2.class";
import { ObjectDefinition } from "../../../classes/objects/ObjectDefinition.class";
import { DateTimeUtil } from "../../utils/classes/DateTimeUtil.class";
import { ObjectModel3 } from "src/app/classes/objects/ObjectModel3.class";
import * as moment from 'moment';


export class Deadline extends ObjectModel3
{
    public name: string = null;

    public constructor()
    {
        super(Deadline);
    }

    public static getFinalDate(deadline: string, startDate: Date)
    {
        if (!deadline) return null;

        let m = moment(startDate);
        let date: Date = startDate;
        if (deadline.indexOf('fin de mois') > 0) m = m.endOf('month');
        let days = parseInt(deadline);
        if (days > 0) m = m.add(days, 'days');
        return m.toDate();
    }

    /* ObjectModel FUNCTIONS */

    public static definition: ObjectDefinition = {
        database: {
            table: 'deadlines',
            id: 'id',
			db_id: 'db_id'
        },
        values: {
            'name': { type: 'string' }
        },
        children: {},
        links: {}
    };
}
