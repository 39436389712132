import { ObjectModel3 } from "./ObjectModel3.class";
import { ClassManager } from "./ClassManager.class";
import { ObjectDefinition } from "./ObjectDefinition.class";
import { ObjectChildDefinition } from "./ObjectChildDefinition.class";
import { ObjectLinkDefinition } from "./ObjectLinkDefinition.class";
import { LoadingPromise } from "./LoadingPromise.class";
import { ObjectValueDefinition } from "./ObjectValueDefinition.class";
import {ApiService} from "../../services/api/api.service";


export class LoadSequence
{
	public unresolved_object_models: { [className: string]: { [id: string]: ObjectModel3 } } = {};
	public object_models: { [className: string]: { [id: string]: ObjectModel3 } } = {};
	public ids_per_class: { [className: string]: string[] } = {};
	public orderBy: string[] = null;
	public conditions: string = null;
	public result: ObjectModel3[] = null;
	public forceReload: boolean = false;

	public static create(currentClass: any, ids: string[], orderBy: string[] = null, groupBy: string[] = null,
						 deleted: boolean = false, conditions: string = null, forceReload: boolean = false) {

		let ids_per_class: { [className: string]: string[] } = {};
		ids_per_class[currentClass] = ids;

		if (typeof(currentClass) === 'string') currentClass = ClassManager.getClass(currentClass);
		let definition: ObjectDefinition = currentClass.definition;
		if (definition.trashDelete === true && deleted != null)
			conditions = (conditions != null ? '(' + conditions + ') AND ' : '') + 'date_del ' + (deleted === true ? 'IS NOT' : 'IS') + ' NULL';

		let seq: LoadSequence = new LoadSequence();
		seq.ids_per_class = ids_per_class;
		seq.orderBy = orderBy;
		seq.conditions = conditions;
		seq.forceReload = true;

		return seq;
	}

	public load()
	{
		return this.runSequence(this.ids_per_class, this.orderBy, this.conditions, true);
	}

	public runSequence(ids_per_class: { [className: string]: string[] }, orderBy: string[] = null, conditions: string = null, first_sequence: boolean = false) {
		return LoadingPromise.create<any>((resolve, reject) => {
			this.loadData(ids_per_class, orderBy, conditions).then(
				(result: { [className: string]: { [id: string]: any } }) => {
					let object_models: ObjectModel3[] = this.createObjectModels(result);
					if (first_sequence) this.result = object_models;
					let missing_ids: { [className: string]: string[] } = this.resolveObjects();
					if (Object.keys(missing_ids).length > 0) this.runSequence(missing_ids).then(
							(result2) => { resolve(this.result); },
							(err2) => { console.error('error during runSequence:', err2); reject(err2); }
						);
					else {
						// console.log('loadsequence result:', this.result);
						resolve(this.result);
					}
				},
				(err) => {
					console.log('error during loadData:', err);
					reject(err);
				}
			)
		});
	}

	public loadData(ids_per_class: { [className: string]: string[] }, orderBy: string[] = null, conditions: string = null)
	{
		return LoadingPromise.create<any>((resolve, reject) => {
			let promises: Promise<any>[] = [];
			let data: { [className: string]: { [id: string]: any } } = {};
			for (let className in ids_per_class) {
				let definition: ObjectDefinition = ClassManager.getDefinition(className);
				let ids = ids_per_class[className];
				if (ids == null || ids.length > 0) promises.push(ApiService.callModule('api', 'select', {
					definition: definition,
					ids: ids,
					orderBy: orderBy,
					conditions: conditions
				}).then(
					(result) => {
						data[className] = result.details;
					},
					(err) => {
						console.log('error while loading ids', ids, 'from class', className, ':', err);
					}
				));
			}
			Promise.all(promises).then(
				(result) => { resolve(data); },
				(err) => { console.error('error while loading data:', err); reject(err); }
			);
		});
	}


	public createObjectModels(data: { [className: string]: { [id: string]: any } })
	{
		let results: ObjectModel3[] = [];
		for (let className in data) {
			if (!this.unresolved_object_models[className]) this.unresolved_object_models[className] = {};
			let unresolved_object_models: { [id: string]: ObjectModel3 } = this.unresolved_object_models[className];
			let currentClass: any = ClassManager.getClass(className);
			let instances: any = ClassManager.getInstances(currentClass);
			let definition: ObjectDefinition = ClassManager.getDefinition(className);
			let id_field: string = definition.database.id || 'id';
			let db_id_field: string = definition.database.db_id || 'db_id';
			for(let i=0; i<data[className].length; ++i)
			{
				let item: any = data[className][i];
				let id: number = +item[id_field] || 0;
				let db_id: number = +item[db_id_field] || 0;
				let full_id: string = db_id + ':' + id;
				if (!unresolved_object_models[full_id]) {
					let object: ObjectModel3 = null;
					let use_cache: boolean = false;
					if (instances[full_id]) {
						object = instances[full_id];
						if (!this.forceReload) use_cache = true;
					}
					if (!use_cache) {
						if (!object) object = new currentClass();
						instances[full_id] = object;
						object.__source = item;
						this.assignValues(object);
						unresolved_object_models[full_id] = object;
					}
					results.push(object);
				}
			}
		}
		return results;
	}


	public assignValues(dst: ObjectModel3)
	{
		let src: any = dst.__source;
		let definition: ObjectDefinition = ClassManager.getDefinition(dst);
		let id_field: string = definition.database.id || 'id';
		let db_id_field: string = definition.database.db_id || 'db_id';
		dst[id_field]  = src[id_field];
		dst[db_id_field] = src[db_id_field];
		if (definition.values)
		{
			for(let valueName in definition.values)
			{
				let valueDef: ObjectValueDefinition = definition.values[valueName];
				if (valueDef.type === 'integer' || valueDef.type === 'number') dst[valueName] = +src[valueName];
				else if (valueDef.type === 'datetime') dst[valueName] = new Date(src[valueName]);
				else if (valueDef.type === 'json') dst[valueName] = JSON.parse(src[valueName]);
				else if (valueDef.type !== 'unused-column') dst[valueName] = src[valueName];
			}
		}
	}

	public resolveObjects()
	{
		let all_missing_ids: { [className: string]: string[] } = {};
		for(let className in this.unresolved_object_models) {
			let definition: ObjectDefinition = ClassManager.getDefinition(className);
			for(let id in this.unresolved_object_models[className]) {
				let missing_ids: { [className: string]: string[] } = this.resolveObject(this.unresolved_object_models[className][id], definition);
				if (Object.keys(missing_ids).length == 0) {
					if (!this.object_models[className]) this.object_models[className] = {};
					this.object_models[className][id] = this.unresolved_object_models[className][id];
					delete this.unresolved_object_models[className][id];
				}
				else for(let missingClass in missing_ids) {
					if (!all_missing_ids[missingClass]) all_missing_ids[missingClass] = [];
					for(let i=0; i<missing_ids[missingClass].length; ++i) {
						let missing_id: string = missing_ids[missingClass][i];
						if (all_missing_ids[missingClass].indexOf(missing_id) < 0) all_missing_ids[missingClass].push(missing_id);
					}
				}
			}
		}
		return all_missing_ids;
	}

	public resolveObject(dst: ObjectModel3, definition: ObjectDefinition = null, setFields: boolean = false)
	{
		let src: any = dst.__source;
		let missing_ids: { [className: string]: string[] } = {};
		if (!definition) definition = ClassManager.getDefinition(dst);
		if (definition.children)
		{
			for(let childName in definition.children)
			{
				let childDef: ObjectChildDefinition = definition.children[childName];
				let childClass: string = childDef.type;
				let id_field: string = childDef.db_column || ('id_' + childName);
				let db_id_field: string = 'db_' + id_field;
				let id: number = +src[id_field] || 0;
				let child: ObjectModel3 = null;
				if (id > 0) {
					let db_id: number = +src[db_id_field] || 0;
					let full_id: string = db_id + ':' + id;
					if (this.object_models[childClass] && this.object_models[childClass][full_id]) {
						child = this.object_models[childClass][full_id];
					}
					else if (this.unresolved_object_models[childClass] && this.unresolved_object_models[childClass][full_id]) {
						child = this.unresolved_object_models[childClass][full_id];
					}
					else {
						if (!missing_ids[childClass]) missing_ids[childClass] = [];
						missing_ids[childClass].push(full_id);
					}
				}
				if (setFields) dst[childName] = child;
			}
		}
		if (definition.links)
		{
			for(let linkName in definition.links)
			{
				let linkDef: ObjectLinkDefinition = definition.links[linkName];
				let linkClass: string = linkDef.type;
				let ids: string = src[linkName];
				let links: ObjectModel3[] = [];
				if (typeof(ids) === 'string') {
					let ids_array = ids.split(',');
					for(let i=0; i<ids_array.length; ++i) {
						let full_id: string = ids_array[i];
						if (this.object_models[linkClass] && this.object_models[linkClass][full_id]) {
							// if (!links) links = [];
							let link: ObjectModel3 = this.object_models[linkClass][full_id];
							if (links.indexOf(link) < 0) links.push(link);
						}
						else if (this.unresolved_object_models[linkClass] && this.unresolved_object_models[linkClass][full_id]) {
							// if (!links) links = [];
							let link: ObjectModel3 = this.unresolved_object_models[linkClass][full_id];
							if (links.indexOf(link) < 0) links.push(link);
						}
						else {
							if (!missing_ids[linkClass]) missing_ids[linkClass] = [];
							missing_ids[linkClass].push(full_id);
						}
					}
				}
				if (setFields) dst[linkName] = links;
			}
		}


		if (!setFields && Object.keys(missing_ids).length == 0) this.resolveObject(dst, definition, true);


		return missing_ids;
	}

}
