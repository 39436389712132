import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { ClassManager } from 'src/app/classes/objects/ClassManager.class';
import { ModuleBase } from '../module-base.class';
import { ItRequest } from './classes/ItRequest.class';
import { ItRequestsListViewComponent } from './views/it-requests-list-view/it-requests-list-view.component';
import { ItRequestViewComponent } from './views/it-request-view/it-request-view.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule
  ],
  declarations: [
      ItRequestsListViewComponent,
      ItRequestViewComponent
  ],
  exports: [
      ItRequestsListViewComponent,
      ItRequestViewComponent
  ],
  entryComponents: [
      ItRequestsListViewComponent,
      ItRequestViewComponent
  ]
})
export class ItModule extends ModuleBase {

    public static moduleName = 'orders';

    public ngOnInit()
    {
        ClassManager.registerClass('ItRequest', ItRequest);

        HomepageViewComponent.registerGroups({
            'others': {
                'name': "Autres",
                'backColor': 'rgb(0, 95, 73)',
                'textColor': 'white',
                'permission': 'home_groupe_autres',
                'children': [
                    {
                        'permission': 'home_bouton_autres_it',
                        'name': "Requêtes IT",
                        'component': ItRequestsListViewComponent
                    }
                ]
            }
        });

        return super.ngOnInit();
    }

}
