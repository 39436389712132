import { Page } from "../../printing/classes/Page.class";
import { Table } from "../../printing/classes/Table.class";
import { PrintableElement } from "../../printing/classes/PrintableElement.class";
import { PrintingModel } from "../../printing/classes/PrintingModel.class";
import { InfoBlock } from "../../../components/info-block/classes/InfoBlock.class";
import { DateTimeUtil } from "../../utils/classes/DateTimeUtil.class";
import { InfoBlockComponent } from "../../../components/info-block/info-block.component";
import { InfoBlockField } from "../../../components/info-block/classes/InfoBlockField.class";
// import { Price } from "../../sales/classes/Price.class";
import { Order } from "./Order.class";
import { NumberUtil } from "../../utils/classes/NumberUtil.class";
import { Deadline } from "../../other-data/classes/Deadline.class";
import { StockMove } from "../../stocks/classes/StockMove.class";
import { PrintingUtil } from "../../utils/classes/PrintingUtil.class";

export class DeliveryNotePrintingModel extends PrintingModel
{
    public optionsBlock: InfoBlock = {
        fields: [
            {
                title: 'Langue', field: 'lang', type: 'select', selectOptions: [
                    { text: '(Selon client)', value: '' },
                    { text: 'Français', value: 'fr' },
                    { text: 'Anglais', value: 'en' },
                    { text: 'Néerlandais', value: 'nl' }
                ], change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
                    this.regenerate();
                }
            }
        ]
    }

    public object: Order | StockMove = null;
    public lang: string = '';
    // public delivery_address: string = null;
    // public delivery_address: string = null;
    // public invoiceing_address: string = null;
    // public send_address: string = null;
    //public hide_customer: boolean = false;

    public static generate(object: Order | StockMove): PrintingModel
    {
        let model: DeliveryNotePrintingModel = new DeliveryNotePrintingModel();
        model.object = object;
        return model.regenerate();
    }

    public get introText()
    {
        if (this.object instanceof StockMove) return {
            'fr': 'Suite à notre contrat n°' + this.object.stock.order.number,
            'en': 'Further to our contract no. ' + this.object.stock.order.number,
            'nl': 'Naar aanleiding van ons contract nr. ' + this.object.stock.order.number
        };
        else if (this.object instanceof Order) return {
            'fr': 'Suite à votre commande n°' + this.object.customer_reference,
            'en': 'Further to your order no. ' + this.object.customer_reference,
            'nl': 'Naar aanleiding van uw bestelling nr. ' + this.object.customer_reference
        };
    }
    public get order()
    {
        if (this.object instanceof StockMove) return this.object.stock.order;
        else if (this.object instanceof Order) return this.object;
    }

    public regenerate(): PrintingModel
    {
        let object: Order | StockMove = this.object;
        let order: Order = this.order;

        let pageWidth = 210;
        let pageHeight = 295;

        let pageLeftMargin = 10;
        let pageRightMargin = 10;
        let pageTopMargin = 10;
        let pageBottomMargin = 10;

        let quantities: string[] = [];
        let prices: string[] = [];
        let totals: string[] = [];

        // if (!this.delivery_address && order.delivery_address) this.delivery_address = order.delivery_address;
        // if (!this.invoiceing_address && order.invoiceing_address) this.invoiceing_address = order.invoiceing_address;
        // if (!this.send_address && order.send_address) this.send_address = order.send_address;

        this.pages = [];

        let lang: string = this.lang;
        let mail_lang: string = this.lang;
        if (!lang || lang == '')
        {
            if (this.order.customer.lang) lang = this.order.customer.lang.code;
            else lang = 'fr';
        }
        if (!mail_lang || mail_lang == '')
        {
            if (this.order.supplier.lang) mail_lang = this.order.supplier.lang.code;
            else mail_lang = 'fr';
        }

        this.saveFilename = 'Bon de livraison n°' + (object instanceof StockMove ? object.fullNumber : object.number).replace(/\//g, '-') + '.pdf';
        this.mailFilename = {
                                'fr': 'Bon de livraison n° ',
                                'nl': 'Leveringsbon nr ',
                                'en': 'Delivery note no '
                            }[mail_lang] + this.order.shortNumber.replace(/\//g, '-') + '.pdf';

        // for(let i=0; i<this.order.suppliers.length; ++i)
        // {

            let page: Page = {

                width: pageWidth,
                height: pageHeight,

                leftMargin: pageLeftMargin,
                rightMargin: pageRightMargin,
                topMargin: pageTopMargin,
                bottomMargin: pageBottomMargin,

                style: {
                    'font-family': 'Calibri',
                    'font-size': '10pt',
                    'width': (pageWidth - pageLeftMargin - pageRightMargin) + 'mm',
                    'height': (pageHeight - pageTopMargin - pageBottomMargin) + 'mm',
                    'margin-top': pageTopMargin + 'mm',
                    'margin-left': pageLeftMargin + 'mm',
                    'margin-right': pageRightMargin + 'mm',
                    'margin-bottom': pageBottomMargin + 'mm',
                },

                headerSize: 33,
                headerElements: [
                    {
                        style: {
                            'position': 'absolute',
                            'right': '0mm',
                            'top': '1mm',
                            'width': '85mm',
                            'height': '17mm',
                            'border': '.5mm solid rgba(0, 176, 240, 1.0)',
                            'border-radius': '2.5mm',
                            'line-height': '4mm'
                        },
                        children: [
                            {
                                style: {
                                    'font-size': '20pt',
                                    'font-weight': 'bold',
                                    'text-align': 'center',
                                    'width': '100%',
                                    'line-height': '5mm',
                                    'margin-top': '1.5mm',
                                    'margin-bottom': '1mm',
                                    'vertical-align': 'top'
                                },
                                html: ({
                                    'fr': 'BON DE LIVRAISON',
                                    'en': 'DELIVERY NOTE',
                                    'nl': 'LEVERINGSBON'
                                }[lang])
                            },
                            {
                                style: {
                                    'display': 'flex',
                                    'flex-direction': 'row'
                                },
                                children: [
                                    {
                                        style: {
                                            'width': '20%',
                                            'text-align': 'right',
                                            'padding': '0 1mm'
                                        },
                                        html: ({
                                                'fr': 'N° :',
                                                'en': 'No :',
                                                'nl': 'Nr :'
                                               } [lang])
                                    },
                                    {
                                        style: {
                                            'width': '80%',
                                            'padding': '0 1mm',
                                            'font-weight': 'bold'
                                        },
                                        html: object.delivery_number
                                    }
                                ]
                            },
                            {
                                style: {
                                    'display': 'flex',
                                    'flex-direction': 'row'
                                },
                                children: [
                                    {
                                        style: {
                                            'width': '20%',
                                            'text-align': 'right',
                                            'padding': '0 1mm'
                                        },
                                        html: ({
                                                'fr': 'Date :',
                                                'en': 'Date :',
                                                'nl': 'Datum :'
                                               } [lang])
                                    },
                                    {
                                        style: {
                                            'width': '30%',
                                            'padding': '0 1mm'
                                        },
                                        html: ''
                                    },
                                    {
                                        style: {
                                            'width': '25%',
                                            'text-align': 'right',
                                            'padding': '0 1mm'
                                        },
                                        html: ({
                                                'fr': 'N° client :',
                                                'en': 'Cust. No :',
                                                'nl': 'Klant Nr :'
                                               } [lang])
                                    },
                                    {
                                        style: {
                                            'width': '25%',
                                            'padding': '0 1mm'
                                        },
                                        html: order.customer.number
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        style: {
                            'position': 'absolute',
                            'left': '0mm',
                            'top': '0mm',
                            'width': '50mm',
                            'height': '30mm',
                            'background-image': 'url(assets/img/logo_documents.png)',
                            'background-position': 'center center',
                            'background-size': 'contain',
                            'background-repeat': 'no-repeat'
                        },
                        html: ''
                    }
                ],

                bodyElements: [
                    {
                        style: {
                            'position': 'absolute',
                            'right': '0mm',
                            'top': '0mm',
                            'width': '85mm',
                            'height': '25m',
                            'line-height': '4mm'
                        },
                        children: PrintingUtil.formatAddress(order.customer.name, this.object.delivery_contact, this.object.delivery_address_text, null, this.object.openingHours)
                    },


                    {
                        style: {
                            'white-space': 'pre-wrap',
                            'font-weight': 'bold',
                            'margin-top': '30mm'
                        },
                        html: (this.introText[lang]) + ({
                                'fr': ', nous avons le plaisir de vous expédier la/les marchandise(s) suivante(s) :',
                                'en': ', we are pleased to deliver to you the following goods :',
                                'nl': ', hebben wij het genoegen U volgende goederen te leveren :'
                              }[lang])
                    },


                    {
                        style: {
                            'display': 'table',
                            'width': '100%',
                            'border-collapse': 'collapse'
                        },
                        children: [
                            {
                                style: {
                                    'display': 'table-row',
                                    'font-size': '11pt'
                                },
                                children: [
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            // 'width': '33%',
                                            'background-color': 'rgba(217,217,217,1)',
                                            'border': '.25mm solid rgba(0,0,0,1)',
                                            'text-align': 'center',
                                            'font-weight': 'bold',
                                            'color': 'rgba(0,156,216,1.0)',
                                            'white-space': 'pre',
                                            'vertical-align': 'middle',
                                        },
                                        html: ({
                                            'fr': 'DÉSIGNATION DE L\'ARTICLE',
                                            'en': 'PRODUCT NAME',
                                            'nl': 'BESCHRIJVING'
                                        })[lang]
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'width': '25mm',
                                            'background-color': 'rgba(217,217,217,1)',
                                            'border': '.25mm solid rgba(0,0,0,1)',
                                            'text-align': 'center',
                                            'font-weight': 'bold',
                                            'color': 'rgba(0,156,216,1.0)',
                                            'white-space': 'pre',
                                            'vertical-align': 'middle',
                                        },
                                        html: ({
                                            'fr': 'QUANTITÉ\nCOMMANDÉE',
                                            'en': 'ORDERED\nQUANTITY',
                                            'nl': 'BESTELDE\nHOEVEELHEID'
                                        })[lang]
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'width': '25mm',
                                            'background-color': 'rgba(217,217,217,1)',
                                            'border': '.25mm solid rgba(0,0,0,1)',
                                            'text-align': 'center',
                                            'font-weight': 'bold',
                                            'color': 'rgba(0,156,216,1.0)',
                                            'white-space': 'pre',
                                            'vertical-align': 'middle',
                                        },
                                        html: ({
                                            'fr': 'QUANTITÉ\nLIVRÉE',
                                            'en': 'DELIVERED\nQUANTITY',
                                            'nl': 'GELEVERDE\nHOEVEELHEID'
                                        })[lang]
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'width': '25mm',
                                            'background-color': 'rgba(217,217,217,1)',
                                            'border': '.25mm solid rgba(0,0,0,1)',
                                            'text-align': 'center',
                                            'font-weight': 'bold',
                                            'color': 'rgba(0,156,216,1.0)',
                                            'white-space': 'pre',
                                            'vertical-align': 'middle',
                                        },
                                        html: (order.sale.article.type == 0 ? {
                                            'fr': 'CONDITION-\nNEMENT',
                                            'en': 'PACKAGING',
                                            'nl': 'VERPAKKING'
                                        } : {
                                            'fr': 'GARANTIE',
                                            'en': 'WARRANTY',
                                            'nl': 'GARANTIE'
                                        })[lang]
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'width': '25mm',
                                            'background-color': 'rgba(217,217,217,1)',
                                            'border': '.25mm solid rgba(0,0,0,1)',
                                            'text-align': 'center',
                                            'font-weight': 'bold',
                                            'color': 'rgba(0,156,216,1.0)',
                                            'white-space': 'pre',
                                            'vertical-align': 'middle',
                                        },
                                        html: ({
                                            'fr': 'NOMBRE DE\nBOITES LIVRÉES',
                                            'en': 'NUMBER OF\nPACKAGES',
                                            'nl': 'AANTAL\nVERPAKKINGEN'
                                        })[lang]
                                    }
                                ]
                            },
                            {
                                style: {
                                    'display': 'table-row'
                                },
                                children: [
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            // 'width': '33%',
                                            'text-align': 'left',
                                            'border': '.25mm solid rgba(0,0,0,1)',
                                            'vertical-align': 'top',
                                            'height': '70mm',
                                            'padding': '0 1mm',
                                            'white-space': 'pre-wrap'
                                        },
                                        html: (order.sale.article && order.sale.article.designation ? order.sale.article.designation : '') +
                                              (order.sale.article_reference && order.sale.article_reference.length ? '\n' + {
                                                    'fr': 'N° article : ',
                                                    'nl': 'Artikel nr. : ',
                                                    'en': 'Article no. : '
                                                }[lang] + order.sale.article_reference : '')
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'width': '25mm',
                                            'text-align': 'center',
                                            'border': '.25mm solid rgba(0,0,0,1)',
                                            'vertical-align': 'top',
                                            'height': '70mm',
                                            'padding': '0 1mm'
                                        },
                                        html: object.quantityAndUnit
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'width': '25mm',
                                            'text-align': 'center',
                                            'border': '.25mm solid rgba(0,0,0,1)',
                                            'vertical-align': 'top',
                                            'height': '70mm',
                                            'padding': '0 1mm'
                                        },
                                        html: object.quantityAndUnit
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'width': '25mm',
                                            'text-align': 'center',
                                            'border': '.25mm solid rgba(0,0,0,1)',
                                            'vertical-align': 'top',
                                            'height': '70mm',
                                            'padding': '0 1mm'
                                        },
                                        html: order.sale.article && order.sale.article.type == 0 ? order.sale.article.field14 : order.sale.article.field13 // packaging / warranty
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'width': '25mm',
                                            'text-align': 'center',
                                            'border': '.25mm solid rgba(0,0,0,1)',
                                            'vertical-align': 'top',
                                            'height': '70mm',
                                            'padding': '0 1mm'
                                        },
                                        html: ''
                                    },
                                ]
                            }
                        ]
                    },






                    {
                        style: {
                            'clear': 'both'
                        }
                    },




                    {
                        style: {
                            'display': 'table',
                            'width': '100%',
                            'border-collapse': 'collapse',
                            'margin-top': '3mm'
                        },
                        children: [
                            {
                                style: {
                                    'display': 'table-row',
                                },
                                children: [
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'border': '.25mm solid rgba(0,0,0,1)',
                                            'text-align': 'right',
                                            'background-color': 'rgba(217, 217, 217, 1)',
                                            'width': '50mm',
                                            'white-space': 'pre-wrap',
                                            'padding-right': '1mm'
                                        },
                                        html: ({
                                            'fr': 'TRANSPORT :\n\n',
                                            'en': 'TRANSPORT :\n\n',
                                            'nl': 'TRANSPORT :\n\n'
                                        }[lang])
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'border': '.25mm solid rgba(0,0,0,1)',
                                            'white-space': 'pre-wrap',
                                            'padding-left': '1mm'
                                        },
                                        html: ({
                                            'fr': 'Nos soins\n\n',
                                            'en': 'Our care\n\n',
                                            'nl': 'Door ons\n\n'
                                        }[lang])
                                    }
                                ]
                            }
                        ]
                    },


                    {
                        style: {
                            'display': 'block',
                            'width': '100%',
                            'border': '.25mm solid rgba(0,0,0,1)',
                            'text-align': 'center',
                            'vertical-align': 'middle',
                            'color': 'rgba(0,156,216,1.0)',
                            'font-weight': 'bold',
                            'font-size': '11pt',
                            'background-color': 'rgba(217,217,217,1)',
                            'margin-top': '3mm'
                        },
                        html: ({
                            'fr': 'REMARQUE(S) IMPORTANTE(S)',
                            'en': 'IMPORTANT NOTE(S)',
                            'nl': 'BELANGERIJKE OPMERKING(EN)'
                        }[lang])
                    },
                    {
                        style: {
                            'display': 'block',
                            'flex': '1',
                            'border-right': '.25mm solid rgba(0,0,0,1)',
                            'border-left': '.25mm solid rgba(0,0,0,1)',
                            'border-bottom': '.25mm solid rgba(0,0,0,1)',
                            'width': '100%',
                            'padding': '.5mm 1mm',
                            'line-height': '5mm',
                            'white-space': 'pre-wrap',
                            'font-weight': 'bold',
                            'vertical-align': 'top'
                        },
                        children: PrintingUtil.linesToDivs(({
                            'fr': 'CLAUSE DE RÉSERVE DE PROPRIÉTÉ :\nNous conservons l\'entière propriété des marchandises vendues jusqu\'à complet paiement de son prix.\n',
                            'en': 'RETENTION OF TITLE CLAUSE :\nWe retain full ownership of the goods sold until full payment.\n',
                            'nl': 'CLAUSULE VAN EIGENDOMSVOORBEHOUD :\nDe goederen blijven onze eigendom tot het volledig betalen van het verschuldigde factuurbedrag.\n'
                        })[lang] + (order.remarks_note || ''))
                    },

                ],

                footerSize: 63,
                footerElements: [
                    {
                        style: {
                            'display': 'table',
                            'width': '100%',
                            'border-collapse': 'collapse',
                            'margin-top': '3mm',
                        },
                        children: [
                            {
                                style: {
                                    'display': 'table-row'
                                },
                                children: [
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'border': '.25mm solid rgba(0,0,0,1)',
                                            'background-color': 'rgba(217, 217, 217, 1.0)',
                                            'width': '40%',
                                            'padding': '.5mm 1mm',
                                            'text-align': 'center',
                                            'line-height': '5mm',
                                            'font-weight': 'bold',
                                            'color': 'rgba(0,156,216,1.0)'
                                        },
                                        html: ({
                                            'fr': 'VOTRE RÉFÉRENCE DE COMMANDE',
                                            'en': 'YOUR ORDER REFERENCE',
                                            'nl': 'UW KENMERK'
                                        }[lang])
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'border': '.25mm solid rgba(0,0,0,1)',
                                            'background-color': 'rgba(217, 217, 217, 1.0)',
                                            'width': '60%',
                                            'padding': '.5mm 1mm',
                                            'text-align': 'center',
                                            'line-height': '5mm',
                                            'font-weight': 'bold',
                                            'color': 'rgba(0,156,216,1.0)'
                                        },
                                        html: ({
                                            'fr': 'DATE ET SIGNATURE POUR RÉCEPTION',
                                            'en': 'RECEIPT DATE AND SIGNATURE',
                                            'nl': 'NAAM EN HANDTEKENING'
                                        }[lang])
                                    }
                                ]
                            },
                            {
                                style: {
                                    'display': 'table-row'
                                },
                                children: [
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'border': '.25mm solid rgba(0,0,0,1)',
                                            'width': '40%',
                                            // 'padding': '.5mm 1mm',
                                            'padding': '0',
                                            'text-align': 'center',
                                            'line-height': '5mm',
                                            'white-space': 'pre'
                                        },
                                        children: [
                                            {
                                                style: {
                                                    'display': 'block',
                                                    'height': '15mm',
                                                    'line-height': '15mm',
                                                    'width': '100%',
                                                    'text-align': 'center',
                                                    // 'vertical-align': 'middle',
                                                    'font-size': '11pt',
                                                    'font-weight': 'bold'
                                                },
                                                html: this.object.customer_reference
                                            },
                                            {
                                                style: {
                                                    'display': 'block',
                                                    'height': '5mm',
                                                    'line-height': '5mm',
                                                    'width': '100%',
                                                    'background-color': 'rgba(217, 217, 217, 1.0)',
                                                    'text-align': 'center',
                                                    'font-weight': 'bold',
                                                    'color': 'rgba(0,156,216,1.0)',
                                                    'border-top': '.25mm solid rgba(0,0,0,1)',
                                                    'border-bottom': '.25mm solid rgba(0,0,0,1)',
                                                },
                                                html: ({
                                                        'fr': 'REPRÉSENTANT',
                                                        'en': 'CONTACT PERSON',
                                                        'nl': 'CONTACT PERSOON'
                                                    })[lang]
                                            },
                                            {
                                                style: {
                                                    'display': 'block',
                                                    'height': '10mm',
                                                    'line-height': '5mm',
                                                    'font-size': '11pt',
                                                    'width': '100%',
                                                    'text-align': 'center',
                                                },
                                                html: order.merchant.name + '\nMail : ' + order.merchant.email
                                            }
                                        ]
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'border': '.25mm solid rgba(0,0,0,1)',
                                            'width': '20%',
                                            'padding': '.5mm 1mm',
                                            'text-align': 'center',
                                            'line-height': '5mm',
                                            'white-space': 'pre',
                                            'height': '30mm',
                                            // 'position': 'absolute',
                                            // 'right': '0',
                                            // 'top': '8.5mm',
                                            'vertical-align': 'top'
                                        },
                                        children: []
                                    }
                                ]
                            },

                        ]
                    },

                    PrintingUtil.getFooter(this.order.accounting, lang)
                ]

            };

            this.pages.push(page);

        //}
        return this;
    }
}
