import { Component, OnInit, Input, forwardRef, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as $ from 'jquery';

@Component({
  selector: 'combo-box',
  templateUrl: './combo-box.component.html',
  styleUrls: ['./combo-box.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboBoxComponent),
      multi: true
    }
  ]
})
export class ComboBoxComponent {

    @Input() public objects: any[] = [];
    @Input() public fieldName: string = null;
    @Input() public linesCount: number = 5;
    @Input() public textFilter: string = null;
    @Input() public disableFiltering: boolean = false;

    @Output() public change: EventEmitter<string> = new EventEmitter<string>();
    @Output() public select: EventEmitter<any> = new EventEmitter<any>();

    public showList: boolean = false;
    public inverseList: boolean = false;

    //@ViewChild('elementRef') public elementRef: ElementRef;

    constructor(public elementRef: ElementRef) { }

    toggleList(action = null)
    {
        this.showList = action ? action : !this.showList;
        if (this.showList === true) {
            this.filterList(null);
            setTimeout(() => {
                let options = $('.combo-box-options')[0];
                let elemRect = this.elementRef.nativeElement.getBoundingClientRect();
                let listRect = options.getBoundingClientRect();
                this.inverseList = (elemRect.bottom + listRect.height > window.innerHeight - 18 /* scrollbar */);
            }, 0);
        }
    }
    itemMouseDown(event: any)
    {
        event.preventDefault();
    }
    itemClick(object: any, event: any)
    {
        event.preventDefault();
        this.value = object[this.fieldName];
        this.showList = false;
        this.select.next(object);
    }

    public get filteredObjects()
    {
        let self = this;
        if (this.disableFiltering || !this.textFilter) return this.objects;
        else {
            let r: RegExp = new RegExp(this.textFilter, 'i');
            return this.objects.filter(
                (value: any, index: number, array: any[]) => {
                    return r.test(value.city);
                }
            )
        }
    }

    blurTimeout: any = null;
    filterList(event)
    {
        this.cancelBlurTimeout();
        this.textFilter = this.value;
    }
    blurInput(event)
    {
        this.cancelBlurTimeout();
        this.blurTimeout = setTimeout(() => { this.showList = false; }, 100);
    }
    cancelBlurTimeout()
    {
        if (this.blurTimeout) clearTimeout(this.blurTimeout);
        this.blurTimeout = null;
    }

    // ControlValueAccessor

    private _value: string = null;
    public get value() { return this._value; }
    public set value(value: string) {
        this._value = value;
        this.filterList(null);
        this.propagateChange(value);
        this.change.next(value);
    }

    propagateChange = (_: any) => {};
    registerOnChange(fn) { this.propagateChange = fn; }
    registerOnTouched() {}
    writeValue(value: any) { this._value = value; }

}
