import { LogEntry } from './LogEntry.class';
import * as StackTrace from 'stacktrace-js';

export class Logger
{
    public static entries: LogEntry[] = [];

    private static registerEntry(logEntry)
    {
        Logger.entries.push(logEntry);
        return logEntry;
    }

    public static logTrace(filename: string, ...args)
    {
        console.trace(...args);
        return Logger.registerEntry(new LogEntry(LogEntry.LOG_TRACE, filename, ...args));
    }
    public static logInfo(filename: string, ...args)
    {
        console.info(...args);
        return Logger.registerEntry(new LogEntry(LogEntry.LOG_INFO, filename, ...args));
    }
    public static logWarning(filename: string, ...args)
    {
        console.warn(...args);
        return Logger.registerEntry(new LogEntry(LogEntry.LOG_WARNING, filename, ...args));
    }
    public static logError(filename: string, ...args)
    {
        console.error(...args);
        return Logger.registerEntry(new LogEntry(LogEntry.LOG_ERROR, filename, ...args));
    }
    public static logCritical(filename: string, ...args)
    {
        console.error(...args);
        return Logger.registerEntry(new LogEntry(LogEntry.LOG_CRITICAL, filename, ...args));
    }
}
