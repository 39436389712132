import { ObjectModel2 } from "src/app/classes/objects/ObjectModel2.class";
import { ObjectDefinition } from "src/app/classes/objects/ObjectDefinition.class";
import { PaymentStatus } from "./PaymentStatus.class";
import { Invoice } from "../../invoicing/classes/Invoice.class";
import { Order } from "../../orders/classes/Order.class";
import { ServerApi } from "src/app/classes/api/ServerApi.class";
import { ObjectModel3 } from "src/app/classes/objects/ObjectModel3.class";
import { Accounting } from "../../other-data/classes/Accounting.class";


export class Payment extends ObjectModel3
{
    public accounting: Accounting = null;
    public date: string = null;
    public number: string = null;
    public amount: number = null;
    public status: PaymentStatus = null;
    public remarks: string = null;
    public archived: boolean = false;

    public invoice: Invoice = null;
    public order: Order = null;

    constructor()
    {
        super(Payment);
    }

    public getNumberPrefix(): string
    {
        return ("00" + (new Date().getFullYear() % 100)).slice(-2) + '-';
    }
    public getNextNumber()
    {
        return ServerApi.callModule('payments', 'paymentNextNumber', { 'number_prefix': this.getNumberPrefix() });
    }
    public generateNumber(number: string): string
    {
        return this.getNumberPrefix() + ("0000" + number).slice(-4);
    }

    /* ObjectModel FUNCTIONS */

    // public static instances: { [id: number]: Address; } = {};

    public static definition: ObjectDefinition = {
        database: {
            table: 'payments',
            id: 'id',
			db_id: 'db_id'
        },
        values: {
            'date': { type: 'string' },
            'number': { type: 'string' },
            'amount': { type: 'number' },
            'remarks': { type: 'string' },
            'archived': { type: 'number' }
        },
        children: {
            'accounting': { type: 'Accounting', clone: false, save: false, delete: false },
            'invoice': { type: 'Invoice', clone: false, save: false, delete: false },
            'order': { type: 'Order', clone: false, save: false, delete: false },
            'status': { type: 'PaymentStatus', clone: false, save: false, delete: false }
        },
        links: {}
    };
}
