import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerPaymentsListViewComponent } from './views/customer-payments-list-view/customer-payments-list-view.component';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components/components.module';
import { ClassManager } from 'src/app/classes/objects/ClassManager.class';
import { PaymentStatus } from './classes/PaymentStatus.class';
import { ModuleBase } from '../module-base.class';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { SupplierPaymentsListViewComponent } from './views/supplier-payments-list-view/supplier-payments-list-view.component';
import { Payment } from './classes/Payment.class';
import { SupplierPayment } from './classes/SupplierPayment.class';
import { RemindersViewComponentComponent } from './views/reminders-view-component/reminders-view-component.component';
import { PrintingModel } from '../printing/classes/PrintingModel.class';
import { PrintingModule } from '../printing/printing.module';
import { ReminderType } from './classes/ReminderType.class';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ComponentsModule,
        PrintingModule
    ],
    declarations: [
        CustomerPaymentsListViewComponent,
        SupplierPaymentsListViewComponent,
        RemindersViewComponentComponent
    ],
    exports: [
        CustomerPaymentsListViewComponent,
        SupplierPaymentsListViewComponent,
        RemindersViewComponentComponent
    ],
    entryComponents: [
        CustomerPaymentsListViewComponent,
        SupplierPaymentsListViewComponent,
        RemindersViewComponentComponent
    ]
})
export class PaymentsModule extends ModuleBase {

    public static moduleName = 'payments';

    public ngOnInit()
    {
        ClassManager.registerClass('PaymentStatus', PaymentStatus);
        ClassManager.registerClass('Payment', Payment);
        ClassManager.registerClass('SupplierPayment', SupplierPayment);
        ClassManager.registerClass('ReminderType', ReminderType);

        HomepageViewComponent.registerGroups({
            'payments': {
                'name': "Paiements",
                'backColor': 'rgb(127, 127, 127)',
                'textColor': 'white',
                'permission': 'home_groupe_paiements',
                'children': [
                    {
                        'name': "Paiements des clients",
                        'permission': 'home_bouton_paiements_clients',
                        'component': CustomerPaymentsListViewComponent
                    },
                    {
                        'name': "Paiements des fournisseurs",
                        'permission': 'home_bouton_paiements_fournisseurs',
                        'component': SupplierPaymentsListViewComponent
                    }
                ]
            }
        });

        return super.ngOnInit();
    }

}
