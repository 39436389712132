import { Address } from '../../addresses/classes/Address.class';
import { Article } from '../../articles/classes/Article.class';
import { CustomerCategory } from './CustomerCategory.class';
import { Deadline } from '../../other-data/classes/Deadline.class';
import { Lang } from '../../other-data/classes/Lang.class';
import { LoadSequence } from 'src/app/classes/objects/LoadSequence.class';
import { Merchant } from '../../other-data/classes/Merchant.class';
import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel } from '../../../classes/objects/ObjectModel.class';
import { ObjectModel2 } from '../../../classes/objects/ObjectModel2.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';
import { ServerApi } from '../../../classes/api/ServerApi.class';

export class Customer extends ObjectModel3 {
  public name: string = null;
  public bob_id: string = null;
  public identifier: string = null;
  public category: CustomerCategory = null;
  public number: string = null;
  public lang: Lang = null;
  public deadline: string = null;

  public parent: Customer = null;

  // public billing_email: string = null;
  // public complaint_email: string = null;

  public id_merchant: number = null;
  public id_billing_address: number = null;
  public id_delivery_address: number = null;
  public id_ordinator_address: number = null;

  public merchant: Merchant = null;
  public billing_address: Address = new Address();
  public delivery_address: Address = new Address();
  public ordinator_address: Address = new Address();

  public vat_number: string = null;
  public vat: number = 21;
  public iban: string = null;
  public bic: string = null;

  public url: string = null;

  public contacts: Address[] = [];
  public delivery_addresses: Address[] = [];
  public invoicing_addresses: Address[] = [];
  // public ids_contacts: string = null;

  public remarks: string = null;
  public warning: string = null;

  public valid: boolean = true;

  public archived: number = 0;

  constructor() {
    super(Customer);
  }

  get deliveryAddressesList() {
    let addrs: any[] = [];
    if (this.delivery_address != null) addrs.push({ name: 'Adresse principale', address: this.delivery_address });
    if (this.delivery_addresses != null && Array.isArray(this.delivery_addresses)) {
      for (let i = 0; i < this.delivery_addresses.length; ++i) {
        addrs.push({
          name:
            'Adresse ' +
            (i + 2) +
            (this.delivery_addresses[i].title ? ' (' + this.delivery_addresses[i].title + ')' : ''),
          address: this.delivery_addresses[i],
        });
      }
    }
    return addrs;
  }
  get invoicingAddressesList() {
    let addrs: any[] = [];
    if (this.invoicing_addresses != null) addrs.push({ name: 'Adresse principale', address: this.billing_address });
    if (this.invoicing_addresses != null && Array.isArray(this.invoicing_addresses)) {
      for (let i = 0; i < this.invoicing_addresses.length; ++i) {
        addrs.push({
          name:
            'Adresse ' +
            (i + 2) +
            (this.invoicing_addresses[i].title ? ' (' + this.invoicing_addresses[i].title + ')' : ''),
          address: this.invoicing_addresses[i],
        });
      }
    }
    return addrs;
  }

  public get nameWithIdentifier() {
    return this.identifier ? `${this.name} (${this.identifier})` : this.name;
  }
  public get merchantName() {
    return this.merchant && this.merchant.name;
  }
  public get categoryName() {
    return this.category && this.category.name;
  }
  public get langName() {
    return this.lang && this.lang.name;
  }
  public get ordinatorPostcode() {
    return this.ordinator_address && this.ordinator_address.postcode;
  }
  public get ordinatorCity() {
    return this.ordinator_address && this.ordinator_address.city;
  }
  public get billingAccountingEmail() {
    return this.billing_address && this.billing_address.accounting_email;
  }
  public get billingInvoicingEmail() {
    return this.billing_address && this.billing_address.invoicing_email;
  }

  public get orderedContacts() {
    return this.contacts.sort((a, b) => {
      const splitA = (a.name1 || '').split(' ').filter(Boolean) || [];
      const splitB = (b.name1 || '').split(' ').filter(Boolean) || [];
      const nameA = splitA[splitA.length - 1] || '';
      const nameB = splitB[splitB.length - 1] || '';
      return nameA.localeCompare(nameB);
    });
  }

  /* ObjectModel FUNCTIONS */

  public static definition: ObjectDefinition = {
    name: ['client', 'le', 'du'],
    database: {
      table: 'customers',
      id: 'id',
      db_id: 'db_id',
    },
    trashDelete: true,
    values: {
      name: { type: 'string' },
      bob_id: { type: 'string' },
      identifier: { type: 'string' },
      number: { type: 'string' },
      vat_number: { type: 'string' },
      vat: { type: 'number' },
      iban: { type: 'string' },
      bic: { type: 'string' },
      url: { type: 'string' },
      remarks: { type: 'string' },
      warning: { type: 'string' },
      deadline: { type: 'string' },
      valid: { type: 'number' },
      archived: { type: 'number' },
    },
    children: {
      billing_address: { type: 'Address', clone: true, save: true, delete: true },
      delivery_address: { type: 'Address', clone: true, save: true, delete: true },
      ordinator_address: { type: 'Address', clone: true, save: true, delete: true },
      merchant: { type: 'Merchant', clone: false, save: false, delete: false },
      category: { type: 'CustomerCategory', clone: false, save: false, delete: false },
      lang: { type: 'Lang', clone: false, save: false, delete: false },
      // 'deadline': { type: 'Deadline', clone: false, save: true, delete: false },
      parent: { type: 'Customer', clone: false, save: false, delete: false },
    },
    links: {
      contacts: {
        type: 'Address',
        table: 'customers_contacts',
        id_self: 'id_customer',
        id_link: 'id_address',
        clone: true,
        save: true,
        delete: true,
      },
      delivery_addresses: {
        type: 'Address',
        table: 'customers_delivery_addresses',
        id_self: 'id_customer',
        id_link: 'id_address',
        clone: true,
        save: true,
        delete: true,
      },
      invoicing_addresses: {
        type: 'Address',
        table: 'customers_invoicing_addresses',
        id_self: 'id_customer',
        id_link: 'id_address',
        clone: true,
        save: true,
        delete: true,
      },
    },
  };

  public static getNextNumber(prefix: string = 'BEL') {
    return ServerApi.callModule('customers', 'nextNumber', { prefix: prefix });
  }
}
