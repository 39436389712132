import { Component, OnInit, ViewChild } from '@angular/core';
import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { ParentStock } from '../../classes/ParentStock.class';
import { Order } from 'src/app/modules/orders/classes/Order.class';
import { DateTimeUtil } from 'src/app/modules/utils/classes/DateTimeUtil.class';
import { Supplier } from 'src/app/modules/suppliers/classes/Supplier.class';
import { Customer } from 'src/app/modules/customers/classes/Customer.class';
import { InfoBlock } from 'src/app/components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from 'src/app/components/info-block/info-block.component';
import { InfoBlockField } from 'src/app/components/info-block/classes/InfoBlockField.class';
import { Form } from 'src/app/classes/forms/Form.class';
import { FormValidator } from 'src/app/classes/forms/FormValidator.class';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { StockMove } from '../../classes/StockMove.class';
import { DataGridComponent } from 'src/app/components/data-grid/data-grid.component';
import { ArrayUtil } from 'src/app/modules/utils/classes/ArrayUtil.class';
import { Nomenclature } from 'src/app/modules/articles/classes/Nomenclature.class';
import { RollingDirection } from 'src/app/modules/other-data/classes/RollingDirection.class';
import { ArticleInfoBlocks } from 'src/app/modules/articles/classes/ArticleInfoBlocks.class';
import { DialogComponent } from 'src/app/components/dialogs/dialog/dialog.component';
import { SelectStockDialogComponent } from '../select-stock-dialog/select-stock-dialog.component';
import { Stock } from '../../classes/Stock.class';
import { SaveSequence } from 'src/app/classes/objects/SaveSequence.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { CurrenciesService } from 'src/app/services/currencies/currencies.service';
import { Permission } from '../../../../classes/credentials/Permission.class';

@Component({
  selector: 'app-stock-view',
  templateUrl: './parent-stock-view.component.html',
  styleUrls: ['./parent-stock-view.component.css']
})
export class ParentStockViewComponent extends AccessViewComponent {

  public permission: string = 'ecriture_stocks_ficheparent';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Stock parent',
    data: this,
    elements: [
      { type: 'separator' },
      {
        type: 'button',
        name: 'saveButton',
        text: 'Enregistrer',
        icon: 'save',
        click: function (view: ParentStockViewComponent) {
          view.save2().then((result) => {
            //view.updateView(view.demandCopy);
          });
        },
        options: { visible: true }
      },
      { type: 'separator-large' },
      {
        type: 'button',
        name: 'saveButton',
        text: 'Lier<br/>un stock',
        icon: 'link',
        click: function (view: ParentStockViewComponent) {
          view.showStockList();
        },
        options: { visible: true }
      },
      { type: 'spacing' }
    ]
  };

  public parentStock: ParentStock = null;
  public parentStockCopy: ParentStock = null;

  public stocksData: any = { items: [] };
  public suppliersData: any = { items: [] };
  public accountingsData: any = { items: [] };
  public currenciesData: any = { items: [] };

  public nomenclaturesData: any = { items: [] };
  public rollingsData: any = { items: [] };

  @ViewChild('parentStockBlockComponent') parentStockBlockComponent;
  public parentStockBlock: InfoBlock = {
    title: 'Stock\nparent',
    backColor: "rgb(54,96,146)",
    textColor: "white",
    fields: [
      { title: 'Date de création', type: 'date', field: 'creation_date' },
      {
        title: 'Fournisseur', field: 'supplier', type: 'foreign-list', allowBlankValues: true,
        multiSelect: false, listItems: this.suppliersData, listField: 'name', nullText: '(Aucun)'
      },
      { title: 'Unité', type: 'text', field: 'unit' },
      { title: 'Prix d\'achat négocié', type: 'number', field: 'buy_price', decimalsCount: 5, numberUnit: ' € H.T.V.A.', textAlign: 'left' }
    ]
  };

  public articleBlock: InfoBlock = {};
  public deviceBlock: InfoBlock = {};
  public serviceBlock: InfoBlock = {};


  public accountingBlock: InfoBlock = {
    title: '',
    backColor: "rgb(64,0,64)",
    textColor: "white",
    fields: [
      {
        title: 'Comptabilité', field: 'accounting', type: 'foreign-list',
        multiSelect: false, listItems: this.accountingsData, listField: 'fullDescription', allowBlankValues: false,
        comment: 'Sélectionnez ici la comptabilité liée à ce dossier'
      },
      {
        title: 'Devise', field: 'currency', type: 'foreign-list',
        multiSelect: false, listItems: this.currenciesData, listField: 'fullDescription', allowBlankValues: false,
        comment: 'Sélectionnez ici la devise à utiliser pour cette charge', readonly: true
      },
      { title: 'Taux de change', field: 'xrate', type: 'number', decimalsCount: 6, textAlign: 'left', readonly: true },
    ]
  };

  @ViewChild('movesGrid') movesGrid: DataGridComponent;
  public movesGridColumns = [
    { title: 'Date', field: 'date', type: 'date', width: 110 },
    { title: 'Client', field: 'customer.nameWithIdentifier', width: 180 },
    { title: 'Numéro d\'appel', field: 'fullNumber', width: 180 },
    // { title: 'Numéro de facture', field: 'invoice.number', width: 200 },
    // { title: 'Désignation', field: 'article.designation', width: 550 },
    // { title: 'N° de commande client', field: 'customer_reference', width: 150 },
    // { title: 'Fournisseur', field: 'supplier.name', width: 200 },
    { title: 'Quantité', field: 'quantityAndUnit', width: 200 },
    { title: 'Prix d\'achat', field: 'buy_price', type: 'number', decimalsCount: 5, numberUnit: ' €', textAlign: 'left', width: 130 },
    { title: 'Bénéfices', field: 'gain', type: 'number', decimalsCount: 5, numberUnit: ' €', textAlign: 'left', width: 130 }
  ];
  @ViewChild('stocksGrid') stocksGrid: DataGridComponent;
  public stocksGridColumns = [
    { title: 'Date', field: 'creation_date', type: 'date', width: 80 },
    // { title: 'Désignation', field: 'article.designation' },
    { title: 'Client', field: 'customer.nameWithIdentifier', width: 180 },
    // { title: 'Fournisseur', field: 'supplier.name' },
    { title: 'Numéro de commande', field: 'order.number', width: 180 },
    { title: 'Quantité', field: 'quantityAndUnit', width: 200 },
    { title: 'Appels', field: 'movesCount', width: 80 },
    { title: 'Prix d\'achat', field: 'buy_price', type: 'number', decimalsCount: 5, numberUnit: ' €', textAlign: 'left', width: 130 },
    { title: 'Bénéfices', field: 'gain', type: 'number', decimalsCount: 5, numberUnit: ' €', textAlign: 'left', width: 130 }
  ];

  public stocksGridActions: any[] = [
    { iconClass: 'fa fa-plus', onClick: (event) => { this.showStockList(); } },
    { iconClass: 'fa fa-trash', onClick: (event) => { this.removeFromList(); } }
  ];


  ngOnInit() {
    let loadingPromises: any[] = [];
    this.accountingsData.items = AccountingsService.accountings;
    this.currenciesData.items = CurrenciesService.currencies;
    loadingPromises.push(
      Supplier.load(null, ['name']).then(
        (result) => { this.suppliersData.items = result; },
        (err) => { console.log(err); }
      ),
      Nomenclature.load(null, ['name']).then(
        (result: Nomenclature[]) => {
          this.nomenclaturesData.items = result.filter((value: Nomenclature, index, array) => {
            return value.name ? value.name.match(/^[0-9A-Z]{3}/) : false;
          });
        },
        (err) => { console.log(err); }
      ),
      RollingDirection.load(null, ['name']).then(
        (result: RollingDirection[]) => { this.rollingsData.items = result; },
        (err) => { console.log(err); }
      ),
      Stock.load().then(
        (result) => { this.stocksData.items = result; },
        (err) => { console.log(err); }
      )
    );
    Promise.all(loadingPromises).then(
      (result) => {
        this.articleBlock = ArticleInfoBlocks.generateArticleBlock(this.nomenclaturesData, this.rollingsData);
        this.deviceBlock = ArticleInfoBlocks.generateDeviceBlock(this.nomenclaturesData);
        this.serviceBlock = ArticleInfoBlocks.generateServiceBlock(this.nomenclaturesData);
      }
    );
  }

  initView(parentStock: ParentStock) {
    if (!parentStock) {
      parentStock = new ParentStock();
      parentStock.creation_date = DateTimeUtil.toDateString(new Date());
      parentStock.accounting = AccountingsService.currentAccounting;
    }
    this.parentStock = parentStock;
    this.parentStockCopy = parentStock.clone(true);
    for (let i = 0; i < this.parentStockCopy.stocks.length; ++i) this.parentStockCopy.stocks[i].parent = this.parentStockCopy;
    this.updateView(this.parentStockCopy);
  }

  updateView(stock: ParentStock) {
  }



  validateForm() {
    let form: Form = {
      fields: [
        // {
        //     name: 'Commande',
        //     type: 'foreign-list',
        //     data: this.stockParentCopy,
        //     field: 'order',
        //     pattern: null,
        //     element: this.selectBlockComponent.getElement('selectedOrder')
        // },
        // {
        //     name: 'Date',
        //     type: 'string',
        //     data: this.stockParentCopy,
        //     field: 'creation_date',
        //     pattern: /[0-9]{4}-[0-9]{2}-[0-9]{2}/,
        //     element: this.stockBlockComponent.getElement('creation_date')
        // },
        // {
        //     name: 'Client',
        //     type: 'foreign-list',
        //     data: this.stockParentCopy,
        //     field: 'customer',
        //     pattern: null,
        //     element: this.stockBlockComponent.getElement('customer')
        // },
        // {
        //     name: 'Fournisseur',
        //     type: 'foreign-list',
        //     data: this.stockParentCopy,
        //     field: 'supplier',
        //     pattern: null,
        //     element: this.stockBlockComponent.getElement('supplier')
        // },
        // {
        //     name: 'Quantité',
        //     type: 'string',
        //     data: this.stockParentCopy,
        //     field: 'quantity',
        //     pattern: /.*/,
        //     element: this.stockBlockComponent.getElement('quantity')
        // },
        // {
        //     name: 'Unité',
        //     type: 'string',
        //     data: this.stockParentCopy,
        //     field: 'unit',
        //     pattern: /.*/,
        //     element: this.stockBlockComponent.getElement('unit')
        // }
      ]
    };
    let result = FormValidator.validateForm(form);
    FormValidator.showFormInvalidFields(form, result);
    if (result !== true) {
      FormValidator.showNotification(result);
      return false;
    }
    else return true;
  }

  private _stocksToSave: Stock[] = [];

  save2() {
    return new Promise<any>((resolve, reject) => {
      let promises = [];
      let count = 0;
      for (let i = 0; i < this._stocksToSave.length; ++i) {
        promises.push(this._stocksToSave[i].save3(false).then(
          (result) => { ++count; },
          (err) => { console.error(err); }
        ));
      }
      Promise.all(promises).then(
        (results) => {
          this.parentStockCopy.save2().then(
            (result) => {
              this.parentStockCopy.clone(true, this.parentStock);
              this.initView(this.parentStock);
              resolve(result);
            },
            (err) => {
              reject(err);
            }
          );
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  addStock(stock: Stock) {
    if (!this.parentStockCopy.stocks.length) this.parentStockCopy.unit = stock.unit;
    this.parentStockCopy.stocks.push(stock);
    stock.parent = this.parentStockCopy;
  }

  removeStock(stock: Stock) {
    let index = this.parentStockCopy.stocks.indexOf(stock);
    if (index > 0) this.parentStockCopy.stocks.splice(index, 1);
    stock.parent = null;
    this._stocksToSave.push(stock);
  }

  private showStockList() {
    DialogsComponent.displayComponent(SelectStockDialogComponent, { stocks: this.getFilteredStocks() }, 'dialog-large').then(
      (result: Stock) => { if (result != null) this.addStock(result); },
      (err) => { console.error(err); }
    );
  }

  private removeFromList() {
    let sel = [...this.stocksGrid.selectedItems];
    for (let i = 0; i < sel.length; ++i) this.removeStock(sel[i]);
    this.stocksGrid.clearSelection();
  }

  private getFilteredStocks() {
    if (this.parentStockCopy.article != null) {
      return this.stocksData.items.filter((stock: Stock) => { return stock.article.fullId == this.parentStockCopy.article.fullId && stock.parent == null; });
    }
    else return this.stocksData.items.filter((stock: Stock) => { return stock.parent == null; });
  }
}
