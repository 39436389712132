import { ObjectModel2 } from "src/app/classes/objects/ObjectModel2.class";
import { ObjectDefinition } from "src/app/classes/objects/ObjectDefinition.class";
import { PaymentStatus } from "./PaymentStatus.class";
import { Invoice } from "../../invoicing/classes/Invoice.class";
import { Order } from "../../orders/classes/Order.class";
import { ServerApi } from "src/app/classes/api/ServerApi.class";
import { ObjectModel3 } from "src/app/classes/objects/ObjectModel3.class";
import { Supplier } from "../../suppliers/classes/Supplier.class";
import { Accounting } from "../../other-data/classes/Accounting.class";
import { CurrenciesService } from "src/app/services/currencies/currencies.service";
import { Currency } from "../../other-data/classes/Currency.class";


export class SupplierPayment extends ObjectModel3
{
    public accounting: Accounting = null;
    public currency: Currency = null;
    public xrate: number = 1;

    public number: string = null;
    public supplier: Supplier = null;

    public invoice_date: string = null;
    public supplier_reference: string = null;

    public amount: number = null;
    public total_to_pay: number = null;

    public get converted_amount() { return CurrenciesService.euroToCurrency(this.xrate || 1, this.amount); }
    public set converted_amount(value: number) { this.amount = CurrenciesService.currencyToEuro(this.xrate || 1, value); }
    public get converted_total_to_pay() { return CurrenciesService.euroToCurrency(this.xrate || 1, this.total_to_pay); }
    public set converted_total_to_pay(value: number) { this.total_to_pay = CurrenciesService.currencyToEuro(this.xrate || 1, value); }

    public invoice: Invoice = null;
    public get customer() { return this.invoice ? this.invoice.customer : null; }

    public supplier_limit: string = null;
    public get customer_limit() { return this.invoice ? this.invoice.expiration : null; }
    public get customer_status() { return this.invoice && this.invoice.payment_status ? this.invoice.payment_status : null; }

    public payment_date: string = null;
    public remarks: string = null;
    public get ended() { return this.payment_date != null && this.customer_status && this.customer_status.ended == 1 }

    public archived: number = 0;

    constructor()
    {
        super(SupplierPayment);
    }

    public getNumberPrefix(): string
    {
        return ("00" + (new Date().getFullYear() % 100)).slice(-2) + '-';
    }
    public getNextNumber()
    {
        return ServerApi.callModule('payments', 'supplierPaymentNextNumber', { 'number_prefix': this.getNumberPrefix(),
                                                                               'id_accounting': (this.accounting ? this.accounting.id : 0) });
    }
    public generateNumber(number: string): string
    {
        return this.getNumberPrefix() + ("0000" + number).slice(-4);
    }

    /* ObjectModel FUNCTIONS */

    // public static instances: { [id: number]: Address; } = {};

    public static definition: ObjectDefinition = {
        trashDelete: true,
        database: {
            table: 'supplier_payments',
            id: 'id',
			db_id: 'db_id'
        },
        values: {
            'xrate': { type: 'number' },
            'number': { type: 'string' },
            'invoice_date': { type: 'string' },
            'supplier_reference': { type: 'string' },
            'amount': { type: 'number' },
            'total_to_pay': { type: 'number' },
            'supplier_limit': { type: 'string' },
            'payment_date': { type: 'string' },
            'remarks': { type: 'string' },
            'archived': { type: 'number' },
        },
        children: {
            'accounting': { type: 'Accounting', clone: false, save: false, delete: false },
            'currency': { type: 'Currency', clone: false, save: false, delete: false },
            'invoice': { type: 'Invoice', clone: false, save: false, delete: false },
            'supplier': { type: 'Supplier', clone: false, save: false, delete: false }
        },
        links: {}
    };
}
