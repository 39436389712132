<div class="settings" [ngStyle]="{'background-color': headerBackColor, 'color': headerTextColor}">
    <div class="buttons">
        <button class="btn btn-default" (click)="clearFilters($event, null)" style="color:red;"><i class="fas fa-filter"></i></button>
        <button class="btn btn-default" (click)="toggleTotals()">f<sub>x</sub></button>
        <button class="btn btn-default" (click)="copyTable()"><i class="fas fa-copy"></i></button>
    </div>
    <label for="showAll">
        <input type="checkbox" id="showAll" [(ngModel)]="showAll" (change)="showAllChanged()" />
        <span>Afficher tout</span>
    </label>
    <label for="nbLines">
        <span>Lignes / page : </span>
        <select class="form-control" id="nbLines" [(ngModel)]="selectedPageSize" (change)="selectedPageSizeChanged()" [disabled]="showAll">
            <option *ngFor="let value of limitValues" [value]="value">{{value}}</option>
        </select>
    </label>
    <div class="table-pagination">
        <button class="btn btn-default" (click)="firstPage()" [disabled]="currentPage <= 0">&lt;&lt;</button>
        <button class="btn btn-default" (click)="prevPage()" [disabled]="currentPage <= 0">&lt;</button>
        <span>Page {{currentPage+1}}/{{pagesCount}}</span>
        <button class="btn btn-default" (click)="nextPage()" [disabled]="currentPage >= pagesCount-1">&gt;</button>
        <button class="btn btn-default" (click)="lastPage()" [disabled]="currentPage >= pagesCount-1">&gt;&gt;</button>
        <span *ngIf="items && filteredItems">({{ filteredItems.length }} enregistrements {{ filteredItems.length != items.length ? 'sur ' + items.length + ' trouvés' : 'au total' }})</span>
    </div>
    <div class="table-actions">
        <button *ngFor="let action of actions" class="btn btn-default" (click)="actionButtonClick(action, $event)">
            <i [class]="action.iconClass"></i>
        </button>
    </div>
</div>



<div class="table-container" #tableContainer [ngClass]="{'editable': editable == true, 'readonly': editable === false}">
    <div class="fixed-cell" #fixedCell>
        <table>
            <colgroup *ngIf="useColGroup == true">
                <col *ngIf="selectable === true" [ngStyle]="{'width': '30px'}" />
                <col *ngFor="let column of fixedHeaderColumns; let index = index" [ngStyle]="{'width': ((column.width || 150))+'px'}" />
            </colgroup>
            <tr *ngFor="let html of fixedPrependHeadersHtml" [innerHTML]="html"></tr>
            <tr class="header-row">
                <th class="selectionColumn" *ngIf="selectable === true">&nbsp;</th>
                <th *ngFor="let column of fixedHeaderColumns; let index = index"
                    [ngStyle]="{'background-color': column.headerBackColor, 'color': column.headerTextColor, 'width': ((column.width || 150))+'px' }"
                    [attr.colspan]="column.colspan || 1" [attr.rowspan]="column.rowspan || 1">
                    <span class="cell-content" [innerHTML]="column.title"></span>
                    <div class="filter-button" (click)="setFiltering(column, $event);" [ngClass]="{'active-filter': (filters[index] != null && filters[index].length > 0)}"></div>
                    <div *ngIf="resizable == true" class="resize-grip" (mousedown)="resizeGripCapture($event, column)"></div>
                </th>
            </tr>
            <tr *ngFor="let html of fixedAdditionalHeadersHtml" [innerHTML]="html"></tr>
            <tr class="totals-row" *ngIf="showTotals">
                <th class="selectionColumn" *ngIf="selectable === true">&nbsp;</th>
                <th *ngFor="let column of fixedColumns; let index=index;">
                    <select *ngIf="showTotalsFunctions" class="total-function" [(ngModel)]="columnTotalFunc[index]" (change)="calculateTotal(index, filteredItems, column)">
                        <option [ngValue]="null">(Aucune)</option>
                        <option *ngFor="let funcName of totalFunctionNames" [ngValue]="funcName">{{funcName}}</option>
                    </select>
                    <div class="total-result">{{ columnTotalFunc[index] && columnTotalValue[index] ? columnTotalValue[index] : '&nbsp;' }}</div>
                </th>
            </tr>
            <!-- <tr *ngFor="let html of additionalFixedHeadersHtml" [innerHTML]="html"></tr> -->
        </table>
    </div>
    <header #headerElem>
        <table>
            <colgroup *ngIf="useColGroup == true">
                <col *ngFor="let column of scrollableHeaderColumns; let index = index" [ngStyle]="{'width': ((column.width || 150))+'px'}" />
            </colgroup>
            <tr *ngFor="let html of prependHeadersHtml" [innerHTML]="html"></tr>
            <tr class="header-row">
                <th *ngFor="let column of scrollableHeaderColumns; let index = index"
                    [ngStyle]="{'background-color': column.headerBackColor, 'color': column.headerTextColor, 'width': ((column.width || 150))+'px' }"
                    [attr.colspan]="column.colspan || 1" [attr.rowspan]="column.rowspan || 1">
                    <span class="cell-content" [innerHTML]="column.title"></span>
                    <div class="filter-button" (click)="setFiltering(column, $event);" [ngClass]="{'active-filter': (filters[index] != null && filters[index].length > 0)}"></div>
                    <div *ngIf="resizable == true" class="resize-grip" (mousedown)="resizeGripCapture($event, column)"></div>
                </th>
            </tr>
            <tr *ngFor="let html of additionalHeadersHtml" [innerHTML]="html"></tr>
            <tr class="totals-row" *ngIf="showTotals">
                <th *ngFor="let column of scrollableColumns; let index=index;">
                    <select *ngIf="showTotalsFunctions" class="total-function" [(ngModel)]="columnTotalFunc[index+fixedColumnsCount]" (change)="calculateTotal(index+fixedColumnsCount, filteredItems, column)">
                        <option [ngValue]="null">(Aucune)</option>
                        <option *ngFor="let funcName of totalFunctionNames" [ngValue]="funcName">{{funcName}}</option>
                    </select>
                    <div class="total-result">{{ columnTotalFunc[index+fixedColumnsCount] && columnTotalValue[index+fixedColumnsCount] ? columnTotalValue[index+fixedColumnsCount] : '&nbsp;' }}</div>
                </th>
            </tr>
        </table>
    </header>
    <aside #asideElem>
        <table>
            <tr *ngFor="let item of pagedFilteredItems" (mouseover)="hoverItem = item" (mouseout)="hoverItem = null" (click)="itemRowClick($event, item)"
                [ngClass]="{'hover': hoverItem == item, 'selected': selectedItems.includes(item) }" [ngStyle]="rowStyleFunction ? rowStyleFunction(item, true) : null">
                <td class="selectionColumn" (click)="selectionCheckboxClick($event, item)" *ngIf="selectable === true"
                    [ngStyle]="{'background-color': hoverItem == item ? 'lightblue' : '' }">
                    <input type="checkbox" [ngModel]="selectedItems.includes(item)" (click)="selectionCheckboxClick($event, item)" />
                </td>
                <td *ngFor="let column of fixedColumns; let index = index" [attr.data-field]="column.field" class="{{column.type ? column.type : ''}}"
                    [ngClass]="{'readonly': column.readonly }" [attr.colspan]="column.colspan || 1"
                    [ngStyle]="{'background-color': column.backColor, 'color': column.textColor, 'max-width': ((column.width || 150))+'px', 'width': ((column.width || 150))+'px', 'font-size': column.fontSize, 'font-weight': column.fontWeight }">
                    <div *ngIf="!editable || column.readonly === true" class="cell-content">
                        <span *ngIf="column.type == 'text' || !column.type">{{getItemValue(item, column)}}</span>
                        <span *ngIf="column.type == 'email'"><a href="mailto:{{getItemValue(item, column)}}">{{getItemValue(item, column)}}</a></span>
                        <span *ngIf="column.type == 'date'">{{formatDate(getItemValue(item, column))}}</span>
                        <span *ngIf="column.type == 'foreign-list'">{{getItemValue(item, column) || (column.nullText || '(Aucun)')}}</span>
                        <number-field *ngIf="column.type == 'number' && item[column.field]" [(ngModel)]="item[column.field]" [decimalsCount]="column.decimalsCount" [unit]="column.unit" [negativeColor]="column.negativeColor" readonly="true" [currency]="column.currencyField ? item[column.currencyField] : null"></number-field>
                        <span *ngIf="column.type == 'checkbox'" class="checkbox-icon"><i class="far fa-{{item[column.field] == true ? 'check-' : ''}}square"></i></span>
                        <span *ngIf="column.type == 'checkbox'" class="checkbox-label" (click)="checkboxLabelClick($event, item, column)">{{ item[column.field] == true ? (column.checkedValue ? column.checkedValue : 'Oui') : ((column.uncheckedValue ? column.uncheckedValue : 'Non')) }}</span>
                    </div>
                    <div *ngIf="(editable == true && !column.readonly)" class="cell-input">
                        <span *ngIf="column.type == 'email'"><a href="mailto:{{getItemValue(item, column)}}">{{getItemValue(item, column)}}</a></span>
                        <input *ngIf="column.type == 'text' || !column.type" type="text" [(ngModel)]="item[column.field]" (change)="callColumnFunc('change', item, column)" />
                        <input *ngIf="column.type == 'date'" type="date" [(ngModel)]="item[column.field]" (change)="callColumnFunc('change', item, column)" />
                        <number-field *ngIf="column.type == 'number'" [(ngModel)]="item[column.field]" [decimalsCount]="column.decimalsCount" [unit]="column.unit"  [negativeColor]="column.negativeColor" (change)="callColumnFunc('change', item, column)" [currency]="column.currencyField ? item[column.currencyField] : null"></number-field>
                        <!-- <number-field *ngIf="column.type == 'percentage'" [(ngModel)]="item[column.field]" unit=" %" decimalsCount="1" [calculateButton]="column.calculateButton" (calculate)="calculate(item, column)" (change)="callColumnFunc('change', item, column)"></number-field> -->
                        <div *ngIf="column.type == 'color'" [ngStyle]="{'background-color': item[column.field]}" class="color-input" (click)="colorClick(item, column.field)"></div>
                        <foreign-list *ngIf="column.type == 'foreign-list' && column.listItems != null" [(ngModel)]="item[column.field]" [multiSelect]="column.multiSelect" [objects]="column.listItems.items" [fieldName]="column.listField" [nullText]="column.nullText" [allowNull]="column.allowNull" (change)="callColumnFunc('change', item, column)" [getOptionStyle]="column.getOptionStyle"></foreign-list>
                        <!-- (mousedown)="callFieldFunc('mousedown', block, field, $event)" (change)="callFieldFunc('change', block, field, $event)"  -->
                            <!-- <input [(colorPicker)]="item[column.field]" [style.background]="item[column.field]" [cpDialogDisplay]="'popup'"/> -->
                        <!-- </div> -->
                        <input *ngIf="column.type == 'checkbox'" type="checkbox" [(ngModel)]="item[column.field]" (change)="callColumnFunc('change', item, column)" />
                        <span *ngIf="column.type == 'checkbox'" class="checkbox-label" (click)="checkboxLabelClick($event, item, column)">{{ item[column.field] == true ? (column.checkedValue ? column.checkedValue : 'Oui') : ((column.uncheckedValue ? column.uncheckedValue : 'Non')) }}</span>
                    </div>
                </td>
            </tr>
        </table>
    </aside>
    <div class="table-body" #tableBody>
        <table>
            <tr *ngFor="let item of pagedFilteredItems" (mouseover)="hoverItem = item" (mouseout)="hoverItem = null" (click)="itemRowClick($event, item)"
                [ngClass]="{'hover': hoverItem == item, 'selected': selectedItems.includes(item) }" [ngStyle]="rowStyleFunction ? rowStyleFunction(item, false) : null">
                <td *ngFor="let column of scrollableColumns" [attr.data-field]="column.field" class="{{column.type ? column.type : ''}}"
                    [ngClass]="{'readonly': column.readonly }" [attr.colspan]="column.colspan || 1"
                    [ngStyle]="{'background-color': column.backColor, 'color': column.textColor, 'max-width': ((column.width || 150))+'px', 'width': ((column.width || 150))+'px', 'font-size': column.fontSize, 'font-weight': column.fontWeight }">
                    <div *ngIf="!editable || column.readonly === true" class="cell-content">
                        <span *ngIf="column.type == 'text' || !column.type">{{getItemValue(item, column)}}</span>
                        <span *ngIf="column.type == 'email'"><a href="mailto:{{getItemValue(item, column)}}">{{getItemValue(item, column)}}</a></span>
                        <span *ngIf="column.type == 'date'">{{formatDate(getItemValue(item, column))}}</span>
                        <span *ngIf="column.type == 'foreign-list'">{{getItemValue(item, column) || (column.nullText || '(Aucun)')}}</span>
                        <number-field *ngIf="column.type == 'number' && item[column.field]" [(ngModel)]="item[column.field]" [decimalsCount]="column.decimalsCount" [unit]="column.unit" [negativeColor]="column.negativeColor" readonly="true" [currency]="column.currencyField ? item[column.currencyField] : null"></number-field>
                        <span *ngIf="column.type == 'checkbox'" class="checkbox-icon"><i class="far fa-{{item[column.field] == true ? 'check-' : ''}}square"></i></span>
                        <span *ngIf="column.type == 'checkbox'" class="checkbox-label" (click)="checkboxLabelClick($event, item, column)">{{ item[column.field] == true ? (column.checkedValue ? column.checkedValue : 'Oui') : ((column.uncheckedValue ? column.uncheckedValue : 'Non')) }}</span>
                    </div>
                    <div *ngIf="(editable == true && !column.readonly)" class="cell-input">
                        <span *ngIf="column.type == 'email'"><a href="mailto:{{getItemValue(item, column)}}">{{getItemValue(item, column)}}</a></span>
                        <input *ngIf="column.type == 'text' || !column.type" type="text" [(ngModel)]="item[column.field]" (change)="callColumnFunc('change', item, column)" />
                        <input *ngIf="column.type == 'date'" type="date" [(ngModel)]="item[column.field]" (change)="callColumnFunc('change', item, column)" />
                        <number-field *ngIf="column.type == 'number'" [(ngModel)]="item[column.field]" [decimalsCount]="column.decimalsCount" [unit]="column.unit"  [negativeColor]="column.negativeColor" (change)="callColumnFunc('change', item, column)" [currency]="column.currencyField ? item[column.currencyField] : null"></number-field>
                        <!-- <number-field *ngIf="column.type == 'percentage'" [(ngModel)]="item[column.field]" unit=" %" decimalsCount="1" [calculateButton]="column.calculateButton" (calculate)="calculate(item, column)" (change)="callColumnFunc('change', item, column)"></number-field> -->
                        <div *ngIf="column.type == 'color'" [ngStyle]="{'background-color': item[column.field]}" class="color-input" (click)="colorClick(item, column.field)"></div>
                        <foreign-list *ngIf="column.type == 'foreign-list' && column.listItems != null" [(ngModel)]="item[column.field]" [multiSelect]="column.multiSelect" [objects]="column.listItems.items" [fieldName]="column.listField" [nullText]="column.nullText" [allowNull]="column.allowNull" (change)="callColumnFunc('change', item, column)" [getOptionStyle]="column.getOptionStyle"></foreign-list>
                        <!-- (mousedown)="callFieldFunc('mousedown', block, field, $event)" (change)="callFieldFunc('change', block, field, $event)"  -->
                            <!-- <input [(colorPicker)]="item[column.field]" [style.background]="item[column.field]" [cpDialogDisplay]="'popup'"/> -->
                        <!-- </div> -->
                        <input *ngIf="column.type == 'checkbox'" type="checkbox" [(ngModel)]="item[column.field]" (change)="callColumnFunc('change', item, column)" />
                        <span *ngIf="column.type == 'checkbox'" class="checkbox-label" (click)="checkboxLabelClick($event, item, column)">{{ item[column.field] == true ? (column.checkedValue ? column.checkedValue : 'Oui') : ((column.uncheckedValue ? column.uncheckedValue : 'Non')) }}</span>
                    </div>
                </td>
                <td class="rowActionsColumn" *ngIf="rowActions != null && rowActions.length > 0" (click)="rowActionsItem = item">
                    <i class="fas fa-ellipsis-h"></i>
                    <ul class="row-actions-list" *ngIf="rowActionsItem == item">
                        <li *ngFor="let action of rowActions" class="row-actions-item" (click)="rowActionClick($event, action, item)">
                            <i *ngIf="action.icon" class="fa {{ action.icon ? 'fa-'+action.icon : ''}}"></i>{{ action.name }}
                        </li>
                    </ul>
                </td>
            </tr>
        </table>
    </div>
</div>

<div #filterElem *ngIf="filterColumn" class="filter-popup-content">
    <div class="filter-popup-item" (click)="sortByClick($event, filterColumn, false)">
        <img src="assets/img/icons/20x20/dark-blue/sort-alpha-asc.png" class="normal-icon" />
        <img src="assets/img/icons/20x20/light-blue/sort-alpha-asc-white.png" class="hover-icon" />
        <span>Trier de A à Z</span>
    </div>
    <div class="filter-popup-item" (click)="sortByClick($event, filterColumn, true)">
        <img src="assets/img/icons/20x20/dark-blue/sort-alpha-desc.png" class="normal-icon" />
        <img src="assets/img/icons/20x20/light-blue/sort-alpha-desc-white.png" class="hover-icon" />
        <span>Trier de Z à A</span>
    </div>
    <div class="filter-popup-item" (click)="clearFilters($event, filterColumn)">
        <i class="fa fa-filter" style="color: red"></i>
        <span>Effacer les filtres sur cette colonne</span>
    </div>
    <input type="text" [(ngModel)]="uniqueFilter[filterColumnIndex]" (keyup)="getUniqueValues(filterColumnIndex, true)" />
    <div class="filter-popup-list">
        <div (click)="toggleAllFilter(filterColumnIndex, $event)">
            <label for="filter-popup-value_all">
                <input type="checkbox" id="filter-popup-value_all" [ngModel]="filtersAll[filterColumnIndex]" />
                <span>(Sélectionner tout)</span>
            </label>
        </div>
        <div *ngFor="let value of getUniqueValues(filterColumnIndex); let index2 = index" (click)="toggleFilter(filterColumnIndex, value, $event)">
            <label for="filter-popup-value_{{index2}}">
                <input type="checkbox" id="filter-popup-value_{{index2}}" [ngModel]="filters[filterColumnIndex] ? filters[filterColumnIndex].includes(value) : false" />
                <span>{{ !value || value == '' ? '(Vides)' : value }}</span>
            </label>
        </div>
    </div>
</div>




<div *ngIf="copyMode" #copyElement class="copy-element" style="float: left; opacity: 1;">

    <table>
        <tr class="header-row">
            <!-- <th class="selectionColumn" *ngIf="selectable === true">&nbsp;</th> -->
            <th *ngFor="let column of (headerColumns && headerColumns.length ? headerColumns : columns); let index = index"
                [ngStyle]="{'background-color': column.headerBackColor, 'color': column.headerTextColor, 'width': ((column.width || 150))+'px' }"
                [attr.colspan]="column.colspan || 1" [attr.rowspan]="column.rowspan || 1">
                <span class="cell-content">{{ column.title }}</span>
                <div class="filter-button" (click)="setFiltering(column, $event);" [ngClass]="{'active-filter': (filters[index] != null && filters[index].length > 0)}"></div>
                <div *ngIf="resizable == true" class="resize-grip" (mousedown)="resizeGripCapture($event, column)"></div>
            </th>
        </tr>
        <tr *ngFor="let html of copyPrependHeadersHtml" [innerHTML]="html"></tr>
        <tr *ngFor="let html of copyAdditionalHeadersHtml" [innerHTML]="html"></tr>
        <tr class="totals-row" *ngIf="showTotals">
            <!-- <th class="selectionColumn">&nbsp;</th> -->
            <th *ngFor="let column of columns; let index=index;">
                <select *ngIf="showTotalsFunctions" class="total-function" [(ngModel)]="columnTotalFunc[index]" (change)="calculateTotal(index, filteredItems, column)">
                    <option [ngValue]="null">(Aucune)</option>
                    <option *ngFor="let funcName of totalFunctionNames" [ngValue]="funcName">{{funcName}}</option>
                </select>
                <div class="total-result">{{ columnTotalFunc[index] && columnTotalValue[index] ? columnTotalValue[index] : '&nbsp;' }}</div>
            </th>
        </tr>

        <tr *ngFor="let item of pagedFilteredItems" (mouseover)="hoverItem = item" (mouseout)="hoverItem = null" (click)="itemRowClick($event, item)"
            [ngClass]="{'hover': hoverItem == item, 'selected': selectedItems.includes(item) }" [ngStyle]="rowStyleFunction ? rowStyleFunction(item) : null">
            <!-- <td class="selectionColumn" (click)="selectionCheckboxClick($event, item)" *ngIf="selectable === true"
                [ngStyle]="{'background-color': hoverItem == item ? 'lightblue' : '' }"
                [style.paddingTop.px]="rowActions && rowActions.length ? 7 : 0" [style.paddingBottom.px]="rowActions && rowActions.length ? 6 : 0">
                <input type="checkbox" [ngModel]="selectedItems.includes(item)" (click)="selectionCheckboxClick($event, item)" />
            </td> -->
            <td *ngFor="let column of columns; let index = index" [attr.data-field]="column.field" class="{{column.type ? column.type : ''}}"
                [ngClass]="{'readonly': column.readonly }" [attr.colspan]="column.colspan || 1"
                [ngStyle]="{'background-color': column.backColor, 'color': column.textColor, 'width': ((column.width || 150))+'px' }">
                <div class="cell-content" [style.width]="((column.width || 150))+'px'">
                    <span *ngIf="column.type == 'text' || !column.type">{{getItemValue(item, column)}}</span>
                    <span *ngIf="column.type == 'date' || column.type == 'foreign-list'">{{getItemValue(item, column) || (column.nullText || '(Aucun)')}}</span>
                    <span *ngIf="column.type == 'number'">{{getItemNumber(item, column, column.decimalsCount)}}</span>
<!--                    <number-field *ngIf="column.type == 'number' && item[column.field]" [(ngModel)]="item[column.field]" [decimalsCount]="column.decimalsCount" [unit]="column.unit" [negativeColor]="column.negativeColor" readonly="true" [currency]="column.currencyField ? item[column.currencyField] : null"></number-field>-->
                    <span *ngIf="column.type == 'checkbox'" class="checkbox-icon"><i class="far fa-{{item[column.field] == true ? 'check-' : ''}}square"></i></span>
                    <span *ngIf="column.type == 'checkbox'" class="checkbox-label" (click)="checkboxLabelClick($event, item, column)">{{ item[column.field] == true ? (column.checkedValue ? column.checkedValue : 'Oui') : ((column.uncheckedValue ? column.uncheckedValue : 'Non')) }}</span>
                </div>
            </td>
        </tr>
    </table>
</div>




<div class="resize-overlay" *ngIf="resizing" (mouseup)="resizeGripRelease($event)" (mousemove)="resizeGripMove($event)"></div>
<div class="filter-overlay" *ngIf="filterColumn != null" (click)="filterColumn = null"></div>
<div class="row-actions-overlay" *ngIf="rowActionsItem != null" (click)="rowActionsItem = null"></div>
