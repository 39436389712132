import { Component, OnInit, ViewChild } from '@angular/core';

import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { ArrayUtil } from 'src/app/modules/utils/classes/ArrayUtil.class';
import { DataGridComponent } from 'src/app/components/data-grid/data-grid.component';
import { DataGridRowAction } from '../../../../components/data-grid/classes/DataGridRowAction.class';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { Order } from '../../../orders/classes/Order.class';
import { Stock } from '../../classes/Stock.class';
import { StockMove } from '../../classes/StockMove.class';
import { StockViewComponent } from '../stock-view/stock-view.component';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from 'src/app/components/views/views.component';

@Component({
  selector: 'app-stocks-list-view',
  templateUrl: './stocks-list-view.component.html',
  styleUrls: ['./stocks-list-view.component.css'],
})
export class StocksListViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_stocks_listestocks';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Liste des stocks',
    data: this,
    elements: [
      {
        type: 'button',
        text: 'Ajouter<br/>un stock',
        icon: 'plus',
        click: function (view: StocksListViewComponent) {
          view.createStock();
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Supprimer',
        icon: 'times',
        click: function (view: StocksListViewComponent) {
          view.deleteStocks();
        },
        access: this.writeAccess,
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Archiver',
        icon: 'archive',
        click: function (view: StocksListViewComponent) {
          view.archiveStocks();
        },
        access: this.writeAccess,
      },
      { type: 'separator-large' },
      {
        type: 'toggle-button',
        text: 'Afficher les<br/>éléments archivés',
        icon: 'filter',
        value: false,
        click: function (view: StocksListViewComponent) {
          view.filterStocks = !this.value;
          if (view.filterStocks) view.grid.clearSelection();
        },
      },
      { type: 'spacing' },
    ],
  };

  @ViewChild('grid') grid: DataGridComponent;
  public gridRowActions: DataGridRowAction[] = [
    {
      name: 'Afficher',
      icon: 'search',
      click: (event: any, item: any) => {
        this.stockDblClick(event, item);
      },
    },
  ];

  public filterStocks: boolean = true;
  public stocks: Stock[] = [];
  public stocksGridColumns = [
    { title: 'Désignation', field: 'article.designation', width: 550 },
    { title: 'Client', field: 'customer.nameWithIdentifier', width: 200 },
    { title: 'Fournisseur', field: 'supplier.name', width: 200 },
    { title: 'Quantité', field: 'stockAndUnit', width: 250 },
    { title: 'Date', field: 'creation_date', type: 'date', width: 80 },
    { title: 'Commande', field: 'order.number', width: 150 },
    { title: 'Appels', field: 'movesCount', width: 80 },
    { title: 'Remarques', field: 'remarks', width: 400 },
  ];

  ngOnInit() {
    this.reloadStocks();
  }
  onAccountingChanged(accounting: Accounting) {
    this.reloadStocks();
  }

  reloadStocks() {
    let conditions: string = null;
    if (AccountingsService.currentAccounting) conditions = 'id_accounting=' + AccountingsService.currentAccounting.id;
    Stock.load(null, null, null, false, conditions).then(
      (result) => {
        this.stocks = result;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  public get filteredStocks(): Stock[] {
    if (!this.filterStocks) return this.stocks;
    else
      return this.stocks
        ? this.stocks.filter((value: Stock, index: number, array: Stock[]) => !value.archived || value.archived != 1)
        : [];
  }

  createStock() {
    let stock: Stock = new Stock();
    stock.accounting = AccountingsService.currentAccounting;
    this.stocks.push(stock);
    this.stockDblClick(null, stock);
  }

  deleteStocks() {
    DialogsComponent.display({
      icon: 'warning',
      title: 'Supprimer les dossiers ?',
      message: 'Êtes-vous sûr(e) de vouloir supprimer les dossiers sélectionnés ?',
      buttons: DialogButton.yesNoButtons,
    }).then((result: any) => {
      if (result === DialogButton.RESULT_YES) {
        let stocks: Stock[] = this.grid.selectedItems as Stock[];
        if (Array.isArray(stocks) && stocks.length > 0) {
          let promises: any[] = [];
          for (let i = 0; i < stocks.length; ++i) promises.push(stocks[i].moveToTrash());
          Promise.all(promises).then(
            (result) => {
              NotificationsComponent.push({
                type: 'success',
                title: 'Dossiers clients supprimés',
                summary: 'Les dossiers clients ont été supprimées avec succès',
              });
              ArrayUtil.removeElements(this.stocks, stocks);
            },
            (err) => {
              NotificationsComponent.push({
                type: 'error',
                title: 'Erreur lors de la suppression',
                summary: "Une erreur s'est produite lors de la suppression",
                content: err,
              });
              console.error(err);
            }
          );
        }
      }
    });
  }

  stockDblClick(event, stock) {
    ViewsComponent.openView(StockViewComponent, stock);
  }

  archiveStocks() {
    let stocks: Stock[] = this.grid.selectedItems as Stock[];
    if (Array.isArray(stocks) && stocks.length > 0) {
      let promises: any[] = [];
      for (let i = 0; i < stocks.length; ++i) {
        stocks[i].archived = 1;
        promises.push(stocks[i].save2());
      }
      Promise.all(promises).then(
        (result) => {
          NotificationsComponent.push({
            type: 'success',
            title: 'Stocks archivés',
            summary: 'Les stocks ont été archivés avec succès',
          });
          this.grid.clearSelection();
          // ArrayUtil.removeElements(this.orders, orders);
        },
        (err) => {
          NotificationsComponent.push({
            type: 'error',
            title: "Erreur lors de l'archivage",
            summary: "Une erreur s'est produite lors de l'archivage des stocks",
            content: err,
          });
          console.error(err);
        }
      );
    }
  }

  gridRowStyle(item: Stock) {
    return item && item.archived == 1
      ? {
          color: 'gray',
          'font-style': 'italic',
        }
      : null;
  }
}
