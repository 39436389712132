<form class="form-horizontal">
    <div class="form-group row" *ngFor="let setting of settings; let index=index">
        <label class="control-label col-lg-3" for="setting_{{index}}">{{setting.title}}</label>
        <div class="col-lg-9">
            <input *ngIf="setting.type == 'number'" type="number" name="setting_{{index}}" id="setting_{{index}}" [(ngModel)]="setting.value" />
            <select *ngIf="setting.type == 'list'" [(ngModel)]="setting.value" name="setting_{{index}}">
                <option *ngFor="let item of setting.listItems" [ngValue]="item">{{item[setting.listField]}}</option>
            </select>
        </div>
    </div>
    <div class="buttons">
        <button class="btn btn-primary" (click)="sendDialogResult(settings)">OK</button>
        <button class="btn btn-default" (click)="sendDialogResult(null)">Cancel</button>
    </div>
</form>
