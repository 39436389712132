import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { Component } from '@angular/core';
import { CurrenciesService } from 'src/app/services/currencies/currencies.service';
import { DateTimeUtil } from 'src/app/modules/utils/classes/DateTimeUtil.class';
import { Deadline } from 'src/app/modules/other-data/classes/Deadline.class';
import { Delivery } from 'src/app/modules/other-data/classes/Delivery.class';
import { DeliveryTime } from 'src/app/modules/other-data/classes/DeliveryTime.class';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { Discount } from 'src/app/modules/other-data/classes/Discount.class';
import { FilesystemUtil } from 'src/app/modules/utils/classes/FilesystemUtil.class';
import { InfoBlock } from 'src/app/components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from 'src/app/components/info-block/info-block.component';
import { InfoBlockField } from 'src/app/components/info-block/classes/InfoBlockField.class';
import { Invoice } from '../../classes/Invoice.class';
import { InvoicePrintingModel } from '../../classes/InvoicePrintingModel.class';
import { Merchant } from 'src/app/modules/other-data/classes/Merchant.class';
import { Order } from 'src/app/modules/orders/classes/Order.class';
import { PrintPreviewViewComponent } from 'src/app/modules/printing/views/print-preview-view/print-preview-view.component';
import { PrintingModel } from 'src/app/modules/printing/classes/PrintingModel.class';
import { SettingsComponent } from 'src/app/components/settings/settings.component';
import { StockMove } from 'src/app/modules/stocks/classes/StockMove.class';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from 'src/app/components/views/views.component';
import { config } from '../../../../classes/config';

@Component({
  selector: 'app-invoice-view',
  templateUrl: './invoice-view.component.html',
  styleUrls: ['./invoice-view.component.css'],
})
export class InvoiceViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_facturation_fichefacture';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Facture commerciale',
    data: this,
    elements: [
      { type: 'separator' },
      {
        type: 'button',
        name: 'newInvoiceButton',
        text: 'Nouvelle<br/>facture',
        icon: ' far fa-file',
        click: function (view: InvoiceViewComponent) {
          DialogsComponent.display({
            title: 'Créer une nouvelle facture ?',
            message: 'Toute modification non enregistrée sera perdue !<br/>Êtes-vous sûr(e) de vouloir continuer ?',
            icon: 'question',
            buttons: DialogButton.yesNoButtons,
          }).then((result: any) => {
            if (result == DialogButton.RESULT_YES) {
              view.ngOnInit();
              view.initView(null);
            }
          });
        },
        visible: true,
        access: this.writeAccess,
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Enregistrer<br/>(ERP + PDF)',
        icon: 'save',
        click: function (view: InvoiceViewComponent) {
          view.save().then((result) => {
            view.updateView(view.invoiceCopy);
            view.saveFile();
          });
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Aperçu avant<br/>impression',
        icon: 'search',
        click: function (view: InvoiceViewComponent) {
          view.openPreview();
        },
      },
      { type: 'spacing' },
    ],
  };

  public invoice: Invoice = null;
  public invoiceCopy: Invoice = null;
  public model: InvoicePrintingModel = null;

  public merchantsData: any = { items: [] };
  public pricesData: any = { items: [] };
  public ordersData: any = { items: [] };
  public discountsData: any = { items: [] };
  public deliveriesData: any = { items: [] };
  public deliveryTimeData: any = { items: [] };
  public deadlineData: any = { items: [] };
  public accountingsData: any = { items: [] };
  public currenciesData: any = { items: [] };
  public selectedObject: Order | StockMove = null;

  public selectBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(0,0,0)',
    textColor: 'white',
    fields: [
      {
        title: "N° de l'accusé de réception",
        field: 'selectedObject',
        type: 'searchable-foreign-list',
        multiSelect: false,
        listItems: this.ordersData,
        listField: 'numberAndName',
        nullText: '(Aucun)',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.invoiceCopy.number = null;
          this.updateView(this.invoiceCopy);
        },
      },
    ],
  };
  public quantityField: InfoBlockField = {
    title: 'Quantité',
    type: 'number',
    field: 'quantity',
    decimalsCount: 0,
    numberUnit: '',
    textAlign: 'left',
    change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
      this.updateView(this.invoiceCopy);
    },
  };
  public invoiceBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(189, 74, 72)',
    textColor: 'white',
    fields: [
      {
        title: 'Date',
        type: 'date',
        field: 'date',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.invoiceCopy);
        },
      },
      {
        title: 'Représentant',
        field: 'merchant',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.merchantsData,
        listField: 'numberAndName',
        nullText: '(Aucun)',
      },
      {
        title: 'Proforma',
        type: 'checkbox',
        field: 'proforma',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.invoiceCopy);
        },
      },
      { title: 'Numéro', type: 'text', field: 'number', readonly: true },
      this.quantityField,
      {
        title: 'Taux TVA',
        field: 'vat',
        type: 'number',
        decimalsCount: 1,
        numberUnit: '%',
        textAlign: 'left',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.invoiceCopy);
        },
      },
      {
        title: 'Escompte de ...',
        field: 'discount',
        type: 'number',
        decimalsCount: 1,
        numberUnit: '%',
        textAlign: 'left',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.invoiceCopy);
        },
      },
      {
        title: '... si paiment dans les',
        field: 'discount_days',
        type: 'number',
        decimalsCount: 0,
        numberUnit: 'jours',
        textAlign: 'left',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.invoiceCopy);
        },
      },
    ],
  };

  public saleBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(149, 55, 53)',
    textColor: 'white',
    fields: [
      { title: 'Fournisseur', field: 'order.supplier.name', readonly: true },
      { title: "N° de l'offre", field: 'order.sale.number', readonly: true },
      { title: 'Représentant', type: 'text', field: 'order.merchant.numberAndName', readonly: true },
    ],
  };

  public deliveryBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(109, 35, 33)',
    textColor: 'white',
    fields: [
      {
        title: 'Texte de livraison',
        field: 'delivery',
        type: 'combo-box',
        disableFiltering: true,
        listItems: this.deliveriesData,
        listField: 'description',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.invoiceCopy);
        },
      },
      {
        title: 'Date de livraison',
        field: 'delivery_date',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.invoiceCopy);
        },
      },
      {
        title: 'Conditions de paiement',
        field: 'deadline',
        type: 'combo-box',
        readonly: false,
        multiSelect: false,
        listItems: this.deadlineData,
        listField: 'name',
        nullText: '(Aucun)',
        disableFiltering: true,
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.invoiceCopy);
        },
      },
    ],
  };

  public accountingBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(64,0,64)',
    textColor: 'white',
    fields: [
      {
        title: 'Comptabilité',
        field: 'accounting',
        type: 'foreign-list',
        readonly: true,
        multiSelect: false,
        listItems: this.accountingsData,
        listField: 'fullDescription',
        allowBlankValues: false,
        comment: 'Sélectionnez ici la comptabilité liée à ce dossier',
      },
      {
        title: "Devise d'achat",
        field: 'buy_currency',
        type: 'foreign-list',
        readonly: true,
        multiSelect: false,
        listItems: this.currenciesData,
        listField: 'fullDescription',
        allowBlankValues: false,
      },
      {
        title: "Taux de change d'achat",
        field: 'buy_xrate',
        type: 'number',
        decimalsCount: 6,
        textAlign: 'left',
        readonly: true,
      },
      {
        title: 'Devise de vente',
        field: 'sell_currency',
        type: 'foreign-list',
        readonly: true,
        multiSelect: false,
        listItems: this.currenciesData,
        listField: 'fullDescription',
        allowBlankValues: false,
      },
      {
        title: 'Taux de change de vente',
        field: 'sell_xrate',
        type: 'number',
        decimalsCount: 6,
        textAlign: 'left',
        readonly: true,
      },
    ],
  };

  public invoiceRemarksBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(78, 12, 10)',
    textColor: 'white',
    fields: [
      {
        title: 'Remarques pour la facture',
        field: 'remarks',
        type: 'textarea',
        comment:
          'Entrez ici les remarques que vous voulez faire apparaître sur la <b>FACTURE</b>, dans le cadre Remarques',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.invoiceCopy);
        },
      },
    ],
  };
  public saleRemarksBlock: InfoBlock = {
    title: 'Remarques\ndossier',
    backColor: 'rgb(226, 107, 10)',
    textColor: 'white',
    fields: [
      {
        title: 'Remarques pour les demandes de prix',
        field: 'demand_remarks',
        type: 'textarea',
        comment:
          'Entrez ici les remarques que vous voulez faire apparaître sur les demandes de prix pour les fournisseurs, dans le cadre Remarques',
      },
      {
        title: "Remarques pour l'offre de prix",
        field: 'offer_remarks',
        type: 'textarea',
        comment:
          "Entrez ici les remarques que vous voulez faire apparaître sur l'offre de prix pour le client, dans le cadre Remarques",
      },
    ],
  };

  public get invoiceCopyArticleIsArticle() {
    return this.invoiceCopyArticle && this.invoiceCopyArticle.type === 0;
  }
  public get invoiceCopyArticleIsDevice() {
    return this.invoiceCopyArticle && this.invoiceCopyArticle.type === 1;
  }
  public get invoiceCopyArticle() {
    if (this.invoiceCopy && this.invoiceCopy.order && this.invoiceCopy.order.sale)
      return this.invoiceCopy.order.sale.article;
    return null;
  }

  ngOnInit() {
    this.accountingsData.items = AccountingsService.accountings;
    this.currenciesData.items = CurrenciesService.currencies;
    Merchant.load(null, ['number']).then(
      (result: Merchant[]) => {
        this.merchantsData.items = result;
      },
      (err) => {
        console.error('merchants loading error:', err);
      }
    );
    Discount.load(null, ['name']).then(
      (result) => {
        this.discountsData.items = result;
      },
      (err) => {
        console.error(err);
      }
    );
    Delivery.load(null, ['description']).then(
      (result) => {
        this.deliveriesData.items = result;
      },
      (err) => {
        console.log(err);
      }
    );
    DeliveryTime.load(null, ['name']).then(
      (result) => {
        this.deliveryTimeData.items = result;
      },
      (err) => {
        console.log(err);
      }
    );
    Deadline.load(null, ['name']).then(
      (result) => {
        this.deadlineData.items = result;
      },
      (err) => {
        console.log(err);
      }
    );
    this.reloadOrders();
  }

  public logisticsView: boolean = false;
  initView(invoice: Invoice, logisticsView: boolean = false) {
    this.logisticsView = logisticsView;
    if (logisticsView) {
      this.invoiceBlock.backColor = 'rgb(60, 95, 189)';
      this.saleBlock.backColor = 'rgb(40, 65, 149)';
      this.deliveryBlock.backColor = 'rgb(20, 35, 109)';
      this.invoiceRemarksBlock.backColor = 'rgb(60, 95, 189)';
      this.toolbar.viewTitle = 'Facture logistique';
    }
    if (!invoice) {
      invoice = new Invoice();
      invoice.accounting = AccountingsService.currentAccounting;
    }
    this.invoice = invoice;
    this.invoiceCopy = invoice.clone(true);
    if (this.invoiceCopy.id == 0) this.reloadOrders();
    this.selectedObject = this.invoiceCopy.order;
    this.updateView(this.invoiceCopy);
  }

  onAccountingChanged(accounting: Accounting) {
    this.reloadOrders();
  }

  reloadOrders() {
    if (this.logisticsView) {
      StockMove.load(
        null,
        ['number'],
        null,
        false,
        (AccountingsService.currentAccounting
          ? 'id_accounting=' + AccountingsService.currentAccounting.id + ' AND '
          : '') +
          "number NOT LIKE '%BEL0000%' AND " +
          '1'
      ) //id_invoice IS NULL')
        .then(
          (result: StockMove[]) => {
            this.ordersData.items = result.filter((value: StockMove, index, arr) => value.remaining_amount > 0);
          },
          (err) => {
            console.error(err);
          }
        );
    } else {
      Order.load(
        null,
        ['number'],
        null,
        false,
        (AccountingsService.currentAccounting
          ? 'id_accounting=' + AccountingsService.currentAccounting.id + ' AND '
          : '') +
          "number NOT LIKE '%BEL0000%' AND " +
          'deliveries <= 1'
      ).then(
        (result: Order[]) => {
          console.log('orders to filter:', result);
          this.ordersData.items = result.filter(
            (value: Order, index, arr) => value.remaining_amount > 0 && !value.isCancelled
          );
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }

  updateView(invoice: Invoice) {
    let selectedOrder: Order = null;
    let selectedMove: StockMove = null;
    if (this.selectedObject instanceof Order) selectedOrder = this.selectedObject;
    else if (this.selectedObject instanceof StockMove) {
      selectedOrder = this.selectedObject.order;
      selectedMove = this.selectedObject;
    }
    if (selectedOrder instanceof Order && this.invoiceCopy.id == 0) {
      if (invoice.order != selectedOrder) {
        invoice.order = selectedOrder;
        invoice.move = selectedMove;
        if (invoice.move) invoice.move.invoice = invoice;
        invoice.merchant = this.selectedObject.merchant;
        invoice.quantity = this.selectedObject.quantity;
        invoice.unit = this.selectedObject.unit;
        invoice.sell_price = this.selectedObject.sell_price;
        invoice.vat = this.selectedObject.customer.vat;
        invoice.delivery_number = this.selectedObject.delivery_number;
        invoice.deadline = this.selectedObject.deadline;
        invoice.quantity = this.selectedObject.remaining_quantity;
        invoice.discount = this.selectedObject.discount;
        invoice.discount_days = this.selectedObject.discount_days;
        invoice.accounting = this.selectedObject.accounting;
      }
      if (!invoice.date) invoice.date = DateTimeUtil.toDateString(new Date());
      //if (!invoice.number)
      //{
      invoice.getNextNumber().then(
        (result) => {
          setTimeout(() => {
            invoice.number = invoice.generateNumber(result.details);
            if (!this.model) this.model = InvoicePrintingModel.generate(invoice);
            else {
              this.model.invoice = invoice;
              this.model.regenerate();
            }
          }, 0);
        },
        (err) => {
          console.log(err);
        }
      );
      //}
    }
    this.quantityField.readonly = this.logisticsView;
    this.quantityField.numberUnit = invoice && invoice.order ? ' ' + invoice.order.unit : '';
    if (!this.model) this.model = InvoicePrintingModel.generate(invoice);
    else {
      this.model.invoice = invoice;
      this.model.regenerate();
    }
  }
  updateExpiration() {
    console.log('calculating expiration...');
    //this.invoiceCopy.expiration = DateTimeUtil.toDateString(Deadline.getFinalDate(this.invoiceCopy.deadline, new Date(this.invoiceCopy.date)));
  }

  validateForm() {
    return true;
  }

  save() {
    return new Promise<any>((resolve, reject) => {
      if (this.validateForm() !== true) reject('invalid form');
      else {
        let invoice_date: string = this.invoiceCopy.date;
        let today_date: string = DateTimeUtil.toDateString(new Date());
        if (invoice_date != today_date) {
          DialogsComponent.display({
            icon: 'question',
            title: 'Date de la facture',
            message:
              'Vous avez spécifié la date suivante pour la facture : <b>' +
              invoice_date +
              '</b><br/>' +
              'Voulez-vous changer pour la date du jour <b>' +
              today_date +
              '</b> ?',
            buttons: [
              { text: 'Oui, changer la date', result: DialogButton.RESULT_YES },
              { text: 'Non, merci !', result: DialogButton.RESULT_NO },
              DialogButton.cancelButton,
            ],
          }).then((result) => {
            if (result == DialogButton.RESULT_YES) this.invoiceCopy.date = today_date;
            if (result == DialogButton.RESULT_CANCEL) reject('cancelled by user');
            else
              this.save2().then(
                (result) => {
                  resolve(result);
                },
                (err) => {
                  console.error(err);
                }
              );
          });
        } else
          this.save2().then(
            (result) => {
              resolve(result);
            },
            (err) => {
              console.error(err);
            }
          );
      }
    });
  }

  save2() {
    return new Promise<any>((resolve, reject) => {
      this.invoiceCopy.save2().then(
        (result) => {
          this.invoiceCopy.clone(true, this.invoice);
          if (!this.invoice.order.invoices) this.invoice.order.invoices = [];
          this.invoice.order.invoices.push(this.invoice);
          let promises: Promise<any>[] = [];
          promises.push(this.invoice.order.save2());
          if (this.logisticsView === true && this.selectedObject instanceof StockMove) {
            this.selectedObject.invoice = this.invoice;
            promises.push(this.selectedObject.save2());
          }
          Promise.all(promises).then(
            (result2) => {
              this.initView(this.invoice, this.logisticsView);
              resolve(result2);
            },
            (err) => {
              console.error(err);
              reject(err);
            }
          );
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }

  public iterateFiles(callback) {
    let models: PrintingModel[] = [];
    models.push(this.model || InvoicePrintingModel.generate(this.invoiceCopy));
    return PrintPreviewViewComponent.globalInstance.iteratePrintingModels(models, callback);
  }

  public saveFile() {
    this.iterateFiles((model: PrintingModel, index: number) => {
      let path: string = FilesystemUtil.resolveSpecialPath(SettingsComponent.get('pdf_invoices_save_path'));
      if (this.logisticsView === true)
        path = FilesystemUtil.resolveSpecialPath(SettingsComponent.get('pdf_logistics_invoices_save_path'));
      if (!path) return null;
      else return PrintPreviewViewComponent.globalInstance.saveFileAs(path + model.saveFilename);
    });
  }

  public printPreview() {
    if (this.validateForm()) {
      let lang: string = this.invoiceCopy.customer.lang ? this.invoiceCopy.customer.lang.code || 'fr' : 'fr';
      let bcc: string[] = [...config.mailBcc];
      if (!bcc.includes(this.invoiceCopy.merchant.email)) bcc.push(this.invoiceCopy.merchant.email);
      let model: InvoicePrintingModel = this.model || InvoicePrintingModel.generate(this.invoiceCopy);
      // this.iterateFiles((model: PrintingModel, index: number) => {
      //     return PrintPreviewViewComponent.globalInstance.sendByMail(
      let view: PrintPreviewViewComponent = ViewsComponent.openView(
        PrintPreviewViewComponent,
        InvoicePrintingModel,
        this.invoiceCopy
      ) as PrintPreviewViewComponent;
      view.mail_to = [this.invoiceCopy.order.invoicing_emails];
      view.mail_cc = null;
      view.mail_bcc = bcc;
      view.mail_subject = [
        {
          fr: 'Notre facture pour votre commande ' + this.invoiceCopy.order.customer_reference,
          en: 'Our invoice for your order ' + this.invoiceCopy.order.customer_reference,
          nl: 'Our invoice for your order ' + this.invoiceCopy.order.customer_reference,
        }[lang],
      ];
      view.mail_body = [
        {
          fr:
            '<p>Madame, Monsieur,</p>' +
            '<p>Faisant suite à votre commande susmentionnée, pour laquelle nous vous remercions, nous tenons à vous informer que votre marchandise ' +
            this.invoiceCopy.delivery +
            ' ' +
            this.invoiceCopy.delivery_date +
            '.</p>' +
            '<p>Vous trouverez, en pièce jointe, ' +
            (this.dontSendByPost
              ? 'votre facture'
              : 'la copie de votre facture que nous vous avons expédiée par voie postale') +
            '</p>' +
            '<p>Nous restons à votre entière disposition, et nous vous prions de croire, Madame, Monsieur, en nos salutations les plus sincères.' +
            '<p>Cordialement,</p>',
          en:
            '<p>Dear Sir / Madam,</p>' +
            '<p>Thank you for your above-mentioned order which: ' +
            this.invoiceCopy.delivery +
            ' ' +
            this.invoiceCopy.delivery_date +
            '</p>' +
            '<p>Attached you will find your invoice ' +
            (this.dontSendByPost
              ? 'which is the only copy you will receive'
              : 'which will also be sent in hard copy by post') +
            '.</p>' +
            '<p>Please do not hesitate to contact us for any questions.</p>' +
            '<p>Yours faithfully,</p>',
          nl:
            '<p>Dear Sir / Madam,</p>' +
            '<p>Thank you for your above-mentioned order which: ' +
            this.invoiceCopy.delivery +
            ' ' +
            this.invoiceCopy.delivery_date +
            '</p>' +
            '<p>Attached you will find your invoice ' +
            (this.dontSendByPost
              ? 'which is the only copy you will receive'
              : 'which will also be sent in hard copy by post') +
            '.</p>' +
            '<p>Please do not hesitate to contact us for any questions.</p>' +
            '<p>Yours faithfully,</p>',
        }[lang],
      ];
      view.mail_filename = [model.mailFilename];
    }
  }
  public printPreviewLogistics() {
    if (this.validateForm()) {
      let lang: string = this.invoiceCopy.move.customer.lang ? this.invoiceCopy.move.customer.lang.code || 'fr' : 'fr';
      let bcc: string[] = [...config.mailBcc];
      if (!bcc.includes(this.invoiceCopy.merchant.email)) bcc.push(this.invoiceCopy.merchant.email);
      let model: InvoicePrintingModel = this.model || InvoicePrintingModel.generate(this.invoiceCopy);
      // this.iterateFiles((model: PrintingModel, index: number) => {
      //     return PrintPreviewViewComponent.globalInstance.sendByMail(
      let view: PrintPreviewViewComponent = ViewsComponent.openView(
        PrintPreviewViewComponent,
        InvoicePrintingModel,
        this.invoiceCopy
      ) as PrintPreviewViewComponent;
      view.mail_to = [this.invoiceCopy.order.invoicing_emails];
      view.mail_cc = null;
      view.mail_bcc = bcc;
      view.mail_subject = [
        {
          fr: 'Votre appel sur stock n° ' + this.invoiceCopy.move.fullNumber,
          en: 'Call of no ' + this.invoiceCopy.move.fullNumber,
          nl: 'Call of no ' + this.invoiceCopy.move.fullNumber,
        }[lang],
      ];
      view.mail_body = [
        {
          fr:
            '<p>Madame, Monsieur,</p>' +
            '<p>Suite à votre appel sur stock susmentionné, pour lequel nous vous remercions, nous tenions à vous informer que votre marchandise ' +
            this.invoiceCopy.delivery +
            ' ' +
            this.invoiceCopy.delivery_date +
            '.</p>' +
            '<p>Vous trouverez, en pièce jointe, ' +
            (this.dontSendByPost
              ? 'votre facture'
              : 'la copie de votre facture que nous vous avons expédiée par voie postale') +
            '.</p>' +
            '<p>Nous restons à votre entière disposition, et nous vous prions de croire, Madame, Monsieur, en nos salutations les plus sincères.</p>' +
            '<p>Cordialement,</p>',
          en:
            '<p>Dear Sir / Madam,</p>' +
            '<p>Thank you for your valued stock call off which: ' +
            this.invoiceCopy.move.delivery +
            ' ' +
            this.invoiceCopy.move.delivery_date +
            '</p>' +
            '<p>Attached you will find your invoice ' +
            (this.dontSendByPost
              ? 'which is the only copy you will receive'
              : 'which will also be sent in hard copy by post') +
            '.</p>' +
            '<p>Please do not hesitate to contact us for any questions.</p>' +
            '<p>Yours faithfully,</p>',
          nl:
            '<p>Dear Sir / Madam,</p>' +
            '<p>Thank you for your valued stock call off which: ' +
            this.invoiceCopy.move.delivery +
            ' ' +
            this.invoiceCopy.move.delivery_date +
            '</p>' +
            '<p>Attached you will find your invoice ' +
            (this.dontSendByPost
              ? 'which is the only copy you will receive'
              : 'which will also be sent in hard copy by post') +
            '.</p>' +
            '<p>Please do not hesitate to contact us for any questions.</p>' +
            '<p>Yours faithfully,</p>',
        }[lang],
      ];
      view.mail_filename = [model.mailFilename];
    }
  }

  get dontSendByPost() {
    if (!this.invoiceCopy) return false;
    if (!this.invoiceCopy.customer) return false;
    if (!this.invoiceCopy.customer) return false;
    if (!this.invoiceCopy.customer.billing_address) return false;
    else return !this.invoiceCopy.customer.billing_address.invoicing_by_paper;
  }

  get dontSendByMail() {
    if (!this.invoiceCopy) return false;
    if (!this.invoiceCopy.customer) return false;
    if (!this.invoiceCopy.customer.billing_address) return false;
    else return !this.invoiceCopy.customer.billing_address.invoicing_by_mail;
  }

  private openPreview() {
    if (this.logisticsView) this.printPreviewLogistics();
    else this.printPreview();
  }
}
