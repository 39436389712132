import { AddressesModule } from './addresses/addresses.module';
import { ArticlesModule } from './articles/articles.module';
import { BobModule } from './bob/bob.module';
import { ChargesModule } from './charges/charges.module';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';
import { CustomersModule } from './customers/customers.module';
import { DatabaseImportModule } from './database-import/database-import.module';
import { DevicesModule } from './devices/devices.module';
import { GoalsModule } from './goals/goals.module';
import { HomepageModule } from './homepage/homepage.module';
import { IncidentsModule } from './incidents/incidents.module';
import { InteropModule } from './interop/interop.module';
import { InvoicingModule } from './invoicing/invoicing.module';
import { ItModule } from './it/it.module';
import { LoadingPromise } from '../classes/objects/LoadingPromise.class';
import { LoggerModule } from './logger/logger.module';
import { LoginModule } from './login/login.module';
import { NgModule } from '@angular/core';
import { OrdersModule } from './orders/orders.module';
import { OtherDataModule } from './other-data/other-data.module';
import { PaymentsModule } from './payments/payments.module';
import { PrintingModule } from './printing/printing.module';
import { ReportsModule } from './reports/reports.module';
import { SalesModule } from './sales/sales.module';
import { SettingsModule } from './settings/settings.module';
import { StatsModule } from './stats/stats.module';
import { StocksModule } from './stocks/stocks.module';
import { SuppliersModule } from './suppliers/suppliers.module';
import { TrashModule } from './trash/trash.module';
import { UtilsModule } from './utils/utils.module';
import { WagesModule } from './wages/wages.module';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    LoginModule,
    HomepageModule,
    CustomersModule,
    SuppliersModule,
    AddressesModule,
    OtherDataModule,
    DatabaseImportModule,
    ArticlesModule,
    UtilsModule,
    PrintingModule,
    SalesModule,
    TrashModule,
    LoggerModule,
    StocksModule,
    OrdersModule,
    InvoicingModule,
    IncidentsModule,
    PaymentsModule,
    ChargesModule,
    SettingsModule,
    ReportsModule,
    StatsModule,
    LoginModule,
    WagesModule,
    DevicesModule,
    ItModule,
    BobModule,
    GoalsModule,
    InteropModule,
  ],
  declarations: [],
  exports: [
    ComponentsModule,
    LoginModule,
    HomepageModule,
    CustomersModule,
    SuppliersModule,
    AddressesModule,
    OtherDataModule,
    DatabaseImportModule,
    ArticlesModule,
    UtilsModule,
    PrintingModule,
    TrashModule,
    SalesModule,
    TrashModule,
    LoggerModule,
    StocksModule,
    OrdersModule,
    InvoicingModule,
    IncidentsModule,
    PaymentsModule,
    ChargesModule,
    SettingsModule,
    ReportsModule,
    StatsModule,
    LoginModule,
    WagesModule,
    DevicesModule,
    ItModule,
    BobModule,
    GoalsModule,
    InteropModule,
  ],
})
export class ModulesModule {
  modules = [
    HomepageModule,

    // Not visible modules
    AddressesModule,
    UtilsModule,

    // Base de données
    CustomersModule,
    SuppliersModule,
    ArticlesModule,
    OtherDataModule,
    DatabaseImportModule,

    // Ventes
    SalesModule,
    IncidentsModule,

    // Offres/demandes prix
    // PriceDemandsModule,
    // PriceOffersModule,
    OrdersModule,

    // Stocks
    StocksModule,

    // Facturation
    InvoicingModule,

    // Paiements
    PaymentsModule,
    ChargesModule,

    // Statistiques
    ReportsModule,
    StatsModule,

    // Salaires
    WagesModule,

    // Budgets
    GoalsModule,

    // RMA et appareils
    DevicesModule,

    // Others
    PrintingModule,
    TrashModule,
    SettingsModule,
    LoggerModule,
    ItModule,

    // Login
    LoginModule,

    // Compta
    BobModule,

    InteropModule,
  ];

  public getModules() {
    return LoadingPromise.create<any>((resolve, reject) => {
      let count = this.modules.length;
      this.modules.forEach((element) => {
        new element().ngOnInit();
        if (--count == 0) resolve(true);
      });
    });
  }
}
