import { DateTimeUtil } from "../../utils/classes/DateTimeUtil.class";

export class LogEntry
{
    public static readonly LOG_TRACE: number = -1;
    public static readonly LOG_INFO: number = 0;
    public static readonly LOG_WARNING: number = 1;
    public static readonly LOG_ERROR: number = 2;
    public static readonly LOG_CRITICAL: number = 4;

    public type: number = LogEntry.LOG_INFO;
    public args: any[] = null;
    public date: Date = new Date();
    public filename: string = null;

    public constructor(type: number, filename: string = null, ...args)
    {
        this.type = type;
        this.args = args;
        this.filename = filename;
    }

    get dateString()
    {
        return DateTimeUtil.toDatabaseDateTimeString(this.date);
    }

    get argsStrings()
    {
        let arr: any[] = [];
        for(let i=0; i<this.args.length; ++i)
        {
            let test = function() {};
            let arg = this.args[i];
            if (typeof(arg) === 'string') arr.push({ class: 'log-string', html: ('"' + arg + '"') });
            else if (typeof(arg) === 'function') arr.push({ class: 'log-function', html: ('function ' + arg.constructor.name + '()') });
            else if (typeof(arg) === 'object') arr.push({ class: 'log-object', html: JSON.stringify(arg) });
        }
        return arr;
    }
}
