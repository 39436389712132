import { Component, OnInit, Input, forwardRef, SimpleChanges, OnChanges, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ArrayUtil } from '../../modules/utils/classes/ArrayUtil.class';
import * as $ from 'jquery';

@Component({
  selector: 'foreign-list',
  templateUrl: './foreign-list.component.html',
  styleUrls: ['./foreign-list.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ForeignListComponent),
      multi: true
    }
  ]
})
export class ForeignListComponent implements ControlValueAccessor, OnChanges {

    private _objects: any[] = null;
    @Input() public set objects(value: any[]) {
        this._objects = value;
    }
    public get objects() { return this._objects; }

    @Input() public fieldName: string = null;
    @Input() public conditions = null;
    @Input() public nullText = null;
    @Input() public allowNull: boolean = false;
    @Input() public multiSelect: boolean = false;
    @Input() public linesCount: number = 1;
    @Input() public readonly: boolean = false;
    @Input() public getOptionStyle: (item: any) => string = null;

    @Output() public change: EventEmitter<any> = new EventEmitter<any>();

    public selectedObjects: any[] = [];
    public selectedObject: any = null;
    public _selectionText: string = this.nullText;

    public showList: boolean = false;
    public inverseList: boolean = false;

    //@ViewChild('elementRef') public elementRef: ElementRef;

    constructor(public elementRef: ElementRef) {}

    toggleList(action = null)
    {
        this.showList = action ? action : !this.showList;
        if (this.showList === true) {
            setTimeout(() => {
                let options = $('.list-options')[0];
                let elemRect = this.elementRef.nativeElement.getBoundingClientRect();
                let listRect = options.getBoundingClientRect();
                console.log('elemRect:', elemRect);
                console.log('listRect:', listRect);
                this.inverseList = (elemRect.bottom + listRect.height > window.innerHeight - 18 /* scrollbar */);
            }, 0);
        }
    }
    itemClick(object: any)
    {
        if (this.readonly !== true)
        {
            this.toggleObject(object);
            if (this.multiSelect !== true) this.showList = false;
        }
    }
    selectChange(event)
    {
        this.toggleObject(this.selectedObject);
    }

    toggleObject(object: any)
    {
        if (this.multiSelect === true) this.toggleObjectMultiple(object);
        else this.toggleObjectSingle(object);
        this.updateSelection(true);
    }
    toggleObjectMultiple(object)
    {
        //this.selectedObject = object;
        //this.showList = false;
        //object.selected = !object.selected;
        if (Array.isArray(this.selectedObjects))
        {
            if (this.selectedObjects.indexOf(object) >= 0) ArrayUtil.removeElements(this.selectedObjects, [object]);
            else this.selectedObjects.push(object);
        }
        else
        {
            this.selectedObjects = [];
            this.selectedObjects.push(object);
        }
        this.change.emit(this.selectedObjects);
    }
    toggleObjectSingle(object)
    {
        this.selectedObject = object;
    }


    updateSelection(propagate: boolean = false)
    {
        let result: any = null;
        if (this.multiSelect === true) result = this.updateSelectionMultiple();
        else result = this.updateSelectionSingle();
        if (propagate === true) {
            this.propagateChange(result);
            //this.change.next(result);
        }
    }
    updateSelectionMultiple()
    {
        let result: string = null;
        let arr: string[] = [];

        if (Array.isArray(this.selectedObjects))
        {
            for(let i=0; i<this.selectedObjects.length; ++i)
            {
                if (!this.selectedObjects[i]) console.log('selectedObjects=', this.selectedObjects);
                arr.push(this.selectedObjects[i][this.fieldName]);
            }
        }
        if (arr.length > 0) result = arr.join(', ');
        this._selectionText = result ? result : (this.nullText ? this.nullText : '(Aucun object sélectionné)');
        return this.selectedObjects;
    }
    updateSelectionSingle()
    {
        this._selectionText = this.selectedObject ? this.selectedObject[this.fieldName] : (this.nullText ? this.nullText : '(Aucun object sélectionné)');
        return this.selectedObject;
    }

    // ControlValueAccessor

    propagateChange = (_: any) => {};
    registerOnChange(fn) { this.propagateChange = fn; }
    registerOnTouched() {}
    writeValue(value: any)
    {
        if (this.multiSelect === true) this.writeValueMultiple(value);
        else this.writeValueSingle(value);
        this.updateSelection();
    }
    writeValueMultiple(values: any[]) {
        this.selectedObjects = values;
    }
    writeValueSingle(value: any) {
        this.selectedObject = value;
    }


    ngOnChanges(changes: SimpleChanges) {
        this.updateSelection();
    }

}
