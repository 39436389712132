import { ObjectModel2 } from "../../../classes/objects/ObjectModel2.class";
import { ObjectDefinition } from "../../../classes/objects/ObjectDefinition.class";
import { ObjectModel3 } from "src/app/classes/objects/ObjectModel3.class";


export class ChargeType extends ObjectModel3
{
    public name: string = null;
    public default_vat: number = null;

    constructor() {
        super(ChargeType);
    }

    /* ObjectModel FUNCTIONS */

    public static definition: ObjectDefinition = {
        trashDelete: true,
        database: {
            table: 'charge_types',
            id: 'id',
			db_id: 'db_id'
        },
        values: {
            'name': { type: 'string' },
            'default_vat': { type: 'number' },
        },
        children: {},
        links: {}
    };
}
