<div class="wrapper">
    <img src="assets/img/logo.png" />
    <ul class="users" *ngIf="!isProd">
        <li *ngFor="let user of users" (click)="login(user)">
            <i class="fas fa-user"></i>
            <span class="username">{{user.name}}</span>
        </li>
    </ul>
    <div class="message" *ngIf="isProd && message" [innerHTML]="message"></div>
</div>
