import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ViewChild } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule} from "@angular/platform-browser/animations";
import * as $ from 'jquery';
//import 'jquery-mask-plugin';
//import 'jquery-input-formats';


// Modules
import { ModulesModule } from './modules/modules.module';

// Components
import { AppComponent } from './app.component';

// Services
import { ServicesModule } from './services/services.module';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ModulesModule,
    ServicesModule,
    BrowserAnimationsModule
  ],
  exports: [
  ],
  providers: [],
  bootstrap: [AppComponent],
//   entryComponents: [CustomersListViewComponent]
})
export class AppModule {

}
