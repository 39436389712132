// import { Sale } from "./Sale.class";

import { DateTimeUtil } from '../../utils/classes/DateTimeUtil.class';
import { InfoBlock } from '../../../components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from '../../../components/info-block/info-block.component';
import { InfoBlockField } from '../../../components/info-block/classes/InfoBlockField.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { Page } from '../../printing/classes/Page.class';
import { Price } from './Price.class';
import { PrintingModel } from '../../printing/classes/PrintingModel.class';
import { PrintingUtil } from '../../utils/classes/PrintingUtil.class';
import { Sale } from './Sale.class';
import { TextUtil } from '../../utils/classes/TextUtil.class';
import { config } from '../../../classes/config';

export class PriceOfferPrintingModel extends PrintingModel {
  public deliveryAddressField: InfoBlockField = {
    title: 'Adresse de livraison',
    field: 'delivery_address',
    type: 'address-text',
    listItems: { items: [] },
    textChange: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
      this.regenerate();
    },
    addressChange: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
      this.regenerate();
    },
  };
  public optionsBlock: InfoBlock = {
    fields: [
      {
        title: 'Langue',
        field: 'lang',
        type: 'select',
        selectOptions: [
          { text: '(Selon client)', value: '' },
          { text: 'Français', value: 'fr' },
          { text: 'Anglais', value: 'en' },
          { text: 'Néerlandais', value: 'nl' },
        ],
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
          this.regenerate();
        },
      },
      this.deliveryAddressField,
      {
        title: 'Afficher logo Wallonie',
        field: 'show_wallonia_logo',
        type: 'checkbox',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
          this.regenerate();
        },
      },
      {
        title: 'Avertissement pénuries',
        field: 'shortage_warning',
        type: 'checkbox',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
          this.regenerate();
        },
      },
    ],
  };

  public sale: Sale = null;
  public lang: string = '';
  public delivery_address: string = null;
  public show_wallonia_logo: boolean = null;
  public shortage_warning: number = 0;
  // public delivery_address: string = null;
  // public billing_address: string = null;
  // public send_address: string = null;
  //public hide_customer: boolean = false;

  public static generate(sale: Sale): PrintingModel {
    let model: PriceOfferPrintingModel = new PriceOfferPrintingModel();
    model.sale = sale;
    model.delivery_address = sale.delivery_address_text;
    model.deliveryAddressField.listItems.items = sale.customer.deliveryAddressesList;
    model.shortage_warning = sale.shortage_warning;
    return model.regenerate();
  }

  public regenerate(): PrintingModel {
    let sale: Sale = this.sale;

    let pageWidth = 210;
    let pageHeight = 295;

    let pageLeftMargin = 10;
    let pageRightMargin = 10;
    let pageTopMargin = 10;
    let pageBottomMargin = 10;

    let quantities: string[] = [];
    let prices: string[] = [];
    let totals: string[] = [];
    let invoicings: string[] = [];
    let deliveries: string[] = [];
    let storages: string[] = [];
    let productions: string[] = [];
    let show_prods: boolean = false;

    if (sale.prices) {
      sale.prices.sort((a: Price, b: Price) => {
        return a.quantity - b.quantity;
      });
      for (let i = 0; i < sale.prices.length; ++i) {
        let price = sale.prices[i];
        if (price.show == 1) {
          quantities.push(price.quantityAndUnit);
          prices.push(
            NumberUtil.formatMoney(
              price.converted_sellPrice,
              price.sell_currency ? price.sell_currency.symbol : '€',
              5,
              '.'
            )
          );
          totals.push(
            NumberUtil.formatMoney(
              price.quantity * price.converted_sellPrice,
              price.sell_currency ? price.sell_currency.symbol : '€',
              2,
              '.'
            )
          );
          invoicings.push(price.invoicings ? price.invoicings.toString() : '-');
          deliveries.push(price.deliveries ? price.deliveries.toString() : '-');
          storages.push(price.storage ? price.storage.toString() : '-');
          if (price.productions && price.productions > 0) show_prods = true;
          productions.push(price.productions ? price.productions.toString() : '-');
        }
      }
    }

    // if (!this.delivery_address && sale.delivery_address) this.delivery_address = sale.delivery_address;
    // if (!this.billing_address && sale.billing_address) this.billing_address = sale.billing_address;
    // if (!this.send_address && sale.send_address) this.send_address = sale.send_address;

    this.pages = [];

    let lang: string = this.lang;
    if (!lang || lang == '') {
      if (this.sale.customer.lang) lang = this.sale.customer.lang.code;
      else lang = 'fr';
    }
    this.saveFilename = 'Offre de prix n°' + this.sale.number.replace(/\//g, '-') + '.pdf';
    this.mailFilename =
      {
        fr: 'Offre de prix n° ',
        nl: 'Prijsofferte nr ',
        en: 'Quotation no ',
      }[lang] +
      this.sale.shortNumber.replace(/\//g, '-') +
      '-' +
      this.sale.history_version +
      '.pdf';

    // for(let i=0; i<this.sale.suppliers.length; ++i)
    // {

    let page: Page = {
      width: pageWidth,
      height: pageHeight,

      leftMargin: pageLeftMargin,
      rightMargin: pageRightMargin,
      topMargin: pageTopMargin,
      bottomMargin: pageBottomMargin,

      style: {
        'font-family': 'Calibri',
        'font-size': '10pt',
        width: pageWidth - pageLeftMargin - pageRightMargin + 'mm',
        height: pageHeight - pageTopMargin - pageBottomMargin + 'mm',
        'margin-top': pageTopMargin + 'mm',
        'margin-left': pageLeftMargin + 'mm',
        'margin-right': pageRightMargin + 'mm',
        'margin-bottom': pageBottomMargin + 'mm',
        'line-height': '4mm',
      },

      headerSize: 33,
      headerElements: [
        this.shortage_warning
          ? {
              style: {
                position: 'absolute',
                right: '0mm',
                left: '0mm',
                top: '-6mm',
                height: '10mm',
                'line-height': '4mm',
                'font-size': '12pt',
                color: 'rgb(255,0,0)',
                'font-weight': 'bold',
                'text-align': 'center',
              },
              html: {
                en: 'Given the current market shortages the cost of raw material could change prior to delivery and we may be obliged to review this price.',
                fr: 'Compte tenu des pénuries actuelles du marché, le coût des matières premières pourrait changer avant la livraison et nous pourrions être obligés de revoir ce prix.',
                nl: 'Vermits ons aktueel tekortkoming van materiaal op de markt, de kost van het materiaal zou kunnen veranderen voor uw levevering en wij zouden verplicht zijn om onze prijsen te moeten aanpassen.',
              }[lang],
            }
          : {},
        {
          style: {
            position: 'absolute',
            right: '0mm',
            top: this.shortage_warning ? '4mm' : '1mm',
            width: '85mm',
            height: '17mm',
            border: '.5mm solid rgba(0, 176, 240, 1.0)',
            'border-radius': '2.5mm',
            'line-height': '4mm',
          },
          children: [
            {
              style: {
                'font-size': '20pt',
                'font-weight': 'bold',
                'text-align': 'center',
                width: '100%',
                'line-height': '5mm',
                'margin-top': '1.5mm',
                'margin-bottom': '1mm',
                'vertical-align': 'top',
              },
              html: {
                fr: 'OFFRE DE PRIX',
                en: 'QUOTATION',
                nl: 'PRIJSOFFERTE',
              }[lang],
            },
            {
              style: {
                display: 'flex',
                'flex-direction': 'row',
              },
              children: [
                {
                  style: {
                    width: '20%',
                    'text-align': 'right',
                    padding: '0 1mm',
                  },
                  html: {
                    fr: 'N° :',
                    en: 'No :',
                    nl: 'Nr :',
                  }[lang],
                },
                {
                  style: {
                    width: '80%',
                    padding: '0 1mm',
                    'font-weight': 'bold',
                  },
                  html: sale.number + '-' + sale.history_version,
                },
              ],
            },
            {
              style: {
                display: 'flex',
                'flex-direction': 'row',
              },
              children: [
                {
                  style: {
                    width: '20%',
                    'text-align': 'right',
                    padding: '0 1mm',
                  },
                  html: {
                    fr: 'Date :',
                    en: 'Date :',
                    nl: 'Datum :',
                  }[lang],
                },
                {
                  style: {
                    width: '30%',
                    padding: '0 1mm',
                  },
                  html: DateTimeUtil.format(new Date(sale.date), 'd/m/Y'),
                },
                {
                  style: {
                    width: '25%',
                    'text-align': 'right',
                    padding: '0 1mm',
                  },
                  html: {
                    fr: 'N° client :',
                    en: 'Cust. No :',
                    nl: 'Klant Nr :',
                  }[lang],
                },
                {
                  style: {
                    width: '25%',
                    padding: '0 1mm',
                  },
                  html: sale.customer ? sale.customer.number : 'BEL0000',
                },
              ],
            },
          ],
        },
        {
          style: {
            position: 'absolute',
            left: '0mm',
            top: this.shortage_warning ? '4mm' : '0mm',
            width: '50mm',
            height: '30mm',
            'background-image': 'url(assets/img/logo_documents.png)',
            'background-position': 'center center',
            'background-size': 'contain',
            'background-repeat': 'no-repeat',
          },
          html: '',
        },
        this.show_wallonia_logo
          ? {
              style: {
                position: 'absolute',
                left: '60mm',
                top: this.shortage_warning ? '4mm' : '0mm',
                width: '30mm',
                height: '30mm',
                'background-image': 'url(assets/img/logo_wallon_print.png)',
                'background-position': 'center center',
                'background-size': 'contain',
                'background-repeat': 'no-repeat',
              },
              html: '',
            }
          : {},

        {
          style: {
            position: 'absolute',
            left: '-10mm',
            top: '90.5mm',
            width: '7.5mm',
            height: '0.1mm',
            'background-color': 'rgba(192,192,192,1)',
          },
          html: '',
        },
        {
          style: {
            position: 'absolute',
            left: '-10mm',
            top: '188mm',
            width: '7.5mm',
            height: '0.1mm',
            'background-color': 'rgba(222,222,222,1)',
          },
          html: '',
        },
      ],

      bodyElements: [
        {
          style: {
            'text-decoration': 'underline',
            'white-space': 'pre',
          },
          html: {
            fr: '\nVotre interlocuteur :',
            en: '\nYour contact-person :',
            nl: '\nUw contact persoon :',
          }[lang],
        },
        {
          style: {
            'white-space': 'pre',
            'font-weight': 'bold',
          },
          html: sale.merchant.name,
        },
        {
          style: {
            'white-space': 'pre',
          },
          html:
            (this.sale.merchant && this.sale.merchant.email ? 'Mail : ' + this.sale.merchant.email + '\n' : '') +
            (this.sale.merchant && this.sale.merchant.phone
              ? {
                  fr: 'Portable : ',
                  en: 'Mobile : ',
                  nl: 'Mobiele : ',
                }[lang] +
                this.sale.merchant.phone +
                '\n'
              : '') +
            {
              fr: 'Téléphone : ',
              en: 'Phone : ',
              nl: 'Telefoon : ',
            }[lang] +
            '+32 (0)4 380 06 05',
        },
        {
          style: {
            'white-space': 'pre-wrap',
            'font-weight': 'bold',
            // 'line-height': '5mm',
            margin: '6mm 0mm 3mm 0mm',
          },
          html: {
            fr: 'À la suite de votre demande de prix #, pour laquelle nous vous remercions, nous vous prions de trouver, ci-dessous,  nos meilleures conditions pour la fourniture suivante :',
            en: 'Further to your price request we are pleased to give you our price quotation for :',
            nl: 'Naar aanleiding van uw prijsaanvraag, waarvoor wij U danken, sturen wij U hierbij onze beste prijs voor de volgende artikel :',
          }[lang].replace(
            '#',
            this.sale.customer_reference ? TextUtil.lowercaseFirst(this.sale.customer_reference) : ''
          ),
        },

        {
          style: {
            position: 'absolute',
            right: '0mm',
            top: '-5mm',
            width: '85mm',
            height: '25m',
          },
          children: PrintingUtil.formatAddress(
            this.sale.customer.name,
            this.sale.ordinator_contact,
            this.sale.ordinator_address_text
          ),
        },

        {
          style: {
            display: 'flex',
            width: '100%',
            'flex-direction': 'row',
          },
          children: [
            {
              style: {
                display: 'table',
                flex: '1',
                'border-collapse': 'collapse',
                position: 'relative',
              },
              children: [
                {
                  style: {
                    display: 'table-row',
                    height: '1px',
                  },
                  children: [
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        'background-color': 'rgba(217,217,217,1)',
                        width: '50mm',
                        padding: '.5mm 1mm',
                        'text-align': 'right',
                        'font-weight': 'bold',
                      },
                      html: {
                        fr: 'Désignation',
                        en: 'Specifications',
                        nl: 'Specificaties',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        'background-color': 'rgba(217,217,217,1)',
                        padding: '.5mm 1mm',
                        'font-weight': 'bold',
                      },
                      html: sale.article.designation,
                    },
                  ],
                },
              ].concat(this.getArticleLines(sale, lang)),
            },

            this.sale.article.field15 || this.sale.article.field9_img
              ? {
                  style: {
                    float: 'right',
                    'max-height': '50mm',
                    'margin-left': '5mm',
                    position: 'relative',
                  },
                  children: [
                    this.sale.article.field15 //picture
                      ? {
                          style: {
                            display: 'block',
                            'max-width': '50mm',
                            'max-height': this.sale.article.field9_img ? '37mm' : '50mm',
                          },
                          image: config.baseUrl + 'uploaded_images/' + this.sale.article.field15,
                          imageStyle: {
                            'max-width': '50mm',
                            'max-height': this.sale.article.field9_img ? '37mm' : '50mm',
                          },
                        }
                      : {},
                    this.sale.article.field9_img //rolling picture
                      ? {
                          style: {
                            display: 'block',
                            width: '10mm',
                            height: '10mm',
                            margin: '3mm auto 0 auto',
                            position: 'relative',
                          },
                          image:
                            config.baseUrl + 'assets/img/rolling-directions/' + this.sale.article.field9_img + '.png',
                          imageStyle: {
                            width: '10mm',
                            height: '10mm',
                          },
                        }
                      : {},
                  ],
                }
              : {},
          ],
        },

        {
          style: {
            clear: 'both',
          },
        },

        {
          style: {
            display: 'flex',
            'flex-direction': 'row',
            width: '100%',
          },
          children: [
            {
              style: {
                display: 'table',
                width: '100%',
                'border-collapse': 'collapse',
                'margin-top': '3mm',
              },
              children: [
                {
                  style: {
                    display: 'table-row',
                  },
                  children: [
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        'background-color': 'rgba(217,217,217,1)',
                        padding: '.5mm 1mm',
                        'text-align': 'center',
                        'white-space': 'pre',
                        // 'line-height': '5mm',
                        // 'width': '50mm',
                        'font-weight': 'bold',
                      },
                      html: {
                        fr: 'Quantité et\nunité',
                        en: 'Quantity and\nunit',
                        nl: 'Hoeveelheid en\nverkoopseenheid',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        'background-color': 'rgba(217,217,217,1)',
                        padding: '.5mm 1mm',
                        'text-align': 'center',
                        'white-space': 'pre',
                        // 'line-height': '5mm',
                        'font-weight': 'bold',
                      },
                      html: {
                        fr: "Prix de vente\nà l'unité",
                        en: 'Unit\nprice',
                        nl: 'Prijs per\nverkoopseenheid',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        'background-color': 'rgba(217,217,217,1)',
                        padding: '.5mm 1mm',
                        'text-align': 'center',
                        'white-space': 'pre',
                        // 'line-height': '5mm',
                        // 'width': '15%',
                        'font-weight': 'bold',
                      },
                      html: {
                        fr: 'Montant de la\ncommande (H.T.V.A.)',
                        en: 'Net amount\n(excl. V.A.T.)',
                        nl: 'Totaal bedrag\n(excl. B.T.W.)',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        'background-color': 'rgba(217,217,217,1)',
                        padding: '.5mm 1mm',
                        'text-align': 'center',
                        'white-space': 'pre',
                        // 'line-height': '5mm',
                        // 'width': '15%',
                        'font-weight': 'bold',
                      },
                      html: {
                        fr: 'Nombre de\nfacturations',
                        en: 'Number of\ninvoicings',
                        nl: 'Aantal\nfacturen',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        'background-color': 'rgba(217,217,217,1)',
                        padding: '.5mm 1mm',
                        'text-align': 'center',
                        'white-space': 'pre',
                        // 'line-height': '5mm',
                        // 'width': '15%',
                        'font-weight': 'bold',
                        color: 'rgba(255,0,0,1)',
                      },
                      html: {
                        fr: 'Livraisons\nstandard',
                        en: 'Standard\ndeliveries',
                        nl: 'Standaard\nlevering',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        'background-color': 'rgba(217,217,217,1)',
                        padding: '.5mm 1mm',
                        'text-align': 'center',
                        'white-space': 'pre',
                        // 'line-height': '5mm',
                        // 'width': '15%',
                        'font-weight': 'bold',
                      },
                      html: {
                        fr: 'Mois\nde stockage',
                        en: 'Storage\n(months)',
                        nl: 'Maanden\nopslag',
                      }[lang],
                    },
                    show_prods
                      ? {
                          style: {
                            display: 'table-cell',
                            border: '.25mm solid rgba(0, 176, 240, 1.0)',
                            'background-color': 'rgba(217,217,217,1)',
                            padding: '.5mm 1mm',
                            'text-align': 'center',
                            'white-space': 'pre',
                            // 'line-height': '5mm',
                            // 'width': '15%',
                            'font-weight': 'bold',
                          },
                          html: {
                            fr: 'Nombre de\nproductions',
                            en: 'Number of\nproductions',
                            nl: 'Aantal\nproducties',
                          }[lang],
                        }
                      : {
                          style: { display: 'none' },
                        },
                  ],
                },
                {
                  style: {
                    display: 'table-row',
                  },
                  children: [
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        padding: '.5mm 1mm',
                        'text-align': 'center',
                        'white-space': 'pre',
                        // 'line-height': '5mm',
                        // 'width': '50mm'
                      },
                      html: quantities.join('\n'),
                    },
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        padding: '.5mm 1mm',
                        'text-align': 'center',
                        'white-space': 'pre',
                        // 'line-height': '5mm'
                      },
                      html: prices.join('\n'),
                    },
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        padding: '.5mm 1mm',
                        'text-align': 'center',
                        'white-space': 'pre',
                        // 'line-height': '5mm',
                        // 'width': '15%'
                      },
                      html: totals.join('\n'),
                    },
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        padding: '.5mm 1mm',
                        'text-align': 'center',
                        'white-space': 'pre',
                        // 'line-height': '5mm',
                        // 'width': '15%'
                      },
                      html: invoicings.join('\n'),
                    },
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        padding: '.5mm 1mm',
                        'text-align': 'center',
                        'white-space': 'pre',
                        // 'line-height': '5mm',
                        // 'width': '15%'
                      },
                      html: deliveries.join('\n'),
                    },
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        padding: '.5mm 1mm',
                        'text-align': 'center',
                        'white-space': 'pre',
                        // 'line-height': '5mm',
                        // 'width': '15%'
                      },
                      html: storages.join('\n'),
                    },
                    show_prods
                      ? {
                          style: {
                            display: 'table-cell',
                            border: '.25mm solid rgba(0, 176, 240, 1.0)',
                            padding: '.5mm 1mm',
                            'text-align': 'center',
                            'white-space': 'pre',
                            // 'line-height': '5mm',
                            // 'width': '15%'
                          },
                          html: productions.join('\n'),
                        }
                      : {
                          style: { display: 'none' },
                        },
                  ],
                },
              ],
            },
          ],
        },

        {
          style: {
            clear: 'both',
          },
        },

        {
          style: {
            display: 'table',
            width: '100%',
            'border-collapse': 'collapse',
            'margin-top': '3mm',
          },
          children: [
            {
              style: {
                display: 'table-row',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    width: '50mm',
                    padding: '.5mm 1mm',
                    'text-align': 'right',
                    'white-space': 'pre',
                    // 'line-height': '5mm',
                  },
                  html: {
                    fr:
                      'Délai de livraison :\nConditions de paiement :\nDate de validité :' +
                      (sale.discount > 0 ? '\nEscompte :' : ''),
                    en:
                      'Delivery time :\nTerms of payment :\nDate of validity' +
                      (sale.discount > 0 ? '\nDiscount :' : ''),
                    nl:
                      'Leveringstermijn :\nBetalingsvoorwaarden :\nGeldigheidsdatum :' +
                      (sale.discount > 0 ? '\nKorting :' : ''),
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    padding: '.5mm 1mm',
                    'white-space': 'pre',
                    // 'line-height': '5mm',
                  },
                  html:
                    (sale.delivery_time ? sale.delivery_time : '') +
                    '\n' +
                    (sale.deadline ? sale.deadline : sale.customer.deadline ? sale.customer.deadline : '') +
                    '\n' +
                    (sale.valid_date ? DateTimeUtil.format(new Date(sale.valid_date), 'd/m/Y') : '-') +
                    (sale.discount > 0
                      ? NumberUtil.formatNumber(sale.discount, 1, '.') +
                        {
                          fr: ' % si paiement dans les # jours',
                          en: ' % if paid within # days',
                          nl: ' % als betaald in # dagen',
                        }[lang].replace('#', NumberUtil.formatNumber(sale.discount_days, 0, '.'))
                      : ''),
                },
              ],
            },
          ],
        },

        {
          style: {
            display: 'block',
            border: '.25mm solid rgba(0, 176, 240, 1.0)',
            'background-color': 'rgba(0, 176, 240, 1.0)',
            width: '100%',
            padding: '.5mm 1mm',
            'text-align': 'center',
            // 'line-height': '5mm',
            color: 'rgba(255,255,255,1)',
            'font-weight': 'bold',
            'margin-top': '3mm',
          },
          html: {
            fr: 'REMARQUES IMPORTANTES',
            en: 'IMPORTANT REMARKS',
            nl: 'BELANGRIJKE OPMERKINGEN',
          }[lang],
        },
        {
          style: {
            display: 'block',
            border: '.25mm solid rgba(0, 176, 240, 1.0)',
            width: '100%',
            padding: '.5mm 1mm',
            // 'line-height': '5mm',
            'white-space': 'pre-wrap',
            flex: '1',
            'vertical-align': 'top',
          },

          children: PrintingUtil.linesToDivs(
            (
              {
                fr: "1) En cas de commandes, merci de rappeler notre n° d'offre au complet.\n2) L'acceptation de notre offre comprend aussi l'adhésion à nos C.G.V. et notamment à notre réserve de propriété.",
                en: '1) On your order, could you please recall our quotation number.\n2) The good remain our propriety until the full payement.',
                nl: 'In geval van bestelling, gelieve ons volledig saletenummer te vermelden, ',
              }[lang] + (sale.offer_remarks ? '\n' + sale.offer_remarks : '')
            ).trim(),
            null,
            3
          ),
        },

        {
          style: {
            'white-space': 'pre-wrap',
            'font-weight': 'bold',
            // 'line-height': '5mm',
            'margin-top': '3mm',
            'font-style': 'italic',
          },
          html: {
            fr: "En restant à votre disposition pour d'éventuelles questions, et en espérant que nos conditions motiveront votre commande. Nous vous prions d'agréer, Madame, Monsieur,  nos sincères salutations.",
            nl: 'Wij blijven geheel tot uw beschikking voor verdere vragen. Met Vriendelijke Groeten.',
            en: 'We are at your disposal for any further questions. Thank you for your valued custom.',
          }[lang],
        },
      ],

      footerSize: 63,
      footerElements: [
        {
          style: {
            display: 'table',
            width: '100%',
            'border-collapse': 'collapse',
            'margin-top': '3mm',
          },
          children: [
            {
              style: {
                display: 'table-row',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    'background-color': 'rgba(217, 217, 217, 1.0)',
                    width: '76mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'font-weight': 'bold',
                  },
                  html: {
                    fr: 'ADRESSE DE LIVRAISON',
                    en: 'DELIVERY ADDRESS',
                    nl: 'LEVERINGS ADRES',
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    'background-color': 'rgba(217, 217, 217, 1.0)',
                    width: '76mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'font-weight': 'bold',
                  },
                  html: {
                    fr: 'ADRESSE DE FACTURATION',
                    en: 'BILLING ADDRESS',
                    nl: 'FACTURATIE ADRES',
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    'background-color': 'rgba(217, 217, 217, 1.0)',
                    width: '38mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'font-weight': 'bold',
                  },
                  html: {
                    fr: 'NOM ET SIGNATURE',
                    en: 'NAME AND SIGNATURE',
                    nl: 'HANDTEKENING',
                  }[lang],
                },
              ],
            },
            {
              style: {
                display: 'table-row',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    width: '76mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'white-space': 'pre-wrap',
                    'vertical-align': 'top',
                  },
                  children: PrintingUtil.formatAddress(
                    this.sale.customer.name,
                    this.sale.delivery_contact,
                    this.sale.delivery_address_text,
                    null,
                    this.sale.opening_hours
                  ),
                },
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    width: '76mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'white-space': 'pre-wrap',
                    'vertical-align': 'top',
                  },
                  children: PrintingUtil.formatAddress(
                    this.sale.customer.name,
                    this.sale.invoicing_contact,
                    this.sale.invoicing_address_text,
                    this.sale.customer.vat_number,
                    null,
                    lang
                  ),
                },
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    width: '38mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    'line-height': '5mm',
                    'white-space': 'pre',
                    position: 'relative',
                    'vertical-align': 'top',
                  },
                  children: [
                    {
                      style: {
                        'text-align': 'center',
                        'white-space': 'pre',
                      },
                      html: '\n\n\n\nLe service commercial',
                    },
                    {
                      style: {
                        'font-weight': 'bold',
                        'text-align': 'center',
                      },
                      html: 'Christophe Toupy',
                    },
                    {
                      style: {
                        position: 'absolute',
                        left: '0mm',
                        top: '0mm',
                        width: '34mm',
                        height: '25mm',
                        'background-image': 'url(assets/img/signature_blue.png)',
                        'background-size': 'contain',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },

        PrintingUtil.getFooter(this.sale.accounting, lang),
      ],
    };

    this.pages.push(page);

    //}
    return this;
  }

  public getArticleLines(sale: Sale, lang: string) {
    let headers: any[] = PrintingUtil.getArticleHeaders(sale.article, lang);
    let values: any[] = PrintingUtil.getArticleValues(sale.article, true);
    let arr: any[] = [];
    if (sale.article_reference && sale.article_reference.length > 0) {
      headers = [
        {
          fr: 'Votre référence :',
          nl: 'Uw referentie :',
          en: 'Your reference :',
        }[lang],
        ...headers,
      ];
      values = [sale.article_reference, ...values];
    }
    for (let i = 0; i < headers.length; ++i) {
      arr.push({
        style: {
          display: 'table-row',
          height: i < headers.length - 1 ? '1px' : 'auto',
        },
        children: [
          {
            style: {
              display: 'table-cell',
              'border-left': '.25mm solid rgba(0, 176, 240, 1.0)',
              'border-right': '.25mm solid rgba(0, 176, 240, 1.0)',
              width: '50mm',
              padding: '.5mm 1mm',
              'text-align': 'right',
              'white-space': 'pre-wrap',
              'line-height': '4mm',
              'vertical-align': 'top',
              'padding-left': '1mm',
              'padding-top': '0',
              'padding-right': '1mm',
              'padding-bottom': '0',
            },
            html: headers[i],
          },
          {
            style: {
              display: 'table-cell',
              'border-right': '.25mm solid rgba(0, 176, 240, 1.0)',
              padding: '.5mm 1mm',
              'white-space': 'pre-wrap',
              'line-height': '4mm',
              'vertical-align': 'top',
              'padding-left': '1mm',
              'padding-top': '0',
              'padding-right': '1mm',
              'padding-bottom': '0',
            },
            html: values[i],
          },
        ],
      });
    }
    if (arr.length > 0) {
      arr[arr.length - 1].children[0].style['border-bottom'] = '.25mm solid rgba(0, 176, 240, 1.0)';
      arr[arr.length - 1].children[1].style['border-bottom'] = '.25mm solid rgba(0, 176, 240, 1.0)';
    }
    return arr;
  }
}
