import { Injectable } from '@angular/core';
import { Currency } from 'src/app/modules/other-data/classes/Currency.class';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { ViewsComponent } from 'src/app/components/views/views.component';

@Injectable({
    providedIn: 'root'
})
export class CurrenciesService {

    public static currencies: Currency[] = null;
    public static defaultCurrency: Currency = null;

    constructor() { }

    public static init() : Promise<any>
    {
        return LoadingPromise.create<any>((resolve, reject) => {
            Currency.load(null, ['symbol']).then(
                (result: Currency[]) => {
                    CurrenciesService.currencies = result;
                    for(var i=0; i<result.length; ++i) {
                        if (result[i].id == 1) CurrenciesService.defaultCurrency = result[i];
                    }
                    resolve(result);
                },
                (err) => { console.error(err); reject(err); }
            );
        });
    }

    public static euroToCurrency(xrate: number, amount: number): number
    {
        return amount * (xrate || 1);
    }

    public static currencyToEuro(xrate: number, amount: number): number
    {
        return amount / (xrate || 1);
    }

}
