import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { ApiService } from '../../../../services/api/api.service';
import { CookieService } from 'ngx-cookie-service';
import { CredentialsService } from '../../../../services/credentials/credentials.service';
import { DataService } from 'src/app/services/data/data.service';
import { HomepageViewComponent } from '../../../homepage/views/homepage-view/homepage-view.component';
import { Merchant } from '../../../other-data/classes/Merchant.class';
import { User } from 'src/app/classes/credentials/User.class';
import { ViewBaseComponent } from '../../../../components/views/view-base.component';
import { ViewsComponent } from '../../../../components/views/views.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.css'],
})
export class LoginViewComponent extends ViewBaseComponent {
  public message: string = "Connexion à l'ERP ...";
  public users: User[] = [];

  constructor(private cookieService: CookieService, protected ref: ChangeDetectorRef) {
    super(ref);
  }

  ngOnInit() {
    this.cookieService.set('XDEBUG_SESSION', 'PHPSTORM');

    this.users = CredentialsService.users;

    if (this.isProd) {
      new Promise<any>((resolve, reject) => {
        let session: string = this.cookieService.get('PHPSESSID');
        console.log('session id:', session);
        if (!session || session == '') reject('no session');
        else {
          DataService.select({
            definition: {
              database: {
                table: 'user_sessions',
                id: 'id',
                db_id: null,
              },
              values: {
                session: { type: 'string' },
                ip: { type: 'string' },
                db_id_user: { type: 'string' },
                id_user: { type: 'string' },
              },
            },
            conditions: 'session="' + session + '"',
          }).then(
            (result: any) => {
              console.log('user_session result:', result);
              if (!result.result || result.result != 'success') {
                console.log('failed!');
                reject(result);
              }
              if (!Array.isArray(result.details) || result.details.length == 0) {
                console.log('empty array!');
                reject(result);
              }
              let db_id_user: string = result.details[0]['db_id_user'];
              let id_user: string = result.details[0]['id_user'];
              if (db_id_user == null) {
                console.log('db_id_user=null');
                reject(result);
              }
              if (!id_user) {
                console.log('!id_user');
                reject(result);
              }
              let id: string = db_id_user + ':' + id_user;
              console.log('loading user with id:', id);
              User.load([id]).then(
                (result: User[]) => {
                  if (!Array.isArray(result) || result.length == 0) {
                    console.log('empty array again!');
                    reject(result);
                  } else resolve(result[0]);
                },
                (err) => {
                  console.error('other err:', err);
                  reject(err);
                }
              );
            },
            (err) => {
              reject(err);
            }
          );
        }
      }).then(
        (result: User) => {
          this.message = "Connecté.<br/>Veuillez patienter pendant le chargement de l'ERP...";
          CredentialsService.login(result).then(
            (merchant: Merchant) => {
              ViewsComponent.openView(HomepageViewComponent);
            },
            (err) => {
              console.error('error during login:', err);
            }
          );
        },
        (err) => {
          this.message =
            'Erreur lors de la connexion à l\'ERP.<br/><a href="logout.php" class="btn btn-primary">Réessayer</a>';
          document.location.href = 'logout.php';
          console.error(err);
        }
      );
    }
  }

  public login(user: User) {
    CredentialsService.login(user).then(
      (merchant: Merchant) => {
        ViewsComponent.openView(HomepageViewComponent);
      },
      (err) => {
        console.error('error during login:', err);
      }
    );
  }

  public get isProd() {
    // return false;
    return environment.production === true;
  }
}
