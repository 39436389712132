import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WagesViewComponent } from './views/wages-view/wages-view.component';
import { ModuleBase } from '../module-base.class';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components/components.module';
import { ClassManager } from 'src/app/classes/objects/ClassManager.class';
import { Wage } from './classes/Wage.class';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ComponentsModule
    ],
    declarations: [
        WagesViewComponent
    ],
    exports: [
        WagesViewComponent
    ],
    entryComponents: [
        WagesViewComponent
    ]
})
export class WagesModule extends ModuleBase
{
    public static moduleName = 'stats';

    public ngOnInit()
    {
        ClassManager.registerClass('Wage', Wage);

        HomepageViewComponent.registerGroups({
            'stats': {
                'name': "Consolidations",
                'permission': 'home_groupe_consolidations',
                'backColor': 'rgb(112, 48, 160)',
                'textColor': 'white',
                'children': [
                    {
                        'name': "Salaires",
                        'permission': 'home_bouton_consolidations_salaires',
                        'backColor': 'rgb(198, 132, 247)',
                        'textColor': 'black',
                        'component': WagesViewComponent
                    },
                ]
            }
        });

        return super.ngOnInit();
    }
}
