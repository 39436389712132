import { ObjectModel } from "./ObjectModel.class";
import { ObjectChildDefinition } from "./ObjectChildDefinition.class";
import { ObjectLinkDefinition } from "./ObjectLinkDefinition.class";
import { EventEmitter } from "@angular/core";
import { ClassManager } from "./ClassManager.class";


export class ObjectModel2 extends ObjectModel
{
    public selected: boolean = false;
    public changed: boolean = false;

    public onSave: EventEmitter<any> = new EventEmitter<any>();
/*
    public assignFromObject(obj: any, children: string[] = null, links: string[] = null)
    {
        let currentClass: any = currentClass;
        let self = this;
        return LoadingPromise.create<any>((resolve, reject) => {
            let linksPromises: Promise<any>[] = [];
            if (currentClass.definition.database.id) {
                this[currentClass.definition.database.id] = +obj[currentClass.definition.database.id];
            }
            if (currentClass.definition.values) {
                for(const valueName in currentClass.definition.values) {
                    let valueDef: ObjectValueDefinition = currentClass.definition.values[valueName];
                    if (valueDef.type === 'integer') this[valueName] = +obj[valueName];
                    else if (valueDef.type === 'number') this[valueName] = +obj[valueName];
                    else if (valueDef.type === 'datetime') this[valueName] = new Date(obj[valueName]);
                    else if (valueDef.type !== 'unused-column') this[valueName] = obj[valueName];
                }
            }
            if (currentClass.definition.children) {
                //this.loadChildren(children, obj);
                let childrenNames = Object.keys(currentClass.definition.children);
                if (children != null) childrenNames = children;
                for(let i=0; i<childrenNames.length; ++i) // childName in currentClass.definition.children)
                {
                    (function(childName) {
                        let childDef: ObjectChildDefinition = currentClass.definition.children[childName];
                        let columnName: string = childDef.db_column || 'id_' + childName;
                        let id = +obj[columnName] || 0;
                        let childClass: any = ClassManager.getClass(childDef.type);
                        if (id > 0) linksPromises.push(childClass.load([id]).then(
                            function(results) {
                                self[childName] = results[0];
                                if (childDef.parent) {
                                    if (!results[0][childDef.parent]) results[0][childDef.parent] = [];
                                    results[0][childDef.parent].push(self);
                                }
                            },
                            function(err) { console.error('child', childName, 'load error:', err); }
                        ));
                    })(childrenNames[i]);
                }
            }
            if (currentClass.definition.links) {
                for(const linkName in currentClass.definition.links)
                {
                    let linkDef: ObjectLinkDefinition = currentClass.definition.links[linkName];
                    let columnName: string = linkDef.db_column || linkName;
                    this['id_'+linkName] = obj[columnName];
                }
                //console.log('ready to load links', links);
                //this.loadLinks(links);
                let linksNames = Object.keys(currentClass.definition.links);
                if (links != null) linksNames = links;
                for(let i=0; i<linksNames.length; ++i) //for(const linkName in currentClass.definition.links)
                {
                    (function(linkName) {
                        let linkDef: ObjectLinkDefinition = currentClass.definition.links[linkName];
                        let linkIds: string = obj[linkName];
                        if (linkIds != null)
                        {
                            let linkIdsArr: string[] = linkIds.split(",");
                            let linkClass: any = ClassManager.getClass(linkDef.type);
                            linksPromises.push(linkClass.load(linkIdsArr).then(
                                function(results) { self[linkName] = results; },
                                function(err) { console.error('link', linkName, 'load error:', err); }
                            ));
                        } else self[linkName] = [];
                    })(linksNames[i]);
                }
            }
            Promise.all(linksPromises).then(
                function(result) { resolve(self); },
                function(err) { console.error(err); }
            );
        });
    }

    // public static getAllIds(currentClass: any)
    // {
    //     return LoadingPromise.create<any>((resolve, reject) => {
    //         let className: string = currentClass.prototype.constructor.name;
    //         return DataService.createCommand('GET_IDS', className).send().then(
    //             (result) => { resolve(result); },
    //             (err) => { reject(err); }
    //         );
    //     });
    // }

    public static _load(currentClass, ids: number[] = null, orderBy: string[] = null, deleted: boolean = false,
                        children: string[] = null, links: string[] = null, conditions: string = null, forceReload: boolean = false)
    {
        return LoadingPromise.create<any>((resolve, reject) => {
            if (ids) {
                for(let i=0; i<ids.length; ++i) ids[i] = parseInt(''+ids[i]);
            }
            if (currentClass.definition.trashDelete === true)
            {
                conditions = (conditions != null ? '(' + conditions + ') AND ' : '') + 'deleted ' + (deleted === true ? '=' : '<>') + ' 1';
            }
            let ids_promises: Promise<any>[] = [];
            // if (!ids) ids_promises.push(ObjectModel2.getAllIds(currentClass).then(
            //     (result: number[]) => { ids = result; },
            //     (err) => { console.error(err); }
            // ));
            Promise.all(ids_promises).then(
                (result) => {
                    let ids_to_load = ids ? ArrayUtil.cloneArray(ids) : null;
                    console.log("loading - ", ids_to_load, currentClass.loaded_ids, forceReload);
                    if (ids_to_load && Array.isArray(currentClass.loaded_ids) && !forceReload)
                    {
                        ArrayUtil.removeElements(ids_to_load, currentClass.loaded_ids);
                    }
                    // HttpUtil.post(AppConfig.baseUrl + 'modules/database/select.php', {
                    //     definition: currentClass.definition,
                    //     ids: ids,
                    //     orderBy: orderBy,
                    //     conditions: conditions
                    // }).subscribe(
                    DataService.select({
                        definition: currentClass.definition,
                        ids: ids,
                        orderBy: orderBy,
                        conditions: conditions
                    }).then(
                        function(result: any) {
                            if (result.result && result.result != 'success')
                            {
                                let notif: Notification = {
                                    type: 'error',
                                    title: 'Erreur base de données',
                                    summary: 'Echec de lecture dans la base de données'
                                };
                                if (result.details)
                                {
                                    if (result.details.sql_error) notif.content = 'SQL error : ' + result.details.sql_error;
                                    else notif.content = result.details;
                                }
                                NotificationsComponent.push(notif);
                                console.error(result);
                                reject(result);
                            }
                            else
                            {
                                let objects: any[] = [];
                                let promises: Promise<any>[] = [];
                                let instances: any = ObjectModel2.getInstances(currentClass);
                                let loaded_ids: number[] = ObjectModel2.getLoadedIds(currentClass);
                                console.log('result obj2:', result);
                                for(let i=0; i<result.details.length; ++i)
                                {
                                    let id = result.details[i].id;
                                    let obj: ObjectModel2 = null;
                                    let instance = instances[id];
                                    if (instance) {
                                        obj = instance;
                                        if (forceReload === true) promises.push(obj.assignFromObject(result.details[i], children, links));
                                    } else {
                                        obj = new currentClass();
                                        instances[id] = obj;
                                        if (!loaded_ids.includes(id)) loaded_ids.push(id);
                                        promises.push(obj.assignFromObject(result.details[i], children, links));
                                    }
                                    //objects.push(obj);
                                }
                                if (!ids) ids = loaded_ids;
                                for(let i=0; i<ids.length; ++i)
                                {
                                    objects.push(instances[ids[i]]);
                                }
                                Promise.all(promises).then(
                                    function(result) { resolve(objects); },
                                    function(err) {
                                        let notif: Notification = {
                                            type: 'error',
                                            title: 'Erreur de chargement',
                                            summary: 'Une erreur s\'est produite lors du chargement des données',
                                            content: err
                                        }
                                        NotificationsComponent.push(notif);
                                        console.error(err);
                                        reject(err);
                                    }
                                );
                            }
                        },
                        function(err) {
                            let notif: Notification = {
                                type: 'error',
                                title: 'Erreur de chargement',
                                summary: 'Une erreur s\'est produite lors de l\'appel au serveur',
                                content: err
                            }
                            NotificationsComponent.push(notif);
                            console.error('objectmodel load error:', err);
                            reject(err);
                        }
                    );
                },
                (err) => { console.error(err); }
            );
        });
    }*/

    /*public loadChild(childName: string)
    {
        let currentClass: any = currentClass;
        let self = this;
        return LoadingPromise.create<any>((resolve, reject) => {
            let childDefinition: ObjectChildDefinition = currentClass.definition.children[childName];
            if (!childDefinition)
            {
                console.error("can't get child definition for", childName, "on class", currentClass);
                reject(false);
            }
            let childType: any = childDefinition.type;
        });
    }*/

    /*public saveToDatabase(data: any)
    {
        let currentClass: any = currentClass;
        let self = this;
        return LoadingPromise.create<any>((resolve, reject) => {
            if (!currentClass.definition.database) reject('No database info specified for class' + currentClass);
            else if (!currentClass.definition.database.table) reject('No database table specified for class' + currentClass);
            else
            {
                let action: DatabaseAction = new DatabaseAction();
                action.action = (!data.id || data.id <= 0) ? 'insert' : 'update';
                action.table = currentClass.definition.database.table;
                action.id = (!data.id || data.id <= 0) ? null : data.id;
                action.data = data;
                action.execute(this).then(
                    function(result: any) { resolve(result); },
                    function(err) { reject(err); }
                );
            }   
        });
    }

    public saveLinks(linkName: string, sequence: number)
    {
        let currentClass: any = currentClass;
        let self = this;
        return LoadingPromise.create<any>((resolve, reject) => {
            let links = this[linkName];
            let linkDef: ObjectLinkDefinition = currentClass.definition.links[linkName];
            let promises: Promise<any>[] = [];
            if (linkDef.save === true) {
                for(let i=0; i<links.length; ++i) {
                    let link = links[i]
                    let id = +link.id || 0;
                    if (id > 0) {
                    }
                    promises.push(link.save2(sequence).then(
                        function(result) { resolve(result); },
                        function(err) { console.error('link', linkName, 'save error:', err); reject(err); }
                    ));
                }
            }
            Promise.all(promises).then(
                function(result) {
                    let actionsGroup: DatabaseActionGroup = new DatabaseActionGroup();
                    let objects: ObjectModel2[] = [];
                    let deleteData = '`' + linkDef.id_self + '` = ' + self.id;// + ' AND `' + linkDef.id_link + '` = ' + id;
                    actionsGroup.actions.push(DatabaseAction.delete(linkDef.table, deleteData));
                    for(let i=0; i<links.length; ++i) {
                        let link = links[i];
                        let id = +link.id || 0;
                        if (id > 0) {
                            let data = {};
                            data[linkDef.id_self] = self.id;
                            data[linkDef.id_link] = id;
                            objects.push(link);
                            actionsGroup.actions.push(DatabaseAction.insert(linkDef.table, data));
                        }
                    }
                    actionsGroup.execute(objects).then(
                        function(result) { resolve(result); },
                        function(err) { console.error('links save error:', err); reject(err); }
                    )
                },
                function(err) { console.error('links save error:', err); reject(err); }
            );
        });
    }*/

    public static nextSequence: number = 1;
    /*
    public static currentlySaving: ObjectModel2[] = [];
    public static toSaveAgain: ObjectModel2[] = [];

    public static saveRemainingObjects()
    {
        let objects: ObjectModel2[] = ObjectModel2.toSaveAgain;
        ObjectModel2.toSaveAgain = [];
        let promises: Promise<any>[] = [];
        for(let i=0; i<objects.length; ++i) {
            console.log('re-saving', objects[i]);
            promises.push(objects[i].save2());
        }
        return Promise.all(promises);
    }

    public save2(sequence: number = null): Promise<any>
    {
        let mustNotify: boolean = false;
        let mustSaveRemaining: boolean = false;
        if (sequence == null) {
            mustNotify = true;
            mustSaveRemaining = true;
            sequence = ObjectModel2.nextSequence++;
        }
        let self = this;
        let currentClass: any = self.currentClass;
        return LoadingPromise.create<any>((resolve, reject) => {
            let data: any = {
                'id': self.id
            };
            ObjectModel2.currentlySaving.push(this);
            let savePromises: Promise<any>[] = [];
            if (currentClass.definition.values) {
                for(const valueName in currentClass.definition.values) {
                    let valueDef: ObjectValueDefinition = currentClass.definition.values[valueName];
                    if (valueDef.type == 'html') data[valueName] = 'HTML:' + self[valueName];
                    else if (valueDef.type == 'number') data[valueName] = 'INT:' + self[valueName];
                    else if (valueDef.type == 'string') data[valueName] = self[valueName] == null ? null : 'TEXT:' + self[valueName];
                    else if (valueDef.type == 'datetime') data[valueName] = 'DATETIME:' + DateTimeUtil.toDatabaseDateTimeString(self[valueName] as Date);
                    else if (valueDef.type == 'date') data[valueName] = 'DATE:' + DateTimeUtil.toDatabaseDateString(self[valueName] as Date);
                    else data[valueName] = self[valueName];
                }
            }
            if (currentClass.definition.children) {
                for(const childName in currentClass.definition.children) {
                    let childDef: ObjectChildDefinition = currentClass.definition.children[childName];
                    let fieldName = currentClass.definition.children[childName].db_column || ('id_' + childName);
                    let child = self[childName];
                    if (child == null) data[fieldName] = null;
                    else {
                        if (+child.id == 0 && ObjectModel2.currentlySaving.includes(child)) {
                            console.log('child', childName, 'already saving, putting on the list to get id later...');
                            ObjectModel2.toSaveAgain.push(this);
                            data[fieldName] = null;
                        }
                        else if (childDef.save === true || +child.id <= 0) {
                            savePromises.push(child.save2(sequence).then(
                                (result) => {
                                    data[fieldName] = result.id;
                                },
                                (err) => {
                                    console.error('child save', childName, 'error:', err);
                                    ArrayUtil.removeElements(ObjectModel2.currentlySaving, [this]);
                                    reject(err);
                                }
                            ));
                        }
                        else data[fieldName] = child.id;
                    }
                }
            }
            Promise.all(savePromises).then(
                (result) => {
                    currentClass.prototype.saveToDatabase.call(self, data).then(
                        (result) => { 
                            let mustResolve: boolean = true;
                            let linkPromises: Promise<any>[] = [];
                            if (currentClass.definition.children) {
                                for(const childName in currentClass.definition.children) {
                                    let childDef: ObjectChildDefinition = currentClass.definition.children[childName];
                                    let dataName: string = childDef.db_column ? childDef.db_column : 'id_' + childName;
                                    let child: ObjectModel2 = self[childName];
                                    data[dataName] = child ? child.id : null;
                                }
                            }
                            if (currentClass.definition.links) {
                                for(const linkName in currentClass.definition.links) {
                                    //let linkDef: ObjectLinkDefinition = currentClass.definition.links[linkName];
                                    linkPromises.push(self.saveLinks(linkName, sequence).then(
                                        (result) => { },
                                        (err) => { console.error('error while saving link for', linkName, ':', err); }
                                    ));
                                }
                            }
                            Promise.all(linkPromises).then(
                                (result) => {
                                    self.changed = false;
                                    if (mustNotify === true)
                                    {
                                        NotificationsComponent.push({
                                            type: 'success',
                                            title: 'Enregistrement effectué',
                                            summary: TextUtil.capitalizeFirst(currentClass.definition.name ?
                                                                                currentClass.definition.name[1] + currentClass.definition.name[0] :
                                                                                'l\'objet')
                                                        + ' a été enregistré avec succès'
                                        });
                                    }
                                    if (mustResolve) {
                                        self.changed = false;
                                        self.onSave.next(self);
                                        ArrayUtil.removeElements(ObjectModel2.currentlySaving, [this]);
                                        if (mustSaveRemaining) {
                                            ObjectModel2.saveRemainingObjects().then(
                                                (result) => { resolve(self); },
                                                (err) => { reject(err); }
                                            );
                                        } else resolve(self);
                                    }
                                },
                                (err) => {
                                    if (mustNotify === true)
                                    {
                                        NotificationsComponent.push({
                                            type: 'error',
                                            title: 'Erreur d\'enregistrement',
                                            summary: 'Erreur lors de l\'enregistrement '
                                                        + (currentClass.definition.name ?
                                                            currentClass.definition.name[2] + currentClass.definition.name[0] :
                                                            'de l\'objet'),
                                            content: JSON.stringify(err)
                                        });
                                    }
                                    console.error('save to db error:', err);
                                    ArrayUtil.removeElements(ObjectModel2.currentlySaving, [this]);
                                    reject(err);
                                }
                            );
                        },
                        (err) => {
                            if (mustNotify === true)
                            {
                                NotificationsComponent.push({
                                    type: 'error',
                                    title: 'Erreur d\'enregistrement',
                                    summary: 'Erreur lors de l\'enregistrement '
                                                + (currentClass.definition.name ?
                                                    currentClass.definition.name[2] + currentClass.definition.name[0] :
                                                    'de l\'objet'),
                                    content: JSON.stringify(err)
                                });
                            }
                            console.error('save error:', err);
                            ArrayUtil.removeElements(ObjectModel2.currentlySaving, [this]);
                            reject(err);
                        }
                    );
                },
                (err) => {
                    if (mustNotify === true)
                    {
                        NotificationsComponent.push({
                            type: 'error',
                            title: 'Erreur d\'enregistrement',
                            summary: 'Erreur lors de l\'enregistrement '
                                        + (currentClass.definition.name ?
                                            currentClass.definition.name[2] + currentClass.definition.name[0] :
                                            'de l\'objet'),
                            content: JSON.stringify(err)
                        });
                    }
                    console.error('save error:', err);
                    ArrayUtil.removeElements(ObjectModel2.currentlySaving, [this]);
                    reject(err);
                }
            );
        });
    }*/

    /*public delete(): Promise<any>
    {
        let currentClass: any = currentClass;
        let self = this;
        if (currentClass.definition.trashDelete === true) return this.moveToTrash();
        else return LoadingPromise.create<any>((resolve, reject) => {
            let id = currentClass.definition.database.id ? this[currentClass.definition.database.id] : this.id;
            let promises: Promise<any>[] = [];
            if (currentClass.definition.children) {
                for(const childName in currentClass.definition.children)
                {
                    (function(childName) {
                        let childDef: ObjectChildDefinition = currentClass.definition.children[childName];
                        let child: ObjectModel2 = self[childName];
                        if (child && childDef.delete === true) promises.push(child.delete());
                    })(childName);
                }
            }
            if (currentClass.definition.links) {
                for(const linkName in currentClass.definition.links) {
                    (function(linkName) {
                        let linkDef: ObjectLinkDefinition = currentClass.definition.links[linkName];
                        let links: ObjectModel2[] = self[linkName];
                        if (linkDef.delete === true) {
                            for(let i=0; i<links.length; ++i) promises.push(links[i].delete());
                        }
                    })(linkName);
                }
            }
            Promise.all(promises).then(
                function(result) {
                    let db_action: DatabaseAction = DatabaseAction.delete(self.currentClass.definition.database.table, 'id = ' + self.id);
                    db_action.execute().then(
                        function(result) { console.log('object deleted:', self); resolve(result); },
                        function(err) { console.error('object delete error:', err); reject(err); }
                    );
                },
                function(err) { console.error('object delete error:', err); reject(err); }
            );
        });
    }*/
/*
    public moveToTrash(restore: boolean = false)
    {
        return LoadingPromise.create<any>((resolve, reject) => {
            if (this.id > 0)
            {
                let def: ObjectDefinition = currentClass.definition;
                let db_action: DatabaseAction = DatabaseAction.update(
                    def.database.table,
                    { 'deleted': restore === true ? 0 : 1 },
                    this.id
                );
                db_action.execute(this).then(
                    function(result) { resolve(result); },
                    function(err) { reject(err); }
                );
            }
            else reject(false);
        });
    }*/
}