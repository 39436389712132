import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsViewComponent } from './views/settings-view/settings-view.component';
import { Setting } from './classes/Setting.class';
import { ClassManager } from 'src/app/classes/objects/ClassManager.class';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { ModuleBase } from '../module-base.class';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule
  ],
  declarations: [SettingsViewComponent],
  exports: [SettingsViewComponent],
  entryComponents: [SettingsViewComponent]
})
export class SettingsModule extends ModuleBase {
    
    public static moduleName = 'settings';
    
    public ngOnInit()
    {
        ClassManager.registerClass('Setting', Setting);

        Toolbar.registerMenuItem({
            name: 'Paramètres',
            icon: ' fas fa-cog',
            viewComponent: SettingsViewComponent
        });
        
        return super.ngOnInit();
    }
    
}
