<div class="overlay" (click)="closeFilter()"></div>
<div class="filter-action" *ngFor="let action of filter.sortActions" (click)="sortActionClicked(action)">
  <img src="{{ action.img ? ('assets/img/icons/20x20/dark-blue/' + action.img + '.png') : '' }}" width="20"
    height="20" />
  <span>{{ action.displayName }}</span>
</div>
<div class="separator"></div>
<div class="filter-action" (click)="clearFilter()">
  <i class="fa fa-filter" style="color: red;"></i>
  <span>Effacer le filtre</span>
</div>
<div class="separator"></div>
<div class="filter-action" *ngIf="filter.filterActionsName">
  <img
    src="{{ 'assets/img/icons/20x20/dark-blue/' + (activeFilterAction ? 'filter-check' : 'transparent-icon') + '.png' }}"
    width="20" height="20" />
  <span>{{ filter.filterActionsName }}</span>
  <i class="fa fa-right-arrow"></i>
  <ul class="filter-actions">
    <li *ngFor="let action of filter.filterActions" (click)="showCustomFilter(action)"
      [class]="!action ? 'separator' : ''">
      {{ action ? action.displayName : '' }}
    </li>
  </ul>
</div>
<div class="separator"></div>
<input type="text" class="filter-search" [(ngModel)]="availableValuesSearch" />
<ul class="available-values">
  <li *ngFor="let value of availableValues; let index = index" [className]="value[0]" (click)="toggleValue(value[1])">
    {{ value[2] }}
  </li>
</ul>
<div class="custom-filter-overlay" *ngIf="!!showCustomFilterWindow">
  <div class="customer-filter-window">
    <div class="title">
      Filtre personnalisé
      <i class="fa fa-times" (click)="showCustomFilterWindow = false"></i>
    </div>
    <div class="content">
      <div class="filter-row">
        <select [(ngModel)]="customFilterOperator1">
          <option [ngValue]="null"></option>
          <option *ngFor="let operator of filter.operatorsList" [ngValue]="operator">{{ operator.displayName }}</option>
        </select>
        <input type="text" [(ngModel)]="customFilterValue1" />
      </div>
      <label>
        <input type="radio" name="operand" [value]="0" [(ngModel)]="customFilterOperand" />
        <span>ET</span>
      </label>
      <label>
        <input type="radio" name="operand" [value]="1" [(ngModel)]="customFilterOperand" />
        <span>OU</span>
      </label>
      <div class="filter-row">
        <select [(ngModel)]="customFilterOperator2">
          <option [ngValue]="null"></option>
          <option *ngFor="let operator of filter.operatorsList" [ngValue]="operator">{{ operator.displayName }}</option>
        </select>
        <input type="text" [(ngModel)]="customFilterValue2" />
      </div>
      <button (click)="validateCustomFilter()">OK</button>
    </div>
  </div>
</div>
