<ul>
    <li *ngFor="let addr of addresses">
        <input type="radio" name="selectedAddress" [(ngModel)]="selectedAddress" [value]="addr.address" />
        <div class="address-text" (click)="selectedAddress = addr.address">
            <b>{{addr.name}}</b>
            <span>{{addr.address.getFullAddress()}}</span>
        </div>
    </li>
</ul>
<div class="buttons">
    <button class="btn btn-primary" (click)="selectButtonClick()">Sélectionner</button>
    <button class="btn btn-danger" (click)="cancelButtonClick()">Annuler</button>
</div>
