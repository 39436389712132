import { Component, ViewChild } from '@angular/core';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { DataGridComponent } from 'src/app/components/data-grid/data-grid.component';
import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { SupplierPayment } from '../../classes/SupplierPayment.class';
import { Supplier } from 'src/app/modules/suppliers/classes/Supplier.class';
import { Invoice } from 'src/app/modules/invoicing/classes/Invoice.class';
import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { CurrenciesService } from 'src/app/services/currencies/currencies.service';
import { LoadingPromise } from '../../../../classes/objects/LoadingPromise.class';
import { ApiService } from '../../../../services/api/api.service';

@Component({
  selector: 'app-supplier-payments-list-view',
  templateUrl: './supplier-payments-list-view.component.html',
  styleUrls: ['./supplier-payments-list-view.component.css']
})
export class SupplierPaymentsListViewComponent extends AccessViewComponent {

  public permission: string = 'ecriture_paiements_fournisseurs';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Paiements fournisseurs',
    data: this,
    elements: [
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Enregistrer',
        icon: 'save',
        click: function (view: SupplierPaymentsListViewComponent) {
          view.save();
        },
        access: this.writeAccess
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Ajouter un<br/>paiement',
        icon: 'plus',
        click: function (view: SupplierPaymentsListViewComponent) {
          view.createPayment();
        },
        access: this.writeAccess
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Archiver',
        icon: ' fas fa-archive',
        click: function (view: SupplierPaymentsListViewComponent) {
          view.archiveSelection();
        },
        access: this.writeAccess
      },
      { type: 'separator-large' },
      {
        type: 'toggle-button',
        text: 'Activer<br/>l\'édition',
        icon: 'edit',
        value: false,
        click: function (view: SupplierPaymentsListViewComponent) {
          view.grid.editable = this.value && view.writeAccess;
          // view.reloadPayments();
          //view.updateSuppliersList();
        }
      },
      { type: 'separator-large' },
      {
        type: 'toggle-button',
        text: 'Afficher les<br/>paiements archivés',
        icon: 'filter',
        value: false,
        click: function (view: SupplierPaymentsListViewComponent) {
          view.filterPayments = !view.filterPayments;
          view.reloadPayments();
          //view.updateSuppliersList();
        }
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Valider les<br/>paiements',
        icon: 'check',
        click: function (view: SupplierPaymentsListViewComponent) {
          view.checkPayments();
          //view.updateSuppliersList();
        }
      },
      { type: 'spacing' },
    ]
  };

  public suppliersData: any = { items: [] };
  public invoicesData: any = { items: [] };
  public currenciesData: any = { items: [] };
  public payments: SupplierPayment[] = [];
  public filterPayments: boolean = true;
  public get filteredPayments(): SupplierPayment[] {
    if (!this.filterPayments) return this.payments;
    return this.payments.filter((value: SupplierPayment, index, arr) => value.archived != 1);
  }

  public paymentsGridColumns = [
    { title: 'N°', field: 'number', width: 70 },
    {
      title: 'Devise', field: 'currency', type: 'foreign-list',
      multiSelect: false, listItems: this.currenciesData, listField: 'fullDescription', allowBlankValues: false, width: 130,
      change: (item, column) => { item.xrate = item.currency.rate; }
    },
    { title: 'Taux de change', field: 'xrate', type: 'number', decimalsCount: 6, textAlign: 'left', width: 90 },

    {
      title: 'Nom du fournisseur', field: 'supplier', type: 'foreign-list', listField: 'name',
      listItems: this.suppliersData, multiSelect: false, nullValue: '(Aucun)', width: 220
    },
    { title: 'Date de la\nfacture', field: 'invoice_date', type: 'date', width: 130 },
    { title: 'N° de la facture\nfournisseur', field: 'supplier_reference' },
    {
      title: 'Montant\nH.T.V.A.', field: 'converted_amount', type: 'number', currencyField: 'currency', decimalsCount: 2, width: 100,
      change: (item: SupplierPayment, column: any) => {
        item.total_to_pay = item.amount * (1 + item.supplier.vat / 100);
      }
    },
    { title: 'Montant total\nà payer', field: 'converted_total_to_pay', type: 'number', currencyField: 'currency', decimalsCount: 2, width: 100 },
    {
      title: 'N° de facture\nclient à imputer', field: 'invoice', type: 'foreign-list', listItems: this.invoicesData, width: 200,
      listField: 'number', multiSelect: false, nullValue: '(Aucun)', getOptionStyle: (item: Invoice) => {
        return item.is_logistic ? 'invoice_logistic' : 'invoice_commercial';
      }
    },
    // { title: 'Montant\nacheté', field: 'invoice.total_buyPrice', type: 'number', unit: '€', decimalsCount: 2, readonly: true },
    { title: 'Client à imputer', field: 'customer.nameWithIdentifier', readonly: true, width: 200 },
    { title: 'Date d\'échéance\ndu fournisseur', field: 'supplier_limit', type: 'date', width: 130 },
    { title: 'Date d\'échéance\ndu client', field: 'customer_limit', type: 'date', width: 130, readonly: true },
    { title: 'Etat de la\nfacture client', field: 'customer_status.name', readonly: true, width: 120 },
    { title: 'Date de paiement\ndu fournisseur', field: 'payment_date', type: 'date', width: 130 },
    { title: 'Dossier terminé', field: 'ended', type: 'checkbox', readonly: true, width: 90 },
    { title: 'Remarques', field: 'remarks', width: 250 }
    //{ title: 'Statut', field: 'payment.status', listItems: this.statusesData, nameField: 'name', }
  ];

  @ViewChild('grid') grid: DataGridComponent;

  ngOnInit() {
    this.currenciesData.items = CurrenciesService.currencies;
    Supplier.load().then(
      (result) => { this.suppliersData.items = result; },
      (err) => { console.error(err); }
    );
    this.reloadPayments();
  }
  onAccountingChanged(accounting: Accounting) {
    this.reloadPayments();
  }
  reloadPayments() {
    let conditions: string[] = [];
    if (AccountingsService.currentAccounting) conditions.push('db_id_accounting=' + AccountingsService.currentAccounting.db_id + ' AND id_accounting=' + AccountingsService.currentAccounting.id);
    Invoice.load(null, ['number'], null, null,
      conditions.length > 0 ? conditions.join(' AND ') : null, true).then(
        (result: Invoice[]) => {
          this.invoicesData.items = result.sort((a: Invoice, b: Invoice) => {
            if (a.is_logistic && !b.is_logistic) return 1;
            else if (!a.is_logistic && b.is_logistic) return -1;
            else return a.number.localeCompare(b.number);
          });
        },
        (err) => { console.error(err); }
      );
    if (this.filterPayments) conditions.push('archived = 0');
    SupplierPayment.load(null, ['~number'], null, null,
      conditions.length > 0 ? conditions.join(' AND ') : null, true).then(
        (result) => { this.payments = result; },
        (err) => { console.error(err); }
      );
  }

  onActivate() {
    //this.loadOrders();
  }

  gridRowStyle(item: SupplierPayment) {
    return (item && item.archived == 1) ? {
      'color': 'gray',
      'font-style': 'italic'
    } : null;
  }

  archiveSelection() {
    let selection: SupplierPayment[] = this.grid.selectedItems;
    let promises: Promise<any>[] = [];
    let canArchive: boolean = true;
    for (let i = 0; i < selection.length; ++i) {
      if (selection[i].ended != true) {
        promises.push(DialogsComponent.display({
          title: 'Paiements non terminés',
          message: 'Dans votre sélection, un ou plusieurs paiements ne sont pas terminés.<br/>Êtes-vous sûr(e) de vouloir les archiver ?',
          buttons: DialogButton.yesNoButtons,
          icon: 'warning'
        }).then((result) => {
          if (result != DialogButton.RESULT_YES) canArchive = false;
        }));
      }
    }
    Promise.all(promises).then(
      (result) => {
        if (canArchive == true) this.save().then(
          (result) => {
            let ids: string[] = [];
            for (let i = 0; i < selection.length; ++i) ids.push(selection[i].fullId);
            ApiService.callModule('payments', 'archive', { ids: ids, archive: 1 }).then(
              (result) => {
                NotificationsComponent.push({
                  title: 'Paiements archivés',
                  summary: '<b>' + ids.length + '</b> paiements ont été archivés.',
                  type: 'success'
                });
                this.grid.clearSelection();
                this.reloadPayments();
              },
              (err) => {
                NotificationsComponent.push({
                  title: 'Échec d\'archivage',
                  summary: 'L\'archivage des paiements a échoué.',
                  content: err,
                  type: 'error'
                });
              }
            );
          },
          (err) => {
            console.error(err);
          }
        );
      }
    );
  }

  save() {
    return LoadingPromise.create<any>((resolve, reject) => {
      // let seq = Date.now();
      let promises: Promise<any>[] = [];
      for (let i = 0; i < this.payments.length; ++i) {
        if (this.payments[i].changed === true) promises.push(this.payments[i].save3(false));
      }
      if (promises.length > 0) {
        Promise.all(promises).then(
          (result) => {
            NotificationsComponent.push({
              title: 'Sauvegarde effectuée',
              summary: '<b>' + promises.length + ' paiements</b> ont été sauvegardés avec succès.',
              type: 'success'
            });
            resolve(result);
          },
          (err) => {
            NotificationsComponent.push({
              title: 'Erreur',
              summary: 'Une erreur s\'est produite lors de la sauvegarde.',
              type: 'error',
              content: err
            });
            reject(err);
          }
        );
      } else resolve(true);
    });
  }

  createPayment() {
    // ViewsComponent.openView(SupplierPaymentViewComponent);
    let payment: SupplierPayment = new SupplierPayment();
    payment.accounting = AccountingsService.currentAccounting;
    payment.currency = payment.accounting.default_currency;
    payment.xrate = payment.currency.rate || 1;
    payment.getNextNumber().then(
      (result) => {
        if (result.result === 'success') {
          let num: string = payment.generateNumber(result.details);
          for (let i = 0; i < this.payments.length; ++i) {
            let pnum: string = this.payments[i].number;
            if (pnum >= num) num = pnum.substr(0, 3) + ('0000' + (parseInt(pnum.substr(3)) + 1)).slice(-4);
          }
          payment.number = num;
        }
      },
      (err) => { console.error(err); }
    );
    this.payments.push(payment);
  }

  checkPayments() {
    const paymentsWithoutId = this.payments.filter(p => !(p.id > 0));
    if (paymentsWithoutId.length > 0) {
      NotificationsComponent.push({
        type: 'error',
        title: 'Paiements non sauvegardés !',
        summary: 'Certains paiements n\'ont pas été sauvegardés',
        content: 'Les paiements ' + paymentsWithoutId.map(p => p.number).join(', ') + ' n\'ont pas été sauvegardés',
      });
    }
    else {
      NotificationsComponent.push({
        type: 'success',
        title: 'Paiements OK',
        summary: 'Tous les paiements sont bien enregistrés dans la base de données',
        content: 'Les paiements ' + paymentsWithoutId.map(p => p.number).join(', ') + ' n\'ont pas été sauvegardés',
      });
    }
  }

}
