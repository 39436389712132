import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassManager } from '../../classes/objects/ClassManager.class';
import { ModuleBase } from '../module-base.class';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { Order } from './classes/Order.class';
import { OrderViewComponent } from './views/order-view/order-view.component';
import { ComponentsModule } from '../../components/components.module';
import { FormsModule } from '@angular/forms';
import { OrdersListViewComponent } from './views/orders-list-view/orders-list-view.component';
import { DeliveryNotePrintingModel } from './classes/DeliveryNotePrintingModel.class';
import { ChangeOrderNumberComponent } from './change-order-number/change-order-number.component';
import { OrdersFollowupViewComponent } from './views/orders-followup-view/orders-followup-view.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule
  ],
  declarations: [
      OrderViewComponent,
      OrdersListViewComponent,
      ChangeOrderNumberComponent,
      OrdersFollowupViewComponent
  ],
  exports: [
      OrderViewComponent,
      OrdersListViewComponent,
      ChangeOrderNumberComponent,
      OrdersFollowupViewComponent
  ],
  entryComponents: [
      OrderViewComponent,
      OrdersListViewComponent,
      ChangeOrderNumberComponent,
      OrdersFollowupViewComponent
  ]
})
export class OrdersModule extends ModuleBase {

    public static moduleName = 'orders';

    public ngOnInit()
    {
        ClassManager.registerClass('Order', Order);

        HomepageViewComponent.registerGroups({
            'offers': {
                'name': "Commandes",
                'backColor': 'rgb(255, 192, 0)',
                'textColor': 'black',
                'permission': 'home_groupe_commandes',
                'children': [
                    {
                        'permission': 'home_bouton_commandes_choixoffre',
                        'name': "Choix de l'offre à traiter",
                        'component': OrderViewComponent
                    },
                    {
                        'permission': 'home_bouton_commandes_livre',
                        'name': "Livre des commandes",
                        'component': OrdersListViewComponent
                    },
                    {
                        'permission': 'home_bouton_commandes_suivi',
                        'backColor': 'rgb(255, 232, 0)',
                        'name': "Suivi des commandes",
                        'component': OrdersFollowupViewComponent
                    }
                ]
            }
        });

        return super.ngOnInit();
    }

}
