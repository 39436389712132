import { ObjectUtil } from "./ObjectUtil.class";

export class ArrayUtil
{
    public static cloneArray(arr: Array<any>, cloneItems: boolean = false, deepClone: boolean = false): Array<any>
    {
        let copy: Array<any> = [];
        for(let i=0; i<arr.length; ++i) {
            let value = arr[i];
            if (cloneItems) {
                if (Array.isArray(value)) { console.log('cloning array array', i); value = ArrayUtil.cloneArray(value, cloneItems, deepClone); }
                else if (typeof value === 'object') { console.log('cloning array object', i); value = ObjectUtil.cloneObject(value, deepClone); }
            }
            copy.push(value);
        }
        return copy;
    }

    public static removeElements(arr: any[], elements: any[]): number
    {
        let count: number = 0;
        for(let i=0; i<elements.length; ++i)
        {
            let index = arr.indexOf(elements[i]);
            if (index >= 0)
            {
                arr.splice(index, 1);
                ++count;
            }
        }
        return count;
    }

    public static objectToArray(object: any)
    {
        let arr: any[] = [];
        for (const key in object) arr.push(object[key]);
        return arr;
    }

    public static toggleItem(arr: any[], item: any)
    {
        let index = arr.indexOf(item);
        if (index >= 0) arr.splice(index, 1);
        else arr.push(item);
    }

    public static sortByField(arr: any[], field: string)
    {
        arr.sort((a: any, b: any) => {
            let _a = a[field];
            let _b = b[field];
            if (_a == _b) return 0;
            else if (_a < _b) return -1;
            else return 1;
        });
    }

    public static sortQuantityArray(arr: any[], textField: any): any[]
    {
        return arr.sort((a: any, b:any) => {
            return (textField ? ArrayUtil.compareQuantityValues(a[textField], b[textField])
                              : ArrayUtil.compareQuantityValues(a, b));
        });
    }
    private static compareQuantityValues(a: string, b: string): number
    {
        let a_num: number = parseFloat(a);
        let b_num: number = parseFloat(b);
        if (!a_num && !b_num || a_num === b_num) return a == b ? 0 : (a < b ? -1 : 1);
        else if (!a_num) return 1;
        else if (!b_num) return -1;
        else return a_num - b_num;
    }
}