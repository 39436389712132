import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Toolbar } from '../toolbar/classes/Toolbar.class';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import * as $ from 'jquery';


@Component({
  selector: 'app-chars-table',
  templateUrl: './chars-table.component.html',
  styleUrls: ['./chars-table.component.css']
})
export class CharsTableComponent implements OnInit {

    public symbols: string = null;
    public visible: boolean = false;
    public page: number = 0;
    public rows: number[] = [];
    public columns: number[] = [];
    public start: number = 33;
    public activeChar: number = 0;
    public selectedSymbols: string = "";

    @ViewChild('symbolsInput') symbolsInput: ElementRef;

    public static instance: CharsTableComponent = null;

    constructor()
    {
        CharsTableComponent.instance = this;
        this.changePage();
        this.columns = [];
        for(let i=0; i<20; ++i) this.columns.push(i);
    }

    prevPage()
    {
        if (this.page > 0) this.changePage(this.page - 1);
    }
    nextPage()
    {
        if (this.page < 65500/15) this.changePage(this.page + 1);
    }
    changePage(page: number = 0)
    {
        this.rows = [];
        this.page = page;
        for(let i=0; i<15; ++i) this.rows.push(page*15 + i);
    }

    getChar(code: number)
    {
        return '&#' + code + ';';
    }
    charClick(code: number, event: any)
    {
        console.log(event);
        this.activeChar = code;
        if (event.detail == 2)
        {
            this.selectedSymbols += String.fromCharCode(code);
        }
    }

    ngOnInit()
    {
        let s = '';
        for(let r=this.page*25; r<(this.page+1)*25; ++r)
        {
            for(let c=0; c<20; ++c)
            {
                s += '<b>&#' + (r*20)+c+33 + ';</b>';
            }
        }
        this.symbols = s;

        Toolbar.registerMenuItem({
            name: 'Table des caractères',
            icon: 'language',
            click: (event) => {
                CharsTableComponent.show();
            }
        });
    }

    public copySymbols()
    {
        $(this.symbolsInput.nativeElement).select();
        document.execCommand('copy');
    }

    public static show()
    {
        CharsTableComponent.instance.visible = true;
    }
    public static hide()
    {
        CharsTableComponent.instance.visible = false;
    }

}
