<div class="stock-container">

    <div class="stock-container-left-column">

        <h3>Informations stock</h3>

        <info-block #selectBlockComponent [block]="selectBlock" [data]="instance" [readonly]="adjustmentCopy.id != 0"></info-block>
        <!-- <info-block #parentBlockComponent [block]="parentBlock" [data]="stockCopy"></info-block> -->
        <info-block #stockBlockComponent [block]="stockBlock" [data]="adjustmentCopy.stock" [readonly]="true"></info-block>

        <h3>Ajustement de stock</h3>

        <info-block #adjustmentBlockComponent [block]="adjustmentBlock" [data]="adjustmentCopy" [readonly]="!writeAccess"></info-block>

<!--        <data-grid #movesGrid [items]="adjustmentCopy.moves" [columns]="movesGridColumns" [editable]="false">-->
<!--        </data-grid>-->

    </div>

    <div class="stock-container-right-column">

        <h3>Informations article</h3>

        <info-block #articleBlockComponent *ngIf="isArticle || !adjustmentCopy" [block]="articleBlock" [data]="adjustmentCopy ? adjustmentCopy.article : null" [readonly]="true"></info-block>
        <info-block #deviceBlockComponent *ngIf="isDevice" [block]="deviceBlock" [data]="adjustmentCopy.article" [readonly]="true"></info-block>
        <info-block #serviceBlockComponent *ngIf="isService" [block]="serviceBlock" [data]="adjustmentCopy.article" [readonly]="true"></info-block>

    </div>

</div>
