<!-- <div class="customer-select">
    <label for="customer-select">Client :&nbsp;</label>
    <select [(ngModel)]="selectedCustomerIndex" (change)="selectCustomer()">
        <option *ngFor="let customer of customers; let index=index;" [value]="index">{{ customer.name }}</option>
    </select>
</div>
<div *ngIf="selectedCustomer" class="customer-articles-list">
    <div *ngFor="let article of selectedCustomer.articles" class="customer-article-item">
        <info-block [block]="articleBlock" [data]="article"></info-block>
    </div>
</div> -->
<div class="vertical-split">

    <div class="nomenclatures-tree" (click)="clearTreeSelection()">
        <data-tree #dataTree modelClass="Nomenclature"
                   [items]="items" [selectedObjects]="selectedObjects"
                   editable="true" [nameField]="displayType == 'list' ? 'designation' : 'name'"
                   (itemRenamed)="itemRenamed($event)"
                   (change)="selectionChange($event)"></data-tree>
    </div>
    <div class="split"></div>
    <div class="article-details">
        <div *ngIf="objectCopy">
            <h3>Informations nomenclature</h3>
            <info-block [block]="nomenclatureBlock" [data]="objectCopy" [readonly]="!writeAccess"></info-block>
            <h3>Fournisseurs associés</h3>
            <foreign-list [objects]="suppliers" [(ngModel)]="objectCopy.suppliers" fieldName="name" [linesCount]="15"
                            [readonly]="!writeAccess" [multiSelect]="true" (change)="fieldChanged($event)"></foreign-list>
        </div>
    </div>
</div>