export class DialogButton
{
    public text: string = null;
    public result: number = null;

    public static readonly RESULT_NONE: number = 0;
    public static readonly RESULT_OK: number = 1;
    public static readonly RESULT_CANCEL: number = 2;
    public static readonly RESULT_ABORT: number = 3;
    public static readonly RESULT_RETRY: number = 4;
    public static readonly RESULT_IGNORE: number = 5;
    public static readonly RESULT_YES: number = 6;
    public static readonly RESULT_NO: number = 7;

    public static okButton: DialogButton = { text: 'OK', result: DialogButton.RESULT_OK };
    public static cancelButton: DialogButton = { text: 'Annuler', result: DialogButton.RESULT_CANCEL };
    public static abortButton: DialogButton = { text: 'Abandonner', result: DialogButton.RESULT_ABORT };
    public static retryButton: DialogButton = { text: 'Réessayer', result: DialogButton.RESULT_RETRY };
    public static ignoreButton: DialogButton = { text: 'Ignorer', result: DialogButton.RESULT_IGNORE };
    public static yesButton: DialogButton = { text: 'Oui', result: DialogButton.RESULT_YES };
    public static noButton: DialogButton = { text: 'Non', result: DialogButton.RESULT_NO };

    public static okOnlyButtons: DialogButton[] = [ DialogButton.okButton ];
    public static okCancelOnlyButtons: DialogButton[] = [ DialogButton.okButton, DialogButton.cancelButton ];
    public static yesNoButtons: DialogButton[] = [ DialogButton.yesButton, DialogButton.noButton ];
    public static yesNoCancelButtons: DialogButton[] = [ DialogButton.yesButton, DialogButton.noButton, DialogButton.cancelButton ];
}