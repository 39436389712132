// import { PriceDemand } from "./PriceDemand.class";

import { DateTimeUtil } from '../../utils/classes/DateTimeUtil.class';
import { InfoBlock } from '../../../components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from '../../../components/info-block/info-block.component';
import { InfoBlockField } from '../../../components/info-block/classes/InfoBlockField.class';
import { Page } from '../../printing/classes/Page.class';
import { Price } from './Price.class';
import { PrintingModel } from '../../printing/classes/PrintingModel.class';
import { PrintingUtil } from '../../utils/classes/PrintingUtil.class';
import { Sale } from './Sale.class';
import { config } from '../../../classes/config';

export class PriceDemandPrintingModel extends PrintingModel {
  public deliveryAddressField: InfoBlockField = {
    title: 'Adresse de livraison',
    field: 'delivery_field',
    type: 'address-text',
    listItems: { items: [] },
    textChange: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
      console.log('text changed!!');
      this.regenerate();
    },
    addressChange: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
      console.log('address changed!!');
      //this.hide_customer = false;
      this.regenerate();
    },
  };
  public optionsBlock: InfoBlock = {
    fields: [
      {
        title: 'Demande générique',
        type: 'checkbox',
        field: 'generic_demand',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
          this.regenerate();
        },
      },
      {
        title: 'Masquer le client',
        type: 'checkbox',
        field: 'hide_customer',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
          //   this.update_delivery_address();
          this.regenerate();
        },
      },
      {
        title: 'Langue',
        field: 'lang',
        type: 'select',
        selectOptions: [
          { text: '(Selon fournisseur)', value: '' },
          { text: 'Français', value: 'fr' },
          { text: 'Anglais', value: 'en' },
          { text: 'Néerlandais', value: 'nl' },
        ],
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
          this.regenerate();
        },
      },
      this.deliveryAddressField,
    ],
  };

  public sale: Sale = null;
  public lang: string = '';
  public delivery_address: string = '';
  public generic_demand: boolean = false;
  public hide_customer: boolean = false;
  public hidden_delivery_address: string = '';

  public static generate(sale: Sale, pageNum: number = null): PrintingModel {
    let model: PriceDemandPrintingModel = new PriceDemandPrintingModel();
    model.sale = sale;
    //if (sale.hide_customer) model.delivery_address = sale.delivery_address_text.split('\n').slice(-2).join('\n');
    model.delivery_address = sale.delivery_address_text;
    model.hidden_delivery_address = model.getHiddenDeliveryAddress(model.delivery_address);
    model.deliveryAddressField.listItems.items = sale.customer.deliveryAddressesList;
    model.hide_customer = sale.hide_customer == 1;
    return model.regenerate(pageNum);
  }

  public get delivery_field() {
    if (this.hide_customer) return this.hidden_delivery_address;
    else return this.delivery_address;
  }
  public set delivery_field(value: string) {
    if (this.hide_customer) this.hidden_delivery_address = value;
    else this.delivery_address = value;
  }

  public getHiddenDeliveryAddress(addr: string): string {
    let matches: RegExpExecArray = /\n((\w{1,2}-?)\d+\s+[^\n]+(\n[^\n]+)?)\n*$/gis.exec(addr);
    if (matches && matches[1]) return matches[1];
    else return '';
  }

  public regenerate(pageNum: number = null): PrintingModel {
    let sale: Sale = this.sale;

    let pageWidth = 210;
    let pageHeight = 295;

    let pageLeftMargin = 10;
    let pageRightMargin = 10;
    let pageTopMargin = 10;
    let pageBottomMargin = 10;

    let quantities: string[] = [];
    let prices: string[] = [];
    let billings: string[] = [];
    let deliveries: string[] = [];
    let storages: string[] = [];
    let productions: string[] = [];
    let show_prods: boolean = false;

    if (sale.prices) {
      sale.prices.sort((a: Price, b: Price) => {
        return a.quantity - b.quantity;
      });
      for (let i = 0; i < sale.prices.length; ++i) {
        let price = sale.prices[i];
        if (price.show == 1) {
          quantities.push(price.quantityAndUnit);
          prices.push('');
          billings.push(price.invoicings ? price.invoicings.toString() : '-');
          deliveries.push(price.deliveries ? price.deliveries.toString() : '-');
          storages.push(price.storage && price.storage > 0 ? price.storage.toString() : '-');
          if (price.productions && price.productions > 0) show_prods = true;
          productions.push(price.productions && price.productions > 0 ? price.productions.toString() : '-');
        }
      }
    }

    this.pages = [];

    let len: number = this.generic_demand ? 1 : this.sale.suppliers.length;
    for (let i = 0; i < len; ++i) {
      if (pageNum != null && i !== pageNum) continue;

      let lang: string = this.lang;
      if (!lang || lang == '') {
        if (this.sale.suppliers[i].lang) lang = this.sale.suppliers[i].lang.code;
        else lang = 'fr';
      }
      this.saveFilename = 'Demande de prix n°' + this.sale.number.replace(/\//g, '-') + '.pdf';
      this.mailFilename =
        {
          fr: 'Demande de prix n° ',
          nl: 'Prijs aanvraag nr ',
          en: 'Quotation request no ',
        }[lang] +
        this.sale.shortNumber.replace(/\//g, '-') +
        '-' +
        this.sale.history_version +
        '.pdf';

      let page: Page = {
        width: pageWidth,
        height: pageHeight,

        leftMargin: pageLeftMargin,
        rightMargin: pageRightMargin,
        topMargin: pageTopMargin,
        bottomMargin: pageBottomMargin,

        style: {
          'font-family': 'Calibri',
          'font-size': '10pt',
          width: pageWidth - pageLeftMargin - pageRightMargin + 'mm',
          height: pageHeight - pageTopMargin - pageBottomMargin + 'mm',
          'margin-top': pageTopMargin + 'mm',
          'margin-left': pageLeftMargin + 'mm',
          'margin-right': pageRightMargin + 'mm',
          'margin-bottom': pageBottomMargin + 'mm',
          'line-height': '4mm',
        },

        headerSize: 33,
        headerElements: [
          {
            style: {
              position: 'absolute',
              right: '0mm',
              top: '1mm',
              width: '85mm',
              height: '17mm',
              border: '.5mm solid rgba(0, 176, 240, 1.0)',
              'border-radius': '2.5mm',
              'line-height': '4mm',
            },
            children: [
              {
                style: {
                  'font-size': '20pt',
                  'font-weight': 'bold',
                  'text-align': 'center',
                  width: '100%',
                  'line-height': '5mm',
                  'margin-top': '1.5mm',
                  'margin-bottom': '1mm',
                  'vertical-align': 'top',
                },
                html: {
                  fr: 'DEMANDE DE PRIX',
                  en: 'QUOTATION REQUEST',
                  nl: 'PRIJS AANVRAAG',
                }[lang],
              },
              {
                style: {
                  display: 'flex',
                  'flex-direction': 'row',
                },
                children: [
                  {
                    style: {
                      width: '20%',
                      'text-align': 'right',
                      padding: '0 1mm',
                    },
                    html: {
                      fr: 'N° :',
                      en: 'No :',
                      nl: 'Nr :',
                    }[lang],
                  },
                  {
                    style: {
                      width: '80%',
                      padding: '0 1mm',
                      'font-weight': 'bold',
                    },
                    html: sale.number + '-' + sale.history_version,
                  },
                ],
              },
              {
                style: {
                  display: 'flex',
                  'flex-direction': 'row',
                },
                children: [
                  {
                    style: {
                      width: '20%',
                      'text-align': 'right',
                      padding: '0 1mm',
                    },
                    html: {
                      fr: 'Date :',
                      nl: 'Datum :',
                      en: 'Date :',
                    }[lang],
                  },
                  {
                    style: {
                      width: '30%',
                      padding: '0 1mm',
                    },
                    html: DateTimeUtil.format(new Date(sale.date), 'd/m/Y'),
                  },
                  {
                    style: {
                      width: '25%',
                      'text-align': 'right',
                      padding: '0 1mm',
                    },
                    html: {
                      fr: 'N° fourn. :',
                      en: 'Suppl. No :',
                      nl: 'Lever. Nr :',
                    }[lang],
                  },
                  {
                    style: {
                      width: '25%',
                      padding: '0 1mm',
                    },
                    html: sale.suppliers[i] && !this.generic_demand ? sale.suppliers[i].number : '',
                  },
                ],
              },
            ],
          },
          {
            style: {
              position: 'absolute',
              left: '0mm',
              top: '0mm',
              width: '50mm',
              height: '30mm',
              'background-image': 'url(assets/img/logo_documents.png)',
              'background-position': 'center center',
              'background-size': 'contain',
              'background-repeat': 'no-repeat',
            },
            html: '',
          },
        ],

        bodyElements: [
          {
            style: {
              'text-decoration': 'underline',
              'white-space': 'pre',
            },
            html: {
              fr: '\nVotre interlocuteur :',
              en: '\nYour contact-person :',
              nl: '\nUw contact persoon :',
            }[lang],
          },
          {
            style: {
              'white-space': 'pre',
              'font-weight': 'bold',
            },
            html: sale.merchant.name,
          },
          {
            style: {
              'white-space': 'pre',
            },
            html:
              (this.sale.merchant && this.sale.merchant.email ? 'Mail : ' + this.sale.merchant.email + '\n' : '') +
              (this.sale.merchant && this.sale.merchant.phone
                ? {
                    fr: 'Portable : ',
                    en: 'Mobile : ',
                    nl: 'Mobiele : ',
                  }[lang] +
                  this.sale.merchant.phone +
                  '\n'
                : '') +
              {
                fr: 'Téléphone : ',
                en: 'Phone : ',
                nl: 'Telefoon : ',
              }[lang] +
              '+32 (0)4 380 06 05',
          },
          {
            style: {
              'white-space': 'pre',
              'font-weight': 'bold',
              'line-height': '8mm',
            },
            html: {
              fr: 'Pouvez vous nous remettre prix pour la fourniture éventuelle de ce qui suit :',
              nl: 'Zou je ons prijs kunnen geven voor de mogelijke levering van wat volgt :',
              en: 'Could you please provide us pricing for the following :',
            }[lang],
          },

          this.generic_demand
            ? {}
            : {
                style: {
                  position: 'absolute',
                  right: '0mm',
                  top: '0mm',
                  width: '85mm',
                  height: '25m',
                },
                children: PrintingUtil.formatAddress(
                  this.sale.suppliers[i].name,
                  this.sale.suppliers[i].commercial_address.contactName,
                  this.sale.suppliers[i].commercial_address.getPostalAddress()
                ),
              },

          {
            style: {
              display: 'flex',
              width: '100%',
              'flex-direction': 'row',
            },
            children: [
              {
                style: {
                  display: 'table',
                  flex: '1',
                  'border-collapse': 'collapse',
                  position: 'relative',
                },
                children: [
                  {
                    style: {
                      display: 'table-row',
                      height: '1px',
                    },
                    children: [
                      {
                        style: {
                          display: 'table-cell',
                          border: '.25mm solid rgba(0, 176, 240, 1.0)',
                          'background-color': 'rgba(217,217,217,1)',
                          width: '50mm',
                          padding: '.5mm 1mm',
                          'text-align': 'right',
                          'font-weight': 'bold',
                        },
                        html: {
                          fr: 'Désignation',
                          en: 'Specifications',
                          nl: 'Specificaties',
                        }[lang],
                      },
                      {
                        style: {
                          display: 'table-cell',
                          border: '.25mm solid rgba(0, 176, 240, 1.0)',
                          'background-color': 'rgba(217,217,217,1)',
                          padding: '.5mm 1mm',
                          'font-weight': 'bold',
                        },
                        html: sale.article.designation,
                      },
                    ],
                  },
                ].concat(this.getArticleLines(sale, lang)),
              },

              this.sale.article.field15 || this.sale.article.field9_img
                ? {
                    style: {
                      float: 'right',
                      'max-height': '50mm',
                      'margin-left': '5mm',
                      position: 'relative',
                    },
                    children: [
                      this.sale.article.field15 //picture
                        ? {
                            style: {
                              display: 'block',
                              'max-width': '50mm',
                              'max-height': this.sale.article.field9_img ? '37mm' : '50mm',
                            },
                            image: config.baseUrl + 'uploaded_images/' + this.sale.article.field15,
                            imageStyle: {
                              'max-width': '50mm',
                              'max-height': this.sale.article.field9_img ? '37mm' : '50mm',
                            },
                          }
                        : {},
                      this.sale.article.field9_img //rolling picture
                        ? {
                            style: {
                              display: 'block',
                              width: '10mm',
                              height: '10mm',
                              margin: '3mm auto 0 auto',
                              position: 'relative',
                            },
                            image:
                              config.baseUrl + 'assets/img/rolling-directions/' + this.sale.article.field9_img + '.png',
                            imageStyle: {
                              width: '10mm',
                              height: '10mm',
                            },
                          }
                        : {},
                    ],
                  }
                : {},
            ],
          },

          {
            style: {
              display: 'table',
              width: '100%',
              'border-collapse': 'collapse',
              'margin-top': '3mm',
            },
            children: [
              {
                style: {
                  display: 'table-row',
                },
                children: [
                  {
                    style: {
                      display: 'table-cell',
                      border: '.25mm solid rgba(0, 176, 240, 1.0)',
                      'background-color': 'rgba(217,217,217,1)',
                      padding: '.5mm 1mm',
                      'text-align': 'center',
                      'white-space': 'pre',
                      // 'line-height': '5mm',
                      // 'width': '50mm',
                      'font-weight': 'bold',
                    },
                    html: {
                      fr: 'Quantité et\nunité',
                      en: 'Quantity and\nunit',
                      nl: 'Hoeveelheid en\nverkoopseenheid',
                    }[lang],
                  },
                  {
                    style: {
                      display: 'table-cell',
                      border: '.25mm solid rgba(0, 176, 240, 1.0)',
                      'background-color': 'rgba(217,217,217,1)',
                      padding: '.5mm 1mm',
                      'text-align': 'center',
                      'white-space': 'pre',
                      // 'line-height': '5mm',
                      'font-weight': 'bold',
                    },
                    html: {
                      fr: 'Votre prix de\nvente H.T.V.A.',
                      en: 'Your price\n(excl. V.A.T.)',
                      nl: 'Uw prijs\n(excl. B.T.W.)',
                    }[lang],
                  },
                  {
                    style: {
                      display: 'table-cell',
                      border: '.25mm solid rgba(0, 176, 240, 1.0)',
                      'background-color': 'rgba(217,217,217,1)',
                      padding: '.5mm 1mm',
                      'text-align': 'center',
                      'white-space': 'pre',
                      // 'line-height': '5mm',
                      width: '20mm',
                      'font-weight': 'bold',
                    },
                    html: {
                      fr: 'Nombre de\nfacturations',
                      en: 'Number of\ninvoicings',
                      nl: 'Aantal\nfacturen',
                    }[lang],
                  },
                  {
                    style: {
                      display: 'table-cell',
                      border: '.25mm solid rgba(0, 176, 240, 1.0)',
                      'background-color': 'rgba(217,217,217,1)',
                      padding: '.5mm 1mm',
                      'text-align': 'center',
                      'white-space': 'pre',
                      // 'line-height': '5mm',
                      width: '20mm',
                      'font-weight': 'bold',
                    },
                    html: {
                      fr: 'Nombre de\nlivraisons',
                      en: 'Number of\ndeliveries',
                      nl: 'Aantal\nleveringen',
                    }[lang],
                  },
                  {
                    style: {
                      display: 'table-cell',
                      border: '.25mm solid rgba(0, 176, 240, 1.0)',
                      'background-color': 'rgba(217,217,217,1)',
                      padding: '.5mm 1mm',
                      'text-align': 'center',
                      'white-space': 'pre',
                      // 'line-height': '5mm',
                      width: '20mm',
                      'font-weight': 'bold',
                    },
                    html: {
                      fr: 'Nombre de mois\nde stockage',
                      en: 'Storage timeline\n(months)',
                      nl: 'Aantal maanden\nopslag',
                    }[lang],
                  },
                  show_prods
                    ? {
                        style: {
                          display: 'table-cell',
                          border: '.25mm solid rgba(0, 176, 240, 1.0)',
                          'background-color': 'rgba(217,217,217,1)',
                          padding: '.5mm 1mm',
                          'text-align': 'center',
                          'white-space': 'pre',
                          // 'line-height': '5mm',
                          width: '20mm',
                          'font-weight': 'bold',
                        },
                        html: {
                          fr: 'Nombre de\nproductions',
                          en: 'Number of\nproductions',
                          nl: 'Aantal\nproducties',
                        }[lang],
                      }
                    : {
                        style: { display: 'none' },
                      },
                ],
              },
              {
                style: {
                  display: 'table-row',
                  'line-height': '5mm',
                },
                children: [
                  {
                    style: {
                      display: 'table-cell',
                      border: '.25mm solid rgba(0, 176, 240, 1.0)',
                      padding: '.5mm 1mm',
                      'text-align': 'center',
                      'white-space': 'pre',
                      // 'line-height': '5mm',
                      // 'width': '50mm'
                    },
                    html: quantities.join('\n'),
                  },
                  {
                    style: {
                      display: 'table-cell',
                      border: '.25mm solid rgba(0, 176, 240, 1.0)',
                      padding: '.5mm 1mm',
                      'text-align': 'center',
                      'white-space': 'pre',
                      // 'line-height': '5mm'
                    },
                    html: prices.join('\n'),
                  },
                  {
                    style: {
                      display: 'table-cell',
                      border: '.25mm solid rgba(0, 176, 240, 1.0)',
                      padding: '.5mm 1mm',
                      'text-align': 'center',
                      'white-space': 'pre',
                      // 'line-height': '5mm',
                      width: '20mm',
                    },
                    html: billings.join('\n'),
                  },
                  {
                    style: {
                      display: 'table-cell',
                      border: '.25mm solid rgba(0, 176, 240, 1.0)',
                      padding: '.5mm 1mm',
                      'text-align': 'center',
                      'white-space': 'pre',
                      // 'line-height': '5mm',
                      width: '20mm',
                    },
                    html: deliveries.join('\n'),
                  },
                  {
                    style: {
                      display: 'table-cell',
                      border: '.25mm solid rgba(0, 176, 240, 1.0)',
                      padding: '.5mm 1mm',
                      'text-align': 'center',
                      'white-space': 'pre',
                      // 'line-height': '5mm',
                      width: '20mm',
                    },
                    html: storages.join('\n'),
                  },
                  show_prods
                    ? {
                        style: {
                          display: 'table-cell',
                          border: '.25mm solid rgba(0, 176, 240, 1.0)',
                          padding: '.5mm 1mm',
                          'text-align': 'center',
                          'white-space': 'pre',
                          // 'line-height': '5mm',
                          width: '20mm',
                        },
                        html: productions.join('\n'),
                      }
                    : {
                        style: { display: 'none' },
                      },
                ],
              },
            ],
          },

          {
            style: {
              display: 'table',
              width: '100%',
              'border-collapse': 'collapse',
              'margin-top': '3mm',
            },
            children: [
              {
                style: {
                  display: 'table-row',
                },
                children: [
                  {
                    style: {
                      display: 'table-cell',
                      border: '.25mm solid rgba(0, 176, 240, 1.0)',
                      width: '70mm',
                      padding: '.5mm 1mm',
                      'text-align': 'right',
                      'white-space': 'pre',
                      // 'line-height': '5mm',
                    },
                    html: {
                      fr: 'Votre commentaire :\nVotre delai de livraison :\nNos conditions de paiement du end user :',
                      en: 'Your comments :\nYour delivery time :\nOur end-user terms of payment :',
                      nl: 'Uw opmerkingen :\nUw levertijd :\nOnze betalingsvoorwaarden voor eindgebruikers :',
                    }[lang],
                  },
                  {
                    style: {
                      display: 'table-cell',
                      border: '.25mm solid rgba(0, 176, 240, 1.0)',
                      padding: '.5mm 1mm',
                      'white-space': 'pre',
                      // 'line-height': '5mm',
                    },
                    html:
                      '\n\n' + (sale.deadline ? sale.deadline : sale.customer.deadline ? sale.customer.deadline : ''),
                  },
                ],
              },
            ],
          },

          {
            style: {
              display: 'block',
              border: '.25mm solid rgba(0, 176, 240, 1.0)',
              'background-color': 'rgba(0, 176, 240, 1.0)',
              width: '100%',
              padding: '.5mm 1mm',
              'text-align': 'center',
              // 'line-height': '5mm',
              color: 'rgba(255,255,255,1)',
              'font-weight': 'bold',
              'margin-top': '3mm',
            },
            html: {
              fr: 'REMARQUES IMPORTANTES',
              en: 'IMPORTANT REMARKS',
              nl: 'BELANGRIJKE OPMERKINGEN',
            }[lang],
          },
          {
            style: {
              display: 'block',
              border: '.25mm solid rgba(0, 176, 240, 1.0)',
              width: '100%',
              padding: '.5mm 1mm',
              // 'line-height': '5mm',
              'white-space': 'pre-wrap',
              flex: '1',
            },
            children: PrintingUtil.linesToDivs(sale.demand_remarks ? sale.demand_remarks.trim() : '', null, 3),
          },

          {
            style: {
              'white-space': 'pre-wrap',
              'font-weight': 'bold',
              // 'line-height': '5mm',
              'margin-top': '3mm',
              'font-style': 'italic',
            },
            html: {
              fr: "En restant à votre disposition pour d'éventuelles questions, nous vous prions d'agréer, Madame, Monsieur, nos salutations les plus sincères.",
              en: 'We are at your disposal for any further questions. Thank you in advance for your response.',
              nl: 'Wij blijven ter uwer beschikking voor mogelijke vragen, gelieve Mevrouw, Mijnheer, met de meeste hoogachting onze oprechte groeten te aanvaarden.',
            }[lang],
          },
        ],

        footerSize: 63,
        footerElements: [
          {
            style: {
              display: 'table',
              width: '100%',
              'border-collapse': 'collapse',
              'margin-top': '3mm',
            },
            children: [
              {
                style: {
                  display: 'table-row',
                },
                children: [
                  {
                    style: {
                      display: 'table-cell',
                      border: '.25mm solid rgba(0, 176, 240, 1.0)',
                      'background-color': 'rgba(217, 217, 217, 1.0)',
                      width: '76mm',
                      padding: '.5mm 1mm',
                      'text-align': 'center',
                      // 'line-height': '5mm',
                      'font-weight': 'bold',
                    },
                    html: {
                      fr: 'ADRESSE DE LIVRAISON',
                      en: 'DELIVERY ADDRESS',
                      nl: 'LEVERINGS ADRES',
                    }[lang],
                  },
                  {
                    style: {
                      display: 'table-cell',
                      border: '.25mm solid rgba(0, 176, 240, 1.0)',
                      'background-color': 'rgba(217, 217, 217, 1.0)',
                      width: '76mm',
                      padding: '.5mm 1mm',
                      'text-align': 'center',
                      // 'line-height': '5mm',
                      'font-weight': 'bold',
                    },
                    html: {
                      fr: 'ADRESSE DE FACTURATION',
                      en: 'BILLING ADDRESS',
                      nl: 'FACTURATIE ADRES',
                    }[lang],
                  },
                  {
                    style: {
                      display: 'table-cell',
                      border: '.25mm solid rgba(0, 176, 240, 1.0)',
                      'background-color': 'rgba(217, 217, 217, 1.0)',
                      width: '38mm',
                      padding: '.5mm 1mm',
                      'text-align': 'center',
                      // 'line-height': '5mm',
                      'font-weight': 'bold',
                    },
                    html: {
                      fr: 'NOM ET SIGNATURE',
                      en: 'NAME AND SIGNATURE',
                      nl: 'HANDTEKENING',
                    }[lang],
                  },
                ],
              },
              {
                style: {
                  display: 'table-row',
                },
                children: [
                  {
                    style: {
                      display: 'table-cell',
                      border: '.25mm solid rgba(0, 176, 240, 1.0)',
                      width: '76mm',
                      padding: '.5mm 1mm',
                      'text-align': 'center',
                      // 'line-height': '5mm',
                      'white-space': 'pre-wrap',
                      'vertical-align': 'top',
                    },
                    children: PrintingUtil.formatAddress(
                      this.sale.customer.name,
                      this.sale.delivery_contact,
                      this.delivery_field,
                      null,
                      null,
                      lang,
                      this.hide_customer ? 1 : 0
                    ),
                  },
                  {
                    style: {
                      display: 'table-cell',
                      border: '.25mm solid rgba(0, 176, 240, 1.0)',
                      width: '76mm',
                      padding: '.5mm 1mm',
                      'text-align': 'center',
                      // 'line-height': '5mm',
                      'white-space': 'pre-wrap',
                      'vertical-align': 'top',
                    },
                    children: PrintingUtil.formatAddress(
                      'G.S. srl',
                      "A l'attention de Mme Gillian Townley",
                      'Atrium du Vertbois\nRue du Vertbois 11 bte 20\nB-4000 Liège (BELGIUM)',
                      'BE 0829 982 973'
                    ),
                  },
                  {
                    style: {
                      display: 'table-cell',
                      border: '.25mm solid rgba(0, 176, 240, 1.0)',
                      width: '38mm',
                      padding: '.5mm 1mm',
                      'text-align': 'center',
                      'line-height': '5mm',
                      'white-space': 'pre',
                      position: 'relative',
                      'vertical-align': 'top',
                    },
                    children: [
                      {
                        style: {
                          'text-align': 'center',
                          'white-space': 'pre',
                        },
                        html: {
                          fr: '\n\n\n\nLe service commercial',
                          en: '\n\n\n\nCommercial department',
                          nl: '\n\n\n\nCommercieele dienst',
                        }[lang],
                      },
                      {
                        style: {
                          'font-weight': 'bold',
                          'text-align': 'center',
                        },
                        html: 'Christophe Toupy',
                      },
                      {
                        style: {
                          position: 'absolute',
                          left: '0mm',
                          top: '0mm',
                          width: '34mm',
                          height: '25mm',
                          'background-image': 'url(assets/img/signature_blue.png)',
                          'background-size': 'contain',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },

          PrintingUtil.getFooter(this.sale.accounting, lang),
        ],
      };

      this.pages.push(page);
    }
    return this;
  }

  public getArticleLines(sale: Sale, lang: string) {
    let headers: any[] = PrintingUtil.getArticleHeaders(sale.article, lang);
    let values: any[] = PrintingUtil.getArticleValues(sale.article);
    let arr: any[] = [];
    for (let i = 0; i < headers.length; ++i) {
      arr.push({
        style: {
          display: 'table-row',
          height: i < headers.length - 1 ? '1px' : 'auto',
        },
        children: [
          {
            style: {
              display: 'table-cell',
              'border-left': '.25mm solid rgba(0, 176, 240, 1.0)',
              'border-right': '.25mm solid rgba(0, 176, 240, 1.0)',
              width: '50mm',
              padding: '.5mm 1mm',
              'text-align': 'right',
              'white-space': 'pre-wrap',
              'line-height': '4mm',
              'vertical-align': 'top',
              'padding-left': '1mm',
              'padding-top': '0',
              'padding-right': '1mm',
              'padding-bottom': '0',
            },
            html: headers[i],
          },
          {
            style: {
              display: 'table-cell',
              'border-right': '.25mm solid rgba(0, 176, 240, 1.0)',
              padding: '.5mm 1mm',
              'white-space': 'pre-wrap',
              'line-height': '4mm',
              'vertical-align': 'top',
              'padding-left': '1mm',
              'padding-top': '0',
              'padding-right': '1mm',
              'padding-bottom': '0',
            },
            html: values[i],
          },
        ],
      });
    }
    if (arr.length > 0) {
      arr[arr.length - 1].children[0].style['border-bottom'] = '.25mm solid rgba(0, 176, 240, 1.0)';
      arr[arr.length - 1].children[1].style['border-bottom'] = '.25mm solid rgba(0, 176, 240, 1.0)';
    }
    return arr;
  }
}
