import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  IterableDiffers,
  NgZone,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as $ from 'jquery';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable, fromEvent } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { DateTimeUtil } from 'src/app/modules/utils/classes/DateTimeUtil.class';
import { NumberUtil } from 'src/app/modules/utils/classes/NumberUtil.class';
import { CredentialsService } from 'src/app/services/credentials/credentials.service';
import { ObjectModel2 } from '../../classes/objects/ObjectModel2.class';
import { ArrayUtil } from '../../modules/utils/classes/ArrayUtil.class';
import { ColorModal2Component } from '../color-modal2/color-modal2.component';
import { LoadingComponent } from '../loading/loading.component';
import { NotificationsComponent } from '../notifications/notifications.component';
import { CheckboxFilter } from './classes/CheckboxFilter';
import { ColorFilter } from './classes/ColorFilter';
import { DataGridColumn, DataGridRenderFunc } from './classes/DataGridColumn.class';
import { ForeingListFilter } from './classes/ForeingListFilter';
import { Filter, SortAction } from './classes/IFilter';
import { NumberFilter } from './classes/NumberFilter';
import { StringFilter } from './classes/StringFilter';

declare class ClipboardItem {
  constructor(data: { [mimeType: string]: Blob });
}

type CanvasTextAlign = 'center' | 'end' | 'left' | 'right' | 'start';

@Component({
  selector: 'data-grid2',
  templateUrl: './data-grid2.component.html',
  styleUrls: ['./data-grid2.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DataGrid2Component implements OnInit, AfterContentInit {
  private static SELECT_COLUMN_WIDTH: number = 30;
  private static DEFAULT_COLUMN_WIDTH: number = 150;
  private static ROW_HEIGHT: number = 36;

  private static checkboxCheckedImageReady = false;
  private static CHECKBOX_CHECKED_IMAGE: HTMLImageElement = null;
  private static checkboxUncheckedImageReady = false;
  private static CHECKBOX_UNCHECKED_IMAGE: HTMLImageElement = null;

  private static EDITABLE_BGCOLOR: string = '#ffffff';
  private static READONLY_BGCOLOR: string = '#cccccc';
  private static SELECTED_BGCOLOR: string = 'rgba(0, 192, 255, .5)';
  private static HOVER_BGCOLOR: string = 'rgba(24, 96, 150, .5)';
  private static FOCUS_BORDER_WIDTH: number = 3;
  private static FOCUS_BORDER_COLOR: string = 'rgba(0, 187, 97, 1)';
  private static FOCUS_BGCOLOR: string = 'rgba(0, 187, 97, .5)';

  private _items: any[] = [];
  @Input() public set items(value: any[]) {
    this._items = value;
    // this.clearFilters();
    // this._generateHtml();
    this.regenerateFilteredArray();
  }
  public get items() {
    return this._items;
  }

  private _columns: DataGridColumn[] = [];
  @Input() public set columns(value: DataGridColumn[]) {
    this._columns = value;
    this._splitColumns();
    this.regenerateFilters();
  }
  public get columns() {
    return this._columns;
  }

  private _selectable: boolean = false;
  @Input() public set selectable(value: boolean) {
    this._selectable = value;
    this._calculateColumnWidths();
  }
  public get selectable() {
    return this._selectable;
  }

  @Input() id: string = null;
  @Input() headerColumns = null;
  @Input() actions = null;
  @Input() rowActions = null;
  @Input() sortArray = null;
  @Input() rowStyleFunction = null;
  @Input() editable: boolean = false;
  @Input() useColGroup: boolean = true;
  @Input() resizable: boolean = true;
  @Input() rowSelect: boolean = false;
  @Input() colGroupHtml: string = null;
  @Input() prependHeadersHtml: string[] = null;
  @Input() fixedPrependHeadersHtml: string[] = null;
  @Input() additionalHeadersHtml: string[] = null;
  @Input() fixedAdditionalHeadersHtml: string[] = null;
  @Input() copyPrependHeadersHtml: string[] = null;
  @Input() copyAdditionalHeadersHtml: string[] = null;
  @Input() fixedFooterRowsHtml: string[] = null;
  @Input() footerRowsHtml: string[] = null;

  @Input() showTotals: boolean = false;
  @Input() showTotalsFunctions: boolean = true;

  @Output('activeCellsChanged') activeCellsChanged = new EventEmitter<[number, number, number, number]>();
  @Output('selectionChange') selectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Output('itemDblClick') itemDblClick: EventEmitter<any> = new EventEmitter<any>();
  @Output('itemClick') itemClick: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren('moneyInputs') moneyInputs: QueryList<ElementRef>;
  @ViewChildren('percentageInputs') percentageInputs: QueryList<ElementRef>;

  limitValues = [10, 20, 30, 50, 100, 500];
  selectedPageSize = 10;
  @Input() pageSize = 10;
  currentPage = 0;
  @Input() showAll = false;
  public rowActionsItem = null;
  public hoverItem = null;
  @Input() public headerBackColor: string = 'darkgray';
  @Input() public headerTextColor: string = 'black';

  public gridId: number = Math.round(Math.random() * 9999);
  private static focusedGrid: DataGrid2Component = null;

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  constructor(
    public elem: ElementRef,
    private modalService: BsModalService,
    private zone: NgZone,
    private ref: ChangeDetectorRef,
    private _iterableDiffers: IterableDiffers,
    private sanitizer: DomSanitizer
  ) {
    // this.iterableDiffer = this._iterableDiffers.find([]).create(null);
    // $(elem.nativeElement).on('resize', () => { setTimeout(() => { console.log("RESIZE!!!"); this.resizeTableBody(); }, 0); });
    if (!(<any>window)._grids) (<any>window)._grids = {};
    (<any>window)._grids[this.gridId] = this;
    (<any>window).$ = $;
    (<any>window)._grid = this;

    if (!DataGrid2Component.CHECKBOX_CHECKED_IMAGE) {
      DataGrid2Component.CHECKBOX_CHECKED_IMAGE = new Image();
      DataGrid2Component.CHECKBOX_CHECKED_IMAGE.onload = () => {
        DataGrid2Component.checkboxCheckedImageReady = true;
      };
      DataGrid2Component.CHECKBOX_CHECKED_IMAGE.src = 'assets/img/icons/20x20/black/checkbox-checked.png';
    }
    if (!DataGrid2Component.CHECKBOX_UNCHECKED_IMAGE) {
      DataGrid2Component.CHECKBOX_UNCHECKED_IMAGE = new Image();
      DataGrid2Component.CHECKBOX_UNCHECKED_IMAGE.onload = () => {
        DataGrid2Component.checkboxUncheckedImageReady = true;
      };
      DataGrid2Component.CHECKBOX_UNCHECKED_IMAGE.src = 'assets/img/icons/20x20/black/checkbox-unchecked.png';
    }
  }

  public detectChanges() {
    this.ref.detectChanges();
  }

  subscription: Subscription;

  ngOnInit() {
    this.selectedPageSize = this.pageSize;
    this.showAllChanged();
    this.loadColumnSizes();

    this.subscription = fromEvent(document, 'keydown').subscribe((e) => {
      this._onKeyDown(e as KeyboardEvent);
    });

    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
      setTimeout(() => {
        console.log('RESIZE!!!');
        this.resizeTableBody();
      }, 0);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  formatDate(value: string) {
    let date = new Date(value);
    if (!value || !date) return '';
    return DateTimeUtil.format(date, 'd/m/Y');
  }

  public addItems(objects: any, count: number = null) {
    if (typeof objects === 'function') {
      let new_objects = [];
      if (count == null) count = 1;
      for (let i = 0; i < count; ++i) new_objects.push(new objects());
      objects = new_objects;
    }
    if (!Array.isArray(objects)) objects = [objects];
    for (let i = 0; i < objects.length; ++i) this.items.push(objects[i]);
    this.regenerateFilteredArray();
    this.lastPage();
  }

  /* PAGINATION */

  get pagesCount() {
    if (!this.filteredItems) return 0;
    else if (this.showAll === true) return 1;
    else return Math.ceil(this.filteredItems.length / this.pageSize);
  }

  prevPage() {
    --this.currentPage;
    setTimeout(() => {
      this.resizeTableBody();
    }, 0);
  }
  nextPage() {
    ++this.currentPage;
    setTimeout(() => {
      this.resizeTableBody();
    }, 0);
  }
  firstPage() {
    this.currentPage = 0;
    setTimeout(() => {
      this.resizeTableBody();
    }, 0);
  }
  lastPage() {
    this.currentPage = Math.max(this.pagesCount - 1, 0);
    setTimeout(() => {
      this.resizeTableBody();
    }, 0);
  }
  selectedPageSizeChanged() {
    let firstItem = this.currentPage * this.pageSize;
    this.pageSize = this.selectedPageSize;
    this.currentPage = Math.floor(firstItem / this.selectedPageSize);
    setTimeout(() => {
      this.resizeTableBody();
    }, 0);
  }
  showAllChanged() {
    if (this.showAll === true) {
      this.pageSize = this.filteredItems.length;
      this.lastPage();
    } else {
      this.pageSize = this.selectedPageSize;
      this.firstPage();
    }
    // setTimeout(() => { this.resizeTableBody(); }, 0);
  }

  public get pagedFilteredItems() {
    return this.filteredItems && this.filteredItems.length
      ? this.filteredItems.slice(this.currentPage * this.pageSize, (this.currentPage + 1) * this.pageSize)
      : [];
  }

  /* RESIZE COLUMNS */

  resizing = false;
  resizingTable = null;
  resizingColumn = null;
  resizingStart = 0;
  resizingEnd = 0;

  //columnWidthDelta = 0;
  columnWidthStart = 0;
  tableWidthStart = 0;

  resizeGripCapture(event: MouseEvent, column) {
    if (event.detail > 1) return this.resizeGripDblClick(event, column);
    this.resizing = true;
    this.resizingTable = $('table', this.elem.nativeElement);
    this.resizingColumn = column;
    this.resizingColumn.element = $(event.target).parent();
    this.resizingStart = event.screenX;
    this.resizingEnd = event.screenX;

    this.columnWidthStart = this.resizingColumn.width || DataGrid2Component.DEFAULT_COLUMN_WIDTH; //this.resizingColumn.element.width();
    //this.columnWidthDelta = this.resizingColumn.element.outerWidth() - this.columnWidthStart;
    this.tableWidthStart = this.resizingTable.width();
  }

  resizeGripMove(event: MouseEvent) {
    this.resizingEnd = event.screenX;
    this.resizeGripApply();
  }

  resizeGripApply() {
    this.resizingColumn.width = Math.max(10, this.columnWidthStart + (this.resizingEnd - this.resizingStart));
    setTimeout(() => {
      this._updateColumnWidth(this.resizingColumn);
      this.resizeTableBody();
    }, 0);
  }

  resizeGripRelease(event: MouseEvent) {
    this.resizing = false;
    this.resizeGripApply();
    this.saveColumnSize(this.resizingColumn);
  }

  resizeGripDblClick(event: MouseEvent, column) {
    this.resizingColumn = column;
    this.resizingColumn.element = $(event.target).parent();
    this.resizingColumn.width = null;
  }

  saveColumnSize(column) {
    const index: number = this._columns.indexOf(column);
    if (index >= 0 && this.id) {
      CredentialsService.loggedUser.setPreference('datagrid_' + this.id + '_column' + index + '_size', column.width);
    }
  }

  loadColumnSizes() {
    if (this.id) {
      for (let i = 0; i < this._columns.length; ++i) {
        const col = this._columns[i];
        const width: number = CredentialsService.loggedUser.getPreference(
          'datagrid_' + this.id + '_column' + i + '_size'
        );
        if (width > 0) col.width = width;
      }
      this._calculateColumnWidths();
    }
  }

  private _updateColumnWidth(col: DataGridColumn) {
    let index: number = this._columns.indexOf(this.resizingColumn);
    if (index < this._fixedColumnsCount) this._updateFixedColumnWidth(col, index);
    else this._updateBodyColumnWidth(col, index - this._fixedColumnsCount);
  }
  private _updateBodyColumnWidth(col, index) {
    if (index < 0) index = this._bodyColumns.indexOf(col);
    // const width: string =  col.width + "px";
    // $('colgroup > col[data-col-index="' + index + '"]', this.bodyTable.nativeElement).css('width', width).css("max-width", width);
    this._calculateColumnWidths();
    this.paintBodyCanvas();
  }
  private _updateFixedColumnWidth(col, index) {
    if (index < 0) index = this._fixedColumns.indexOf(col);
    // const width: string =  col.width + "px";
    // $('colgroup > col[data-col-index="' + index + '"]', this.asideTable.nativeElement).css('width', width).css("max-width", width);
    this._calculateColumnWidths();
    this.paintFixedCanvas();
  }

  /* FILTERS */

  // public filteredItems: any[] = [];

  private _filterValuesFunctions = {
    number: (item: any, value, column: DataGridColumn) =>
      NumberUtil.formatNumber(value, column.decimalsCount >= 0 ? column.decimalsCount : 2) +
      (column.unit ? ' ' + column.unit : ''),
    'foreign-list': (item: any, value, column: DataGridColumn) => value[column.listField],
    text: (item: any, value, column: DataGridColumn) => value,
  };
  private _filterSortFunctions = {
    text: (value1, value2) => (value1 ? value1.localeCompare(value2) : -1),
    number: (value1, value2) => value1 - value2,
  };

  public filters: Filter[] = [];
  public filterIndex: number = -1;
  public showFilter(index: number) {
    if (this.filterIndex === index) this.closeFilter();
    else {
      const filter: Filter = this.filters[index];
      const column: DataGridColumn = this._columns[index];
      if (filter && column && column.getter) {
        let _values = this._getFilteredItems(this.filters.filter((f, i) => i !== index)).map((item) =>
          column.getter(item, column)
        );
        _values = _values.filter((item, index) => _values.indexOf(item) === index);
        const sortFunc = this._filterSortFunctions[column.type || 'text'];
        if (sortFunc) _values.sort(sortFunc);
        filter.availableValues = _values;

        let _formattedValues = [..._values];
        const mapFunc = column.format || this._filterValuesFunctions[column.type || 'text'];
        if (mapFunc) _formattedValues = _formattedValues.map((value) => mapFunc(undefined, value, column));
        filter.formattedValues = _formattedValues;
      }
      this.filterIndex = index;
    }
  }
  public closeFilter() {
    this.filterIndex = -1;
  }
  public onFilterChanged(filter: Filter) {
    this.regenerateFilteredArray();
  }
  public onFilterSortClicked(action: SortAction<any>) {
    const column: DataGridColumn = this._columns[this.filterIndex];
    this._filteredItems.sort((a, b) => {
      const valueA = column.format(a, column.getter(a, column), column);
      const valueB = column.format(b, column.getter(b, column), column);
      return action.func(valueA, valueB);
    });
    this.regenerateVisibleItems();
  }

  public _filteredItems: any[] = null;
  public get filteredItems() {
    if (!this._items) return [];
    if (!this._filteredItems) this.regenerateFilteredArray();
    return this._filteredItems;
  }

  public regenerateFilteredArray() {
    this._filteredItems = this._getFilteredItems(this.filters);
    this._resizeFillers();
  }

  private _getFilteredItems(filters: Filter[]) {
    // let r: any = [];
    if (!this._items || !filters || !filters.length) {
      return this._items;
    }

    // filters.map((filter, i) => {
    //     r[i] = [];
    //     filter.selectedValues && filter.selectedValues.map((value, j) => {
    //         r[i].push(value ? TextUtil.createRegexFromString(value.toString(), true, 'i') : value);
    //     });
    // });

    let result: any[] = this._items;
    filters.map((filter, i) => {
      if (filter.isActive && filter.column.getter)
        result = result.filter((item) => filter.check(filter.column.getter(item, filter.column)));
    });

    return result;
  }

  private regenerateFilters() {
    this.filters = this._columns.map((column) => {
      const filter: Filter = new {
        number: NumberFilter,
        text: StringFilter,
        date: StringFilter,
        email: StringFilter,
        phone: StringFilter,
        'foreing-list': ForeingListFilter,
        checkbox: CheckboxFilter,
        color: ColorFilter,
      }[column.type || 'text']();
      filter.column = column;
      return filter;
      // this.filters.push(filter);
    });
    this.clearFilters();
  }

  public clearFilters() {
    for (const filter of this.filters) filter.clear();
    this._filteredItems = this._items;
    // this._generateHtml();
    this._resizeFillers();
    this.regenerateVisibleItems();
  }

  public onSortActionClicked(action: SortAction<any>) {
    const column = this._columns[this.filterIndex];
    this._items.sort((a: any, b: any) => {
      const value1: any = column.format(a, column.getter(a, column), column); //this.getItemValue(element, this.columns[i]);
      const value2: any = column.format(b, column.getter(b, column), column); //this.getItemValue(element, this.columns[i]);
      console.log('here are the values:', value1, value2);
      return action.func(value1, value2);
    });
    this.closeFilter();
    this.regenerateFilteredArray();
  }

  /* COLOR INPUT */

  public colorModalRef: BsModalRef = null;
  colorClick(item: any, column: DataGridColumn) {
    let initialState: any = {
      setter: (value: string) => (this.editingValue = value),
      getter: () => this.editingValue,
    };
    this.colorModalRef = this.modalService.show(ColorModal2Component, { initialState, class: 'modal-sm' });
  }

  /* SELECTION */

  public selectedItems: any[] = [];

  setSelection(event, item) {
    if (event != null && event.ctrlKey === true) ArrayUtil.toggleItem(this.selectedItems, item);
    else this.selectedItems = [item];
    this.selectionChange.next(this.selectedItems);
  }

  selectionCheckboxClick(event, item) {
    event.stopPropagation();
    ArrayUtil.toggleItem(this.selectedItems, item);
    this.selectionChange.next(this.selectedItems);
  }

  clearSelection() {
    this.selectedItems.splice(0, this.selectedItems.length);
    this.selectionChange.next(this.selectedItems);
  }

  public get allSelected() {
    return (
      this.selectedItems &&
      this.selectedItems.length > 0 &&
      (this.selectedItems.filter((item) => !this.filteredItems.includes(item)).length === 0 ? true : undefined) &&
      this.selectedItems.length == this.filteredItems.length
    );
  }

  toggleAllSelected(e) {
    // e.stopPropagation();
    // e.preventDefault();
    if (this.allSelected !== false) this.selectedItems = [];
    else this.selectedItems = [...this.filteredItems];
    this.paint();
  }

  /* EVENTS */

  itemRowClick(event, item) {
    if (!this.editable) {
      event.item = item;
      if (event.detail == 2) this.itemDblClick.next(event);
      else this.itemClick.next(event);
    }
  }
  onLongPress(event, item) {
    event.item = item;
    this.itemDblClick.next(event);
  }

  callColumnFunc(funcName: string, item: any, column: any) {
    if (funcName === 'change' && item instanceof ObjectModel2) {
      console.log('item changed:', item);
      item.changed = true;
    }
    if (column[funcName] && typeof column[funcName] === 'function') {
      this.zone.run(() => {
        column[funcName](item, column);
      });
    }
  }

  /* NUMBER FIELD */

  calculate(item: any, column: any) {
    if (column.calculate && typeof (column.calculate === 'function')) column.calculate(item);
  }

  /* ACTIONS */
  actionButtonClick(action: any, event) {
    if (action.onClick && typeof action.onClick === 'function') action.onClick(event);
  }

  /* ROW ACTIONS */
  rowActionClick(event, action, item) {
    event.stopPropagation();
    event.preventDefault();
    this.rowActionsItem = null;
    setTimeout(() => {
      if (action.click && typeof action.click === 'function') action.click(event, item);
    }, 0);
  }

  /* CHECKBOX */

  checkboxLabelClick(event, item, column) {
    this.editingValue = !this.editingValue;
    // if (item && column && column.field) {
    //     item[column.field] = !item[column.field];
    //     this.callColumnFunc('change', item, column);
    // }
  }

  /* TOTALS */

  public columnTotalFunc: string[] = [];
  public columnTotalValue: string[] = [];
  public totalFunctions: any = {
    Somme: (items: any[], column: DataGridColumn) => {
      if (items && items.length > 0 && column.type === 'number') {
        let total: number = 0;
        total = items.reduce<number>((prev, cur) => prev + (column.getter ? column.getter(cur, column) : 0), 0);
        // for(let i=0; i<items.length; ++i) total += this.getItemValue(items[i], column);
        return NumberUtil.formatNumber(total, column.decimalsCount, '.') + (column.unit ? ' ' + column.unit : '');
      } else return '';
    },
    'Nombre non vides': (items: any[], column: DataGridColumn) => {
      if (items && items.length > 0) {
        let total: number = 0;
        total = items.reduce<number>((prev, cur) => {
          const value = column.getter ? column.getter(cur, column) : 0;
          return prev + (!value || value == '' ? 0 : 1);
        }, 0);
        // for(let i=0; i<items.length; ++i) {
        //     let value: any = this.getItemValue(items[i], column);
        //     total += (!value || value == '') ? 0 : 1;
        // }
        return NumberUtil.formatNumber(total, 0, '.');
      } else return '';
    },
  };

  public get totalFunctionNames() {
    return Object.keys(this.totalFunctions);
  }

  public calculateTotal(index, items, column) {
    let funcName: string = this.columnTotalFunc[index];
    if (funcName) this.columnTotalValue[index] = this.totalFunctions[funcName](items, column);
  }

  public toggleTotals() {
    this.showTotals = !this.showTotals;
    setTimeout(() => {
      this.resizeTableBody();
    }, 0);
  }

  /* COPY */

  public copyHtml: string = '';
  public copyText: string = '';
  @ViewChild('copyElement') copyElement: ElementRef;

  public async copyTable(includeHeaders: boolean, includeFooters: boolean, items?: any[], columns?: DataGridColumn[]) {
    LoadingComponent.push();
    this.copyHtml = this._generateCopyHtml(includeHeaders, includeFooters, items, columns);
    this.copyText = this._generateCopyText(includeHeaders, includeFooters, items, columns);

    await navigator['clipboard'].write([
      new ClipboardItem({
        ['text/plain']: new Blob([this.copyText], { type: 'text/plain' }),
        ['text/html']: new Blob([this.copyHtml], { type: 'text/html' }),
      }),
    ]);

    NotificationsComponent.push({
      type: 'success',
      summary: 'Le contenu de la grille a été copié dans la presse-papiers.',
      title: 'Contenu copié',
    });
    LoadingComponent.pop();
  }

  private copySelection() {
    this.copyTable(
      false,
      false,
      this._filteredItems.slice(this._activeCellStartY, this._activeCellEndY + 1),
      this.columns.slice(this._activeCellStartX, this._activeCellEndX + 1)
    );
  }

  public getActiveCell() {
    return [this._activeCellStartX, this._activeCellStartY];
  }

  private _generateCopyHtml(
    includeHeaders: boolean,
    includeFooters: boolean,
    items?: any[],
    columns?: DataGridColumn[]
  ) {
    return `
            <table>
                ${includeHeaders ? this._generateHeadersHtml() : ''}
                ${(items || this._filteredItems || [])
                  .map(
                    (item) =>
                      `<tr>
                        ${(columns || this.columns || [])
                          .map((col) => {
                            const value: any = col.getter ? col.getter(item, col) : '';
                            return `
                                <td style="
                                    ${col.excelFormat ? "mso-number-format: '" + col.excelFormat(col) + "';" : ''}
                                    background-color: ${col.backColor || 'rgb(255,255,255)'};
                                    border: 1px solid gray;
                                    font-family: Calibri;
                                    font-weight: ${col.fontWeight || 'normal'};
                                    font-size: ${col.fontSize || '12pt'};
                                ">
                                    ${
                                      col.excelValue
                                        ? col.excelValue(col, value)
                                        : col.type === 'foreing-list'
                                        ? this._getFormattedText(item, value, col)
                                        : value != null && value != undefined
                                        ? value.toString()
                                        : ''
                                    }
                                </td>
                            `;
                          })
                          .join('\n')}
                    </tr>`
                  )
                  .join('\n')}
                  ${includeFooters ? this._generateFootersHtml() : ''}
            </table>
        `;
  }

  private _generateCopyText(
    includeHeaders: boolean,
    includeFooters: boolean,
    items?: any[],
    columns?: DataGridColumn[]
  ) {
    return [
      includeHeaders ? this._generateHeadersText() : null,
      ...(items || this._filteredItems || []).map((item) =>
        (columns || this.columns || [])
          .map((col) => {
            const value: any = col.getter ? col.getter(item, col) : '';
            return col.excelValue
              ? col.excelValue(col, value)
              : col.type === 'foreing-list'
              ? this._getFormattedText(item, value, col)
              : value != null && value != undefined
              ? value.toString()
              : '';
          })
          .join('\t')
      ),
      includeFooters ? this._generateFootersText() : null,
    ]
      .filter((s) => s != null)
      .join('\n');
  }

  private _generateHeadersHtml() {
    const fixedRows: HTMLElement[] = $('tr', this.fixedCell.nativeElement).toArray();
    const bodyRows: HTMLElement[] = $('tr', this.headerElem.nativeElement).toArray();
    const rows: HTMLElement[][] = [];
    fixedRows.map((row: HTMLElement) => {
      rows.push(
        $(row)
          .children()
          .toArray()
          .slice(this.selectable ? 1 : 0)
      );
    });
    for (let i = 0; i < bodyRows.length; ++i) {
      rows[i] = rows[i].concat($(bodyRows[i]).children().toArray());
    }
    return (rows || [])
      .map(
        (row) =>
          `<tr>${(row || []).map((cell) => this._generateCellHtml(cell as HTMLTableCellElement)).join('\n')}</tr>`
      )
      .join('\n');
  }

  private _generateFootersHtml() {
    const fixedRows: HTMLElement[] = $('tr', this.asideElem.nativeElement).toArray();
    const bodyRows: HTMLElement[] = $('tr', this.footerElem.nativeElement).toArray();
    const rows: string[][] = [];
    fixedRows.map((row: HTMLElement) => {
      rows.push(
        $(row)
          .children()
          .toArray()
          .map((cell, index) => this._generateCellHtml(cell as HTMLTableCellElement, index === 0 && this.selectable))
      );
    });
    for (let i = 0; i < bodyRows.length; ++i) {
      rows[i] = rows[i].concat(
        $(bodyRows[i])
          .children()
          .toArray()
          .map((cell) => this._generateCellHtml(cell as HTMLTableCellElement))
      );
    }
    return (rows || []).map((row) => `<tr>${(row || []).join('\n')}</tr>`).join('\n');
  }

  private _generateCellHtml(cell: HTMLTableCellElement, decrementColspan: boolean = false) {
    return `<${cell.tagName} rowspan="${cell.rowSpan}" colspan="${
      (Number(cell.colSpan) || 0) - (decrementColspan ? 1 : 0)
    }" style="
            background-color: ${$(cell).css('background-color') || 'rgba(255,255,255)'};
            border: ${$(cell).css('border') || '1px solid gray'};
            font-family: ${$(cell).css('font-family') || 'Calibri'};
            font-weight: ${$(cell).css('font-weight') || 'normal'};
            font-style: ${$(cell).css('font-style') || 'normal'};
            font-size: ${$(cell).css('font-size') || '12pt'};
        ">
            ${cell.innerText.replace('\n', '')}
        </${cell.tagName}>`;
  }

  private _generateHeadersText() {
    const fixedRows: HTMLElement[] = $('tr', this.fixedCell.nativeElement).toArray();
    const bodyRows: HTMLElement[] = $('tr', this.headerElem.nativeElement).toArray();
    const rows: HTMLElement[][] = [];
    fixedRows.map((row: HTMLElement) => {
      rows.push(
        $(row)
          .children()
          .toArray()
          .slice(this.selectable ? 1 : 0)
      );
    });
    for (let i = 0; i < bodyRows.length; ++i) {
      rows[i] = rows[i].concat($(bodyRows[i]).children().toArray());
    }
    return (rows || [])
      .map(
        (row) =>
          `<tr>${(row || []).map((cell) => this._generateCellText(cell as HTMLTableCellElement)).join('\n')}</tr>`
      )
      .join('\n');
  }

  private _generateFootersText() {
    const fixedRows: HTMLElement[] = $('tr', this.asideElem.nativeElement).toArray();
    const bodyRows: HTMLElement[] = $('tr', this.footerElem.nativeElement).toArray();
    const rows: string[][] = [];
    fixedRows.map((row: HTMLElement) => {
      rows.push(
        $(row)
          .children()
          .toArray()
          .map((cell, index) => this._generateCellText(cell as HTMLTableCellElement, index === 0 && this.selectable))
      );
    });
    for (let i = 0; i < bodyRows.length; ++i) {
      rows[i] = rows[i].concat(
        $(bodyRows[i])
          .children()
          .toArray()
          .map((cell) => this._generateCellText(cell as HTMLTableCellElement))
      );
    }
    return (rows || []).map((row) => `<tr>${(row || []).join('\n')}</tr>`).join('\n');
  }

  private _generateCellText(cell: HTMLTableCellElement, decrementColspan: boolean = false) {
    return cell.innerText.replace('\n', '');
  }

  /* FIXED COLUMNS */

  private _fixedColumnsCount: number = 0;
  @Input() public set fixedColumnsCount(value: number) {
    this._fixedColumnsCount = value;
    this._splitColumns();
  }
  public get fixedColumnsCount() {
    return this._fixedColumnsCount;
  }

  public get fixedHeaderColumns() {
    if (this._fixedColumnsCount <= 0) return [];
    let cols: any = this.headerColumns || this._columns;
    return cols.slice(0, this._fixedColumnsCount);
  }

  public get fixedColumns() {
    if (this._fixedColumnsCount <= 0) return [];
    return this._columns.slice(0, this._fixedColumnsCount);
  }

  public get scrollableHeaderColumns() {
    let cols: any = this.headerColumns || this._columns;
    if (this._fixedColumnsCount <= 0) return cols;
    return cols.slice(this._fixedColumnsCount, cols.length);
  }

  public get scrollableColumns() {
    if (this._fixedColumnsCount <= 0) return this._columns;
    return this._columns.slice(this._fixedColumnsCount, this._columns.length);
  }

  @ViewChild('fixedCell') fixedCell: ElementRef;
  @ViewChild('tableBody') tableBody: ElementRef;
  @ViewChild('asideElem') asideElem: ElementRef;
  @ViewChild('headerElem') headerElem: ElementRef;
  @ViewChild('footerElem') footerElem: ElementRef;
  @ViewChild('tableContainer') tableContainer: ElementRef;

  private containerWidth: number = 0;
  private containerHeight: number = 0;
  private headerPadding: number = 0;

  resizeTableBody() {
    var fixedCellWidth = 0;
    this.fixedColumns.map((col) => (fixedCellWidth += col.width || DataGrid2Component.DEFAULT_COLUMN_WIDTH));
    if (this.selectable) fixedCellWidth += DataGrid2Component.SELECT_COLUMN_WIDTH;
    // $(this.fixedCell.nativeElement).width();
    const parentWidth: number = $('.table-container', this.elem.nativeElement).width();
    $(this.asideElem.nativeElement).css('width', fixedCellWidth + 'px');
    $(this.headerElem.nativeElement).css('left', fixedCellWidth + 'px');
    $(this.tableBody.nativeElement).css('width', parentWidth - fixedCellWidth + 'px');
    $(this.tableBody.nativeElement).css('max-width', parentWidth - fixedCellWidth + 'px');
    this._resizeCanvas();
    this.paint();
  }
  ngAfterContentInit() {
    setTimeout(() => {
      this._initCanvas();
      this.resizeTableBody();
    }, 0);
  }

  @ViewChild('bodyTable') bodyTable: ElementRef<HTMLTableElement>;
  @ViewChild('asideTable') asideTable: ElementRef<HTMLTableElement>;

  private _fixedColumns: DataGridColumn[] = [];
  private _bodyColumns: DataGridColumn[] = [];
  private _fixedWidth: number = 0;

  private _splitColumns() {
    this._fixedColumns = this.fixedColumns;
    this._bodyColumns = this.scrollableColumns;
    this._calculateColumnWidths();
  }

  private _calculateColumnWidths() {
    let x = this.selectable ? DataGrid2Component.SELECT_COLUMN_WIDTH : 0;
    this._fixedColumns.map((col) => {
      col.globalX = x;
      col.x = x;
      x += col.width || DataGrid2Component.DEFAULT_COLUMN_WIDTH;
    });
    this._fixedWidth = x;
    let globalX = x;
    x = 0;
    this._bodyColumns.map((col) => {
      col.globalX = globalX;
      col.x = x;
      globalX += col.width || DataGrid2Component.DEFAULT_COLUMN_WIDTH;
      x += col.width || DataGrid2Component.DEFAULT_COLUMN_WIDTH;
    });
    this._calculateFocusRect();
    // this._generateHtml();
  }

  private _generateSelectionColumn(index: number) {
    if (!this.selectable) return '';
    return (
      '<td class="selectionColumn" onclick="_grids[' +
      this.gridId +
      '].setRowSelection(event, ' +
      index +
      ')">' +
      '<input type="checkbox" />' +
      '</td>'
    );
  }
  private _generateColgroupHtml(columns: DataGridColumn[], addSelectionColumn: boolean = false) {
    return (
      '<colgroup>' +
      (addSelectionColumn ? '<col style="width: ' + DataGrid2Component.SELECT_COLUMN_WIDTH + 'px" />' : '') +
      columns
        .map(
          (col, index) =>
            '<col data-col-index="' +
            index +
            '" style="width: ' +
            (col.width || DataGrid2Component.DEFAULT_COLUMN_WIDTH) +
            'px; max-width: ' +
            (col.width || DataGrid2Component.DEFAULT_COLUMN_WIDTH) +
            'px;" />'
        )
        .join('\n') +
      '</colgroup>'
    );
  }
  private getFixedRowHtml = (item: any, rowIndex: number): string => {
    return (
      '<tr data-row-index="' +
      rowIndex +
      '" ' +
      'onmouseover="_grids[' +
      this.gridId +
      '].onRowHover(' +
      rowIndex +
      ')" ' +
      (this.rowStyleFunction ? 'style="' + this.rowStyleFunction(item, false) + '" ' : '') +
      '>' +
      this._generateSelectionColumn(rowIndex) +
      (this._fixedColumns || []).map((col, colIndex) => this._getColumnHtml(col, item, rowIndex, colIndex)).join('\n') +
      '</tr>'
    );
  };
  private getRowHtml = (item: any, rowIndex: number): string => {
    return (
      '<tr data-row-index="' +
      rowIndex +
      '" ' +
      'onmouseover="_grids[' +
      this.gridId +
      '].onRowHover(' +
      rowIndex +
      ')" ' +
      (this.rowStyleFunction ? 'style="' + this.rowStyleFunction(item, true) + '" ' : '') +
      '>' +
      (this._bodyColumns || [])
        .map((col, colIndex) => this._getColumnHtml(col, item, rowIndex, colIndex + this._fixedColumnsCount))
        .join('\n') +
      '</tr>'
    );
  };

  private _getColumnHtml(col: DataGridColumn, item, rowIndex: number, colIndex: number) {
    let value: any = col.getter ? col.getter(item, col) : ''; // this.getItemValue(item, col);
    return (
      '<td data-row-index="' +
      rowIndex +
      '" data-col-index="' +
      colIndex +
      '" ' +
      'onMouseDown="_grids[' +
      this.gridId +
      '].onCellMouseDown(event, ' +
      rowIndex +
      ', ' +
      colIndex +
      ')" ' +
      'onMouseUp="_grids[' +
      this.gridId +
      '].onCellMouseUp(event, ' +
      rowIndex +
      ', ' +
      colIndex +
      ')" ' +
      'onMouseMove="_grids[' +
      this.gridId +
      '].onCellMouseMove(event, ' +
      rowIndex +
      ', ' +
      colIndex +
      ')" ' +
      'onMouseOut="_grids[' +
      this.gridId +
      '].onCellMouseOut(event, ' +
      rowIndex +
      ', ' +
      colIndex +
      ')" ' +
      'onDblClick="_grids[' +
      this.gridId +
      '].onCellDblClick(event, ' +
      rowIndex +
      ', ' +
      colIndex +
      ')" ' +
      'style="' +
      (col.fontSize ? 'font-size:' + col.fontSize + ';' : '') +
      (col.fontWeight ? 'font-weight:' + col.fontWeight + ';' : '') +
      '" class="' +
      (col.type || 'text') +
      '">' +
      this._getFormattedText(item, value, col) +
      '</td>'
    );
  }

  public onRowHover(index: number) {
    $('tr.hover', this.elem.nativeElement).removeClass('hover');
    $(this.asideTable.nativeElement.getElementsByTagName('tbody')[0].children[index]).addClass('hover');
    $(this.bodyTable.nativeElement.getElementsByTagName('tbody')[0].children[index]).addClass('hover');
  }

  public setRowSelection(event: MouseEvent, index: number) {
    event.stopPropagation();
    event.preventDefault();
    const item: any = this._items[index];
    const n: number = this.selectedItems.indexOf(item);
    const target = $(event.target).closest('td');
    if (n >= 0) {
      this.selectedItems.splice(n, 1);
      setTimeout(() => {
        $('input[type="checkbox"]', target).prop('checked', false);
        $($('tr', this.asideTable.nativeElement)[index]).removeClass('selected');
        $($('tr', this.bodyTable.nativeElement)[index]).removeClass('selected');
      }, 0);
    } else {
      this.selectedItems.push(item);
      setTimeout(() => {
        $('input[type="checkbox"]', target).prop('checked', true);
        $($('tr', this.asideTable.nativeElement)[index]).addClass('selected');
        $($('tr', this.bodyTable.nativeElement)[index]).addClass('selected');
      }, 0);
    }
  }

  private _getCellElement(rowIndex: number, colIndex: number) {
    return $(
      'td[data-row-index="' + rowIndex + '"][data-col-index="' + colIndex + '"]',
      this.elem.nativeElement
    )[0] as HTMLTableCellElement;
  }

  private _formattingFunctions = {
    text: (item, value, column) => value,
    number: (item, value, column) =>
      NumberUtil.formatNumber(value, column.decimalsCount >= 0 ? column.decimalsCount : 2) +
      (column.currencyField && item[column.currencyField]
        ? ' ' + item[column.currencyField].symbol
        : column.unit
        ? ' ' + column.unit
        : ''),
    date: (item, value, column) => this.formatDate(value),
  };
  private _getFormattedText(item: any, value: any, column: DataGridColumn) {
    return (column.format || this._formattingFunctions[column.type || 'text'] || this._formattingFunctions['text'])(
      item,
      value,
      column
    );
  }

  private _activeCellStart: HTMLTableCellElement = null;
  private _activeCellEnd: HTMLTableCellElement = null;
  private _activeCellStartX: number = -1;
  private _activeCellStartY: number = -1;
  private _activeCellEndX: number = -1;
  private _activeCellEndY: number = -1;
  private _activating: boolean = false;
  private _justClicked: boolean = false;

  public onCellMouseDown(event: MouseEvent, rowIndex: number, colIndex: number) {
    DataGrid2Component.focusedGrid = this;
    if (event.button === 0) {
      this._activating = true;
      this._justClicked = true;
      if (!event.shiftKey || !this._activeCellStart) this._throttleActivate(this.setActiveCell, rowIndex, colIndex);
      else this.setActiveCellEnd(rowIndex, colIndex);
    }
  }
  public onCellMouseUp(event: MouseEvent, rowIndex: number, colIndex: number) {
    if (DataGrid2Component.focusedGrid == this && event.button === 0) {
      if (!this._justClicked) this._throttleActivate(this.setActiveCellEnd, rowIndex, colIndex);
      this._activating = false;
    }
  }
  public onCellMouseMove(event: MouseEvent, rowIndex: number, colIndex: number) {
    this._justClicked = false;
    if (DataGrid2Component.focusedGrid == this && this._activating) this.setActiveCellEnd(rowIndex, colIndex);
  }
  public onCellMouseOut(event: MouseEvent, rowIndex: number, colIndex: number) {}

  private _scrollTimeout: number = 0;
  private _throttleScroll(rowIndex, colIndex) {
    if (this._scrollTimeout <= 0) {
      this._scrollToCell(rowIndex, colIndex);
      this._scrollTimeout = window.setTimeout(() => (this._scrollTimeout = 0), 250);
    }
  }
  private _activateTimeout: number = 0;
  private _throttleActivate(func: Function, rowIndex, colIndex, update: boolean = true) {
    if (this._activateTimeout <= 0) {
      func.call(this, rowIndex, colIndex, update);
      this._activateTimeout = window.setTimeout(() => (this._activateTimeout = 0), 100);
    }
  }

  public setActiveCell(rowIndex: number, colIndex: number, update: boolean = true) {
    this.setActiveCellStart(rowIndex, colIndex, update);
    this.setActiveCellEnd(rowIndex, colIndex, update);
    console.log('emitting cells changed...');
    this.activeCellsChanged.emit([
      this._activeCellStartX,
      this._activeCellStartY,
      this._activeCellEndX,
      this._activeCellEndY,
    ]);
  }

  public setActiveCellStart(
    rowIndex: number,
    colIndex: number,
    update: boolean = true,
    throttleScroll: boolean = true
  ) {
    this._activeCellStartX = colIndex;
    this._activeCellStartY = rowIndex;
    if (throttleScroll) this._throttleScroll(rowIndex, colIndex);
    else this._scrollToCell(rowIndex, colIndex);
    this._calculateFocusRect();
    this.paint();
  }

  public setActiveCellEnd(rowIndex: number, colIndex: number, update: boolean = true, throttleScroll: boolean = true) {
    this._activeCellEndX = colIndex;
    this._activeCellEndY = rowIndex;
    if (throttleScroll) this._throttleScroll(rowIndex, colIndex);
    else this._scrollToCell(rowIndex, colIndex);
    this._calculateFocusRect();
    this.paint();
    // console.log('emitting cells changed...');
    // this.activeCellsChanged.emit([
    //   this._activeCellStartX,
    //   this._activeCellStartY,
    //   this._activeCellEndX,
    //   this._activeCellEndY,
    // ]);
  }

  private _shiftStartCell(rowDiff: number, colDiff: number, setEndCell: boolean = false) {
    let curRow: number = this._activeCellStartY; // ? parseInt($(this._activeCellStart).attr('data-row-index')) : 0;
    let curCol: number = this._activeCellStartX; // ? parseInt($(this._activeCellStart).attr('data-col-index')) : 0;
    curRow += rowDiff;
    curCol += colDiff;
    if (curRow < 0) curRow = 0;
    else if (curRow >= this._filteredItems.length) curRow = this._filteredItems.length - 1;
    if (curCol < 0) curCol = 0;
    else if (curCol >= this._columns.length) curCol = this._columns.length - 1;
    this.setActiveCellStart(curRow, curCol, true, false);
    if (setEndCell) this.setActiveCellEnd(curRow, curCol);
  }

  private _shiftEndCell(rowDiff: number, colDiff: number) {
    let curRow: number = this._activeCellEndY; // ? parseInt($(this._activeCellEnd).attr('data-row-index')) : 0;
    let curCol: number = this._activeCellEndX; // ? parseInt($(this._activeCellEnd).attr('data-col-index')) : 0;
    curRow += rowDiff;
    curCol += colDiff;
    if (curRow < 0) curRow = 0;
    else if (curRow >= this._filteredItems.length) curRow = this._filteredItems.length - 1;
    if (curCol < 0) curCol = 0;
    else if (curCol >= this._columns.length) curCol = this._columns.length - 1;
    this.setActiveCellEnd(curRow, curCol, true, false);
  }

  private _scrollToCell(rowIndex: number, colIndex: number) {
    if (rowIndex >= 0 && colIndex >= 0) {
      if (this._scrollTop > rowIndex * DataGrid2Component.ROW_HEIGHT) {
        this._scrollTop = rowIndex * DataGrid2Component.ROW_HEIGHT;
      } else if (this._scrollTop + this._scrollHeight < (rowIndex + 1) * DataGrid2Component.ROW_HEIGHT) {
        this._scrollTop = (rowIndex + 1) * DataGrid2Component.ROW_HEIGHT - this._scrollHeight;
      }
      const colX: number = this._columns[colIndex].x;
      const colWidth: number = this._columns[colIndex].width || DataGrid2Component.DEFAULT_COLUMN_WIDTH;
      if (this._scrollLeft > colX) {
        this._scrollLeft = colX;
      } else if (this._scrollLeft + this._scrollWidth < colX + colWidth) {
        this._scrollLeft = colX + colWidth - this._scrollWidth;
      }
    }
  }

  private _onKeyDown(event: KeyboardEvent) {
    if (DataGrid2Component.focusedGrid !== this) return;
    switch (event.key) {
      case 'ArrowUp':
        if (this.isEditing) return;
        if (event.ctrlKey) this.tableBody.nativeElement.scrollTop -= 50;
        else if (event.shiftKey) this._shiftEndCell(-1, 0);
        else this._shiftStartCell(-1, 0, true);
        break;
      case 'ArrowDown':
        if (this.isEditing) return;
        if (event.ctrlKey) this.tableBody.nativeElement.scrollTop += 50;
        else if (event.shiftKey) this._shiftEndCell(1, 0);
        else this._shiftStartCell(1, 0, true);
        break;
      case 'ArrowLeft':
        if (this.isEditing) return;
        if (event.ctrlKey) this.tableBody.nativeElement.scrollLeft -= 36;
        else if (event.shiftKey) this._shiftEndCell(0, -1);
        else this._shiftStartCell(0, -1, true);
        break;
      case 'ArrowRight':
        if (this.isEditing) return;
        if (event.ctrlKey) this.tableBody.nativeElement.scrollLeft += 36;
        else if (event.shiftKey) this._shiftEndCell(0, 1);
        else this._shiftStartCell(0, 1, true);
        break;
      case 'Enter':
        if (this.isEditing) this.onStopEdit();
        else this.startEditingCurrentCell();
        break;
      case 'Tab':
        if (this.isEditing) {
          this.onStopEdit();
          this.selectNextCell();
          this.startEditingCurrentCell();
        }
        break;
      case 'Escape':
        if (this.isEditing) this.onCancelEdit();
        break;
      case 'C':
      case 'c':
        if (event.ctrlKey) this.copySelection();
        else return;
        break;
      default:
        console.log('unhandled keydown:', event);
        return;
    }

    event.stopPropagation();
    event.preventDefault();
    return true;
  }

  public editingItem: any = null;
  public editingColumn: DataGridColumn = null;
  public showEditingField: boolean = false;
  public editingValue: any = null;
  // private _editingCell: HTMLTableCellElement = null;

  @ViewChild('editContainer') editContainer: ElementRef;

  public get isEditing() {
    return !!this.editingItem && !!this.editingColumn;
  }

  private _isColumnEditable(column: DataGridColumn) {
    return this.editable && column.getter && column.setter && column.readonly === false;
  }

  public onCellDblClick(event, rowIndex: number, colIndex: number) {
    const col: DataGridColumn = this._columns[colIndex];
    DataGrid2Component.focusedGrid = this;
    if (this._isColumnEditable(col)) this.startEditing(rowIndex, colIndex);
    else {
      this.zone.run(() => {
        const item: any = this._filteredItems[rowIndex];
        this.itemDblClick.emit(item);
      });
    }
  }

  public onCellLongPress(event, rowIndex: number, colIndex: number) {
    const col: DataGridColumn = this._columns[colIndex];
    const val = col.getter(this._filteredItems[rowIndex], col);
    if (val) {
      switch (col.type) {
        case 'phone':
          window.open(
            'tel:' +
              val
                .trim()
                .replace(/\(0\)/g, '')
                .replace(/[^\d\+]+/g, '')
          );
          break;
        case 'email':
          window.open('mailto:' + val.trim());
          break;
        default:
          break;
      }
    }
  }

  public selectNextCell() {
    if (this._activeCellStartX + 1 >= this.columns.length) {
      // If we reached the end of the row
      if (this._activeCellStartY + 1 >= this.filteredItems.length) {
        // If we reached the end of the table, do nothing
        // TODO: Allow automatic row insertion ?
      } else {
        // Else, reach first cell of next row
        this.setActiveCell(this._activeCellStartY + 1, 0);
      }
    } else {
      // Otherwise, select next cell
      this._shiftStartCell(0, 1, true);
    }
  }

  public startEditingCurrentCell() {
    if (this._activeCellStartX >= 0 && this._activeCellStartY >= 0) {
      this.startEditing(this._activeCellStartY, this._activeCellStartX);
    }
  }
  public startEditing(rowIndex: number, colIndex: number) {
    this.zone.run(() => {
      // const cell: HTMLTableCellElement = this._getCellElement(rowIndex, colIndex);
      const column: DataGridColumn =
        colIndex >= this._fixedColumnsCount
          ? this._bodyColumns[colIndex - this._fixedColumnsCount]
          : this._fixedColumns[colIndex];
      if (column && this._isColumnEditable(column)) {
        this.editingColumn = column;
        this.editingItem = this._filteredItems[rowIndex];
        this.editingValue = column.getter(this.editingItem, this.editingColumn);
        // this._editingCell = cell;
        setTimeout(() => {
          const rect = {
            left: this._columns[colIndex].x + $(this.asideElem.nativeElement).offset().left,
            top:
              rowIndex * DataGrid2Component.ROW_HEIGHT + $(this.asideElem.nativeElement).offset().top - this._scrollTop,
            width: this._columns[colIndex].width || DataGrid2Component.DEFAULT_COLUMN_WIDTH,
            height: DataGrid2Component.ROW_HEIGHT,
          };
          if (colIndex >= this.fixedColumnsCount) rect.left += this._bodyColumns[0].globalX - this._scrollLeft;
          $(this.editContainer.nativeElement).offset(rect);
          $(this.editContainer.nativeElement).css('width', rect.width + 'px');
          $(this.editContainer.nativeElement).css('height', rect.height + 'px');
          this.showEditingField = true;
          setTimeout(() => {
            $('input, select', this.editContainer.nativeElement).focus();
          }, 0);
        }, 0);
      }
    });
  }

  public onStopEdit() {
    this.onEditingValueChanged();
    this.onCancelEdit();
  }

  public onCancelEdit() {
    this.editingColumn = null;
    this.editingItem = null;
    this.showEditingField = false;
  }

  public onEditingInputClicked(event) {
    event.stopPropagation();
  }

  public onEditingValueChanged() {
    console.log('editing value changed:', this.editingItem, this.editingColumn, this.editingValue);
    this.editingColumn.setter(this.editingItem, this.editingColumn, this.editingValue);
    // this._editingCell.innerHTML = this._getFormattedText(this.editingItem, this.editingValue, this.editingColumn);
    this.paint();
    // this.onStopEdit();
  }

  /** CANVAS */

  @ViewChild('fixedCanvas') fixedCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('bodyCanvas') bodyCanvas: ElementRef<HTMLCanvasElement>;
  private _fixedCtx: CanvasRenderingContext2D = null;
  private _bodyCtx: CanvasRenderingContext2D = null;
  private _visibleItems: any[] = [];

  private _$fixedCanvas: JQuery<HTMLCanvasElement> = null;
  private _$bodyCanvas: JQuery<HTMLCanvasElement> = null;

  private _firstRowNum: number = 0;
  private _lastRowNum: number = 0;
  private get _scrollTop() {
    return $('.scroll__container', this.tableBody.nativeElement).scrollTop();
  }
  private set _scrollTop(value: number) {
    $('.scroll__container', this.tableBody.nativeElement).scrollTop(value);
  }
  private get _scrollLeft() {
    return $('.scroll__container', this.tableBody.nativeElement).scrollLeft();
  }
  private set _scrollLeft(value: number) {
    $('.scroll__container', this.tableBody.nativeElement).scrollLeft(value);
  }
  private get _scrollWidth() {
    return $('.scroll__container', this.tableBody.nativeElement).width() - 20;
  }
  private get _scrollHeight() {
    return $('.scroll__container', this.tableBody.nativeElement).height() - 20;
  }
  private _scrollDelta: number = 0;

  private _initCanvas() {
    this._fixedCtx = this.fixedCanvas.nativeElement.getContext('2d');
    this._bodyCtx = this.bodyCanvas.nativeElement.getContext('2d');

    this._$fixedCanvas = $(this.fixedCanvas.nativeElement);
    this._$bodyCanvas = $(this.bodyCanvas.nativeElement);

    $('.scroll__container', this.tableBody.nativeElement).scroll((event) => {
      this._updateGridScroll();
    });

    $('canvas', this.asideElem.nativeElement)
      .on('click', this._onFixedCanvasClick)
      .on('mousedown', this._onFixedCanvasMouseDown)
      .on('mouseup', this._onFixedCanvasMouseUp)
      .on('mousemove', this._onFixedCanvasMouseMove)
      .on('dblclick', this._onFixedCanvasMouseDblClick)
      .on('touchstart', this._onFixedCanvasTouchStart)
      .on('touchmove', this._onFixedCanvasTouchMove)
      .on('touchend', this._onFixedCanvasTouchEnd);

    $('.filler', this.tableBody.nativeElement)
      .on('mousedown', this._onBodyCanvasMouseDown)
      .on('mouseup', this._onBodyCanvasMouseUp)
      .on('mousemove', this._onBodyCanvasMouseMove)
      .on('dblclick', this._onBodyCanvasMouseDblClick)
      .on('touchstart', this._onBodyCanvasTouchStart)
      .on('touchmove', this._onBodyCanvasTouchMove)
      .on('touchend', this._onBodyCanvasTouchEnd);
  }

  private _updateGridScroll() {
    // let $body = $(this.tableBody.nativeElement);
    let $header = $(this.headerElem.nativeElement);
    $header.css('margin-left', -this._scrollLeft);
    let $asideFiller = $('.filler', this.asideElem.nativeElement);
    $asideFiller.css('margin-top', -this._scrollTop);
    this.regenerateVisibleItems();
  }

  public regenerateVisibleItems() {
    this._firstRowNum = Math.floor(this._scrollTop / DataGrid2Component.ROW_HEIGHT);
    this._lastRowNum =
      this._firstRowNum + Math.ceil(this.bodyCanvas.nativeElement.offsetHeight / DataGrid2Component.ROW_HEIGHT);
    this._visibleItems = this._filteredItems.slice(this._firstRowNum, this._lastRowNum);
    this._scrollDelta = this._firstRowNum * DataGrid2Component.ROW_HEIGHT - this._scrollTop;
    this._calculateFocusRect();
    this.paint();
  }

  private _resizeCanvas() {
    if (this._$fixedCanvas && this._$bodyCanvas) {
      const $fixedParent: JQuery = this._$fixedCanvas.parent();
      const $bodyParent: JQuery = this._$bodyCanvas.parent();
      this._$fixedCanvas.attr('width', $fixedParent.width());
      this._$fixedCanvas.attr('height', $fixedParent.height() + 16);
      this._$bodyCanvas.attr('width', $bodyParent.width());
      this._$bodyCanvas.attr('height', $bodyParent.height());
      this.regenerateVisibleItems();
    }
  }

  private _resizeFillers() {
    if (this._fixedColumns.length > 0) {
      const lastFixedCol: DataGridColumn = this._fixedColumns[this._fixedColumns.length - 1];
      $('.filler', this.asideElem.nativeElement).width(
        lastFixedCol.x + (lastFixedCol.width || DataGrid2Component.DEFAULT_COLUMN_WIDTH)
      );
    }
    if (this._bodyColumns.length > 0) {
      const lastFixedCol: DataGridColumn = this._bodyColumns[this._bodyColumns.length - 1];
      $('.filler', this.tableBody.nativeElement).width(
        lastFixedCol.x + (lastFixedCol.width || DataGrid2Component.DEFAULT_COLUMN_WIDTH)
      );
    }
    if (this._filteredItems) {
      $('.filler', this.elem.nativeElement).height(this._filteredItems.length * DataGrid2Component.ROW_HEIGHT);
    }
    this.regenerateVisibleItems();
  }

  public paint() {
    if (this._fixedCtx) this.paintFixedCanvas();
    if (this._bodyCtx) this.paintBodyCanvas();
  }

  public paintFixedCanvas() {
    this._fixedCtx.clearRect(
      0,
      0,
      this.fixedCanvas.nativeElement.offsetWidth,
      this.fixedCanvas.nativeElement.offsetHeight
    );
    if (this.selectable) {
      this._visibleItems.map((item, index) =>
        this._paintSelectionCell(
          index * DataGrid2Component.ROW_HEIGHT + this._scrollDelta,
          this.selectedItems.includes(item),
          this.hoverItem === item
        )
      );
    }
    const deltaX = 0; //this.selectable ? DataGrid2Component.SELECT_COLUMN_WIDTH : 0;
    this._paintRows(this._fixedCtx, this._visibleItems, this._fixedColumns, 0, true, false, false);
    this._paintFocusRect(this._fixedCtx, deltaX, 0, true, false);
    this._paintRows(this._fixedCtx, this._visibleItems, this._fixedColumns, 0, false, true, true);
    this._paintFocusRect(this._fixedCtx, deltaX, 0, false, true);
  }

  public paintBodyCanvas() {
    this._bodyCtx.clearRect(
      0,
      0,
      this.bodyCanvas.nativeElement.offsetWidth,
      this.bodyCanvas.nativeElement.offsetHeight
    );
    const deltaX = -this._scrollLeft;
    this._paintRows(this._bodyCtx, this._visibleItems, this._bodyColumns, deltaX, true, false, false);
    this._paintFocusRect(this._bodyCtx, deltaX - this._fixedWidth, 0, true, false);
    this._paintRows(this._bodyCtx, this._visibleItems, this._bodyColumns, deltaX, false, true, true);
    this._paintFocusRect(this._bodyCtx, deltaX - this._fixedWidth, 0, false, true);
  }

  private _getRowBackgroundColor(item: any) {
    if (this.selectedItems.includes(item)) return '#64c0e0';
    else return 'rgb(255,255,255)';
  }

  private _paintRows(
    ctx: CanvasRenderingContext2D,
    items: any[],
    columns: DataGridColumn[],
    deltaX: number = 0,
    fill: boolean = true,
    stroke: boolean = true,
    text: boolean = true
  ) {
    items.map((item, index) =>
      columns.map((col) => {
        const value: any = col.getter ? col.getter(item, col) : '';
        const bgcolor: string =
          col.readonly === false ? DataGrid2Component.EDITABLE_BGCOLOR : DataGrid2Component.READONLY_BGCOLOR;
        const selected: boolean = this.selectedItems.includes(item);
        const hover: boolean = item === this.hoverItem;
        this._paintCell(
          ctx,
          col.x + deltaX,
          index * DataGrid2Component.ROW_HEIGHT + this._scrollDelta,
          col.width || DataGrid2Component.DEFAULT_COLUMN_WIDTH,
          DataGrid2Component.ROW_HEIGHT,
          bgcolor,
          selected,
          hover,
          this._getFormattedText(item, value, col),
          fill,
          stroke,
          text,
          col.type === 'number' ? 'right' : 'left',
          col.render
        );
      })
    );
  }

  private _paintSelectionCell(y: number, selected: boolean, hover: boolean) {
    this._drawCellRect(
      this._fixedCtx,
      DataGrid2Component.READONLY_BGCOLOR,
      selected,
      hover,
      true,
      0,
      y,
      DataGrid2Component.SELECT_COLUMN_WIDTH,
      DataGrid2Component.ROW_HEIGHT
    );
    this._fixedCtx.strokeStyle = 'rgb(0,0,0)';
    this._fixedCtx.strokeRect(0, y, DataGrid2Component.SELECT_COLUMN_WIDTH, DataGrid2Component.ROW_HEIGHT);
    const [imageReady, image] = selected
      ? [DataGrid2Component.checkboxCheckedImageReady, DataGrid2Component.CHECKBOX_CHECKED_IMAGE]
      : [DataGrid2Component.checkboxUncheckedImageReady, DataGrid2Component.CHECKBOX_UNCHECKED_IMAGE];
    if (imageReady) this._fixedCtx.drawImage(image, 5, y + (DataGrid2Component.ROW_HEIGHT - 20) / 2);
  }

  private _drawCellRect(
    ctx: CanvasRenderingContext2D,
    bgcolor: string,
    selected: boolean,
    hover: boolean,
    border: boolean,
    x: number,
    y: number,
    w: number,
    h: number,
    fill: boolean = true,
    stroke: boolean = true
  ) {
    if (fill) {
      ctx.fillStyle = bgcolor;
      ctx.fillRect(x, y, w, h);
      if (selected) {
        ctx.fillStyle = DataGrid2Component.SELECTED_BGCOLOR;
        ctx.fillRect(x, y, w, h);
      }
      if (hover) {
        ctx.fillStyle = DataGrid2Component.HOVER_BGCOLOR;
        ctx.fillRect(x, y, w, h);
      }
    }
    if (border && stroke) {
      ctx.lineWidth = 1;
      ctx.strokeStyle = 'rgb(0,0,0)';
      ctx.strokeRect(x, y, w, h);
    }
  }

  private _paintCell(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
    bgcolor: string,
    selected: boolean,
    hover: boolean,
    content: string,
    fill: boolean = true,
    stroke: boolean = true,
    text: boolean = true,
    textAlign: CanvasTextAlign = 'left',
    render?: DataGridRenderFunc
  ) {
    this._drawCellRect(ctx, bgcolor, selected, hover, true, x, y, w, h, fill, stroke);
    if (render) render(ctx, { x, y, w, h }, content);
    else {
      const textWidth: number = ctx.measureText(content).width;
      ctx.textBaseline = 'middle';
      ctx.font = '12pt Calibri';
      ctx.fillStyle = 'rgb(0,0,0)';
      ctx.textAlign = textAlign;
      if (text) {
        ctx.save();
        ctx.beginPath();
        ctx.rect(x, y, textWidth > w - 10 ? w - 20 : w, h);
        ctx.clip();
        if (textAlign === 'right') ctx.fillText(content, x + w - 5, y + h / 2);
        else ctx.fillText(content, x + 5, y + h / 2);
        ctx.restore();
      }
      if (textWidth > w - 10) {
        this._drawCellRect(ctx, bgcolor, selected, hover, false, x + w - 20, y + 1, 19, h - 2, fill, false);
        if (text) ctx.fillText('...', x + w - 20, y + h / 2);
      }
    }
  }

  private _focusRect = { x: 0, y: 0, w: 0, h: 0 };
  private _calculateFocusRect() {
    let startRow: number = this._activeCellStartY - this._firstRowNum;
    let endRow: number = this._activeCellEndY - this._firstRowNum;
    let startCol: number = this._activeCellStartX;
    let endCol: number = this._activeCellEndX;
    if (startCol >= 0 && endCol >= 0) {
      if (startRow > endRow) {
        var tmp = startRow;
        startRow = endRow;
        endRow = tmp;
      }
      if (startCol > endCol) {
        var tmp = startCol;
        startCol = endCol;
        endCol = tmp;
      }
      const startX: number = this._columns[startCol].globalX;
      const endX: number =
        this._columns[endCol].globalX + (this._columns[endCol].width || DataGrid2Component.DEFAULT_COLUMN_WIDTH);
      const startY: number = startRow * DataGrid2Component.ROW_HEIGHT + this._scrollDelta;
      const endY: number = (endRow + 1) * DataGrid2Component.ROW_HEIGHT + this._scrollDelta;
      const width: number = endX - startX;
      const height: number = endY - startY;
      this._focusRect.x = startX;
      this._focusRect.y = startY;
      this._focusRect.w = width;
      this._focusRect.h = height;
    }
  }
  private _paintFocusRect(
    ctx: CanvasRenderingContext2D,
    deltaX: number,
    deltaY: number,
    fill: boolean = true,
    stroke: boolean = true
  ) {
    if (this._focusRect.w > 0 && this._focusRect.h > 0) {
      if (fill) {
        ctx.fillStyle = DataGrid2Component.FOCUS_BGCOLOR;
        ctx.fillRect(this._focusRect.x + deltaX, this._focusRect.y + deltaY, this._focusRect.w, this._focusRect.h);
      }
      if (stroke) {
        ctx.lineWidth = DataGrid2Component.FOCUS_BORDER_WIDTH;
        ctx.strokeStyle = DataGrid2Component.FOCUS_BORDER_COLOR;
        ctx.strokeRect(this._focusRect.x + deltaX, this._focusRect.y + deltaY, this._focusRect.w, this._focusRect.h);
      }
    }
  }

  // Mouse events

  private _getRowFromY(y: number): number {
    return Math.floor((y - this._scrollDelta) / DataGrid2Component.ROW_HEIGHT);
  }

  private _getFixedColumnFromX(x: number): number {
    x -= this.selectable ? DataGrid2Component.SELECT_COLUMN_WIDTH : 0;
    for (let i = 0; i < this._fixedColumns.length; ++i) {
      const col: DataGridColumn = this._fixedColumns[i];
      const width: number = col.width || DataGrid2Component.DEFAULT_COLUMN_WIDTH;
      if (x >= col.x && x < col.x + width) return i;
    }
    return -1;
  }

  private _getBodyColumnFromX(x: number, includeSelectColumn: boolean = false): number {
    // x += this._scrollLeft;
    for (let i = 0; i < this._bodyColumns.length; ++i) {
      const col: DataGridColumn = this._bodyColumns[i];
      const width: number = col.width || DataGrid2Component.DEFAULT_COLUMN_WIDTH;
      if (x >= col.x && x < col.x + width) return i + this._fixedColumnsCount;
    }
    return -1;
  }

  private _getItemFromY(y: number) {
    return this._visibleItems[this._getRowFromY(y)];
  }

  private _onFixedCanvasMouseMove = (e) => {
    const item = this._getItemFromY(e.offsetY);
    if (this.selectable) {
      if (e.offsetX < DataGrid2Component.SELECT_COLUMN_WIDTH) this._$fixedCanvas.css('cursor', 'pointer');
      else this._$fixedCanvas.css('cursor', 'default');
    }
    if (item !== this.hoverItem) {
      this.hoverItem = item;
      this.paint();
    }
    this.onCellMouseMove(
      e as MouseEvent,
      this._getRowFromY(e.offsetY) + this._firstRowNum,
      this._getFixedColumnFromX(e.offsetX)
    );
  };

  private _onFixedCanvasClick = (e) => {
    if (this.selectable && e.offsetX < DataGrid2Component.SELECT_COLUMN_WIDTH) {
      this.selectionCheckboxClick(e, this._getItemFromY(e.offsetY));
      this.paint();
    }
  };

  private _onFixedCanvasMouseDown = (e) => {
    if (this.selectable && e.offsetX < DataGrid2Component.SELECT_COLUMN_WIDTH) {
    } else
      this.onCellMouseDown(
        e as MouseEvent,
        this._getRowFromY(e.offsetY) + this._firstRowNum,
        this._getFixedColumnFromX(e.offsetX)
      );
  };

  private _onFixedCanvasMouseUp = (e) => {
    if (this.selectable && e.offsetX < DataGrid2Component.SELECT_COLUMN_WIDTH) {
    } else
      this.onCellMouseUp(
        e as MouseEvent,
        this._getRowFromY(e.offsetY) + this._firstRowNum,
        this._getFixedColumnFromX(e.offsetX)
      );
  };

  private _onFixedCanvasMouseDblClick = (e) => {
    this.onCellDblClick(
      e as MouseEvent,
      this._getRowFromY(e.offsetY) + this._firstRowNum,
      this._getFixedColumnFromX(e.offsetX)
    );
  };

  private _onBodyCanvasMouseMove = (e) => {
    const item = this._getItemFromY(e.offsetY - this._scrollTop);
    if (item !== this.hoverItem) {
      this.hoverItem = item;
      this.paint();
    }
    this.onCellMouseMove(
      e as MouseEvent,
      this._getRowFromY(e.offsetY - this._scrollTop) + this._firstRowNum,
      this._getBodyColumnFromX(e.offsetX)
    );
  };

  private _onBodyCanvasMouseDown = (e) => {
    this.onCellMouseDown(
      e as MouseEvent,
      this._getRowFromY(e.offsetY - this._scrollTop) + this._firstRowNum,
      this._getBodyColumnFromX(e.offsetX)
    );
  };

  private _onBodyCanvasMouseUp = (e) => {
    this.onCellMouseUp(
      e as MouseEvent,
      this._getRowFromY(e.offsetY - this._scrollTop) + this._firstRowNum,
      this._getBodyColumnFromX(e.offsetX)
    );
  };

  private _onBodyCanvasMouseDblClick = (e) => {
    this.onCellDblClick(
      e as MouseEvent,
      this._getRowFromY(e.offsetY - this._scrollTop) + this._firstRowNum,
      this._getBodyColumnFromX(e.offsetX)
    );
  };

  touchStartTime = -1;
  touchStartX = -1;
  touchStartY = -1;

  private _onBodyCanvasTouchStart = (e) => {
    this.touchStartTime = Date.now();
    const { left, top } = $(this.bodyCanvas.nativeElement).offset();
    this.touchStartX = e.touches[0].clientX - left + this._scrollLeft;
    this.touchStartY = e.touches[0].clientY - top + this._scrollTop;
  };

  private _onBodyCanvasTouchMove = (e) => {
    this.touchStartTime = -1;
  };

  private _onBodyCanvasTouchEnd = (e) => {
    if (this.touchStartTime > 0 && Date.now() - this.touchStartTime > 750) {
      e.preventDefault();
      this.onCellLongPress(
        e as MouseEvent,
        this._getRowFromY(this.touchStartY) + this._firstRowNum,
        this._getBodyColumnFromX(this.touchStartX)
      );
    }
    this.touchStartTime = -1;
    this.touchStartX = -1;
    this.touchStartY = -1;
  };

  private _onFixedCanvasTouchStart = (e) => {
    this.touchStartTime = Date.now();
    const { left, top } = $(this.fixedCanvas.nativeElement).offset();
    this.touchStartX = e.touches[0].clientX - left + this._scrollLeft;
    this.touchStartY = e.touches[0].clientY - top + this._scrollTop;
  };

  private _onFixedCanvasTouchMove = (e) => {
    this.touchStartTime = -1;
  };

  private _onFixedCanvasTouchEnd = (e) => {
    if (this.touchStartTime > 0 && Date.now() - this.touchStartTime > 750) {
      e.preventDefault();
      this.onCellLongPress(
        e as MouseEvent,
        this._getRowFromY(this.touchStartY) + this._firstRowNum,
        this._getFixedColumnFromX(this.touchStartX)
      );
    }
    this.touchStartTime = -1;
    this.touchStartX = -1;
    this.touchStartY = -1;
  };
}
