import { Component, OnInit } from '@angular/core';
import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { Logger } from '../../classes/Logger.class';

@Component({
  selector: 'app-logger-view',
  templateUrl: './logger-view.component.html',
  styleUrls: ['./logger-view.component.css']
})
export class LoggerViewComponent extends AccessViewComponent {

    public toolbar = {
        class: 'toolbar-big',
        viewTitle: 'Logs',
        data: this,
        elements: [
            { type: 'spacing' }
        ]
    };

    ngOnInit() {
    }

    initView(...args) {
    }

    get logEntries()
    {
        return Logger.entries;
    }

}
