import {
  FilterActions,
  FilterOperand,
  FilterOperator,
  FilterOperator as FilterOperatorDefinition,
  FilterOperators,
  IFilter,
  SortActions,
} from './IFilter';

import { DataGridColumn } from './DataGridColumn.class';

export class ForeingListFilter implements IFilter<string> {
  operators: FilterOperators<string>;
  operatorsList: FilterOperator<string>[];
  filterActionsName?: string;
  filterActions: FilterActions<string>;

  column: DataGridColumn;

  operator1?: FilterOperator<string>;
  public get value1(): string | undefined {
    return this.value1String;
  }
  value1String: string = undefined;

  operand: FilterOperand = FilterOperand.And;

  operator2?: FilterOperator<string>;
  public get value2(): string | undefined {
    return this.value2String;
  }
  value2String: string = undefined;

  availableValues: string[] = [];
  formattedValues: string[] = [];
  selectedValues: string[] = [];

  isActive(): boolean {
    return !!this.operator1 || !!this.operator2 || (this.selectedValues && this.selectedValues.length > 0);
  }

  check(value: string): boolean {
    if (this.selectedValues.length) return this.selectedValues.includes(value);
    let b1: boolean | null = null,
      b2: boolean | null = null;
    if (!!this.value1 && !!this.operator1) b1 = this.operator1.func(this.value1, value);
    if (!!this.value2 && !!this.operator2) b2 = this.operator2.func(this.value2, value);
    if (b1 !== null && b2 !== null) {
      if (this.operand == FilterOperand.And) return b1 && b2;
      else return b1 || b2;
    } else if (b1 !== null) return b1;
    else if (b2 !== null) return b2;
    else return true;
  }

  clear(): void {
    this.value1String = undefined;
    this.value2String = undefined;
    this.operator1 = undefined;
    this.operator2 = undefined;
    this.operand = FilterOperand.And;
    this.selectedValues = [];
  }

  sortActions: SortActions<string> = [
    {
      displayName: 'De A à Z',
      img: 'sort-alpha-asc',
      func: (value1: string, value2: string) => (value1 || '').localeCompare(value2),
    },
    {
      displayName: 'De Z à A',
      img: 'sort-alpha-desc',
      func: (value1: string, value2: string) => (value2 || '').localeCompare(value1),
    },
  ];
}
