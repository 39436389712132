import { Component, OnInit, ViewChild } from '@angular/core';
import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from '../../../../components/views/views.component';
import { DateTimeUtil } from '../../../utils/classes/DateTimeUtil.class';
import { DataGridComponent } from '../../../../components/data-grid/data-grid.component';
import { NotificationsComponent } from '../../../../components/notifications/notifications.component';
import { ArrayUtil } from '../../../utils/classes/ArrayUtil.class';
import { DataGridRowAction } from 'src/app/components/data-grid/classes/DataGridRowAction.class';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { ArticleBase } from '../../classes/ArticleBase.class';
import { ArticleViewComponent } from '../article-view/article-view.component';
import { Article } from '../../classes/Article.class';
import { Device } from '../../classes/Device.class';
import { TrashViewComponent } from 'src/app/modules/trash/views/trash-view/trash-view.component';
import { Service } from '../../classes/Service.class';
// import { SaleCreateViewComponent } from '../sale-create-view/sale-create-view.component';

@Component({
  selector: 'app-articles-list-view',
  templateUrl: './articles-list-view.component.html',
  styleUrls: ['./articles-list-view.component.css']
})
export class ArticlesListViewComponent extends AccessViewComponent {

    public permission: string = 'ecriture_basededonnees_liste_articles';

    public toolbar: Toolbar = {
        class: 'toolbar-big',
        viewTitle: 'Liste des articles',
        data: this,
        elements: [
            {
                type: 'button',
                text: 'Créer un<br/>article',
                icon: 'euro-sign',
                click: function(view: ArticlesListViewComponent) {
                    view.createArticle();
                },
                access: this.writeAccess
            },
            { type: 'separator' },
            {
                type: 'button',
                text: 'Supprimer',
                icon: 'times',
                click: function(view: ArticlesListViewComponent) {
                    view.deleteArticles();
                },
                access: this.writeAccess
            },
            { type: 'spacing' }
        ]
    };

    public articlesType: number = 0;

    public articles: ArticleBase[][] = [[],[],[]];
    public articlesGridColumns = [
        [
            // { title: 'Numéro', field: 'id' },
            // { title: 'Aperçu', field: 'field15', type: 'image' },
            { title: 'Désignation', field: 'designation', width: 420 },
            { title: 'Nomenclature', field: 'nomenclature.name', width: 300 },
            { title: 'Format', field: 'field1', width: 250 },
            { title: 'Matière', field: 'field2', width: 250 },
            // { title: 'Matière (clients)', field: 'field3' },
            { title: 'Impression', field: 'field4', width: 250 },
            { title: 'ø du mandrin / rouleau', field: 'field5', width: 170 },
            { title: 'Présentation', field: 'field6', width: 120},
            { title: 'Nb. d\'éti./feuil./bob./parav.', field: 'field7', width: 120 },
            { title: 'Esp. vertical/horizontal', field: 'field8', width: 100 },
            { title: 'Sens d\'enroulement', field: 'field9', width: 150 },
            { title: 'Laize', field: 'field10', width: 90 },
            { title: 'Pinfeed', field: 'field11', width: 100 },
            { title: 'Perforations / Façonnage', field: 'field12', width: 200 },
            { title: 'Autres', field: 'field13', width: 250 },
            { title: 'Conditionnement', field: 'field14', width: 250 }
        ],
        [
            // { title: 'Numéro', field: 'id' },
            // { title: 'Aperçu', field: 'field15', type: 'image' },
            { title: 'Désignation', field: 'designation', width: 420 },
            { title: 'Nomenclature', field: 'nomenclature.name', width: 300 },
            { title: 'Marque et modèle', field: 'field1', width: 250 },
            { title: 'Technologie', field: 'field2', width: 250 },
            { title: 'Résolution', field: 'field3', width: 160 },
            { title: 'Vitesse max.', field: 'field4', width: 180 },
            { title: 'Largeur du média', field: 'field5', width: 200 },
            { title: 'Epaisseur du média', field: 'field6', width: 200 },
            { title: 'Mémoire', field: 'field7', width: 100 },
            { title: 'Couteau', field: 'field8', width: 100 },
            { title: 'Interface (standard)', field: 'field9', width: 180 },
            { title: 'Programmation', field: 'field10', width: 140 },
            { title: 'Options', field: 'field11', width: 200 },
            { title: 'Construction', field: 'field12', width: 120 },
            { title: 'Garantie', field: 'field13', width: 120 },
            { title: 'Autres', field: 'field14', width: 250 }
        ],
        [
            { title: 'Désignation', field: 'designation', width: 420 },
            { title: 'Nomenclature', field: 'nomenclature.name', width: 300 },
            { title: 'Description', field: 'field1', width: 400 },
        ]
    ];
    public articlesGridHeaderBackColor: string[] = [
        "rgb(0,156,218)", "rgb(55,136,105)", "rgb(175, 170, 20)"
    ];
    public articlesGridRowActions: DataGridRowAction[] = [
        { name: 'Afficher', icon: 'search', click: (event: any, item: any) => {
            this.articleDblClick(event, item);
        } }
    ];

    @ViewChild('grid') grid: DataGridComponent;

    onActivate()
    {
        let self = this;
        Article.load(null, ['designation'], null, false, null, true).then(
            function(result) { self.articles[0] = result; },
            function(err) { console.error(err); }
        );
        Device.load(null, ['designation'], null, false, null, true).then(
            function(result) { self.articles[1] = result; },
            function(err) { console.error(err); }
        );
        Service.load(null, ['designation'], null, false, null, true).then(
            function(result) { self.articles[2] = result; },
            function(err) { console.error(err); }
        );
    }

    createArticle()
    {
        ViewsComponent.openView(ArticleViewComponent, null);
    }

    deleteArticles()
    {
        DialogsComponent.display({
            icon: 'warning',
            title: 'Supprimer les articles ?',
            message: 'Êtes-vous sûr(e) de vouloir supprimer les articles sélectionnés ?',
            buttons: DialogButton.yesNoButtons
        }).then((result: any) => {
            if (result === DialogButton.RESULT_YES)
            {
                let articles: Article[] = this.grid.selectedItems as Article[];
                if (Array.isArray(articles) && articles.length > 0)
                {
                    let promises: any[] = [];
                    for(let i=0; i<articles.length; ++i) promises.push(articles[i].delete());
                    Promise.all(promises).then(
                        (result) => {
                            NotificationsComponent.push({
                                type: 'success',
                                title: 'Articles supprimés',
                                summary: 'Les articles ont été supprimées avec succès'
                            });
                            ArrayUtil.removeElements(this.articles[this.articlesType], articles);
                        },
                        (err) => {
                            NotificationsComponent.push({
                                type: 'error',
                                title: 'Erreur lors de la suppression',
                                summary: 'Une erreur s\'est produite lors de la suppression',
                                content: err
                            });
                            console.error(err);
                        }
                    )
                }
            }
        });
    }

    articleDblClick(event, sale)
    {
        ViewsComponent.openView(ArticleViewComponent, sale);
    }

}
