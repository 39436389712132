import { Component, OnInit, Input, ViewChildren, QueryList, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Page } from '../../classes/Page.class';
import { PrintableElementComponent } from '../printable-element/printable-element.component';
import { TextUtil } from '../../../utils/classes/TextUtil.class';

@Component({
  selector: 'printable-page',
  templateUrl: './printable-page.component.html',
  styleUrls: ['./printable-page.component.css']
})
export class PrintablePageComponent implements OnInit {

    @Input() page: Page = null;
    @ViewChild('pageItem') pageItem: ElementRef;
    @ViewChildren('childElement') childrenElements: QueryList<PrintableElementComponent>;

    constructor(public elementRef: ElementRef) { }

    ngOnInit() {
    }

    public static getModel(settings: any, pageComponent: PrintablePageComponent)
    {
        let model = {
            infos: {
                'left': 0,
                'top': 0,
                'right': pageComponent.page.width,
                'bottom': pageComponent.page.height,
                'width': pageComponent.page.width,
                'height': pageComponent.page.height
            },
            headerSize: pageComponent.page.headerSize,
            footerSize: pageComponent.page.footerSize,
            topMargin: pageComponent.page.topMargin,
            leftMargin: pageComponent.page.leftMargin,
            rightMargin: pageComponent.page.rightMargin,
            bottomMargin: pageComponent.page.bottomMargin,
            elements: []
        }

        let page = pageComponent.pageItem.nativeElement;

        let _font_size = TextUtil.isPtSize(page.style.fontSize) ? parseFloat(TextUtil.left(page.style.fontSize, -2)) : null;
        model.infos['font-size'] = _font_size ? _font_size : 10;

        let _font_family = page.style.fontFamily;
        model.infos['font-family'] = (_font_family != '') ? _font_family : 'Arial';

        let children = pageComponent.childrenElements.toArray();
        for(let i=0; i<children.length; ++i) model.elements.push(PrintableElementComponent.getModel(settings, children[i], pageComponent, model));
        return model;
    }

}
