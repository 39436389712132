import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleBase } from '../module-base.class';
import { ClassManager } from '../../classes/objects/ClassManager.class';
import { Stock } from './classes/Stock.class';
import { ParentStock } from './classes/ParentStock.class';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { StocksListViewComponent } from './views/stocks-list-view/stocks-list-view.component';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../../components/components.module';
import { StockViewComponent } from './views/stock-view/stock-view.component';
import { StockMoveViewComponent } from './views/stock-move-view/stock-move-view.component';
import { ParentStockViewComponent } from './views/parent-stock-view/parent-stock-view.component';
import { ParentStocksListViewComponent } from './views/parent-stocks-list-view/parent-stocks-list-view.component';
import { StockMove } from './classes/StockMove.class';
import { PrintingModule } from '../printing/printing.module';
import { InvoicesListViewComponent } from '../invoicing/views/invoices-list-view/invoices-list-view.component';
import { ReimbursementViewComponent } from '../invoicing/views/reimbursement-view/reimbursement-view.component';
import { ReimbursementsListViewComponent } from '../invoicing/views/reimbursements-list-view/reimbursements-list-view.component';
import { StockMovesListViewComponent } from './views/stock-moves-list-view/stock-moves-list-view.component';
import { InvoiceViewComponent } from '../invoicing/views/invoice-view/invoice-view.component';
import { StockMovesFollowupViewComponent } from './views/stock-moves-followup-view/stock-moves-followup-view.component';
import { SelectStockDialogComponent } from './views/select-stock-dialog/select-stock-dialog.component';
import {StockAdjustmentViewComponent} from './views/stock-adjustment-view/stock-adjustment-view.component';
import {StocksAdjustmentsListViewComponent} from './views/stocks-adjustments-list-view/stocks-adjustments-list-view.component';
import {StockAdjustment} from './classes/StockAdjustment.class';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    PrintingModule
  ],
  declarations: [
      StocksListViewComponent,
      StockViewComponent,
      StockMovesListViewComponent,
      StockMoveViewComponent,
      ParentStockViewComponent,
      ParentStocksListViewComponent,
      StockMovesFollowupViewComponent,
      SelectStockDialogComponent,
      StockAdjustmentViewComponent,
      StocksAdjustmentsListViewComponent
  ],
  exports: [
      StocksListViewComponent,
      StockViewComponent,
      StockMovesListViewComponent,
      StockMoveViewComponent,
      ParentStockViewComponent,
      ParentStocksListViewComponent,
      StockMovesFollowupViewComponent,
      SelectStockDialogComponent,
      StockAdjustmentViewComponent,
      StocksAdjustmentsListViewComponent
  ],
  entryComponents: [
      StocksListViewComponent,
      StockViewComponent,
      StockMovesListViewComponent,
      StockMoveViewComponent,
      ParentStockViewComponent,
      ParentStocksListViewComponent,
      StockMovesFollowupViewComponent,
      SelectStockDialogComponent,
      StockAdjustmentViewComponent,
      StocksAdjustmentsListViewComponent
  ]
})
export class StocksModule extends ModuleBase {

    public static moduleName = 'suppliers';

    public ngOnInit()
    {
        ClassManager.registerClass('Stock', Stock);
        ClassManager.registerClass('StockAdjustment', StockAdjustment);
        ClassManager.registerClass('ParentStock', ParentStock);
        ClassManager.registerClass('StockMove', StockMove);

        HomepageViewComponent.registerGroups({
            'stocks': {
                'name': "Stocks",
                'backColor': 'rgb(0, 51, 205)',
                'textColor': 'white',
                'permission': 'home_groupe_stocks',
                'children': [
                    // {
                    //     'name': "Stocks parents",
                    //     'permission': 'home_bouton_stocks_parents',
                    //     'component': ParentStocksListViewComponent
                    // },
                    {
                        'name': "Liste des articles de stock",
                        'permission': 'home_bouton_stocks_liste',
                        'component': StocksListViewComponent
                    },
                    {
                        'name': "Ajustements de stock",
                        'permission': 'home_bouton_stocks_ajustements',
                        'component': StocksAdjustmentsListViewComponent
                    },
                    {
                        'name': "Choix de l\'article à traiter",
                        'permission': 'home_bouton_stocks_choix',
                        'backColor': 'rgb(51,102,255)',
                        'textColor': 'white',
                        'component': StockMoveViewComponent
                    },
                    {
                        'name': "Appels sur stock",
                        'permission': 'home_bouton_stocks_appels',
                        'backColor': 'rgb(149,179,215)',
                        'textColor': 'black',
                        'component': StockMovesListViewComponent,
                    },
                    {
                        'name': "Suivi des appels",
                        'permission': 'home_bouton_stocks_suivi',
                        'backColor': 'rgb(149,179,215)',
                        'textColor': 'black',
                        'component': StockMovesFollowupViewComponent,
                    }
                ]
            }
        });

        return super.ngOnInit();
    }

}
