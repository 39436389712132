import { Component, OnInit } from '@angular/core';
import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { ExcelData } from '../../classes/ExcelData.class';
import { Customer } from '../../../customers/classes/Customer.class';
import { Address } from '../../../addresses/classes/Address.class';


@Component({
  selector: 'database-customers-import-view',
  templateUrl: './customers-import-view.component.html',
  styleUrls: ['./customers-import-view.component.css']
})
export class CustomersImportViewComponent  extends AccessViewComponent {

    public excel: any = null;

    public selectedSheet: string = null;
    public skipLines: number = 1;

    public selectedFields: any = {
        A:"name",
        B:"number",
        D:"category",
        E:"lang",
        F:"payment",
        G:"billing_name1",
        H:"billing_address1",
        I:"billing_postcode",
        J:"billing_city",
        K:"billing_country",
        L:"billing_phone",
        M:"billing_fax",
        N:"billing_vat",
        O:"billing_mail",
        P:"delivery_name1",
        Q:"delivery_name2",
        R:"delivery_address1",
        S:"delivery_postcode",
        T:"delivery_city",
        U:"delivery_country",
        V:"delivery_phone",
        W:"delivery_fax",
        X:"delivery_hours",
        Y:"contact1_name1",
        Z:"contact1_name2",
        AA:"contact1_phone",
        AB:"contact1_fax",
        AC:"contact1_mobile",
        AD:"contact1_email",
        AE:"contact2_name1",
        AF:"contact2_name2",
        AG:"contact2_phone",
        AH:"contact2_fax",
        AI:"contact2_mobile",
        AJ:"contact2_email",
        AK:"contact3_name1",
        AL:"contact3_name2",
        AM:"contact3_phone",
        AN:"contact3_fax",
        AO:"contact3_mobile",
        AP:"contact3_email",
        AQ:"contact4_name1",
        AR:"contact4_name2",
        AS:"contact4_phone",
        AT:"contact4_fax",
        AU:"contact4_mobile",
        AV:"contact4_email",
        AW:"contact5_name1",
        AX:"contact5_name2",
        AY:"contact5_phone",
        AZ:"contact5_fax",
        BA:"contact5_mobile",
        BB:"contact5_email",
        CH:"ordinator_name1",
        CI:"ordinator_address1",
        CJ:"ordinator_postcode",
        CK:"ordinator_city",
        CL:"ordinator_country",
        CM:"ordinator_phone",
        CN:"ordinator_fax",
        CO:"ordinator_email",
        CP:"complaint_email",
        CQ:"billing_email"
    };
    private contacts: any = {};

    //public selectedFields: any = {};
    public fields = [
        { name: 'id', description: 'ID' },
        { name: 'name', description: 'Nom' },
        { name: 'number', description: 'Numéro' },
        { name: 'category', description: 'Catégorie' },
        { name: 'lang', description: 'Langue' },
        { name: 'payment', description: 'Paiement' },
        { name: 'billing_name1', description: 'ServiceF' },
        { name: 'billing_address1', description: 'RueF' },
        { name: 'billing_postcode', description: 'CodePostalF' },
        { name: 'billing_city', description: 'CommuneF' },
        { name: 'billing_country', description: 'PaysF' },
        { name: 'billing_phone', description: 'TelephoneF' },
        { name: 'billing_fax', description: 'FaxF' },
        { name: 'billing_vat', description: 'TVAF' },
        { name: 'billing_mail', description: 'EmailF' },
        { name: 'delivery_name1', description: 'DestinataireL' },
        { name: 'delivery_name2', description: 'ServiceL' },
        { name: 'delivery_address1', description: 'RueL' },
        { name: 'delivery_postcode', description: 'CodePostalL' },
        { name: 'delivery_city', description: 'CommuneL' },
        { name: 'delivery_country', description: 'PaysL' },
        { name: 'delivery_phone', description: 'TelephoneL' },
        { name: 'delivery_fax', description: 'FaxL' },
        { name: 'delivery_opening_hours', description: 'HeuresL' },
        { name: 'ordinator_name1', description: 'ServiceCO' },
        { name: 'ordinator_address1', description: 'RueCO' },
        { name: 'ordinator_postcode', description: 'CodePostalCO' },
        { name: 'ordinator_city', description: 'CommuneCO' },
        { name: 'ordinator_country', description: 'PaysCO' },
        { name: 'ordinator_phone', description: 'TelephoneCO' },
        { name: 'ordinator_fax', description: 'FaxCO' },
        { name: 'ordinator_email', description: 'EmailCO' },
        { name: 'complaint_email', description: 'ComplaintEmail' },
        { name: 'billing_email', description: 'BillingEmail' },
        { name: 'remarks', description: 'Remarques' },
        { name: 'id_merchant', description: 'Représentant' },

        { name: 'contact1_name1', description: 'Contact1' },
        { name: 'contact1_name2', description: 'Service1' },
        { name: 'contact1_phone', description: 'Tel1' },
        { name: 'contact1_fax', description: 'Fax1' },
        { name: 'contact1_mobile', description: 'GSM1' },
        { name: 'contact1_email', description: 'Email1' },

        { name: 'contact2_name1', description: 'Contact2' },
        { name: 'contact2_name2', description: 'Service2' },
        { name: 'contact2_phone', description: 'Tel2' },
        { name: 'contact2_fax', description: 'Fax2' },
        { name: 'contact2_mobile', description: 'GSM2' },
        { name: 'contact2_email', description: 'Email2' },

        { name: 'contact3_name1', description: 'Contact3' },
        { name: 'contact3_name2', description: 'Service3' },
        { name: 'contact3_phone', description: 'Tel3' },
        { name: 'contact3_fax', description: 'Fax3' },
        { name: 'contact3_mobile', description: 'GSM3' },
        { name: 'contact3_email', description: 'Email3' },

        { name: 'contact4_name1', description: 'Contact4' },
        { name: 'contact4_name2', description: 'Service4' },
        { name: 'contact4_phone', description: 'Tel4' },
        { name: 'contact4_fax', description: 'Fax4' },
        { name: 'contact4_mobile', description: 'GSM4' },
        { name: 'contact4_email', description: 'Email4' },

        { name: 'contact5_name1', description: 'Contact5' },
        { name: 'contact5_name2', description: 'Service5' },
        { name: 'contact5_phone', description: 'Tel5' },
        { name: 'contact5_fax', description: 'Fax5' },
        { name: 'contact5_mobile', description: 'GSM5' },
        { name: 'contact5_email', description: 'Email5' },

        { name: 'contact6_name1', description: 'Contact6' },
        { name: 'contact6_name2', description: 'Service6' },
        { name: 'contact6_phone', description: 'Tel6' },
        { name: 'contact6_fax', description: 'Fax6' },
        { name: 'contact6_mobile', description: 'GSM6' },
        { name: 'contact6_email', description: 'Email6' },

        { name: 'contact7_name1', description: 'Contact7' },
        { name: 'contact7_name2', description: 'Service7' },
        { name: 'contact7_phone', description: 'Tel7' },
        { name: 'contact7_fax', description: 'Fax7' },
        { name: 'contact7_mobile', description: 'GSM7' },
        { name: 'contact7_email', description: 'Email7' },

        { name: 'contact8_name8', description: 'Contact8' },
        { name: 'contact8_name2', description: 'Service8' },
        { name: 'contact8_phone', description: 'Tel8' },
        { name: 'contact8_fax', description: 'Fax8' },
        { name: 'contact8_mobile', description: 'GSM8' },
        { name: 'contact8_email', description: 'Email8' },

        { name: 'contact9_name1', description: 'Contact9' },
        { name: 'contact9_name2', description: 'Service9' },
        { name: 'contact9_phone', description: 'Tel9' },
        { name: 'contact9_fax', description: 'Fax9' },
        { name: 'contact9_mobile', description: 'GSM9' },
        { name: 'contact9_email', description: 'Email9' },

        { name: 'contact10_name1', description: 'Contact10' },
        { name: 'contact10_name2', description: 'Service10' },
        { name: 'contact10_phone', description: 'Tel10' },
        { name: 'contact10_fax', description: 'Fax10' },
        { name: 'contact10_mobile', description: 'GSM10' },
        { name: 'contact10_email', description: 'Email10' },
    ];

    ngOnInit() {
        ExcelData.loadExcelData('test_import.xlsx').subscribe((data) => {
        //ExcelData.loadExcelData('db.xlsm').subscribe((data) => {
            console.log('excel data recieved:', data);
            this.excel = data;
        });
    }

    get sheets() {
        if (!this.excel || !this.excel.sheets) return null;
        else return Object.keys(this.excel.sheets);
    }

    import() {
        console.log(this.selectedFields);
        let customers: Customer[] = [];
        for(let i=this.skipLines; i<this.excel.sheets[this.selectedSheet].rows.length; ++i)
        {
            let row = this.excel.sheets[this.selectedSheet].rows[i];
            let customer = new Customer();
            this.contacts = {};
            for (const column in this.selectedFields) {
                if (this.selectedFields.hasOwnProperty(column)) {
                    const field_name = this.selectedFields[column];
                    this.loadField(customer, row, column);
                }
            }
            for (const key in this.contacts) {
                if (this.contacts.hasOwnProperty(key)) {
                    customer.contacts.push(this.contacts[key]);
                }
            }
            console.log('imported customer:', customer);
            customers.push(customer);
            customer.save2();
        }
    }

    loadField(customer: Customer, row, column)
    {
        let field_name: string = this.selectedFields[column] || null;
        let value: any = row[column];
        if (field_name != null && value != null) {
            if (field_name.startsWith('billing_') && field_name != 'billing_email') {
                if (!customer.billing_address) customer.billing_address = new Address();
                customer.billing_address[field_name.substring(8)] = value;
            }
            else if (field_name.startsWith('delivery_')) {
                if (!customer.delivery_address) customer.delivery_address = new Address();
                customer.delivery_address[field_name.substring(9)] = value;
            }
            else if (field_name.startsWith('ordinator_')) {
                if (!customer.ordinator_address) customer.ordinator_address = new Address();
                customer.ordinator_address[field_name.substring(10)] = value;
            }
            else {
                let matches: RegExpMatchArray = field_name.match('^contact([0-9]+)_([a-z0-9]+)$');
                if (matches && matches.length > 0)
                {
                    let contact_num = matches[1];
                    let contact_field = matches[2];
                    if (!this.contacts[contact_num]) this.contacts[contact_num] = new Address();
                    this.contacts[contact_num][contact_field] = value;
                }
                else
                {
                    if (customer.hasOwnProperty(field_name)) customer[field_name] = value;
                }
            }
        }
    }

}
