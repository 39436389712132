import { ObjectModel2 } from "../objects/ObjectModel2.class";
import { ObjectDefinition } from "../objects/ObjectDefinition.class";
import { User } from "./User.class";
import { UserProfile } from "./UserProfile.class";
import { ObjectModel3 } from "../objects/ObjectModel3.class";
import { LoadSequence } from "../objects/LoadSequence.class";

export class Permission extends ObjectModel3
{
    public name: string = null;
    public description: string = null;
    public allowedUsers: User[] = [];
    public allowedProfiles: UserProfile[] = [];

    constructor()
    {
        super(Permission);
    }

    /* ObjectModel FUNCTIONS */

    public static definition: ObjectDefinition = {
        database: {
            table: 'permissions',
            id: 'id',
			db_id: 'db_id'
        },
        values: {
            'name': { type: 'string' },
            'description': { type: 'string' }
        },
        children: {},
        links: {
            'allowedUsers': { type: 'User', table: 'permissions_users', id_self: 'id_permission', id_link: 'id_user',
                              clone: false, save: false, delete: false },
            'allowedProfiles': { type: 'UserProfile', table: 'permissions_user_profiles', id_self: 'id_permission', id_link: 'id_user_profile',
                                 clone: false, save: false, delete: false },
        }
    };
}
