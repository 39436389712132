import { Component, OnInit } from '@angular/core';
import { DialogComponent } from 'src/app/components/dialogs/dialog/dialog.component';

@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.css']
})
export class ResetPasswordModalComponent extends DialogComponent {

    public newPassword: string = "";

}
