import { Currency } from './Currency.class';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';

export class Accounting extends ObjectModel3 {
  public name: string = null;
  public number: number = null;
  public vat_number: string = null;
  public address: string = null;
  public phone_number: string = null;
  public default_currency: Currency = null;
  public general_account: string = null;
  public commercial_sales_journal: string = null;
  public logistics_sales_journal: string = null;
  public commercial_credit_journal: string = null;
  public logistics_credit_journal: string = null;
  public sales_account: string = null;
  public credit_account: string = null;
  public purchases_journal: string = null;
  public purchases_account: string = null;

  public iban: string = null;
  public bic: string = null;
  public contacts: string = null;
  public company: string = null;
  public seat: string = null;

  public static _all: Accounting[] = null;
  public static getAll(forceReload: boolean = false): Promise<Accounting[]> {
    return LoadingPromise.create<Accounting[]>((resolve, reject) => {
      if (!Accounting._all || forceReload === true) {
        Accounting.load(null, ['number']).then(
          (result: Accounting[]) => {
            Accounting._all = result;
            resolve(result);
          },
          (err) => {
            console.error(err);
            reject(err);
          }
        );
      } else resolve(Accounting._all);
    });
  }

  public get fullDescription() {
    return this.name + ' (' + this.number.toString() + ')';
  }

  public constructor() {
    super(Accounting);
  }

  /* ObjectModel FUNCTIONS */

  public static definition: ObjectDefinition = {
    database: {
      table: 'accountings',
      id: 'id',
      db_id: 'db_id',
    },
    values: {
      name: { type: 'string' },
      number: { type: 'number' },
      vat_number: { type: 'string' },
      address: { type: 'string' },
      phone_number: { type: 'string' },
      general_account: { type: 'string' },
      commercial_sales_journal: { type: 'string' },
      logistics_sales_journal: { type: 'string' },
      commercial_credit_journal: { type: 'string' },
      logistics_credit_journal: { type: 'string' },
      sales_account: { type: 'string' },
      credit_account: { type: 'string' },
      purchases_journal: { type: 'string' },
      purchases_account: { type: 'string' },
      iban: { type: 'string' },
      bic: { type: 'string' },
      contacts: { type: 'string' },
      company: { type: 'string' },
      seat: { type: 'string' },
    },
    children: {
      default_currency: { type: 'Currency', clone: false, save: false, delete: false },
    },
    links: {},
  };
}
