import { Type } from "@angular/core";
import { HttpUtil } from "../../modules/utils/classes/HttpUtil.class";

export class ObjectModel
{
    public db_id: number = 0;
    public id: number = 0;
    public get fullId(): string { return this.db_id+':'+this.id; };

    public currentClass: any = null;
    public instance: ObjectModel = null;

    constructor(currentClass: any) {
        this.currentClass = currentClass;
        this.instance = this;
    }

    // public assignFromObject(obj: any) {
    //     if (obj.id) this.id = +obj.id || 0;
    //     this.register(obj.id);
    //     return this;
    // }

    public static getInstances(currentClass: any)
    {
        if (!currentClass.instances) currentClass.instances = {};
        return currentClass.instances;
    }
    public static getLoadedIds(currentClass: any)
    {
        if (!currentClass.loaded_ids) currentClass.loaded_ids = [];
        return currentClass.loaded_ids;
    }

    public register(newId: number)
    {
        let instances = this.currentClass.instances;
        if (instances) {
            if (this.id != 0) {
                if (instances[this.id]) delete instances[this.id];
            }
            if (newId != 0) instances[newId] = this;
            console.log('setting newId for', this, 'to', newId);
        }
        this.id = newId;
    }
}
