import * as am4charts from '@amcharts/amcharts4/charts';

import { ApiService } from 'src/app/services/api/api.service';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { ObjectUtil } from '../../utils/classes/ObjectUtil.class';
import { ReportCell } from '../../reports/classes/ReportCell.class';
import { ReportHeaderRow } from '../../reports/classes/ReportHeaderRow.class';
import { ReportModel } from '../../reports/classes/ReportModel.class';
import { ReportRow } from '../../reports/classes/ReportRow.class';
import { ReportSetting } from '../../reports/classes/ReportSetting.class';
import { StatsModule } from '../stats.module';
import { config } from '../../../classes/config';

export class MonthlySalesSplitReportModel extends ReportModel {
  public title: string = 'REPARTITION DES VENTES';
  public settings: ReportSetting[] = [
    { name: 'year_start', title: 'Année de début', type: 'number', value: new Date().getFullYear() },
    { name: 'year_end', title: 'Année de fin', type: 'number', value: new Date().getFullYear() },
  ];

  public tableStyle: any = {
    border: '2px solid black',
  };

  public headerRows: ReportHeaderRow[] = [{ cells: [] }];
  public rows: ReportRow[] = [];
  public points: any[] = [];
  public tri_points: any[] = [];

  public static titleOrTotal_CellStyle: any = {
    'background-color': 'rgb(51,51,255)',
    color: 'white',
    'font-weight': 'bold',
    'border-top': '2px solid black',
    'border-bottom': '2px solid black',
    'text-align': 'center',
  };
  public static subTotal_CellStyle: any = {
    'background-color': 'rgb(0,112,192)',
    color: 'white',
    'font-weight': 'bold',
    'text-align': 'center',
  };
  public static body_CellStyle: any = {
    // 'background-color': 'rgb(217, 217, 217)',
    color: 'black',
  };
  public static bodyTotal_CellStyle: any = {
    'background-color': 'rgb(217, 217, 217)',
    color: 'black',
    'font-weight': 'bold',
  };
  public static month_CellStyle: any = {
    'background-color': 'rgb(141, 180, 226)',
    color: 'black',
    'font-weight': 'bold',
    'text-align': 'center',
  };

  public static generate(settings: ReportSetting[] = null) {
    let model: MonthlySalesSplitReportModel = new MonthlySalesSplitReportModel();
    if (settings) model.settings = settings;
    model.regenerate();
    return model;
  }

  public regenerate() {
    return LoadingPromise.create<any>((resolve, reject) => {
      let month_names: string[] = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Aout',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ];
      let tri_month_names: string[] = ['1er trimestre', '2ème trimestre', '3ème trimestre', '4ème trimestre'];

      this.headerRows = [
        {
          cells: [
            {
              value: 'REPARTITION DES VENTES : MENSUELLE ET TRIMESTRIELLE',
              colSpan: 11,
              style: MonthlySalesSplitReportModel.titleOrTotal_CellStyle,
            },
          ],
        },
      ];

      ApiService.callModule('reports', 'report', {
        query: 'monthly_sales',
        settings: { year_start: this.getSetting('year_start').value, year_end: this.getSetting('year_end').value },
      }).then(
        (result2: any) => {
          console.log('MONTHLY ORDERS RESULT:', result2);
          // (results: any[]) => {
          let amount_month: number = 0;
          let amount_year: number = 0;
          let amounts: number[] = [];
          let vat_month: number = 0;
          let vat_year: number = 0;
          let vats: number[] = [];
          let total_month: number = 0;
          let total_year: number = 0;
          let totals: number[] = [];
          let ifee_month: number = 0;
          let ifee_year: number = 0;
          let ifees: number[] = [];
          let efee_month: number = 0;
          let efee_year: number = 0;
          let efees: number[] = [];
          let buy_month: number = 0;
          let buy_year: number = 0;
          let buys: number[] = [];
          let cost_month: number = 0;
          let cost_year: number = 0;
          let costs: number[] = [];
          let raw_margin_month: number = 0;
          let raw_margin_year: number = 0;
          let raw_margins: number[] = [];
          let net_margin_month: number = 0;
          let net_margin_year: number = 0;
          let net_margins: number[] = [];
          let month_count: number = 0;
          for (let i = 0; i < result2.details.length; ++i) {
            let month_str: any = result2.details[i]['month'];
            let month: number = parseFloat(month_str);
            let amount_str: any = result2.details[i]['amount'];
            let amount: number = parseFloat(amount_str);
            let vat_str: any = result2.details[i]['vat'];
            let vat: number = parseFloat(vat_str);
            let total_str: any = result2.details[i]['total'];
            let total: number = parseFloat(total_str);
            let ifee_str: any = result2.details[i]['internal_fees'];
            let ifee: number = parseFloat(ifee_str);
            let efee_str: any = result2.details[i]['external_fees'];
            let efee: number = parseFloat(efee_str);
            let buy_str: any = result2.details[i]['buy_price'];
            let buy: number = parseFloat(buy_str);
            let cost_str: any = result2.details[i]['cost_price'];
            let cost: number = parseFloat(cost_str);
            let raw_margin_str: any = result2.details[i]['raw_margin'];
            let raw_margin: number = parseFloat(raw_margin_str);
            let net_margin_str: any = result2.details[i]['net_margin'];
            let net_margin: number = parseFloat(net_margin_str);
            if (month >= 1) {
              if (amount && amount != 0) {
                if (!amounts[month - 1]) amounts[month - 1] = 0;
                amounts[month - 1] += amount;
              }
              if (vat && vat != 0) {
                if (!vats[month - 1]) vats[month - 1] = 0;
                vats[month - 1] += vat;
              }
              if (total && total != 0) {
                if (!totals[month - 1]) totals[month - 1] = 0;
                totals[month - 1] += total;
              }
              if (ifee && ifee != 0) {
                if (!ifees[month - 1]) ifees[month - 1] = 0;
                ifees[month - 1] += ifee;
              }
              if (efee && efee != 0) {
                if (!efees[month - 1]) efees[month - 1] = 0;
                efees[month - 1] += efee;
              }
              if (buy && buy != 0) {
                if (!buys[month - 1]) buys[month - 1] = 0;
                buys[month - 1] += buy;
              }
              if (cost && cost != 0) {
                if (!costs[month - 1]) costs[month - 1] = 0;
                costs[month - 1] += cost;
              }
              if (raw_margin && raw_margin != 0) {
                if (!raw_margins[month - 1]) raw_margins[month - 1] = 0;
                raw_margins[month - 1] += raw_margin;
              }
              if (net_margin && net_margin != 0) {
                if (!net_margins[month - 1]) net_margins[month - 1] = 0;
                net_margins[month - 1] += net_margin;
              }
            }
          }

          this.rows = [
            {
              cells: [
                { value: this.getSetting('year_start').value, style: MonthlySalesSplitReportModel.subTotal_CellStyle },
                { value: 'H.T.V.A.', style: MonthlySalesSplitReportModel.month_CellStyle },
                { value: 'T.V.A.', style: MonthlySalesSplitReportModel.month_CellStyle },
                { value: 'T.V.A.C.', style: MonthlySalesSplitReportModel.month_CellStyle },
                { value: "Prix d'achat", style: MonthlySalesSplitReportModel.month_CellStyle },
                { value: 'Frais de gestion', style: MonthlySalesSplitReportModel.month_CellStyle },
                {
                  value: `Frais externes / ${config.companyName}`,
                  style: MonthlySalesSplitReportModel.month_CellStyle,
                },
                { value: 'Prix de revient', style: MonthlySalesSplitReportModel.month_CellStyle },
                { value: 'Marge brute (€)', style: MonthlySalesSplitReportModel.month_CellStyle },
                // { value: 'Marge brute (%)', style: MonthlySalesSplitReportModel.month_CellStyle },
                { value: 'Marge nette (€)', style: MonthlySalesSplitReportModel.month_CellStyle },
                // { value: 'Marge nette (%)', style: MonthlySalesSplitReportModel.month_CellStyle },
                { value: 'Taux de marque (%)', style: MonthlySalesSplitReportModel.month_CellStyle },
              ],
            },
          ];

          for (let i = 0; i < 12; ++i) {
            try {
              let amount: number = amounts[i] || 0;
              let vat: number = vats[i] || 0;
              let total: number = totals[i] || 0;
              let ifee: number = ifees[i] || 0;
              let efee: number = efees[i] || 0;
              let buy: number = buys[i] || 0;
              let cost: number = costs[i] || 0;
              let raw_margin: number = raw_margins[i] || 0;
              // let raw_perc: number = amount > 0 ? 100 * raw_margin / amount : 0;
              let net_margin: number = net_margins[i] || 0;
              // let net_perc: number = amount > 0 ? 100 * net_margin / amount : 0;
              let net_markup: number = amount ? 100 * (1 - cost / amount) : 0;
              this.rows.push({
                cells: [
                  {
                    value: [
                      'Janvier',
                      'Février',
                      'Mars',
                      'Avril',
                      'Mai',
                      'Juin',
                      'Juillet',
                      'Aout',
                      'Septembre',
                      'Octobre',
                      'Novembre',
                      'Décembre',
                    ][i],
                    style: MonthlySalesSplitReportModel.month_CellStyle,
                  },
                  {
                    value: amount && amount != 0 ? NumberUtil.formatMoney(amount, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MonthlySalesSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                  {
                    value: vat && vat != 0 ? NumberUtil.formatMoney(vat, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MonthlySalesSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                  {
                    value: total && total != 0 ? NumberUtil.formatMoney(total, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MonthlySalesSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                  {
                    value: buy && buy != 0 ? NumberUtil.formatMoney(buy, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MonthlySalesSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                  {
                    value: ifee && ifee != 0 ? NumberUtil.formatMoney(ifee, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MonthlySalesSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                  {
                    value: efee && efee != 0 ? NumberUtil.formatMoney(efee, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MonthlySalesSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                  {
                    value: cost && cost != 0 ? NumberUtil.formatMoney(cost, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MonthlySalesSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                  {
                    value: raw_margin && raw_margin != 0 ? NumberUtil.formatMoney(raw_margin, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MonthlySalesSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                  // { value: raw_perc && raw_perc != 0 ? NumberUtil.formatMoney(raw_perc, '%', 2, '.') : '', style: ObjectUtil.merge(MonthlySalesSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle) },
                  {
                    value: net_margin && net_margin != 0 ? NumberUtil.formatMoney(net_margin, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MonthlySalesSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                  // { value: net_perc && net_perc != 0 ? NumberUtil.formatMoney(net_perc, '%', 2, '.') : '', style: ObjectUtil.merge(MonthlySalesSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle) },
                  {
                    value: net_markup && net_markup != 0 ? NumberUtil.formatMoney(net_markup, '%', 2, '.') : '',
                    style: ObjectUtil.merge(MonthlySalesSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                ],
              });
              this.points.push({ name: month_names[i], month: i });
              this.points[i].amount = amount;
              this.points[i].vat = vat;
              this.points[i].total = total;
              this.points[i].buy = buy;
              this.points[i].ifees = ifee;
              this.points[i].efees = efee;
              this.points[i].cost = cost;
              this.points[i].raw = raw_margin;
              this.points[i].net = net_margin;
              this.points[i].markup = net_markup;

              if (amount != 0) ++month_count;
              amount_month += amount;
              vat_month += vat;
              total_month += total;
              ifee_month += ifee;
              efee_month += efee;
              buy_month += buy;
              cost_month += cost;
              raw_margin_month += raw_margin;
              net_margin_month += net_margin;
              amount_year += amount;
              vat_year += vat;
              total_year += total;
              ifee_year += ifee;
              efee_year += efee;
              buy_year += buy;
              cost_year += cost;
              raw_margin_year += raw_margin;
              net_margin_year += net_margin;
              if ((i + 1) % 3 == 0) {
                let j: number = Math.floor(i / 3);
                // let raw_perc_month: number = amount_month > 0 ? 100 * raw_margin_month / amount_month : 0;
                // let net_perc_month: number = amount_month > 0 ? 100 * net_margin_month / amount_month : 0;
                let net_markup_month: number = amount_month ? 100 * (1 - cost_month / amount_month) : 0;
                this.rows.push({
                  cells: [
                    {
                      value: ['1er trimestre', '2ème trimestre', '3ème trimestre', '4ème trimestre'][Math.floor(i / 3)],
                      style: MonthlySalesSplitReportModel.subTotal_CellStyle,
                    },
                    {
                      value: amount_month && amount_month != 0 ? NumberUtil.formatMoney(amount_month, '€', 2, '.') : '',
                      style: ObjectUtil.merge(
                        MonthlySalesSplitReportModel.subTotal_CellStyle,
                        ReportCell.moneyCellStyle
                      ),
                    },
                    {
                      value: vat_month && vat_month != 0 ? NumberUtil.formatMoney(vat_month, '€', 2, '.') : '',
                      style: ObjectUtil.merge(
                        MonthlySalesSplitReportModel.subTotal_CellStyle,
                        ReportCell.moneyCellStyle
                      ),
                    },
                    {
                      value: total_month && amount_month != 0 ? NumberUtil.formatMoney(total_month, '€', 2, '.') : '',
                      style: ObjectUtil.merge(
                        MonthlySalesSplitReportModel.subTotal_CellStyle,
                        ReportCell.moneyCellStyle
                      ),
                    },
                    {
                      value: buy_month && buy_month != 0 ? NumberUtil.formatMoney(buy_month, '€', 2, '.') : '',
                      style: ObjectUtil.merge(
                        MonthlySalesSplitReportModel.subTotal_CellStyle,
                        ReportCell.moneyCellStyle
                      ),
                    },
                    {
                      value: ifee_month && ifee_month != 0 ? NumberUtil.formatMoney(ifee_month, '€', 2, '.') : '',
                      style: ObjectUtil.merge(
                        MonthlySalesSplitReportModel.subTotal_CellStyle,
                        ReportCell.moneyCellStyle
                      ),
                    },
                    {
                      value: efee_month && efee_month != 0 ? NumberUtil.formatMoney(efee_month, '€', 2, '.') : '',
                      style: ObjectUtil.merge(
                        MonthlySalesSplitReportModel.subTotal_CellStyle,
                        ReportCell.moneyCellStyle
                      ),
                    },
                    {
                      value: cost_month && cost_month != 0 ? NumberUtil.formatMoney(cost_month, '€', 2, '.') : '',
                      style: ObjectUtil.merge(
                        MonthlySalesSplitReportModel.subTotal_CellStyle,
                        ReportCell.moneyCellStyle
                      ),
                    },
                    {
                      value:
                        raw_margin_month && raw_margin_month != 0
                          ? NumberUtil.formatMoney(raw_margin_month, '€', 2, '.')
                          : '',
                      style: ObjectUtil.merge(
                        MonthlySalesSplitReportModel.subTotal_CellStyle,
                        ReportCell.moneyCellStyle
                      ),
                    },
                    // { value: raw_perc_month && raw_perc_month != 0 ? NumberUtil.formatMoney(raw_perc_month, '%', 2, '.') : '', style: ObjectUtil.merge(MonthlySalesSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle) },
                    {
                      value:
                        net_margin_month && net_margin_month != 0
                          ? NumberUtil.formatMoney(net_margin_month, '€', 2, '.')
                          : '',
                      style: ObjectUtil.merge(
                        MonthlySalesSplitReportModel.subTotal_CellStyle,
                        ReportCell.moneyCellStyle
                      ),
                    },
                    // { value: net_perc_month && net_perc_month != 0 ? NumberUtil.formatMoney(net_perc_month, '%', 2, '.') : '', style: ObjectUtil.merge(MonthlySalesSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle) },
                    {
                      value:
                        net_markup_month && net_markup_month != 0
                          ? NumberUtil.formatMoney(net_markup_month, '%', 2, '.')
                          : '',
                      style: ObjectUtil.merge(
                        MonthlySalesSplitReportModel.subTotal_CellStyle,
                        ReportCell.moneyCellStyle
                      ),
                    },
                  ],
                });
                this.tri_points.push({ name: tri_month_names[j], month: j });
                this.tri_points[j].amount = amount_month;
                this.tri_points[j].vat = vat_month;
                this.tri_points[j].total = total_month;
                this.tri_points[j].buy = buy_month;
                this.tri_points[j].ifees = ifee_month;
                this.tri_points[j].efees = efee_month;
                this.tri_points[j].cost = cost_month;
                this.tri_points[j].raw = raw_margin_month;
                this.tri_points[j].net = net_margin_month;
                this.tri_points[j].markup = net_markup_month;
                amount_month = 0;
                vat_month = 0;
                total_month = 0;
                buy_month = 0;
                cost_month = 0;
                ifee_month = 0;
                efee_month = 0;
                raw_margin_month = 0;
                net_margin_month = 0;
              }
            } catch {
              continue;
            }
          }

          // let raw_perc_year: number = amount_year > 0 ? 100 * raw_margin_year / amount_year : 0;
          // let net_perc_year: number = amount_year > 0 ? 100 * net_margin_year / amount_year : 0;
          let net_markup_year: number = amount_year ? 100 * (1 - cost_year / amount_year) : 0;
          this.rows.push({
            cells: [
              { value: 'TOTAUX', style: MonthlySalesSplitReportModel.titleOrTotal_CellStyle },
              {
                value: amount_year && amount_year != 0 ? NumberUtil.formatMoney(amount_year, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlySalesSplitReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: vat_year && vat_year != 0 ? NumberUtil.formatMoney(vat_year, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlySalesSplitReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: total_year && total_year != 0 ? NumberUtil.formatMoney(total_year, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlySalesSplitReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: buy_year && buy_year != 0 ? NumberUtil.formatMoney(buy_year, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlySalesSplitReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: ifee_year && ifee_year != 0 ? NumberUtil.formatMoney(ifee_year, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlySalesSplitReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: efee_year && efee_year != 0 ? NumberUtil.formatMoney(efee_year, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlySalesSplitReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: cost_year && cost_year != 0 ? NumberUtil.formatMoney(cost_year, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlySalesSplitReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value:
                  raw_margin_year && raw_margin_year != 0 ? NumberUtil.formatMoney(raw_margin_year, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlySalesSplitReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              // { value: raw_perc_year && raw_perc_year != 0 ? NumberUtil.formatMoney(raw_perc_year, '%', 2, '.') : '', style: ObjectUtil.merge(MonthlySalesSplitReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle) },
              {
                value:
                  net_margin_year && net_margin_year != 0 ? NumberUtil.formatMoney(net_margin_year, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlySalesSplitReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              // { value: net_perc_year && net_perc_year != 0 ? NumberUtil.formatMoney(net_perc_year, '%', 2, '.') : '', style: ObjectUtil.merge(MonthlySalesSplitReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle) },
              {
                value:
                  net_markup_year && net_markup_year != 0 ? NumberUtil.formatMoney(net_markup_year, '%', 2, '.') : '',
                style: ObjectUtil.merge(MonthlySalesSplitReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
            ],
          });

          let amount_avg: number = month_count > 0 ? amount_year / month_count : 0;
          let vat_avg: number = month_count > 0 ? vat_year / month_count : 0;
          let total_avg: number = month_count > 0 ? total_year / month_count : 0;
          let buy_avg: number = month_count > 0 ? buy_year / month_count : 0;
          let ifee_avg: number = month_count > 0 ? ifee_year / month_count : 0;
          let efee_avg: number = month_count > 0 ? efee_year / month_count : 0;
          let cost_avg: number = month_count > 0 ? cost_year / month_count : 0;
          let raw_margin_avg: number = month_count > 0 ? raw_margin_year / month_count : 0;
          // let raw_perc_avg: number = amount_avg > 0 ? 100 * raw_margin_avg / amount_avg : 0;
          let net_margin_avg: number = month_count > 0 ? net_margin_year / month_count : 0;
          // let net_perc_avg: number = amount_avg > 0 ? 100 * net_margin_avg / amount_avg : 0;
          let net_markup_avg: number = amount_avg ? 100 * (1 - cost_avg / amount_avg) : 0;
          this.rows.push({
            cells: [
              { value: 'Moy. mens.', style: MonthlySalesSplitReportModel.subTotal_CellStyle },
              {
                value: amount_avg && amount_avg != 0 ? NumberUtil.formatMoney(amount_avg, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlySalesSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: vat_avg && vat_avg != 0 ? NumberUtil.formatMoney(vat_avg, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlySalesSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: total_avg && total_avg != 0 ? NumberUtil.formatMoney(total_avg, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlySalesSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: buy_avg && buy_avg != 0 ? NumberUtil.formatMoney(buy_avg, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlySalesSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: ifee_avg && ifee_avg != 0 ? NumberUtil.formatMoney(ifee_avg, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlySalesSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: efee_avg && efee_avg != 0 ? NumberUtil.formatMoney(efee_avg, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlySalesSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: cost_avg && cost_avg != 0 ? NumberUtil.formatMoney(cost_avg, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlySalesSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: raw_margin_avg && raw_margin_avg != 0 ? NumberUtil.formatMoney(raw_margin_avg, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlySalesSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              // { value: raw_perc_avg && raw_perc_avg != 0 ? NumberUtil.formatMoney(raw_perc_avg, '%', 2, '.') : '', style: ObjectUtil.merge(MonthlySalesSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle) },
              {
                value: net_margin_avg && net_margin_avg != 0 ? NumberUtil.formatMoney(net_margin_avg, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlySalesSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              // { value: net_perc_avg && net_perc_avg != 0 ? NumberUtil.formatMoney(net_perc_avg, '%', 2, '.') : '', style: ObjectUtil.merge(MonthlySalesSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle) },
              {
                value: net_markup_avg && net_markup_avg != 0 ? NumberUtil.formatMoney(net_markup_avg, '%', 2, '.') : '',
                style: ObjectUtil.merge(MonthlySalesSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
            ],
          });

          this.createEmptyCells();

          this.charts = [];

          this.charts.push({
            type: am4charts.XYChart,
            inline: false,
            colors: StatsModule.chartsColor,
            titles: [{ text: 'Répartition mensuelle des ventes', fontSize: 25, marginBottom: 30 }],
            series: [
              {
                type: 'ColumnSeries',
                name: 'HTVA',
                dataFields: { valueY: 'amount', categoryX: 'name' },
                yAxis: 'numbers',
              },
              {
                type: 'ColumnSeries',
                name: 'TVA',
                dataFields: { valueY: 'vat', categoryX: 'name' },
                yAxis: 'numbers',
                stacked: true,
              },
              // { type: 'LineSeries', name: 'TVAC', dataFields: { valueY: 'total', categoryX: 'name' },
              //     bullets: [{ type: 'CircleBullet', stroke: '#fff', strokeWidth: 2 }], yAxis: 'numbers', hidden: true },
              {
                type: 'ColumnSeries',
                name: "Prix d'achat",
                dataFields: { valueY: 'buy', categoryX: 'name' },
                yAxis: 'numbers',
              },
              {
                type: 'ColumnSeries',
                name: 'Frais de gestion',
                dataFields: { valueY: 'ifees', categoryX: 'name' },
                yAxis: 'numbers',
              },
              {
                type: 'ColumnSeries',
                name: 'Frais ext. / GS',
                dataFields: { valueY: 'efees', categoryX: 'name' },
                yAxis: 'numbers',
                stacked: true,
              },
              {
                type: 'ColumnSeries',
                name: 'Prix de revient',
                dataFields: { valueY: 'cost', categoryX: 'name' },
                yAxis: 'numbers',
              },
              {
                type: 'ColumnSeries',
                name: 'Marge brute (€)',
                dataFields: { valueY: 'raw', categoryX: 'name' },
                yAxis: 'numbers',
              },
              {
                type: 'ColumnSeries',
                name: 'Marge nette (€)',
                dataFields: { valueY: 'net', categoryX: 'name' },
                yAxis: 'numbers',
              },
              {
                type: 'LineSeries',
                name: 'Taux de marque (%)',
                dataFields: { valueY: 'markup', categoryX: 'name' },
                bullets: [{ type: 'CircleBullet', stroke: '#fff', strokeWidth: 2 }],
                yAxis: 'percents',
              },
            ],
            // maskBullets: false,
            data: this.points,
            yAxes: [
              {
                type: 'ValueAxis',
                id: 'numbers',
                dataFields: { value: 'value', category: 'name' },
                title: { text: 'Montants' },
              },
              {
                type: 'ValueAxis',
                id: 'percents',
                dataFields: { value: 'value', category: 'name' },
                title: { text: 'Poucentage' },
                renderer: { opposite: true },
              },
            ],
            xAxes: [
              {
                type: 'CategoryAxis',
                dataFields: { value: 'value', category: 'name' },
                renderer: { cellStartLocation: 0.1, cellEndLocation: 0.9 },
              },
            ],
            legend: {
              numberFormatter: {
                numberFormat: '#.',
              },
            },
            container_width: 1200,
            container_height: 480,
            events: {
              beforedatavalidated: function (ev) {
                console.log('beforedatavalidated event:', ev);
                ev.target.data.sort((a, b) => {
                  return a.month - b.month;
                });
              },
            },
          });

          this.charts.push({
            type: am4charts.XYChart,
            inline: false,
            colors: StatsModule.chartsColor,
            titles: [{ text: 'Répartition trimestrielle des ventes', fontSize: 25, marginBottom: 30 }],
            series: [
              {
                type: 'ColumnSeries',
                name: 'HTVA',
                dataFields: { valueY: 'amount', categoryX: 'name' },
                yAxis: 'numbers',
              },
              {
                type: 'ColumnSeries',
                name: 'TVA',
                dataFields: { valueY: 'vat', categoryX: 'name' },
                yAxis: 'numbers',
                stacked: true,
              },
              // { type: 'LineSeries', name: 'TVAC', dataFields: { valueY: 'total', categoryX: 'name' },
              //     bullets: [{ type: 'CircleBullet', stroke: '#fff', strokeWidth: 2 }], yAxis: 'numbers', hidden: true },
              {
                type: 'ColumnSeries',
                name: "Prix d'achat",
                dataFields: { valueY: 'buy', categoryX: 'name' },
                yAxis: 'numbers',
              },
              {
                type: 'ColumnSeries',
                name: 'Frais de gestion',
                dataFields: { valueY: 'ifees', categoryX: 'name' },
                yAxis: 'numbers',
              },
              {
                type: 'ColumnSeries',
                name: 'Frais ext. / GS',
                dataFields: { valueY: 'efees', categoryX: 'name' },
                yAxis: 'numbers',
                stacked: true,
              },
              {
                type: 'ColumnSeries',
                name: 'Prix de revient',
                dataFields: { valueY: 'cost', categoryX: 'name' },
                yAxis: 'numbers',
              },
              {
                type: 'ColumnSeries',
                name: 'Marge brute (€)',
                dataFields: { valueY: 'raw', categoryX: 'name' },
                yAxis: 'numbers',
              },
              {
                type: 'ColumnSeries',
                name: 'Marge nette (€)',
                dataFields: { valueY: 'net', categoryX: 'name' },
                yAxis: 'numbers',
              },
              {
                type: 'LineSeries',
                name: 'Taux de marque (%)',
                dataFields: { valueY: 'markup', categoryX: 'name' },
                bullets: [{ type: 'CircleBullet', stroke: '#fff', strokeWidth: 2 }],
                yAxis: 'percents',
              },
            ],
            // maskBullets: false,
            data: this.tri_points,
            yAxes: [
              {
                type: 'ValueAxis',
                id: 'numbers',
                dataFields: { value: 'value', category: 'name' },
                title: { text: 'Montants' },
              },
              {
                type: 'ValueAxis',
                id: 'percents',
                dataFields: { value: 'value', category: 'name' },
                title: { text: 'Poucentage' },
                renderer: { opposite: true },
              },
            ],
            xAxes: [
              {
                type: 'CategoryAxis',
                dataFields: { value: 'value', category: 'name' },
                renderer: { cellStartLocation: 0.1, cellEndLocation: 0.9 },
              },
            ],
            legend: {
              numberFormatter: {
                numberFormat: '#.',
              },
            },
            container_width: 1200,
            container_height: 480,
            events: {
              beforedatavalidated: function (ev) {
                console.log('beforedatavalidated event:', ev);
                ev.target.data.sort((a, b) => {
                  return a.month - b.month;
                });
              },
            },
          });

          resolve(true);
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }
}
