import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleBase } from '../module-base.class';
import { FormsModule } from '../../../../node_modules/@angular/forms';
import { ComponentsModule } from '../../components/components.module';
import { LoginViewComponent } from './views/login-view/login-view.component';
import { ClassManager } from 'src/app/classes/objects/ClassManager.class';
import { User } from 'src/app/classes/credentials/User.class';
import { Permission } from 'src/app/classes/credentials/Permission.class';
import { Signature } from '../other-data/classes/Signature';
import { UserProfile } from 'src/app/classes/credentials/UserProfile.class';
import { CookieService } from 'ngx-cookie-service';
import { ViewsComponent } from 'src/app/components/views/views.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule
  ],
  declarations: [
      LoginViewComponent
  ],
  exports: [
      LoginViewComponent
  ],
  entryComponents: [
      LoginViewComponent
  ],
  providers: [
      CookieService
  ]
})
export class LoginModule extends ModuleBase
{
    ngOnInit()
    {
		ClassManager.registerClass('User', User);
		ClassManager.registerClass('UserProfile', UserProfile);
		ClassManager.registerClass('Permission', Permission);
		ClassManager.registerClass('Signature', Signature);
    }
}
