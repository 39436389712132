import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import * as $ from 'jquery';
import { Filter, FilterAction, FilterOperator, SortAction } from '../data-grid2/classes/IFilter';

@Component({
  selector: 'data-grid-filter',
  templateUrl: './data-grid-filter.component.html',
  styleUrls: ['./data-grid-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataGridFilterComponent implements OnInit {
  @Input() public filter: Filter = null;

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChange: EventEmitter<Filter> = new EventEmitter<Filter>();
  @Output() onSortActionClicked: EventEmitter<SortAction<any>> = new EventEmitter<SortAction<any>>();

  public availableValuesSearch = '';
  public get availableValues() {
    if (!this.filter) return [];
    const a = (this.filter.availableValues || []).map((value, index) => [
      this.filter.selectedValues.includes(value) ? 'checked' : '',
      value,
      this.filter.formattedValues[index] || '(Vides)',
    ]);
    if (!this.availableValuesSearch) return a;
    const s = this.availableValuesSearch.toLowerCase();
    return a.filter((value) => value[2].toLowerCase().indexOf(s) >= 0);
  }

  constructor(private ref: ChangeDetectorRef, private element: ElementRef) {
    (<any>window)._filter = this;
  }

  ngOnInit(): void {
    const $elem = $(this.element.nativeElement);
    console.log('elem left:', $elem.offset());
    if ($elem.offset().left - $elem.closest('.data-grid').offset().left < 0) $elem.addClass('right');
  }

  public toggleValue(value: any) {
    let selected = this.filter.selectedValues;
    if (selected.indexOf(value) < 0) selected.push(value); // = [ ...selected, value ];
    else selected = selected.filter((item) => item !== value);
    console.log('selected filter values:', selected);
    this.filter.selectedValues = selected;
    this.onChange.emit(this.filter);
  }

  public clearFilter() {
    this.filter.clear();
    this.onChange.emit(this.filter);
    this.closeFilter();
  }

  public closeFilter() {
    this.onClose.emit();
  }

  public sortActionClicked(action: SortAction<any>) {
    this.onSortActionClicked.emit(action);
    this.closeFilter();
  }

  public showCustomFilterWindow: boolean = false;
  public customFilterOperator1: FilterOperator<any> = null;
  public customFilterValue1: any = null;
  public customFilterOperand: number = 0;
  public customFilterOperator2: FilterOperator<any> = null;
  public customFilterValue2: any = null;

  public showCustomFilter(action: FilterAction<any>) {
    console.log('showCustomFilter:', action);
    if (!action) return;
    else {
      this.customFilterOperator1 = action.operator1;
      this.customFilterValue1 = action.value1;
      // this.customFilterOperand = isNaN(action.operand) ? 0 : action.operand;
      this.customFilterOperator2 = action.operator2;
      this.customFilterValue2 = action.value2;
    }
    this.showCustomFilterWindow = true;
  }

  public validateCustomFilter() {
    this.filter.operator1 = this.customFilterOperator1;
    this.filter.value1String = this.customFilterValue1;
    this.filter.operator2 = this.customFilterOperator2;
    this.filter.value2String = this.customFilterValue2;
    this.filter.operand = this.customFilterOperand;
    this.showCustomFilterWindow = false;
    console.log('custom filter:', this.filter);
    this.onChange.emit(this.filter);
    this.closeFilter();
  }
}
