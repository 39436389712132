import { Component, OnInit, ViewChild } from '@angular/core';

import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { ArrayUtil } from 'src/app/modules/utils/classes/ArrayUtil.class';
import { ArticleBase } from 'src/app/modules/articles/classes/ArticleBase.class';
import { ArticleInfoBlocks } from 'src/app/modules/articles/classes/ArticleInfoBlocks.class';
import { Customer } from 'src/app/modules/customers/classes/Customer.class';
import { DataGridComponent } from 'src/app/components/data-grid/data-grid.component';
import { DateTimeUtil } from 'src/app/modules/utils/classes/DateTimeUtil.class';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { Form } from 'src/app/classes/forms/Form.class';
import { FormValidator } from 'src/app/classes/forms/FormValidator.class';
import { InfoBlock } from 'src/app/components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from 'src/app/components/info-block/info-block.component';
import { InfoBlockField } from 'src/app/components/info-block/classes/InfoBlockField.class';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { Order } from 'src/app/modules/orders/classes/Order.class';
import { Stock } from '../../classes/Stock.class';
import { StockMove } from '../../classes/StockMove.class';
import { Supplier } from 'src/app/modules/suppliers/classes/Supplier.class';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';

@Component({
  selector: 'app-stock-view',
  templateUrl: './stock-view.component.html',
  styleUrls: ['./stock-view.component.css'],
})
export class StockViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_stocks_fichestock';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Fiche de stock',
    data: this,
    elements: [
      { type: 'separator' },
      {
        type: 'button',
        name: 'saveButton',
        text: 'Enregistrer',
        icon: 'save',
        click: function (view: StockViewComponent) {
          view.save().then((result) => {
            //view.updateView(view.demandCopy);
          });
        },
        options: { visible: true },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        name: 'reloadArticleButton',
        text: "Recharger<br/>l'article",
        icon: 'sync-alt',
        click: function (view: StockViewComponent) {
          view.reloadArticle();
        },
        visible: true,
        access: this.writeAccess,
      },
      { type: 'spacing' },
    ],
  };

  public stock: Stock = null;
  public stockCopy: Stock = null;

  public selectedOrder: Order = null;

  public ordersData: any = { items: [] };
  public customersData: any = { items: [] };
  public suppliersData: any = { items: [] };
  public parentStockData: any = { items: [] };

  @ViewChild('selectBlockComponent') selectBlockComponent;
  public selectBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(0,0,0)',
    textColor: 'white',
    fields: [
      {
        title: 'Commande liée',
        field: 'selectedOrder',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.ordersData,
        listField: 'numberAndName',
        nullText: '(Aucun)',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.stockCopy);
        },
      },
    ],
  };
  // @ViewChild('parentBlockComponent') parentBlockComponent;
  // public parentBlock: InfoBlock = {
  //     title: '',
  //     backColor: "rgb(0,0,0)",
  //     textColor: "white",
  //     fields: [
  //         { title: 'Stock parent', field: 'parent', type: 'foreign-list',
  //           multiSelect: false, listItems: this.parentStockData, listField: 'fullDescription', nullText: '(Aucun)',
  //           change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
  //               this.updateView(this.stockCopy);
  //           } }
  //     ]
  // };

  @ViewChild('stockBlockComponent') stockBlockComponent;
  public stockBlock: InfoBlock = {
    title: 'Stock',
    backColor: 'rgb(54,96,146)',
    textColor: 'white',
    fields: [
      { title: 'Date de création', type: 'date', field: 'creation_date' },
      { title: 'Dernière mise à jour', type: 'date', field: 'update_date' },
      {
        title: 'Client',
        field: 'customer',
        type: 'foreign-list',
        allowBlankValues: false,
        multiSelect: false,
        listItems: this.customersData,
        listField: 'nameWithIdentifier',
        nullText: '(Aucun)',
      },
      { title: 'Référence article client', field: 'article_reference' },
      {
        title: 'Fournisseur',
        field: 'supplier',
        type: 'foreign-list',
        allowBlankValues: false,
        multiSelect: false,
        listItems: this.suppliersData,
        listField: 'name',
        nullText: '(Aucun)',
      },
      { title: 'Quantité de départ', field: 'quantity', type: 'number', textAlign: 'left' },
      { title: 'Quantité restante', field: 'availableStock', type: 'number', textAlign: 'left', readonly: true },
      { title: 'Unité', field: 'unit', type: 'text' },
      {
        title: "Prix d'achat unité",
        field: 'converted_buy_price',
        type: 'number',
        textAlign: 'left',
        decimalsCount: 5,
        currencyField: 'buy_currency',
      },
      {
        title: 'Prix de vente unité',
        field: 'converted_sell_price',
        type: 'number',
        textAlign: 'left',
        decimalsCount: 5,
        currencyField: 'sell_currency',
      },

      {
        title: 'Clients autorisés',
        field: 'allowed_customers',
        type: 'foreign-list',
        multiSelect: true,
        listItems: this.customersData,
        listField: 'nameWithIdentifier',
        nullText: '(Aucun)',
        comment: 'Cochez ici le(s) client(s) supplémentaires autorisés à commander sur ce stock',
      },
    ],
  };

  @ViewChild('remarksBlockComponent') remarksBlockComponent;
  public remarksBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(128,128,128)',
    textColor: 'white',
    fields: [{ title: 'Remarques', field: 'remarks', type: 'text' }],
  };

  public articleBlock: InfoBlock = ArticleInfoBlocks.generateReadOnlyArticleBlock();
  public deviceBlock: InfoBlock = ArticleInfoBlocks.generateReadOnlyDeviceBlock();
  public serviceBlock: InfoBlock = ArticleInfoBlocks.generateReadOnlyServiceBlock();

  @ViewChild('movesGrid') movesGrid: DataGridComponent;
  public movesGridColumns = [
    { title: 'Date', field: 'date', type: 'date' },
    { title: 'Quantité', field: 'quantityAndUnit' },
    { title: "N° d'appel", field: 'fullNumber' },
    { title: 'N° de facture', field: 'invoice.number' },
    { title: 'N° de commande client', field: 'customer_reference' },
    { title: 'Remarques\n(suivi des appels)', field: 'followup_remarks' },
    { title: 'Remarques\n(suivi client)', field: 'cfollowup_remarks' },
  ];
  @ViewChild('adjustmentsGrid') adjustmentsGrid: DataGridComponent;
  public adjustmentsGridColumns = [
    { title: 'Date', field: 'date', type: 'date', width: 120 },
    { title: 'Quantité', field: 'adjustmentWithUnit', width: 200 },
    { title: 'Remarques', field: 'remarks', width: 550 },
  ];

  ngOnInit() {
    Order.load(null, ['number']).then(
      (result) => {
        this.ordersData.items = result;
      },
      (err) => {
        console.error(err);
      }
    );
    Supplier.load(null, ['name']).then(
      (result) => {
        this.suppliersData.items = result;
      },
      (err) => {
        console.log(err);
      }
    );
    Customer.load(null, ['name']).then(
      (result) => {
        this.customersData.items = result;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  initView(stock: Stock) {
    if (!stock) stock = new Stock();
    this.stock = stock;
    console.log('stock moves:', this.stock.moves);
    this.stockCopy = stock.clone(true);
    this.selectedOrder = this.stockCopy.order;
    this.updateView(this.stockCopy);
  }

  updateView(stock: Stock) {
    if (this.selectedOrder instanceof Order && stock.id == 0) {
      stock.order = this.selectedOrder;
      stock.customer = this.selectedOrder.customer;
      stock.article = this.selectedOrder.article;
      stock.article_reference = this.selectedOrder.article_reference;
      if (!stock.creation_date) {
        stock.creation_date = DateTimeUtil.toDateString(new Date());
        stock.update_date = stock.creation_date;
      }
    }
  }

  reloadArticle(notify: boolean = true) {
    return ArticleBase.load(
      null,
      null,
      null,
      null,
      'old_id=' + this.stockCopy.article.id + ' AND db_old_id=' + this.stockCopy.article.db_id
    ).then(
      (result: ArticleBase[]) => {
        if (Array.isArray(result) && result.length > 0) {
          this.stockCopy.article = result[0];
          this.reloadArticle(false).then(
            (result) => {
              if (notify)
                NotificationsComponent.push({
                  type: 'success',
                  title: 'Article mis à jour',
                  summary: "L'article a été rechargé avec succès.",
                });
            },
            (err) => {
              if (notify)
                NotificationsComponent.push({
                  type: 'error',
                  title: 'Echec du chargement',
                  summary: "L'article n'a pas pu être rechargé.",
                });
              console.error(err);
            }
          );
        } else {
          if (notify)
            NotificationsComponent.push({
              type: 'warning',
              title: 'Echec du chargement',
              summary: "L'article n'a pas pu être rechargé. Il s'agit peut-être de la dernière version ?",
            });
        }
      },
      (err) => {
        if (notify)
          NotificationsComponent.push({
            type: 'error',
            title: 'Echec du chargement',
            summary: "L'article n'a pas pu être rechargé.",
          });
        console.error(err);
      }
    );
  }

  validateForm() {
    let form: Form = {
      fields: [
        {
          name: 'Commande',
          type: 'foreign-list',
          data: this.stockCopy,
          field: 'order',
          pattern: null,
          element: this.selectBlockComponent.getElement('selectedOrder'),
        },
        {
          name: 'Date',
          type: 'string',
          data: this.stockCopy,
          field: 'creation_date',
          pattern: /[0-9]{4}-[0-9]{2}-[0-9]{2}/,
          element: this.stockBlockComponent.getElement('creation_date'),
        },
        {
          name: 'Client',
          type: 'foreign-list',
          data: this.stockCopy,
          field: 'customer',
          pattern: null,
          element: this.stockBlockComponent.getElement('customer'),
        },
        {
          name: 'Fournisseur',
          type: 'foreign-list',
          data: this.stockCopy,
          field: 'supplier',
          pattern: null,
          element: this.stockBlockComponent.getElement('supplier'),
        },
        {
          name: 'Quantité',
          type: 'string',
          data: this.stockCopy,
          field: 'quantity',
          pattern: /.*/,
          element: this.stockBlockComponent.getElement('quantity'),
        },
        {
          name: 'Unité',
          type: 'string',
          data: this.stockCopy,
          field: 'unit',
          pattern: /.*/,
          element: this.stockBlockComponent.getElement('unit'),
        },
      ],
    };
    let result = FormValidator.validateForm(form);
    FormValidator.showFormInvalidFields(form, result);
    if (result !== true) {
      FormValidator.showNotification(result);
      return false;
    } else return true;
  }

  save() {
    let canSave: boolean = true;
    let createStock: boolean = false;
    return new Promise<any>((resolve, reject) => {
      if (this.validateForm() !== true) reject('invalid form');
      else {
        let stock_date: string = this.stockCopy.creation_date;
        let today_date: string = DateTimeUtil.toDateString(new Date());
        let promises: any[] = [];
        if (stock_date != today_date) {
          promises.push(
            DialogsComponent.display({
              icon: 'question',
              title: 'Date de création',
              message:
                'Vous avez spécifié la date suivante pour la création du stock : <b>' +
                stock_date +
                '</b><br/>' +
                'Voulez-vous changer pour la date du jour <b>' +
                today_date +
                '</b> ?',
              buttons: [
                { text: 'Oui, changer la date', result: DialogButton.RESULT_YES },
                { text: 'Non, merci !', result: DialogButton.RESULT_NO },
                DialogButton.cancelButton,
              ],
            }).then((result) => {
              if (result == DialogButton.RESULT_YES) this.stockCopy.creation_date = today_date;
              if (result == DialogButton.RESULT_CANCEL) canSave = false;
            })
          );
        }
        Promise.all(promises).then(
          (result) => {
            if (!canSave) {
              NotificationsComponent.push({
                type: 'error',
                title: 'Sauvegarde annulée',
                summary: "L'enregistrement a été annulé par l'utilisateur",
              });
              reject('cancelled by user');
            } else
              this.save2().then(
                (result) => {
                  resolve(result);
                },
                (err) => {
                  console.error(err);
                  reject(err);
                }
              );
          },
          (err) => {
            NotificationsComponent.push({
              type: 'error',
              title: 'Sauvegarde annulée',
              summary: "Une erreur s'est produite lors de la pré-sauvegarde",
              content: err,
            });
            console.error(err);
            reject(err);
          }
        );
      }
    });
  }

  save2() {
    return new Promise<any>((resolve, reject) => {
      this.stockCopy.save2().then(
        (result) => {
          this.stockCopy.clone(true, this.stock);
          this.initView(this.stock);
          resolve(result);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public get isArticle() {
    return this.stockCopy && this.stockCopy.article && this.stockCopy.article.type === 0;
  }
  public get isDevice() {
    return this.stockCopy && this.stockCopy.article && this.stockCopy.article.type === 1;
  }
  public get isService() {
    return this.stockCopy && this.stockCopy.article && this.stockCopy.article.type === 2;
  }
}
