import { DateTimeUtil } from '../../utils/classes/DateTimeUtil.class';
import { InfoBlock } from '../../../components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from '../../../components/info-block/info-block.component';
import { InfoBlockField } from '../../../components/info-block/classes/InfoBlockField.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { Order } from './Order.class';
import { Page } from '../../printing/classes/Page.class';
import { PrintingModel } from '../../printing/classes/PrintingModel.class';
import { PrintingUtil } from '../../utils/classes/PrintingUtil.class';
import { config } from '../../../classes/config';

export class OrderPrintingModel extends PrintingModel {
  public deliveryAddressField: InfoBlockField = {
    title: 'Adresse de livraison',
    field: 'delivery_address',
    type: 'address-text',
    listItems: { items: [] },
    textChange: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
      this.regenerate();
    },
    addressChange: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
      this.regenerate();
    },
  };
  public optionsBlock: InfoBlock = {
    fields: [
      {
        title: 'Langue',
        field: 'lang',
        type: 'select',
        selectOptions: [
          { text: '(Selon fournisseur)', value: '' },
          { text: 'Français', value: 'fr' },
          { text: 'Anglais', value: 'en' },
          { text: 'Néerlandais', value: 'nl' },
        ],
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
          this.regenerate();
        },
      },
      this.deliveryAddressField,
    ],
  };

  public order: Order = null;
  public lang: string = '';
  public delivery_address: string = null;
  // public delivery_address: string = null;
  // public billing_address: string = null;
  // public send_address: string = null;
  //public hide_customer: boolean = false;

  public static generate(order: Order): PrintingModel {
    let model: OrderPrintingModel = new OrderPrintingModel();
    model.order = order;
    model.delivery_address = order.delivery_address_text;
    model.deliveryAddressField.listItems.items = order.customer.deliveryAddressesList;
    return model.regenerate();
  }

  public regenerate(): PrintingModel {
    let order: Order = this.order;

    let pageWidth = 210;
    let pageHeight = 295;

    let pageLeftMargin = 10;
    let pageRightMargin = 10;
    let pageTopMargin = 10;
    let pageBottomMargin = 10;

    let quantities: string[] = [];
    let prices: string[] = [];
    let totals: string[] = [];

    // if (!this.delivery_address && order.delivery_address) this.delivery_address = order.delivery_address;
    // if (!this.billing_address && order.billing_address) this.billing_address = order.billing_address;
    // if (!this.send_address && order.send_address) this.send_address = order.send_address;

    this.pages = [];

    let lang: string = this.lang;
    if (!lang || lang == '') {
      if (this.order.supplier.lang) lang = this.order.supplier.lang.code;
      else lang = 'fr';
    }

    this.saveFilename = 'Bon de commande n°' + this.order.number.replace(/\//g, ' ') + '.pdf';
    this.mailFilename =
      {
        fr: 'Bon de commande n° ',
        nl: 'Bestelbon nr ',
        en: 'Purchase order no ',
      }[lang] +
      this.order.shortNumber.replace(/\//g, ' ') +
      '.pdf';

    // for(let i=0; i<this.order.suppliers.length; ++i)
    // {

    let page: Page = {
      width: pageWidth,
      height: pageHeight,

      leftMargin: pageLeftMargin,
      rightMargin: pageRightMargin,
      topMargin: pageTopMargin,
      bottomMargin: pageBottomMargin,

      style: {
        'font-family': 'Calibri',
        'font-size': '10pt',
        width: pageWidth - pageLeftMargin - pageRightMargin + 'mm',
        height: pageHeight - pageTopMargin - pageBottomMargin + 'mm',
        'margin-top': pageTopMargin + 'mm',
        'margin-left': pageLeftMargin + 'mm',
        'margin-right': pageRightMargin + 'mm',
        'margin-bottom': pageBottomMargin + 'mm',
        'line-height': '4mm',
      },

      headerSize: 33,
      headerElements: [
        {
          style: {
            position: 'absolute',
            right: '0mm',
            top: '1mm',
            width: '85mm',
            height: '17mm',
            border: '.5mm solid rgba(0, 176, 240, 1.0)',
            'border-radius': '2.5mm',
            'line-height': '4mm',
          },
          children: [
            {
              style: {
                'font-size': '20pt',
                'font-weight': 'bold',
                'text-align': 'center',
                width: '100%',
                'line-height': '5mm',
                'margin-top': '1.5mm',
                'margin-bottom': '1mm',
                'vertical-align': 'top',
              },
              html: {
                fr: order.isStock ? 'CONTRAT STOCK' : 'BON DE COMMANDE',
                en: order.isStock ? 'STOCK CONTRACT' : 'PURCHASE ORDER',
                nl: order.isStock ? 'BESTELBON' : 'BESTELBON',
              }[lang],
            },
            {
              style: {
                display: 'flex',
                'flex-direction': 'row',
              },
              children: [
                {
                  style: {
                    width: '20%',
                    'text-align': 'right',
                    padding: '0 1mm',
                  },
                  html: {
                    fr: 'N° :',
                    en: 'No :',
                    nl: 'Nr :',
                  }[lang],
                },
                {
                  style: {
                    width: '80%',
                    padding: '0 1mm',
                    'font-weight': 'bold',
                  },
                  html: order.number,
                },
              ],
            },
            {
              style: {
                display: 'flex',
                'flex-direction': 'row',
              },
              children: [
                {
                  style: {
                    width: '20%',
                    'text-align': 'right',
                    padding: '0 1mm',
                  },
                  html: {
                    fr: 'Date :',
                    en: 'Date :',
                    nl: 'Datum :',
                  }[lang],
                },
                {
                  style: {
                    width: '30%',
                    padding: '0 1mm',
                  },
                  html: DateTimeUtil.format(new Date(order.date), 'd/m/Y'),
                },
                {
                  style: {
                    width: '25%',
                    'text-align': 'right',
                    padding: '0 1mm',
                  },
                  html: {
                    fr: 'N° fourn. :',
                    en: 'Suppl. No :',
                    nl: 'Lever. Nr :',
                  }[lang],
                },
                {
                  style: {
                    width: '25%',
                    padding: '0 1mm',
                  },
                  html: order.supplier.number,
                },
              ],
            },
          ],
        },
        {
          style: {
            position: 'absolute',
            left: '0mm',
            top: '0mm',
            width: '50mm',
            height: '30mm',
            'background-image': 'url(assets/img/logo_documents.png)',
            'background-position': 'center center',
            'background-size': 'contain',
            'background-repeat': 'no-repeat',
          },
          html: '',
        },
      ],

      bodyElements: [
        {
          style: {
            'text-decoration': 'underline',
            'white-space': 'pre',
          },
          html: {
            fr: 'Cette commande comprend :',
            nl: 'Deze bestelling omvat :',
            en: 'This command includes :',
          }[lang],
        },
        {
          style: {
            'white-space': 'pre',
          },
          html: {
            fr: `- Ce bon de commande\n- Le bordereau de livraison\n- Les étiquettes d'expédition\n- Les conditions d'achats de ${config.companyName}`,
            nl: `- Deze bestelbon\n- De leveringsbon\n- De verzendingslabels\n- De algemene verkoopsvoorwaarden van ${config.companyName}`,
            en: `- This purchase order\n- The delivery note\n- Shipping labels\n- Our terms and conditions`,
          }[lang],
        },
        {
          style: {
            'font-weight': 'bold',
            'white-space': 'pre',
            'margin-top': '3mm',
          },
          children: [
            {
              style: {
                'text-decoration': 'underline',
                display: 'inline-block',
                width: '100%',
              },
              html: {
                fr: 'Votre offre de prix : #',
                nl: 'Uw prijsofferte : #',
                en: 'Your price offer : #',
              }[lang].replace('#', this.order.supplier_reference ? this.order.supplier_reference : ''),
            },
            // {
            //     style: {
            //         'display': 'inline-block'
            //     },
            //     html: order.supplier_reference ? (' ' + order.supplier_reference) : ''
            // }
          ],
        },
        {
          style: {
            'white-space': 'pre-wrap',
            'font-weight': 'bold',
            // 'line-height': '5mm',
            'margin-bottom': '3mm',
          },
          html: {
            fr: 'À la suite de votre offre de prix, pour laquelle nous vous remercions, nous avons le plaisir de vous passer commande pour la fourniture suivante :',
            en: 'Following your quotation hereafter our order :',
            nl: 'Vervolgens uw prijsofferte, voor degene wij U bedanken, hebben wij het plezier van U een bestelling te plaatsen voor de volgende goederen :',
          }[lang],
        },

        {
          style: {
            position: 'absolute',
            right: '0mm',
            top: '0mm',
            width: '85mm',
            height: '25m',
            'white-space': 'pre-wrap',
          },
          children: PrintingUtil.formatAddress(
            this.order.supplier.name,
            this.order.supplier.commercial_address.contactName,
            this.order.supplier.commercial_address.getPostalAddress()
          ),
        },

        {
          style: {
            // 'display': 'flex',
            width: '100%',
            // 'flex-direction': 'row'
          },
          children: [
            {
              style: {
                display: 'table',
                width: '100%',
                'border-collapse': 'collapse',
                position: 'relative',
                // 'flex': '1'
              },
              children: [
                this.order.article.field9_img //rolling picture
                  ? {
                      style: {
                        display: 'block',
                        width: '10mm',
                        height: '10mm',
                        position: 'absolute',
                        right: '0.5mm',
                        bottom: '0.5mm',
                      },
                      image: config.baseUrl + 'assets/img/rolling-directions/' + this.order.article.field9_img + '.png',
                      imageStyle: {
                        width: '10mm',
                        height: '10mm',
                      },
                    }
                  : {},
                {
                  style: {
                    display: 'table-row',
                  },
                  children: [
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        'background-color': 'rgba(217,217,217,1)',
                        width: '50mm',
                        padding: '.5mm 1mm',
                        'text-align': 'right',
                        'font-weight': 'bold',
                      },
                      html: {
                        fr: 'Désignation',
                        en: 'Specifications',
                        nl: 'Specificaties',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        'background-color': 'rgba(217,217,217,1)',
                        padding: '.5mm 1mm',
                        'font-weight': 'bold',
                      },
                      html: order.sale.article.designation,
                    },
                  ],
                },
              ].concat(this.getArticleLines(order, lang, this.order.article.field9_img ? '11mm' : undefined)),
            },

            {
              style: {
                width: '100%',
                display: 'flex',
                'flex-direction': 'row',
                'margin-top': '3mm',
                'align-items': 'flex-start',
              },
              children: [
                {
                  style: {
                    display: 'table',
                    // 'width': '100%',
                    'border-collapse': 'collapse',
                    position: 'relative',
                    'margin-right': '3mm',
                  },
                  children: [
                    {
                      style: {
                        display: 'table-row',
                      },
                      children: [
                        {
                          style: {
                            display: 'table-cell',
                            border: '.25mm solid rgba(0, 176, 240, 1.0)',
                            width: '50mm',
                            padding: '.5mm 1mm',
                            'text-align': 'right',
                            'white-space': 'pre',
                            'line-height': '4mm',
                          },
                          html: {
                            fr:
                              'Nombre de facturations\nNombre de livraisons' +
                              (order.storage > 0 ? '\nMois de stockage' : '') +
                              (order.productions > 0 ? '\nNombre de productions' : ''),
                            en:
                              'Number of invoicings\nNumber of deliveries' +
                              (order.storage > 0 ? '\nStorage months' : '') +
                              (order.productions > 0 ? '\nNumber of productions' : ''),
                            nl:
                              'Antaal facturen\nAntaal leveringen' +
                              (order.storage > 0 ? '\nAntaal manden opslag' : '') +
                              (order.productions > 0 ? '\nAantal producties' : ''),
                          }[lang],
                        },
                        {
                          style: {
                            display: 'table-cell',
                            border: '.25mm solid rgba(0, 176, 240, 1.0)',
                            padding: '.5mm 1mm',
                            'white-space': 'pre',
                            'line-height': '4mm',
                            width: '10mm',
                          },
                          html:
                            [order.invoicings, order.deliveries].join('\n') +
                            (order.storage > 0 ? '\n' + order.storage : '') +
                            (order.productions > 0 ? '\n' + order.productions : ''),
                        },
                      ],
                    },
                  ],
                },
                {
                  style: {
                    display: 'table',
                    // 'width': '100%',
                    flex: '1',
                    'border-collapse': 'collapse',
                    position: 'relative',
                  },
                  children: [
                    {
                      style: {
                        display: 'table-row',
                      },
                      children: [
                        {
                          style: {
                            display: 'table-cell',
                            border: '.25mm solid rgba(0, 176, 240, 1.0)',
                            'white-space': 'pre',
                            'line-height': '7mm',
                            'font-size': '12pt',
                            padding: '2mm',
                            'text-align': 'center',
                          },
                          children: [
                            {
                              style: {
                                display: 'block',
                                'text-decoration': 'underline',
                                color: 'rgba(255,0,0,1.0)',
                              },
                              html: {
                                fr: 'Références à rappeler impérativement sur les bons de livraison :',
                                en: 'References to be imperatively recalled on the delivery notes :',
                                nl: 'Verwijzingen naar dwingend te herinneren aan de leveringsbonnen :',
                              }[lang],
                            },
                            {
                              style: {
                                display: 'block',
                                'font-weight': 'bold',
                                'white-space': 'pre',
                                color: 'rgba(255,0,0,1.0)',
                                'font-size': '14pt',
                              },
                              html: order.customer_reference ? order.customer_reference : '',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },

            {
              style: {
                display: 'inline-table',
                width: 'calc(50% - 1.5mm)',
                'border-collapse': 'collapse',
                position: 'relative',
                'margin-top': '3mm',
                border: '.25mm solid rgba(0, 176, 240, 1.0)',
                'vertical-align': 'top',
              },
              children: [
                {
                  style: {
                    display: 'table-row',
                  },
                  children: [
                    {
                      style: {
                        display: 'table-cell',
                        'border-right': '.25mm solid rgba(0, 176, 240, 1.0)',
                        width: '50mm',
                        padding: '.5mm 1mm',
                        'text-align': 'right',
                        'white-space': 'pre-wrap',
                        'vertical-align': 'top',
                        // 'line-height': '5mm',
                      },
                      html: {
                        fr: 'Quantité et unité',
                        nl: 'Hoeveelheid en verkoopseenheid',
                        en: 'Quantity and unit',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'table-cell',
                        padding: '.5mm 1mm',
                        'white-space': 'pre-wrap',
                        'vertical-align': 'top',
                        // 'line-height': '5mm',
                      },
                      html: order.quantityAndUnit,
                    },
                  ],
                },
                {
                  style: {
                    display: 'table-row',
                  },
                  children: [
                    {
                      style: {
                        display: 'table-cell',
                        'border-right': '.25mm solid rgba(0, 176, 240, 1.0)',
                        width: '50mm',
                        padding: '.5mm 1mm',
                        'text-align': 'right',
                        'white-space': 'pre-wrap',
                        'vertical-align': 'top',
                        // 'line-height': '5mm',
                      },
                      html: {
                        fr: "Prix de vente à l'unité",
                        nl: 'Prijs per verkoopseenheid',
                        en: 'Purchase price per unit',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'table-cell',
                        padding: '.5mm 1mm',
                        'white-space': 'pre-wrap',
                        'vertical-align': 'top',
                        // 'line-height': '5mm',
                      },
                      html: NumberUtil.formatMoney(
                        order.converted_buy_price,
                        (order.buy_currency ? order.buy_currency.symbol : '€') +
                          { fr: ' H.T.V.A.', nl: ' excl. B.T.W.', en: ' excl. VAT' }[lang],
                        5,
                        '.'
                      ),
                    },
                  ],
                },
                {
                  style: {
                    display: 'table-row',
                  },
                  children: [
                    {
                      style: {
                        display: 'table-cell',
                        'border-right': '.25mm solid rgba(0, 176, 240, 1.0)',
                        width: '50mm',
                        padding: '.5mm 1mm',
                        'text-align': 'right',
                        'white-space': 'pre-wrap',
                        'vertical-align': 'top',
                        // 'line-height': '5mm',
                      },
                      html: {
                        fr: 'Frais supplémentaires',
                        nl: 'Aanvullende kosten',
                        en: 'Additionnal costs',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'table-cell',
                        padding: '.5mm 1mm',
                        'white-space': 'pre-wrap',
                        'vertical-align': 'top',
                        // 'line-height': '5mm',
                      },
                      html: NumberUtil.formatMoney(
                        order.converted_supplier_fees,
                        (order.buy_currency ? order.buy_currency.symbol : '€') +
                          { fr: ' H.T.V.A.', nl: ' excl. B.T.W.', en: ' excl. VAT' }[lang],
                        2,
                        '.'
                      ),
                    },
                  ],
                },
              ],
            },
            {
              style: {
                display: 'inline-table',
                width: 'calc(50% - 1.5mm)',
                'border-collapse': 'collapse',
                position: 'relative',
                'margin-top': '3mm',
                border: '.25mm solid rgba(0, 176, 240, 1.0)',
                'margin-left': '3mm',
                'vertical-align': 'top',
              },
              children: [
                {
                  style: {
                    display: 'table-row',
                  },
                  children: [
                    {
                      style: {
                        display: 'table-cell',
                        'border-right': '.25mm solid rgba(0, 176, 240, 1.0)',
                        // 'width': '50mm',
                        padding: '.5mm 1mm',
                        'text-align': 'right',
                        'white-space': 'pre-wrap',
                        'vertical-align': 'top',
                        // 'line-height': '5mm',
                      },
                      html: {
                        fr: 'Montant de la commande',
                        nl: 'Totaal bedrag',
                        en: 'Order amount',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'table-cell',
                        padding: '.5mm 1mm',
                        'white-space': 'pre-wrap',
                        'vertical-align': 'top',
                        // 'line-height': '5mm',
                      },
                      html: NumberUtil.formatMoney(
                        order.converted_total_buyPrice,
                        (order.buy_currency ? order.buy_currency.symbol : '€') +
                          { fr: ' H.T.V.A.', nl: ' excl. B.T.W.', en: ' excl. VAT' }[lang],
                        2,
                        '.'
                      ),
                    },
                  ],
                },
                {
                  style: {
                    display: 'table-row',
                  },
                  children: [
                    {
                      style: {
                        display: 'table-cell',
                        'border-right': '.25mm solid rgba(0, 176, 240, 1.0)',
                        // 'width': '50mm',
                        'vertical-align': 'top',
                        padding: '.5mm 1mm',
                        'text-align': 'right',
                        'white-space': 'pre-wrap',
                        // 'line-height': '5mm',
                      },
                      html: {
                        fr: 'Délai de livraison',
                        nl: 'Leveringstermijn',
                        en: 'Delivery time',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'table-cell',
                        padding: '.5mm 1mm',
                        'white-space': 'pre-wrap',
                        'vertical-align': 'top',
                        // 'line-height': '5mm',
                      },
                      html: (order.delivery_time || '').trim(),
                    },
                  ],
                },
                {
                  style: {
                    display: 'table-row',
                  },
                  children: [
                    {
                      style: {
                        display: 'table-cell',
                        'border-right': '.25mm solid rgba(0, 176, 240, 1.0)',
                        // 'width': '50mm',
                        'vertical-align': 'top',
                        padding: '.5mm 1mm',
                        'text-align': 'right',
                        'white-space': 'pre-wrap',
                        // 'line-height': '5mm',
                      },
                      html: {
                        fr: 'Conditions de paiement',
                        nl: 'Betalingsvoorwaarden',
                        en: 'Payement terms',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'table-cell',
                        padding: '.5mm 1mm',
                        'white-space': 'pre-wrap',
                        'vertical-align': 'top',
                        // 'line-height': '5mm',
                      },
                      html: (order.supplier.deadline ? order.supplier.deadline : '').trim(),
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          style: {
            clear: 'both',
          },
        },

        {
          style: {
            display: 'block',
            border: '.25mm solid rgba(0, 176, 240, 1.0)',
            'background-color': 'rgba(0, 176, 240, 1.0)',
            width: '100%',
            padding: '.5mm 1mm',
            'text-align': 'center',
            // 'line-height': '5mm',
            color: 'rgba(255,255,255,1)',
            'font-weight': 'bold',
            'margin-top': '3mm',
          },
          html: {
            fr: 'IMPÉRATIF',
            en: 'PURCHASE CONDITIONS',
            nl: 'ABSOLUUT NOODZAKELIJK',
          }[lang],
        },
        {
          style: {
            display: 'block',
            border: '.25mm solid rgba(0, 176, 240, 1.0)',
            width: '100%',
            padding: '.5mm 1mm',
            // 'line-height': '4mm',
            // 'white-space': 'pre-wrap',
            flex: '1',
          },
          children: []
            .concat([
              {
                style: {
                  display: 'block',
                  'font-weight': 'bold',
                },
                html: {
                  fr: 'Veuillez respecter la quantité exacte commandée et joindre 3 modèles avec votre facture.',
                  en: 'Exact quantity and include 3 models with your invoice.',
                  nl: 'Gelieve de juiste hoeveelheid van de bestelling bij te volgen en 3 produktiemodellen bij te voegen met de faktuur.',
                }[lang],
              },
              {
                style: {
                  display: 'block',
                  'font-weight': 'bold',
                  'background-color': 'rgba(255,255,0,1.0)',
                },
                html: {
                  fr: 'Confirmez, le plutôt possible, soit par mail, soit par fax , la date de départ usine.',
                  en: 'Could you please send us, by fax ou by mail, the delivery note copy on the shipping day.',
                  nl: 'Bevestig ons, zo vroeg mogelijk, per Mail, per Fax, de datum van vertrek fabriek van de goederen.',
                }[lang],
              },
              {
                style: {
                  display: 'block',
                },
                html: {
                  fr: "Toujours utiliser une note d'envoi neutre (ou celle fournie) avec notre adresse comme expéditeur.",
                  en: 'Always use blind shipment.',
                  nl: 'Altijd een neutraal verzendingsbon gebruiken ofwel degene geleverd met ons leveringsadres.',
                }[lang],
              },
              {
                style: {
                  display: 'block',
                },
                html: {
                  fr: 'Toujours reprendre la référence de cette commande dans votre facture. (Une facture par commande.)',
                  en: 'Reference to recall on your invoice. (One invoice per order.)',
                  nl: 'Altijd de referenties overnemen van deze bestelling bij uw faktuur. (Een factuur per order.)',
                }[lang],
              },
              order.use_stock
                ? {
                    style: {
                      display: 'block',
                      color: 'red',
                      'font-weight': 'bold',
                      'font-size': '12pt',
                      'line-height': '1cm',
                    },
                    html: {
                      fr: 'Pourriez-vous utiliser notre stock (##) pour cette commande.',
                      en: 'Could you please use our stock (##) for this order.',
                      nl: 'Pourriez-vous utiliser notre stock (##) pour cette commande.',
                    }[lang].replace('##', order.use_stock.number),
                  }
                : {},
            ])
            .concat(PrintingUtil.linesToDivs(order.remarks_order || '')),
        },

        {
          style: {
            'white-space': 'pre-wrap',
            'font-weight': 'bold',
            // 'line-height': '5mm',
            'margin-top': '3mm',
            'font-style': 'italic',
          },
          html: {
            fr: "En restant à votre disposition pour d'éventuelles questions, et en vous remerciant par avance de la bonne exécution de cette commande, nous vous prions d'agréer nos sincères salutations.",
            nl: 'Verblijvend tot uw dienst voor bijkomende vragen, en alvorens bedankt voor de juiste uitvoering van die bestelling, aanvaar onze meest opgerechte groeten.',
            en: 'We are at your disposal for any further questions. Thank you in advance for the correct execution of this order. Kind regards.',
          }[lang],
        },
      ],

      footerSize: 63,
      footerElements: [
        {
          style: {
            display: 'table',
            width: '100%',
            'border-collapse': 'collapse',
            'margin-top': '3mm',
          },
          children: [
            {
              style: {
                display: 'table-row',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    'background-color': 'rgba(217, 217, 217, 1.0)',
                    width: '76mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'font-weight': 'bold',
                  },
                  html: {
                    fr: 'ADRESSE DE LIVRAISON',
                    en: 'DELIVERY ADDRESS',
                    nl: 'LEVERINGS ADRES',
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    'background-color': 'rgba(217, 217, 217, 1.0)',
                    width: '76mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'font-weight': 'bold',
                  },
                  html: {
                    fr: 'ADRESSE DE FACTURATION',
                    en: 'BILLING ADDRESS',
                    nl: 'FACTURATIE ADRES',
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    'background-color': 'rgba(217, 217, 217, 1.0)',
                    width: '38mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'font-weight': 'bold',
                  },
                  html: {
                    fr: 'NOM ET SIGNATURE',
                    en: 'NAME AND SIGNATURE',
                    nl: 'HANDTEKENING',
                  }[lang],
                },
              ],
            },
            {
              style: {
                display: 'table-row',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    width: '76mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'white-space': 'pre-wrap',
                    'vertical-align': 'top',
                  },
                  children:
                    this.order.deliveries > 1
                      ? [
                          {
                            style: {
                              'white-space': 'pre-wrap',
                            },
                            html: {
                              fr: "Selon l'adresse qui sera sur les appels de stock.",
                              nl: "Selon l'adresse qui sera sur les appels de stock.",
                              en: "Selon l'adresse qui sera sur les appels de stock.",
                            }[lang],
                          },
                        ]
                      : this.order.self_delivery == 1
                      ? [
                          {
                            style: {
                              'white-space': 'pre-wrap',
                            },
                            html: {
                              fr: 'Enlèvement par nos soins',
                              nl: 'Enlèvement par nos soins',
                              en: 'Enlèvement par nos soins',
                            }[lang],
                          },
                        ]
                      : PrintingUtil.formatAddress(
                          this.order.customer.name,
                          this.order.delivery_contact,
                          this.order.delivery_address_text,
                          null,
                          this.order.openingHours
                        ),
                },
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    width: '76mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'white-space': 'pre-wrap',
                    'vertical-align': 'top',
                  },
                  children: PrintingUtil.formatAddress(
                    'G.S. srl',
                    null,
                    'Atrium du Vertbois\nRue du Vertbois 11 bte 20\nB-4000 Liège (BELGIUM)',
                    'BE 0829 982 973'
                  ),
                },
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    width: '38mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'white-space': 'pre',
                    position: 'relative',
                    'vertical-align': 'top',
                  },
                  children: [
                    {
                      style: {
                        'text-align': 'center',
                        'white-space': 'pre',
                        'margin-top': '15mm',
                      },
                      html: {
                        fr: 'Le service logistique',
                        en: 'The logistics service',
                        nl: 'De logistieke dienst',
                      }[lang],
                    },
                    {
                      style: {
                        'font-weight': 'bold',
                        'text-align': 'center',
                        'margin-bottom': '5mm',
                      },
                      html: 'Gillian Townley',
                    },
                    {
                      style: {
                        position: 'absolute',
                        left: '2mm',
                        top: '5mm',
                        width: '35mm',
                        height: '8.4mm',
                        'background-image': 'url(assets/img/signature_gill.png)',
                        'background-size': '100% 100%',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },

        PrintingUtil.getFooter(this.order.accounting, lang),
      ],
    };

    this.pages.push(page);

    //}
    return this;
  }

  public getArticleLines(order: Order, lang: string, paddingRight?: string) {
    let headers: any[] = PrintingUtil.getArticleHeaders(order.article, lang);
    let values: any[] = PrintingUtil.getArticleValues(order.article);
    let arr: any[] = [];
    for (let i = 0; i < headers.length; ++i) {
      arr.push({
        style: {
          display: 'table-row',
        },
        children: [
          {
            style: {
              display: 'table-cell',
              'border-left': '.25mm solid rgba(0, 176, 240, 1.0)',
              'border-right': '.25mm solid rgba(0, 176, 240, 1.0)',
              width: '50mm',
              padding: '.5mm 1mm',
              'text-align': 'right',
              'white-space': 'pre-wrap',
              'line-height': '4mm',
              'vertical-align': 'top',
              'padding-left': '1mm',
              'padding-top': '0',
              'padding-right': '1mm',
              'padding-bottom': '0',
            },
            html: headers[i],
          },
          {
            style: {
              display: 'table-cell',
              'border-right': '.25mm solid rgba(0, 176, 240, 1.0)',
              padding: '.5mm 1mm',
              'white-space': 'pre-wrap',
              'line-height': '4mm',
              'vertical-align': 'top',
              'padding-left': '1mm',
              'padding-top': '0',
              'padding-right': paddingRight || '1mm',
              'padding-bottom': '0',
            },
            html: values[i],
          },
        ],
      });
    }
    if (arr.length > 0) {
      arr[arr.length - 1].children[0].style['border-bottom'] = '.25mm solid rgba(0, 176, 240, 1.0)';
      arr[arr.length - 1].children[1].style['border-bottom'] = '.25mm solid rgba(0, 176, 240, 1.0)';
    }
    return arr;
  }
}
