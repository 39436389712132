import { Component, OnInit, ViewChild } from '@angular/core';

import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { ArrayUtil } from '../../../utils/classes/ArrayUtil.class';
import { CredentialsService } from '../../../../services/credentials/credentials.service';
import { Customer } from '../../classes/Customer.class';
import { CustomerViewComponent } from '../customer-view/customer-view.component';
import { DataGridComponent } from 'src/app/components/data-grid/data-grid.component';
import { DataGridRowAction } from 'src/app/components/data-grid/classes/DataGridRowAction.class';
import { DataListComponent } from '../../../../components/data-list/data-list.component';
import { DialogButton } from '../../../../components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from '../../../../components/dialogs/dialogs.component';
import { NotificationsComponent } from '../../../../components/notifications/notifications.component';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from '../../../../components/views/views.component';

@Component({
  selector: 'app-customers-list-view',
  templateUrl: './customers-list-view.component.html',
  styleUrls: ['./customers-list-view.component.css'],
})
export class CustomersListViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_basededonnees_liste_clients';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Liste des clients',
    data: this,
    elements: [
      { type: 'separator' },
      {
        type: 'button',
        text: 'Créer un<br/>client',
        icon: 'user-plus',
        click: function (view: CustomersListViewComponent) {
          view.createCustomerWithNewNumber();
        },
        access: this.writeAccess,
      },
      { type: 'separator-large' },
      {
        name: 'refreshList',
        type: 'button',
        text: 'Rafraîchir<br/>la liste',
        icon: 'sync-alt',
        click: function (view: CustomersListViewComponent) {
          view.refreshList();
        },
      },
      { type: 'separator-large' },
      {
        name: 'deleteCustomers',
        type: 'button',
        text: 'Supprimer<br/>le(s) client(s)',
        icon: 'user-minus',
        click: function (view: CustomersListViewComponent) {
          view.deleteCustomers();
        },
        disabled: true,
        access: this.writeAccess,
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Archiver',
        icon: 'archive',
        click: function (view: CustomersListViewComponent) {
          view.archiveCustomers();
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Désarchiver',
        icon: 'archive',
        click: function (view: CustomersListViewComponent) {
          view.archiveCustomers(false);
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'toggle-button',
        text: 'Afficher les<br/>éléments archivés',
        icon: 'filter',
        value: false,
        click: function (view: CustomersListViewComponent) {
          view.showArchived = this.value;
          view.refreshList();
          // if (view.filterOrders) view.grid.clearSelection();
        },
      },
      { type: 'spacing' },
    ],
  };

  @ViewChild('grid') grid: DataGridComponent;
  items: Customer[] = [];
  gridColumns = [
    { title: 'Nom', field: 'nameWithIdentifier', width: 300, fontSize: '14pt', fontWeight: 'bold' },
    { title: 'Numéro', field: 'number', width: 150, fontSize: '14pt', fontWeight: 'bold' },
    { title: 'Représentant', field: 'merchant.numberAndName', width: 180 },
    { title: 'Cat.', field: 'category.name', width: 65 },
    { title: 'Langue', field: 'lang.name', width: 90 },
    { title: 'Paiement', field: 'deadline', width: 150 },
    { title: 'N° TVA', field: 'vat_number', width: 120 },
    { title: 'Code\npostal', field: 'ordinator_address.postcode', width: 80 },
    { title: 'Localité', field: 'ordinator_address.city', width: 150 },
    { title: 'Pays', field: 'ordinator_address.country.name', width: 150 },
    { title: 'E-mail réclamations', field: 'billing_address.accounting_email', type: 'email', width: 250 },
    { title: 'E-mail facturation', field: 'billing_address.invoicing_email', type: 'email', width: 250 },
    { title: 'Remarques', field: 'remarks', width: 300 },
  ];
  listFields = [
    {
      style: {},
      fields: [
        {
          title: 'Badge',
          field: 'name',
          name: 'badge',
          function: (value, field, item) => {
            return CustomersListViewComponent.getInitials(value, item);
          },
          template: '##value##',
          rowspan: 2,
        },
      ],
    },
    {
      style: { 'min-width': '300px', 'margin-right': '30px' },
      fields: [
        { title: 'Nom', field: 'name', name: 'name' },
        { title: 'Numéro', field: 'number', name: 'number', template: 'Numéro : <b>##value##</b>' },
      ],
    },
    {
      style: { flex: '1', 'margin-right': '30px' },
      fields: [
        {
          title: 'E-mail réclamations',
          field: 'complaint_email',
          name: 'complaint_email',
          template: 'E-mail réclamations : <a href="mailto:##value##">##value##</a>',
        },
        {
          title: 'E-mail facturation',
          field: 'billing_email',
          name: 'billing_email',
          template: 'E-mail facturation : <a href="mailto:##value##">##value##</a>',
        },
      ],
    },
    {
      style: { 'min-width': '300px' },
      fields: [
        {
          title: 'Représentant',
          field: 'merchant',
          name: 'merchant',
          function: (value, field, item) => {
            return value ? value.name : null;
          },
          template: 'Représentant : ##value##',
        },
        {
          title: 'Remarques',
          field: 'remarks',
          name: 'remarks',
          template: 'Remarques : ##value##',
        },
      ],
    },
  ];
  public listActions: DataGridRowAction[] = [
    {
      name: 'Afficher',
      icon: 'search',
      click: (event: any, item: any) => {
        this.itemDblClick(event, item);
      },
    },
  ];

  public showArchived = false;

  @ViewChild('customerList') customerList: DataListComponent;

  ngOnInit() {
    this.refreshList();
  }

  refreshList() {
    let conditions: string[] = [];
    if (!CredentialsService.isUserAllowed('customers_list_show_not_valid')) conditions.push('valid = 1');
    if (!this.showArchived) conditions.push('archived != 1');
    Customer.load(null, ['~valid', 'name'], null, false, conditions.join(' AND ') || null, true).then(
      (customers: Customer[]) => {
        this.items = customers.sort((a: Customer, b: Customer) => {
          return a.valid == b.valid ? a.name.localeCompare(b.name) : a.valid ? 1 : -1;
        });
      },
      (err) => {
        console.error(err);
      }
    );
  }

  createCustomerWithNewNumber() {
    let self = this;
    Customer.getNextNumber().then(
      function (result) {
        console.log('result:', result);
        self.createCustomer('BEL' + result.details);
      },
      function (err) {
        console.error('get customer number error:', err);
        self.createCustomer();
      }
    );
  }
  createCustomer(number: string = null) {
    let customer = new Customer();
    customer.name = 'Nouveau Client';
    customer.number = number;
    this.items.push(customer);
    this.itemDblClick(null, customer);
  }

  updateSelectedCustomers() {
    Toolbar.getToolbarItem(this.toolbar, 'deleteCustomers').disabled = this.grid.selectedItems.length == 0;
  }

  deleteCustomers() {
    let self = this;
    DialogsComponent.display({
      icon: 'warning',
      title: 'Supprimer les clients ?',
      message: 'Êtes-vous sûr(e) de vouloir supprimer les clients sélectionnés ?',
      buttons: DialogButton.yesNoButtons,
    }).then((result: any) => {
      if (result === DialogButton.RESULT_YES) {
        for (let i = 0; i < this.grid.selectedItems.length; ++i) {
          if (this.grid.selectedItems[i].id != 0) {
            this.grid.selectedItems[i].delete().then(
              function (result) {
                NotificationsComponent.push({
                  type: 'success',
                  title: 'Clients supprimés',
                  summary: 'La sélection a été supprimée avec succès',
                });
                ArrayUtil.removeElements(self.items, [self.grid.selectedItems[i]]);
              },
              function (err) {
                console.error('error while deleting customer:', err);
              }
            );
          } else {
            ArrayUtil.removeElements(self.items, [self.grid.selectedItems[i]]);
          }
        }
      }
    });
  }

  itemClick(item: Customer) {
    this.updateSelectedCustomers();
  }

  itemDblClick(event, item) {
    this.updateSelectedCustomers();
    ViewsComponent.openView(CustomerViewComponent, item);
  }

  static getInitials(value, item) {
    let initials = value.match(/\b[A-Z0-9]/g) || [];
    let str = initials.join('').substring(0, 3);
    let html =
      '<div class="initials-badge" style="background-color: ' +
      (item.merchant ? item.merchant.color : 'darkgray') +
      ';"><div>' +
      str +
      '</div></div>';
    // console.log('html:', html);
    return html;
  }

  gridRowStyle(item: Customer, selectColumn: boolean = false) {
    if (!item) return null;
    return {
      'border-left': '5px solid ' + (item.merchant && item.merchant.color ? item.merchant.color : 'gray'),
      ...(item.archived
        ? {
            color: 'gray',
            'font-style': 'italic',
          }
        : {}),
    };
  }

  archiveCustomers(archive = true) {
    let customers: Customer[] = this.grid.selectedItems as Customer[];
    if (Array.isArray(customers) && customers.length > 0) {
      let promises: any[] = [];
      for (let i = 0; i < customers.length; ++i) {
        customers[i].archived = archive === false ? 0 : 1;
        promises.push(customers[i].save2());
      }
      Promise.all(promises).then(
        (result) => {
          NotificationsComponent.push({
            type: 'success',
            title: 'Clients archivés',
            summary: 'Les clients ont été ' + (archive === false ? 'dés' : '') + 'archivés avec succès',
          });
          this.grid.clearSelection();
        },
        (err) => {
          NotificationsComponent.push({
            type: 'error',
            title: "Erreur lors de l'archivage",
            summary:
              "Une erreur s'est produite lors " + (archive === false ? 'du dés' : "de l'") + 'archivage des clients',
            content: err,
          });
          console.error(err);
        }
      );
    }
  }
}
