import * as am4charts from '@amcharts/amcharts4/charts';

import { ApiService } from 'src/app/services/api/api.service';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { ObjectUtil } from '../../utils/classes/ObjectUtil.class';
import { ReportCell } from '../../reports/classes/ReportCell.class';
import { ReportHeaderRow } from '../../reports/classes/ReportHeaderRow.class';
import { ReportModel } from '../../reports/classes/ReportModel.class';
import { ReportRow } from '../../reports/classes/ReportRow.class';
import { ReportSetting } from '../../reports/classes/ReportSetting.class';
import { StatsModule } from '../stats.module';

export class MonthlyVatSplitReportModel extends ReportModel {
  public title: string = 'BALANCE TVA';
  public settings: ReportSetting[] = [
    { name: 'year_start', title: 'Année de début', type: 'number', value: new Date().getFullYear() },
    { name: 'year_end', title: 'Année de fin', type: 'number', value: new Date().getFullYear() },
  ];

  public tableStyle: any = {
    border: '2px solid black',
  };

  public headerRows: ReportHeaderRow[] = [{ cells: [] }];
  public rows: ReportRow[] = [];
  public points: any[] = [];
  public tri_points: any[] = [];

  public static titleOrTotal_CellStyle: any = {
    'background-color': 'rgb(118,147,60)',
    color: 'black',
    'font-weight': 'bold',
    'border-top': '2px solid black',
    'border-bottom': '2px solid black',
    'text-align': 'center',
  };
  public static subTotal_CellStyle: any = {
    'background-color': 'rgb(181,205,129)',
    color: 'black',
    'font-weight': 'bold',
    'text-align': 'center',
  };
  public static body_CellStyle: any = {
    // 'background-color': 'rgb(217, 217, 217)',
    color: 'black',
  };
  public static bodyTotal_CellStyle: any = {
    'background-color': 'rgb(217, 217, 217)',
    color: 'black',
    'font-weight': 'bold',
  };
  public static month_CellStyle: any = {
    'background-color': 'rgb(235, 241, 222)',
    color: 'black',
    'font-weight': 'bold',
    'text-align': 'center',
  };

  public static generate(settings: ReportSetting[] = null) {
    let model: MonthlyVatSplitReportModel = new MonthlyVatSplitReportModel();
    if (settings) model.settings = settings;
    model.regenerate();
    return model;
  }

  public regenerate() {
    return LoadingPromise.create<any>((resolve, reject) => {
      let month_names: string[] = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Aout',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ];
      let tri_month_names: string[] = ['1er trimestre', '2ème trimestre', '3ème trimestre', '4ème trimestre'];

      this.headerRows = [
        {
          cells: [
            {
              value: 'BALANCE TVA : MENSUELLE ET TRIMESTRIELLE (à payer)',
              colSpan: 4,
              style: MonthlyVatSplitReportModel.titleOrTotal_CellStyle,
            },
          ],
        },
      ];

      ApiService.callModule('reports', 'report', {
        query: 'monthly_vat',
        settings: { year_start: this.getSetting('year_start').value, year_end: this.getSetting('year_end').value },
      }).then(
        (result2: any) => {
          console.log('MONTHLY PURCHASES RESULT:', result2);
          // (results: any[]) => {
          let topay_month: number = 0;
          let topay_year: number = 0;
          let topays: number[] = [];
          let toget_month: number = 0;
          let toget_year: number = 0;
          let togets: number[] = [];
          let total_month: number = 0;
          let total_year: number = 0;
          let totals: number[] = [];
          let month_count: number = 0;
          for (let i = 0; i < result2.details.length; ++i) {
            let month_str: any = result2.details[i]['month'];
            let month: number = parseFloat(month_str);
            let topay_str: any = result2.details[i]['vat_to_pay'];
            let amount: number = parseFloat(topay_str);
            let toget_str: any = result2.details[i]['vat_to_get'];
            let vat: number = parseFloat(toget_str);
            let total_str: any = result2.details[i]['vat_total'];
            let total: number = parseFloat(total_str);
            if (month >= 1) {
              if (amount && amount != 0) {
                if (!topays[month - 1]) topays[month - 1] = 0;
                topays[month - 1] += amount;
              }
              if (vat && vat != 0) {
                if (!togets[month - 1]) togets[month - 1] = 0;
                togets[month - 1] += vat;
              }
              if (total && total != 0) {
                if (!totals[month - 1]) totals[month - 1] = 0;
                totals[month - 1] += total;
              }
            }
          }

          this.rows = [
            {
              cells: [
                { value: this.getSetting('year_start').value, style: MonthlyVatSplitReportModel.subTotal_CellStyle },
                { value: 'T.V.A. à payer', style: MonthlyVatSplitReportModel.subTotal_CellStyle },
                { value: 'T.V.A. à récupérer', style: MonthlyVatSplitReportModel.subTotal_CellStyle },
                { value: 'Balance T.V.A. (à payer)', style: MonthlyVatSplitReportModel.subTotal_CellStyle },
              ],
            },
          ];

          for (let i = 0; i < 12; ++i) {
            try {
              let topay: number = topays[i] || 0;
              let toget: number = togets[i] || 0;
              let total: number = totals[i] || 0;
              this.rows.push({
                cells: [
                  {
                    value: [
                      'Janvier',
                      'Février',
                      'Mars',
                      'Avril',
                      'Mai',
                      'Juin',
                      'Juillet',
                      'Aout',
                      'Septembre',
                      'Octobre',
                      'Novembre',
                      'Décembre',
                    ][i],
                    style: MonthlyVatSplitReportModel.month_CellStyle,
                  },
                  {
                    value: topay && topay != 0 ? NumberUtil.formatMoney(topay, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MonthlyVatSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                  {
                    value: toget && toget != 0 ? NumberUtil.formatMoney(toget, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MonthlyVatSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                  {
                    value: total && total != 0 ? NumberUtil.formatMoney(total, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MonthlyVatSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                ],
              });
              this.points.push({ name: month_names[i], month: i });
              this.points[i].topay = topay;
              this.points[i].toget = -toget;
              this.points[i].total = topay - toget;

              if (topay != 0) ++month_count;
              topay_month += topay;
              toget_month += toget;
              total_month += total;
              topay_year += topay;
              toget_year += toget;
              total_year += total;
              if ((i + 1) % 3 == 0) {
                let j: number = Math.floor(i / 3);
                this.rows.push({
                  cells: [
                    {
                      value: ['1er trimestre', '2ème trimestre', '3ème trimestre', '4ème trimestre'][Math.floor(i / 3)],
                      style: MonthlyVatSplitReportModel.subTotal_CellStyle,
                    },
                    {
                      value: topay_month && topay_month != 0 ? NumberUtil.formatMoney(topay_month, '€', 2, '.') : '',
                      style: ObjectUtil.merge(MonthlyVatSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
                    },
                    {
                      value: toget_month && toget_month != 0 ? NumberUtil.formatMoney(toget_month, '€', 2, '.') : '',
                      style: ObjectUtil.merge(MonthlyVatSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
                    },
                    {
                      value: total_month && topay_month != 0 ? NumberUtil.formatMoney(total_month, '€', 2, '.') : '',
                      style: ObjectUtil.merge(MonthlyVatSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
                    },
                  ],
                });
                this.tri_points.push({ name: tri_month_names[j], month: j });
                this.tri_points[j].topay = topay_month;
                this.tri_points[j].toget = -toget_month;
                this.tri_points[j].total = topay_month - toget_month;
                topay_month = 0;
                toget_month = 0;
                total_month = 0;
              }
            } catch {
              continue;
            }
          }

          this.rows.push({
            cells: [
              { value: 'TOTAUX', style: MonthlyVatSplitReportModel.titleOrTotal_CellStyle },
              {
                value: topay_year && topay_year != 0 ? NumberUtil.formatMoney(topay_year, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlyVatSplitReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: toget_year && toget_year != 0 ? NumberUtil.formatMoney(toget_year, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlyVatSplitReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: total_year && total_year != 0 ? NumberUtil.formatMoney(total_year, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlyVatSplitReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
            ],
          });

          let topay_avg: number = month_count > 0 ? topay_year / month_count : 0;
          let toget_avg: number = month_count > 0 ? toget_year / month_count : 0;
          let total_avg: number = month_count > 0 ? total_year / month_count : 0;
          this.rows.push({
            cells: [
              { value: 'Moy. mens.', style: MonthlyVatSplitReportModel.subTotal_CellStyle },
              {
                value: topay_avg && topay_avg != 0 ? NumberUtil.formatMoney(topay_avg, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlyVatSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: toget_avg && toget_avg != 0 ? NumberUtil.formatMoney(toget_avg, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlyVatSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: total_avg && total_avg != 0 ? NumberUtil.formatMoney(total_avg, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlyVatSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
            ],
          });

          this.createEmptyCells();

          this.charts = [];

          this.charts.push({
            type: am4charts.XYChart,
            inline: false,
            colors: StatsModule.chartsColor,
            series: [
              {
                type: 'ColumnSeries',
                name: 'TVA à payer',
                dataFields: { valueY: 'topay', categoryX: 'name' },
                yAxis: 'numbers',
                stacked: true,
              },
              {
                type: 'ColumnSeries',
                name: 'TVA à récupérer',
                dataFields: { valueY: 'toget', categoryX: 'name' },
                yAxis: 'numbers',
                stacked: true,
              },
              {
                type: 'LineSeries',
                name: 'Résultat',
                dataFields: { valueY: 'total', categoryX: 'name' },
                bullets: [{ type: 'CircleBullet', stroke: '#fff', strokeWidth: 2 }],
                yAxis: 'numbers',
              },
            ],
            // maskBullets: false,
            data: this.points,
            yAxes: [
              {
                type: 'ValueAxis',
                id: 'numbers',
                dataFields: { value: 'value', category: 'name' },
                title: { text: 'Montants' },
              },
            ],
            xAxes: [{ type: 'CategoryAxis', dataFields: { value: 'value', category: 'name' } }],
            legend: {
              numberFormatter: {
                numberFormat: '#.',
              },
            },
            container_width: 1000,
            container_height: 480,
            events: {
              beforedatavalidated: function (ev) {
                console.log('beforedatavalidated event:', ev);
                ev.target.data.sort((a, b) => {
                  return a.month - b.month;
                });
              },
            },
          });

          this.charts.push({
            type: am4charts.XYChart,
            inline: false,
            colors: StatsModule.chartsColor,
            series: [
              {
                type: 'ColumnSeries',
                name: 'TVA à payer',
                dataFields: { valueY: 'topay', categoryX: 'name' },
                yAxis: 'numbers',
                stacked: true,
              },
              {
                type: 'ColumnSeries',
                name: 'TVA à récupérer',
                dataFields: { valueY: 'toget', categoryX: 'name' },
                yAxis: 'numbers',
                stacked: true,
              },
              {
                type: 'LineSeries',
                name: 'Résultat',
                dataFields: { valueY: 'total', categoryX: 'name' },
                bullets: [{ type: 'CircleBullet', stroke: '#fff', strokeWidth: 2 }],
                yAxis: 'numbers',
              },
            ],
            // maskBullets: false,
            data: this.tri_points,
            yAxes: [
              {
                type: 'ValueAxis',
                id: 'numbers',
                dataFields: { value: 'value', category: 'name' },
                title: { text: 'Montants' },
              },
            ],
            xAxes: [{ type: 'CategoryAxis', dataFields: { value: 'value', category: 'name' } }],
            legend: {
              numberFormatter: {
                numberFormat: '#.',
              },
            },
            container_width: 1000,
            container_height: 480,
            events: {
              beforedatavalidated: function (ev) {
                console.log('beforedatavalidated event:', ev);
                ev.target.data.sort((a, b) => {
                  return a.month - b.month;
                });
              },
            },
          });

          resolve(true);
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }
}
