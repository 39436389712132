import { Component, OnInit, Input, ViewChild, EventEmitter, Output, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { DataTreeFileComponent } from '../data-tree-file/data-tree-file.component';
import { ArrayUtil } from '../../../modules/utils/classes/ArrayUtil.class';
import { Nomenclature } from '../../../modules/articles/classes/Nomenclature.class';
import { DataTreeComponent } from '../data-tree.component';
import * as $ from 'jquery';

@Component({
  selector: 'data-tree-directory',
  templateUrl: './data-tree-directory.component.html',
  styleUrls: ['./data-tree-directory.component.css']
})
export class DataTreeDirectoryComponent implements OnInit {

    @Input() tree: DataTreeComponent = null;
    @Input() items: any[] = null;
    @Input() selectedObjects: any[] = [];
    @Output() itemClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() itemRenamed: EventEmitter<any> = new EventEmitter<any>();
    @Input() level: number = 0;
    @Input() nameField: string = "name";

    @ViewChildren('treeChildren') treeChildren: QueryList<DataTreeDirectoryComponent>;
    @ViewChildren('treeFiles') treeFiles: QueryList<DataTreeFileComponent>;
    @ViewChild('nameInput') nameInput;

    public editing: boolean = false;

    constructor(private chRef: ChangeDetectorRef) { }

    ngOnInit() {
    }

    expandClick(event, item)
    {
        if (item instanceof Nomenclature) this.sortChildren(item);
        item.expanded = !item.expanded;
        event.stopPropagation();
    }
    iconClick(event, item)
    {
        if (event.detail == 2) {
            if (item instanceof Nomenclature) this.sortChildren(item);
            item.expanded = !item.expanded;
        }
        // event.stopPropagation();
    }
    // nameClick(event, item)
    // {
    //     if (event.detail == 2) {
    //         event.stopPropagation();
    //         event.preventDefault();
    //         this.rename(item);
    //     }
    //     // event.stopPropagation();
    // }
    rename(item: any)
    {
        item.editing = true;
        this.chRef.detectChanges();
        console.log('elem:', $(this.nameInput.nativeElement));
        setTimeout(() => { $(this.nameInput.nativeElement).focus().select(); }, 0);
    }
    nameBlur(event, item)
    {
        item.editing = false;
        this.onItemRenamed(item);
    }
    nameKeyDown(event, item)
    {
        if (event.key == 'Enter')
        {
            item.editing = false;
            this.onItemRenamed(item);
        }
    }
    onItemRenamed(event)
    {
        this.itemRenamed.next(event);
    }

    onDragEnter(event: DragEvent)
    {
        console.log('drag enter:', event);
        $('.drag-drop-target').removeClass('drag-drop-target');
        $(event.toElement).addClass('drag-drop-target');
    }
    onDrop(drop_item: any, event: any)
    {
        $('.drag-drop-target').removeClass('drag-drop-target');
        let drag_item: any = event.dragData;
        if (drag_item.parent) {
            if (drag_item instanceof Nomenclature && drag_item.parent.children) ArrayUtil.removeElements(drag_item.parent.children, [drag_item]);
            else if (drag_item.parent.files)  ArrayUtil.removeElements(drag_item.parent.files, [drag_item]);
            drag_item.parent.changed = true;
        }
        drag_item.parent = drop_item;
        if (drag_item instanceof Nomenclature) {
            if (!drop_item.children) drop_item.children = [drag_item];
            else drop_item.children.push(event.dragData);
        } else {
            if (!drop_item.files) drop_item.files = [drag_item];
            else drop_item.files.push(event.dragData);
        }
        drag_item.changed = true;
        drop_item.changed = true;
        if (drop_item instanceof Nomenclature) this.sortChildren(drop_item);
        if (this.tree) this.tree.expandTo(drag_item, true);
    }

    sortChildren(obj: any)
    {
        if (Array.isArray(obj.children)) obj.children.sort((a: Nomenclature, b: Nomenclature) => { return a.name.localeCompare(b.name); });
        if (Array.isArray(obj.files)) obj.files.sort((a: any, b: any) => { return a[this.nameField].localeCompare(b[this.nameField]); });
    }

    public getImage(item: any)
    {
        if (item.currentClass.definition.icon) return item.currentClass.definition.icon;
        return 'assets/img/icons/20x20/light-blue/' + (item.expanded ? 'open-folder' : 'closed-folder') + '.png';
    }

}
