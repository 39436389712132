import { ObjectModel2 } from "../../../classes/objects/ObjectModel2.class";
import { ObjectDefinition } from "../../../classes/objects/ObjectDefinition.class";
import { ObjectModel3 } from "src/app/classes/objects/ObjectModel3.class";

export class SuppliersNumbering extends ObjectModel3
{
    public country: string = null;

    public constructor()
    {
        super(SuppliersNumbering);
    }

    /* ObjectModel FUNCTIONS */

    public static definition: ObjectDefinition = {
        database: {
            table: 'suppliers_numbering',
            id: 'id',
			db_id: 'db_id'
        },
        values: {
            'country': { type: 'string' }
        },
        children: {},
        links: {}
    };
}
