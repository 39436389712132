import { Component, OnInit } from '@angular/core';
import { DataGridColumn, DataGridSetterFunc } from '../data-grid2/classes/DataGridColumn.class';

import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-color-modal2',
  templateUrl: './color-modal2.component.html',
  styleUrls: ['./color-modal2.component.css'],
})
export class ColorModal2Component implements OnInit {
  public getter: () => string = null;
  public setter: (value: string) => void = null;
  public column: DataGridColumn = null;

  constructor(private modalRef: BsModalRef) {}

  ngOnInit() {}

  get color() {
    return this.getter ? this.getter() : null;
  }
  set color(value: string) {
    if (value.startsWith('#')) value = this.hexToRgbA(value);
    if (this.setter) this.setter(value);
  }

  hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
    } else return 'rgba(192,192,192,1)';
  }

  selectColor(event) {
    this.modalRef.hide();
  }
}
