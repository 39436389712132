import { TextUtil } from "./TextUtil.class";
import * as BigInt from 'big-integer';

export class BusinessUtil
{
    public static checkIban(iban: string)
    {
        try
        {
            let plain_iban: string = iban.toUpperCase().replace(/[^A-Z0-9]/g, '');
            plain_iban = plain_iban.substring(4) + plain_iban.substr(0, 4).replace(/[A-Z]/g,
                (substring: string, ...args) => {
                    return '' + (substring.charCodeAt(0) - 55);
                }
            );
            console.log('plain:', plain_iban);
            let iban_number: BigInt.BigInteger = BigInt(plain_iban);
            let iban_mod: BigInt.BigInteger = iban_number.mod(97);
            return iban_mod.toJSNumber() === 1;
        }
        catch
        {
            return false;
        }
    }
}