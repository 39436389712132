import { Component, OnInit } from '@angular/core';

import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { FilesystemUtil } from 'src/app/modules/utils/classes/FilesystemUtil.class';
import { Invoice } from 'src/app/modules/invoicing/classes/Invoice.class';
import { PaymentReminderPrintingModel } from '../../classes/PaymentReminderPrintingModel.class';
import { PrintPreviewViewComponent } from 'src/app/modules/printing/views/print-preview-view/print-preview-view.component';
import { PrintingModel } from 'src/app/modules/printing/classes/PrintingModel.class';
import { SettingsComponent } from 'src/app/components/settings/settings.component';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { config } from '../../../../classes/config';

@Component({
  selector: 'app-reminders-view-component',
  templateUrl: './reminders-view-component.component.html',
  styleUrls: ['./reminders-view-component.component.css'],
})
export class RemindersViewComponentComponent extends AccessViewComponent {
  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Paiements clients',
    data: this,
    elements: [
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Enregistrer<br/>le PDF',
        icon: 'save',
        click: function (view: RemindersViewComponentComponent) {
          view.saveFile();
        },
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Imprimer',
        icon: 'print',
        click: function (view: RemindersViewComponentComponent) {
          view.print();
        },
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Envoyer<br/>au client',
        icon: ' fas fa-at',
        click: function (view: RemindersViewComponentComponent) {
          view.sendToCustomer();
        },
      },
      { type: 'spacing' },
    ],
  };

  public invoices_by_customer: { [number: string]: Invoice[] } = {};
  public models_by_customer: { [number: string]: PaymentReminderPrintingModel } = {};
  public selectedPreview: string;

  ngOnInit() {}

  initView(invoices: Invoice[]) {
    this.invoices_by_customer = {};
    for (let i = 0; i < invoices.length; ++i) {
      let number: string = invoices[i].customer.number;
      if (!this.invoices_by_customer[number]) this.invoices_by_customer[number] = [];
      this.invoices_by_customer[number].push(invoices[i]);
    }
    let numbers: string[] = this.customers;
    for (let i = 0; i < numbers.length; ++i) {
      this.models_by_customer[numbers[i]] = PaymentReminderPrintingModel.generate(
        this.invoices_by_customer[numbers[i]]
      );
    }
    this.selectedPreview = numbers[0];
    console.log('invoices by customer: ', this.invoices_by_customer);
  }

  public setPreview(preview: string) {
    this.selectedPreview = preview;
  }

  public get customers() {
    return Object.keys(this.invoices_by_customer);
  }
  public get model() {
    return this.models_by_customer[this.selectedPreview] || null;
  }
  public get invoices() {
    return this.invoices_by_customer[this.selectedPreview] || null;
  }

  public iterateFiles(callback) {
    let models: PrintingModel[] = [];
    models.push(this.model);
    return PrintPreviewViewComponent.globalInstance.iteratePrintingModels(models, callback);
  }

  public print() {
    if (this.invoices && this.invoices.length > 0) {
      this.iterateFiles((model: PrintingModel, index: number) => {
        return PrintPreviewViewComponent.globalInstance.print();
      });
    }
  }

  public saveFile() {
    if (this.invoices && this.invoices.length > 0) {
      this.iterateFiles((model: PrintingModel, index: number) => {
        let path: string = FilesystemUtil.resolveSpecialPath(SettingsComponent.get('pdf_reminders_save_path'));
        if (!path) return null;
        else return PrintPreviewViewComponent.globalInstance.saveFileAs(path + model.saveFilename);
      });
    }
  }

  public sendToCustomer() {
    if (this.invoices && this.invoices.length > 0) {
      let bcc: string[] = [...config.mailBcc];
      if (!bcc.includes(this.invoices[0].customer.merchant.email)) bcc.push(this.invoices[0].customer.merchant.email);
      this.iterateFiles((model: PaymentReminderPrintingModel, index: number) => {
        return PrintPreviewViewComponent.globalInstance.sendByMail(
          model.reminders_emails,
          null,
          bcc.join('; '),
          'Rappel de paiement',
          '<p>Madame, Monsieur,</p>' +
            "<p>Par la présente, nous tenons à vous informer que nous n'avons toujours pas reçu le(s) paiement(s) détaillés en pièce jointe.<br/>" +
            'Merci de bien vouloir régler la situation dans les plus brefs délais.</p>' +
            '<p>Veuillez bien sûr nous excuser et ne pas tenir compte de ce courrier, si ce dernier a croisé votre paiement.</p>' +
            '<p>Cordialement,</p>',
          model.mailFilename
        );
      });
    }
  }
}
