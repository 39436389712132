import { Component, ViewChild } from '@angular/core';

import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { ArrayUtil } from 'src/app/modules/utils/classes/ArrayUtil.class';
import { DataGridComponent } from 'src/app/components/data-grid/data-grid.component';
import { DeviceInstance } from '../../classes/DeviceInstance.class';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { Invoice } from 'src/app/modules/invoicing/classes/Invoice.class';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { config } from '../../../../classes/config';

@Component({
  selector: 'app-devices-list-view',
  templateUrl: './devices-list-view.component.html',
  styleUrls: ['./devices-list-view.component.css'],
})
export class DevicesListViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_materiels_listemateriels';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Liste des matériels',
    data: this,
    elements: [
      { type: 'separator' },
      {
        type: 'button',
        name: 'saveButton',
        text: 'Enregistrer',
        icon: 'save',
        click: function (view: DevicesListViewComponent) {
          view.save();
        },
        options: { visible: true },
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Ajouter un<br/>matériel',
        icon: 'print',
        click: function (view: DevicesListViewComponent) {
          view.addDevice();
        },
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Supprimer',
        icon: 'times',
        click: function (view: DevicesListViewComponent) {
          view.deleteDevices();
        },
        access: this.writeAccess,
      },
      { type: 'spacing' },
    ],
  };

  public devices: DeviceInstance[] = [];
  public invoicesData: any = { items: [] };

  public devicesGridColumns = [
    { title: 'Marque', field: 'brand' },
    { title: 'Modèle', field: 'model' },
    { title: 'N° de série', field: 'serial' },
    { title: 'Date de fabrication', field: 'manufacture_date', type: 'date', width: 130 },
    {
      title: 'N° de facture',
      field: 'invoice',
      type: 'foreign-list',
      listItems: this.invoicesData,
      listField: 'number',
      multiSelect: false,
      nullValue: '(Aucun)',
      getOptionStyle: (item: Invoice) => {
        return item.is_logistic ? 'invoice_logistic' : 'invoice_commercial';
      },
      width: 200,
    },
    {
      title: `Numéro de commande ou d\'appel ${config.companyName}`,
      field: 'order_number',
      readonly: true,
      width: 200,
    },
    { title: 'Client', field: 'order.customer.name', readonly: true, width: 200 },
    { title: 'Numéro de commande du client', field: 'customer_reference', readonly: true, width: 150 },
    { title: 'Référence client du produit', field: 'product_reference', width: 150 },
    { title: 'Date de livraison', field: 'delivery_date', readonly: true, width: 100 },
    { title: 'Date de facture', field: 'invoice.date', readonly: true, width: 100 },
    { title: 'Fournisseur', field: 'order.supplier.name', readonly: true, width: 200 },
    { title: 'Durée de la garantie', field: 'months', type: 'number', unit: 'mois', decimalsCount: 0, width: 90 },
    { title: 'Date de fin de garantie', field: 'end_warranty_date', readonly: true, width: 100 },
    { title: 'Toujours sous garantie', field: 'is_under_warranty', type: 'checkbox', readonly: true, width: 90 },
    // { title: 'Référence de l\'offre / devis fournisseur', field: 'customer_reference' },
    // { title: 'Prix', field: 'price', type: 'number', unit: '€', decimalsCount: 2, width: 90 }
    { title: 'Remarques', field: 'remarks', width: 350 },
  ];

  @ViewChild('grid') grid: DataGridComponent;

  public ngOnInit() {
    DeviceInstance.load().then(
      (result) => {
        this.devices = result;
      },
      (err) => {
        console.error(err);
      }
    );
    Invoice.load(null, ['number']).then(
      (result) => {
        this.invoicesData.items = result;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  save() {
    let seq = Date.now();
    let promises: Promise<any>[] = [];
    for (let i = 0; i < this.devices.length; ++i) {
      if (this.devices[i].changed === true) promises.push(this.devices[i].save2(seq));
    }
    if (promises.length > 0) {
      Promise.all(promises).then(
        (result) => {
          NotificationsComponent.push({
            title: 'Sauvegarde effectuée',
            summary: '<b>' + promises.length + ' appareils</b> ont été sauvegardés avec succès.',
            type: 'success',
          });
        },
        (err) => {
          NotificationsComponent.push({
            title: 'Erreur',
            summary: "Une erreur s'est produite lors de la sauvegarde.",
            type: 'error',
            content: err,
          });
        }
      );
    }
  }

  deleteDevices() {
    DialogsComponent.display({
      icon: 'warning',
      title: 'Supprimer les matériels ?',
      message: 'Êtes-vous sûr(e) de vouloir supprimer les matériels sélectionnés ?',
      buttons: DialogButton.yesNoButtons,
    }).then((result: any) => {
      if (result === DialogButton.RESULT_YES) {
        let devices: DeviceInstance[] = this.grid.selectedItems as DeviceInstance[];
        if (Array.isArray(devices) && devices.length > 0) {
          let promises: any[] = [];
          for (let i = 0; i < devices.length; ++i) promises.push(devices[i].moveToTrash());
          Promise.all(promises).then(
            (result) => {
              NotificationsComponent.push({
                type: 'success',
                title: 'Matériels supprimés',
                summary: 'Les matériels ont été supprimées avec succès',
              });
              ArrayUtil.removeElements(this.devices, devices);
            },
            (err) => {
              NotificationsComponent.push({
                type: 'error',
                title: 'Erreur lors de la suppression',
                summary: "Une erreur s'est produite lors de la suppression",
                content: err,
              });
              console.error(err);
            }
          );
        }
      }
    });
  }

  addDevice() {
    this.devices.push(new DeviceInstance());
    setTimeout(() => {
      this.grid.lastPage();
    }, 100);
  }
}
