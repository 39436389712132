import { ObjectModel2 } from "../../../classes/objects/ObjectModel2.class";
import { ObjectDefinition } from "../../../classes/objects/ObjectDefinition.class";
import { Nomenclature } from "../../articles/classes/Nomenclature.class";
import { ArticleBase } from "./ArticleBase.class";


export class Device extends ArticleBase
{
    public get model(): string { return this.field1; };
    public get technology(): string { return this.field2; };
    public get resolution(): string { return this.field3; };
    public get max_speed(): string { return this.field4; };
    public get media_width(): string { return this.field5; };
    public get media_thickness(): string { return this.field6; };
    public get memory(): string { return this.field7; };
    public get knife(): string { return this.field8; };
    public get interface(): string { return this.field9; };
    public get programming(): string { return this.field10; };
    public get options(): string { return this.field11; };
    public get building(): string { return this.field12; };
    public get warranty(): string { return this.field13; };
    public get others(): string { return this.field14; };
    public get picture(): string { return this.field15; };

    public set model(value: string) { this.field1 = value; };
    public set technology(value: string) { this.field2 = value; };
    public set resolution(value: string) { this.field3 = value; };
    public set max_speed(value: string) { this.field4 = value; };
    public set media_width(value: string) { this.field5 = value; };
    public set media_thickness(value: string) { this.field6 = value; };
    public set memory(value: string) { this.field7 = value; };
    public set knife(value: string) { this.field8 = value; };
    public set interface(value: string) { this.field9 = value; };
    public set programming(value: string) { this.field10 = value; };
    public set options(value: string) { this.field11 = value; };
    public set building(value: string) { this.field12 = value; };
    public set warranty(value: string) { this.field13 = value; };
    public set others(value: string) { this.field14 = value; };
    public set picture(value: string) { this.field15 = value; };

    constructor()
    {
        super();
        this.type = 1;
    }

    public static load(ids: string[] = null, orderBy: string[] = null, groupBy: string[] = null,
        deleted: boolean = false, conditions: string = null, forceReload: boolean = false)
    {
        if (conditions != null) conditions = '(' + conditions + ') AND type = 1';
        else conditions = 'type = 1';
        return super.load(ids, orderBy, groupBy, deleted, conditions, forceReload);
    }
}
