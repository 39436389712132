import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { ClassManager } from '../../classes/objects/ClassManager.class';
import { ArrayUtil } from '../../modules/utils/classes/ArrayUtil.class';
import { DataTreeDirectoryComponent } from './data-tree-directory/data-tree-directory.component';
import { isArray } from 'util';
import { DataTreeFileComponent } from './data-tree-file/data-tree-file.component';

@Component({
  selector: 'data-tree',
  templateUrl: './data-tree.component.html',
  styleUrls: ['./data-tree.component.css']
})
export class DataTreeComponent implements OnInit {

    @Input() modelClass: string = null;
    @Input() rootId: number = null;
    @Input() editable: boolean = false;
    @Input() selectedObjects: any[] = [];
    @Input() items: any[] = [];
    @Input() level: number = 0;
    @Input() nameField: string = "name";

    @Output() change: EventEmitter<any> = new EventEmitter<any>();
    @Output() itemRenamed: EventEmitter<any> = new EventEmitter<any>();
    //@Output() fileDblClick: EventEmitter<any> = new EventEmitter<any>();

    //@ViewChildren('treeChildren') treeChildren: QueryList<DataTreeDirectoryComponent>;
    //@ViewChildren('treeFiles') treeFiles: QueryList<DataTreeFileComponent>;
    @ViewChild('dataTree') dataTree: DataTreeDirectoryComponent;

    public classRef: any = null;
    public instance: DataTreeComponent = this;

    constructor() { }

    ngOnInit() {
        this.classRef = ClassManager.getClass(this.modelClass);
    }

    // loadItems()
    // {
    //     let self = this;
    //     this.classRef.load().then(
    //         function(result) { self.items = result; },
    //         function(err) {
    //             console.error('error loading tree items:', err);
    //         }
    //     );
    // }

    sortBy(field: string, arr: any[] = null)
    {
        if (!arr) arr = this.items;
        if (Array.isArray(arr)) {
            ArrayUtil.sortByField(arr, field);
            for(let i=0; i<arr.length; ++i)
            {
                if (Array.isArray(arr[i].children)) this.sortBy(field, arr[i].children);
                if (Array.isArray(arr[i].files)) this.sortBy(field, arr[i].files);
            }
        }
    }

    findParent(item: any)
    {
        // let treeChildren: DataTreeDirectoryComponent[] = this.treeChildren.toArray();
        // for(let i=0; i<treeChildren.length; ++i)
        // {
        //     let treeItem = treeChildren[i].item;
        //     if (treeItem.children && treeItem.children.includes(item)) return treeItem;
        //     else if (treeItem.files && treeItem.files.includes(item)) return treeItem;
        //     let result = treeChildren[i].findParent(item);
        //     if (result != null) return result;
        // }
        // return null;
    }

    expandTo(item: any, select: boolean = true)
    {
        let parent = item.parent;
        // let parent: DataTreeDirectoryComponent = null;
        // if (!item.parent) parent = this.getItemComponent(this.findParent(item));
        // else this.getItemComponent(item.parent);
        while(parent) {
            console.log('expanding', parent);
            parent.expanded = true;
            parent = parent.parent;
        }
        if (select === true) {
            this.selectedObjects.splice(0, this.selectedObjects.length, item);
            this.change.next(this.selectedObjects);
        }
    }

    getItemComponent(item: any, directoryComponent: DataTreeDirectoryComponent = null)
    {
        if (!directoryComponent) directoryComponent = this.dataTree;
        let treeChildren: DataTreeDirectoryComponent[] = directoryComponent.treeChildren.toArray();
        for(let i=0; i<treeChildren.length; ++i)
        {
            let treeItem = treeChildren[i];
            if (treeItem.items.includes(item)) return treeItem;
            let result = this.getItemComponent(item, treeItem);
            if (result != null) return result;
        }
        return null;
    }

    get rootItems()
    {
        console.log('calculating root items...');
        return this.items;
        /*let arr = this.items.filter(
            (value, index, arr) => {
                return (this.rootId == null && value.parent == null) ||
                       (value.parent && value.parent.id == this.rootId);
            }
        );
        return arr;*/
    }

    itemClick(event)
    {
        event.event.stopPropagation();
        if (!event.event.ctrlKey) this.selectedObjects.splice(0, this.selectedObjects.length);
        ArrayUtil.toggleItem(this.selectedObjects, event.item);
        console.log('selected items: ', this.selectedObjects);
        this.change.next(this.selectedObjects);
    }
    onItemRenamed(event)
    {
        this.itemRenamed.next(event);
    }

    clearSelection()
    {
        this.selectedObjects = [];
    }

}
