import { Component, OnInit, ViewChild } from '@angular/core';

import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { ApiService } from '../../../../services/api/api.service';
import { ArrayUtil } from '../../../utils/classes/ArrayUtil.class';
import { DataGridComponent } from '../../../../components/data-grid/data-grid.component';
import { DateTimeUtil } from '../../../utils/classes/DateTimeUtil.class';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { NotificationsComponent } from '../../../../components/notifications/notifications.component';
import { Order } from '../../../orders/classes/Order.class';
import { SaveSequence } from 'src/app/classes/objects/SaveSequence.class';
import { StockMove } from '../../classes/StockMove.class';
import { StockMoveViewComponent } from '../stock-move-view/stock-move-view.component';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from '../../../../components/views/views.component';
// import { StockMoveCreateViewComponent } from '../move-create-view/move-create-view.component';

@Component({
  selector: 'app-stock-move-followup-list-view',
  templateUrl: './stock-moves-followup-view.component.html',
  styleUrls: ['./stock-moves-followup-view.component.css'],
})
export class StockMovesFollowupViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_stocks_suiviappels';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Suivi des appels',
    data: this,
    elements: [
      { type: 'separator' },
      {
        type: 'button',
        text: 'Enregistrer',
        icon: 'save',
        click: function (view: StockMovesFollowupViewComponent) {
          view.saveStockMoves();
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Archiver',
        icon: 'archive',
        click: function (view: StockMovesFollowupViewComponent) {
          view.archiveMoves();
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Désarchiver',
        icon: 'archive',
        click: function (view: StockMovesFollowupViewComponent) {
          view.archiveMoves(false);
        },
        access: this.writeAccess,
      },
      { type: 'separator-large' },
      {
        type: 'toggle-button',
        text: 'Afficher les<br/>éléments archivés',
        icon: 'filter',
        value: false,
        click: function (view: StockMovesFollowupViewComponent) {
          view.filterMoves = !this.value;
          view.reloadMoves();
          //view.updateSuppliersList();
        },
      },
      { type: 'spacing' },
    ],
  };

  public movesType: number = 0;
  public filterMoves: boolean = true;

  public moves: StockMove[] = [];
  public movesGridColumns = [
    { title: "Numéro d'appel", field: 'fullNumber', readonly: true, width: 200 },
    { title: "N° d'appel\ndu client", field: 'customer_reference', readonly: true, width: 170 },
    { title: 'Client', field: 'customer.nameWithIdentifier', readonly: true, width: 200 },
    { title: 'Quantité', field: 'quantityAndUnit', readonly: true, width: 170 },
    { title: 'Désignation', field: 'article.designation', readonly: true, width: 450 },
    {
      title: 'Total de la commande HTVA',
      field: 'converted_totalAmount',
      type: 'number',
      decimalsCount: 2,
      textAlign: 'left',
      currencyField: 'sell_currency',
      readonly: true,
      width: 100,
    },
    { title: "Date d'appel\nsur stock", field: 'date', readonly: true, width: 90 },
    { title: 'Date de livraison prévue', field: 'delivery_date', readonly: true, width: 90 },
    { title: 'Jours de\nretard', field: 'delayDays', readonly: true, width: 80 },
    { title: 'Fournisseur', field: 'supplier.name', readonly: true, width: 200 },
    { title: 'Etat de la commande / Remarque', field: 'followup_remarks', width: 250 },
  ];

  @ViewChild('grid') grid: DataGridComponent;

  ngOnInit() {
    this.reloadMoves();
  }

  onAccountingChanged(accounting: Accounting) {
    this.reloadMoves();
  }

  reloadMoves() {
    const conditions = [];
    if (AccountingsService.currentAccounting)
      conditions.push(
        'id_accounting=' +
          AccountingsService.currentAccounting.id +
          ' AND db_id_accounting=' +
          AccountingsService.currentAccounting.db_id
      );
    if (this.filterMoves) conditions.push('followup_archived = 0');
    StockMove.load(null, ['~date'], null, false, conditions.length > 0 ? conditions.join(' AND ') : null, true).then(
      (result: StockMove[]) => {
        this.moves = result;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  saveStockMoves() {
    let moves_to_save: StockMove[] = [];
    for (let i = 0; i < this.moves.length; ++i) {
      if (this.moves[i].changed === true) moves_to_save.push(this.moves[i]);
    }
    if (moves_to_save.length > 0) {
      let ss: SaveSequence = new SaveSequence(moves_to_save);
      ss.save().then(
        (result) => {
          NotificationsComponent.push({
            type: 'success',
            title: 'Enregistrement effectué',
            summary: '<b>' + moves_to_save.length + ' commandes</b> ont été enregistrées.',
          });
        },
        (err) => {
          console.error(err);
          NotificationsComponent.push({
            type: 'error',
            title: "Echec de l'enregistrement",
            summary: "Les commandes n'ont pas pu être enregistrées.",
          });
        }
      );
    }
  }

  moveDblClick(event, move) {
    ViewsComponent.openView(StockMoveViewComponent, move);
  }

  archiveMoves(archive = true) {
    const moves: StockMove[] = this.grid.selectedItems as StockMove[];
    if (Array.isArray(moves) && moves.length > 0) {
      const ids: string[] = [];
      for (let i = 0; i < moves.length; ++i) {
        // orders[i].archived = (archive === false ? 0 : 1);
        ids.push(moves[i].fullId);
      }
      ApiService.callModule('moves', 'followup_archive', { ids: ids, archive: archive ? 1 : 0 }).then(
        (result) => {
          NotificationsComponent.push({
            type: 'success',
            title: 'Appels archivés',
            summary: 'Les appels ont été ' + (archive === false ? 'dés' : '') + 'archivés avec succès',
          });
          this.grid.clearSelection();
          this.reloadMoves();
          // ArrayUtil.removeElements(this.orders, orders);
        },
        (err) => {
          NotificationsComponent.push({
            type: 'error',
            title: "Erreur lors de l'archivage",
            summary:
              "Une erreur s'est produite lors " + (archive === false ? 'du dés' : "de l'") + 'archivage des appels',
            content: err,
          });
          console.error(err);
        }
      );
    }
  }

  gridRowStyle(item: Order) {
    return item && item.followup_archived == 1
      ? {
          color: 'gray',
          'font-style': 'italic',
        }
      : null;
  }
}
