<div class="supplier-view-wrapper">
  <div class="supplier-view-info-column">
    <h3>Informations client</h3>

    <info-block
      #nameBlockComponent
      [block]="nameBlock"
      [data]="supplierCopy"
      [readonly]="writeAccess !== true"
    ></info-block>
    <info-block
      #generalBlockComponent
      [block]="generalBlock"
      [data]="supplierCopy"
      [readonly]="writeAccess !== true"
    ></info-block>
    <info-block
      #vatBlockComponent
      [block]="vatBlock"
      [data]="supplierCopy"
      [readonly]="writeAccess !== true"
    ></info-block>

    <info-block
      [block]="commercialBlock"
      [data]="supplierCopy.commercial_address"
      [readonly]="writeAccess !== true"
    ></info-block>
    <info-block
      #bankBlockComponent
      [block]="bankBlock"
      [data]="supplierCopy"
      [readonly]="writeAccess !== true"
    ></info-block>

    <info-block [block]="remarksBlock" [data]="supplierCopy" [readonly]="writeAccess !== true"></info-block>
  </div>

  <div class="supplier-view-addresses-column">
    <div class="supplier-view-contacts-column">
      <h3>
        Contacts
        <div class="title-buttons">
          <button class="btn btn-default" (click)="addContact()" [disabled]="writeAccess !== true">
            <i class="fa fa-plus"></i>
          </button>
          <button class="btn btn-default" (click)="deleteContacts()" [disabled]="writeAccess !== true">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </h3>

      <div *ngIf="views.printMode">
        <info-block
          *ngFor="let contact of supplierCopy.contacts; let index = index"
          #contactsBlockComponent
          [block]="contactBlock"
          [data]="contact"
          [title]="'Contact ' + (index + 1)"
          [backColor]="contactBackColor(99, 37, 35, index, supplierCopy.contacts.length)"
          [textColor]="contactTextColor(index, supplierCopy.contacts.length)"
          [checkable]="true"
          (selectedChange)="contactsSelectionChanged()"
          [readonly]="isViewReadOnly"
        ></info-block>
      </div>

      <data-grid2
        *ngIf="!views.printMode"
        #contactsGrid
        [items]="supplierCopy.orderedContacts"
        [columns]="contactsColumns"
        [selectable]="true"
        [editable]="true"
      ></data-grid2>
    </div>

    <div class="supplier-view-column">
      <h3>Nomenclatures liées</h3>

      <foreign-list
        [fieldName]="'name'"
        [objects]="views.printMode ? supplierCopy.nomenclatures : nomenclatures"
        [linesCount]="28"
        [multiSelect]="true"
        style="border: 2px solid black !important"
        [(ngModel)]="supplierCopy.nomenclatures"
        (change)="nomenclatureChanged($event)"
        [readonly]="writeAccess !== true"
      ></foreign-list>
    </div>
  </div>
</div>
