import { Pipe } from '@angular/core';


@Pipe({
    name: 'dataListSearch',
    pure: false
})
export class DataListSearchPipe
{
    transform(array: Array<any>, searchPattern: any, fields: Array<any>): Array<any>
    {
        let result: Array<any> = [];
        if (array == null || searchPattern == null || searchPattern == '' || fields == null || fields.length == 0) return array;
        else {
            let r: RegExp = new RegExp(searchPattern, 'i');
            array.forEach(element => {
                let isOk = true;
                for (let i=0; i<fields.length; ++i) {
                    let value: string = element[fields[i]];
                    if (!value) isOk = false;
                    else {
                        let matches = value.match(r);
                        // console.log("search for", value, "with pattern", searchPattern, "=", matches);
                        if (!matches || matches.length == 0) isOk = false;
                    }
                }
                if (isOk === true) result.push(element);
            });
        }
        // console.log(result);
        return result;
    }
}
