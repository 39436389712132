import { Accounting } from '../../other-data/classes/Accounting.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { Address } from '../../addresses/classes/Address.class';
import { Article } from '../../articles/classes/Article.class';
import { ArticleBase } from '../../articles/classes/ArticleBase.class';
import { Currency } from '../../other-data/classes/Currency.class';
import { Customer } from '../../customers/classes/Customer.class';
import { Merchant } from '../../other-data/classes/Merchant.class';
import { ObjectDefinition } from 'src/app/classes/objects/ObjectDefinition.class';
import { ObjectModel2 } from 'src/app/classes/objects/ObjectModel2.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';
import { Price } from './Price.class';
import { ServerApi } from 'src/app/classes/api/ServerApi.class';
import { Supplier } from '../../suppliers/classes/Supplier.class';
// import { HistoryObjectModel } from "src/app/classes/objects/HistoryObjectModel.class";

export class Sale extends ObjectModel3 {
  // Database fields

  public accounting: Accounting = null;
  public currency: Currency = null;
  public xrate: number = 1;

  public number: string = null;

  public creation_date: string = null;
  public date: string = null;
  public valid_date: string = null;

  public article: ArticleBase = null;
  public article_reference: string = null;

  public merchant: Merchant = null;
  public customer: Customer = null;
  public contact: Address = null;

  public deadline: string = null;
  public delivery_time: string = null;
  public customer_reference: string = null;

  public ordinator_contact: string = null;
  public ordinator_address: Address = null;
  public ordinator_address_text: string = null;
  public ordinator_emails: string = null;
  public invoicing_contact: string = null;
  public invoicing_address: Address = null;
  public invoicing_address_text: string = null;
  public invoicing_emails: string = null;
  public reminders_emails: string = null;
  public delivery_contact: string = null;
  public delivery_address: Address = null;
  public delivery_address_text: string = null;
  public opening_hours: string = null;
  public hide_customer: number = 0;

  public discount: number = 0;
  public discount_days: number = null;

  // public invoicings: number = null;
  // public deliveries: number = null;
  // public storage: number = null;

  public get invoicings(): number {
    return this.prices && this.prices.length > 0
      ? this.prices.reduce((prev, curr, index, arr) =>
          prev.invoicings && prev.invoicings > curr.invoicings ? prev : curr
        ).invoicings
      : 0;
  }
  public get deliveries(): number {
    return this.prices && this.prices.length > 0
      ? this.prices.reduce((prev, curr, index, arr) =>
          prev.invoicings && prev.invoicings > curr.invoicings ? prev : curr
        ).deliveries
      : 0;
  }
  public get storage(): number {
    return this.prices && this.prices.length > 0
      ? this.prices.reduce((prev, curr, index, arr) =>
          prev.invoicings && prev.invoicings > curr.invoicings ? prev : curr
        ).storage
      : 0;
  }

  public demand_remarks: string = null;
  public copy_demand_remarks: number = 0;
  public offer_remarks: string = null;
  public copy_offer_remarks: number = 0;

  public suppliers: Supplier[] = [];
  public prices: Price[] = [];

  public history_version: number = null;

  public archived: number = 0;

  public internal_notes: string = null;

  public shortage_warning: number = 0;

  public sent_to_customer: number = 0;
  public sent_to_suppliers: number = 0;

  public constructor() {
    super(Sale);
  }

  public getNumberPrefix() {
    return this.merchant.number + '/' + ('00' + (new Date().getFullYear() % 100)).slice(-2);
  }
  public getNextNumber() {
    return ServerApi.callModule('sales', 'nextNumber', { number_prefix: this.getNumberPrefix() });
  }
  public getNextHistoryVersion() {
    return ServerApi.callModule('sales', 'nextHistoryVersion', { number: this.number });
  }
  public generateNumber(number: string) {
    return (
      this.getNumberPrefix() +
      (this.deliveries > 1 ? '1' : '0') +
      (this.accounting ? this.accounting.number : '0') +
      ' ' +
      ('0000' + number).slice(-4) +
      '/' +
      (this.customer ? this.customer.number : 'BEL0000') +
      '/' +
      (this.article && this.article.nomenclature && this.article.nomenclature.name
        ? this.article.nomenclature.name.replace(/[^A-Za-z0-9]/g, '').substr(0, 3)
        : '000')
    );
  }
  public get numberWithVersion() {
    if (!this.number) return null;
    else return this.number + '-' + this.history_version;
  }
  public get numberAndName() {
    let arr: string[] = [];
    if (this.numberWithVersion) arr.push(this.numberWithVersion);
    if (this.article && this.article.designation) arr.push(this.article.designation);
    return arr.join(' - ');
  }
  public get shortNumber() {
    return this.numberWithVersion.replace(/(\/[A-Za-z]{2,}[0-9]{4,}\/[0-9A-Za-z]{3}(\-[0-9]+)?)$/, '');
  }

  public get merchantName() {
    return this.merchant && this.merchant.name;
  }
  public get customerName() {
    return this.customer && this.customer.name;
  }
  public get articleDesignation() {
    return this.article && this.article.designation;
  }
  public get articleNomenclatureName() {
    return this.article && this.article.nomenclature && this.article.nomenclature.name;
  }
  public get articleField1() {
    return this.article && this.article.field1;
  }
  public get articleField2() {
    return this.article && this.article.field2;
  }
  public get articleField3() {
    return this.article && this.article.field3;
  }
  public get articleField4() {
    return this.article && this.article.field4;
  }
  public get articleField5() {
    return this.article && this.article.field5;
  }
  public get articleField6() {
    return this.article && this.article.field6;
  }
  public get articleField7() {
    return this.article && this.article.field7;
  }
  public get articleField8() {
    return this.article && this.article.field8;
  }
  public get articleField9() {
    return this.article && this.article.field9;
  }
  public get articleField10() {
    return this.article && this.article.field10;
  }
  public get articleField11() {
    return this.article && this.article.field11;
  }
  public get articleField12() {
    return this.article && this.article.field12;
  }
  public get articleField13() {
    return this.article && this.article.field13;
  }
  public get articleField14() {
    return this.article && this.article.field14;
  }

  /* ObjectModel FUNCTIONS */

  public static definition: ObjectDefinition = {
    trashDelete: true,
    database: {
      table: 'sales',
      id: 'id',
      db_id: 'db_id',
      history_key: 'number',
    },
    values: {
      xrate: { type: 'number' },
      number: { type: 'string' },
      creation_date: { type: 'string' },
      date: { type: 'string' },
      valid_date: { type: 'string' },
      article_reference: { type: 'string' },
      deadline: { type: 'string' },
      delivery_time: { type: 'string' },
      customer_reference: { type: 'string' },
      ordinator_contact: { type: 'string' },
      ordinator_address_text: { type: 'string' },
      ordinator_emails: { type: 'string' },
      invoicing_contact: { type: 'string' },
      invoicing_address_text: { type: 'string' },
      invoicing_emails: { type: 'string' },
      reminders_emails: { type: 'string' },
      delivery_contact: { type: 'string' },
      delivery_address_text: { type: 'string' },
      opening_hours: { type: 'string' },
      hide_customer: { type: 'number' },
      demand_remarks: { type: 'string' },
      offer_remarks: { type: 'string' },
      copy_demand_remarks: { type: 'number' },
      copy_offer_remarks: { type: 'number' },
      history_version: { type: 'number' },
      discount: { type: 'number' },
      discount_days: { type: 'number' },
      archived: { type: 'number' },
      internal_notes: { type: 'string' },
      shortage_warning: { type: 'number' },
      sent_to_customer: { type: 'number' },
      sent_to_suppliers: { type: 'number' },
    },
    children: {
      accounting: { type: 'Accounting', clone: false, save: false, delete: false },
      currency: { type: 'Currency', clone: false, save: false, delete: false },
      article: { type: 'ArticleBase', clone: false, save: false, delete: false, includeDeleted: true },
      merchant: { type: 'Merchant', clone: false, save: false, delete: false },
      customer: { type: 'Customer', clone: false, save: false, delete: false },
      contact: { type: 'Address', clone: false, save: false, delete: false },
      ordinator_address: { type: 'Address', clone: false, save: false, delete: false },
      delivery_address: { type: 'Address', clone: false, save: false, delete: false },
      invoicing_address: { type: 'Address', clone: false, save: false, delete: false },
    },
    links: {
      suppliers: {
        type: 'Supplier',
        table: 'sales_suppliers',
        id_self: 'id_sale',
        id_link: 'id_supplier',
        save: false,
        clone: false,
        delete: false,
      },
      prices: {
        type: 'Price',
        table: 'sales_prices',
        id_self: 'id_sale',
        id_link: 'id_price',
        save: true,
        clone: true,
        delete: true,
      },
    },
  };
}
