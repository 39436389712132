import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Dialog } from '../classes/Dialog.class';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

    public dialog: Dialog = null;

    public promise: Promise<any> = null;
    private resolve: (value?: any) => void;
    private reject: (value?: any) => void;

    constructor(private modalRef: BsModalRef) {
        this.promise = new Promise<any>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }

    ngOnInit() {
    }

    sendDialogResult(result: any)
    {
        this.resolve(result);
        this.modalRef.hide();
    }

}
