import { Goal } from './Goal.class';
import { InfoBlock } from '../../../components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from '../../../components/info-block/info-block.component';
import { InfoBlockField } from '../../../components/info-block/classes/InfoBlockField.class';
import { Merchant } from '../../other-data/classes/Merchant.class';
import { Nomenclature } from '../../articles/classes/Nomenclature.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { PrintingModel } from '../../printing/classes/PrintingModel.class';

export class GoalsPrintingModel extends PrintingModel {
  public optionsBlock: InfoBlock = {
    fields: [
      {
        title: 'Langue',
        field: 'lang',
        type: 'select',
        selectOptions: [
          { text: '(Selon client)', value: '' },
          { text: 'Français', value: 'fr' },
          { text: 'Anglais', value: 'en' },
          { text: 'Néerlandais', value: 'nl' },
        ],
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
          this.regenerate();
        },
      },
      {
        title: 'Afficher logo Wallonie',
        field: 'show_wallonia_logo',
        type: 'checkbox',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
          this.regenerate();
        },
      },
    ],
  };

  public goals: Goal[] = [];
  public nomenclatures: Nomenclature[] = [];
  public year: number;
  public merchant: Merchant = null;

  private pageWidth = 295;
  private pageHeight = 210;
  private pageLeftMargin = 10;
  private pageRightMargin = 10;
  private pageTopMargin = 10;
  private pageBottomMargin = 10;

  private zoom = 1;
  private fontSize = 8 * this.zoom;

  private titleHeight = 6 * this.zoom;
  private titleFontSize = 14 * this.zoom;
  private firstLineHeight = 5 * this.zoom;
  private secondLineHeight = 20 * this.zoom;
  private headerSize = this.titleHeight + 1 + this.firstLineHeight + this.secondLineHeight;

  private categoryColWidth = 10 * this.zoom;
  private customerColWidth = 40 * this.zoom;

  private lastYearColWidth = 20 * this.zoom;
  private lastYearTotalWidth = 4 * this.lastYearColWidth;

  private amountColWidth = 20 * this.zoom;
  private marginColWidth = 12 * this.zoom;
  private amountTotalWidth = 0;
  private marginTotalWidth = 0;

  private totalColWidth = 20 * this.zoom;
  private totalTotalWidth = 5 * this.totalColWidth;

  private bodyLineHeight = 5 * this.zoom;

  private totalWidth = 0;

  private get total(): number {
    let total: number = 0;
    for (let i = 0; i < this.goals.length; ++i) total += this.goals[i].total;
    return total;
  }

  subtotal(property: string) {
    return this.goals.reduce((prev, cur) => prev + (cur[property] || 0), 0);
  }

  public static generate(
    goals: Goal[],
    nomenclatures: Nomenclature[],
    year: number,
    merchant: Merchant
  ): GoalsPrintingModel {
    //if (object instanceof StockMove) object = object.Goal;
    let model: GoalsPrintingModel = new GoalsPrintingModel();
    model.goals = goals;
    model.nomenclatures = nomenclatures;
    model.year = year;
    model.merchant = merchant;

    model.amountTotalWidth = model.nomenclatures.length * model.amountColWidth;
    model.marginTotalWidth = model.nomenclatures.length * model.marginColWidth;
    model.totalWidth =
      model.categoryColWidth +
      model.customerColWidth +
      model.lastYearTotalWidth +
      model.amountTotalWidth +
      model.marginTotalWidth;

    return model.regenerate();
  }

  private splitNomenclaturesByPage(): Nomenclature[][] {
    const byPage: Nomenclature[][] = [];
    let x = this.categoryColWidth + this.customerColWidth + this.lastYearTotalWidth;
    let page: Nomenclature[] = [];
    const availWidth = this.pageWidth - this.pageLeftMargin - this.pageRightMargin;
    for (let i = 0; i < this.nomenclatures.length; ++i) {
      const n = this.nomenclatures[i];
      if (x + this.amountColWidth + this.marginColWidth < availWidth || !page.length) {
        page.push(n);
        x += this.amountColWidth + this.marginColWidth;
      } else {
        byPage.push(page);
        page = [];
        x = this.categoryColWidth + this.customerColWidth;
      }
    }
    if (page.length > 0) {
      byPage.push(page);
    }
    console.log('remaining width:', x, this.totalTotalWidth, availWidth);
    if (x + this.totalTotalWidth > availWidth) byPage.push([]);

    return byPage;
  }

  private spltGoalsByPage(): Goal[][] {
    const goalsCopy: Goal[] = [...this.goals, undefined];
    const byPage: Goal[][] = [];
    const n = Math.floor(
      (this.pageHeight - this.pageBottomMargin - this.pageTopMargin - this.headerSize) / this.bodyLineHeight
    );

    do {
      byPage.push(goalsCopy.splice(0, n));
    } while (goalsCopy.length > 0);

    return byPage;
  }

  public regenerate(): GoalsPrintingModel {
    // let goals: Goal[] = this.goals;

    let pageWidth = this.pageWidth;
    let pageHeight = this.pageHeight;

    let pageLeftMargin = this.pageLeftMargin;
    let pageRightMargin = this.pageRightMargin;
    let pageTopMargin = this.pageTopMargin;
    let pageBottomMargin = this.pageBottomMargin;

    const nByPage = this.splitNomenclaturesByPage();
    console.log('split by pages:', nByPage);
    const gByPage = this.spltGoalsByPage();
    console.log('goals split by page:', gByPage);

    if (nByPage.length && gByPage.length) {
      this.saveFilename = `Budgets - ${this.merchant.numberAndName} - ${this.year}.pdf`;
      this.mailFilename = this.saveFilename;

      this.pages = [];

      gByPage.map((goals, gPageNum) => {
        this.pages.push(
          ...nByPage.map((nomenclatures, nPageNum) => ({
            width: pageWidth,
            height: pageHeight,

            leftMargin: pageLeftMargin,
            rightMargin: pageRightMargin,
            topMargin: pageTopMargin,
            bottomMargin: pageBottomMargin,

            style: {
              'font-family': 'Calibri',
              'font-size': `${this.fontSize}pt`,
              width: pageWidth - pageLeftMargin - pageRightMargin + 'mm',
              height: pageHeight - pageTopMargin - pageBottomMargin + 'mm',
              'margin-top': pageTopMargin + 'mm',
              'margin-left': pageLeftMargin + 'mm',
              'margin-right': pageRightMargin + 'mm',
              'margin-bottom': pageBottomMargin + 'mm',
            },

            headerSize: this.headerSize,
            headerElements: [
              {
                style: {
                  display: 'block',
                  'font-size': `${this.titleFontSize}pt`,
                  'font-weight': 'bold',
                  height: `${this.titleHeight + 1}mm`,
                  'line-height': `${this.titleHeight}mm`,
                },
                html: `Objectifs ${this.year} - ${this.merchant.numberAndName}`,
              },
              {
                style: {
                  display: 'block',
                  'font-size': `${this.titleHeight / 2}mm`,
                  'font-weight': 'normal',
                  height: `${this.titleHeight / 2}mm`,
                  'line-height': `${this.titleHeight / 2}mm`,
                  position: 'absolute',
                  top: '0mm',
                  right: '0mm',
                },
                html: `Page ${gPageNum * nByPage.length + nPageNum + 1}/${gByPage.length * nByPage.length}`,
              },
              {
                style: {
                  width: `${
                    this.categoryColWidth +
                    this.customerColWidth +
                    (nPageNum === 0 ? this.lastYearTotalWidth : 0) +
                    (nPageNum === nByPage.length - 1 ? this.totalTotalWidth : 0) +
                    (this.amountColWidth + this.marginColWidth) * nomenclatures.length
                  }mm`,
                  display: 'flex',
                  'flex-direction': 'row',
                  'flex-wrap': 'no-wrap',
                },
                children: [
                  {
                    style: {
                      display: 'inline-block',
                      width: `${this.categoryColWidth + this.customerColWidth}mm`,
                      border: '1px solid black',
                      'background-color': 'rgb(255, 215, 0)',
                      'text-align': 'center',
                      height: `${this.firstLineHeight}mm`,
                    },
                    html: 'Client',
                  },
                  ...(nPageNum === 0
                    ? [
                        {
                          style: {
                            display: 'inline-block',
                            width: `${this.lastYearTotalWidth}mm`,
                            border: '1px solid black',
                            'background-color': 'rgb(255, 215, 0)',
                            'text-align': 'center',
                            height: `${this.firstLineHeight}mm`,
                          },
                          html: `Récapitulatif ${this.year - 1}`,
                        },
                      ]
                    : []),
                  ...(nomenclatures.length > 0
                    ? [
                        {
                          style: {
                            display: 'inline-block',
                            width: `${(this.amountColWidth + this.marginColWidth) * nomenclatures.length}mm`,
                            border: '1px solid black',
                            'background-color': 'rgb(255, 215, 0)',
                            'text-align': 'center',
                            height: `${this.firstLineHeight}mm`,
                          },
                          html: `Objectif ${this.year} par famille de produits`,
                        },
                      ]
                    : []),
                  ...(nPageNum === nByPage.length - 1
                    ? [
                        {
                          style: {
                            display: 'inline-block',
                            width: `${this.totalTotalWidth}mm`,
                            border: '1px solid black',
                            'background-color': 'rgb(255, 215, 0)',
                            'text-align': 'center',
                            height: `${this.firstLineHeight}mm`,
                          },
                          html: `TOTAUX`,
                        },
                      ]
                    : []),
                ],
              },
              {
                style: {
                  width: `${
                    this.categoryColWidth +
                    this.customerColWidth +
                    (nPageNum === 0 ? this.lastYearTotalWidth : 0) +
                    (nPageNum === nByPage.length - 1 ? this.totalTotalWidth : 0) +
                    (this.amountColWidth + this.marginColWidth) * nomenclatures.length
                  }mm`,
                  display: 'flex',
                  'flex-direction': 'row',
                  'flex-wrap': 'no-wrap',
                },
                children: [
                  {
                    style: {
                      display: 'inline-block',
                      width: `${this.categoryColWidth}mm`,
                      border: '1px solid black',
                      'background-color': 'rgb(255, 215, 0)',
                      height: `${this.secondLineHeight}mm`,
                      'text-align': 'center',
                    },
                    html: 'Catég.',
                  },
                  {
                    style: {
                      display: 'inline-block',
                      width: `${this.customerColWidth}mm`,
                      border: '1px solid black',
                      'background-color': 'rgb(255, 215, 0)',
                      'text-align': 'center',
                      height: `${this.secondLineHeight}mm`,
                    },
                    html: 'Nom',
                  },

                  ...(nPageNum === 0
                    ? [
                        {
                          style: {
                            display: 'inline-block',
                            width: `${this.lastYearColWidth}mm`,
                            border: '1px solid black',
                            'background-color': 'rgb(255, 215, 0)',
                            'text-align': 'center',
                            height: `${this.secondLineHeight}mm`,
                          },
                          html: 'CA\nréel',
                        },
                        {
                          style: {
                            display: 'inline-block',
                            width: `${this.lastYearColWidth}mm`,
                            border: '1px solid black',
                            'background-color': 'rgb(255, 215, 0)',
                            'text-align': 'center',
                            height: `${this.secondLineHeight}mm`,
                          },
                          html: 'CA\nestimé',
                        },
                        {
                          style: {
                            display: 'inline-block',
                            width: `${this.lastYearColWidth}mm`,
                            border: '1px solid black',
                            'background-color': 'rgb(255, 215, 0)',
                            'text-align': 'center',
                            height: `${this.secondLineHeight}mm`,
                          },
                          html: 'Marge\n(€)',
                        },
                        {
                          style: {
                            display: 'inline-block',
                            width: `${this.lastYearColWidth}mm`,
                            border: '1px solid black',
                            'background-color': 'rgb(255, 215, 0)',
                            'text-align': 'center',
                            height: `${this.secondLineHeight}mm`,
                            'white-space': 'pre',
                          },
                          html: 'Taux de\nmarque\n(%)',
                        },
                      ]
                    : []),

                  ...nomenclatures
                    .map((n) => [
                      {
                        style: {
                          display: 'inline-block',
                          width: `${this.amountColWidth}mm`,
                          border: '1px solid black',
                          'background-color': 'rgb(255, 215, 0)',
                          'text-align': 'center',
                          height: `${this.secondLineHeight}mm`,
                        },
                        html: n.name,
                      },
                      {
                        style: {
                          display: 'inline-block',
                          width: `${this.marginColWidth}mm`,
                          border: '1px solid black',
                          'background-color': 'rgb(255, 215, 0)',
                          'text-align': 'center',
                          height: `${this.secondLineHeight}mm`,
                          'white-space': 'pre',
                        },
                        html: 'Taux de\r\nmarque\r\n(%)',
                      },
                    ])
                    .reduce((prev, cur) => [...prev, ...cur], []),

                  ...(nPageNum === nByPage.length - 1
                    ? [
                        {
                          style: {
                            display: 'inline-block',
                            width: `${this.lastYearColWidth}mm`,
                            border: '1px solid black',
                            'background-color': 'rgb(255, 215, 0)',
                            'text-align': 'center',
                            height: `${this.secondLineHeight}mm`,
                          },
                          html: 'Total',
                        },
                        {
                          style: {
                            display: 'inline-block',
                            width: `${this.lastYearColWidth}mm`,
                            border: '1px solid black',
                            'background-color': 'rgb(255, 215, 0)',
                            'text-align': 'center',
                            height: `${this.secondLineHeight}mm`,
                          },
                          html: 'Pourcentage portefeuille',
                        },
                        {
                          style: {
                            display: 'inline-block',
                            width: `${this.lastYearColWidth}mm`,
                            border: '1px solid black',
                            'background-color': 'rgb(255, 215, 0)',
                            'text-align': 'center',
                            height: `${this.secondLineHeight}mm`,
                          },
                          html: 'Taux de marque (%)',
                        },
                        {
                          style: {
                            display: 'inline-block',
                            width: `${this.lastYearColWidth}mm`,
                            border: '1px solid black',
                            'background-color': 'rgb(255, 215, 0)',
                            'text-align': 'center',
                            height: `${this.secondLineHeight}mm`,
                          },
                          html: 'Marge nette',
                        },
                        {
                          style: {
                            display: 'inline-block',
                            width: `${this.lastYearColWidth}mm`,
                            border: '1px solid black',
                            'background-color': 'rgb(255, 215, 0)',
                            'text-align': 'center',
                            height: `${this.secondLineHeight}mm`,
                          },
                          html: `Revenus bruts représentants (${this.merchant.margin}%)`,
                        },
                      ]
                    : []),
                ],
              },
            ],

            bodyElements: goals.map((goal) =>
              !!goal
                ? {
                    style: {
                      width: `${
                        this.categoryColWidth +
                        this.customerColWidth +
                        (nPageNum === 0 ? this.lastYearTotalWidth : 0) +
                        (nPageNum === nByPage.length - 1 ? this.totalTotalWidth : 0) +
                        (this.amountColWidth + this.marginColWidth) * nomenclatures.length
                      }mm`,
                      display: 'flex',
                      'flex-direction': 'row',
                      'flex-wrap': 'no-wrap',
                    },
                    children: [
                      {
                        style: {
                          display: 'inline-block',
                          width: `${this.categoryColWidth}mm`,
                          border: '1px solid black',
                          height: `${this.bodyLineHeight}mm`,
                          'padding-left': '0.5mm',
                          'padding-right': '0.5mm',
                          overflow: 'hidden',
                          'background-color': 'rgb(255,255,255)',
                        },
                        html: goal.customer.categoryName,
                      },
                      {
                        style: {
                          display: 'inline-block',
                          width: `${this.customerColWidth}mm`,
                          border: '1px solid black',
                          height: `${this.bodyLineHeight}mm`,
                          'padding-left': '0.5mm',
                          'padding-right': '0.5mm',
                          overflow: 'hidden',
                          'background-color': 'rgb(255,255,255)',
                          'text-overflow': 'ellipsis',
                          'white-space': 'nowrap',
                        },
                        html: goal.customer.name,
                      },

                      ...(nPageNum === 0
                        ? [
                            {
                              style: {
                                display: 'inline-block',
                                width: `${this.lastYearColWidth}mm`,
                                border: '1px solid black',
                                height: `${this.bodyLineHeight}mm`,
                                'padding-left': '0.5mm',
                                'padding-right': '0.5mm',
                                'text-align': 'right',
                                overflow: 'hidden',
                                'background-color': 'rgb(215,215,215)',
                              },
                              html: NumberUtil.formatMoney(goal['history_balance'], '€', 2),
                            },
                            {
                              style: {
                                display: 'inline-block',
                                width: `${this.lastYearColWidth}mm`,
                                border: '1px solid black',
                                height: `${this.bodyLineHeight}mm`,
                                'padding-left': '0.5mm',
                                'padding-right': '0.5mm',
                                'text-align': 'right',
                                overflow: 'hidden',
                                'background-color': 'rgb(215,215,215)',
                              },
                              html: NumberUtil.formatMoney(goal['history_goals'], '€', 2),
                            },
                            {
                              style: {
                                display: 'inline-block',
                                width: `${this.lastYearColWidth}mm`,
                                border: '1px solid black',
                                height: `${this.bodyLineHeight}mm`,
                                'padding-left': '0.5mm',
                                'padding-right': '0.5mm',
                                'text-align': 'right',
                                overflow: 'hidden',
                                'background-color': 'rgb(215,215,215)',
                              },
                              html: NumberUtil.formatMoney(goal['history_margin'], '€', 2),
                            },
                            {
                              style: {
                                display: 'inline-block',
                                width: `${this.lastYearColWidth}mm`,
                                border: '1px solid black',
                                height: `${this.bodyLineHeight}mm`,
                                'padding-left': '0.5mm',
                                'padding-right': '0.5mm',
                                'text-align': 'right',
                                overflow: 'hidden',
                                'background-color': 'rgb(215,215,215)',
                                'white-space': 'pre',
                              },
                              html: NumberUtil.formatMoney(goal['history_markup_perc'], '€', 2),
                            },
                          ]
                        : []),

                      ...nomenclatures
                        .map((n) => [
                          {
                            style: {
                              display: 'inline-block',
                              width: `${this.amountColWidth}mm`,
                              border: '1px solid black',
                              height: `${this.bodyLineHeight}mm`,
                              'padding-left': '0.5mm',
                              'padding-right': '0.5mm',
                              'text-align': 'right',
                              overflow: 'hidden',
                              'background-color': 'rgb(255,255,255)',
                            },
                            html: NumberUtil.formatMoney(goal[`amount_${n.fullId}`], '€', 2),
                          },
                          {
                            style: {
                              display: 'inline-block',
                              width: `${this.marginColWidth}mm`,
                              border: '1px solid black',
                              height: `${this.bodyLineHeight}mm`,
                              'padding-left': '0.5mm',
                              'padding-right': '0.5mm',
                              'text-align': 'right',
                              overflow: 'hidden',
                              'background-color': 'rgb(255,255,255)',
                            },
                            html: NumberUtil.formatMoney(goal[`margin_${n.fullId}`] || 0, '%', 2),
                          },
                        ])
                        .reduce((prev, cur) => [...prev, ...cur], []),

                      ...(nPageNum === nByPage.length - 1
                        ? [
                            {
                              style: {
                                display: 'inline-block',
                                width: `${this.totalColWidth}mm`,
                                border: '1px solid black',
                                height: `${this.bodyLineHeight}mm`,
                                'padding-left': '0.5mm',
                                'padding-right': '0.5mm',
                                'text-align': 'right',
                                overflow: 'hidden',
                                'background-color': 'rgb(215,215,215)',
                              },
                              html: NumberUtil.formatMoney(goal['total'], '€', 2),
                            },
                            {
                              style: {
                                display: 'inline-block',
                                width: `${this.totalColWidth}mm`,
                                border: '1px solid black',
                                height: `${this.bodyLineHeight}mm`,
                                'padding-left': '0.5mm',
                                'padding-right': '0.5mm',
                                'text-align': 'right',
                                overflow: 'hidden',
                                'background-color': 'rgb(215,215,215)',
                              },
                              html: NumberUtil.formatMoney(goal['total_perc'], '%', 2),
                            },
                            {
                              style: {
                                display: 'inline-block',
                                width: `${this.totalColWidth}mm`,
                                border: '1px solid black',
                                height: `${this.bodyLineHeight}mm`,
                                'padding-left': '0.5mm',
                                'padding-right': '0.5mm',
                                'text-align': 'right',
                                overflow: 'hidden',
                                'background-color': 'rgb(215,215,215)',
                              },
                              html: NumberUtil.formatMoney(goal['margin'], '%', 2),
                            },
                            {
                              style: {
                                display: 'inline-block',
                                width: `${this.totalColWidth}mm`,
                                border: '1px solid black',
                                height: `${this.bodyLineHeight}mm`,
                                'padding-left': '0.5mm',
                                'padding-right': '0.5mm',
                                'text-align': 'right',
                                overflow: 'hidden',
                                'background-color': 'rgb(215,215,215)',
                              },
                              html: NumberUtil.formatMoney(goal['total_margin'], '€', 2),
                            },
                            {
                              style: {
                                display: 'inline-block',
                                width: `${this.totalColWidth}mm`,
                                border: '1px solid black',
                                height: `${this.bodyLineHeight}mm`,
                                'padding-left': '0.5mm',
                                'padding-right': '0.5mm',
                                'text-align': 'right',
                                overflow: 'hidden',
                                'background-color': 'rgb(215,215,215)',
                              },
                              html: NumberUtil.formatMoney(goal['total_merchant'], '€', 2),
                            },
                          ]
                        : []),
                    ],
                  }
                : {
                    style: {
                      width: `${
                        this.categoryColWidth +
                        this.customerColWidth +
                        (nPageNum === 0 ? this.lastYearTotalWidth : 0) +
                        (nPageNum === nByPage.length - 1 ? this.totalTotalWidth : 0) +
                        (this.amountColWidth + this.marginColWidth) * nomenclatures.length
                      }mm`,
                      display: 'flex',
                      'flex-direction': 'row',
                      'flex-wrap': 'no-wrap',
                    },
                    children: [
                      {
                        style: {
                          display: 'inline-block',
                          width: `${this.categoryColWidth + this.customerColWidth}mm`,
                          border: '1px solid black',
                          height: `${this.bodyLineHeight}mm`,
                          'padding-left': '0.5mm',
                          'padding-right': '0.5mm',
                          overflow: 'hidden',
                          'background-color': 'rgb(255, 215, 0)',
                          'text-overflow': 'ellipsis',
                          'white-space': 'nowrap',
                          'text-align': 'right',
                        },
                        html: 'TOTAUX',
                      },

                      ...(nPageNum === 0
                        ? [
                            {
                              style: {
                                display: 'inline-block',
                                width: `${this.lastYearColWidth}mm`,
                                border: '1px solid black',
                                height: `${this.bodyLineHeight}mm`,
                                'padding-left': '0.5mm',
                                'padding-right': '0.5mm',
                                'text-align': 'right',
                                overflow: 'hidden',
                                'background-color': 'rgb(255, 215, 0)',
                              },
                              html: NumberUtil.formatMoney(this.subtotal('history_balance'), '€', 2),
                            },
                            {
                              style: {
                                display: 'inline-block',
                                width: `${this.lastYearColWidth}mm`,
                                border: '1px solid black',
                                height: `${this.bodyLineHeight}mm`,
                                'padding-left': '0.5mm',
                                'padding-right': '0.5mm',
                                'text-align': 'right',
                                overflow: 'hidden',
                                'background-color': 'rgb(255, 215, 0)',
                              },
                              html: NumberUtil.formatMoney(this.subtotal('history_goals'), '€', 2),
                            },
                            {
                              style: {
                                display: 'inline-block',
                                width: `${this.lastYearColWidth}mm`,
                                border: '1px solid black',
                                height: `${this.bodyLineHeight}mm`,
                                'padding-left': '0.5mm',
                                'padding-right': '0.5mm',
                                'text-align': 'right',
                                overflow: 'hidden',
                                'background-color': 'rgb(255, 215, 0)',
                              },
                              html: NumberUtil.formatMoney(this.subtotal('history_margin'), '€', 2),
                            },
                            {
                              style: {
                                display: 'inline-block',
                                width: `${this.lastYearColWidth}mm`,
                                border: '1px solid black',
                                height: `${this.bodyLineHeight}mm`,
                                'padding-left': '0.5mm',
                                'padding-right': '0.5mm',
                                'text-align': 'right',
                                overflow: 'hidden',
                                'background-color': 'rgb(255, 215, 0)',
                                'white-space': 'pre',
                              },
                              html: NumberUtil.formatMoney(this.subtotal('history_markup_perc'), '€', 2),
                            },
                          ]
                        : []),

                      ...nomenclatures
                        .map((n) => [
                          {
                            style: {
                              display: 'inline-block',
                              width: `${this.amountColWidth}mm`,
                              border: '1px solid black',
                              height: `${this.bodyLineHeight}mm`,
                              'padding-left': '0.5mm',
                              'padding-right': '0.5mm',
                              'text-align': 'right',
                              overflow: 'hidden',
                              'background-color': 'rgb(255, 215, 0)',
                            },
                            html: NumberUtil.formatMoney(this.subtotal(`amount_${n.fullId}`), '€', 2),
                          },
                          {
                            style: {
                              display: 'inline-block',
                              width: `${this.marginColWidth}mm`,
                              border: '1px solid black',
                              height: `${this.bodyLineHeight}mm`,
                              'padding-left': '0.5mm',
                              'padding-right': '0.5mm',
                              'text-align': 'right',
                              overflow: 'hidden',
                              'background-color': 'rgb(255, 215, 0)',
                            },
                            html: NumberUtil.formatMoney(
                              this.subtotal(`marginamount_${n.fullId}`) / this.subtotal(`amount_${n.fullId}`),
                              '%',
                              2
                            ),
                          },
                        ])
                        .reduce((prev, cur) => [...prev, ...cur], []),

                      ...(nPageNum === nByPage.length - 1
                        ? [
                            {
                              style: {
                                display: 'inline-block',
                                width: `${this.totalColWidth}mm`,
                                border: '1px solid black',
                                height: `${this.bodyLineHeight}mm`,
                                'padding-left': '0.5mm',
                                'padding-right': '0.5mm',
                                'text-align': 'right',
                                overflow: 'hidden',
                                'background-color': 'rgb(255, 215, 0)',
                              },
                              html: NumberUtil.formatMoney(this.subtotal('total'), '€', 2),
                            },
                            {
                              style: {
                                display: 'inline-block',
                                width: `${this.totalColWidth}mm`,
                                border: '1px solid black',
                                height: `${this.bodyLineHeight}mm`,
                                'padding-left': '0.5mm',
                                'padding-right': '0.5mm',
                                'text-align': 'right',
                                overflow: 'hidden',
                                'background-color': 'rgb(255, 215, 0)',
                              },
                              html: NumberUtil.formatMoney(this.subtotal('total_perc'), '%', 2),
                            },
                            {
                              style: {
                                display: 'inline-block',
                                width: `${this.totalColWidth}mm`,
                                border: '1px solid black',
                                height: `${this.bodyLineHeight}mm`,
                                'padding-left': '0.5mm',
                                'padding-right': '0.5mm',
                                'text-align': 'right',
                                overflow: 'hidden',
                                'background-color': 'rgb(255, 215, 0)',
                              },
                              html: NumberUtil.formatMoney(
                                (100 * (this.subtotal('total_margin') + this.subtotal('total_merchant'))) /
                                  this.subtotal('total'),
                                '%',
                                2
                              ),
                            },
                            {
                              style: {
                                display: 'inline-block',
                                width: `${this.totalColWidth}mm`,
                                border: '1px solid black',
                                height: `${this.bodyLineHeight}mm`,
                                'padding-left': '0.5mm',
                                'padding-right': '0.5mm',
                                'text-align': 'right',
                                overflow: 'hidden',
                                'background-color': 'rgb(255, 215, 0)',
                              },
                              html: NumberUtil.formatMoney(this.subtotal('total_margin'), '€', 2),
                            },
                            {
                              style: {
                                display: 'inline-block',
                                width: `${this.totalColWidth}mm`,
                                border: '1px solid black',
                                height: `${this.bodyLineHeight}mm`,
                                'padding-left': '0.5mm',
                                'padding-right': '0.5mm',
                                'text-align': 'right',
                                overflow: 'hidden',
                                'background-color': 'rgb(255, 215, 0)',
                              },
                              html: NumberUtil.formatMoney(this.subtotal('total_merchant'), '€', 2),
                            },
                          ]
                        : []),
                    ],
                  }
            ),

            footerSize: 0,
            footerElements: [],
          }))
        );
      });
    }
    return this;
  }
}
