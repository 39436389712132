import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components/components.module';
import { ChargesListViewComponent } from './views/charges-list-view/charges-list-view.component';
import { ModuleBase } from '../module-base.class';
import { ClassManager } from 'src/app/classes/objects/ClassManager.class';
import { ChargeType } from './classes/ChargeType.class';
import { Charge } from './classes/Charge.class';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { ChargeViewComponent } from './views/charge-view/charge-view.component';
import { ReportViewComponent } from '../reports/views/report-view/report-view.component';
import { ChargesReportModel } from './classes/ChargesReportModel.class';
import { ReportsModule } from '../reports/reports.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ComponentsModule,
        ReportsModule
    ],
    declarations: [
        ChargesListViewComponent,
        ChargeViewComponent
    ],
    exports: [
        ChargesListViewComponent,
        ChargeViewComponent
    ],
    entryComponents: [
        ChargesListViewComponent,
        ChargeViewComponent
    ]
})
export class ChargesModule extends ModuleBase {

    public static moduleName = 'charges';

    public ngOnInit()
    {
        ClassManager.registerClass('Charge', Charge);
        ClassManager.registerClass('ChargeType', ChargeType);

        HomepageViewComponent.registerGroups({
            'payments': {
                'name': "Paiements",
                'permission': 'home_groupe_paiements',
                'children': [
                    {
                        'backColor': 'rgb(191, 191, 191)',
                        'textColor': 'black',
                        'name': "Charges",
                        'permission': 'home_bouton_paiements_charges',
                        'component': ChargesListViewComponent
                    },
                    {
                        'backColor': 'rgb(191, 191, 191)',
                        'textColor': 'black',
                        'name': "Consolidation des charges",
                        'component': ReportViewComponent,
                        'permission': 'home_bouton_paiements_consolidation',
                        'getArgs': () => [ { 'charges': ChargesReportModel.generate() } ]
                    }
                ]
            }
        });

        return super.ngOnInit();
    }

}

