import { ObjectModel2 } from "../../../classes/objects/ObjectModel2.class";
import { ObjectDefinition } from "../../../classes/objects/ObjectDefinition.class";
import { Page } from "./Page.class";
import { InfoBlock } from "../../../components/info-block/classes/InfoBlock.class";
import { ObjectModel3 } from "src/app/classes/objects/ObjectModel3.class";

export class PrintingModel extends ObjectModel3
{
    public name: string = null;
    public html: string = null;

    public saveFilename: string = 'document.pdf';
    public mailFilename: string = 'attachment.pdf';
    public saveFilter: string = 'Documents PDF (*.pdf)|*.pdf|Tous les fichiers (*.*)|*.*';
    public openExtension: string = 'pdf';

    public pages: Page[] = [];
    public optionsBlock: InfoBlock = null;

    constructor() {
        super(PrintingModel);
    }

    /* ObjectModel FUNCTIONS */

    public static definition: ObjectDefinition = {
        database: {
            table: 'printing_models',
            id: 'id',
			db_id: 'db_id'
        },
        values: {
            'name': { type: 'string' },
            'html': { type: 'html' }
        },
        children: {},
        links: {}
    };
}
