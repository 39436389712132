<div *ngFor="let item of items" [ngClass]="{'sub-directory':level>0}" droppable [dropScope]="'data-tree-item'"
     (onDragEnter)="onDragEnter($event)" (onDrop)="onDrop(item, $event)">
    <div class="tree-directory" (click)="itemClick.next({'event':$event,'item':item})" [ngClass]="{'selected': selectedObjects.includes(item)}"
         draggable [dragScope]="'data-tree-item'" [dragData]="item">
        <span [ngStyle]="{'visibility': (item.children && item.children.length > 0) || (item.files && item.files.length > 0) ? 'visible' : 'hidden'}" class="data-tree-expand" (click)="expandClick($event, item)">{{ item.expanded ? '-' : '+' }}</span>
        <img class="folder-icon" [src]="getImage(item)" (click)="iconClick($event, item)" />
        <!-- <span *ngIf="!item.editing" class="data-tree-name" [attr.title]="item.name" (click)="nameClick($event, item)">{{ item[nameField] }}</span> -->
        <span *ngIf="!item.editing" class="data-tree-name" [attr.title]="item.name" (click)="iconClick($event, item)">{{ item[nameField] }}</span>
        <!-- <input #nameInput type="text" *ngIf="item.editing" [(ngModel)]="item.name" (blur)="nameBlur(event, item)" (keydown)="nameKeyDown($event, item)" /> -->
    </div>
    <data-tree-directory [tree]="tree" [nameField]="nameField" [level]="level+1" *ngIf="item.expanded" #treeChildren [items]="item.children" [selectedObjects]="selectedObjects" (itemClick)="itemClick.next($event)" (itemRenamed)="onItemRenamed($event)"></data-tree-directory>
    <div *ngIf="item.files && item.expanded">
        <data-tree-file [nameField]="nameField" [level]="level+1" #treeFiles *ngFor="let file of item.files" [item]="file" [selectedObjects]="selectedObjects" (itemClick)="itemClick.next($event)"></data-tree-file>
    </div>
</div>