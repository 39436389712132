import { Component, ViewChild } from '@angular/core';

import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { Address } from 'src/app/modules/addresses/classes/Address.class';
import { CredentialsService } from 'src/app/services/credentials/credentials.service';
import { CurrenciesService } from 'src/app/services/currencies/currencies.service';
import { Customer } from 'src/app/modules/customers/classes/Customer.class';
import { DateTimeUtil } from 'src/app/modules/utils/classes/DateTimeUtil.class';
import { Deadline } from '../../../other-data/classes/Deadline.class';
import { Delivery } from 'src/app/modules/other-data/classes/Delivery.class';
import { DeliveryNotePrintingModel } from 'src/app/modules/orders/classes/DeliveryNotePrintingModel.class';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { Discount } from 'src/app/modules/other-data/classes/Discount.class';
import { ExternalService } from 'src/app/services/external/external.service';
import { FilesystemUtil } from 'src/app/modules/utils/classes/FilesystemUtil.class';
import { Form } from 'src/app/classes/forms/Form.class';
import { FormValidator } from 'src/app/classes/forms/FormValidator.class';
import { InfoBlock } from 'src/app/components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from 'src/app/components/info-block/info-block.component';
import { InfoBlockField } from 'src/app/components/info-block/classes/InfoBlockField.class';
import { Invoice } from 'src/app/modules/invoicing/classes/Invoice.class';
import { InvoicePrintingModel } from 'src/app/modules/invoicing/classes/InvoicePrintingModel.class';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { Merchant } from 'src/app/modules/other-data/classes/Merchant.class';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { PrintPreviewViewComponent } from 'src/app/modules/printing/views/print-preview-view/print-preview-view.component';
import { PrintingModel } from 'src/app/modules/printing/classes/PrintingModel.class';
import { ServerApi } from 'src/app/classes/api/ServerApi.class';
import { SettingsComponent } from 'src/app/components/settings/settings.component';
import { StickersPrintingModel } from 'src/app/modules/orders/classes/StickersPrintingModel.class';
import { Stock } from '../../classes/Stock.class';
import { StockMove } from '../../classes/StockMove.class';
import { StockMovePrintingModel } from '../../classes/StockMovePrintingModel.class';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from 'src/app/components/views/views.component';
import { config } from '../../../../classes/config';

@Component({
  selector: 'app-stock-move-view',
  templateUrl: './stock-move-view.component.html',
  styleUrls: ['./stock-move-view.component.css'],
})
export class StockMoveViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_stocks_ficheappel';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Appel sur stock',
    data: this,
    elements: [
      { type: 'separator' },
      // {
      //     type: 'button',
      //     name: 'saveButton',
      //     text: 'Enregistrer',
      //     icon: 'save',
      //     click: function(view: StockMoveViewComponent) {
      //         view.save().then((result) => {
      //             //view.updateView(view.demandCopy);
      //         });
      //     },
      //     options: { visible: true }
      // },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Aperçu appel<br/>sur stock',
        icon: 'search',
        click: function (view: StockMoveViewComponent) {
          if (view.validateForm() == true) {
            ViewsComponent.openView(PrintPreviewViewComponent, StockMovePrintingModel, view.moveCopy);
          }
        },
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Aperçu<br/>facture',
        icon: 'search',
        click: function (view: StockMoveViewComponent) {
          if (view.validateForm() == true) {
            ViewsComponent.openView(PrintPreviewViewComponent, InvoicePrintingModel, view.moveCopy.invoice);
          }
        },
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Aperçu bon<br/>de livraison',
        icon: 'search',
        click: function (view: StockMoveViewComponent) {
          if (view.validateForm() == true) {
            ViewsComponent.openView(PrintPreviewViewComponent, DeliveryNotePrintingModel, view.moveCopy);
          }
        },
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Aperçu<br/>étiquettes',
        icon: 'search',
        click: function (view: StockMoveViewComponent) {
          if (view.validateForm() == true) {
            ViewsComponent.openView(PrintPreviewViewComponent, StickersPrintingModel, view.moveCopy);
          }
        },
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Enregistrer<br/>(ERP + PDF)',
        icon: 'save',
        click: function (view: StockMoveViewComponent) {
          view.save().then((result) => {
            view.updateView(view.moveCopy);
            view.saveFiles();
          });
        },
        access: this.writeAccess,
      },
      // {
      //     type: 'button',
      //     text: 'Enregistrer<br/>les documents',
      //     icon: 'save',
      //     click: function(view: StockMoveViewComponent) {
      //         view.saveFiles();
      //     }
      // },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Envoyer au<br/>fournisseur',
        icon: ' fas fa-at',
        click: function (view: StockMoveViewComponent) {
          view.sendToSupplier();
        },
      },
      { type: 'separator' },
      {
        name: 'sendToCustomerButton',
        type: 'button',
        text: 'Envoyer<br/>au client',
        icon: ' fas fa-at',
        click: function (view: StockMoveViewComponent) {
          view.sendToCustomer();
        },
        disabled: false,
      },
      { type: 'spacing' },
    ],
  };

  public move: StockMove = null;
  public moveCopy: StockMove = null;

  public generate_invoice: boolean = true;

  public selectedCustomer: Customer = null;
  public selectedCustomerLoaded: boolean = false;
  public selectedStock: Stock = null;
  public selectedStockLoaded: boolean = false;

  public contactsData: any = { items: [] };
  public customersData: any = { items: [] };
  public discountsData: any = { items: [] };
  public stocksData: any = { items: [] };
  public merchantsData: any = { items: [] };
  public deliveriesData: any = { items: [] };
  public accountingsData: any = { items: [] };
  public currenciesData: any = { items: [] };
  public deadlineData: any = { items: [] };

  @ViewChild('selectBlockComponent') selectBlockComponent;
  public selectBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(0,0,0)',
    textColor: 'white',
    fields: [
      {
        title: 'Choix du client',
        field: 'selectedCustomer',
        type: 'searchable-foreign-list',
        multiSelect: false,
        listItems: this.customersData,
        listField: 'nameWithIdentifier',
        nullText: '(Aucun)',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          console.log('changed!');
          this.selectedCustomerLoaded = false;
          this.updateView(this.moveCopy);
        },
      },
      {
        title: "Choix de l'article",
        field: 'selectedStock',
        type: 'searchable-foreign-list',
        multiSelect: false,
        listItems: this.stocksData,
        listField: 'numberNameAndDate',
        nullText: '(Aucun)',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.selectedStockLoaded = false;
          this.updateView(this.moveCopy);
        },
      },
    ],
  };

  public quantityField: InfoBlockField = {
    title: 'Quantité appelée',
    field: 'quantity',
    type: 'number',
    numberUnit: '',
    textAlign: 'left',
    decimalsCount: 1,
    change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
      this.updateView(this.moveCopy);
    },
  };
  public availableQuantityField: InfoBlockField = {
    title: 'Quantité disponible',
    field: 'stock.stockAndUnit',
    type: 'text',
    readonly: true,
    visible: false,
  };
  public numberField: InfoBlockField = {
    title: "N° d'appel",
    field: 'number',
    type: 'number',
    textAlign: 'left',
    decimalsCount: 0,
    inputSize: 4,
    change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
      this.updateView(this.moveCopy);
    },
  };
  @ViewChild('moveBlockComponent') moveBlockComponent;
  public moveBlock: InfoBlock = {
    title: 'Appel',
    backColor: 'rgb(60, 95, 189)',
    textColor: 'white',
    fields: [
      {
        title: 'Date',
        type: 'date',
        field: 'date',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.moveCopy);
        },
      },
      this.availableQuantityField,
      this.quantityField,
      {
        title: 'Frais de gestion',
        field: 'converted_internal_fees',
        type: 'number',
        decimalsCount: 2,
        currencyField: 'sell_currency',
        textAlign: 'left',
      },
      {
        title: 'Prix de vente',
        field: 'converted_sell_price',
        type: 'number',
        decimalsCount: 5,
        currencyField: 'sell_currency',
        textAlign: 'left',
      },
      this.numberField,
      { title: 'N° du bon de livraison', field: 'delivery_number', type: 'text', readonly: true },
      {
        title: 'Nombre de boites livrées',
        field: 'delivered_quantity',
        type: 'text',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.moveCopy);
        },
      },
      {
        title: 'Date de livraison prévue',
        field: 'delivery_date',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.moveCopy);
        },
      },
      {
        title: 'N° de commande (appel) du client',
        field: 'customer_reference',
        type: 'text',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.moveCopy);
        },
      },
      {
        title: 'Escompte accordé',
        field: 'discount',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.discountsData,
        listField: 'name',
        nullText: '(Aucun)',
        allowBlankValues: true,
      },
    ],
  };

  @ViewChild('followupBlockComponent') followupBlockComponent;
  public followupBlock: InfoBlock = {
    title: 'Suivi pour\nles clients',
    backColor: 'rgb(30, 50, 90)',
    textColor: 'white',
    fields: [
      { title: 'Date de livraison prévue', type: 'date', field: 'cfollowup_estimated_delivery' },
      {
        title: 'Quantité livrée',
        field: 'cfollowup_delivered_quantity',
        type: 'number',
        decimalsCount: 2,
        textAlign: 'left',
      },
      { title: 'Date de livraison', type: 'date', field: 'cfollowup_real_delivery_date' },
      { title: 'Remarques', type: 'text', field: 'cfollowup_remarks' },
    ],
  };

  public generateInvoiceBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(20, 35, 109)',
    textColor: 'white',
    fields: [
      {
        title: 'Générer la facture',
        field: 'generate_invoice',
        type: 'checkbox',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.moveCopy);
        },
      },
    ],
  };

  public invoiceBlock: InfoBlock = {
    title: 'Facture',
    backColor: 'rgb(20, 35, 109)',
    textColor: 'white',
    fields: [
      {
        title: 'Représentant',
        field: 'merchant',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.merchantsData,
        listField: 'numberAndName',
        nullText: '(Aucun)',
      },
      { title: 'N° de la facture', field: 'number', type: 'text', readonly: true },
      {
        title: 'Taux TVA',
        field: 'vat',
        type: 'number',
        decimalsCount: 1,
        numberUnit: '%',
        textAlign: 'left',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.moveCopy);
        },
      },
      {
        title: 'Texte de livraison',
        field: 'delivery',
        type: 'combo-box',
        disableFiltering: true,
        listItems: this.deliveriesData,
        listField: 'description',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.moveCopy);
        },
      },
      {
        title: 'Date de livraison',
        field: 'delivery_date',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.moveCopy);
        },
      },
      {
        title: 'Conditions de paiement',
        field: 'deadline',
        type: 'combo-box',
        readonly: false,
        multiSelect: false,
        listItems: this.deadlineData,
        listField: 'name',
        nullText: '(Aucun)',
        disableFiltering: true,
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.moveCopy);
        },
      },
    ],
  };
  public totalsBlock: InfoBlock = {
    title: 'Totaux',
    backColor: 'rgb(8, 19, 72)',
    textColor: 'white',
    fields: [
      {
        title: 'Montant facture client',
        field: 'converted_totalSellAmount',
        type: 'number',
        textAlign: 'left',
        decimalsCount: 2,
        currencyField: 'sell_currency',
        readonly: true,
      },
      {
        title: 'Montant facture fournisseur',
        field: 'converted_totalBuyAmount',
        type: 'number',
        textAlign: 'left',
        decimalsCount: 2,
        currencyField: 'buy_currency',
        readonly: true,
      },
      {
        title: 'Marge bénéficiaire',
        field: 'totalProfitAmount',
        type: 'number',
        textAlign: 'left',
        decimalsCount: 2,
        numberUnit: ' € H.T.V.A.',
        readonly: true,
      },
    ],
  };

  private deliveryAddressInfoBlockField: InfoBlockField = {
    title: 'Adresse',
    field: 'delivery_address_text',
    type: 'address-text',
    listItems: { items: [] },
    listField: 'title',
    nullText: '(Aucun)',
    addressFunction: (addr: Address) => {
      return addr.getPostalAddress();
    },
    textChange: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
      this.moveCopy.changed = true;
    },
    addressChange: (
      component: InfoBlockComponent,
      block: InfoBlock,
      field: InfoBlockField,
      object: any,
      addr: Address
    ) => {
      this.moveCopy.delivery_address = addr;
      if (addr) {
        this.moveCopy.delivery_address_text = addr.getPostalAddress();
        this.moveCopy.opening_hours = addr.opening_hours;
        if (addr.name1 || addr.name2) {
          this.moveCopy.delivery_contact = addr.contactName;
        }
      }
      this.moveCopy.changed = true;
      this.updateView(this.moveCopy);
    },
    comment:
      "Sélectionnez ici l'adresse de livraison (choix via l'icone ci-contre), ou entrez une adresse manuellement.<br/><br/>REMARQUE : Cela modifiera automatiquement le champ 'Service - Nom du contact' si des informations de service/contact sont présents dans l'adresse sélectionnée.",
  };
  public deliveryAddressBlock: InfoBlock = {
    title: 'Adresse\nde livraison',
    backColor: 'rgb(0,0,0)',
    textColor: 'white',
    fields: [
      {
        title: 'Service - Nom du contact',
        field: 'delivery_contact',
        type: 'combo-box',
        disableFiltering: true,
        listItems: this.contactsData,
        listField: 'addressContactLine',
        nullText: '(Aucun)',
        comment: 'Sélectionnez ou entrez ici le nom du contact, si besoin, pour la livraison.',
        select: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.moveCopy.changed = true;
        },
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.moveCopy.changed = true;
          this.updateView(this.moveCopy);
        },
      },
      this.deliveryAddressInfoBlockField,
      {
        title: "Heures d'ouverture",
        field: 'opening_hours',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.moveCopy);
        },
      },
      {
        title: 'Enlèvement par nos soins',
        field: 'self_delivery',
        type: 'checkbox',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.moveCopy);
        },
      },
    ],
  };

  public accountingBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(64,0,64)',
    textColor: 'white',
    fields: [
      {
        title: 'Comptabilité',
        field: 'accounting',
        type: 'foreign-list',
        readonly: true,
        multiSelect: false,
        listItems: this.accountingsData,
        listField: 'fullDescription',
        allowBlankValues: false,
        comment: 'Sélectionnez ici la comptabilité liée à ce dossier',
      },
      {
        title: "Devise d'achat",
        field: 'buy_currency',
        type: 'foreign-list',
        readonly: true,
        multiSelect: false,
        listItems: this.currenciesData,
        listField: 'fullDescription',
        allowBlankValues: false,
      },
      {
        title: "Taux de change d'achat",
        field: 'buy_xrate',
        type: 'number',
        decimalsCount: 6,
        textAlign: 'left',
        readonly: true,
      },
      {
        title: 'Devise de vente',
        field: 'sell_currency',
        type: 'foreign-list',
        readonly: true,
        multiSelect: false,
        listItems: this.currenciesData,
        listField: 'fullDescription',
        allowBlankValues: false,
      },
      {
        title: 'Taux de change de vente',
        field: 'sell_xrate',
        type: 'number',
        decimalsCount: 6,
        textAlign: 'left',
        readonly: true,
      },
    ],
  };

  loadCustomers(ids = null) {
    Customer.load(ids, ['name']).then(
      (result) => {
        this.customersData.items = result;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  loadStocks(ids = null) {
    Stock.load(ids).then(
      (result) => {
        this.selectedCustomerLoaded = true;
        if (result.length == 0)
          NotificationsComponent.push({
            type: 'warning',
            title: 'Aucun stock trouvé',
            summary: "Aucun article n'a été trouvé en stock pour le client spécifié.",
          });
        else if (result.length == 1) {
          this.selectedStock = result[0];
          this.selectedStockLoaded = false;
        }
        result = result
          .filter((item: Stock, index: number, arr: any[]) => {
            return item.availableStock > 0;
          })
          .sort((a: Stock, b: Stock) => {
            let name_compare: number = a.article.designation.localeCompare(b.article.designation);
            return name_compare == 0 ? a.creation_date.localeCompare(b.creation_date) : name_compare;
          });
        this.stocksData.items = result;
        if (!result.includes(this.selectedStock)) this.selectedStock = null;
        this.updateView(this.moveCopy);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  ngOnInit() {
    this.accountingsData.items = AccountingsService.accountings;
    this.currenciesData.items = CurrenciesService.currencies;
    Discount.load(null, ['name']).then(
      (result) => {
        this.discountsData.items = result;
      },
      (err) => {
        console.log(err);
      }
    );
    Delivery.load(null, ['description']).then(
      (result) => {
        this.deliveriesData.items = result;
      },
      (err) => {
        console.log(err);
      }
    );
    Merchant.load(null, ['number']).then(
      (result: Merchant[]) => {
        this.merchantsData.items = result;
      },
      (err) => {
        console.error('merchants loading error:', err);
      }
    );
    Deadline.load(null, ['name']).then(
      (result) => {
        this.deadlineData.items = result;
      },
      (err) => {
        console.log(err);
      }
    );
    this.reloadCustomers();
  }
  onAccountingChanged(accounting: Accounting) {
    this.reloadCustomers();
  }
  reloadCustomers() {
    ServerApi.callModule('stocks', 'customersHavingStock', {
      id_accounting: AccountingsService.currentAccounting ? AccountingsService.currentAccounting.fullId : null,
    }).then(
      (result) => {
        if (result.result === 'success') this.loadCustomers(result.details);
        else this.loadCustomers();
      },
      (err) => {
        this.loadCustomers();
      }
    );
  }

  initView(move: StockMove) {
    if (!move) {
      move = new StockMove();
      move.accounting = AccountingsService.currentAccounting;
    }
    this.move = move;
    this.moveCopy = move.clone(true);
    if (this.moveCopy.customer) {
      this.selectedCustomer = this.moveCopy.customer;
      this.selectedCustomerLoaded = true;
    }
    if (this.moveCopy.stock) {
      this.selectedStock = this.moveCopy.stock;
      this.selectedStockLoaded = true;
    }
    // this.selectedCustomer = this.moveCopy.;
    this.updateView(this.moveCopy);
  }

  updateView(move: StockMove) {
    console.log('stock move:', move);
    if (this.selectedCustomer instanceof Customer && !this.selectedCustomerLoaded && move.id == 0) {
      console.log('id_customer = ' + this.selectedCustomer.id + ' AND quantity > 0');
      this.stocksData.items = null;

      ServerApi.callModule('stocks', 'stocksByCustomer', { id_customer: this.selectedCustomer.fullId }).then(
        (result) => {
          if (result.result === 'success') this.loadStocks(result.details);
        },
        (err) => {
          console.error(err);
        }
      );
    }

    if (this.selectedStock instanceof Stock && !this.selectedStockLoaded && move.id == 0) {
      if (!move.date) move.date = DateTimeUtil.toDateString(new Date());
      if (this.selectedStock != move.stock) {
        move.quantity = Math.min(
          Math.ceil(this.selectedStock.quantity / this.selectedStock.order.deliveries),
          this.selectedStock.availableStock
        );
        move.stock = this.selectedStock;
        move.number = this.selectedStock.moves.length + 1;
        move.internal_fees = this.selectedStock.order.internal_fees / this.selectedStock.order.deliveries;
        //move.delivery_number = move.stock.order.delivery_number + '-' + move.number;
        move.sell_price = this.selectedStock.sell_price;
        move.delivery_address_text = move.stock.order.delivery_address_text;
        move.delivery_contact = move.stock.order.delivery_contact;
      }
    }

    if (move.stock != null) {
      if (this.generate_invoice !== true) {
        if (this.selectedPreview == 'invoice') this.selectedPreview = 'move';
        move.invoice = null;
      } else if (move.id == 0) {
        if (!move.invoice) move.invoice = new Invoice();
        let invoice: Invoice = move.invoice;
        move.invoice.accounting = move.accounting;
        invoice.order = move.stock.order;
        invoice.move = move;
        invoice.merchant = move.merchant;

        invoice.quantity = move.quantity;
        invoice.unit = move.order.unit;
        invoice.sell_price = move.sell_price;
        if (!invoice.vat) invoice.vat = move.customer.vat;
        invoice.delivery_number = move.delivery_number;

        invoice.date = move.date;
        invoice.deadline = move.stock.order.deadline;
        invoice.delivery_time = move.stock.order.delivery_time;
        invoice.discount = move.stock.order.discount;

        if (!invoice.number)
          invoice.getNextNumber().then((result) => {
            invoice.number = invoice.generateNumber(result.details);
            this.setPreview();
          });
        this.quantityField.numberUnit = move.stock.unit;
        this.numberField.numberUnit = '/ ' + move.order.deliveries;
        this.availableQuantityField.visible = true;
      }
    }

    let gsAddr: Address = new Address();
    gsAddr.name2 = 'G.S.';
    gsAddr.name1 = "A l'attention de Mme Gillian Townley";
    gsAddr.address1 = 'Rue du Vertbois, 11 bte 20';
    gsAddr.postcode = '4000';
    gsAddr.city = 'Liège';
    let pickupAddr: Address = new Address();
    pickupAddr.name1 = 'Enlèvement par nos soins';
    pickupAddr.country = null;
    this.contactsData.items = move.customer ? move.customer.contacts : [];
    this.deliveryAddressInfoBlockField.listItems.items = move.customer
      ? move.customer.deliveryAddressesList.concat(
          { name: `Chez ${config.companyName}`, address: gsAddr },
          { name: 'Enlèvement', address: pickupAddr }
        )
      : [];

    Toolbar.getToolbarItem(this.toolbar, 'sendToCustomerButton').disabled = this.moveCopy.invoice == null;
    this.setPreview();
  }

  validateForm() {
    let form: Form = {
      fields: [
        {
          name: 'Choix du client',
          type: 'foreign-list',
          data: this,
          field: 'selectedCustomer',
          pattern: null,
          element: this.selectBlockComponent.getElement('selectedCustomer'),
        },
        {
          name: 'Choix du stock',
          type: 'foreign-list',
          data: this,
          field: 'selectedStock',
          pattern: null,
          element: this.selectBlockComponent.getElement('selectedStock'),
        },
        {
          name: 'Date',
          type: 'string',
          data: this.moveCopy,
          field: 'date',
          pattern: /[0-9]{4}-[0-9]{2}-[0-9]{2}/,
          element: this.moveBlockComponent.getElement('date'),
        },
        // {
        //     name: 'Client',
        //     type: 'foreign-list',
        //     data: this.moveCopy,
        //     field: 'customer',
        //     pattern: null,
        //     element: this.stockBlockComponent.getElement('customer')
        // },
        // {
        //     name: 'Fournisseur',
        //     type: 'foreign-list',
        //     data: this.moveCopy,
        //     field: 'supplier',
        //     pattern: null,
        //     element: this.stockBlockComponent.getElement('supplier')
        // },
        {
          name: 'Quantité appelée',
          type: 'string',
          data: this.moveCopy,
          field: 'quantity',
          pattern: /.*/,
          element: this.moveBlockComponent.getElement('quantity'),
        },
        {
          name: "Numéro d'appel",
          type: 'string',
          data: this.moveCopy,
          field: 'number',
          pattern: /.*/,
          element: this.moveBlockComponent.getElement('number'),
        },
        // {
        //     name: 'Unité',
        //     type: 'string',
        //     data: this.moveCopy,
        //     field: 'unit',
        //     pattern: /.*/,
        //     element: this.stockBlockComponent.getElement('unit')
        // }
      ],
    };
    let result = FormValidator.validateForm(form);
    FormValidator.showFormInvalidFields(form, result);
    if (result !== true) {
      FormValidator.showNotification(result);
      return false;
    } else return true;
  }

  save() {
    let canSave: boolean = true;
    let createStock: boolean = false;
    return new Promise<any>((resolve, reject) => {
      if (this.validateForm() !== true) reject('invalid form');
      else {
        let stock_date: string = this.moveCopy.date;
        let today_date: string = DateTimeUtil.toDateString(new Date());
        let promises: any[] = [];
        if (stock_date != today_date) {
          promises.push(
            DialogsComponent.display({
              icon: 'question',
              title: 'Date de création',
              message:
                "Vous avez spécifié la date suivante pour l'appel sur stock : <b>" +
                stock_date +
                '</b><br/>' +
                'Voulez-vous changer pour la date du jour <b>' +
                today_date +
                '</b> ?',
              buttons: [
                { text: 'Oui, changer la date', result: DialogButton.RESULT_YES },
                { text: 'Non, merci !', result: DialogButton.RESULT_NO },
                DialogButton.cancelButton,
              ],
            }).then((result) => {
              if (result == DialogButton.RESULT_YES) this.moveCopy.date = today_date;
              if (result == DialogButton.RESULT_CANCEL) canSave = false;
            })
          );
        }
        Promise.all(promises).then(
          (result) => {
            if (!canSave) {
              NotificationsComponent.push({
                type: 'error',
                title: 'Sauvegarde annulée',
                summary: "L'enregistrement a été annulé par l'utilisateur",
              });
              reject('cancelled by user');
            } else
              this.save2().then(
                (result) => {
                  resolve(result);
                },
                (err) => {
                  console.error(err);
                  reject(err);
                }
              );
          },
          (err) => {
            NotificationsComponent.push({
              type: 'error',
              title: 'Sauvegarde annulée',
              summary: "Une erreur s'est produite lors de la pré-sauvegarde",
              content: err,
            });
            console.error(err);
            reject(err);
          }
        );
      }
    });
  }

  save2() {
    return new Promise<any>((resolve, reject) => {
      let addToStock: boolean = true;
      let moveDest: StockMove = this.moveCopy;
      if (this.moveCopy.id != 0) {
        for (let i = 0; i < this.moveCopy.stock.moves.length; ++i) {
          let move: StockMove = this.moveCopy.stock.moves[i];
          if (move.id == this.moveCopy.id) {
            moveDest = move;
            addToStock = false;
            break;
          }
        }
      }
      let prom: Promise<any> = null;
      if (addToStock) {
        this.moveCopy.stock.moves.push(moveDest);
        prom = this.moveCopy.stock.save2();
      } else prom = this.moveCopy.save2();
      prom.then(
        (result) => {
          this.moveCopy.clone(true, moveDest);
          this.initView(moveDest);
          resolve(result);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public get isArticle() {
    return this.moveCopy && this.moveCopy.stock && this.moveCopy.stock.article && this.moveCopy.stock.article.type == 0;
  }
  public get isDevice() {
    return this.moveCopy && this.moveCopy.stock && this.moveCopy.stock.article && this.moveCopy.stock.article.type == 1;
  }
  public get moveCopyArticle() {
    return this.moveCopy && this.moveCopy.stock ? this.moveCopy.stock.article : null;
  }

  public selectedPreview: string = 'move';
  public previewModels: any = {
    move: StockMovePrintingModel,
    invoice: InvoicePrintingModel,
    delivery: DeliveryNotePrintingModel,
    labels: StickersPrintingModel,
  };
  public model: PrintingModel = null;
  public setPreview(preview: string = null) {
    if (preview) this.selectedPreview = preview;
    if (this.selectedPreview == 'invoice') {
      console.log('updating invoice model with:', this.moveCopy.invoice);
      this.model = this.previewModels[this.selectedPreview].generate(this.moveCopy.invoice);
    } else this.model = this.previewModels[this.selectedPreview].generate(this.moveCopy);
  }

  public iterateFiles(includeCustomer: boolean = true, includeSupplier: boolean = true, callback) {
    let models: PrintingModel[] = [];
    if (includeCustomer === true && this.moveCopy.invoice)
      models.push(InvoicePrintingModel.generate(this.moveCopy.invoice));
    if (includeSupplier === true) {
      models.push(StockMovePrintingModel.generate(this.moveCopy));
      models.push(DeliveryNotePrintingModel.generate(this.moveCopy));
      models.push(StickersPrintingModel.generate(this.moveCopy));
    }
    return PrintPreviewViewComponent.globalInstance.iteratePrintingModels(models, callback);
  }

  public saveFiles() {
    this.iterateFiles(true, true, (model: PrintingModel, index: number) => {
      let path: string = '';
      let num: string = this.moveCopy.order.number.replace(/\//g, '-');
      if (model instanceof InvoicePrintingModel)
        path = FilesystemUtil.resolveSpecialPath(
          SettingsComponent.get('pdf_logistics_invoices_save_path').replace('%NUMBER%', num)
        );
      else if (model instanceof StockMovePrintingModel)
        path = FilesystemUtil.resolveSpecialPath(
          SettingsComponent.get('pdf_stock_moves_save_path').replace('%NUMBER%', num)
        );
      else if (model instanceof DeliveryNotePrintingModel)
        path = FilesystemUtil.resolveSpecialPath(
          SettingsComponent.get('pdf_logistics_delivery_notes_save_path').replace('%NUMBER%', num)
        );
      else if (model instanceof StickersPrintingModel)
        path = FilesystemUtil.resolveSpecialPath(
          SettingsComponent.get('pdf_logistics_labels_save_path').replace('%NUMBER%', num)
        );
      return PrintPreviewViewComponent.globalInstance.saveFileAs(path + model.saveFilename);
    });
  }

  public sendToSupplier() {
    return LoadingPromise.create<any>((resolve, reject) => {
      let lang: string = this.moveCopy.supplier.lang ? this.moveCopy.supplier.lang.code || 'fr' : 'fr';
      let bcc: string[] = [...config.mailBcc];
      let filenames: string[] = [];
      if (!bcc.includes(this.moveCopy.merchant.email)) bcc.push(this.moveCopy.merchant.email);
      this.iterateFiles(false, true, (model: PrintingModel, index: number) => {
        return PrintPreviewViewComponent.globalInstance.saveAsTempFilename(model.mailFilename).then(
          (result) => {
            filenames.push(result + '|' + model.mailFilename);
            resolve(result);
          },
          (err) => {
            reject(err);
          }
        );
      }).then(
        (result) => {
          ExternalService.createMail(
            this.moveCopy.supplier.commercial_address.email,
            null,
            bcc.join('; '),
            {
              fr: 'Notre appel sur stock n° ' + this.moveCopy.fullNumber,
              en: 'Call off no ' + this.moveCopy.fullNumber,
              nl: 'Call off no ' + this.moveCopy.fullNumber,
            }[lang],
            {
              fr:
                '<p>Madame, Monsieur,</p>' +
                '<p>Vous trouverez, ci-joint, notre appel sur stock susmentionné.<br/>' +
                'Nous y joignons une page composée de 6 étiquettes de livraison, à imprimer autant de fois que nécessaire, et à coller sur chaque boîte.' +
                "<p>S'y trouve également le bordereau d'expédition à imprimer, et à nous refaxer dès que la commande sera (sur le point d'être) livrée. Ceci dans le but de facturer le client au plus vite, et de vous payer tout aussi rapidement.</p>" +
                '<p>Nous comptons sur vous pour une livraison chez le client ce ' +
                this.moveCopy.delivery_date +
                '.</p>' +
                '<p>En vous remerciant,</p>',
              en:
                '<p>Dear Partner,</p>' +
                '<p>Attached you will find our stock call off for the contract mentioned above.</p>' +
                `<p>We have included our ${config.companyName} branded delivery note and labels. Could you please use 1 label per box or pallet and inform us when the call off has been shipped.</p>` +
                '<p>We are counting on you to deliver to our client the ' +
                this.moveCopy.delivery_date +
                '. </p>' +
                '<p>Yours faithfully,</p>',
              nl:
                '<p>Dear Partner,</p>' +
                '<p>Attached you will find our stock call off for the contract mentioned above.</p>' +
                `<p>We have included our ${config.companyName} branded delivery note and labels. Could you please use 1 label per box or pallet and inform us when the call off has been shipped.</p>` +
                '<p>We are counting on you to deliver to our client the ' +
                this.moveCopy.delivery_date +
                '. </p>' +
                '<p>Yours faithfully,</p>',
            }[lang],
            filenames,
            CredentialsService.loggedMerchant.outlook_signature
          ).then(
            (result) => {
              resolve(result);
            },
            (err) => {
              reject(err);
            }
          );
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public sendToCustomer() {
    let lang: string = this.moveCopy.customer.lang ? this.moveCopy.customer.lang.code || 'fr' : 'fr';
    let bcc: string[] = [...config.mailBcc];
    if (!bcc.includes(this.moveCopy.order.merchant.email)) bcc.push(this.moveCopy.order.merchant.email);
    this.iterateFiles(true, false, (model: PrintingModel, index: number) => {
      return PrintPreviewViewComponent.globalInstance.sendByMail(
        this.moveCopy.order.invoicing_emails,
        null,
        bcc.join('; '),
        {
          fr: 'Votre appel sur stock n° ' + this.moveCopy.fullNumber,
          en: 'Call off no ' + this.moveCopy.fullNumber,
          nl: 'Call off no ' + this.moveCopy.fullNumber,
        }[lang],
        {
          fr:
            '<p>Madame, Monsieur,</p>' +
            '<p>Suite à votre appel sur stock susmentionné, pour lequel nous vous remercions, nous tenions à vous informer que votre marchandise ' +
            this.moveCopy.delivery +
            ' ' +
            this.moveCopy.delivery_date +
            '.</p>' +
            '<p>Vous trouverez, en pièce jointe, ' +
            (this.dontSendByPost
              ? 'votre facture'
              : 'la copie de votre facture que nous vous avons expédiée par voie postale') +
            '.</p>' +
            '<p>Nous restons à votre entière disposition, et nous vous prions de croire, Madame, Monsieur, en nos salutations les plus sincères.</p>' +
            '<p>Cordialement,</p>',
          en:
            '<p>Dear Sir / Madam,</p>' +
            '<p>Thank you for your valued stock call off which: ' +
            this.moveCopy.delivery +
            ' ' +
            this.moveCopy.delivery_date +
            '</p>' +
            '<p>Attached you will find your invoice ' +
            (this.dontSendByPost
              ? 'which is the only copy you will receive'
              : 'which will also be sent in hard copy by post') +
            '.</p>' +
            '<p>Please do not hesitate to contact us for any questions.</p>' +
            '<p>Yours faithfully,</p>',
          nl:
            '<p>Dear Sir / Madam,</p>' +
            '<p>Thank you for your valued stock call off which: ' +
            this.moveCopy.delivery +
            ' ' +
            this.moveCopy.delivery_date +
            '</p>' +
            '<p>Attached you will find your invoice ' +
            (this.dontSendByPost
              ? 'which is the only copy you will receive'
              : 'which will also be sent in hard copy by post') +
            '.</p>' +
            '<p>Please do not hesitate to contact us for any questions.</p>' +
            '<p>Yours faithfully,</p>',
        }[lang],
        model.mailFilename
      );
    });
  }

  get dontSendByPost() {
    if (!this.moveCopy) return false;
    if (!this.moveCopy.invoice) return false;
    if (!this.moveCopy.invoice.customer) return false;
    if (!this.moveCopy.invoice.customer) return false;
    if (!this.moveCopy.invoice.customer.billing_address) return false;
    else return !this.moveCopy.invoice.customer.billing_address.invoicing_by_paper;
  }

  get dontSendByMail() {
    if (!this.moveCopy) return false;
    if (!this.moveCopy.invoice) return false;
    if (!this.moveCopy.invoice.customer) return false;
    if (!this.moveCopy.invoice.customer.billing_address) return false;
    else return !this.moveCopy.invoice.customer.billing_address.invoicing_by_mail;
  }
}
