import { ObjectModel2 } from "../../../classes/objects/ObjectModel2.class";
import { ObjectDefinition } from "../../../classes/objects/ObjectDefinition.class";
import { ChargeType } from "./ChargeType.class";
import { ObjectModel3 } from "src/app/classes/objects/ObjectModel3.class";
import { LoadSequence } from "src/app/classes/objects/LoadSequence.class";
import { Merchant } from "../../other-data/classes/Merchant.class";
import { Accounting } from "../../other-data/classes/Accounting.class";
import { Currency } from "../../other-data/classes/Currency.class";
import { CurrenciesService } from "src/app/services/currencies/currencies.service";


export class Charge extends ObjectModel3
{
    public accounting: Accounting = null;
    public currency: Currency = null;
    public xrate: number = 1;

    public date: string = null;
    public effective_date: string = null;
    public number: number = null;
    public name: string = null;

    public type: ChargeType = null;
    public merchant: Merchant = null;

    public amount: number = null;
    public vat: number = null;

    public remarks: string = null;

    public get converted_total() { return CurrenciesService.euroToCurrency(this.xrate || 1, this.total); }
    public get total() { return (this.amount || 0) + (this.vat || 0); }

    public get converted_amount() { return CurrenciesService.euroToCurrency(this.xrate || 1, this.amount); }
    public set converted_amount(value: number) { this.amount = CurrenciesService.currencyToEuro(this.xrate || 1, value); }
    public get converted_vat() { return CurrenciesService.euroToCurrency(this.xrate || 1, this.vat); }
    public set converted_vat(value: number) { this.vat = CurrenciesService.currencyToEuro(this.xrate || 1, value); }

    constructor() {
        super(Charge);
    }

    /* ObjectModel FUNCTIONS */

    public static definition: ObjectDefinition = {
        trashDelete: true,
        database: {
            table: 'charges',
            id: 'id',
			db_id: 'db_id'
        },
        values: {
            'xrate': { type: 'number' },
            'date': { type: 'string' },
            'effective_date': { type: 'string' },
            'number': { type: 'number' },
            'name': { type: 'string' },
            'amount': { type: 'number' },
            'vat': { type: 'number' },
            'remarks': { type: 'string' },
        },
        children: {
            'accounting': { type: 'Accounting', clone: false, save: false, delete: false },
            'currency': { type: 'Currency', clone: false, save: false, delete: false },
            'type': { type: 'ChargeType', clone: false, save: false, delete: false },
            'merchant': { type: 'Merchant', clone: false, save: false, delete: false }
        },
        links: {}
    };
}
