import { Component, ViewChild } from '@angular/core';

import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { Address } from 'src/app/modules/addresses/classes/Address.class';
import { ArrayUtil } from '../../../utils/classes/ArrayUtil.class';
import { Article } from '../../../articles/classes/Article.class';
import { ArticleBase } from 'src/app/modules/articles/classes/ArticleBase.class';
import { ArticleInfoBlocks } from 'src/app/modules/articles/classes/ArticleInfoBlocks.class';
import { ArticleViewComponent } from 'src/app/modules/articles/views/article-view/article-view.component';
import { ChangeSaleNumberComponent } from '../../change-sale-number/change-sale-number.component';
import { CredentialsService } from '../../../../services/credentials/credentials.service';
import { CurrenciesService } from 'src/app/services/currencies/currencies.service';
import { Currency } from 'src/app/modules/other-data/classes/Currency.class';
import { Customer } from '../../../customers/classes/Customer.class';
import { CustomerReference } from 'src/app/modules/other-data/classes/CustomerReference.class';
import { DataGridComponent } from '../../../../components/data-grid/data-grid.component';
import { DateTimeUtil } from '../../../utils/classes/DateTimeUtil.class';
import { Deadline } from 'src/app/modules/other-data/classes/Deadline.class';
import { DeliveryTime } from 'src/app/modules/other-data/classes/DeliveryTime.class';
import { Device } from '../../../articles/classes/Device.class';
import { DialogButton } from '../../../../components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from '../../../../components/dialogs/dialogs.component';
import { FilesystemUtil } from 'src/app/modules/utils/classes/FilesystemUtil.class';
import { Form } from '../../../../classes/forms/Form.class';
import { FormValidator } from '../../../../classes/forms/FormValidator.class';
import { Incident } from '../../../incidents/classes/Incident.class';
import { InfoBlock } from '../../../../components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from '../../../../components/info-block/info-block.component';
import { InfoBlockField } from '../../../../components/info-block/classes/InfoBlockField.class';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { Merchant } from '../../../other-data/classes/Merchant.class';
import { Nomenclature } from '../../../articles/classes/Nomenclature.class';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { Price } from '../../classes/Price.class';
import { PriceDemandPrintingModel } from '../../classes/PriceDemandPrintingModel.class';
import { PriceOfferPrintingModel } from '../../classes/PriceOfferPrintingModel.class';
import { PrintPreviewViewComponent } from '../../../printing/views/print-preview-view/print-preview-view.component';
import { PrintingModel } from 'src/app/modules/printing/classes/PrintingModel.class';
import { RollingDirection } from 'src/app/modules/other-data/classes/RollingDirection.class';
import { Sale } from '../../classes/Sale.class';
import { ServerApi } from 'src/app/classes/api/ServerApi.class';
import { Service } from 'src/app/modules/articles/classes/Service.class';
import { SettingsComponent } from 'src/app/components/settings/settings.component';
import { StickersPrintingModel } from '../../../orders/classes/StickersPrintingModel.class';
import { Supplier } from '../../../suppliers/classes/Supplier.class';
import { TechnicalSheetPrintingModel } from '../../classes/TechnicalSheetPrintingModel.class';
import { TextUtil } from 'src/app/modules/utils/classes/TextUtil.class';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from '../../../../components/views/views.component';
import { config } from '../../../../classes/config';

@Component({
  selector: 'app-sale-view',
  templateUrl: './sale-view.component.html',
  styleUrls: ['./sale-view.component.css'],
})
export class SaleViewComponent extends AccessViewComponent {
  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Dossier commercial',
    data: this,
    onPrevPage: () => {
      if (this.saleCopy && (this.saleCopy.changed || this.pricesChanged)) {
        DialogsComponent.display({
          icon: 'question',
          title: 'Enregistrer les modifications',
          message: 'Des modifications ont été effectuées.<br/>Voulez-vous enregistrer les modifications ?',
          buttons: DialogButton.yesNoCancelButtons,
        }).then((result: any) => {
          if (result === DialogButton.RESULT_YES) {
            this.save().then((result2: any) => {
              ViewsComponent.closeView();
            });
          } else if (result !== DialogButton.RESULT_CANCEL) ViewsComponent.closeView();
        });
        return false;
      }
      return true;
    },
    elements: [
      { type: 'separator' },
      {
        type: 'button',
        name: 'saveButton',
        text: 'Enregistrer',
        icon: 'save',
        click: function (view: SaleViewComponent) {
          view.save().then((result) => {
            //if (view.originalSales.length > 0) view.sale = view.originalSales.pop();
            view.updateView(view.saleCopy);
          });
        },
        visible: true,
      },
      { type: 'separator' },
      {
        type: 'button',
        name: 'cancelButton',
        text: 'Annuler',
        icon: 'times',
        click: function (view: SaleViewComponent) {
          view.cancelChanges();
        },
        visible: false,
      },
      // { type: 'separator-large' },
      // {
      //     name: 'duplicateArticleButton',
      //     type: 'button',
      //     text: 'Dupliquer<br/>l\'article',
      //     icon: 'copy',
      //     click: function(view: SaleViewComponent) {
      //         view.duplicateArticle();
      //     },
      //     visible: false
      // },
      {
        name: 'duplicateSaleButton',
        type: 'button',
        text: 'Créer un<br/>nouveau dossier',
        icon: 'copy',
        click: function (view: SaleViewComponent) {
          view.duplicateSale();
        },
        visible: false,
      },
      { type: 'separator' },
      {
        name: 'updateSaleButton',
        type: 'button',
        text: 'Mettre à jour<br/>le dossier',
        icon: 'edit',
        click: function (view: SaleViewComponent) {
          DialogsComponent.display({
            icon: 'question',
            title: 'Créer une nouvelle version ?',
            message: 'Voulez-vous créer une nouvelle version du dossier, ou conserver la version actuelle ?',
            buttons: [
              { text: 'Nouvelle version', result: DialogButton.RESULT_YES },
              { text: "Conserver l'actuelle", result: DialogButton.RESULT_NO },
              DialogButton.cancelButton,
            ],
          }).then((result: any) => {
            if (result === DialogButton.RESULT_YES) view.updateSale(true);
            else if (result === DialogButton.RESULT_NO) view.updateSale(false);
          });
        },
        visible: false,
      },
      { type: 'separator-large' },
      {
        type: 'button',
        name: 'editNumberButton',
        text: 'Modifier<br/>le numéro',
        icon: 'edit',
        click: function (view: SaleViewComponent) {
          view.editNumber();
        },
        visible: false,
        access: CredentialsService.isUserAllowed('sale_view_edit_number'),
      },
      { type: 'separator' },
      {
        type: 'button',
        name: 'reloadArticleButton',
        text: "Recharger<br/>l'article",
        icon: 'sync-alt',
        click: function (view: SaleViewComponent) {
          view.reloadArticle();
        },
        visible: false,
      },
      // { type: 'separator' },
      // {
      //     type: 'button',
      //     name: 'openArticleButton',
      //     text: 'Ouvrir la<br/>fiche de l\'article',
      //     icon: 'arrow-right',
      //     click: function(view: SaleViewComponent) {
      //         view.openArticleView();
      //     },
      //     visible: true
      // },
      { type: 'separator-large' },
      {
        type: 'toggle-button',
        name: 'filterSuppliersButton',
        text: 'Afficher tous<br/>les fournisseurs',
        icon: 'filter',
        value: false,
        click: function (view: SaleViewComponent) {
          view.showAllSuppliers = this.value;
          view.updateSuppliersList();
        },
      },
      { type: 'separator-large' },
      {
        type: 'button',
        name: 'previewDemandButton',
        text: 'Aperçu demande<br/>de prix',
        icon: 'search',
        click: function (view: SaleViewComponent) {
          view.previewDemand();
        },
      },
      { type: 'separator' },
      {
        type: 'button',
        name: 'previewOfferButton',
        text: 'Aperçu offre<br/>de prix',
        icon: 'search',
        click: function (view: SaleViewComponent) {
          view.previewOffers();
        },
      },
      { type: 'separator' },
      {
        type: 'button',
        name: 'previewLabelsButton',
        text: 'Aperçu<br/>étiquettes',
        icon: 'search',
        click: function (view: SaleViewComponent) {
          view.previewLabels();
        },
      },
      { type: 'separator' },
      {
        type: 'button',
        name: 'previewLabelsButton',
        text: 'Aperçu<br/>fiche technique',
        icon: 'search',
        click: function (view: SaleViewComponent) {
          view.previewTechnical();
        },
      },
      { type: 'separator-large' },
      {
        type: 'button',
        name: 'saveDocsButton',
        text: 'Enregistrer<br/>les documents',
        icon: 'save',
        click: function (view: SaleViewComponent) {
          view.saveFiles();
        },
      },
      // { type: 'separator' },
      // {
      //     type: 'button',
      //     name: 'sendDemandsButton',
      //     text: 'Envoyer les<br/>demandes de prix',
      //     icon: ' fas fa-at',
      //     click: function(view: SaleViewComponent) {
      //         view.sendDemands();
      //     }
      // },
      // { type: 'separator' },
      // {
      //     type: 'button',
      //     name: 'sendOfferButton',
      //     text: 'Envoyer<br/>l\'offre de prix',
      //     icon: ' fas fa-at',
      //     click: function(view: SaleViewComponent) {
      //         view.sendOffer();
      //     }
      // },
      { type: 'spacing' },
    ],
  };

  // public newArticle: Article = (() => { let art = new Article(); art.designation = '(Nouvel article consommable)'; return art; })();
  // public newDevice: Device = (() => { let art = new Device(); art.designation = '(Nouvel article matériel)'; return art; })();
  // public articlesData: any = { items: [ this.newArticle, this.newDevice ] };
  public articlesData: any = { items: [] };
  public addArticles(articles: any[]) {
    this.articlesData.items = this.articlesData.items.concat(articles);
    this.articlesData.items.sort((a: any, b: any) => {
      // sort by type
      if (a.type < b.type) return -1;
      else if (a.type > b.type) return 1;
      // then by name
      else return a.designation < b.designation ? -1 : a.designation == b.designation ? 0 : 1;
    });
  }
  //public selectedArticle: any = { object: null };
  public get objectIsArticle() {
    return this.saleCopy && this.saleCopy.article.type == ArticleBase.TYPE_ARTICLE;
  }
  public get objectIsDevice() {
    return this.saleCopy && this.saleCopy.article.type == ArticleBase.TYPE_DEVICE;
  }
  public get objectIsService() {
    return this.saleCopy && this.saleCopy.article.type == ArticleBase.TYPE_SERVICE;
  }
  @ViewChild('articleBlockComponent') articleBlockComponent: InfoBlockComponent;
  @ViewChild('deviceBlockComponent') deviceBlockComponent: InfoBlockComponent;
  @ViewChild('serviceBlockComponent') serviceBlockComponent: InfoBlockComponent;
  @ViewChild('ordinatorBlockComponent') ordinatorBlockComponent: InfoBlockComponent;
  @ViewChild('invoicingBlockComponent') invoicingBlockComponent: InfoBlockComponent;
  @ViewChild('deliveryBlockComponent') deliveryBlockComponent: InfoBlockComponent;
  @ViewChild('tempCustomerBlockComponent') tempCustomerBlockComponent: InfoBlockComponent;

  public articleHistoryVisible: boolean = false;
  public customerTurnoversVisible: boolean = false;
  public incidentsVisible: boolean = false;

  public suppliers: Supplier[] = [];
  public customersData: any = { items: [] };
  public contactsData: any = { items: [] };
  public suppliersData: any = { items: [] };
  public deliveryTimeData: any = { items: [] };
  public customerReferenceData: any = { items: [] };
  public deadlineData: any = { items: [] };
  public rollingsData: any = { items: [] };
  public accountingsData: any = { items: [] };
  public currenciesData: any = { items: [] };
  public showAllSuppliers: boolean = false;
  public updateSuppliersList() {
    let items = this.suppliers;
    if (
      this.showAllSuppliers !== true &&
      this.saleCopy.article &&
      this.saleCopy.article.nomenclature &&
      Array.isArray(this.saleCopy.article.nomenclature.suppliers) &&
      this.saleCopy.article.nomenclature.suppliers.length > 0
    ) {
      items = [].concat(this.saleCopy.article.nomenclature.suppliers);
      for (let i = 0; i < this.saleCopy.suppliers.length; ++i) {
        if (!items.includes(this.saleCopy.suppliers[i])) items.push(this.saleCopy.suppliers[i]);
      }
    }
    setTimeout(() => {
      this.suppliersData.items = items;
      this.updateView(this.saleCopy);
    }, 0);
  }
  public merchantsData: any = { items: [] };
  public nomenclaturesData: any = { items: [] };

  public sale: Sale = null;
  public saleCopy: Sale = null;
  // public originalSales: Sale[] = [];
  public olderVersions: Sale[] = [];

  public editMode: boolean = false;

  @ViewChild('merchantBlockComponent') merchantBlockComponent: InfoBlockComponent;
  @ViewChild('saleBlockComponent') saleBlockComponent: InfoBlockComponent;
  @ViewChild('remarksBlockComponent') remarksBlockComponent: InfoBlockComponent;
  @ViewChild('accountingBlockComponent') accountingBlockComponent: InfoBlockComponent;
  @ViewChild('selectBlockComponent') selectBlockComponent: InfoBlockComponent;
  @ViewChild('quantitiesGrid') quantitiesGrid: DataGridComponent;

  public selectBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(0,0,0)',
    textColor: 'white',
    fields: [
      {
        title: "Choix de l'article",
        field: 'article',
        type: 'searchable-foreign-list',
        multiSelect: false,
        listItems: this.articlesData,
        listField: 'designation',
        nullText: '(Créer un nouvel article)',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
          this.updateSuppliersList();
        },
        getOptionStyle: (item: ArticleBase) => {
          return 'article-' + item.type;
        },
        comment: "Sélectionnez ici l'article souhaité.",
      },
    ],
  };

  public merchantBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(149,179,215)',
    textColor: 'black',
    fields: [
      {
        title: 'Représentant',
        field: 'merchant',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.merchantsData,
        listField: 'numberAndName',
        nullText: '(Aucun)',
        comment: 'Sélectionnez ici le représentant pour ce dossier',
      },
    ],
  };

  public creationDateField: InfoBlockField = {
    title: 'Date de création',
    type: 'date',
    field: 'creation_date',
    visible: true,
    readonly: true,
  };
  private customerInfoBlockField: InfoBlockField = {
    title: 'Client',
    field: 'customer',
    type: 'foreign-list',
    readonly: false,
    multiSelect: false,
    listItems: this.customersData,
    listField: 'nameWithIdentifier',
    allowBlankValues: true,
    nullText: '(Aucun)',
    change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
      this.saleCopy.deadline =
        this.saleCopy.customer && this.saleCopy.customer.deadline ? this.saleCopy.customer.deadline : '';
      this.saleCopy.contact = null;
      if (this.saleCopy.customer) {
        if (this.saleCopy.customer.ordinator_address)
          this.ordinatorAddressInfoBlockField.addressChange(
            null,
            null,
            null,
            null,
            this.saleCopy.customer.ordinator_address
          );
        if (this.saleCopy.customer.billing_address)
          this.invoicingAddressInfoBlockField.addressChange(
            null,
            null,
            null,
            null,
            this.saleCopy.customer.billing_address
          );
        if (this.saleCopy.customer.delivery_address)
          this.deliveryAddressInfoBlockField.addressChange(
            null,
            null,
            null,
            null,
            this.saleCopy.customer.delivery_address
          );
        this.displayCustomerWarning();
      }
      this.updateView(this.saleCopy);
    },
    comment: 'Choisissez ici le client pour ce dossier commercial',
  };
  private dicountDaysField: InfoBlockField = {
    title: '... si paiment dans les',
    field: 'discount_days',
    type: 'number',
    decimalsCount: 0,
    numberUnit: 'jours',
    textAlign: 'left',
    readonly: true,
  };
  public saleBlock: InfoBlock = {
    title: 'Informations\ndossier',
    backColor: 'rgb(54,96,146)',
    textColor: 'white',
    fields: [
      {
        title: "Date d'encodage",
        type: 'date',
        field: 'date',
        comment: "Insérez ici la date de l'offre",
      },
      this.creationDateField,
      {
        title: 'Date de validité',
        type: 'date',
        field: 'valid_date',
        comment: "Entrez ici une date limite de validité pour l'offre de prix, le cas échéant",
      },
      this.customerInfoBlockField,
      {
        title: 'N° ou type demande de prix client',
        field: 'customer_reference',
        type: 'combo-box',
        disableFiltering: true,
        listItems: this.customerReferenceData,
        listField: 'name',
        nullText: '(Aucun)',
        comment: 'Sélectionnez ici le n° de la demande de prix ou le type de la demande de prix',
      },
      { title: 'Référence article client', field: 'article_reference' },
      {
        title: 'Fournisseurs',
        field: 'suppliers',
        type: 'foreign-list',
        multiSelect: true,
        listItems: this.suppliersData,
        listField: 'name',
        nullText: '(Aucun)',
        comment: 'Cochez ici le(s) fournisseur(s) à qui envoyer des demandes de prix',
      },
      {
        title: 'Délai de livraison',
        field: 'delivery_time',
        type: 'combo-box',
        disableFiltering: true,
        listItems: this.deliveryTimeData,
        listField: 'name',
        nullText: '(Aucun)',
        comment: 'Sélectionnez ici le délai de livraison prévu',
      },
      {
        title: 'Conditions de paiement',
        field: 'deadline',
        type: 'combo-box',
        readonly: true,
        multiSelect: false,
        listItems: this.deadlineData,
        listField: 'name',
        nullText: '(Aucun)',
        disableFiltering: true,
      },
      {
        title: 'Escompte de ...',
        field: 'discount',
        type: 'number',
        decimalsCount: 1,
        numberUnit: '%',
        textAlign: 'left',
        change: () => {
          this.updateView(this.saleCopy);
        },
      },
      this.dicountDaysField,
    ],
  };
  public remarksBlock: InfoBlock = {
    title: 'Remarques',
    backColor: 'rgb(31,56,83)',
    textColor: 'white',
    fields: [
      {
        title: 'Remarques pour les demandes de prix',
        field: 'demand_remarks',
        type: 'textarea',
        comment:
          'Entrez ici les remarques que vous voulez faire apparaître sur les demandes de prix pour les fournisseurs, dans le cadre Remarques',
      },
      {
        title: 'Copier sur le bon de commande',
        field: 'copy_demand_remarks',
        type: 'checkbox',
        comment:
          'Cochez ici si vous voulez que les remarques concernant les demandes de prix soient recopiées automatiquement sur le bon de commande.',
      },
      {
        title: "Remarques pour l'offre de prix",
        field: 'offer_remarks',
        type: 'textarea',
        comment:
          "Entrez ici les remarques que vous voulez faire apparaître sur l'offre de prix pour le client, dans le cadre Remarques",
      },
      {
        title: "Copier sur l'accusé de réception",
        field: 'copy_offer_remarks',
        type: 'checkbox',
        comment:
          "Cochez ici si vous voulez que les remarques concernant l'offre de prix soient recopiées automatiquement sur l'accusé de réception.",
      },
    ],
  };

  public accountingBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(64,0,64)',
    textColor: 'white',
    fields: [
      {
        title: 'Comptabilité',
        field: 'accounting',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.accountingsData,
        listField: 'fullDescription',
        allowBlankValues: false,
        comment: 'Sélectionnez ici la comptabilité liée à ce dossier',
      },
      {
        title: 'Devise',
        field: 'currency',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.currenciesData,
        listField: 'fullDescription',
        allowBlankValues: false,
        comment: 'Sélectionnez ici la devise à utiliser pour ce dossier',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.saleCopy.xrate = this.saleCopy.currency.rate;
          this.updateCurrenciesInPrices(this.saleCopy.currency, this.saleCopy.xrate);
        },
      },
      { title: 'Taux de change', field: 'xrate', type: 'number', decimalsCount: 6, textAlign: 'left' },
    ],
  };

  public _ordinatorContact: Address = null;
  private ordinatorAddressInfoBlockField: InfoBlockField = {
    title: 'Adresse',
    field: 'ordinator_address_text',
    type: 'address-text',
    listItems: { items: [] },
    listField: 'title',
    nullText: '(Aucun)',
    addressFunction: (addr: Address) => {
      return addr.getPostalAddress();
    },
    textChange: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
      this.saleCopy.changed = true;
    },
    addressChange: (
      component: InfoBlockComponent,
      block: InfoBlock,
      field: InfoBlockField,
      object: any,
      addr: Address
    ) => {
      this.saleCopy.ordinator_address = addr;
      if (addr) {
        this.saleCopy.ordinator_address_text = addr.getPostalAddress();
        if (addr.name1 || addr.name2) {
          this._ordinatorContact = addr;
          this.saleCopy.ordinator_contact = addr.contactName;
        }
        this.updateOrdinatorMails();
      }
      this.saleCopy.changed = true;
    },
    comment:
      "Sélectionnez ici l'adresse du client ordonateur (choix via l'icone ci-contre), ou entrez une adresse manuellement.<br/><br/>REMARQUE : Cela modifiera automatiquement le champ 'E-mails' ci-dessous, et le champ 'Service - Nom du contact' si des informations de service/contact sont présents dans l'adresse sélectionnée.",
  };
  public updateOrdinatorMails() {
    let mails: string[] = [];
    mails = mails.concat(this.saleCopy.ordinator_address.getMailsArray());
    if (this._ordinatorContact && this._ordinatorContact !== this.saleCopy.ordinator_address)
      mails = mails.concat(this._ordinatorContact.getMailsArray());
    let unique_mails: string[] = [];
    for (let i = 0; i < mails.length; ++i) if (!unique_mails.includes(mails[i])) unique_mails.push(mails[i]);
    this.saleCopy.ordinator_emails = unique_mails.join('; ');
  }
  public ordinatorBlock: InfoBlock = {
    title: "Adresse\nd'ordonateur",
    backColor: 'rgb(200, 80, 80)',
    textColor: 'black',
    fields: [
      {
        title: 'Service - Nom du contact',
        field: 'ordinator_contact',
        type: 'combo-box',
        disableFiltering: true,
        listItems: this.contactsData,
        listField: 'addressContactLine',
        nullText: '(Aucun)',
        linesCount: 8,
        select: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this._ordinatorContact = event;
          this.updateOrdinatorMails();
          this.saleCopy.changed = true;
        },
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.saleCopy.changed = true;
        },
        comment:
          "Sélectionnez ou entrez ici le nom du contact, si besoin, pour le client ordonateur.<br/><br/>REMARQUE : Cela modifiera automatique le champ 'E-mails' ci-dessous !",
      },
      this.ordinatorAddressInfoBlockField,
      {
        title: 'E-mails ordonateur',
        field: 'ordinator_emails',
        comment: 'Entrez ici les e-mails (séparés par des <b>;</b> ) auxquels seront envoyés les documents',
      },
    ],
  };

  public _invoicingContact: Address = null;
  private invoicingAddressInfoBlockField: InfoBlockField = {
    title: 'Adresse',
    field: 'invoicing_address_text',
    type: 'address-text',
    listItems: { items: [] },
    listField: 'title',
    nullText: '(Aucun)',
    addressFunction: (addr: Address) => {
      return addr.getPostalAddress();
    },
    textChange: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
      this.saleCopy.changed = true;
    },
    addressChange: (
      component: InfoBlockComponent,
      block: InfoBlock,
      field: InfoBlockField,
      object: any,
      addr: Address
    ) => {
      this.saleCopy.invoicing_address = addr;
      if (addr) {
        this.saleCopy.invoicing_address_text = addr.getPostalAddress();
        if (addr.name1 || addr.name2) {
          this._invoicingContact = addr;
          this.saleCopy.invoicing_contact = addr.contactName;
        }
        this.updateInvoicingMails();
      }
      this.saleCopy.changed = true;
    },
    comment:
      "Sélectionnez ici l'adresse de facturation (choix via l'icone ci-contre), ou entrez une adresse manuellement.<br/><br/>REMARQUE : Cela modifiera automatiquement le champ 'E-mails' ci-dessous, et le champ 'Service - Nom du contact' si des informations de service/contact sont présents dans l'adresse sélectionnée.",
  };
  public updateInvoicingMails() {
    // invoicing mails
    let mails: string[] = this.saleCopy.invoicing_address.getInvoicingMailsArray();
    if (this._invoicingContact && this._invoicingContact !== this.saleCopy.invoicing_address)
      mails = mails.concat(this._invoicingContact.getMailsArray());
    let unique_mails: string[] = [];
    for (let i = 0; i < mails.length; ++i) if (!unique_mails.includes(mails[i])) unique_mails.push(mails[i]);
    this.saleCopy.invoicing_emails = unique_mails.join('; ');
    // reminders mails
    mails = this.saleCopy.invoicing_address.getAccountingMailsArray();
    unique_mails = [];
    for (let i = 0; i < mails.length; ++i) if (!unique_mails.includes(mails[i])) unique_mails.push(mails[i]);
    this.saleCopy.reminders_emails = unique_mails.join('; ');
  }
  public invoicingBlock: InfoBlock = {
    title: 'Adresse\nde facturation',
    backColor: 'rgb(160, 50, 50)',
    textColor: 'white',
    fields: [
      {
        title: 'Service - Nom du contact',
        field: 'invoicing_contact',
        type: 'combo-box',
        disableFiltering: true,
        listItems: this.contactsData,
        listField: 'addressContactLine',
        nullText: '(Aucun)',
        linesCount: 8,
        select: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this._invoicingContact = event;
          this.updateInvoicingMails();
          this.saleCopy.changed = true;
        },
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.saleCopy.changed = true;
        },
        comment:
          "Sélectionnez ou entrez ici le nom du contact, si besoin, pour la facturation.<br/><br/>REMARQUE : Cela modifiera automatiquement le champ 'E-mails' ci-dessous !",
      },
      this.invoicingAddressInfoBlockField,
      {
        title: 'E-mails facturation',
        field: 'invoicing_emails',
        comment: 'Entrez ici les e-mails (séparés par des <b>;</b> ) auxquels seront envoyées les factures',
      },
      {
        title: 'E-mails réclamation',
        field: 'reminders_emails',
        comment: 'Entrez ici les e-mails (séparés par des <b>;</b> ) auxquels seront envoyées les rappels de paiement',
      },
    ],
  };

  private deliveryAddressInfoBlockField: InfoBlockField = {
    title: 'Adresse',
    field: 'delivery_address_text',
    type: 'address-text',
    listItems: { items: [] },
    listField: 'title',
    nullText: '(Aucun)',
    addressFunction: (addr: Address) => {
      return addr.getPostalAddress();
    },
    textChange: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
      this.saleCopy.changed = true;
    },
    addressChange: (
      component: InfoBlockComponent,
      block: InfoBlock,
      field: InfoBlockField,
      object: any,
      addr: Address
    ) => {
      this.saleCopy.delivery_address = addr;
      if (addr) {
        this.saleCopy.delivery_address_text = addr.getPostalAddress();
        this.saleCopy.opening_hours = addr.opening_hours;
        if (addr.name1 || addr.name2) {
          this.saleCopy.delivery_contact = addr.contactName;
        }
      }
      this.saleCopy.changed = true;
    },
    comment:
      "Sélectionnez ici l'adresse de livraison (choix via l'icone ci-contre), ou entrez une adresse manuellement.<br/><br/>REMARQUE : Cela modifiera automatiquement le champ 'Service - Nom du contact' si des informations de service/contact sont présents dans l'adresse sélectionnée.",
  };
  public deliveryBlock: InfoBlock = {
    title: 'Adresse\nde livraison',
    backColor: 'rgb(120, 20, 20)',
    textColor: 'white',
    fields: [
      {
        title: 'Service - Nom du contact',
        field: 'delivery_contact',
        type: 'combo-box',
        disableFiltering: true,
        listItems: this.contactsData,
        listField: 'addressContactLine',
        nullText: '(Aucun)',
        linesCount: 8,
        comment: 'Sélectionnez ou entrez ici le nom du contact, si besoin, pour la livraison.',
        select: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.saleCopy.changed = true;
        },
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.saleCopy.changed = true;
        },
      },
      this.deliveryAddressInfoBlockField,
      { title: "Heures d'ouverture", field: 'opening_hours' },
      { title: 'Masquer sur la demande de prix', type: 'checkbox', field: 'hide_customer' },
    ],
  };

  public shortageBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(255,0,0)',
    textColor: 'white',
    fields: [{ title: 'Sujet aux pénuries', field: 'shortage_warning', type: 'checkbox' }],
  };

  public articleBlock: InfoBlock = ArticleInfoBlocks.generateArticleBlock(this.nomenclaturesData, this.rollingsData);
  public deviceBlock: InfoBlock = ArticleInfoBlocks.generateDeviceBlock(this.nomenclaturesData);
  public serviceBlock: InfoBlock = ArticleInfoBlocks.generateServiceBlock(this.nomenclaturesData);

  public fieldsFunc(
    funcName: string,
    component: InfoBlockComponent,
    block: InfoBlock,
    field: InfoBlockField,
    object: any,
    event: any
  ) {
    if (funcName === 'change' && field.field === 'nomenclature') this.updateSuppliersList();
  }

  public quantitiesGridColumns: any[] = [
    { title: 'Quantité', field: 'quantity', type: 'number', width: '10%' },
    { title: 'Unité', field: 'unit' },
  ];
  public quantitiesGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.addQuantity();
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.deleteQuantities();
      },
    },
  ];
  public internalFeesField: any = {
    title: 'Frais de\ngestion',
    field: 'converted_internalFees',
    type: 'number',
    decimalsCount: 2,
    unit: ' €',
    width: 100,
    currencyField: 'sell_currency',
    readonly: !CredentialsService.isUserAllowed('sale_view_edit_internal_fees'),
  };
  public supplierFeesField: any = {
    title: 'Frais\nfournis-\nseur /\ndécoupe /\ntransport',
    field: 'converted_supplierFees',
    type: 'number',
    decimalsCount: 2,
    unit: ' €',
    width: 120,
    currencyField: 'buy_currency',
    change: (item, column) => {
      this.calculateInternalFees(item);
    },
  };
  public externalFeesField: any = {
    title: `Frais\n${config.companyName} /\ncréation`,
    field: 'converted_externalFees',
    type: 'number',
    decimalsCount: 2,
    unit: ' €',
    width: 120,
    currencyField: 'sell_currency',
    change: (item, column) => {
      this.calculateInternalFees(item);
    },
  };
  public agentFeesField: any = {
    title: 'Commission\nagent',
    field: 'converted_agentFees',
    type: 'number',
    decimalsCount: 2,
    unit: ' €',
    width: 120,
    currencyField: 'sell_currency',
    change: (item, column) => {
      this.calculateInternalFees(item);
    },
  };
  public buyPriceField: any = {
    title: "Prix\nd'achat\na l'unité\nHTVA",
    field: 'converted_unitPrice',
    type: 'number',
    decimalsCount: 8,
    unit: ' €',
    width: 100,
    currencyField: 'buy_currency',
    change: (item, column) => {
      this.calculateInternalFees(item);
    },
    backColor: '#fd8',
  };
  public sellPriceField: any = {
    title: "Prix de\nvente\nà l'unité\nHTVA",
    field: 'converted_sellPrice',
    type: 'number',
    decimalsCount: 8,
    unit: ' €',
    width: 100,
    currencyField: 'sell_currency',
    backColor: '#8df',
  };
  public pricesGridColumns = [
    {
      title: 'Qté',
      field: 'quantity',
      type: 'number',
      width: 90,
      change: (item, column) => {
        this.calculateInternalFees(item);
      },
    },
    { title: 'Unité', field: 'unit', width: 130 },
    { title: 'Visi-\nble', field: 'show', type: 'checkbox', width: 70 },
    {
      title: 'Nb.\nde\nfac-\ntura-\ntions',
      field: 'invoicings',
      type: 'number',
      width: 80,
      change: (item, column) => {
        this.calculateInternalFees(item);
      },
    },
    {
      title: 'Nb.\nde\nliv-\nrai-\nsons',
      field: 'deliveries',
      type: 'number',
      width: 80,
      change: (item, column) => {
        this.calculateInternalFees(item);
      },
    },
    { title: 'Mois\nde\nsto-\nckage', field: 'storage', type: 'number', width: 80 },
    { title: 'Nb.\nde\npro-\nduc-\ntions', field: 'productions', type: 'number', width: 80 },

    {
      title: "Devise d'achat",
      field: 'buy_currency',
      type: 'foreign-list',
      multiSelect: false,
      listItems: this.currenciesData,
      listField: 'fullDescription',
      allowBlankValues: false,
      change: (item, column) => {
        item.buy_xrate = item.buy_currency.rate;
      },
      width: 130,
    },
    {
      title: "Taux de change d'achat",
      field: 'buy_xrate',
      type: 'number',
      decimalsCount: 6,
      textAlign: 'left',
      width: 90,
    },

    {
      title: 'Fournis-\nseur',
      field: 'supplier',
      type: 'foreign-list',
      width: 120,
      change: (item, column) => {
        this.priceSupplierChange(item);
      },
      multiSelect: false,
      listItems: this.suppliersData,
      listField: 'name',
      nullText: '(Aucun)',
    },
    { title: "Date de\nl'offre\nfournisseur", field: 'date', type: 'date', width: 130 },
    { title: 'Réf.\nfournis-\nseur', field: 'reference', width: 120 },
    this.internalFeesField,
    this.supplierFeesField,
    this.externalFeesField,
    this.agentFeesField,
    this.buyPriceField,
    {
      title: "Prix\nd'achat\ntotal\nHTVA",
      field: 'converted_total_buyPrice',
      type: 'number',
      decimalsCount: 5,
      unit: ' €',
      currencyField: 'buy_currency',
      readonly: true,
      width: 120,
      backColor: '#fd8',
    },
    {
      title: "Prix de\nrevient\nà l'unité\nHTVA",
      field: 'converted_unit_costPrice',
      type: 'number',
      decimalsCount: 5,
      unit: ' €',
      currencyField: 'buy_currency',
      readonly: true,
      width: 120,
    },
    {
      title: 'Prix de\nrevient\ntotal\nHTVA',
      field: 'converted_total_costPrice',
      type: 'number',
      decimalsCount: 5,
      unit: ' €',
      currencyField: 'buy_currency',
      readonly: true,
      width: 120,
    },
    this.sellPriceField,
    {
      title: 'Prix de\nvente\ntotal\nHTVA',
      field: 'converted_total_sellPrice',
      type: 'number',
      decimalsCount: 5,
      unit: ' €',
      currencyField: 'sell_currency',
      readonly: true,
      backColor: '#8df',
      width: 120,
    },
    {
      title: 'Marge\nHTVA',
      field: 'profitAmount',
      type: 'number',
      decimalsCount: 5,
      unit: ' €',
      readonly: true,
      width: 120,
    },
    // { title: 'Marge\n(%)', field: 'profitPercentage', type: 'number', decimalsCount: 2, unit: ' %', readonly: true, width: '10%' },
    {
      title: 'Taux de\nmarque (%)',
      field: 'total_netMarkupPerc',
      type: 'number',
      decimalsCount: 2,
      unit: ' %',
      readonly: true,
      width: 100,
    },
  ];

  public incidents: any[] = [];
  public incidentsColumns: any[] = [
    { title: 'Date', field: 'date', type: 'date', width: 80 },
    { title: 'N° de commande', field: 'order.number', width: 180 },
    { title: 'Quantité', field: 'quantityAndUnit', width: 180 },
    { title: 'Montant\nestimé', field: 'amount', type: 'number', decimalsCount: 2, unit: ' €', width: 120 },
    { title: 'Clôturé', field: 'closed', type: 'checkbox', width: 80 },
  ];
  incidentRowStyle(item: Incident) {
    if (item && item.closed == 0) return { color: 'rgb(192,0,0)' };
    else if (item && item.closed == 1) return { color: 'rgb(0,192,0)' };
    else return null;
  }

  public turnovers: any[] = [];
  public turnoverColumns: any[] = [
    { title: 'Année', field: 'year', width: 80 },
    { title: 'Dossiers\ncommerciaux', field: 'sales_count', width: 100 },
    { title: 'Nombre de\ncommandes', field: 'orders_count', width: 120 },
    {
      title: 'Total\ncommandes\nHTVA',
      field: 'orders_amount',
      type: 'number',
      decimalsCount: 2,
      unit: ' €',
      width: 120,
    },
    { title: 'Nombre de\nfactures', field: 'invoices_count', width: 120 },
    {
      title: 'Total\nfactures\nHTVA',
      field: 'invoices_amount',
      type: 'number',
      decimalsCount: 2,
      unit: ' €',
      width: 120,
    },
  ];

  public old_prices: any[] = [];
  public oldPricesColumns: any[] = [
    { title: 'N° de dossier', field: 'number', width: 180 },
    { title: 'Date', type: 'date', field: 'date', width: 100 },
    { title: 'Quantité', field: 'quantity', type: 'number', decimalsCount: 2, width: 100 },
    { title: 'Unité', field: 'unit', width: 150 },
    { title: 'Client', field: 'customer', width: 150 },
    { title: 'Fournisseur', field: 'supplier', width: 150 },
    { title: "Prix d'achat", field: 'unit_price', type: 'number', decimalsCount: 8, unit: ' €', width: 120 },
    { title: 'Prix de vente', field: 'sell_price', type: 'number', decimalsCount: 8, unit: ' €', width: 120 },
  ];

  public internalNotesBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(32, 128, 128)',
    textColor: 'white',
    fields: [{ title: 'Notes internes', field: 'internal_notes', type: 'textarea' }],
  };

  public allowed_stocks: any[] = [];

  priceSupplierChange(price: Price) {
    price.changed = true;
    this.updateView(this.saleCopy);
  }

  calculateInternalFees(item: Price) {
    let fees: number = 0;
    let totalBuyPrice: number = item.totalBuyPrice;
    if (totalBuyPrice <= 250) fees = 50;
    else if (totalBuyPrice <= 750) fees = 50 + 0.2 * totalBuyPrice;
    else fees = 200;
    fees += Math.max(item.deliveries - 1, 0) * 20;
    console.log('calculated internal fees:', fees);
    item.internal_fees = fees;
  }

  updateCurrenciesInPrices(sell_currency: Currency, sell_xrate: number) {
    for (let i = 0; i < this.saleCopy.prices.length; ++i) {
      let price = this.saleCopy.prices[i];
      price.sell_currency = sell_currency;
      price.sell_xrate = sell_xrate;
    }
  }

  initView(sale: Sale) {
    console.log('init view with sale:', sale);
    if (sale == null) {
      sale = new Sale();
      sale.date = DateTimeUtil.toDateString(new Date());
      sale.creation_date = sale.date;
      sale.article = null;
      sale.accounting = AccountingsService.currentAccounting;
      sale.currency = sale.accounting.default_currency;
      sale.xrate = sale.currency.rate;
    }
    //this.selectedArticle = sale.article;
    this.sale = sale;
    this.saleCopy = this.sale.clone(true);
    //this.selectedArticle = this.saleCopy.article ? this.saleCopy.article : this.saleCopy.device;
    this.pricesGridColumns[2].listItems = { items: this.saleCopy.suppliers };
    this.loadOlderVersions();
    this.editMode = false;
    this.updateView(this.saleCopy);
    this.displayCustomerWarning();
  }

  get pricesChanged(): boolean {
    if (this.saleCopy && Array.isArray(this.saleCopy.prices)) {
      for (let i = 0; i < this.saleCopy.prices.length; ++i) {
        if (this.saleCopy.prices[i].changed === true) return true;
      }
    }
    return false;
  }
  onPriceChanged() {
    this.updateView(this.saleCopy);
  }

  updateView(sale: Sale) {
    if (sale.merchant == null && sale.id == 0 && this.merchantsData.items.length == 1)
      sale.merchant = this.merchantsData.items[0];
    sale.prices.sort((a: Price, b: Price) => {
      return a.quantity == b.quantity ? 0 : a.quantity < b.quantity ? -1 : 1;
    });

    this.customerInfoBlockField.readonly = sale.number != null;
    if (!sale.customer) sale.contact = null;
    // this.contactInfoBlockField.listItems.items = sale.customer ? sale.customer.contacts : [];
    this.contactsData.items = sale.customer ? sale.customer.contacts.filter((contact) => !contact.date_del) : [];
    this.creationDateField.visible = sale.history_version > 1;

    this.ordinatorAddressInfoBlockField.listItems.items = sale.customer
      ? [{ name: "Adresse d'ordonateur", address: sale.customer.ordinator_address }]
      : [];
    this.deliveryAddressInfoBlockField.listItems.items = sale.customer ? sale.customer.deliveryAddressesList : [];
    this.invoicingAddressInfoBlockField.listItems.items = sale.customer ? sale.customer.invoicingAddressesList : [];
    this.dicountDaysField.readonly = this.saleCopy.discount <= 0;

    Toolbar.getToolbarItem(this.toolbar, 'saveButton').visible =
      this.editMode == true || this.saleCopy.id == 0 || this.saleCopy.number == null || this.pricesChanged;
    Toolbar.getToolbarItem(this.toolbar, 'cancelButton').visible = this.editMode;
    Toolbar.getToolbarItem(this.toolbar, 'editNumberButton').visible = this.editMode;
    Toolbar.getToolbarItem(this.toolbar, 'reloadArticleButton').visible = this.editMode;
    Toolbar.getToolbarItem(this.toolbar, 'duplicateSaleButton').visible =
      this.editMode == false && this.saleCopy.id != 0 && this.saleCopy.number != null && !this.pricesChanged;
    Toolbar.getToolbarItem(this.toolbar, 'updateSaleButton').visible =
      this.editMode == false && this.saleCopy.id != 0 && this.saleCopy.number != null && !this.pricesChanged;

    Toolbar.getToolbarItem(this.toolbar, 'filterSuppliersButton').visible =
      this.editMode == true || this.saleCopy.id == 0 || this.saleCopy.number == null;
    Toolbar.getToolbarItem(this.toolbar, 'previewDemandButton').visible =
      this.editMode == false && this.saleCopy.id != 0 && this.saleCopy.number != null && !this.pricesChanged;
    Toolbar.getToolbarItem(this.toolbar, 'previewDemandButton').className = this.saleCopy.sent_to_suppliers
      ? 'check'
      : '';
    Toolbar.getToolbarItem(this.toolbar, 'previewOfferButton').visible =
      this.editMode == false && this.saleCopy.id != 0 && this.saleCopy.number != null && !this.pricesChanged;
    Toolbar.getToolbarItem(this.toolbar, 'previewOfferButton').className = this.saleCopy.sent_to_customer
      ? 'check'
      : '';
    Toolbar.getToolbarItem(this.toolbar, 'saveDocsButton').visible =
      this.editMode == false && this.saleCopy.id != 0 && this.saleCopy.number != null && !this.pricesChanged;
    // Toolbar.getToolbarItem(this.toolbar, 'sendDemandsButton').visible = (this.editMode == false && this.saleCopy.id != 0 && this.saleCopy.number != null && !this.pricesChanged);
    // Toolbar.getToolbarItem(this.toolbar, 'sendOfferButton').visible = (this.editMode == false && this.saleCopy.id != 0 && this.saleCopy.number != null && !this.pricesChanged);

    if (!(this.saleCopy.customer instanceof Customer) || this.saleCopy.customer.id == 0) this.turnovers = [];
    else {
      ServerApi.callModule('invoices', 'customerTotals', { id_customer: this.saleCopy.customer.fullId }).then(
        (result) => {
          this.turnovers = Array.isArray(result.details) ? result.details : [];
          console.log('turnovers:', this.turnovers);
        },
        (err) => {
          this.turnovers = [];
        }
      );
    }
    if (!(this.saleCopy.article instanceof ArticleBase) || this.saleCopy.article.id == 0) this.old_prices = [];
    else {
      ServerApi.callModule('sales', 'articlePrices', { id_article: this.saleCopy.article.fullId }).then(
        (result) => {
          this.old_prices = Array.isArray(result.details) ? result.details : [];
          console.log('old_prices:', this.old_prices);
        },
        (err) => {
          this.old_prices = [];
        }
      );
    }

    if (!this.saleCopy.id) this.incidents = [];
    else if (!this.incidents || !this.incidents.length) {
      ServerApi.callModule('sales', 'getIncidents', { id_sale: this.saleCopy.fullId }).then(
        (result) => {
          console.log('incidents ids:', result.details);
          Incident.load(result.details).then(
            (result2) => {
              console.log('incidents:', result2);
              this.incidents = result2;
            },
            (err2) => {
              console.error(err2);
            }
          );
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }

  public selectedOldVersion: Sale = null;

  loadOlderVersions() {
    Sale.load(
      null,
      ['number'],
      null,
      false,
      '`number` = "' + this.sale.number + '" AND `history_version` != ' + this.sale.history_version
    ).then(
      (result) => {
        this.olderVersions = result;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  oldVersionSelectChanged(event) {
    this.initView(this.selectedOldVersion);
  }

  addQuantity() {
    let price: Price = new Price();
    if (this.saleCopy.prices.length > 0) price.unit = this.saleCopy.prices[this.saleCopy.prices.length - 1].unit;
    price.buy_currency = AccountingsService.currentAccounting.default_currency;
    price.buy_xrate = price.buy_currency.rate;
    price.sell_currency = this.saleCopy.currency;
    price.sell_xrate = this.saleCopy.xrate;
    this.saleCopy.prices.push(price);
    this.saleCopy.changed = true;
  }

  deleteQuantities() {
    ArrayUtil.removeElements(this.saleCopy.prices, this.quantitiesGrid.selectedItems);
    this.saleCopy.changed = true;
  }

  // duplicateArticle()
  // {
  //     this.selectedArticle.clone(false, this.newArticle);
  //     this.selectedArticle = this.newArticle;
  //     this.saleCopy.article = this.newArticle;
  //     Toolbar.getToolbarItem(this.toolbar, 'duplicateArticleButton').visible = (this.selectedArticle.id != 0 && this.saleCopy.id == 0);
  // }

  duplicateSale() {
    let sale: Sale = this.saleCopy.clone(false);
    sale.number = null;
    sale.id = 0;
    sale.date = DateTimeUtil.toDateString(new Date());
    // this.originalSales.push(this.saleCopy);
    this.initView(sale);
  }
  updateSale(newVersion: boolean = true) {
    // this.originalSales.push(this.saleCopy);
    if (newVersion == false) {
      this.editMode = true;
      //this.sale = this.saleCopy.clone(true);
      //this.saleCopy = this.sale.clone(true);
      this.updateView(this.saleCopy);
    } else if (this.saleCopy.number != null) {
      this.saleCopy.getNextHistoryVersion().then(
        (numberResult) => {
          this.updateSale2(parseInt(numberResult.details));
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }

  updateSale2(number: number, updateRate: boolean = false) {
    let sale: Sale = this.saleCopy.clone(false);
    sale.id = 0;
    sale.history_version = number;
    console.log('sale:', sale);
    sale.date = DateTimeUtil.toDateString(new Date());
    sale.deadline = sale.customer.deadline;
    if (updateRate) sale.xrate = sale.currency.rate;
    this.initView(sale);
  }

  setSaleType() {}

  validateForm() {
    return LoadingPromise.create<any>((resolve, reject) => {
      let form: Form = {
        fields: [
          {
            name: 'Représentant',
            type: 'foreign-list',
            data: this.saleCopy,
            field: 'merchant',
            pattern: null,
            element: this.merchantBlockComponent.getElement('merchant'),
          },
          {
            name: 'Client',
            type: 'foreign-list',
            data: this.saleCopy,
            field: 'customer',
            pattern: null,
            element: this.saleBlockComponent.getElement('customer'),
          },
          {
            name: 'Fournisseurs',
            type: 'foreign-list',
            data: this.saleCopy,
            field: 'suppliers',
            pattern: null,
            element: this.saleBlockComponent.getElement('suppliers'),
          },
          {
            name: 'Délai de livraison',
            type: 'string',
            data: this.saleCopy,
            field: 'delivery_time',
            pattern: /.+/g,
            element: this.saleBlockComponent.getElement('delivery_time'),
          },
          {
            name: 'Conditions de paiement',
            type: 'string',
            data: this.saleCopy,
            field: 'deadline',
            pattern: /.+/g,
            element: this.saleBlockComponent.getElement('deadline'),
          },
          // {
          //     name: 'Nombre de livraisons',
          //     type: 'string',
          //     data: this.saleCopy,
          //     field: 'deliveries',
          //     pattern: /[0-9]+/g,
          //     element: this.saleBlockComponent.getElement('deliveries')
          // },
          // {
          //     name: 'Nombre de facturations',
          //     type: 'string',
          //     data: this.saleCopy,
          //     field: 'invoicings',
          //     pattern: /[0-9]+/g,
          //     element: this.saleBlockComponent.getElement('invoicings')
          // },
          {
            name: "Adresse d'ordonateur",
            type: 'string',
            data: this.saleCopy,
            field: 'ordinator_address_text',
            pattern: /.+/g,
            element: this.ordinatorBlockComponent.getElement('ordinator_address_text'),
          },
          {
            name: "E-mails d'ordonateur",
            type: 'string',
            data: this.saleCopy,
            field: 'ordinator_emails',
            pattern: /.+/g,
            element: this.ordinatorBlockComponent.getElement('ordinator_emails'),
          },
          {
            name: 'Adresse de facturation',
            type: 'string',
            data: this.saleCopy,
            field: 'invoicing_address_text',
            pattern: /.+/g,
            element: this.invoicingBlockComponent.getElement('invoicing_address_text'),
          },
          {
            name: 'E-mails facturation',
            type: 'string',
            data: this.saleCopy,
            field: 'invoicing_emails',
            pattern: /.+/g,
            element: this.invoicingBlockComponent.getElement('invoicing_emails'),
          },
          {
            name: 'Adresse de livraison',
            type: 'string',
            data: this.saleCopy,
            field: 'delivery_address_text',
            pattern: /.+/g,
            element: this.deliveryBlockComponent.getElement('delivery_address_text'),
          },
        ],
      };
      if (this.saleCopy.number == null) {
        form.fields.push({
          name: "Choix de l'article",
          type: 'foreign-list',
          data: this.saleCopy,
          field: 'article',
          pattern: null,
          element: this.selectBlockComponent.getElement('article'),
        });
      }

      const missingSupplierPrices = this.saleCopy.prices.filter(
        (price) => price.unitBuyPrice > 0 && (!price.supplier || !price.reference)
      );
      if (missingSupplierPrices.length > 0) {
        NotificationsComponent.push({
          title: 'Fournisseur manquant',
          type: 'error',
          summary: 'Le fournisseur et la référence fournisseur ne sont pas spécifiés pour certaines quantités',
          content: missingSupplierPrices.map((price) => price.quantity + ' ' + price.unit).join(', '),
        });
        reject('missing suppliers');
      }

      const result = FormValidator.validateForm(form);
      FormValidator.showFormInvalidFields(form, result);
      if (result !== true) {
        FormValidator.showNotification(result);
        reject(result);
      } else resolve(true);
    });
  }

  async save(checkDates: boolean = true) {
    if (!(await this.validateForm())) throw new Error('Invalid form');

    let sale_date: string = this.saleCopy.date;
    let today_date: string = DateTimeUtil.toDateString(new Date());
    if (checkDates && sale_date != today_date) {
      var response = await DialogsComponent.display({
        icon: 'question',
        title: 'Date du dossier',
        message:
          'Vous avez spécifié la date suivante pour le dossier client : <b>' +
          this.saleCopy.date +
          '</b><br/>' +
          'Voulez-vous changer pour la date du jour <b>' +
          today_date +
          '</b> ?',
        buttons: [
          { text: 'Oui, changer la date', result: DialogButton.RESULT_YES },
          { text: 'Non, merci !', result: DialogButton.RESULT_NO },
          DialogButton.cancelButton,
        ],
      });
      if (response == DialogButton.RESULT_YES) this.saleCopy.date = today_date;
      else if (response == DialogButton.RESULT_CANCEL) throw new Error('cancelled by user');
    }

    return await this.save2();
  }

  save2() {
    return new Promise<any>((resolve, reject) => {
      let self = this;
      if (this.saleCopy.history_version == 1 && !this.saleCopy.creation_date) {
        this.saleCopy.creation_date = this.saleCopy.date;
      }
      if (this.saleCopy.valid_date == '') this.saleCopy.valid_date = null;
      let promises = [];
      if (this.saleCopy.id == 0 && !this.saleCopy.number)
        promises.push(
          this.saleCopy.getNextNumber().then(
            (result) => {
              this.saleCopy.number = this.saleCopy.generateNumber(result.details);
              this.saleCopy.history_version = 1;
            },
            (err) => {
              console.error(err);
            }
          )
        );
      Promise.all(promises).then(
        (result) => {
          this.saleCopy.clone(true, self.sale);
          this.sale.save2().then(
            function (result) {
              self.initView(self.sale);
              resolve(result);
            },
            function (err) {
              reject(err);
            }
          );
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  cancelChanges() {
    // if (this.originalSales.length > 0) this.initView(this.originalSales.pop());
    this.initView(this.sale);
  }

  editNumber() {
    DialogsComponent.displayComponent(ChangeSaleNumberComponent, { newNumber: this.saleCopy.number }).then((result) => {
      if (result !== DialogButton.RESULT_OK && typeof result === 'string') this.saleCopy.number = result;
    });
  }

  reloadArticle(notify: boolean = true) {
    return ArticleBase.load(
      null,
      null,
      null,
      null,
      'old_id=' + this.saleCopy.article.id + ' AND db_old_id=' + this.saleCopy.article.db_id
    ).then(
      (result: ArticleBase[]) => {
        if (Array.isArray(result) && result.length > 0) {
          this.saleCopy.article = result[0];
          this.reloadArticle(false).then(
            (result) => {
              if (notify)
                NotificationsComponent.push({
                  type: 'success',
                  title: 'Article mis à jour',
                  summary: "L'article a été rechargé avec succès.",
                });
            },
            (err) => {
              if (notify)
                NotificationsComponent.push({
                  type: 'error',
                  title: 'Echec du chargement',
                  summary: "L'article n'a pas pu être rechargé.",
                });
              console.error(err);
            }
          );
        } else {
          if (notify)
            NotificationsComponent.push({
              type: 'warning',
              title: 'Echec du chargement',
              summary: "L'article n'a pas pu être rechargé. Il s'agit peut-être de la dernière version ?",
            });
        }
      },
      (err) => {
        if (notify)
          NotificationsComponent.push({
            type: 'error',
            title: 'Echec du chargement',
            summary: "L'article n'a pas pu être rechargé.",
          });
        console.error(err);
      }
    );
  }

  openArticleView() {
    ArticleBase.load([this.saleCopy.article.fullId]).then(
      (result: ArticleBase[]) => {
        ViewsComponent.openView(ArticleViewComponent, result[0]);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  ngOnInit() {
    let self = this;
    this.accountingsData.items = AccountingsService.accountings;
    this.currenciesData.items = CurrenciesService.currencies;
    let loadingPromises: any[] = [];
    loadingPromises.push(
      Merchant.load().then(
        function (result: Merchant[]) {
          self.merchantsData.items = CredentialsService.isUserAllowed('dossier_commercial_choix_tous_representants')
            ? result
            : result.filter((value: Merchant, index, array) => {
                return value.user && value.user.fullId == CredentialsService.loggedUser.fullId;
              });
        },
        function (err) {
          console.error('merchants loading error:', err);
        }
      )
    );
    loadingPromises.push(
      Nomenclature.load(null, ['name']).then(
        function (result: Nomenclature[]) {
          self.nomenclaturesData.items = result.filter((value: Nomenclature, index, array) => {
            return value.name.match(/^[0-9A-Z]{3}/);
          });
        },
        function (err) {
          console.log(err);
        }
      )
    );
    loadingPromises.push(
      Customer.load(null, ['name'], null, false, 'valid = 1').then(
        function (result) {
          self.customersData.items = result;
        },
        function (err) {
          console.log(err);
        }
      )
    );
    loadingPromises.push(
      DeliveryTime.load(null, ['name']).then(
        function (result) {
          self.deliveryTimeData.items = result;
        },
        function (err) {
          console.log(err);
        }
      )
    );
    loadingPromises.push(
      CustomerReference.load(null, ['name']).then(
        function (result) {
          self.customerReferenceData.items = result;
        },
        function (err) {
          console.log(err);
        }
      )
    );
    loadingPromises.push(
      Deadline.load(null, ['name']).then(
        function (result) {
          self.deadlineData.items = result;
        },
        function (err) {
          console.log(err);
        }
      )
    );
    loadingPromises.push(
      Supplier.load(null, ['name']).then(
        function (result) {
          self.suppliers = result;
          self.updateSuppliersList();
        },
        function (err) {
          console.log(err);
        }
      )
    );
    loadingPromises.push(
      Device.load().then(
        (result) => {
          this.addArticles(result);
        },
        (err) => {
          console.log('articles loading failed:', err);
        }
      )
    );
    loadingPromises.push(
      Article.load(null, ['designation']).then(
        (result) => {
          this.addArticles(result);
        },
        (err) => {
          console.log(err);
        }
      )
    );
    loadingPromises.push(
      Service.load(null, ['designation']).then(
        (result) => {
          this.addArticles(result);
        },
        (err) => {
          console.log(err);
        }
      )
    );
    loadingPromises.push(
      RollingDirection.load(null, ['name']).then(
        (result) => {
          this.rollingsData.items = result;
        },
        (err) => {
          console.error('rollings loading failed', err);
        }
      )
    );
    Promise.all(loadingPromises).then((result) => {
      this.updateView(this.saleCopy);
    });
  }

  priceBackColor(r, g, b, index, count) {
    if (count > 0) {
      let ratio = ((count - index - 1) / count) * 0.75;
      //console.log('ratio:', ratio);
      r = Math.round(r + (255 - r) * ratio);
      g = Math.round(g + (255 - g) * ratio);
      b = Math.round(b + (255 - b) * ratio);
    }
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  }
  priceTextColor(index, count) {
    if (index >= count / 2 || count == 1) return 'white';
    else return 'black';
  }

  public showHistory: boolean = false;
  showVersion(version) {
    this.showHistory = false;
    this.selectedOldVersion = version;
    this.oldVersionSelectChanged(null);
  }

  public iterateFiles(includeOffer: boolean = true, includeDemands: boolean = true, callback) {
    let models: PrintingModel[] = [];
    if (includeOffer === true) models.push(PriceOfferPrintingModel.generate(this.saleCopy));
    if (includeDemands === true) {
      for (let i = 0; i < this.saleCopy.suppliers.length; ++i)
        models.push(PriceDemandPrintingModel.generate(this.saleCopy, i));
    }
    PrintPreviewViewComponent.globalInstance.iteratePrintingModels(models, callback);
  }

  public saveFiles() {
    this.iterateFiles(true, true, (model: PrintingModel, index: number) => {
      let path: string = '';
      let num: string = this.saleCopy.numberWithVersion.replace(/\//g, '-');
      if (model instanceof PriceDemandPrintingModel)
        path = FilesystemUtil.resolveSpecialPath(
          SettingsComponent.get('pdf_price_demand_save_path').replace('%NUMBER%', num)
        );
      else if (model instanceof PriceOfferPrintingModel)
        path = FilesystemUtil.resolveSpecialPath(
          SettingsComponent.get('pdf_price_offer_save_path').replace('%NUMBER%', num)
        );
      return PrintPreviewViewComponent.globalInstance.saveFileAs(path + model.saveFilename);
    });
  }

  public sendDemands() {
    this.iterateFiles(false, true, (model: PrintingModel, index: number) => {
      let lang: string = this.saleCopy.suppliers[index].lang ? this.saleCopy.suppliers[index].lang.code || 'fr' : 'fr';
      return PrintPreviewViewComponent.globalInstance.sendByMail(
        this.saleCopy.suppliers[index].commercial_address.email,
        null,
        null,
        {
          fr: 'Demande de prix n°' + this.saleCopy.number,
          en: 'Quotation request no. ' + this.saleCopy.number,
          nl: 'Quotation request no. ' + this.saleCopy.number,
        }[lang],
        {
          fr: '<p>Madame, Monsieur,</p><p>Pourriez-vous nous remettre prix pour la fourniture éventuelle de cet article (voir pièce jointe) ?</p><p>Cordialement,</p>',
          en: '<p>Dear Partner,</p><p>Could you please provide pricing for the possible supply of the attached item(s) ?</p><p>Thanking you in advance,</p>',
          nl: '<p>Dear Partner,</p><p>Could you please provide pricing for the possible supply of the attached item(s) ?</p><p>Thanking you in advance,</p>',
        }[lang],
        model.mailFilename
      );
    });
  }

  public sendOffer() {
    this.iterateFiles(true, false, (model: PrintingModel, index: number) => {
      let lang: string = this.saleCopy.customer.lang ? this.saleCopy.customer.lang.code || 'fr' : 'fr';
      return PrintPreviewViewComponent.globalInstance.sendByMail(
        this.saleCopy.ordinator_emails,
        null,
        null,
        {
          fr: 'Votre offre de prix n°' + this.saleCopy.number,
          en: 'Quotation no. ' + this.saleCopy.number,
          nl: 'Quotation no. ' + this.saleCopy.number,
        }[lang],
        {
          fr: '<p>Madame, Monsieur,</p>Suite à votre demande de prix, veuillez trouver ci-joint notre meilleure offre.</p><p>Cordialement,</p>',
          en: '<p>Dear Sir / Madam,</p>Following your price request, please find attached our best offer.</p><p>Yours faithfully,</p>',
          nl: '<p>Dear Sir / Madam,</p>Following your price request, please find attached our best offer.</p><p>Yours faithfully,</p>',
        }[lang],
        model.mailFilename
      );
    });
  }

  public onDemandSent = () => {
    this.saleCopy.sent_to_suppliers = 1;
    return this.save(false);
  };

  public onOfferSent = () => {
    this.saleCopy.sent_to_customer = 1;
    return this.save(false);
  };

  public previewDemand() {
    this.updateSuppliersList();
    this.validateForm().then(
      (result) => {
        let view: PrintPreviewViewComponent = ViewsComponent.openView(
          PrintPreviewViewComponent,
          PriceDemandPrintingModel,
          this.saleCopy
        ) as PrintPreviewViewComponent;
        let mail_to: string[] = [];
        let mail_cc: string[] = [];
        let mail_bcc: string[] = [];
        let mail_subject: string[] = [];
        let mail_body: string[] = [];
        let mail_filename: string[] = [];
        let suppliers: Supplier[] = this.saleCopy.suppliers;
        for (let i = 0; i < suppliers.length; ++i) {
          let lang: string = suppliers[i].lang ? suppliers[i].lang.code || 'fr' : 'fr';
          mail_to.push(suppliers[i].commercial_address.email);
          mail_cc.push(null);
          mail_bcc.push(null);
          mail_subject.push(
            {
              fr: 'Demande de prix n°' + this.saleCopy.number,
              en: 'Quotation request no. ' + this.saleCopy.number,
              nl: 'Quotation request no. ' + this.saleCopy.number,
            }[lang]
          );
          mail_body.push(
            {
              fr:
                '<p>Bonjour,</p>' +
                "<p>Pourriez-vous, s'il vous plaît, nous envoyer votre meilleure offre concernant notre demande de prix en pièce-jointe ?</p>" +
                '<p>Par avance merci,</p>' +
                '<p>Cordialement,</p>',
              en:
                '<p>Dear Partner,</p>' +
                '<p>Could you please provide pricing for the possible supply of the attached item(s) ?</p>' +
                '<p>Thanking you in advance,</p>' +
                '<p>Best regards,</p>',
              nl:
                '<p>Dear Partner,</p>' +
                '<p>Could you please provide pricing for the possible supply of the attached item(s) ?</p>' +
                '<p>Thanking you in advance,</p>' +
                '<p>Best regards,</p>',
            }[lang]
          );
        }
        view.mail_to = mail_to;
        view.mail_cc = mail_cc;
        view.mail_bcc = mail_bcc;
        view.mail_subject = mail_subject;
        view.mail_body = mail_body;
        view.onSendByMailClicked = this.onDemandSent;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  public previewOffers() {
    this.updateSuppliersList();
    this.validateForm().then(
      (result) => {
        let view: PrintPreviewViewComponent = ViewsComponent.openView(
          PrintPreviewViewComponent,
          PriceOfferPrintingModel,
          this.saleCopy
        ) as PrintPreviewViewComponent;
        let lang: string = this.saleCopy.customer.lang ? this.saleCopy.customer.lang.code || 'fr' : 'fr';
        let mail_to: string[] = [this.saleCopy.ordinator_emails];
        let mail_cc: string[] = [null];
        let mail_bcc: string[] = [null];
        let mail_subject: string[] = [
          {
            fr: 'Votre offre de prix n°' + this.saleCopy.number,
            en: 'Quotation no. ' + this.saleCopy.number,
            nl: 'Quotation no. ' + this.saleCopy.number,
          }[lang],
        ];
        let mail_body: string[] = [
          {
            fr:
              '<p>Madame, Monsieur,</p>' +
              "<p>À la suite de votre demande de prix pour laquelle nous vous remercions, veuillez s'il vous plaît, trouver notre devis en pièce jointe.</p>" +
              `<p>Merci de bien vouloir envoyer votre commande à l\'adresse : <a href="mailto:${config.orderMail}">${config.orderMail}</a></p>` +
              `<p>Nous vous remercions pour l\'intérêt que vous portez à la démarche de ${config.companyName}.</p>` +
              "<p>Dans l'attente de votre ordre, veuillez agréer, Madame, Monsieur, nos salutations les plus sincères.</p>" +
              '<p>Cordialement,</p>',
            en:
              '<p>Dear Sir / Madam,</p>' +
              '<p>Following your price request, please find attached our best offer.</p>' +
              `<p>Please send your order to the following email address : <a href="mailto:${config.orderMail}">${config.orderMail}</a></p>` +
              `<p>Thank you for the interest you take in ${config.companyName}.</p>` +
              '<p>Yours faithfully,</p>',
            nl:
              '<p>Dear Sir / Madam,</p>' +
              '<p>Following your price request, please find attached our best offer.</p>' +
              `<p>Please send your order to the following email address : <a href="mailto:${config.orderMail}">${config.orderMail}</a></p>` +
              `<p>Thank you for the interest you take in ${config.companyName}.</p>` +
              '<p>Yours faithfully,</p>',
          }[lang],
        ];
        view.mail_to = mail_to;
        view.mail_cc = mail_cc;
        view.mail_bcc = mail_bcc;
        view.mail_subject = mail_subject;
        view.mail_body = mail_body;
        view.onSendByMailClicked = this.onOfferSent;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  public previewTechnical() {
    this.updateSuppliersList();
    this.validateForm().then(
      (result) => {
        let view: PrintPreviewViewComponent = ViewsComponent.openView(
          PrintPreviewViewComponent,
          TechnicalSheetPrintingModel,
          this.saleCopy
        ) as PrintPreviewViewComponent;
        let lang: string = this.saleCopy.customer.lang ? this.saleCopy.customer.lang.code || 'fr' : 'fr';
        let mail_to: string[] = [this.saleCopy.ordinator_emails];
        let mail_cc: string[] = [null];
        let mail_bcc: string[] = [null];
        let mail_subject: string[] = [
          {
            fr: 'Fiche technique n°' + this.saleCopy.number,
            en: 'Technical sheet no. ' + this.saleCopy.number,
            nl: 'Technical sheet no. ' + this.saleCopy.number,
          }[lang],
        ];
        view.mail_to = mail_to;
        view.mail_cc = mail_cc;
        view.mail_bcc = mail_bcc;
        view.mail_subject = mail_subject;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  public previewLabels() {
    this.updateSuppliersList();
    this.validateForm().then(
      (result) => {
        let view: PrintPreviewViewComponent = ViewsComponent.openView(
          PrintPreviewViewComponent,
          StickersPrintingModel,
          this.saleCopy
        ) as PrintPreviewViewComponent;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  public displayCustomerWarning() {
    if (this.saleCopy && this.saleCopy.customer && this.saleCopy.customer.warning) {
      DialogsComponent.display({
        title: 'Avertissement client',
        message: `<big><strong style="font-size: 120%;">${this.saleCopy.customerName}</strong></big><p>${this.saleCopy.customer.warning}</p>`,
        buttons: DialogButton.okOnlyButtons,
        icon: 'warning',
      });
    }
  }
}
