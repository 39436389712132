import { ObjectModel2 } from "src/app/classes/objects/ObjectModel2.class";
import { ObjectDefinition } from "src/app/classes/objects/ObjectDefinition.class";
import { ObjectModel3 } from "src/app/classes/objects/ObjectModel3.class";


export class ReminderType extends ObjectModel3
{
    public name: string = null;
    public name_en: string = null;
    public name_nl: string = null;
    public back_color: string = 'rgb(255,255,255)';
    public text_color: string = 'rgb(0,0,0)';

    constructor()
    {
        super(ReminderType);
    }

    /* ObjectModel FUNCTIONS */

    // public static instances: { [id: number]: Address; } = {};

    public static definition: ObjectDefinition = {
        database: {
            table: 'reminder_types',
            id: 'id',
			db_id: 'db_id'
        },
        values: {
            'name': { type: 'string' },
            'name_en': { type: 'string' },
            'name_nl': { type: 'string' },
            'back_color': { type: 'string' },
            'text_color': { type: 'string' },
        },
        children: {},
        links: {}
    };

}
