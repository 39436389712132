<!-- <select [(ngModel)]="selectedObject"> -->
<div class="combo-box">
    <input #elementRef type="text" [(ngModel)]="value" class="combo-box-input" (focus)="toggleList(true)" (blur)="blurInput($event)" (click)="toggleList()" (change)="filterList($event)" />
    <span class="combo-box-input-arrow" (click)="toggleList(true)">{{ showList === true ? '&#9650;' : '&#9660;' }}</span>
    <div class="combo-box-options-overlay" *ngIf="showList" (click)="showList = false"></div>
    <div class="combo-box-options" [ngStyle]="{'height': (100*linesCount)+'%'}" *ngIf="showList" [ngClass]="{'combo-box-options-inverse': inverseList}">
        <div class="combo-box-option" *ngFor="let object of filteredObjects" (mousedown)="itemMouseDown($event)" (mouseup)="itemClick(object, $event)">{{ object[fieldName] }}</div>
    </div>
</div>
<!-- </select> -->
