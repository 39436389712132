import * as am4charts from '@amcharts/amcharts4/charts';

import { ApiService } from 'src/app/services/api/api.service';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { ObjectUtil } from '../../utils/classes/ObjectUtil.class';
import { ReportCell } from '../../reports/classes/ReportCell.class';
import { ReportHeaderRow } from '../../reports/classes/ReportHeaderRow.class';
import { ReportModel } from '../../reports/classes/ReportModel.class';
import { ReportRow } from '../../reports/classes/ReportRow.class';
import { ReportSetting } from '../../reports/classes/ReportSetting.class';
import { StatsModule } from '../stats.module';

export class YearlyOrdersSplitReportModel extends ReportModel {
  public title: string = 'REPARTITION DES COMMANDES';
  public settings: ReportSetting[] = [
    { name: 'year_start', title: 'Année de début', type: 'number', value: new Date().getFullYear() - 4 },
    { name: 'year_end', title: 'Année de fin', type: 'number', value: new Date().getFullYear() },
  ];

  public tableStyle: any = {
    border: '2px solid black',
  };

  public headerRows: ReportHeaderRow[] = [{ cells: [] }];
  public rows: ReportRow[] = [];
  public points: any[] = [];
  public tri_points: any[] = [];

  public static titleOrTotal_CellStyle: any = {
    'background-color': 'rgb(96,73,122)',
    color: 'white',
    'font-weight': 'bold',
    'border-top': '2px solid black',
    'border-bottom': '2px solid black',
    'text-align': 'center',
  };
  public static split_CellStyle: any = {
    background: 'none',
    'border-left': '2px solid black',
    'border-right': '2px solid black',
    'border-top': 'none',
    'border-bottom': 'none',
    width: '40px',
  };
  public static subTotal_CellStyle: any = {
    'background-color': 'rgb(140, 117, 160)',
    color: 'white',
    'font-weight': 'bold',
    'text-align': 'center',
    border: '1px solid #ccc',
  };
  public static body_CellStyle: any = {
    // 'background-color': 'rgb(217, 217, 217)',
    color: 'black',
  };
  public static bodyTotal_CellStyle: any = {
    'background-color': 'rgb(217, 217, 217)',
    color: 'black',
    'font-weight': 'bold',
  };
  public static month_CellStyle: any = {
    'background-color': 'rgb(204, 192, 218)',
    color: 'black',
    'font-weight': 'bold',
    'text-align': 'center',
  };

  public static generate(settings: ReportSetting[] = null) {
    let model: YearlyOrdersSplitReportModel = new YearlyOrdersSplitReportModel();
    if (settings) model.settings = settings;
    // model.regenerate();
    return model;
  }

  public regenerate() {
    return LoadingPromise.create<any>((resolve, reject) => {
      let month_names: string[] = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Aout',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ];
      let tri_month_names: string[] = ['1er trimestre', '2ème trimestre', '3ème trimestre', '4ème trimestre'];

      let year: number = parseInt(this.getSetting('year_end').value);
      let years: number =
        parseInt(this.getSetting('year_end').value) - parseInt(this.getSetting('year_start').value) + 1;

      this.headerRows = [
        {
          cells: [
            {
              value: 'REPARTITION ANNUELLE DES COMMANDES (H.T.V.A.)',
              colSpan: years + 1,
              style: YearlyOrdersSplitReportModel.titleOrTotal_CellStyle,
            },
            { value: '&nbsp;', style: YearlyOrdersSplitReportModel.split_CellStyle, rowSpan: 20 },
            {
              value: 'MARGES NETTES DÉGAGÉES',
              colSpan: years * 2,
              style: YearlyOrdersSplitReportModel.titleOrTotal_CellStyle,
            },
          ],
        },
        { cells: [{ value: '&nbsp;', style: YearlyOrdersSplitReportModel.subTotal_CellStyle, rowSpan: 2 }] },
        { cells: [] },
      ];
      if (years <= 0) reject('number of years must be >= 0');
      this.points = [];
      this.tri_points = [];
      for (let i = 0; i < 12; ++i) this.points.push({ name: month_names[i], month: i });
      for (let i = 0; i < 4; ++i) this.tri_points.push({ name: tri_month_names[i], month: i });
      for (let y = 0; y < years; ++y) {
        this.headerRows[1].cells.push({
          value: '' + (year - years + y + 1),
          rowSpan: 2,
          style: YearlyOrdersSplitReportModel.month_CellStyle,
        });
      }
      for (let y = 0; y < years; ++y) {
        this.headerRows[1].cells.push({
          value: '' + (year - years + y + 1),
          colSpan: 2,
          style: YearlyOrdersSplitReportModel.month_CellStyle,
        });
        this.headerRows[2].cells.push({ value: '€', style: YearlyOrdersSplitReportModel.month_CellStyle });
        this.headerRows[2].cells.push({ value: '%', style: YearlyOrdersSplitReportModel.month_CellStyle });
      }

      this.rows = [];

      ApiService.callModule('reports', 'report', {
        query: 'monthly_orders',
        settings: { year_start: this.getSetting('year_start').value, year_end: this.getSetting('year_end').value },
      }).then(
        (result2) => {
          console.log('result:', result2);
          let amounts_month: number[] = [];
          let amounts_year: number[] = [];
          let amounts: number[][] = [];
          let costs_month: number[] = [];
          let costs_year: number[] = [];
          let costs: number[][] = [];
          let margins_month: number[] = [];
          let margins_year: number[] = [];
          let margins: number[][] = [];
          let month_count: number[] = [];
          for (let i = 0; i < result2.details.length; ++i) {
            let year_str: any = result2.details[i]['year'];
            let year: number = parseFloat(year_str);
            let month_str: any = result2.details[i]['month'];
            let month: number = parseFloat(month_str);
            let amount_str: any = result2.details[i]['amount'];
            let amount: number = parseFloat(amount_str);
            let margin_str: any = result2.details[i]['net_margin'];
            let margin: number = parseFloat(margin_str);
            let cost_str: any = result2.details[i]['cost_price'];
            let cost: number = parseFloat(cost_str);
            if (year >= 1 && month >= 1) {
              if (!amounts[year]) amounts[year] = [];
              if (!costs[year]) costs[year] = [];
              if (!margins[year]) margins[year] = [];
              if (amount && amount != 0) amounts[year][month - 1] = amount;
              if (cost && cost != 0) costs[year][month - 1] = cost;
              if (margin && margin != 0) margins[year][month - 1] = margin;
            }
          }

          for (let i = 0; i < 12; ++i) {
            try {
              let cells: ReportCell[] = [
                {
                  value: [
                    'Janvier',
                    'Février',
                    'Mars',
                    'Avril',
                    'Mai',
                    'Juin',
                    'Juillet',
                    'Aout',
                    'Septembre',
                    'Octobre',
                    'Novembre',
                    'Décembre',
                  ][i],
                  style: YearlyOrdersSplitReportModel.month_CellStyle,
                },
              ];
              for (let y = year - years + 1; y <= year; ++y) {
                let amount: number = amounts[y] ? amounts[y][i] || 0 : 0;
                cells.push({
                  value: amount && amount != 0 ? NumberUtil.formatMoney(amount, '€', 2, '.') : '',
                  style: ObjectUtil.merge(YearlyOrdersSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                });
                this.points[i][y] = amount;
              }
              for (let y = year - years + 1; y <= year; ++y) {
                let amount: number = amounts[y] ? amounts[y][i] || 0 : 0;
                let cost: number = costs[y] ? costs[y][i] || 0 : 0;
                let margin: number = margins[y] ? margins[y][i] || 0 : 0;
                let margin_perc: number = cost ? margin / cost : 0;
                cells.push(
                  {
                    value: margin && margin != 0 ? NumberUtil.formatMoney(margin, '€', 2, '.') : '',
                    style: ObjectUtil.merge(YearlyOrdersSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                  {
                    value:
                      margin_perc && margin_perc != 0 ? NumberUtil.formatMoney(margin_perc * 100, '%', 1, '.') : '',
                    style: ObjectUtil.merge(YearlyOrdersSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  }
                );
                if (amount != 0) {
                  if (!month_count[y]) month_count[y] = 0;
                  ++month_count[y];
                }
                if (!amounts_month[y]) amounts_month[y] = 0;
                amounts_month[y] += amount;
                if (!costs_month[y]) costs_month[y] = 0;
                costs_month[y] += cost;
                if (!margins_month[y]) margins_month[y] = 0;
                margins_month[y] += margin;
                if (!amounts_year[y]) amounts_year[y] = 0;
                amounts_year[y] += amount;
                if (!costs_year[y]) costs_year[y] = 0;
                costs_year[y] += cost;
                if (!margins_year[y]) margins_year[y] = 0;
                margins_year[y] += margin;
              }
              this.rows.push({
                cells: cells,
              });

              if ((i + 1) % 3 == 0) {
                let j: number = Math.floor(i / 3);
                let cells: ReportCell[] = [
                  {
                    value: ['1er trimestre', '2ème trimestre', '3ème trimestre', '4ème trimestre'][Math.floor(i / 3)],
                    style: YearlyOrdersSplitReportModel.subTotal_CellStyle,
                  },
                ];
                for (let y = year - years + 1; y <= year; ++y) {
                  let amount_month: number = amounts_month[y] || 0;
                  cells.push({
                    value: amount_month && amount_month != 0 ? NumberUtil.formatMoney(amount_month, '€', 2, '.') : '',
                    style: ObjectUtil.merge(YearlyOrdersSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
                  });
                  this.tri_points[j][y] = amount_month;
                }
                for (let y = year - years + 1; y <= year; ++y) {
                  let amount_month: number = amounts_month[y] || 0;
                  let cost_month: number = costs_month[y] || 0;
                  let margin_month: number = margins_month[y] || 0;
                  let margin_perc_month: number = cost_month ? margin_month / cost_month : 0;
                  cells.push(
                    {
                      value: margin_month && margin_month != 0 ? NumberUtil.formatMoney(margin_month, '€', 2, '.') : '',
                      style: ObjectUtil.merge(
                        YearlyOrdersSplitReportModel.subTotal_CellStyle,
                        ReportCell.moneyCellStyle
                      ),
                    },
                    {
                      value:
                        margin_perc_month && margin_perc_month != 0
                          ? NumberUtil.formatMoney(margin_perc_month * 100, '%', 1, '.')
                          : '',
                      style: ObjectUtil.merge(
                        YearlyOrdersSplitReportModel.subTotal_CellStyle,
                        ReportCell.moneyCellStyle
                      ),
                    }
                  );
                  amounts_month[y] = 0;
                  costs_month[y] = 0;
                  margins_month[y] = 0;
                }
                this.rows.push({
                  cells: cells,
                });
              }
            } catch {
              continue;
            }
          }

          let cells: ReportCell[] = [{ value: 'TOTAUX', style: YearlyOrdersSplitReportModel.subTotal_CellStyle }];
          for (let y = year - years + 1; y <= year; ++y) {
            let amount_year: number = amounts_year[y] || 0;
            cells.push({
              value: amount_year && amount_year != 0 ? NumberUtil.formatMoney(amount_year, '€', 2, '.') : '',
              style: ObjectUtil.merge(YearlyOrdersSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
            });
          }
          for (let y = year - years + 1; y <= year; ++y) {
            let amount_year: number = amounts_year[y] || 0;
            let cost_year: number = costs_year[y] || 0;
            let margin_year: number = margins_year[y] || 0;
            let margin_perc_year: number = cost_year ? margin_year / cost_year : 0;
            cells.push(
              {
                value: margin_year && margin_year != 0 ? NumberUtil.formatMoney(margin_year, '€', 2, '.') : '',
                style: ObjectUtil.merge(YearlyOrdersSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value:
                  margin_perc_year && margin_perc_year != 0
                    ? NumberUtil.formatMoney(margin_perc_year * 100, '%', 1, '.')
                    : '',
                style: ObjectUtil.merge(YearlyOrdersSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              }
            );
          }
          this.rows.push({
            cells: cells,
          });

          // let total_year: number = amount_year + vat_year;
          // let margin_perc_year: number = margin_year / amount_year;
          // this.rows.push({
          //     cells: [
          //         { value: "TOTAUX", style: YearlyOrdersSplitReportModel.titleOrTotal_CellStyle },
          //         { value: amount_year && amount_year != 0 ? NumberUtil.formatMoney(amount_year, '€', 2, '.') : '', style: ObjectUtil.merge(YearlyOrdersSplitReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle) },
          //         { value: vat_year && vat_year != 0 ? NumberUtil.formatMoney(vat_year, '€', 2, '.') : '', style: ObjectUtil.merge(YearlyOrdersSplitReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle) },
          //         { value: total_year && total_year != 0 ? NumberUtil.formatMoney(total_year, '€', 2, '.') : '', style: ObjectUtil.merge(YearlyOrdersSplitReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle) },
          //         { value: margin_year && margin_year != 0 ? NumberUtil.formatMoney(margin_year, '€', 2, '.') : '', style: ObjectUtil.merge(YearlyOrdersSplitReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle) },
          //         { value: margin_perc_year && margin_perc_year != 0 ? NumberUtil.formatMoney(margin_perc_year*100, '%', 1, '.') : '', style: ObjectUtil.merge(YearlyOrdersSplitReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle) }
          //     ]
          // });

          // let amount_avg: number = (month_count > 0) ? amount_year / month_count : 0;
          // let vat_avg: number = (month_count > 0) ? vat_year / month_count : 0;
          // let total_avg: number = (month_count > 0) ? total_year / month_count : 0;
          // let margin_avg: number = (month_count > 0) ? margin_year / month_count : 0;
          // let margin_perc_avg: number = margin_avg / amount_avg;
          // this.rows.push({
          //     cells: [
          //         { value: "Moy. mens.", style: YearlyOrdersSplitReportModel.subTotal_CellStyle },
          //         { value: amount_avg && amount_avg != 0 ? NumberUtil.formatMoney(amount_avg, '€', 2, '.') : '', style: ObjectUtil.merge(YearlyOrdersSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle) },
          //         { value: vat_avg && vat_avg != 0 ? NumberUtil.formatMoney(vat_avg, '€', 2, '.') : '', style: ObjectUtil.merge(YearlyOrdersSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle) },
          //         { value: total_avg && total_year != 0 ? NumberUtil.formatMoney(total_avg, '€', 2, '.') : '', style: ObjectUtil.merge(YearlyOrdersSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle) },
          //         { value: margin_avg && margin_avg != 0 ? NumberUtil.formatMoney(margin_avg, '€', 2, '.') : '', style: ObjectUtil.merge(YearlyOrdersSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle) },
          //         { value: margin_perc_avg && margin_perc_avg != 0 ? NumberUtil.formatMoney(margin_perc_avg*100, '%', 1, '.') : '', style: ObjectUtil.merge(YearlyOrdersSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle) }
          //     ]
          // });

          //this.createEmptyCells();

          let series: any[] = [];
          let tri_series: any[] = [];
          for (let y = year - years + 1; y <= year; ++y) {
            series.push({
              type: 'ColumnSeries',
              name: y,
              dataFields: { valueY: y, categoryX: 'name' },
            });
            tri_series.push({
              type: 'ColumnSeries',
              name: y,
              dataFields: { valueY: y, categoryX: 'name' },
            });
          }

          this.charts = [];

          this.charts.push({
            type: am4charts.XYChart,
            inline: false,
            titles: [{ text: 'Répartition mensuelle des commandes', fontSize: 25, marginBottom: 30 }],
            series: series,
            colors: StatsModule.chartsColor,
            // maskBullets: false,
            data: this.points,
            yAxes: [
              {
                type: 'ValueAxis',
                id: 'numbers',
                dataFields: { value: 'value', category: 'name' },
                title: { text: 'Montants' },
              },
            ],
            xAxes: [
              {
                type: 'CategoryAxis',
                dataFields: { value: 'value', category: 'name' },
                renderer: { cellStartLocation: 0.1, cellEndLocation: 0.9 },
              },
            ],
            legend: {
              numberFormatter: {
                numberFormat: '#.',
              },
            },
            container_width: 1200,
            container_height: 480,
            events: {
              beforedatavalidated: function (ev) {
                console.log('beforedatavalidated event:', ev);
                ev.target.data.sort((a, b) => {
                  return a.month - b.month;
                });
              },
            },
          });

          this.charts.push({
            type: am4charts.XYChart,
            inline: false,
            titles: [{ text: 'Répartition trimestrielle des commandes', fontSize: 25, marginBottom: 30 }],
            series: tri_series,
            colors: StatsModule.chartsColor,
            // maskBullets: false,
            data: this.tri_points,
            yAxes: [
              {
                type: 'ValueAxis',
                id: 'numbers',
                dataFields: { value: 'value', category: 'name' },
                title: { text: 'Montants' },
              },
            ],
            xAxes: [
              {
                type: 'CategoryAxis',
                dataFields: { value: 'value', category: 'name' },
                renderer: { cellStartLocation: 0.1, cellEndLocation: 0.9 },
              },
            ],
            legend: {
              numberFormatter: {
                numberFormat: '#.',
              },
            },
            container_width: 1200,
            container_height: 480,
            events: {
              beforedatavalidated: function (ev) {
                console.log('beforedatavalidated event:', ev);
                ev.target.data.sort((a, b) => {
                  return a.month - b.month;
                });
              },
            },
          });

          resolve(true);
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }
}
