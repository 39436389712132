<div class="chars-table-wrapper" *ngIf="visible" (click)="visible = false">
    <div class="chars-table" (click)="$event.stopPropagation()">
        <div>
            <div class="arrow" (click)="prevPage()">
                <i class="fa fa-chevron-left"></i>
            </div>
            <div class="symbols">
                <div class="symbols-line" *ngFor="let row of rows">
                    <div class="symbol" *ngFor="let col of columns" [ngClass]="{ 'active': activeChar == row*20+col+start }"
                        (click)="charClick(row*20+col+start, $event)">
                        <span [innerHTML]="getChar(row*20+col+start)"></span>
                    </div>
                </div>
            </div>
            <div class="arrow" (click)="nextPage()">
                <i class="fa fa-chevron-right"></i>
            </div>
        </div>
        <div class="bottom">
            <input type="text" #symbolsInput [(ngModel)]="selectedSymbols" />
            <button class="btn btn-primary" (click)="copySymbols()">Copier</button>
        </div>
    </div>
</div>