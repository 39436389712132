import { ObjectModel2 } from "src/app/classes/objects/ObjectModel2.class";
import { ObjectDefinition } from "src/app/classes/objects/ObjectDefinition.class";
import { ObjectModel3 } from "src/app/classes/objects/ObjectModel3.class";


export class Setting extends ObjectModel3
{
    public name: string = null;
    public category: string = null;
    public value: string = null;

    public constructor(category = null)
    {
        super(Setting);
        this.category = category;
    }

    /* ObjectModel FUNCTIONS */

    public static definition: ObjectDefinition = {
        database: {
            table: 'settings',
            id: 'id',
			db_id: 'db_id'
        },
        values: {
            'name': { type: 'string' },
            'category': { type: 'string' },
            'value': { type: 'string' }
        },
        children: {},
        links: {}
    };
}
