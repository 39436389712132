import { InfoBlock } from "src/app/components/info-block/classes/InfoBlock.class";
import { InfoBlockComponent } from "src/app/components/info-block/info-block.component";
import { InfoBlockField } from "src/app/components/info-block/classes/InfoBlockField.class";

export class ArticleInfoBlocks
{
    public static generateArticleBlock(nomenclaturesData, rollingsData, fieldsFunc = null): InfoBlock {
        if (!nomenclaturesData) nomenclaturesData = { items: [] };
        if (!rollingsData) nomenclaturesData = { items: [] };
        const rollingField: any = {
          title: 'Sens d\'enroulement (texte)', field: 'field9', type: 'combo-box', listItems: rollingsData, listField: 'name', readonly: false,
          comment: 'Sélectionnez ou entrez ici le sens d\'enroulement. Ce champ sera ignoré si une image est sélectionnée dans le champ précédent.',
          change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
        };
        return {
            title: "Informations\narticle consommable",
            backColor: 'rgb(0,156,218)',
            textColor: 'white',
            objectLoaded: function(obj) {
              // rollingField.readonly = !!obj && (obj['field9_img'] > 0);
            },
            fields: [
                { title: 'Aperçu', field: 'field15', type: 'image',
                  comment: 'Sélectionnez une image/épreuve pour représenter graphiquement l\'article sur l\'offre de prix.', //picture
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                },
                { title: 'Désignation', field: 'designation',
                  comment: 'Entrez ici la désignation de l\'article',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 },
                { title: 'Nomenclature', field: 'nomenclature', type: 'foreign-list',
                  //change: (item, column) => { this.priceSupplierChange(item); },
                  multiSelect: false, listItems: nomenclaturesData, listField: 'name', nullText: '(Aucun)',
                  comment: 'Sélectionnez ici la nomenclature à laquelle appartient l\'article.',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                },
                { title: 'Format', field: 'field1',
                  comment: 'Entrez ici le format de l\'article',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //format
                { title: 'Matière', field: 'field2',
                  comment: 'Insérez ici la matière utilisée.<br/>Si vous désirez cacher la matière au client, utilisez le champ suivant "Matière (clients)".',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //material
                { title: 'Matière (clients)', field: 'field3', type: 'nullable-text', nullText: '(Afficher la matière comme pour les fournisseurs)',
                  comment: 'Insérez ici la matière, si nécessaire, comme vous voulez qu\'elle apparaisse sur les documents du client.<br/>(NOTE: Pour les fournisseurs, la valeur "Matière" ci-dessus est toujours utilisée.)',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //material2
                { title: 'Impression', field: 'field4',
                  comment: 'Insérez ici les COULEURS d\'impression utilisées pour le recto/verso',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //printing
                { title: 'ø du mandrin / ø du rouleau', field: 'field5',
                  comment: 'Insérez ici le diamètre intérieur/extérieur du rouleau.',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //diameter
                { title: 'Présentation', field: 'field6',
                  comment: 'Insérez ici la présentation des étiquettes',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //presentation
                { title: 'Nb. d\'éti. / feuil. / bob. / parav.', field: 'field7',
                  comment: 'Insérez ici le nombre d\'étiquettes, de feuilles, de bobines, ou de paravents',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //labels_count
                { title: 'Esp. vertical / Esp. horizontal', field: 'field8',
                  comment: 'Insérez ici l\'espace vertical et/ou horizontal entre les étiquettes',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //space
                // { title: 'Sens d\'enroulement', field: 'field9' }, //rolling_direction
                { title: 'Sens d\'enroulement (image)', field: 'field9_img', type: 'rolling-direction',
                  comment: 'Sélectionnez ici l\'image du sens d\'enroulement. Si aucune sélectionnée, le champ "Sens d\'enroulement (texte)" sera utilisé.',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
                    // rollingField.readonly = (object[field.field] > 0);
                    if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event);
                  }
                },
                rollingField,
                { title: 'Laize', field: 'field10',
                  comment: 'Insérez ici la dimension de la laize',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //width
                { title: 'Pinfeed', field: 'field11',
                  comment: 'Précisez ici s\'il y a des pinfeeds',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //pinfeed
                { title: 'Perforations ou façonnage', field: 'field12',
                  comment: 'Précisez ici s\'il y a des perforations ou un façonnage particulier',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //perforations
                { title: 'Autre', field: 'field13',
                  comment: 'Vous pouvez ajouter ici tout autres renseignements utiles',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //other
                { title: 'Conditionnement', field: 'field14',
                  comment: 'Insérez ici le conditionnement ou le multiple à commander',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 } //packaging
            ]
        };
    }

    public static generateDeviceBlock(nomenclaturesData, fieldsFunc = null): InfoBlock {
        if (!nomenclaturesData) nomenclaturesData = { items: [] };
        return {
            title: "Informations\narticle matériel",
            backColor: 'rgb(55, 136, 105)',
            textColor: 'white',
            fields: [
                { title: 'Aperçu', field: 'field15', type: 'image', //picture
                  comment: 'Sélectionnez une image/épreuve pour représenter graphiquement le matériel sur l\'offre de prix.',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //picture
                { title: 'Désignation', field: 'designation',
                  comment: 'Entrez ici la désignation du matériel',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 },
                { title: 'Nomenclature', field: 'nomenclature', type: 'foreign-list', //parent
                  multiSelect: false, listItems: nomenclaturesData, listField: 'name', nullText: '(Aucun)',
                  comment: 'Sélectionnez ici la nomenclature à laquelle appartient le matériel.',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                },
                { title: 'Marque et modèle', field: 'field1',
                  comment: 'Entrez ici la marque et le modèle du matériel',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //model
                { title: 'Technologie', field: 'field2',
                  comment: 'Entrez ici la technologie du matériel',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //technology
                { title: 'Résolution', field: 'field3',
                  comment: 'Entrez ici la résolution',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //resoution
                { title: 'Vitesse maximum', field: 'field4',
                  comment: 'Entrez ici la vitesse maximum du matériel',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //max_speed
                { title: 'Largeur du média', field: 'field5',
                  comment: 'Entrez ici la largeur du média',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //media_width
                { title: 'Epaisseur du média', field: 'field6',
                  comment: 'Entrez ici l\'épaisseur du média',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //media_thickness
                { title: 'Mémoire', field: 'field7',
                  comment: 'Entrez ici la quantité de mémoire du matériel',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //memory
                { title: 'Couteau', field: 'field8',
                  comment: 'Précisez ici, le cas échéant, le couteau présent sur le matériel',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //knife
                { title: 'Interface (standard)', field: 'field9',
                  comment: 'Spécifiez ici l\'interface utilisée par le matériel',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //interface
                { title: 'Programmation', field: 'field10',
                  comment: 'Entrez ici la programmation du matériel',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //programming
                { title: 'Options', field: 'field11',
                  comment: 'Décrivez ici les options du matériel',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //options
                { title: 'Construction', field: 'field12',
                  comment: 'Entrez ici la construction du matériel',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //building 
                { title: 'Garantie', field: 'field13',
                  comment: 'Précisez ici la garantie sur le  matériel',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //warranty
                { title: 'Autre', field: 'field14',
                  comment: 'Vous pouvez ajouter ici tout autres renseignements utiles',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //other
            ]
        };
    }

    public static generateServiceBlock(nomenclaturesData, fieldsFunc = null): InfoBlock {
        if (!nomenclaturesData) nomenclaturesData = { items: [] };
        return {
            title: "Informations\nservice",
            backColor: 'rgb(145, 152, 35)',
            textColor: 'white',
            fields: [
                { title: 'Aperçu', field: 'field15', type: 'image', //picture
                  comment: 'Sélectionnez une image/épreuve pour représenter graphiquement le service sur l\'offre de prix.',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                }, //picture
                { title: 'Désignation', field: 'designation',
                  comment: 'Entrez ici la désignation du service',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 },
                { title: 'Nomenclature', field: 'nomenclature', type: 'foreign-list', //parent
                  multiSelect: false, listItems: nomenclaturesData, listField: 'name', nullText: '(Aucun)',
                  comment: 'Sélectionnez ici la nomenclature à laquelle appartient le service.',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                },
                { title: 'Description', field: 'field1', type: 'textarea',
                  comment: 'Entrez ici la description du service',
                  change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => { if (typeof(fieldsFunc) === 'function') fieldsFunc('change', component, block, field, object, event); }
                 }, //description
            ]
        };
    }

    public static generateReadOnlyArticleBlock(): InfoBlock
    {
        return {
            title: "Informations article\nconsommable",
            backColor: 'rgb(0,156,218)',
            textColor: 'white',
            fields: [
                { title: 'Aperçu', field: 'field15', type: 'image' }, //picture
                { title: 'Désignation', field: 'designation' },
                { title: 'Nomenclature', field: 'nomenclature.name', type: 'text', readonly: true },
                { title: 'Format', field: 'field1' }, //format
                { title: 'Matière', field: 'field2' }, //material
                { title: 'Matière (clients)', field: 'field3', type: 'nullable-text', nullText: '(Afficher la matière comme pour les fournisseurs)' }, //material2
                { title: 'Impression', field: 'field4' }, //printing
                { title: 'ø du mandrin / ø du rouleau', field: 'field5' }, //diameter
                { title: 'Présentation', field: 'field6' }, //presentation
                { title: 'Nb. d\'éti. / feuil. / bob. / parav.', field: 'field7' }, //labels_count
                { title: 'Esp. vertical / Esp. horizontal', field: 'field8' }, //space
                { title: 'Sens d\'enroulement (texte)', field: 'field9' }, //rolling_direction
                { title: 'Sens d\'enroulement (image)', field: 'field9_img', type: 'rolling-direction' }, //rolling_direction
                { title: 'Laize', field: 'field10' }, //width
                { title: 'Pinfeed', field: 'field11' }, //pinfeed
                { title: 'Perforations ou façonnage', field: 'field12' }, //perforations
                { title: 'Autre', field: 'field13' }, //other
                { title: 'Conditionnement', field: 'field14' } //packaging
            ]
        };
    }

    public static generateReadOnlyDeviceBlock(): InfoBlock
    {
        return {
            title: "Informations\narticle matériel",
            backColor: 'rgb(55, 136, 105)',
            textColor: 'white',
            fields: [
                { title: 'Aperçu', field: 'field15', type: 'image' }, //picture
                { title: 'Désignation', field: 'designation' }, //name
                { title: 'Nomenclature', field: 'nomenclature.name', type: 'text' }, //parent
                { title: 'Marque et modèle', field: 'field1' }, //model
                { title: 'Technologie', field: 'field2' }, //technology
                { title: 'Résolution', field: 'field3' }, //resoution
                { title: 'Vitesse maximum', field: 'field4' }, //max_speed
                { title: 'Largeur du média', field: 'field5' }, //media_width
                { title: 'Epaisseur du média', field: 'field6' }, //media_thickness
                { title: 'Mémoire', field: 'field7' }, //memory
                { title: 'Couteau', field: 'field8' }, //knife
                { title: 'Interface (standard)', field: 'field9' }, //interface
                { title: 'Programmation', field: 'field10' }, //programming
                { title: 'Options', field: 'field11' }, //options
                { title: 'Construction', field: 'field12' }, //building 
                { title: 'Garantie', field: 'field13' }, //warranty
                { title: 'Autre', field: 'field14' } //other
            ]
        };
    }

    public static generateReadOnlyServiceBlock(): InfoBlock
    {
        return {
            title: "Informations\nservice",
            backColor: 'rgb(145, 152, 35)',
            textColor: 'white',
            fields: [
                { title: 'Aperçu', field: 'field15', type: 'image' }, //picture
                { title: 'Désignation', field: 'designation' }, //name
                { title: 'Nomenclature', field: 'nomenclature.name', type: 'text' }, //parent
                { title: 'Description', field: 'field1' }, //description
            ]
        };
    }
}