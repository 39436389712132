import { Component, ViewChild } from '@angular/core';
import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from '../../../../components/views/views.component';
import { DataGridComponent } from '../../../../components/data-grid/data-grid.component';
import { NotificationsComponent } from '../../../../components/notifications/notifications.component';
import { ArrayUtil } from '../../../utils/classes/ArrayUtil.class';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { ItRequest } from '../../classes/ItRequest.class';
import { ItRequestViewComponent } from '../it-request-view/it-request-view.component';
import {DataGridRowAction} from "../../../../components/data-grid/classes/DataGridRowAction.class";

@Component({
  selector: 'app-it-requests-list-view',
  templateUrl: './it-requests-list-view.component.html',
  styleUrls: ['./it-requests-list-view.component.css']
})
export class ItRequestsListViewComponent extends AccessViewComponent {

    public toolbar: Toolbar = {
        class: 'toolbar-big',
        viewTitle: 'Liste des requêtes',
        data: this,
        elements: [
            {
                type: 'button',
                text: 'Créer une<br/>requête',
                icon: 'euro-sign',
                click: function(view: ItRequestsListViewComponent) {
                    view.createRequest();
                },
                access: this.writeAccess
            },
            { type: 'separator' },
            {
                type: 'button',
                text: 'Supprimer',
                icon: 'times',
                click: function(view: ItRequestsListViewComponent) {
                    view.deleteRequests();
                },
                access: this.writeAccess
            },
            { type: 'spacing' }
        ]
    };

    public articlesType: number = 0;

    public requests: ItRequest[] = [];
    public requestsGridColumns = [
        // { title: 'Numéro', field: 'id' },
        // { title: 'Aperçu', field: 'field15', type: 'image' },
        { title: 'Date', field: 'date', type: 'date', width: 250 },
        { title: 'Sujet', field: 'subject', width: 420 },
        { title: 'Estimation\n(heures)', field: 'estimation', type: 'number', width: 90, textAlign: 'left' },
        { title: 'Date de la\nréponse', field: 'response_date', type: 'date', width: 90 },
        { title: 'Validé', field: 'validated', type: 'checkbox', width: 90 },
        { title: 'Date\nde la\nvalidation', field: 'validation_date', type: 'date', width: 90 }
    ];

    @ViewChild('grid') grid: DataGridComponent;
	public gridRowActions: DataGridRowAction[] = [
		{
			name: 'Afficher', icon: 'search', click: (event: any, item: any) => {
				this.requestDblClick(event, item);
			}
		}
	];

    reloadRequests()
    {
        ItRequest.load(null, ['date'], null, false).then(
            (result) => { this.requests = result; },
            (err) => { console.error(err); }
        );
    }
    onActivate()
    {
        this.reloadRequests();
    }

    createRequest()
    {
        ViewsComponent.openView(ItRequestViewComponent, null);
    }

    deleteRequests()
    {
        DialogsComponent.display({
            icon: 'warning',
            title: 'Supprimer les requêtes ?',
            message: 'Êtes-vous sûr(e) de vouloir supprimer les requêtes sélectionnées ?',
            buttons: DialogButton.yesNoButtons
        }).then((result: any) => {
            if (result === DialogButton.RESULT_YES)
            {
                let requests: ItRequest[] = this.grid.selectedItems as ItRequest[];
                if (Array.isArray(requests) && requests.length > 0)
                {
                    let promises: any[] = [];
                    for(let i=0; i<requests.length; ++i) promises.push(requests[i].moveToTrash());
                    Promise.all(promises).then(
                        (result) => {
                            NotificationsComponent.push({
                                type: 'success',
                                title: 'Requêtes supprimés',
                                summary: 'Les requêtes ont été supprimées avec succès'
                            });
                            ArrayUtil.removeElements(this.requests, requests);
                        },
                        (err) => {
                            NotificationsComponent.push({
                                type: 'error',
                                title: 'Erreur lors de la suppression',
                                summary: 'Une erreur s\'est produite lors de la suppression',
                                content: err
                            });
                            console.error(err);
                        }
                    )
                }
            }
        });
    }

    requestDblClick(event, sale)
    {
        ViewsComponent.openView(ItRequestViewComponent, sale);
    }

}
