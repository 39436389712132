import * as $ from 'jquery';

import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { ColorModalComponent } from 'src/app/components/color-modal/color-modal.component';
import { CredentialsService } from '../../../../services/credentials/credentials.service';
import { ExternalService } from 'src/app/services/external/external.service';
import { InfoBlock } from '../../../../components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from '../../../../components/info-block/info-block.component';
import { InfoBlockField } from '../../../../components/info-block/classes/InfoBlockField.class';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { Page } from '../../classes/Page.class';
import { PrintablePageComponent } from '../../components/printable-page/printable-page.component';
import { PrintingModel } from '../../classes/PrintingModel.class';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { config } from '../../../../classes/config';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-print-preview',
  templateUrl: './print-preview-view.component.html',
  styleUrls: ['./print-preview-view.component.css'],
})
export class PrintPreviewViewComponent extends AccessViewComponent {
  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Aperçu avant impression',
    data: this,
    elements: [
      { type: 'separator' },
      {
        type: 'button',
        name: 'printButton',
        text: 'Imprimer',
        icon: 'print',
        click: function (view: PrintPreviewViewComponent) {
          if (ExternalService.available) view.printFile();
          else view.print();
        },
        options: { visible: true },
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Enregistrer<br/>sous ...',
        icon: 'save',
        click: function (view: PrintPreviewViewComponent) {
          view.saveFileAs();
        },
      },
      { type: 'separator' },
      {
        type: 'button',
        name: 'sendByMailButton',
        visible: false,
        text: 'Envoyer<br/>par e-mail',
        icon: ' fas fa-at',
        click: function (view: PrintPreviewViewComponent) {
          view.sendByMailWithSettings();
        },
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Ouvrir dans<br/>Acrobat',
        icon: ' far fa-file-pdf',
        click: function (view: PrintPreviewViewComponent) {
          view.openFile();
        },
      },
      { type: 'spacing' },
    ],
  };

  public contentBlock: InfoBlock = {
    title: 'Elément',
    fields: [
      { title: 'Elément', type: 'text', field: 'selectedTagName' },
      {
        title: 'Type',
        type: 'select',
        field: 'valueType',
        selectOptions: [
          { text: 'Conteneur', value: 'container' },
          { text: 'Texte', value: 'text' },
          { text: 'Variable', value: 'var' },
        ] /*, change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event) => {
            this.contentSettingsChanged(event) }*/,
      },
      {
        title: 'Valeur',
        type: 'textarea',
        field: 'valueVal',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event) => {
          this.contentSettingsChanged(event);
        },
      },
    ],
  };
  public backgroundBlock: InfoBlock = {
    title: 'Fond',
    fields: [
      { title: 'Image', type: 'text', field: 'background-image' },
      { title: 'Taille', type: 'text', field: 'background-size' },
    ],
  };
  public fontBlock: InfoBlock = {
    title: 'Police',
    fields: [
      { title: 'Police', field: 'font-family' },
      { title: 'Taille', field: 'font-size' },
      { title: 'Couleur', field: 'color' },
      {
        title: 'Epaisseur',
        field: 'font-weight',
        type: 'select',
        selectOptions: [
          { text: '(Hérité)', value: null },
          { text: 'Normal', value: 'normal' },
          { text: 'Gras', value: 'bold' },
        ],
      },
      {
        title: 'Style',
        field: 'font-style',
        type: 'select',
        selectOptions: [
          { text: '(Hérité)', value: null },
          { text: 'Normal', value: 'none' },
          { text: 'Italique', value: 'italic' },
        ],
      },
      {
        title: 'Alignement',
        field: 'text-align',
        type: 'select',
        selectOptions: [
          { text: 'Gauche', value: 'left' },
          { text: 'Centré', value: 'center' },
          { text: 'Droite', value: 'right' },
        ],
      },
    ],
  };
  public paragraphBlock: InfoBlock = {
    title: 'Paragraphe',
    fields: [
      { title: 'Alignement', field: 'text-align' },
      { title: 'Hauteur de ligne', field: 'line-height' },
      { title: 'Marges (L T R B)', field: 'margin' },
      { title: 'Padding (L T R B)', field: 'padding' },
    ],
  };
  public borderBlock: InfoBlock = {
    title: 'Bordures',
    fields: [
      { title: 'Style', field: 'border-style' },
      { title: 'Épaisseur', field: 'border-width' },
      { title: 'Couleur', field: 'border-color' },
      { title: 'Rayon', field: 'border-radius' },
    ],
  };
  public positionBlock: InfoBlock = {
    title: 'Bloc',
    fields: [
      { title: 'Affichage', field: 'display' },
      { title: 'Position', field: 'position' },
      { title: 'X', field: 'left' },
      { title: 'Y', field: 'top' },
      { title: 'Droite', field: 'right' },
      { title: 'Bas', field: 'bottom' },
      { title: 'Largeur', field: 'width' },
      { title: 'Hauteur', field: 'height' },
    ],
  };

  @ViewChild('pagesWrapper') pagesWrapper;
  public model: PrintingModel = null;
  public pages: Page[] = [
    // {
    //     headerSize: 26,
    //     headerElements: [],
    //     bodyElements: [
    //         { html: 'HELLO WORLD !', style: {} }
    //     ],
    //     footerSize: 26,
    //     footerElements: []
    // }
  ];

  public selectedObject: any = null;
  public selectedStyle: any = null;
  public page: Page = null;
  public zoom: number = 1;
  public form_action: string = null;

  public self: PrintPreviewViewComponent = this;
  public valueType: string = 'text';
  public valueVal: string = null;
  public dataObject: any = null;

  public mergePages: boolean = false;

  private _onSendByMailClicked: () => Promise<any> = null;
  public get onSendByMailClicked() {
    return this._onSendByMailClicked;
  }
  public set onSendByMailClicked(value) {
    this._onSendByMailClicked = value;
    this.updateToolbar();
  }

  private _mail_to: string[] = null;
  public get mail_to() {
    return this._mail_to;
  }
  public set mail_to(value) {
    this._mail_to = value;
    this.updateToolbar();
  }
  public mail_cc: string[] = null;
  public mail_bcc: string[] = null;
  public mail_subject: string[] = null;
  public mail_body: string[] = null;
  public mail_filename: string[] = null;

  @HostBinding('class.global') @Input() global: boolean = false;
  public static globalInstance: PrintPreviewViewComponent = null;

  constructor(private modalService: BsModalService, private elementRef: ElementRef, protected ref: ChangeDetectorRef) {
    super(ref);
  }

  ngOnInit() {
    if (this.global === true) PrintPreviewViewComponent.globalInstance = this;
    this.form_action = config.apiUrl + 'modules/print/print_model.php';
  }

  initView(printingModelClass: any, ...args: any[]) {
    let self = this;
    this.model = printingModelClass.generate(...args);
    console.log('preview model:', this.model);
  }
  ngAfterViewInit() {
    // setTimeout(() => {
    //     let imgs = $('img',this.elementRef.nativeElement);
    //     for(let i=0; i<imgs.length; ++i) this.selectObject(null, imgs[i]);
    // }, 0);
  }
  private updateToolbar() {
    Toolbar.getToolbarItem(this.toolbar, 'sendByMailButton').visible =
      !!this.onSendByMailClicked || (this._mail_to && this._mail_to.length);
  }

  @ViewChild('printForm') printForm: ElementRef;
  @ViewChild('modelInput') modelInput: ElementRef;
  @ViewChild('mmStandard') mmStandard: ElementRef;
  @ViewChildren('pagesElements') pagesElements: QueryList<PrintablePageComponent>;

  print() {
    if (ExternalService.available) return this.printFile();
    else {
      return new Promise<any>((resolve, reject) => {
        this.zoom = 1;
        setTimeout(() => {
          let pages = this.pagesElements.toArray();
          for (let i = 0; i < pages.length; ++i) {
            let form = $(this.printForm.nativeElement);
            $('input', form).val(JSON.stringify(this.getModel()));
            // let tab = window.open('about:blank', 'print'+i);
          }
          window.focus();
          $(this.printForm.nativeElement)[0].submit();
          resolve(true);
        });
      });
    }
  }

  getModel(pageNum: number = null) {
    let settings = {
      mm_width: $(this.mmStandard.nativeElement).width() / 1000.0,
      mm_height: $(this.mmStandard.nativeElement).height() / 1000.0,
    };
    let model = {
      pages: [],
      html: '',
    };
    let pages = this.pagesElements.toArray();
    if (pageNum == null) {
      for (let i = 0; i < pages.length; ++i) {
        let page = PrintablePageComponent.getModel(settings, pages[i]);
        model.pages.push(page);
      }
    } else {
      let page = PrintablePageComponent.getModel(settings, pages[pageNum]);
      model.pages.push(page);
    }
    // model.html = this.pagesWrapper.nativeElement.innerHTML;
    // model.html = model.html.replace(/printable\-page/gm, 'div');
    // model.html = model.html.replace(/printable\-element/gm, 'div');
    // console.log('model:', model);
    return model;
  }

  /*
    loadPageHtml()
    {
        this.pageElement.nativeElement.innerHTML = this.page ? this.page.html : '';
    }

    save()
    {
        if (this.page)
        {
            this.page.html = this.pageElement.nativeElement.innerHTML;
            this.page.save2();
        }
    }*/

  selectObject(event, obj = null) {
    $('.selected-item').removeClass('selected-item');
    if (obj != null) this.selectedObject = obj;
    else if (event.target.tagName.toLowerCase() === 'printable-page') this.selectedObject = null;
    else this.selectedObject = event.target;
    //this.selectedObject = obj ? obj : (event.target == this.pageElement.nativeElement ? null : event.target);
    this.selectedStyle = this.selectedObject ? this.selectedObject.style : event.target.style;
    if (this.selectedObject) {
      $(this.selectedObject).addClass('selected-item');
      this.valueType =
        $(this.selectedObject).attr('data-value-type') ||
        ($(this.selectedObject).children().length > 0 ? 'container' : 'text');
      switch (this.valueType) {
        case 'text':
          this.valueVal = $(this.selectedObject).text();
          break;
        case 'var':
          this.valueVal = $(this.selectedObject).attr('data-value-val') || null;
          break;
        default:
          this.valueVal = null;
          break;
      }
    } else {
      this.valueVal = null;
      this.valueType = null;
    }
  }

  /*selectParent(event = null)
    {
        if (this.selectedObject && this.selectedObject.parentElement
            && this.selectedObject.parentElement != this.pageElement.nativeElement)
            this.selectObject(event, this.selectedObject.parentElement);
    }

    insertElement()
    {
        let parent = this.selectedObject;
        if (!parent) parent = this.pageElement.nativeElement;
        let elem = document.createElement('div');
        elem.innerHTML = 'Nouvel élément';
        $(parent).append(elem);
        $(elem).attr('data-value-type', 'text').attr('data-value-val', 'Nouvel élément');
        this.selectObject(null, elem);
    }

    deleteElement()
    {
        if (this.selectedObject)
        {
            let objToDelete = this.selectedObject;
            this.selectParent();
            $(objToDelete).remove();
        }
    }*/

  get selectedTagName() {
    return this.selectedObject ? this.selectedObject.tagName : '(Aucun élément sélectionné)';
  }

  contentSettingsChanged(event) {
    console.log('selected obj:', this.selectedObject);
    if (this.selectedObject) {
      $(this.selectedObject).attr('data-value-type', this.valueType);
      $(this.selectedObject).attr('data-value-val', this.valueVal);
      switch (this.valueType) {
        case 'text':
          $(this.selectedObject).text(this.valueVal);
          break;
        case 'var':
          if (this.dataObject) $(this.selectedObject).text(this.dataObject[this.valueVal]);
          break;
        default:
          break;
      }
    }
  }

  toggleBold(event) {
    console.log('toggleBold:', event);
    if (this.selectedStyle)
      this.selectedStyle['font-weight'] = this.selectedStyle['font-weight'] == 'bold' ? 'normal' : 'bold';
  }

  public colorModalRef: BsModalRef = null;
  selectTextColor() {
    if (this.selectedStyle) {
      let initialState: any = {
        object: this.selectedStyle,
        field: 'color',
      };
      this.colorModalRef = this.modalService.show(ColorModalComponent, { initialState, class: 'modal-sm' });
    }
  }
  selectBackColor() {
    if (this.selectedStyle) {
      let initialState: any = {
        object: this.selectedStyle,
        field: 'background-color',
      };
      this.colorModalRef = this.modalService.show(ColorModalComponent, { initialState, class: 'modal-sm' });
    }
  }

  getData(pageNum: number = null) {
    return 'model=' + encodeURIComponent(JSON.stringify(this.getModel(pageNum)));
  }

  printFile() {
    return new Promise<any>((resolve, reject) => {
      let oldZoom: number = this.zoom;
      this.zoom = 1;
      setTimeout(() => {
        if (!ExternalService.available) {
          ExternalService.showAvailabilityWarning();
          reject('external component not available');
        } else {
          ExternalService.downloadAndPrint('pdf', this.form_action, 'POST', this.getData());
          this.zoom = oldZoom;
          setTimeout(() => {
            resolve(true);
          }, 0);
        }
      }, 0);
    });
  }

  saveFileAs(filename: string = null) {
    return new Promise<any>((resolve, reject) => {
      let oldZoom: number = this.zoom;
      this.zoom = 1;
      setTimeout(() => {
        if (!ExternalService.available) {
          ExternalService.showAvailabilityWarning();
          reject('external component not available');
        } else {
          let promises: Promise<any>[] = [];
          if (this.mergePages) {
            if (!filename)
              promises.push(
                ExternalService.downloadSaveAs(
                  this.form_action,
                  'POST',
                  this.getData(),
                  this.model.saveFilename,
                  this.model.saveFilter
                )
              );
            else promises.push(ExternalService.downloadToFile(filename, this.form_action, 'POST', this.getData()));
          } else {
            for (var i = 0; i < this.model.pages.length; ++i) {
              if (!filename)
                promises.push(
                  ExternalService.downloadSaveAs(
                    this.form_action,
                    'POST',
                    this.getData(i),
                    this.model.saveFilename,
                    this.model.saveFilter
                  )
                );
              else promises.push(ExternalService.downloadToFile(filename, this.form_action, 'POST', this.getData(i)));
            }
          }
          Promise.all(promises).then(
            (result) => {
              this.zoom = oldZoom;
              setTimeout(() => {
                resolve(true);
              }, 0);
            },
            (err) => {
              this.zoom = oldZoom;
              console.error(err);
              setTimeout(() => {
                reject(err);
              }, 0);
            }
          );
        }
      }, 0);
    });
  }

  saveAsTemp() {
    return new Promise<any>((resolve, reject) => {
      let oldZoom: number = this.zoom;
      this.zoom = 1;
      setTimeout(() => {
        if (!ExternalService.available) {
          ExternalService.showAvailabilityWarning();
          reject('external component not available');
        } else {
          let promises: Promise<any>[] = [];
          if (this.mergePages)
            promises.push(ExternalService.saveAsTemp('pdf', this.form_action, 'POST', this.getData()));
          else
            for (var i = 0; i < this.model.pages.length; ++i) {
              promises.push(ExternalService.saveAsTemp('pdf', this.form_action, 'POST', this.getData(i)));
            }
          Promise.all(promises).then(
            (result) => {
              this.zoom = oldZoom;
              setTimeout(() => {
                resolve(result);
              }, 0);
            },
            (err) => {
              this.zoom = oldZoom;
              console.error(err);
              setTimeout(() => {
                reject(err);
              }, 0);
            }
          );
        }
      }, 0);
    });
  }

  saveAsTempFilename(filename: string) {
    return new Promise<any>((resolve, reject) => {
      let oldZoom: number = this.zoom;
      this.zoom = 1;
      setTimeout(() => {
        if (!ExternalService.available) {
          ExternalService.showAvailabilityWarning();
          reject('external component not available');
        } else {
          let promises: Promise<any>[] = [];
          if (this.mergePages)
            promises.push(ExternalService.saveAsTempFilename(filename, this.form_action, 'POST', this.getData()));
          else
            for (var i = 0; i < this.model.pages.length; ++i) {
              promises.push(ExternalService.saveAsTempFilename(filename, this.form_action, 'POST', this.getData(i)));
            }
          Promise.all(promises).then(
            (result) => {
              this.zoom = oldZoom;
              setTimeout(() => {
                resolve(result);
              }, 0);
            },
            (err) => {
              this.zoom = oldZoom;
              console.error(err);
              setTimeout(() => {
                reject(err);
              }, 0);
            }
          );
        }
      }, 0);
    });
  }

  openFile() {
    return new Promise<any>((resolve, reject) => {
      let oldZoom: number = this.zoom;
      this.zoom = 1;
      setTimeout(() => {
        if (!ExternalService.available) {
          ExternalService.showAvailabilityWarning();
          reject('external component not available');
        } else {
          let promises: Promise<any>[] = [];
          if (this.mergePages)
            promises.push(ExternalService.downloadAndOpen('pdf', this.form_action, 'POST', this.getData()));
          else
            for (var i = 0; i < this.model.pages.length; ++i) {
              promises.push(ExternalService.downloadAndOpen('pdf', this.form_action, 'POST', this.getData(i)));
            }
          Promise.all(promises).then(
            (result) => {
              this.zoom = oldZoom;
              setTimeout(() => {
                resolve(result);
              }, 0);
            },
            (err) => {
              this.zoom = oldZoom;
              console.error(err);
              setTimeout(() => {
                reject(err);
              }, 0);
            }
          );
        }
      }, 0);
    });
  }

  async sendByMailWithSettings() {
    if (this.onSendByMailClicked && typeof this.onSendByMailClicked === 'function') {
      const result = await this.onSendByMailClicked();
      if (result === false) return;
    }
    return LoadingPromise.create<any>((resolve, reject) => {
      let promises: any[] = [];
      for (let i = 0; i < this.mail_to.length; ++i) {
        promises.push(
          this._sendByMail(
            this.mail_to && this.mail_to[i] ? this.mail_to[i] : null,
            this.mail_cc && this.mail_cc[i] ? this.mail_cc[i] : null,
            this.mail_bcc && this.mail_bcc[i] ? this.mail_bcc[i] : null,
            this.mail_subject && this.mail_subject[i] ? this.mail_subject[i] : null,
            this.mail_body && this.mail_body[i] ? this.mail_body[i] : null,
            this.mail_filename && this.mail_filename[i] ? this.mail_filename[i] : null,
            this.mergePages ? undefined : i
          )
        );
      }
      Promise.all(promises).then(resolve, reject);
    });
  }

  private _sendByMail(to, cc, bcc, subject, body, filename, i?: number) {
    let mail_filename: string = this.mergePages
      ? this.model.mailFilename
      : this.model.mailFilename.replace('.pdf', '(' + (i + 1) + ').pdf');
    if (i >= this.model.pages.length) i = this.model.pages.length - 1;
    return ExternalService.saveAsTempFilename(
      mail_filename,
      this.form_action,
      'POST',
      this.mergePages ? this.getData() : this.getData(i)
    ).then((result) => {
      let tempFileName: string = result;
      let signature: string = CredentialsService.loggedMerchant.outlook_signature || null;
      console.log('signature: ', signature);
      if (signature == '') signature = null;
      // console.log('creating mail using filename', tempFileName);
      ExternalService.createMail(
        to,
        cc,
        bcc,
        subject,
        body,
        [tempFileName + ('|' + (filename != null ? filename : this.model.mailFilename))],
        signature
      );
    });
  }

  sendByMail(
    to: string,
    cc: string = null,
    bcc: string = null,
    subject: string = null,
    body: string = null,
    filename: string = null,
    pageNum = null
  ) {
    return new Promise<any>((resolve, reject) => {
      let oldZoom: number = this.zoom;
      this.zoom = 1;
      setTimeout(() => {
        if (!ExternalService.available) {
          ExternalService.showAvailabilityWarning();
          reject('external component not available');
        } else {
          let promises: Promise<any>[] = [];
          if (pageNum >= 0) {
            if (this.mergePages) promises.push(this._sendByMail(to, cc, bcc, subject, body, filename));
            for (var i = 0; i < this.model.pages.length; ++i) {
              promises.push(this._sendByMail(to, cc, bcc, subject, body, filename, i));
            }
          } else {
            promises.push(this._sendByMail(to, cc, bcc, subject, body, filename, pageNum));
          }
          Promise.all(promises).then(
            (result) => {
              this.zoom = oldZoom;
              setTimeout(() => {
                resolve(result);
              }, 0);
            },
            (err) => {
              this.zoom = oldZoom;
              console.error(err);
              setTimeout(() => {
                reject(err);
              }, 0);
            }
          );
        }
      }, 0);
    });
  }

  iteratePrintingModels(models: PrintingModel[], callback, index: number = 0) {
    return LoadingPromise.create<any>((resolve, reject) => {
      if (models.length == 0) resolve(true);
      else {
        let model: PrintingModel = models[0];
        PrintPreviewViewComponent.globalInstance.model = model;
        setTimeout(() => {
          callback(model, index).then(
            (result) => {
              this.iteratePrintingModels(models.slice(1), callback, index + 1).then(
                (result2) => {
                  resolve(result);
                },
                (err) => {
                  reject(err);
                }
              );
            },
            (err) => {
              reject(err);
            }
          );
        }, 0);
      }
    });
  }
}
