<div class="data-tree-file-container" [ngClass]="{'sub-directory': level > 0}">
    <div class="tree-file" (click)="itemClick.next({'event':$event,'item':item})"
         [ngClass]="{'selected': selectedObjects.includes(item)}"
         draggable [dragScope]="'data-tree-item'" [dragData]="item">
        <span [ngStyle]="{'visibility': 'hidden'}" class="data-tree-expand">-</span>
        <img class="folder-icon" [src]="image" />
        <span *ngIf="!editing" [attr.title]="item.name" class="data-tree-name">{{ item[nameField] }}</span>
        <input type="text" *ngIf="editing" [(ngModel)]="item.name" (blur)="nameBlur($event, item)" (keydown)="nameKeyDown($event, item)" />
    </div>
</div>
