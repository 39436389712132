<div class="reimbursement-container">

    <div class="reimbursement-container-columns">

        <info-block #selectBlockComponent [block]="selectBlock" [data]="instance" [readonly]="!writeAccess"></info-block>
        <info-block #reimbursementBlockComponent [block]="reimbursementBlock" [data]="reimbursementCopy" [readonly]="!writeAccess"></info-block>
        <info-block #saleBlockComponent [block]="saleBlock" [data]="reimbursementCopy" [readonly]="true"></info-block>
        <info-block #amountsBlockComponent [block]="amountsBlock" [data]="reimbursementCopy" [readonly]="!writeAccess"></info-block>
        <info-block #remarksBlockComponent [block]="remarksBlock" [data]="reimbursementCopy" [readonly]="!writeAccess"></info-block>

        <info-block [block]="accountingBlock" #accountingBlockComponent [data]="reimbursementCopy" [readonly]="!writeAccess"></info-block>

        <div class="send-warning-block" *ngIf="dontSendByPost">NE PAS ENVOYER PAR VOIE POSTALE</div>
        <div class="send-warning-block" *ngIf="dontSendByMail">NE PAS ENVOYER PAR E-MAIL</div>

    </div>

    <div class="reimbursement-container-columns">

        <div *ngIf="reimbursementCopy && reimbursementCopy.order && model">
            <printable-page #pagesElements *ngFor="let page of model.pages" [page]="page" [ngStyle]="{'width': page.width+'mm', 'height': page.height+'mm'}"></printable-page>
        </div>

    </div>

</div>
