import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';

import { AccessViewComponent } from './access-view.component';
import { ExternalService } from '../../services/external/external.service';
import { Toolbar } from '../toolbar/classes/Toolbar.class';
import { ViewBaseComponent } from './view-base.component';
import { config } from '../../classes/config';

// import { ViewBaseComponent } from './view-base.component';

@Component({
  selector: 'app-views',
  templateUrl: './views.component.html',
  styleUrls: ['./views.component.scss'],
})
export class ViewsComponent implements OnInit {
  public static instance: ViewsComponent;
  public views: AccessViewComponent[] = [];
  public toolbars: Toolbar[] = [];
  public printMode: boolean = false;

  @ViewChild('vcr', { read: ViewContainerRef }) vcr: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    ViewsComponent.instance = this;
  }

  get currentPage() {
    return Math.max(this.views.length - 1, 0);
  }

  ngOnInit() {}

  createViewComponent(type) {
    if (!(type.prototype instanceof ViewBaseComponent)) {
      console.log('Bad view type :', type.name);
      return null;
    }
    let factory = this.componentFactoryResolver.resolveComponentFactory<AccessViewComponent>(type);
    let ref: ComponentRef<AccessViewComponent> = this.vcr.createComponent<AccessViewComponent>(factory);
    ref.instance._ref = ref;
    return ref ? ref.instance : null;
  }

  static createViewComponent(type) {
    return ViewsComponent.instance.createViewComponent(type);
  }

  static openView(type, ...args) {
    return ViewsComponent.instance.openView(type, ...args);
  }
  public updateChangeDetection() {
    if (this.views.length > 0) {
      for (let i = 0; i < this.views.length - 1; ++i) this.views[i].disableChangeDetection();
      this.views[this.views.length - 1].enableChangeDetection();
    }
  }
  public setWindowTitle(title: string) {
    if (ExternalService.available)
      ExternalService.setWindowTitle(title && title.length ? title : `${config.companyName} ERP`);
    document.title = `${config.companyName} ERP` + (title && title.length ? ' - ' + title : '');
  }
  public activateView() {
    if (this.views.length > 0) {
      let view: ViewBaseComponent = this.views[this.views.length - 1];
      view.onActivate();
      this.setWindowTitle(view.toolbar && view.toolbar.viewTitle ? view.toolbar.viewTitle : '');
    }
  }
  public openView(type, ...args) {
    let view: AccessViewComponent = ViewsComponent.createViewComponent(type) as AccessViewComponent;
    if (view) {
      this.toolbars.push(view.toolbar);
      this.views.push(view);
      view.viewLevel = this.views.length + 1;
      view.views = this;
      //ViewsComponent.views.push(view);
      view.initView(...args);
      view.onActivate();
      this.setWindowTitle(view.toolbar && view.toolbar.viewTitle ? view.toolbar.viewTitle : '');
      this.updateChangeDetection();
    }
    return view;
  }

  public get currentView() {
    if (!this.views || this.views.length == 0) return null;
    else return this.views[this.views.length - 1];
  }

  closeView() {
    let view: AccessViewComponent = this.views.pop();
    let viewRef: ViewRef = this.vcr.get(this.vcr.length - 1);
    let index: number = this.vcr.indexOf(viewRef);
    if (index > 0) this.vcr.remove(index);
    this.toolbars.pop();
    view._ref.destroy();
    this.updateChangeDetection();
    this.activateView();
  }

  static closeView() {
    return ViewsComponent.instance.closeView();
  }

  public static closeAllViews() {
    while (ViewsComponent.instance.views.length > 0) ViewsComponent.instance.closeView();
  }
}
