import { NumberUtil } from 'src/app/modules/utils/classes/NumberUtil.class';
import { SafeHtml } from '@angular/platform-browser';
import { TextUtil } from 'src/app/modules/utils/classes/TextUtil.class';

export class DataGridColumn {
  public title?: string | SafeHtml = null;
  public field?: string = null;

  public type?: string = null;
  public unit?: string = null;
  public decimalsCount?: number = 0;

  public listField?: string = null;
  public listItems?: DataGridForeignListItems = null;
  public multiSelect?: boolean = false;
  public nullText?: string = null;

  public x?: number = 0;
  public globalX?: number = 0;
  public width?: number = 200;
  public readonly?: boolean = true;

  public headerBackColor?: string = null;
  public backColor?: string = null;
  public fontSize?: string = null;
  public fontWeight?: string = null;

  public format?: DataGridFormatFunc;
  public getter?: DataGridGetterFunc;
  public setter?: DataGridSetterFunc;
  public render?: DataGridRenderFunc;
  public excelFormat?: DataGridExcelFormatFunc;
  public excelValue?: DataGridExcelValueFunc;

  public currencyField?: string = null;

  public change?: any;

  public negativeColor?: string = null;
  public checkedValue?: string = null;
  public uncheckedValue?: string = null;

  public allowNull?: boolean;
  public getOptionStyle?: (val: any) => any;
}

/** FORMATS */

export type DataGridFormatFunc = (item: any, value: any, column: DataGridColumn) => string;

export const defaultFormat: DataGridFormatFunc = (item: any, value: any, column: DataGridColumn) => value || '';

export const numberFormat: DataGridFormatFunc = (item: any, value: any, column: DataGridColumn) =>
  NumberUtil.formatNumber(value, column.decimalsCount) + (column.unit ? ' ' + column.unit : '');

export const moneyFormat = (currencyProperty: string): DataGridFormatFunc => {
  return function (item: any, value: any, column: DataGridColumn) {
    const currency = item[currencyProperty];
    return NumberUtil.formatNumber(value, column.decimalsCount) + (currency ? ' ' + currency.symbol : '');
  };
};

export const foreingListFormat = (fieldName: string, nullText: string): DataGridFormatFunc => {
  return function (item: any, value: any, column: DataGridColumn) {
    const _format = (value: any) => (value == null || value == undefined ? nullText || '' : value[fieldName]);
    if (Array.isArray(value)) return value.map(_format).join(', ');
    else return _format(value);
  };
};

export const checkboxFormat: DataGridFormatFunc = (item: any, value: any, column: DataGridColumn) =>
  '<span class="checkbox-icon"><i class="far fa-' +
  (value == true ? 'check-' : '') +
  'square"></i></span> ' +
  '<span class="checkbox-label">' +
  (value == true ? 'Oui' : 'Non') +
  '</span>';

export const colorFormat: DataGridFormatFunc = (item: any, value: any, column: DataGridColumn) =>
  '<div style="background-color: ' + value + '" class="color-input"></div>';

/** GETTERS */

export type DataGridGetterFunc = (item: any, column: DataGridColumn) => any;

export const defaultGetter = (textProperty: string): DataGridGetterFunc => {
  return function (item: any, column: DataGridColumn) {
    return item[textProperty];
  };
};

export const numberGetter = (numberProperty: string): DataGridGetterFunc => {
  return function (item: any, column: DataGridColumn) {
    let value: number = Number(item[numberProperty]);
    if (isNaN(value)) value = 0;
    return parseFloat(value.toFixed(column.decimalsCount));
  };
};

export const foreingListGetter = (propertyName: string): DataGridGetterFunc => {
  return function (item: any, column: DataGridColumn) {
    return item && item[propertyName] ? item[propertyName] : null;
  };
};

/** SETTERS */

export type DataGridSetterFunc = (item: any, column: DataGridColumn, value: any) => any;

export const defaultSetter = (propertyName: string): DataGridSetterFunc => {
  return function (item: any, column: DataGridColumn, value: any) {
    item[propertyName] = value;
  };
};

export const numberSetter = (propertyName: string): DataGridSetterFunc => {
  return function (item: any, column: DataGridColumn, value: any) {
    const num_val: number = parseFloat(value);
    if (!isNaN(num_val)) item[propertyName] = num_val;
  };
};

/** EXCEL */

const decimalSeparator = (1.25).toLocaleString()[1];
const thousandSepatator = decimalSeparator === '.' ? ',' : '.';

export type DataGridExcelFormatFunc = (column: DataGridColumn) => string;
export type DataGridExcelValueFunc = (column: DataGridColumn, value: any) => string;

export const numberExcelFormat = (column: DataGridColumn) =>
  `#,##0` +
  (column.decimalsCount > 0 ? '.'.padEnd(column.decimalsCount + 1, '0') : '') +
  (column.unit ? `&quot; ${column.unit}&quot;` : '');

export const numberExcelValue = (column: DataGridColumn, value: any) =>
  (value || 0).toFixed(column.decimalsCount || 2).replace('.', decimalSeparator);

/** COLUMNS */

export const textColumn = (propertyName: string, otherProps: DataGridColumn): DataGridColumn => {
  const setter = !otherProps || otherProps.readonly !== false ? null : defaultSetter(propertyName);
  return {
    getter: defaultGetter(propertyName),
    setter,
    format: defaultFormat,
    ...otherProps,
  };
};

export const phoneColumn = (propertyName: string, otherProps: DataGridColumn): DataGridColumn => ({
  ...textColumn(propertyName, otherProps),
  type: 'phone',
});

export const emailColumn = (propertyName: string, otherProps: DataGridColumn): DataGridColumn => ({
  ...textColumn(propertyName, otherProps),
  type: 'email',
});

export const dateColumn = (propertyName: string, otherProps: DataGridColumn): DataGridColumn => {
  const setter = !otherProps || otherProps.readonly !== false ? null : defaultSetter(propertyName);
  return {
    type: 'date',
    getter: defaultGetter(propertyName),
    setter,
    format: defaultFormat,
    ...otherProps,
  };
};

export const numberColumn = (
  propertyName: string,
  decimalsCount: number,
  otherProps: DataGridColumn
): DataGridColumn => {
  const setter = !otherProps || otherProps.readonly !== false ? null : numberSetter(propertyName);
  return {
    type: 'number',
    getter: numberGetter(propertyName),
    setter,
    format: numberFormat,
    excelFormat: numberExcelFormat,
    excelValue: numberExcelValue,
    decimalsCount,
    ...otherProps,
  };
};

export const moneyColumn = (
  propertyName: string,
  currencyProperty: string,
  decimalsCount: number,
  otherProps: DataGridColumn
): DataGridColumn => {
  const setter = !otherProps || otherProps.readonly !== false ? null : numberSetter(propertyName);
  return {
    type: 'number',
    getter: numberGetter(propertyName),
    setter,
    format: moneyFormat(currencyProperty),
    decimalsCount,
    ...otherProps,
  };
};

export const checkboxColumn = (propertyName: string, otherProps: DataGridColumn): DataGridColumn => {
  const setter = !otherProps || otherProps.readonly !== false ? null : defaultSetter(propertyName);
  return {
    type: 'checkbox',
    getter: defaultGetter(propertyName),
    setter,
    format: checkboxFormat,
    ...otherProps,
  };
};

export const colorColumn = (propertyName: string, otherProps: DataGridColumn): DataGridColumn => {
  const setter = !otherProps || otherProps.readonly !== false ? null : defaultSetter(propertyName);
  return {
    type: 'color',
    getter: defaultGetter(propertyName),
    setter,
    // format: colorFormat,
    render: (ctx: CanvasRenderingContext2D, rect: any, value: any) => {
      ctx.fillStyle = value;
      ctx.fillRect(rect.x, rect.y, rect.w, rect.h);
    },
    ...otherProps,
  };
};

export type DataGridForeignListItems = { items: any[] };

export const foreingListColumn: (
  propertyName: string,
  listItems: DataGridForeignListItems,
  fieldName: string,
  otherProps: DataGridColumn
) => DataGridColumn = (
  propertyName: string,
  listItems: DataGridForeignListItems,
  fieldName: string,
  otherProps: DataGridColumn
) => {
  const setter = !otherProps || otherProps.readonly !== false ? null : defaultSetter(propertyName);
  return {
    type: 'foreing-list',
    getter: foreingListGetter(propertyName),
    setter,
    format: foreingListFormat(fieldName, otherProps.nullText),
    listItems,
    listField: fieldName,
    ...otherProps,
  };
};

export type DataGridRenderFunc = (ctx: CanvasRenderingContext2D, rect: any, value: any) => void;
