import { Form } from "./Form.class";
import { FormField } from "./FormField.class";
import { Notification } from "../../components/notifications/classes/Notification.class";
import { NotificationsComponent } from "../../components/notifications/notifications.component";
import * as $ from 'jquery';

export class FormValidator
{
    public static numericPattern: RegExp = new RegExp(/[0-9]+/g);
    public static alphaPattern: RegExp = new RegExp(/[a-z]/gi);
    public static alphaNumericPattern: RegExp = new RegExp(/[0-9a-z]+/gi);

    public static validateForm(form: Form): any
    {
        let result: any = true;
        for(let i=0; i<form.fields.length; ++i)
        {
            let field: FormField = form.fields[i];
            let valid = FormValidator.validateField(field);
            if (valid !== true && valid !== false)
            {
                if (result === true) result = [];
                result.push(valid);
            }
        }
        return result;
    }

    public static validateField(field: FormField): any
    {
        if (field.data == null) console.log('FormValidator.validateField: field.data is null');
        else if (field.field == null) console.log('FormValidator.validateField: field.field is null : data = ', field.data);
        //else if (field.pattern == null) console.log('FormValidator.validateField: field.pattern is null : field = ', field.field, ', data = ', field.data);
        else
        {
            let val = field.data[field.field];
            let val_str = null;
            switch(field.type)
            {
                case 'number':
                    val_str = val.toString();
                    break;
                case 'string':
                    val_str = val;
                    break;
                case 'foreign-list':
                    if (Array.isArray(val) && val.length > 0) return true;
                    else if (!Array.isArray(val) && val !== null) return true;
                    else return field;
                    // break;
                case 'function':
                    if (typeof field.function === 'function') return field.function(field.data) || field;
                    console.log('FormValidator.validateField: can\'t validate, function is not a function');
                    return field;
                default:
                    console.log('FormValidator.validateField: can\'t validate', typeof(val), 'type : field = ', field.field, ', data = ', field.data);
                    val_str = null;
                    break;
            }
            if (val_str == null) return field;
            else {
                if (field.pattern.test(val_str) === true) return true;
                else return field;
            }
        }
        return false;
    }

    public static join(result1: any, result2: any)
    {
        if (result1 === true && result2 === true) return true;
        if (result1 === true) result1 = [];
        if (result2 === true) result2 = [];
        return result1.concat(result2);
    }

    public static showFormInvalidFields(form: Form, result: any)
    {
        for(let i=0; i<form.fields.length; ++i)
        {
            let field = form.fields[i];
            if (field.element) {
                let elem = field.element.nativeElement;
                $(elem).removeClass('form-invalid-field');
            }
        }
        if (Array.isArray(result))
        {
            for(let i=0; i<result.length; ++i)
            {
                let field = result[i] as FormField;
                console.log('validation field:', field);
                if (field.element)
                {
                    let elem = field.element.nativeElement;
                    $(elem).addClass('form-invalid-field');
                }
            }
        }
    }

    public static showNotification(result: any)
    {
        let notif: Notification = {
            type: 'error',
            title: 'Vérification des champs',
            summary: null
        };
        if (Array.isArray(result))
        {
            if (result.length === 1)
            {
                let field: FormField = (result as FormField[])[0];
                notif.summary = field.error_message ? field.error_message : ('Le champ <b><i>' + field.name + '</b></i> n\'est pas valide');
            }
            else
            {
                let lines: string[] = [];
                for(let i=0; i<result.length; ++i)
                {
                    let field: FormField = (result as FormField[])[i];
                    lines.push(field.error_message ? field.error_message : ('Le champ <b><i>' + field.name + '</b></i> n\'est pas valide'));
                    console.log(notif.content);
                }
                notif.content = lines.join('<br/>');
                notif.summary = '<b>' + result.length + '</b> champs ne sont pas valides.';
            }
            NotificationsComponent.push(notif);
        }
    }
}