import { UserProfile } from "./UserProfile.class";
import { ObjectModel2 } from "../objects/ObjectModel2.class";
import { ObjectDefinition } from "../objects/ObjectDefinition.class";
import { Signature } from "src/app/modules/other-data/classes/Signature";
import { ObjectModel3 } from "../objects/ObjectModel3.class";
import { LoadSequence } from "../objects/LoadSequence.class";

export class User extends ObjectModel3
{
    public username: string = null;
    public name: string = null;
    public profile: UserProfile = null;
    public signature: Signature = null;
    public admin: number = 0;
    public preferences: string = null;

    constructor()
    {
        super(User);
    }

    public getPreference(name: string, defaultValue: any = null) {
        const prefs: any = JSON.parse(this.preferences || "{}");
        return (name in prefs ? prefs[name] : defaultValue);
    }

    public setPreference(name: string, value: any) {
        const prefs: any = JSON.parse(this.preferences || "{}");
        prefs[name] = value;
        this.preferences = JSON.stringify(prefs);
        this.save3(false);
    }

    /* ObjectModel FUNCTIONS */

    public static definition: ObjectDefinition = {
        database: {
            table: 'users',
            id: 'id',
			db_id: 'db_id'
        },
        values: {
            'username': { type: 'string' },
            'name': { type: 'string' },
            'admin': { type: 'number' },
            'preferences': { type: 'string' }
        },
        children: {
            'profile': { type: 'UserProfile', clone: false, save: false, delete: false },
            'signature': { type: 'Signature', clone: false, save: false, delete: false }
        },
        links: {}
    };
}
