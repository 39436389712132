import { PrintStyle } from "./PrintStyle.class";

export class PrintableElement
{
    public style?: any = null;
    public image?: any = null;
    public imageStyle?: any = null;
    public html?: any = null;
    public rawHtml?: string;
    public children?: PrintableElement[] = null;
}