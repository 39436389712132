import { ObjectModel2 } from "../../../classes/objects/ObjectModel2.class";
import { ObjectDefinition } from "../../../classes/objects/ObjectDefinition.class";
import { ObjectModel3 } from "src/app/classes/objects/ObjectModel3.class";
import { LoadSequence } from "src/app/classes/objects/LoadSequence.class";

export class CustomerCategory extends ObjectModel3
{
    public name: string = null;

    public constructor()
    {
        super(CustomerCategory);
    }

    /* ObjectModel FUNCTIONS */

    public static definition: ObjectDefinition = {
        database: {
            table: 'customer_categories',
            id: 'id',
			db_id: 'db_id'
        },
        children: {},
        links: {},
        values: {
            'name': { type: 'string' },
            'visit_days': { type: 'number' },
        }
    };
}
