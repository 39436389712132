import { Component, OnInit } from '@angular/core';
import { DialogComponent } from 'src/app/components/dialogs/dialog/dialog.component';
import { Stock } from '../../classes/Stock.class';
import {BsModalRef} from 'ngx-bootstrap';

@Component({
  selector: 'app-select-stock-dialog',
  templateUrl: './select-stock-dialog.component.html',
  styleUrls: ['./select-stock-dialog.component.css']
})
export class SelectStockDialogComponent extends DialogComponent {

    public selectedStock: Stock = null;
    public stocks: Stock[] = null;

}

