import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import * as $ from 'jquery';
import { SlicePipe } from '@angular/common';
import { DataListSearchPipe } from './data-list-search.pipe';
import { ArrayUtil } from '../../modules/utils/classes/ArrayUtil.class';
import { DomSanitizer } from '@angular/platform-browser';
import { DataGridRowAction } from '../data-grid/classes/DataGridRowAction.class';
//import { DatalistFiltersPipe } from './data-list-filters.pipe';


@Component({
  selector: 'data-list',
  templateUrl: './data-list.component.html',
  styleUrls: ['./data-list.component.css']
})
export class DataListComponent implements OnInit {

    @Input() fields = null;
    @Input() items = null;
    @Input() actions: DataGridRowAction[] = null;

    constructor(private elem: ElementRef,
                public sanitizer: DomSanitizer) {
    }

    ngOnInit() {
    }

    /* PAGINATION */

    limitValues = [ 10, 20, 30, 50, 100, 500 ];
    selectedPageSize = 10;
    pageSize = 10;
    currentPage = 0;
    showAll = false;

    get pagesCount() {
        if (!this.searchResults) return 0;
        else if (this.showAll === true) return 1;
        else return Math.ceil((this.searchResults.length)/this.pageSize);
    }

    prevPage() {
        --this.currentPage;
    }
    nextPage() {
        ++this.currentPage;
    }
    selectedPageSizeChanged() {
        let firstItem = this.currentPage * this.pageSize;
        this.pageSize = this.selectedPageSize;
        this.currentPage = Math.floor(firstItem / this.selectedPageSize);
    }
    showAllChanged() {
        console.log('show all:', this.showAll);
        if (this.showAll === true) {
            this.pageSize = this.searchResults.length;
        } else {
            this.pageSize = this.selectedPageSize;
            this.currentPage = 0;
        }
    }

    /* TEMPLATING */

    // executeFunction(field, value) {
    //     return field.function(field, value)
    // }

    displayTemplate(field, value, nullValue = '-') {
        if (value == null) value = nullValue;
        return this.sanitizer.bypassSecurityTrustHtml(field.template.replace(/##value##/g, value));
    }

    /* SEARCH */

    searchPattern = '';
    searchFields = ['name'];

    get searchResults() {
        let result = (new DataListSearchPipe()).transform(this.items, this.searchPattern, this.searchFields);
        return result;
    }

    /* EVENTS */

    @Output() itemClick: EventEmitter<any> = new EventEmitter();
    @Output() itemDblClick: EventEmitter<any> = new EventEmitter();

    listItemClick(event, item) {
        event.item = item;
        if (event.detail == 2) {
            event.preventDefault();
            this.itemDblClick.next(event);
        }
        else {
            this.setSelection(event, item);
            this.itemClick.next(event);
        }
    }


    /* SELECTION */

    public selectedItems: any[] = [];

    setSelection(event, item)
    {
        if (event.ctrlKey === true) ArrayUtil.toggleItem(this.selectedItems, item);
        else this.selectedItems = [ item ];
    }
    
    /* ROW ACTIONS */
    public actionsItem: any = null;
    actionClick(event, action, item)
    {
        setTimeout(() => {
            this.actionsItem = null;
            if (action.click && typeof(action.click) === 'function') action.click(event, item);
        }, 0);
    }

}
