import { ReportModel } from "../../reports/classes/ReportModel.class";
import { ReportRow } from "../../reports/classes/ReportRow.class";
import { LoadingPromise } from "src/app/classes/objects/LoadingPromise.class";
import { ReportHeaderRow } from "../../reports/classes/ReportHeaderRow.class";
import { DataService } from "src/app/services/data/data.service";
import { ReportSetting } from "../../reports/classes/ReportSetting.class";
import { ApiService } from "src/app/services/api/api.service";
import { NumberUtil } from "../../utils/classes/NumberUtil.class";
import { ReportCell } from "../../reports/classes/ReportCell.class";
import { ObjectUtil } from "../../utils/classes/ObjectUtil.class";
import * as am4charts from '@amcharts/amcharts4/charts';
import {StatsModule} from '../stats.module';

export class YearlyResultReportModel extends ReportModel
{
    public title: string = "RÉSULTATS ANNUELS";
    public settings: ReportSetting[] = [
        { name: 'year_start', title: 'Année de début', type: 'number', value: (new Date()).getFullYear() - 4 },
        { name: 'year_end', title: 'Année de fin', type: 'number', value: (new Date()).getFullYear() }
    ];

    public tableStyle: any = {
        'border': '2px solid black'
    };

    public headerRows: ReportHeaderRow[] = [
        { cells: [] }
    ];
    public rows: ReportRow[] = [];
    public points: any[] = [];
    public tri_points: any[] = [];
    public net_points: any[] = [];
    public net_tri_points: any[] = [];

    public static split_CellStyle: any = {
        'background': 'none',
        'border-left': '2px solid black',
        'border-right': '2px solid black',
        'border-top': 'none',
        'border-bottom': 'none',
        'width': '40px'
    };

    public static raw_titleOrTotal_CellStyle: any = {
        'background-color': 'rgb(153,0,0)',
        'color': 'white',
        'font-weight': 'bold',
        'border-top': '2px solid black',
        'border-bottom': '2px solid black',
        'text-align': 'center'
    };
    public static raw_subTotal_CellStyle: any = {
        'background-color': 'rgb(153,51,0)',
        'color': 'white',
        'font-weight': 'bold',
        'text-align': 'center'
    };
    public static raw_body_CellStyle: any = {
        // 'background-color': 'rgb(217, 217, 217)',
        'color': 'black'
    };
    public static raw_bodyTotal_CellStyle: any = {
        'background-color': 'rgb(217, 217, 217)',
        'color': 'black',
        'font-weight': 'bold'
    };
    public static raw_month_CellStyle: any = {
        'background-color': 'rgb(192,0,0)',
        'color': 'white',
        'font-weight': 'bold',
        'text-align': 'center'
    };

    public static net_titleOrTotal_CellStyle: any = {
        'background-color': 'rgb(255,204,0)',
        'color': 'black',
        'font-weight': 'bold',
        'border-top': '2px solid black',
        'border-bottom': '2px solid black',
        'text-align': 'center'
    };
    public static net_subTotal_CellStyle: any = {
        'background-color': 'rgb(255,192,0)',
        'color': 'black',
        'font-weight': 'bold',
        'text-align': 'center'
    };
    public static net_body_CellStyle: any = {
        // 'background-color': 'rgb(217, 217, 217)',
        'color': 'black'
    };
    public static net_bodyTotal_CellStyle: any = {
        'background-color': 'rgb(217, 217, 217)',
        'color': 'black',
        'font-weight': 'bold'
    };
    public static net_month_CellStyle: any = {
        'background-color': 'rgb(255,255,0)',
        'color': 'black',
        'font-weight': 'bold',
        'text-align': 'center'
    };

    public static generate(settings: ReportSetting[] = null)
    {
        let model: YearlyResultReportModel = new YearlyResultReportModel();
        if (settings) model.settings = settings;
        // model.regenerate();
        return model;
    }

    public regenerate()
    {
        return LoadingPromise.create<any>((resolve, reject) => {

            let month_names :string[] = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Aout','Septembre','Octobre','Novembre','Décembre'];
            let tri_month_names :string[] = ['1er trimestre', '2ème trimestre', '3ème trimestre', '4ème trimestre'];

            let year: number = parseInt(this.getSetting('year_end').value);
            let years: number = parseInt(this.getSetting('year_end').value) - parseInt(this.getSetting('year_start').value) + 1;

            this.headerRows = [
                { cells: [
                    { value: "MARGES BRUTES DÉGAGÉES", colSpan: years*2+1, style: YearlyResultReportModel.raw_titleOrTotal_CellStyle },
                    { value: "&nbsp;", style: YearlyResultReportModel.split_CellStyle, rowSpan: 20 },
                    { value: "MARGES NETTES DÉGAGÉES", colSpan: years*2, style: YearlyResultReportModel.net_titleOrTotal_CellStyle }
                ] },
                { cells: [
                    { value: '&nbsp;', style: YearlyResultReportModel.raw_subTotal_CellStyle, rowSpan: 2 }
                ] },
                { cells: [] }
            ];
            if (years <= 0) reject('number of years must be >= 0');
            this.points = [];
            this.tri_points = [];
            for(let i=0; i<12; ++i) this.points.push({ name: month_names[i], month: i });
            for(let i=0; i<4; ++i) this.tri_points.push({ name: tri_month_names[i], month: i });
            this.net_points = [];
            this.net_tri_points = [];
            for(let i=0; i<12; ++i) this.net_points.push({ name: month_names[i], month: i });
            for(let i=0; i<4; ++i) this.net_tri_points.push({ name: tri_month_names[i], month: i });
            for(let y=0; y<years; ++y)
            {
                this.headerRows[1].cells.push({ value: ''+(year-years+y+1), colSpan: 2, style: YearlyResultReportModel.raw_month_CellStyle });
                this.headerRows[2].cells.push({ value: '€', style: YearlyResultReportModel.raw_month_CellStyle });
                this.headerRows[2].cells.push({ value: '%', style: YearlyResultReportModel.raw_month_CellStyle });
            }
            for(let y=0; y<years; ++y)
            {
                this.headerRows[1].cells.push({ value: ''+(year-years+y+1), colSpan: 2, style: YearlyResultReportModel.net_month_CellStyle });
                this.headerRows[2].cells.push({ value: '€', style: YearlyResultReportModel.net_month_CellStyle });
                this.headerRows[2].cells.push({ value: '%', style: YearlyResultReportModel.net_month_CellStyle });
            }

            this.rows = [];

            ApiService.callModule('reports', 'report', {
                query: 'monthly_margins',
                settings: { year_start: this.getSetting('year_start').value, year_end: this.getSetting('year_end').value }
            }).then(
                (result2: any) => {
                    let buys_month: number[] = [];
                    let buys_year: number[] = [];
                    let buys: number[][] = [];
                    let raw_margins_month: number[] = [];
                    let raw_margins_year: number[] = [];
                    let raw_margins: number[][] = [];
                    let net_margins_month: number[] = [];
                    let net_margins_year: number[] = [];
                    let net_margins: number[][] = [];
                    let month_count: number[] = [];

                    // let result2: any = results[j];
                    console.log('result:', result2);
                    for(let i=0; i<result2.details.length; ++i)
                    {
                        let year_str: any = result2.details[i]['year'];
                        let year: number = parseFloat(year_str);
                        let month_str: any = result2.details[i]['month'];
                        let month: number = parseFloat(month_str);
                        let buy_str: any = result2.details[i]['total_buy'];
                        let buy: number = parseFloat(buy_str);
                        let raw_margin_str: any = result2.details[i]['raw_margin'];
                        let raw_margin: number = parseFloat(raw_margin_str);
                        let net_margin_str: any = result2.details[i]['net_margin'];
                        let net_margin: number = parseFloat(net_margin_str);
                        if (year >= 1 && month >= 1)
                        {
                            if (!buys[year]) buys[year] = []
                            if (!raw_margins[year]) raw_margins[year] = []
                            if (!net_margins[year]) net_margins[year] = []
                            if (!buys[year][month-1]) buys[year][month-1] = 0;
                            if (!raw_margins[year][month-1]) raw_margins[year][month-1] = 0;
                            if (!net_margins[year][month-1]) net_margins[year][month-1] = 0;
                            if (buy && buy != 0) buys[year][month-1] += buy;
                            if (raw_margin && raw_margin != 0) raw_margins[year][month-1] += raw_margin;
                            if (net_margin && net_margin != 0) net_margins[year][month-1] += net_margin;
                        }
                    }

                    for(let i=0; i<12; ++i)
                    {
                        try
                        {
                            let cells: ReportCell[] = [
                                { value: ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Aout','Septembre','Octobre','Novembre','Décembre'][i], style: YearlyResultReportModel.raw_month_CellStyle },
                            ]
                            let add_month: boolean = false;
                            for(let y=year-years+1; y<=year; ++y)
                            {
                                let buy: number = buys[y] ? buys[y][i] || 0 : 0;
                                let raw_margin: number = raw_margins[y] ? raw_margins[y][i] || 0 : 0;
                                let raw_perc: number = raw_margin / buy;
                                cells.push(
                                    { value: raw_margin && raw_margin != 0 ? NumberUtil.formatMoney(raw_margin, '€', 2, '.') : '', style: ObjectUtil.merge(YearlyResultReportModel.raw_body_CellStyle, ReportCell.moneyCellStyle) },
                                    { value: raw_perc && raw_perc != 0 ? NumberUtil.formatMoney(raw_perc*100, '%', 1, '.') : '', style: ObjectUtil.merge(YearlyResultReportModel.raw_body_CellStyle, ReportCell.moneyCellStyle) }
                                );
                                this.points[i][y] = raw_margin;
                                if (raw_margin != 0) add_month = true;
                                if (!raw_margins_month[y]) raw_margins_month[y] = 0;
                                raw_margins_month[y] += raw_margin;
                                if (!raw_margins_year[y]) raw_margins_year[y] = 0;
                                raw_margins_year[y] += raw_margin;
                            }
                            for(let y=year-years+1; y<=year; ++y)
                            {
                                let buy: number = buys[y] ? buys[y][i] || 0 : 0;
                                let net_margin: number = net_margins[y] ? net_margins[y][i] || 0 : 0;
                                let net_perc: number = net_margin / buy;
                                cells.push(
                                    { value: net_margin && net_margin != 0 ? NumberUtil.formatMoney(net_margin, '€', 2, '.') : '', style: ObjectUtil.merge(YearlyResultReportModel.net_body_CellStyle, ReportCell.moneyCellStyle) },
                                    { value: net_perc && net_perc != 0 ? NumberUtil.formatMoney(net_perc*100, '%', 1, '.') : '', style: ObjectUtil.merge(YearlyResultReportModel.net_body_CellStyle, ReportCell.moneyCellStyle) }
                                );
                                this.net_points[i][y] = net_margin;
                                if (net_margin != 0 || add_month) {
                                    if (!month_count[y]) month_count[y] = 0;
                                    ++month_count[y];
                                }
                                if (!net_margins_month[y]) net_margins_month[y] = 0;
                                net_margins_month[y] += net_margin;
                                if (!net_margins_year[y]) net_margins_year[y] = 0;
                                net_margins_year[y] += net_margin;
                                if (!buys_month[y]) buys_month[y] = 0;
                                buys_month[y] += buy;
                                if (!buys_year[y]) buys_year[y] = 0;
                                buys_year[y] += buy;
                            }
                            this.rows.push({
                                cells: cells
                            });

                            if ((i+1) % 3 == 0)
                            {
                                let j: number = Math.floor(i/3);
                                let cells: ReportCell[] = [
                                    { value: ['1er trimestre', '2ème trimestre', '3ème trimestre', '4ème trimestre'][Math.floor(i/3)], style: YearlyResultReportModel.raw_subTotal_CellStyle },
                                ]
                                for(let y=year-years+1; y<=year; ++y)
                                {
                                    let buy_month: number = buys_month[y] || 0;
                                    let raw_margin_month: number = raw_margins_month[y] || 0;
                                    let raw_perc_month: number = buy_month != 0 ? raw_margin_month / buy_month : 0;
                                    cells.push(
                                        { value: raw_margin_month && raw_margin_month != 0 ? NumberUtil.formatMoney(raw_margin_month, '€', 2, '.') : '', style: ObjectUtil.merge(YearlyResultReportModel.raw_subTotal_CellStyle, ReportCell.moneyCellStyle) },
                                        { value: raw_perc_month && raw_perc_month != 0 ? NumberUtil.formatMoney(raw_perc_month*100, '%', 1, '.') : '', style: ObjectUtil.merge(YearlyResultReportModel.raw_subTotal_CellStyle, ReportCell.moneyCellStyle) }
                                    );
                                    this.tri_points[j][y] = raw_margin_month;
                                }
                                for(let y=year-years+1; y<=year; ++y)
                                {
                                    let buy_month: number = buys_month[y] || 0;
                                    let net_margin_month: number = net_margins_month[y] || 0;
                                    let net_perc_month: number = buy_month != 0 ? net_margin_month / buy_month : 0;
                                    cells.push(
                                        { value: net_margin_month && net_margin_month != 0 ? NumberUtil.formatMoney(net_margin_month, '€', 2, '.') : '', style: ObjectUtil.merge(YearlyResultReportModel.net_subTotal_CellStyle, ReportCell.moneyCellStyle) },
                                        { value: net_perc_month && net_perc_month != 0 ? NumberUtil.formatMoney(net_perc_month*100, '%', 1, '.') : '', style: ObjectUtil.merge(YearlyResultReportModel.net_subTotal_CellStyle, ReportCell.moneyCellStyle) }
                                    );
                                    buys_month[y] = 0;
                                    raw_margins_month[y] = 0;
                                    net_margins_month[y] = 0;
                                    this.net_tri_points[j][y] = net_margin_month;
                                }
                                this.rows.push({
                                    cells: cells
                                });
                            }
                        }
                        catch { continue; }
                    }


                    let cells: ReportCell[] = [
                        { value: 'TOTAUX', style: YearlyResultReportModel.raw_subTotal_CellStyle },
                    ]
                    for(let y=year-years+1; y<=year; ++y)
                    {
                        let buy_year: number = buys_year[y] || 0;
                        let raw_margin_year: number = raw_margins_year[y] || 0;
                        let raw_perc_year: number = buy_year != 0 ? raw_margin_year / buy_year : 0;
                        cells.push(
                            { value: raw_margin_year && raw_margin_year != 0 ? NumberUtil.formatMoney(raw_margin_year, '€', 2, '.') : '', style: ObjectUtil.merge(YearlyResultReportModel.raw_subTotal_CellStyle, ReportCell.moneyCellStyle) },
                            { value: raw_perc_year && raw_perc_year != 0 ? NumberUtil.formatMoney(raw_perc_year*100, '%', 1, '.') : '', style: ObjectUtil.merge(YearlyResultReportModel.raw_subTotal_CellStyle, ReportCell.moneyCellStyle) }
                        );
                    }
                    for(let y=year-years+1; y<=year; ++y)
                    {
                        let buy_year: number = buys_year[y] || 0;
                        let net_margin_year: number = net_margins_year[y] || 0;
                        let net_perc_year: number = buy_year != 0 ? net_margin_year / buy_year : 0;
                        cells.push(
                            { value: net_margin_year && net_margin_year != 0 ? NumberUtil.formatMoney(net_margin_year, '€', 2, '.') : '', style: ObjectUtil.merge(YearlyResultReportModel.net_subTotal_CellStyle, ReportCell.moneyCellStyle) },
                            { value: net_perc_year && net_perc_year != 0 ? NumberUtil.formatMoney(net_perc_year*100, '%', 1, '.') : '', style: ObjectUtil.merge(YearlyResultReportModel.net_subTotal_CellStyle, ReportCell.moneyCellStyle) }
                        );
                    }
                    this.rows.push({
                        cells: cells
                    });


                    console.log('points:', this.points);
                    console.log('tri_points:', this.tri_points);
                    console.log('net_points:', this.net_points);
                    console.log('net_tri_points:', this.net_tri_points);

                    let series: any[] = [];
                    let tri_series: any[] = [];
                    for(let y=year-years+1; y<=year; ++y) {
                        series.push({
                            type: 'ColumnSeries', name: y, dataFields: { valueY: y, categoryX: 'name' },
                            // bullets: [{ type: 'CircleBullet', stroke: '#fff', strokeWidth: 2, yAxis: 'numbers' }]
                        });
                        tri_series.push({
                            type: 'ColumnSeries', name: y, dataFields: { valueY: y, categoryX: 'name' },
                            // bullets: [{ type: 'CircleBullet', stroke: '#fff', strokeWidth: 2, yAxis: 'numbers' }]
                        });
                    }

                    this.charts = [];

                    this.charts.push({
                        type: am4charts.XYChart,
                        inline: false,
                        titles: [{ text: "Marges brutes mensuelles dégagées", fontSize: 25, marginBottom: 30 }],
                        series: series,
                        colors: StatsModule.chartsColor,
                        data: this.points,
                        yAxes: [
                            { type: 'ValueAxis', id: 'numbers', dataFields: { value: 'value', category: 'name' }, title: { text: 'Montants' } },
                        ],
                        xAxes: [
                            {
                                type: 'CategoryAxis', dataFields: {value: 'value', category: 'name'},
                                renderer: { cellStartLocation: 0.1, cellEndLocation: 0.9 }
                            }
                        ],
                        legend: {
                            numberFormatter: {
                                numberFormat: "#."
                            },
                        },
                        container_width: 1000,
                        container_height: 480,
                        events: {
                            beforedatavalidated: function(ev) {
                                console.log('beforedatavalidated event:', ev);
                                ev.target.data.sort((a,b) => { return a.month - b.month; });
                            }
                        }
                    });


                    this.charts.push({
                        type: am4charts.XYChart,
                        inline: false,
                        titles: [{ text: "Marge nettes mensuelles dégagées", fontSize: 25, marginBottom: 30 }],
                        series: tri_series,
                        colors: StatsModule.chartsColor,
                        data: this.net_points,
                        yAxes: [
                            { type: 'ValueAxis', id: 'numbers', dataFields: { value: 'value', category: 'name' }, title: { text: 'Montants' } },
                        ],
                        xAxes: [
                            {
                                type: 'CategoryAxis', dataFields: {value: 'value', category: 'name'},
                                renderer: { cellStartLocation: 0.1, cellEndLocation: 0.9 }
                            }
                        ],
                        legend: {
                            numberFormatter: {
                                numberFormat: "#."
                            },
                        },
                        container_width: 1000,
                        container_height: 480,
                        events: {
                            beforedatavalidated: function(ev) {
                                console.log('beforedatavalidated event:', ev);
                                ev.target.data.sort((a,b) => { return a.month - b.month; });
                            }
                        }
                    });

                    this.charts.push({
                        type: am4charts.XYChart,
                        inline: false,
                        titles: [{ text: "Marges brutes trimestrielles dégagées", fontSize: 25, marginBottom: 30 }],
                        series: series,
                        colors: StatsModule.chartsColor,
                        data: this.tri_points,
                        yAxes: [
                            { type: 'ValueAxis', id: 'numbers', dataFields: { value: 'value', category: 'name' }, title: { text: 'Montants' } },
                        ],
                        xAxes: [
                            {
                                type: 'CategoryAxis', dataFields: {value: 'value', category: 'name'},
                                renderer: { cellStartLocation: 0.1, cellEndLocation: 0.9 }
                            }
                        ],
                        legend: {},
                        container_width: 1000,
                        container_height: 480,
                        events: {
                            beforedatavalidated: function(ev) {
                                console.log('beforedatavalidated event:', ev);
                                ev.target.data.sort((a,b) => { return a.month - b.month; });
                            }
                        }
                    });


                    this.charts.push({
                        type: am4charts.XYChart,
                        inline: false,
                        titles: [{ text: "Marge nettes trimestrielles dégagées", fontSize: 25, marginBottom: 30 }],
                        series: tri_series,
                        colors: StatsModule.chartsColor,
                        data: this.net_tri_points,
                        yAxes: [
                            { type: 'ValueAxis', id: 'numbers', dataFields: { value: 'value', category: 'name' }, title: { text: 'Montants' } },
                        ],
                        xAxes: [
                            {
                                type: 'CategoryAxis', dataFields: {value: 'value', category: 'name'},
                                renderer: { cellStartLocation: 0.1, cellEndLocation: 0.9 }
                            }
                        ],
                        legend: {},
                        container_width: 1000,
                        container_height: 480,
                        events: {
                            beforedatavalidated: function(ev) {
                                console.log('beforedatavalidated event:', ev);
                                ev.target.data.sort((a,b) => { return a.month - b.month; });
                            }
                        }
                    });



                    resolve(true);
                },
                (err) => {
                    console.error(err);
                    reject(err);
                }
            );
        });
    }
}
