<ul class="debug-flags" *ngIf="debugMode">
  <li class="debug">DEBUG MODE</li>
</ul>

<div class="main-wrapper">
  <!--
    <div class="toolbars" [ngStyle]="{'margin-left': (-100*currentPage)+'%'}">
        <app-toolbar *ngFor="let toolbar of toolbars" [toolbar]="toolbar"></app-toolbar>
    </div> -->
  <!-- <div class="views" [ngStyle]="{'margin-left': (-100*currentPage)+'%'}"> -->
  <app-views #views></app-views>
  <!-- </div> -->

  <app-print-preview [global]="true"></app-print-preview>

  <app-settings></app-settings>
  <app-notifications #notifications></app-notifications>
  <app-notes></app-notes>
  <app-dialogs></app-dialogs>
  <app-loading></app-loading>
  <app-chars-table></app-chars-table>
</div>
