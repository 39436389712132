import { DataGridColumn } from "./DataGridColumn.class";

export interface IFilter<T> {

    column: DataGridColumn;

    operators: FilterOperators<T>;
    operatorsList: FilterOperator<T>[];

    availableValues: T[];
    formattedValues: string[];
    selectedValues: T[];

    value1?: T;
    value2?: T;
    value1String: string;
    value2String: string;
    operator1?: FilterOperator<T>;
    operator2?: FilterOperator<T>;
    operand: FilterOperand;

    isActive(): boolean;
    check(value: T): boolean;
    clear(): void;

    sortActions: SortActions<T>;
    filterActionsName?: string;
    filterActions: FilterActions<T>;
};

export type Filter = IFilter<any>;

export type FilterFunction<T> = (currentValue: T, compareValue: T) => boolean;
export type FilterOperator<T> = {
    displayName: string;
    func: FilterFunction<T>;
};

export enum FilterOperand {
    And = 0,
    Or = 1
};

export type FilterOperators<T> = { [name: string]: FilterOperator<T> };

export type SortFunction<T> = (value1: T, value2: T) => number;

export type SortAction<T> = {
    displayName: string;
    img?: string;
    func: SortFunction<T>;
};

export type SortActions<T> = SortAction<T>[];


export type FilterAction<T> = {
    displayName: string;
    operator1?: FilterOperator<T>;
    value1?: T;
    operand?: FilterOperand;
    operator2?: FilterOperator<T>;
    value2?: T;
}

export type FilterActions<T> = FilterAction<T>[];
