import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components/components.module';
import { HomepageModule } from '../homepage/homepage.module';
import { ModuleBase } from '../module-base.class';

import { SuppliersListViewComponent } from './views/suppliers-list-view/suppliers-list-view.component';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { SupplierViewComponent } from './views/supplier-view/supplier-view.component';
import { FormsModule } from '@angular/forms';
import { ClassManager } from '../../classes/objects/ClassManager.class';
import { SupplierCategory } from './classes/SupplierCategory.class';
import { Supplier } from './classes/Supplier.class';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ComponentsModule,
        HomepageModule
    ],
    declarations: [
        SuppliersListViewComponent,
        SupplierViewComponent
    ],
    exports: [
        SuppliersListViewComponent,
        SupplierViewComponent
    ],
    entryComponents: [
        SuppliersListViewComponent,
        SupplierViewComponent
    ]
})

export class SuppliersModule extends ModuleBase {

    public static moduleName = 'suppliers';

    public ngOnInit()
    {
        ClassManager.registerClass('Supplier', Supplier);
        ClassManager.registerClass('SupplierCategory', SupplierCategory);

        HomepageViewComponent.registerGroups({
            'database': {
                'name': "Base de données",
                'permission': 'home_groupe_basededonnees',
                'children': [
                    {
                        'name': "Fournisseurs",
                        'permission': 'home_bouton_basededonnees_fournisseurs',
                        'component': SuppliersListViewComponent
                    }
                ]
            }
        });

        return super.ngOnInit();
    }

}
