import { ObjectModel2 } from "../../../classes/objects/ObjectModel2.class";
import { ObjectDefinition } from "../../../classes/objects/ObjectDefinition.class";
import { ObjectModel3 } from "src/app/classes/objects/ObjectModel3.class";

export class Country extends ObjectModel3
{
    public name: string = null;
    public iso_code: string = null;

    public constructor()
    {
        super(Country);
    }

    /* ObjectModel FUNCTIONS */

    public static definition: ObjectDefinition = {
        database: {
            table: 'countries',
            id: 'id',
			db_id: 'db_id'
        },
        values: {
            'name': { type: 'string' },
            'iso_code': { type: 'string' }
        },
        children: {},
        links: {}
    };
}
