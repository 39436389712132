import * as am4charts from '@amcharts/amcharts4/charts';

import { ApiService } from 'src/app/services/api/api.service';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { ObjectUtil } from '../../utils/classes/ObjectUtil.class';
import { ReportCell } from '../../reports/classes/ReportCell.class';
import { ReportHeaderRow } from '../../reports/classes/ReportHeaderRow.class';
import { ReportModel } from '../../reports/classes/ReportModel.class';
import { ReportRow } from '../../reports/classes/ReportRow.class';
import { ReportSetting } from '../../reports/classes/ReportSetting.class';

export class PurchasesBySupplierReportModel extends ReportModel {
  public title: string = 'RÉPARTITION DES ACHATS ET DES PAIEMENTS PAR FOURNISSEUR (H.T.V.A.)';
  public settings: ReportSetting[] = [
    { name: 'year_start', title: 'Année de début', type: 'number', value: new Date().getFullYear() },
    { name: 'year_end', title: 'Année de fin', type: 'number', value: new Date().getFullYear() },
    {
      name: 'order_by',
      title: 'Trier par',
      type: 'list',
      listField: 'text',
      listItems: [
        { text: 'Nom', value: '`name`' },
        { text: 'Rang', value: '-`rank` DESC' },
        { text: 'Balance', value: 'SUM(t.balance) DESC' },
        { text: 'Payé', value: 'SUM(t.paid) DESC' },
        { text: 'A échoir', value: 'SUM(t.balance)-SUM(t.paid) DESC' },
      ],
      value: { text: 'Nom', value: '`name`' },
    },
  ];

  public tableStyle: any = {
    border: '2px solid black',
  };

  public headerRows: ReportHeaderRow[] = [{ cells: [] }];
  public rows: ReportRow[] = [];
  public dataPoints: any[] = [];

  public static titleCellStyle: any = {
    'background-color': 'rgb(51,51,255)',
    color: 'white',
    'font-weight': 'bold',
    'border-top': '2px solid black',
    'border-bottom': '2px solid black',
    'text-align': 'center',
  };
  public static headerCellStyle: any = {
    'background-color': 'rgb(141,180,226)',
    color: 'white',
    'font-weight': 'bold',
    'text-align': 'center',
  };
  public static body_CellStyle: any = {
    // 'background-color': 'rgb(217, 217, 217)',
    color: 'black',
  };
  public static name_CellStyle: any = {
    color: 'black',
    'font-weight': 'bold',
  };
  public static bodyTotal_CellStyle: any = {
    'background-color': 'rgb(217, 217, 217)',
    color: 'black',
    'font-weight': 'bold',
  };
  public static month_CellStyle: any = {
    'background-color': 'rgb(196, 189, 151)',
    color: 'black',
    'font-weight': 'bold',
    'text-align': 'center',
  };

  public static generate(settings: ReportSetting[] = null) {
    let model: PurchasesBySupplierReportModel = new PurchasesBySupplierReportModel();
    if (settings) model.settings = settings;
    // model.regenerate();
    return model;
  }

  public regenerate() {
    return LoadingPromise.create<any>((resolve, reject) => {
      this.headerRows = [
        {
          cells: [
            {
              value: 'RÉPARTITION DES ACHATS ET PAIEMENTS PAR FOURNISSEUR (H.T.V.A.)',
              colSpan: 4,
              style: PurchasesBySupplierReportModel.titleCellStyle,
            },
          ],
        },
        {
          cells: [
            { value: 'Noms des fournisseurs', rowSpan: 2, style: PurchasesBySupplierReportModel.headerCellStyle },
            { value: 'Balance', style: PurchasesBySupplierReportModel.headerCellStyle },
            // { value: 'Payé', style: PurchasesBySupplierReportModel.headerCellStyle },
            //{ value: 'A réclamer', style: PurchasesBySupplierReportModel.headerCellStyle },
            // { value: 'A échoir', style: PurchasesBySupplierReportModel.headerCellStyle },
            { value: 'Rang', style: PurchasesBySupplierReportModel.headerCellStyle },
          ],
        },
      ];

      ApiService.callModule('reports', 'report', {
        query: 'suppliers_balance',
        settings: {
          year_start: this.getSetting('year_start').value,
          year_end: this.getSetting('year_end').value,
          order_by: this.getSetting('order_by').value ? this.getSetting('order_by').value.value || 'name' : 'name',
        },
      }).then(
        (result2) => {
          console.log('result:', result2);
          let balance_total: number = 0;
          let paid_total: number = 0;
          let to_claim_total: number = 0;
          let to_go_total: number = 0;
          this.rows = [];
          this.dataPoints = [];
          for (let i = 0; i < result2.details.length; ++i) {
            let name: string = result2.details[i].name;
            let balance_str: string = result2.details[i].balance;
            let paid_str: string = result2.details[i].paid;
            let to_claim_str: string = result2.details[i].to_claim;
            let rank_str: string = result2.details[i].rank;
            let balance: number = parseFloat(balance_str) || 0;
            let paid: number = parseFloat(paid_str) || 0;
            let to_claim: number = parseFloat(to_claim_str) || 0;
            let rank: number = parseFloat(rank_str) || 0;
            let to_go: number = balance - paid - to_claim;
            result2.details[i].balance = balance;
            result2.details[i].to_go = to_go;
            balance_total += balance;
            paid_total += paid;
            to_claim_total += to_claim;
            to_go_total += to_go;
            this.rows.push({
              cells: [
                { value: name, style: PurchasesBySupplierReportModel.name_CellStyle },
                {
                  value: balance && balance != 0 ? NumberUtil.formatMoney(balance, '€', 2, '.') : '',
                  style: ObjectUtil.merge(PurchasesBySupplierReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                },
                // { value: paid && paid != 0 ? NumberUtil.formatMoney(paid, '€', 2, '.') : '', style: ObjectUtil.merge(PurchasesBySupplierReportModel.body_CellStyle, ReportCell.moneyCellStyle) },
                //{ value: to_claim && to_claim != 0 ? NumberUtil.formatMoney(to_claim, '€', 2, '.') : '', style: ObjectUtil.merge(PurchasesBySupplierReportModel.body_CellStyle, ReportCell.moneyCellStyle) },
                // { value: to_go && to_go != 0 ? NumberUtil.formatMoney(to_go, '€', 2, '.') : '', style: ObjectUtil.merge(PurchasesBySupplierReportModel.body_CellStyle, ReportCell.moneyCellStyle) },
                {
                  value: rank && rank != 0 ? rank_str : '',
                  style: ObjectUtil.merge(PurchasesBySupplierReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                },
              ],
            });
            if (balance > 0) this.dataPoints.push({ value: balance, name: name });
          }

          this.headerRows.push({
            cells: [
              {
                value: balance_total && balance_total != 0 ? NumberUtil.formatMoney(balance_total, '€', 2, '.') : '',
                style: ObjectUtil.merge(PurchasesBySupplierReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              // { value: paid_total && paid_total != 0 ? NumberUtil.formatMoney(paid_total, '€', 2, '.') : '', style: ObjectUtil.merge(PurchasesBySupplierReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle) },
              //{ value: to_claim_total && to_claim_total != 0 ? NumberUtil.formatMoney(to_claim_total, '€', 2, '.') : '', style: ObjectUtil.merge(PurchasesBySupplierReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle) },
              // { value: to_go_total && to_go_total != 0 ? NumberUtil.formatMoney(to_go_total, '€', 2, '.') : '', style: ObjectUtil.merge(PurchasesBySupplierReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle) },
              {
                value: '',
                style: ObjectUtil.merge(PurchasesBySupplierReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle),
              },
            ],
          });

          this.charts = [];

          this.charts.push({
            type: am4charts.XYChart,
            language: {
              locale: {
                _decimalSeparator: ',',
                _thousandSeparator: '.',
              },
            },
            inline: true,
            titles: [{ text: 'Achats par fournisseur', fontSize: 25, marginBottom: 30 }],
            series: [
              {
                type: 'ColumnSeries',
                dataFields: { valueX: 'value', categoryY: 'name' },
                bullets: [
                  {
                    type: 'LabelBullet',
                    label: {
                      text: '{value.formatNumber("#,###.00")}',
                      truncate: false,
                      hideOversized: false,
                      horizontalCenter: 'left',
                      dx: 10,
                    },
                  },
                ],
              },
            ],
            maskBullets: false,
            data: this.dataPoints,
            xAxes: [
              {
                type: 'ValueAxis',
                dataFields: { value: 'value', category: 'name' },
              },
            ],
            yAxes: [
              {
                type: 'CategoryAxis',
                dataFields: { value: 'value', category: 'name' },
                renderer: {
                  minGridDistance: 10,
                  // minLabelPosition: -0.1,
                  // maxLabelPosition: 1.1
                },
                // startLocation: -0.5,
                // endLocation: 1.5
              },
            ],
            container_width: 800,
            container_height: 250 + 25 * this.dataPoints.length,
            events: {
              beforedatavalidated: function (ev) {
                console.log('beforedatavalidated event:', ev);
                ev.target.data.sort((a, b) => {
                  return a.value - b.value;
                });
              },
            },
          });

          //
          // this.gridColumns = [
          //     { title: 'Fournisseur', field: 'name', width: 250 },
          //     { title: 'Balance', field: 'balance', type: 'number', unit: ' €', decimalsCount: 2, width: 150 },
          //     { title: 'A échoir', field: 'to_go', type: 'number', unit: ' €', decimalsCount: 2, width: 150 },
          //     { title: 'Rang', field: 'rank', type: 'number', decimalsCount: 0, width: 75 },
          // ];
          // this.gridItems = result2.details;
          // this.gridStyle = {
          //     'display': 'inline-block'
          // };

          resolve(true);
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }
}
