import { ChangeSaleNumberComponent } from './change-sale-number/change-sale-number.component';
import { ClassManager } from 'src/app/classes/objects/ClassManager.class';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { Customer } from '../customers/classes/Customer.class';
import { CustomerHistoryViewComponent } from './views/customer-history-view/customer-history-view.component';
import { CustomerViewComponent } from '../customers/views/customer-view/customer-view.component';
import { FormsModule } from '@angular/forms';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { InteropViewComponent } from '../interop/interop-view.component';
import { ModuleBase } from '../module-base.class';
import { NgModule } from '@angular/core';
import { Price } from './classes/Price.class';
import { Sale } from './classes/Sale.class';
import { SaleViewComponent } from './views/sale-view/sale-view.component';
import { SalesListViewComponent } from './views/sales-list-view/sales-list-view.component';
import { SalesSqlListViewComponent } from './views/sales-sql-list-view/sales-sql-list-view.component';
import { config } from '../../classes/config';

@NgModule({
  imports: [CommonModule, FormsModule, ComponentsModule],
  declarations: [
    SalesListViewComponent,
    SalesSqlListViewComponent,
    SaleViewComponent,
    ChangeSaleNumberComponent,
    CustomerHistoryViewComponent,
  ],
  exports: [
    SalesListViewComponent,
    SalesSqlListViewComponent,
    SaleViewComponent,
    ChangeSaleNumberComponent,
    CustomerHistoryViewComponent,
  ],
  entryComponents: [
    SalesListViewComponent,
    SalesSqlListViewComponent,
    SaleViewComponent,
    ChangeSaleNumberComponent,
    CustomerHistoryViewComponent,
  ],
})
export class SalesModule extends ModuleBase {
  public static moduleName = 'sales';

  public ngOnInit() {
    ClassManager.registerClass('Sale', Sale);
    ClassManager.registerClass('Price', Price);

    HomepageViewComponent.registerGroups({
      sales: {
        name: 'Ventes',
        backColor: 'rgb(226, 107, 10)',
        textColor: 'white',
        permission: 'home_groupe_ventes',
        children: [
          {
            name: 'Demande de création de compte client',
            permission: 'home_bouton_ventes_creerclient',
            component: CustomerViewComponent,
            getArgs: () => {
              let customer = new Customer();
              customer.valid = false;
              return [customer];
            },
          },
          {
            name: 'Créer un dossier commercial',
            permission: 'home_bouton_ventes_creerdossier',
            component: SaleViewComponent,
          },
          {
            name: 'Dossiers commerciaux',
            permission: 'home_bouton_ventes_dossiers',
            component: SalesListViewComponent,
          },

          ...(config.interop.lists.customers
            ? [
                {
                  name: 'Dossiers commerciaux 🏎️',
                  component: InteropViewComponent,
                  permission: 'home_bouton_ventes_dossiers',
                  getArgs: () => ['SalesList'],
                },
              ]
            : []),
          {
            name: 'Historique de vente client',
            permission: 'home_bouton_historique_client',
            component: CustomerHistoryViewComponent,
          },
        ],
      },
    });

    return super.ngOnInit();
  }
}
