import { DataGridColumn } from "./DataGridColumn.class";
import { FilterActions, FilterOperand, FilterOperator, FilterOperator as FilterOperatorDefinition, FilterOperators, IFilter, SortActions } from "./IFilter";


export class NumberFilter implements IFilter<number> {
    
    column: DataGridColumn;

    operator1?: FilterOperator<number>;
    public get value1(): number|undefined {
        return parseFloat(this.value1String);
    };
    value1String: string = undefined;

    operand: FilterOperand;

    operator2?: FilterOperator<number>;
    public get value2(): number|undefined {
        return parseFloat(this.value2String);
    };
    value2String: string = undefined;

    availableValues: number[] = [];
    formattedValues: string[] = [];
    selectedValues: number[] = [];

    isActive(): boolean {
        return (!!this.operator1 || !!this.operator2 || (this.selectedValues && this.selectedValues.length > 0));
    }

    check(value: number): boolean {
        if (this.selectedValues.length) return this.selectedValues.includes(value);
        let b1: boolean|null = null, b2: boolean|null = null;
        if (!isNaN(this.value1) && !!this.operator1) b1 = this.operator1.func(this.value1, value);
        if (!isNaN(this.value2) && !!this.operator2) b2 = this.operator2.func(this.value2, value);
        if (b1 !== null && b2 !== null) {
            if (this.operand == 0) return b1 && b2;
            else return b1 || b2;
        }
        else if (b1 !== null) return b1;
        else if (b2 !== null) return b2;
        else return true;
    }

    clear(): void {
        this.value1String = undefined;
        this.value2String = undefined;
        this.operator1 = undefined;
        this.operator2 = undefined;
        this.operand = 0;
        this.selectedValues = [];
    }

    operators: FilterOperators<number> = {
        Equal: { displayName: "Égal à", func: (filterValue, itemValue) => filterValue == itemValue },
        NotEqual: { displayName: "Différent de", func: (filterValue, itemValue) => filterValue != itemValue },
        LessThan: { displayName: "Inférieur à", func: (filterValue, itemValue) => itemValue < filterValue },
        LessThanOrEqual: { displayName: "Inférieur ou égal à", func: (filterValue, itemValue) => itemValue <= filterValue },
        GreaterThanOrEqual: { displayName: "Supérieur à", func: (filterValue, itemValue) => itemValue >= filterValue },
        GreaterThan: { displayName: "Supérieur ou égal à", func: (filterValue, itemValue) => itemValue > filterValue },
    };
    operatorsList: FilterOperator<number>[] = [
        this.operators.Equal,
        this.operators.NotEqual,
        this.operators.LessThan,
        this.operators.LessThanOrEqual,
        this.operators.GreaterThanOrEqual,
        this.operators.GreaterThan,
    ];


    sortActions: SortActions<number> = [
        { displayName: "Du plus petit au plus grand", img: 'sort-alpha-asc', func: (value1: number, value2: number) => value1 - value2 },
        { displayName: "Du plus grand au plus petit", img: 'sort-alpha-desc', func: (value1: number, value2: number) => value2 - value1 }
    ];

    filterActionsName?: string = "Filtres numériques";
    filterActions: FilterActions<number> = [
        { displayName: "Est égal à ...", operator1: this.operators.Equal },
        { displayName: "Est différent de ...", operator1: this.operators.NotEqual },
        null,
        { displayName: "Inférieur à ...", operator1: this.operators.LessThan },
        { displayName: "Inférieur ou égal à ...", operator1: this.operators.LessThanOrEqual },
        { displayName: "Supérieur ou égal à ...", operator1: this.operators.GreaterThanOrEqual },
        { displayName: "Supérieur à ...", operator1: this.operators.GreaterThan },
        { displayName: "Entre ...", operator1: this.operators.GreaterThanOrEqual, operand: FilterOperand.And, operator2: this.operators.LessThanOrEqual },
        null,
        { displayName: "Filtre personnalisé ..." },
    ];
    
}
