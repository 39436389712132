import { ObjectDefinition } from "src/app/classes/objects/ObjectDefinition.class";
import { ServerApi } from "src/app/classes/api/ServerApi.class";
import { Invoice } from "./Invoice.class";
import { NumberUtil } from "../../utils/classes/NumberUtil.class";
import { PaymentStatus } from "../../payments/classes/PaymentStatus.class";
import { ObjectModel3 } from "src/app/classes/objects/ObjectModel3.class";
import { User } from "src/app/classes/credentials/User.class";
import { Accounting } from "../../other-data/classes/Accounting.class";
import { CurrenciesService } from "src/app/services/currencies/currencies.service";
import {CredentialsService} from "../../../services/credentials/credentials.service";

export class Reimbursement extends ObjectModel3
{
    public accounting: Accounting = null;

    public date: string = null;
    public number: string = null;

    public invoice: Invoice = null;

    public designation: string = null;
    public quantity: number = null;
    public unit: string = null;
    public sell_price: number = null;
    public vat: number = null;
    public merchant_fees: number = 25;
    public internal_fees: number = 0;
    public financial: number = 0;

    public deadline: string = null;
    public expiration: string = null;
    public remarks: string = null;

    public user: User = null;

    public payment_date: string = null;
    public payment_amount: number = null;
    public payment_status: PaymentStatus = null;
    public payment_remarks: string = null;
    public payment_archived: number = 0;

    public get order() { return this.invoice ? this.invoice.order : null; };
    public get orderNumber() { return this.order ? this.order.number : null; };
    public get customer() { return this.invoice ? this.invoice.customer : null };
    public get supplier() { return this.invoice ? this.invoice.supplier : null };
    public get merchant() { return this.invoice ? this.invoice.merchant : null };
    public get article() { return this.invoice ? this.invoice.article : null };

    public get buy_currency() { return this.invoice ? this.invoice.buy_currency : null; }
    public get buy_xrate() { return this.invoice ? this.invoice.buy_xrate : null; }
    public get sell_currency() { return this.invoice ? this.invoice.sell_currency : null; }
    public get sell_xrate() { return this.invoice ? this.invoice.sell_xrate : null; }

    constructor() {
        super(Reimbursement);
        this.user = CredentialsService.loggedUser;
    }

    public getNumberPrefix()
    {
        return '02/' +
               ("00" + (new Date().getFullYear() % 100)).slice(-2) +
               (this.order.deliveries > 1 ? '1' : '0') +
               (this.accounting ? this.accounting.number : '0');
    }
    public getNextNumber()
    {
        return ServerApi.callModule('reimbursements', 'nextNumber', { 'number_prefix': this.getNumberPrefix() });
    }
    public generateNumber(number: string)
    {
        return this.getNumberPrefix() + ' ' +
               ("0000" + number).slice(-4) + '/' +
               (this.customer ? this.customer.number : 'BEL0000') + '/' +
               (this.article && this.article.nomenclature && this.article.nomenclature.name ?
                this.article.nomenclature.name.replace(/[^A-Za-z0-9]/g, '').substr(0,3) : '000');
    }
    public get shortNumber()
    {
        return this.number.replace(/(\/[A-Za-z]{2,}[0-9]{4,}\/[0-9A-Za-z]{3}(\-[0-9]+)?)$/, '');
    }

    public get quantityAndUnit()
    {
        let arr: string[] = [];
        if (this.quantity != null) arr.push(NumberUtil.formatNumber(this.quantity, null, '.'));
        if (this.unit != null) arr.push(this.unit);
        return arr.join(' ');
    }
    public get totalAmount()
    {
        // console.log('quantity:', this.quantity, ', sell_price:', this.sell_price, ', total=', this.quantity*this.sell_price);
        if (this.quantity != null && this.sell_price != null) return this.quantity * this.sell_price;
        else return 0;
    }
    public get totalAmountWithDiscount() { return this.totalAmount; }
    public get vatAmount()
    {
        if (this.quantity != null && this.sell_price != null) return this.quantity * this.sell_price * ((isNaN(this.vat) ? 21 : this.vat)/100);
        else return 0;
    }
    public get vatAmountWithDiscount() { return this.vatAmount; }
    public get totalWithTax()
    {
        return this.totalAmount + this.vatAmount;
    }
    public get totalWithTaxWithTaxDiscount()
    {
        return this.totalAmount + this.vatAmountWithDiscount;
    }
    public get totalWithTaxWithFullDiscount()
    {
        return this.totalAmountWithDiscount + this.vatAmountWithDiscount;
    }

    public get payment_result(): number { return this.payment_status && this.payment_status.ended == 1 ? (this.payment_amount - this.totalWithTax) : 0; }
    public get converted_payment_amount() { return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.payment_amount); }
    public set converted_payment_amount(value: number) { this.payment_amount = CurrenciesService.currencyToEuro(this.sell_xrate || 1, value); }
    public get converted_payment_result() { return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.payment_result); }


    public get converted_totalAmount() { return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.totalAmount); }
    public get converted_vatAmount() { return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.vatAmount); }
    public get converted_totalWithTax() { return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.totalWithTax); }

    public get converted_totalAmountWithDiscount() { return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.totalAmountWithDiscount); }
    public get converted_vatAmountWithDiscount() { return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.vatAmountWithDiscount); }
    public get converted_totalWithTaxWithTaxDiscount() { return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.totalWithTaxWithTaxDiscount); }
    public get converted_totalWithTaxWithFullDiscount() { return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.totalWithTaxWithFullDiscount); }


    public get buy_price() { return this.order ? this.order.buy_price : null; }
    public get unitBuyPrice() { return this.order ? this.order.unitBuyPrice : null; }
    public get totalBuyPrice() { return this.unitBuyPrice ? (this.quantity * this.unitBuyPrice) : null; }
    public get unitCostPrice() { return this.order ? this.order.unitCostPrice : null; }
    public get totalCostPrice() { return this.unitBuyPrice ? (this.quantity * this.unitCostPrice) : null; }
    public get totalSellPrice() { return this.totalAmount; };

    public get converted_buy_price() { return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.buy_price); }
    public get converted_unitBuyPrice() { return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.unitBuyPrice); }
    public get converted_totalBuyPrice() { return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.totalBuyPrice); }
    public get converted_unitCostPrice() { return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.unitCostPrice); }
    public get converted_totalCostPrice() { return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.totalCostPrice); }
    public get converted_sell_price() { return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.sell_price); }
    public set converted_sell_price(value: number) { this.sell_price = CurrenciesService.currencyToEuro(this.sell_xrate || 1, value); }
    public get converted_totalSellPrice() { return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.totalSellPrice); }

    public get converted_internal_fees() { return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.internal_fees); }
    public set converted_internal_fees(value: number) { this.internal_fees = CurrenciesService.currencyToEuro(this.sell_xrate || 1, value); }

    public get rawMargin() { return this.totalSellPrice + this.totalBuyPrice; }
    public get netMargin() { return this.totalSellPrice + this.totalCostPrice; }
    public get rawMarginPercentage() { return this.total_buyPrice == 0 ? 0 : (this.rawMargin / -this.total_buyPrice) * 100; }
    public get netMarginPercentage() { return this.total_costPrice == 0 ? 0 : (this.netMargin / -this.total_costPrice) * 100; }


    public get merchantMargin() { return (this.payment_status != null ? (this.payment_status.ended * this.payment_status.paid) : 0) *
                                         (this.netMargin * ((this.merchant && this.merchant.margin ? this.merchant.margin : 0)/100)); }





    public get unit_buyPrice() { return this.financial ? 0 : (this.order ? -this.order.unit_buyPrice : 0); }
    public get unit_costPrice() { return this.financial ? 0 : (this.order ? -this.order.unit_costPrice : 0); }
    public get unit_supplierFees() { return this.financial ? 0 : (this.order ? -this.order.unit_supplierFees : 0); }
    public get unit_externalFees() { return 0; }//this.order ? this.order.unit_externalFees : 0; }
    public get unit_internalFees() { return this.quantity ? this.total_internalFees / this.quantity : 0; }//this.order ? this.order.unit_internalFees : 0; }
    public get unit_sellPrice() { return this.sell_price; }
    public get unit_rawMargin() { return this.total_rawMargin / this.quantity; }
    public get unit_netMargin() { return this.total_netMargin / this.quantity; }

    public get total_buyPrice() { return this.unit_buyPrice * this.quantity; }
    public get total_costPrice() { return this.unit_costPrice * this.quantity; }
    public get total_supplierFees() { return this.unit_supplierFees * this.quantity; }
    public get total_externalFees() { return 0; } //this.external_fees || (this.unit_externalFees * this.quantity); }
    public get total_internalFees() { return this.internal_fees || 0; } //this.internal_fees || (this.unit_internalFees * this.quantity); }
    public get total_sellPrice() { return this.unit_sellPrice * this.quantity; }
    public get total_rawMargin() { return this.total_sellPrice - this.total_buyPrice; }
    public get total_netMargin() { return this.total_rawMargin - this.total_externalFees - this.total_internalFees; }

    public get total_rawMarginPerc() { return this.total_buyPrice == 0 ? 0 : (this.total_rawMargin / -this.total_buyPrice) * 100; }
    public get total_netMarginPerc() { return this.total_costPrice == 0 ? 0 : (this.total_netMargin / -this.total_costPrice) * 100; }



    public get converted_unit_buyPrice() { return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.unit_buyPrice) }
    public get converted_unit_costPrice() { return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.unit_costPrice) }
    public get converted_unit_supplierFees() { return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.unit_supplierFees) }
    public get converted_unit_externalFees() { return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.unit_externalFees) }
    public get converted_unit_internalFees() { return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.unit_internalFees) }
    public get converted_unit_sellPrice() { return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.unit_sellPrice) }

    public get converted_total_buyPrice() { return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.total_buyPrice) }
    public get converted_total_costPrice() { return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.total_costPrice) }
    public get converted_total_supplierFees() { return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.total_supplierFees) }
    public get converted_total_externalFees() { return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.total_externalFees) }
    public get converted_total_internalFees() { return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.total_internalFees) }
    public get converted_total_sellPrice() { return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.total_sellPrice) }



    /* ObjectModel FUNCTIONS */

    public static definition: ObjectDefinition = {
        trashDelete: true,
        database: {
            table: 'reimbursements',
            id: 'id',
			db_id: 'db_id'
        },
        values: {
            'date': { type: 'string' },
            'number': { type: 'string' },

            'designation': { type: 'string' },
            'quantity': { type: 'number' },
            'unit': { type: 'string' },
            'sell_price': { type: 'number' },
            'vat': { type: 'number' },
            'merchant_fees': { type: 'number' },
            'internal_fees': { type: 'number' },
            'financial': { type: 'number' },

            'deadline': { type: 'string' },
            'expiration': { type: 'string' },
            'remarks': { type: 'string' },

            'payment_date': { type: 'string' },
            'payment_amount': { type: 'number' },
            'payment_remarks': { type: 'string' },
            'payment_archived': { type: 'number' },
        },
        children: {
            'accounting': { type: 'Accounting', clone: false, save: false, delete: false },
            'invoice': { type: 'Invoice', clone: false, save: false, delete: false },
            'payment_status': { type: 'PaymentStatus', clone: false, save: false, delete: false },
            'user': { type: 'User', clone: false, save: false, delete: false }
        },
        links: {}
    };

}
