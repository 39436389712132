import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { ApiService } from '../../../../services/api/api.service';
import { ArticleViewComponent } from '../../../articles/views/article-view/article-view.component';
import { CredentialsService } from '../../../../services/credentials/credentials.service';
import { CustomerViewComponent } from '../../../customers/views/customer-view/customer-view.component';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from 'src/app/components/views/views.component';
import { config } from '../../../../classes/config';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage-view.component.html',
  styleUrls: ['./homepage-view.component.scss'],
})
export class HomepageViewComponent extends AccessViewComponent {
  public toolbar: Toolbar = {
    class: 'toolbar-big toolbar-transparent toolbar-nocontent',
    viewTitle: '',
    data: this,
    elements: [{ type: 'spacing' }],
  };
  public logoAlt = config.companyName;

  @ViewChild('mydiv') mydiv;
  @ViewChild('reactRoot') reactRoot: ElementRef;
  @ViewChild('reactContainer1') reactContainer1: ElementRef;
  @ViewChild('reactContainer2') reactContainer2: ElementRef;
  @ViewChild('reactContainer3') reactContainer3: ElementRef;

  static registredGroups = {};
  homepageGroups = null;

  public static registerGroups(groups) {
    for (const groupKey in groups) {
      if (groups.hasOwnProperty(groupKey)) {
        const group = groups[groupKey];
        if (!HomepageViewComponent.registredGroups[groupKey]) {
          HomepageViewComponent.registredGroups[groupKey] = group;
        } else if (Array.isArray(group.children)) {
          if (!HomepageViewComponent.registredGroups[groupKey].children) {
            HomepageViewComponent.registredGroups[groupKey].children = [];
          }
          group.children.forEach((child) => {
            HomepageViewComponent.registredGroups[groupKey].children.push(child);
          });
        }
      }
    }
    //HomepageViewComponent.registredGroups = UtilsModule.mergeDeep(HomepageViewComponent.registredGroups, groups);
  }

  onActivate() {
    // console.log('syncing from homepage!!');
    // ApiService.sync();
  }

  ngOnInit() {
    var arr = [];
    var groups = HomepageViewComponent.registredGroups;
    for (const key in groups) {
      if (groups.hasOwnProperty(key)) {
        const element = groups[key];
        arr.push(element);
      }
    }
    this.homepageGroups = arr;
  }

  ngAfterViewInit(): void {
    this.waitForInterop();
  }

  waitForInterop = () => {
    if (!window.reactInterop) {
      console.log('interop not found, waiting...');
      return setTimeout(this.waitForInterop, 1000);
    }
    console.log('interop found, initializing...');
    const interopConfig: InteropConfig = {
      apiUrl: config.apiUrl,
      baseUrl: config.baseUrl,
    };
    window.reactInterop.initInterop(
      this.reactRoot.nativeElement,
      {
        viewsComponent: ViewsComponent,
        viewsTypes: {
          ArticleViewComponent,
          CustomerViewComponent,
        },
      },
      interopConfig
    );
  };

  openView(child) {
    let component = child.component;
    let args = child.getArgs && typeof child.getArgs === 'function' ? child.getArgs() : [];
    ViewsComponent.openView(component, ...args);
  }

  private _filteredHomepageGroups: any = null;
  public get filteredHomepageGroups() {
    if (!this._filteredHomepageGroups) {
      let groups: any[] = [];
      for (let groupName in this.homepageGroups) {
        let group = this.homepageGroups[groupName];
        if (!group.permission || CredentialsService.isUserAllowed(group.permission)) {
          let _group = {
            name: group.name,
            backColor: group.backColor,
            textColor: group.textColor,
            height: group.height,
            fontSize: group.fontSize,
            lineHeight: group.lineHeight,
            children: [],
          };
          for (let i = 0; i < group.children.length; ++i) {
            let child = group.children[i];
            if (!child.permission || CredentialsService.isUserAllowed(child.permission)) {
              _group.children.push(child);
            }
          }
          if (_group.children.length > 0) groups.push(_group);
        }
      }
      this._filteredHomepageGroups = groups;
    }
    return this._filteredHomepageGroups;
  }
}
