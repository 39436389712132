import { Component, OnInit, AfterViewInit, ComponentRef, ChangeDetectorRef } from '@angular/core';
import { Toolbar } from '../toolbar/classes/Toolbar.class';
import { ViewsComponent } from './views.component';
import {LoginViewComponent} from "../../modules/login/views/login-view/login-view.component";
import {ViewBaseComponent} from "./view-base.component";
import {CredentialsService} from "../../services/credentials/credentials.service";

@Component({
  selector: 'app-access-view',
  templateUrl: './view-base.component.html',
})
export class AccessViewComponent extends ViewBaseComponent {

    public get writeAccess()
    {
        let permission: string = this.permission;
        if (!this.permission) permission = this.constructor.name + '_write_access';
        return CredentialsService.isUserAllowed(permission);
        //return false;
    }

}
