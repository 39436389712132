import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleBase } from '../module-base.class';
import { ClassManager } from 'src/app/classes/objects/ClassManager.class';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { ReportViewComponent } from './views/report-view/report-view.component';
import { ReportSettingsViewComponent } from './views/report-settings-view/report-settings-view.component';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components/components.module';
import { ReportComponent } from './components/report/report.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule
  ],
    declarations: [
        ReportViewComponent,
        ReportSettingsViewComponent,
        ReportComponent
    ],
    exports: [
        ReportViewComponent,
        ReportSettingsViewComponent
    ],
    entryComponents: [
        ReportViewComponent,
        ReportSettingsViewComponent
    ]
})
export class ReportsModule extends ModuleBase {

    public static moduleName = 'reports';

    public ngOnInit()
    {
        // HomepageViewComponent.registerGroups({
        //     'reports': {
        //         'name': "Consolidations",
        //         'permission': 'homepage_reports_group',
        //         'children': [
        //             // {
        //             //     'name': "",
        //             //     'component': SuppliersListViewComponent
        //             // }
        //         ]
        //     }
        // },);

        return super.ngOnInit();
    }

}
