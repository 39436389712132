import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap'
import { ComponentsModule } from '../../components/components.module';
import { ClassManager } from '../../classes/objects/ClassManager.class';
import { Address } from './classes/Address.class';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    ComponentsModule
  ],
  declarations: [
  ],
  exports: [
  ],
  entryComponents: [
  ]
})
export class AddressesModule implements OnInit {

    ngOnInit() {
        ClassManager.registerClass('Address', Address);
    }

}
