import * as am4charts from '@amcharts/amcharts4/charts';

import { ApiService } from 'src/app/services/api/api.service';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { ObjectUtil } from '../../utils/classes/ObjectUtil.class';
import { ReportCell } from '../../reports/classes/ReportCell.class';
import { ReportHeaderRow } from '../../reports/classes/ReportHeaderRow.class';
import { ReportModel } from '../../reports/classes/ReportModel.class';
import { ReportRow } from '../../reports/classes/ReportRow.class';
import { ReportSetting } from '../../reports/classes/ReportSetting.class';
import { StatsModule } from '../stats.module';

export class MonthlyNetMarginReportModel extends ReportModel {
  public title: string = 'MARGE NETTE TOTALE DÉGAGÉE';
  public settings: ReportSetting[] = [
    { name: 'year_start', title: 'Année de début', type: 'number', value: new Date().getFullYear() },
    { name: 'year_end', title: 'Année de fin', type: 'number', value: new Date().getFullYear() },
  ];

  public tableStyle: any = {
    border: '2px solid black',
  };

  public headerRows: ReportHeaderRow[] = [{ cells: [] }];
  public rows: ReportRow[] = [];
  public points: any[] = [];
  public tri_points: any[] = [];

  public static titleOrTotal_CellStyle: any = {
    'background-color': 'rgb(255,204,0)',
    color: 'black',
    'font-weight': 'bold',
    'border-top': '2px solid black',
    'border-bottom': '2px solid black',
    'text-align': 'center',
  };
  public static subTotal_CellStyle: any = {
    'background-color': 'rgb(255,192,0)',
    color: 'black',
    'font-weight': 'bold',
    'text-align': 'center',
  };
  public static body_CellStyle: any = {
    // 'background-color': 'rgb(217, 217, 217)',
    color: 'black',
  };
  public static bodyTotal_CellStyle: any = {
    'background-color': 'rgb(217, 217, 217)',
    color: 'black',
    'font-weight': 'bold',
  };
  public static month_CellStyle: any = {
    'background-color': 'rgb(255,255,0)',
    color: 'black',
    'font-weight': 'bold',
    'text-align': 'center',
  };

  public static generate(settings: ReportSetting[] = null) {
    let model: MonthlyNetMarginReportModel = new MonthlyNetMarginReportModel();
    if (settings) model.settings = settings;
    model.regenerate();
    return model;
  }

  public regenerate() {
    return LoadingPromise.create<any>((resolve, reject) => {
      let month_names: string[] = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Aout',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ];
      let tri_month_names: string[] = ['1er trimestre', '2ème trimestre', '3ème trimestre', '4ème trimestre'];

      this.headerRows = [
        {
          cells: [
            {
              value: 'MARGE NETTE TOTALE DÉGAGÉE : MENSUELLE ET TRIMESTRIELLE (Ventes - Achats - Charges)',
              colSpan: 9,
              style: MonthlyNetMarginReportModel.titleOrTotal_CellStyle,
            },
          ],
        },
      ];

      ApiService.callModule('reports', 'report', {
        query: 'monthly_margins',
        settings: { year_start: this.getSetting('year_start').value, year_end: this.getSetting('year_end').value },
      }).then(
        (result2: any) => {
          console.log('MONTHLY MARGINS RESULT:', result2);
          // (results: any[]) => {
          let buy_month: number = 0;
          let buy_year: number = 0;
          let buys: number[] = [];
          let sell_month: number = 0;
          let sell_year: number = 0;
          let sells: number[] = [];
          let charge_month: number = 0;
          let charge_year: number = 0;
          let charges: number[] = [];
          let margin_month: number = 0;
          let margin_year: number = 0;
          let margins: number[] = [];
          let month_count: number = 0;
          for (let i = 0; i < result2.details.length; ++i) {
            let month_str: any = result2.details[i]['month'];
            let month: number = parseFloat(month_str);
            let buy_str: any = result2.details[i]['total_buy'];
            let buy: number = parseFloat(buy_str);
            let sell_str: any = result2.details[i]['total_sell'];
            let sell: number = parseFloat(sell_str);
            let charge_str: any = result2.details[i]['total_charge'];
            let charge: number = parseFloat(charge_str);
            let margin_str: any = result2.details[i]['net_margin'];
            let margin: number = parseFloat(margin_str);
            if (month >= 1) {
              if (buy && buy != 0) {
                if (!buys[month - 1]) buys[month - 1] = 0;
                buys[month - 1] += buy;
              }
              if (sell && sell != 0) {
                if (!sells[month - 1]) sells[month - 1] = 0;
                sells[month - 1] += sell;
              }
              if (charge && charge != 0) {
                if (!charges[month - 1]) charges[month - 1] = 0;
                charges[month - 1] += charge;
              }
              if (margin && margin != 0) {
                if (!margins[month - 1]) margins[month - 1] = 0;
                margins[month - 1] += margin;
              }
            }
          }

          this.rows = [
            {
              cells: [
                { value: this.getSetting('year_start').value, style: MonthlyNetMarginReportModel.subTotal_CellStyle },
                { value: 'ACHATS', style: MonthlyNetMarginReportModel.month_CellStyle },
                { value: 'VENTES', style: MonthlyNetMarginReportModel.month_CellStyle },
                { value: 'CHARGES', style: MonthlyNetMarginReportModel.month_CellStyle },
                { value: 'MARGE NETTE (€)', style: MonthlyNetMarginReportModel.month_CellStyle },
                { value: '% ANNEE', style: MonthlyNetMarginReportModel.month_CellStyle },
                { value: 'RANG', style: MonthlyNetMarginReportModel.month_CellStyle },
                { value: 'MARGE NETTE (%)', style: MonthlyNetMarginReportModel.month_CellStyle },
                { value: 'RANG', style: MonthlyNetMarginReportModel.month_CellStyle },
              ],
            },
          ];

          let month_data: any[] = [];
          for (let i = 0; i < 12; ++i) {
            try {
              let buy: number = buys[i] || 0;
              let sell: number = sells[i] || 0;
              let charge: number = charges[i] || 0;
              let margin: number = margins[i] || 0;
              let perc: number = buy == 0 ? 0 : margin / buy;
              month_data[i] = {
                buy: buy,
                sell: sell,
                charge: charge,
                margin: margin,
                perc: perc,
                margin_rank: 0,
                year_perc: 0,
                year_rank: 0,
                month: i,
              };
            } catch {
              continue;
            }
          }

          month_data.sort((a: any, b: any) => {
            return b.margin - a.margin;
          });
          for (let i = 0; i < 12; ++i) {
            month_data[i].year_rank = month_data[i].margin ? i + 1 : 0;
            margin_year += month_data[i].margin;
          }
          for (let i = 0; i < 12; ++i) month_data[i].year_perc = month_data[i].margin / margin_year;
          month_data.sort((a: any, b: any) => {
            return b.perc - a.perc;
          });
          for (let i = 0; i < 12; ++i) month_data[i].margin_rank = month_data[i].margin ? i + 1 : 0;
          month_data.sort((a: any, b: any) => {
            return a.month - b.month;
          });

          for (let i = 0; i < 12; ++i) {
            try {
              let buy: number = buys[i] || 0;
              let sell: number = sells[i] || 0;
              let charge: number = charges[i] || 0;
              let margin: number = margins[i] || 0;
              let perc: number = buy > 0 ? (100 * margin) / buy : 0;
              let year_perc: number = month_data[i].year_perc || 0;
              let year_rank: number = month_data[i].year_rank || 0;
              let margin_rank: number = month_data[i].margin_rank || 0;
              this.rows.push({
                cells: [
                  {
                    value: [
                      'Janvier',
                      'Février',
                      'Mars',
                      'Avril',
                      'Mai',
                      'Juin',
                      'Juillet',
                      'Aout',
                      'Septembre',
                      'Octobre',
                      'Novembre',
                      'Décembre',
                    ][i],
                    style: MonthlyNetMarginReportModel.month_CellStyle,
                  },
                  {
                    value: buy && buy != 0 ? NumberUtil.formatMoney(buy, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MonthlyNetMarginReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                  {
                    value: sell && sell != 0 ? NumberUtil.formatMoney(sell, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MonthlyNetMarginReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                  {
                    value: charge && charge != 0 ? NumberUtil.formatMoney(charge, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MonthlyNetMarginReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                  {
                    value: margin && margin != 0 ? NumberUtil.formatMoney(margin, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MonthlyNetMarginReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                  {
                    value: year_perc && year_perc != 0 ? NumberUtil.formatMoney(100 * year_perc, '%', 2, '.') : '',
                    style: ObjectUtil.merge(MonthlyNetMarginReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                  {
                    value: year_rank && year_rank != 0 ? NumberUtil.formatNumber(year_rank, 0, '.') : '',
                    style: ObjectUtil.merge(MonthlyNetMarginReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                  {
                    value: perc && perc != 0 ? NumberUtil.formatMoney(perc, '%', 2, '.') : '',
                    style: ObjectUtil.merge(MonthlyNetMarginReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                  {
                    value: margin_rank && margin_rank != 0 ? NumberUtil.formatNumber(year_rank, 0, '.') : '',
                    style: ObjectUtil.merge(MonthlyNetMarginReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  },
                  // { value: margin && margin != 0 ? NumberUtil.formatMoney(margin, '€', 2, '.') : '', style: ObjectUtil.merge(MonthlyNetMarginReportModel.body_CellStyle, ReportCell.moneyCellStyle) },
                  // { value: perc && perc != 0 ? NumberUtil.formatMoney(perc, '%', 2, '.') : '', style: ObjectUtil.merge(MonthlyNetMarginReportModel.body_CellStyle, ReportCell.moneyCellStyle) },
                ],
              });
              this.points.push({ name: month_names[i], month: i });
              this.points[i].buy = buy;
              this.points[i].sell = sell;
              this.points[i].charges = charge;
              this.points[i].margin = margin;
              this.points[i].perc = perc;

              if (buy != 0) ++month_count;
              buy_month += buy;
              sell_month += sell;
              charge_month += charge;
              margin_month += margin;
              buy_year += buy;
              sell_year += sell;
              charge_year += charge;
              //margin_year += margin;
              if ((i + 1) % 3 == 0) {
                let j: number = Math.floor(i / 3);
                let perc_month: number = buy_month > 0 ? (100 * margin_month) / buy_month : 0;
                this.rows.push({
                  cells: [
                    {
                      value: ['1er trimestre', '2ème trimestre', '3ème trimestre', '4ème trimestre'][Math.floor(i / 3)],
                      style: MonthlyNetMarginReportModel.subTotal_CellStyle,
                    },
                    {
                      value: buy_month && buy_month != 0 ? NumberUtil.formatMoney(buy_month, '€', 2, '.') : '',
                      style: ObjectUtil.merge(
                        MonthlyNetMarginReportModel.subTotal_CellStyle,
                        ReportCell.moneyCellStyle
                      ),
                    },
                    {
                      value: sell_month && sell_month != 0 ? NumberUtil.formatMoney(sell_month, '€', 2, '.') : '',
                      style: ObjectUtil.merge(
                        MonthlyNetMarginReportModel.subTotal_CellStyle,
                        ReportCell.moneyCellStyle
                      ),
                    },
                    {
                      value: charge_month && buy_month != 0 ? NumberUtil.formatMoney(charge_month, '€', 2, '.') : '',
                      style: ObjectUtil.merge(
                        MonthlyNetMarginReportModel.subTotal_CellStyle,
                        ReportCell.moneyCellStyle
                      ),
                    },
                    {
                      value: margin_month && margin_month != 0 ? NumberUtil.formatMoney(margin_month, '€', 2, '.') : '',
                      style: ObjectUtil.merge(
                        MonthlyNetMarginReportModel.subTotal_CellStyle,
                        ReportCell.moneyCellStyle
                      ),
                    },
                    {
                      value: '',
                      style: ObjectUtil.merge(
                        MonthlyNetMarginReportModel.subTotal_CellStyle,
                        ReportCell.moneyCellStyle
                      ),
                    },
                    {
                      value: '',
                      style: ObjectUtil.merge(
                        MonthlyNetMarginReportModel.subTotal_CellStyle,
                        ReportCell.moneyCellStyle
                      ),
                    },
                    {
                      value: perc_month && perc_month != 0 ? NumberUtil.formatMoney(perc_month, '%', 2, '.') : '',
                      style: ObjectUtil.merge(
                        MonthlyNetMarginReportModel.subTotal_CellStyle,
                        ReportCell.moneyCellStyle
                      ),
                    },
                    {
                      value: '',
                      style: ObjectUtil.merge(
                        MonthlyNetMarginReportModel.subTotal_CellStyle,
                        ReportCell.moneyCellStyle
                      ),
                    },
                  ],
                });
                this.tri_points.push({ name: tri_month_names[j], month: j });
                this.tri_points[j].buy = buy_month;
                this.tri_points[j].sell = sell_month;
                this.tri_points[j].charges = charge_month;
                this.tri_points[j].margin = margin_month;
                this.tri_points[j].perc = perc_month;
                buy_month = 0;
                sell_month = 0;
                charge_month = 0;
                margin_month = 0;
              }
            } catch {
              continue;
            }
          }

          let perc_year: number = buy_year > 0 ? (100 * margin_year) / buy_year : 0;
          this.rows.push({
            cells: [
              { value: 'TOTAUX', style: MonthlyNetMarginReportModel.titleOrTotal_CellStyle },
              {
                value: buy_year && buy_year != 0 ? NumberUtil.formatMoney(buy_year, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlyNetMarginReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: sell_year && sell_year != 0 ? NumberUtil.formatMoney(sell_year, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlyNetMarginReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: charge_year && charge_year != 0 ? NumberUtil.formatMoney(charge_year, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlyNetMarginReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: margin_year && margin_year != 0 ? NumberUtil.formatMoney(margin_year, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlyNetMarginReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: '',
                style: ObjectUtil.merge(MonthlyNetMarginReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: '',
                style: ObjectUtil.merge(MonthlyNetMarginReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: perc_year && perc_year != 0 ? NumberUtil.formatMoney(perc_year, '%', 2, '.') : '',
                style: ObjectUtil.merge(MonthlyNetMarginReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: '',
                style: ObjectUtil.merge(MonthlyNetMarginReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              // { value: margin_year && margin_year != 0 ? NumberUtil.formatMoney(margin_year, '€', 2, '.') : '', style: ObjectUtil.merge(MonthlyNetMarginReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle) },
              // { value: perc_year && perc_year != 0 ? NumberUtil.formatMoney(perc_year, '%', 2, '.') : '', style: ObjectUtil.merge(MonthlyNetMarginReportModel.titleOrTotal_CellStyle, ReportCell.moneyCellStyle) },
            ],
          });

          let buy_avg: number = month_count > 0 ? buy_year / month_count : 0;
          let sell_avg: number = month_count > 0 ? sell_year / month_count : 0;
          let charge_avg: number = month_count > 0 ? charge_year / month_count : 0;
          let margin_avg: number = month_count > 0 ? margin_year / month_count : 0;
          let perc_avg: number = buy_avg > 0 ? (100 * margin_avg) / buy_avg : 0;
          this.rows.push({
            cells: [
              { value: 'Moy. mens.', style: MonthlyNetMarginReportModel.subTotal_CellStyle },
              {
                value: buy_avg && buy_avg != 0 ? NumberUtil.formatMoney(buy_avg, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlyNetMarginReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: sell_avg && sell_avg != 0 ? NumberUtil.formatMoney(sell_avg, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlyNetMarginReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: charge_avg && charge_avg != 0 ? NumberUtil.formatMoney(charge_avg, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlyNetMarginReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: margin_avg && margin_avg != 0 ? NumberUtil.formatMoney(margin_avg, '€', 2, '.') : '',
                style: ObjectUtil.merge(MonthlyNetMarginReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: '',
                style: ObjectUtil.merge(MonthlyNetMarginReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: '',
                style: ObjectUtil.merge(MonthlyNetMarginReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: perc_avg && perc_avg != 0 ? NumberUtil.formatMoney(perc_avg, '%', 2, '.') : '',
                style: ObjectUtil.merge(MonthlyNetMarginReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: '',
                style: ObjectUtil.merge(MonthlyNetMarginReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              // { value: margin_avg && margin_avg != 0 ? NumberUtil.formatMoney(margin_avg, '€', 2, '.') : '', style: ObjectUtil.merge(MonthlyNetMarginReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle) },
              // { value: perc_avg && perc_avg != 0 ? NumberUtil.formatMoney(perc_avg, '%', 2, '.') : '', style: ObjectUtil.merge(MonthlyNetMarginReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle) },
            ],
          });

          this.createEmptyCells();

          this.charts = [];

          this.charts.push({
            type: am4charts.XYChart,
            inline: false,
            colors: StatsModule.chartsColor,
            titles: [
              { text: 'Marge mensuelle nette dégagée (Ventes - Achats - Charges)', fontSize: 25, marginBottom: 30 },
            ],
            series: [
              {
                type: 'ColumnSeries',
                name: 'Ventes',
                dataFields: { valueY: 'sell', categoryX: 'name' },
                yAxis: 'numbers',
              },
              {
                type: 'ColumnSeries',
                name: 'Achats',
                dataFields: { valueY: 'buy', categoryX: 'name' },
                yAxis: 'numbers',
              },
              {
                type: 'ColumnSeries',
                name: 'Charges',
                dataFields: { valueY: 'charges', categoryX: 'name' },
                yAxis: 'numbers',
                stacked: true,
              },
              {
                type: 'ColumnSeries',
                name: 'Marge (€)',
                dataFields: { valueY: 'margin', categoryX: 'name' },
                yAxis: 'numbers',
                stacked: true,
              },
              {
                type: 'LineSeries',
                name: 'Marge (%)',
                dataFields: { valueY: 'perc', categoryX: 'name' },
                bullets: [{ type: 'CircleBullet', stroke: '#fff', strokeWidth: 2 }],
                yAxis: 'percents',
              },
            ],
            // maskBullets: false,
            data: this.points,
            yAxes: [
              {
                type: 'ValueAxis',
                id: 'numbers',
                dataFields: { value: 'value', category: 'name' },
                title: { text: 'Montants' },
              },
              {
                type: 'ValueAxis',
                id: 'percents',
                dataFields: { value: 'value', category: 'name' },
                title: { text: 'Poucentage' },
                renderer: { opposite: true },
              },
            ],
            xAxes: [
              {
                type: 'CategoryAxis',
                dataFields: { value: 'value', category: 'name' },
                renderer: { cellStartLocation: 0.1, cellEndLocation: 0.9 },
              },
            ],
            legend: {
              numberFormatter: {
                numberFormat: '#.',
              },
            },
            container_width: 1200,
            container_height: 480,
            events: {
              beforedatavalidated: function (ev) {
                console.log('beforedatavalidated event:', ev);
                ev.target.data.sort((a, b) => {
                  return a.month - b.month;
                });
              },
            },
          });

          this.charts.push({
            type: am4charts.XYChart,
            inline: false,
            colors: StatsModule.chartsColor,
            titles: [
              { text: 'Marge trimestrielle nette dégagée (Ventes - Achats - Charges)', fontSize: 25, marginBottom: 30 },
            ],
            series: [
              {
                type: 'ColumnSeries',
                name: 'Ventes',
                dataFields: { valueY: 'sell', categoryX: 'name' },
                yAxis: 'numbers',
              },
              {
                type: 'ColumnSeries',
                name: 'Achats',
                dataFields: { valueY: 'buy', categoryX: 'name' },
                yAxis: 'numbers',
              },
              {
                type: 'ColumnSeries',
                name: 'Charges',
                dataFields: { valueY: 'charges', categoryX: 'name' },
                yAxis: 'numbers',
                stacked: true,
              },
              {
                type: 'ColumnSeries',
                name: 'Marge (€)',
                dataFields: { valueY: 'margin', categoryX: 'name' },
                yAxis: 'numbers',
                stacked: true,
              },
              {
                type: 'LineSeries',
                name: 'Marge (%)',
                dataFields: { valueY: 'perc', categoryX: 'name' },
                bullets: [{ type: 'CircleBullet', stroke: '#fff', strokeWidth: 2 }],
                yAxis: 'percents',
              },
            ],
            // maskBullets: false,
            data: this.tri_points,
            yAxes: [
              {
                type: 'ValueAxis',
                id: 'numbers',
                dataFields: { value: 'value', category: 'name' },
                title: { text: 'Montants' },
              },
              {
                type: 'ValueAxis',
                id: 'percents',
                dataFields: { value: 'value', category: 'name' },
                title: { text: 'Poucentage' },
                renderer: { opposite: true },
              },
            ],
            xAxes: [
              {
                type: 'CategoryAxis',
                dataFields: { value: 'value', category: 'name' },
                renderer: { cellStartLocation: 0.1, cellEndLocation: 0.9 },
              },
            ],
            legend: {
              numberFormatter: {
                numberFormat: '#.',
              },
            },
            container_width: 1200,
            container_height: 480,
            events: {
              beforedatavalidated: function (ev) {
                console.log('beforedatavalidated event:', ev);
                ev.target.data.sort((a, b) => {
                  return a.month - b.month;
                });
              },
            },
          });

          resolve(true);
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }
}
