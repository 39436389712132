<div class="toolbar {{ toolbar.class }}" *ngIf="toolbar">
  <button class="btn btn-default" (click)="prevPage()">
    <i class="fa fa-chevron-left"></i>
    <span>Retour</span>
  </button>
  <div class="toolbar-separator"></div>
  <div #interopToolbar></div>
  <div
    *ngFor="let element of toolbar.elements"
    [ngClass]="{
      'toolbar-spacing': element.type == 'spacing',
      'toolbar-separator': element.type == 'separator',
      'toolbar-separator-large': element.type == 'separator-large'
    }"
  >
    <!-- {foreach $toolbar_items item='item'}
            {include file='toolbar/'|cat:$item.type|cat:'.tpl' item=$item}
        {/foreach} -->
    <select
      *ngIf="element.type == 'select' && !(element.visible === false)"
      class="form-control"
      [(ngModel)]="element.value"
    >
      <option *ngFor="let option of element.options" [value]="option.value">{{ option.text }}</option>
    </select>
    <button
      *ngIf="element.type == 'button' && !(element.visible === false)"
      class="btn btn-default form-control {{ element.className }}"
      (click)="element.click(toolbar.data)"
      [disabled]="element.disabled || element.access === false"
    >
      <i class="fa fa-{{ element.icon }}"></i><span [innerHTML]="element.text"></span>
    </button>
    <label
      *ngIf="element.type == 'toggle-button' && !(element.visible === false)"
      class="btn btn-default"
      [(ngModel)]="element.value"
      (click)="element.click(toolbar.data)"
      btnCheckbox
    >
      <i class="fa fa-{{ element.icon }}"></i><span [innerHTML]="element.text"></span>
    </label>
  </div>
  <div class="toolbar-separator"></div>
  <h3 class="ml-auto">{{ toolbar.viewTitle }}</h3>
  <div class="account-menu">
    <div class="account-menu-icon" (click)="toolbar.showAccountMenu = !toolbar.showAccountMenu">
      <i class="fas fa-user"></i>
    </div>
    <div class="account-menu-overlay" *ngIf="toolbar.showAccountMenu" (click)="toolbar.showAccountMenu = false"></div>
    <ul
      class="toolbar-menu-content"
      [ngStyle]="{
        height: toolbar.showAccountMenu ? 'auto' : '0%',
        display: toolbar.showAccountMenu ? 'block' : 'none'
      }"
    >
      <li class="toolbar-menu-item">
        <i class="fas fa-user"></i><span>{{ loggedUserName }}</span>
      </li>
      <li class="toolbar-menu-item" (click)="logout()">
        <i class="fas fa-sign-out-alt"></i><span>Se déconnecter</span>
      </li>
    </ul>
  </div>
  <div class="toolbar-menu">
    <div class="toolbar-menu-icon" (click)="toolbar.showMenu = !toolbar.showMenu">
      <i class="fa fa-bars"></i>
    </div>
    <div class="toolbar-menu-overlay" *ngIf="toolbar.showMenu" (click)="toolbar.showMenu = false"></div>
    <ul
      class="toolbar-menu-content"
      [ngStyle]="{ height: toolbar.showMenu ? 'auto' : '0%', display: toolbar.showMenu ? 'block' : 'none' }"
    >
      <li *ngFor="let item of menuItems" class="toolbar-menu-item" (click)="menuItemClick($event, item)">
        <i class="fa fa-{{ item.icon }}"></i><span>{{ item.name }}</span>
      </li>
    </ul>
  </div>
  <div class="accounting-menu">
    <div class="accounting-menu-icon" (click)="toolbar.showAccountingMenu = !toolbar.showAccountingMenu">
      {{ currentAccounting.name }}
    </div>
    <div
      class="accounting-menu-overlay"
      *ngIf="toolbar.showAccountingMenu"
      (click)="toolbar.showAccountingMenu = false"
    ></div>
    <ul
      class="toolbar-menu-content"
      [ngStyle]="{
        height: toolbar.showAccountingMenu ? 'auto' : '0%',
        display: toolbar.showAccountingMenu ? 'block' : 'none'
      }"
    >
      <li *ngFor="let item of accountingsItems" class="toolbar-menu-item" (click)="accountingsItemClick($event, item)">
        <i class="fa fa-calculator"></i><span>{{ item.fullDescription }}</span>
      </li>
    </ul>
  </div>
</div>
