import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IncidentsListViewComponent } from './views/incidents-list-view/incidents-list-view.component';
import { IncidentViewComponent } from './views/incident-view/incident-view.component';
import { ClassManager } from 'src/app/classes/objects/ClassManager.class';
import { Incident } from './classes/Incident.class';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { ModuleBase } from '../module-base.class';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ComponentsModule
    ],
    declarations: [
        IncidentsListViewComponent,
        IncidentViewComponent
    ],
    exports: [
        IncidentsListViewComponent,
        IncidentViewComponent
    ],
    entryComponents: [
        IncidentsListViewComponent,
        IncidentViewComponent
    ]
})
export class IncidentsModule extends ModuleBase {

    public static moduleName = 'invoicing';

    public ngOnInit()
    {
        ClassManager.registerClass('Incident', Incident);

        HomepageViewComponent.registerGroups({
            'sales': {
                'name': "Ventes",
                'backColor': 'rgb(149, 55, 53)',
                'textColor': 'white',
                'permission': 'home_groupe_ventes',
                'children': [
                    {
                        'name': "Fiches incident",
                        'permission': 'home_bouton_ventes_incidents',
                        'component': IncidentsListViewComponent
                    }
                ]
            }
        });

        return super.ngOnInit();
    }
  }
