import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleBase } from '../module-base.class';
import { HomepageViewComponent } from './views/homepage-view/homepage-view.component';
import { FormsModule } from '../../../../node_modules/@angular/forms';
import { ComponentsModule } from '../../components/components.module';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ComponentsModule
    ],
    declarations: [
        HomepageViewComponent
    ],
    exports: [
        HomepageViewComponent
    ],
    entryComponents: [
        HomepageViewComponent
    ]
})
export class HomepageModule extends ModuleBase {

    ngOnInit()
    {
        Toolbar.registerMenuItem({
            name: 'Recharger l\'ERP',
            icon: 'sync-alt',
            click: () => { window.location.reload(); }
        });
    }

}
