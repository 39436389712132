<div class="invoice-container">

    <div class="invoice-container-columns">

        <info-block #selectBlockComponent [block]="selectBlock" [data]="instance" [readonly]="invoiceCopy.id != 0 || !writeAccess"></info-block>
        <info-block #invoiceBlockComponent [block]="invoiceBlock" [data]="invoiceCopy" [readonly]="!writeAccess"></info-block>
        <info-block #saleBlockComponent [block]="saleBlock" [data]="invoiceCopy" [readonly]="true"></info-block>
        <info-block #deliveryBlockComponent [block]="deliveryBlock" [data]="invoiceCopy" [readonly]="!writeAccess"></info-block>

        <info-block #invoiceRemarksBlockComponent [block]="invoiceRemarksBlock" [data]="invoiceCopy" [readonly]="!writeAccess"></info-block>
        <info-block #saleRemarksBlockComponent [block]="saleRemarksBlock" [data]="invoiceCopy" [readonly]="true"></info-block>

        <info-block [block]="accountingBlock" #accountingBlockComponent [data]="invoiceCopy" [readonly]="!writeAccess"></info-block>

        <div class="send-warning-block" *ngIf="dontSendByPost">NE PAS ENVOYER PAR VOIE POSTALE</div>
        <div class="send-warning-block" *ngIf="dontSendByMail">NE PAS ENVOYER PAR E-MAIL</div>

        <info-block #optionsBlockComponent *ngIf="model" [block]="model.optionsBlock" [data]="model" [readonly]="!writeAccess"></info-block>

    </div>

    <div class="invoice-container-columns">

        <div *ngIf="invoiceCopy && invoiceCopy.order && model">
            <printable-page #pagesElements *ngFor="let page of model.pages" [page]="page" [ngStyle]="{'width': page.width+'mm', 'height': page.height+'mm'}"></printable-page>
        </div>

    </div>

</div>
