<!-- <select [(ngModel)]="selectedObject"> -->
<!-- <div class="list-input" #elementRef> -->
<div *ngIf="linesCount == 1" [ngClass]="{'readonly': readonly}" class="single-line-container">
    <div *ngIf="multiSelect" class="list-selected-option" (click)="toggleList()">
        {{ _selectionText }}
        <span class="list-input-arrow">{{ showList === true ? '&#9650;' : '&#9660;' }}</span>
    </div>
    <div class="list-options-overlay" *ngIf="showList && multiSelect" (click)="showList = false"></div>
    <div class="list-options" *ngIf="showList && multiSelect" [ngClass]="{'list-options-inverse': inverseList}">
        <div class="list-option" *ngIf="allowNull === true && !multiSelect" (click)="itemClick(null)">{{ nullText ? nullText : '(Aucun object sélectionné)' }}</div>
        <div class="list-option" *ngFor="let object of objects" (click)="itemClick(object)">
            <input type="checkbox" *ngIf="multiSelect === true" [checked]="selectedObjects ? selectedObjects.includes(object) : false" />{{ object[fieldName] }}
        </div>
    </div>
    <select *ngIf="!multiSelect" [(ngModel)]="selectedObject" (change)="selectChange($event)" class="single-line-input">
    <!-- <select *ngIf="!multiSelect" [(ngModel)]="selectedObject" class="single-line-input"> -->
        <option *ngIf="allowNull === true" [ngValue]="null">{{ nullText ? nullText : '(Aucun object sélectionné)' }}</option>
        <option *ngFor="let object of objects" [ngValue]="object" class="{{ getOptionStyle ? getOptionStyle(object) : '' }}">{{ object[fieldName] }}</option>
    </select>
</div>
<div *ngIf="linesCount > 1" class="list-options-fixed" [ngStyle]="{'height': (1.5 * linesCount) + 'em'}" [ngClass]="{'readonly': readonly}">
    <div class="list-option" *ngIf="allowNull === true && !multiSelect" (click)="itemClick(null)">{{ nullText ? nullText : '(Aucun object sélectionné)' }}</div>
    <div class="list-option" *ngFor="let object of objects" (click)="itemClick(object)"
         [ngClass]="{'list-selected-option-multiline': (selectedObject == object && !multiSelect) || (multiSelect == true && selectedObjects && selectedObjects.includes(object))}">
        <input type="checkbox" *ngIf="multiSelect === true" [disabled]="readonly"
               [checked]="selectedObjects ? selectedObjects.includes(object) : false" />{{ object[fieldName] }}
    </div>
</div>
<!-- </div> -->
<!-- </select> -->