import { Component, OnInit, ViewChild } from '@angular/core';
import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { Stock } from '../../classes/Stock.class';
import { ViewsComponent } from 'src/app/components/views/views.component';
import { StockViewComponent } from '../stock-view/stock-view.component';
import { StockMove } from '../../classes/StockMove.class';
import { StockMoveViewComponent } from '../stock-move-view/stock-move-view.component';
import { DataGridComponent } from 'src/app/components/data-grid/data-grid.component';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { ArrayUtil } from 'src/app/modules/utils/classes/ArrayUtil.class';
import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { DataGridRowAction } from "../../../../components/data-grid/classes/DataGridRowAction.class";
import { Order } from '../../../orders/classes/Order.class';
import { ApiService } from '../../../../services/api/api.service';

@Component({
  selector: 'app-stock-moves-list-view',
  templateUrl: './stock-moves-list-view.component.html',
  styleUrls: ['./stock-moves-list-view.component.css']
})
export class StockMovesListViewComponent extends AccessViewComponent {

  public permission: string = 'ecriture_stocks_listeappels';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Appels sur stock',
    data: this,
    elements: [
      {
        type: 'button',
        text: 'Créer un appel<br/>sur stock',
        icon: 'plus',
        click: function (view: StockMovesListViewComponent) {
          view.createMove();
        },
        access: this.writeAccess
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Supprimer',
        icon: 'times',
        click: function (view: StockMovesListViewComponent) {
          view.deleteMoves();
        },
        access: this.writeAccess
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Archiver',
        icon: 'archive',
        click: function (view: StockMovesListViewComponent) {
          view.archiveMoves();
        },
        access: this.writeAccess
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Désarchiver',
        icon: 'archive',
        click: function (view: StockMovesListViewComponent) {
          view.archiveMoves(false);
        },
        access: this.writeAccess
      },
      { type: 'separator-large' },
      {
        type: 'toggle-button',
        text: 'Afficher les<br/>éléments archivés',
        icon: 'filter',
        value: false,
        click: function (view: StockMovesListViewComponent) {
          view.filterMoves = !this.value;
          view.reloadMoves();
          //view.updateSuppliersList();
        }
      },
      { type: 'spacing' }
    ]
  };

  @ViewChild('grid') grid: DataGridComponent;
  public gridRowActions: DataGridRowAction[] = [
    {
      name: 'Afficher', icon: 'search', click: (event: any, item: any) => {
        this.moveDblClick(event, item);
      }
    }
  ];

  public filterMoves = true;
  public moves: StockMove[] = [];
  public movesGridColumns = [
    { title: 'Date', field: 'date', type: 'date', width: 80 },
    { title: 'Numéro d\'appel', field: 'fullNumber', width: 200 },
    { title: 'Numéro de facture', field: 'invoice.number', width: 200 },
    { title: 'Désignation', field: 'article.designation', width: 550 },
    { title: 'Client', field: 'customer.nameWithIdentifier', width: 200 },
    { title: 'N° de commande client', field: 'customer_reference', width: 150 },
    { title: 'Fournisseur', field: 'supplier.name', width: 200 },
    { title: 'Quantité', field: 'quantityAndUnit', width: 250 },
  ];

  ngOnInit() {
    this.reloadMoves();
  }

  onAccountingChanged(accounting: Accounting) {
    this.reloadMoves();
  }

  reloadMoves() {
    const conditions = [];
    if (AccountingsService.currentAccounting) conditions.push('id_accounting=' + AccountingsService.currentAccounting.id + ' AND db_id_accounting=' + AccountingsService.currentAccounting.db_id);
    if (this.filterMoves) conditions.push('archived = 0');
    StockMove.load(null, ['~date'], null, false,
      conditions.length > 0 ? conditions.join(' AND ') : null, true).then(
        (result) => { this.moves = result; },
        (err) => { console.error(err); }
      );
  }

  createMove() {
    let move: StockMove = new StockMove();
    this.moves.push(move);
    this.moveDblClick(null, move);
  }

  deleteMoves() {
    let moves: StockMove[] = this.grid.selectedItems as StockMove[];
    if (Array.isArray(moves) && moves.length > 0) {
      let promises: any[] = [];
      for (let i = 0; i < moves.length; ++i) promises.push(moves[i].moveToTrash());
      Promise.all(promises).then(
        (result) => {
          NotificationsComponent.push({
            type: 'success',
            title: 'Demandes supprimées',
            summary: 'Les demandes de prix ont été supprimées avec succès'
          });
          ArrayUtil.removeElements(this.moves, moves);
        },
        (err) => {
          NotificationsComponent.push({
            type: 'error',
            title: 'Erreur lors de la suppression',
            summary: 'Une erreur s\'est produite lors de la suppression',
            content: err
          });
          console.error(err);
        }
      )
    }
  }

  moveDblClick(event, move) {
    ViewsComponent.openView(StockMoveViewComponent, move);
  }

  archiveMoves(archive = true) {
    const moves: StockMove[] = this.grid.selectedItems as StockMove[];
    if (Array.isArray(moves) && moves.length > 0) {
      const ids: string[] = [];
      for (let i = 0; i < moves.length; ++i) {
        // orders[i].archived = (archive === false ? 0 : 1);
        ids.push(moves[i].fullId);
      }
      ApiService.callModule('moves', 'archive', { ids: ids, archive: (archive ? 1 : 0) }).then(
        (result) => {
          NotificationsComponent.push({
            type: 'success',
            title: 'Appels archivés',
            summary: 'Les appels ont été ' + (archive === false ? 'dés' : '') + 'archivés avec succès'
          });
          this.grid.clearSelection();
          this.reloadMoves();
          // ArrayUtil.removeElements(this.orders, orders);
        },
        (err) => {
          NotificationsComponent.push({
            type: 'error',
            title: 'Erreur lors de l\'archivage',
            summary: 'Une erreur s\'est produite lors ' + (archive === false ? 'du dés' : 'de l\'') + 'archivage des appels',
            content: err
          });
          console.error(err);
        }
      );
    }
  }

  gridRowStyle(item: Order) {
    return (item && item.archived == 1) ? {
      'color': 'gray',
      'font-style': 'italic'
    } : null;
  }

}
