<div class="articles-type-selection">
    <label for="articlesType_0" class="articlesType_0" [ngClass]="{'active': articlesType == 0}">
        <input type="radio" name="articlesType" id="articlesType_0" [(ngModel)]="articlesType" [value]="0" />
        <span>Articles consommables</span>
    </label>
    <label for="articlesType_1" class="articlesType_1" [ngClass]="{'active': articlesType == 1}">
        <input type="radio" name="articlesType" id="articlesType_1" [(ngModel)]="articlesType" [value]="1" />
        <span>Articles matériels</span>
    </label>
    <label for="articlesType_2" class="articlesType_2" [ngClass]="{'active': articlesType == 2}">
        <input type="radio" name="articlesType" id="articlesType_2" [(ngModel)]="articlesType" [value]="2" />
        <span>Services</span>
    </label>
</div>
<data-grid #grid *ngIf="articlesType == 0" id="articles-list-0"
    [ngClass]="articlesType_0" [pageSize]="20"
    [items]="articles[articlesType]"
    [columns]="articlesGridColumns[articlesType]"
    [rowActions]="articlesGridRowActions"
    [headerBackColor]="articlesGridHeaderBackColor[articlesType]"
    [resizable]="true" [editable]="false" [selectable]="writeAccess"
    (itemDblClick)="articleDblClick($event, $event.item)">
</data-grid>
<data-grid #grid *ngIf="articlesType == 1" id="articles-list-1"
    [ngClass]="articlesType_1" [pageSize]="20"
    [items]="articles[articlesType]"
    [columns]="articlesGridColumns[articlesType]"
    [rowActions]="articlesGridRowActions"
    [headerBackColor]="articlesGridHeaderBackColor[articlesType]"
    [resizable]="true" [editable]="false" [selectable]="writeAccess"
    (itemDblClick)="articleDblClick($event, $event.item)">
</data-grid>
<data-grid #grid *ngIf="articlesType == 2" id="articles-list-2"
    [ngClass]="articlesType_2" [pageSize]="20"
    [items]="articles[articlesType]"
    [columns]="articlesGridColumns[articlesType]"
    [rowActions]="articlesGridRowActions"
    [headerBackColor]="articlesGridHeaderBackColor[articlesType]"
    [resizable]="true" [editable]="false" [selectable]="writeAccess"
    (itemDblClick)="articleDblClick($event, $event.item)">
</data-grid>