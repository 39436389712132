import { ObjectModel2 } from "../../../classes/objects/ObjectModel2.class";
import { ObjectDefinition } from "../../../classes/objects/ObjectDefinition.class";
import { ClassManager } from "../../../classes/objects/ClassManager.class";
import { Supplier } from "../../suppliers/classes/Supplier.class";
import { ObjectModel3 } from "src/app/classes/objects/ObjectModel3.class";
import { LoadSequence } from "src/app/classes/objects/LoadSequence.class";
import { LoadingPromise } from "src/app/classes/objects/LoadingPromise.class";


export class Nomenclature extends ObjectModel3
{
    // public number: string = null;
    public name: string = null;
    public budget: number = 0;
    public locked: number = 0;

    public parent: Nomenclature = null;
    public children: Nomenclature[] = [];
    public files: any[] = [];

    public suppliers: Supplier[] = [];

    constructor() {
        super(Nomenclature);
    }

    get number()
    {
        if (!this.name) return '';
        let result = /^([^\s\.]+)/.exec(this.name);
        if (!result) return '';
        else if (!result[0]) return '';
        else return result[0];
    }


    /* ObjectModel FUNCTIONS */

    public static definition: ObjectDefinition = {
        trashDelete: true,
        database: {
            table: 'nomenclatures',
            id: 'id',
			db_id: 'db_id'
        },
        values: {
            // 'number': { type: 'string' },
            'name': { type: 'string' },
            'budget': { type: 'number' },
            'locked': { type: 'number' },
        },
        children: {
            'parent': { type: 'Nomenclature', clone: false, save: false, delete: false, parent: 'children' }
        },
        links: {
            'suppliers': { type: 'Supplier', table: 'suppliers_nomenclatures', id_self: 'id_nomenclature', id_link: 'id_supplier',
                           clone: false, save: false, delete: false  }
        }
    };

    public static load(ids: string[] = null, orderBy: string[] = null, groupBy: string[] = null,
        deleted: boolean = false, conditions: string = null, forceReload: boolean = false)
    {
        return LoadingPromise.create<any>((resolve, reject) => {
            super.load(ids, orderBy, groupBy, deleted, conditions, forceReload).then(
                (result: Nomenclature[]) => {
                    for(let i=0; i<result.length; ++i)
                    {
                        let parent: Nomenclature = result[i].parent;
                        if (parent && !parent.children.includes(result[i])) parent.children.push(result[i]);
                    }
                    resolve(result);
                },
                (err) => { reject(err); }
            );
        });
    }
}
