<div class="stock-container">

    <div class="stock-container-columns">

        <h3>Stock parent</h3>
        <info-block #parentStockBlockComponent [block]="parentStockBlock" [data]="parentStockCopy"></info-block>

        <h3>Stocks liés</h3>
        <data-grid id="parent-stock-linked-stocks" #stocksGrid *ngIf="parentStockCopy" [items]="parentStockCopy.stocks" [columns]="stocksGridColumns"
            [resizable]="true" [editable]="false" [selectable]="true" [actions]="stocksGridActions"></data-grid>

        <h3>Appels sur stock liés</h3>
        <data-grid id="parent-stock-linked-moves" #movesGrid *ngIf="parentStockCopy" [items]="parentStockCopy.moves" [columns]="movesGridColumns"
            [resizable]="true" [editable]="false" [selectable]="false"></data-grid>

    </div>

    <div class="stock-container-columns">

        <h3>Informations article</h3>

        <info-block #articleBlockComponent [block]="articleBlock" [data]="parentStockCopy.article" [readonly]="true" *ngIf="!parentStockCopy.article || parentStockCopy.article.type == 0"></info-block>
        <info-block #deviceBlockComponent [block]="deviceBlock" [data]="parentStockCopy.article" [readonly]="true" *ngIf="parentStockCopy.article && parentStockCopy.article.type == 1"></info-block>
        <info-block #serviceBlockComponent [block]="serviceBlock" [data]="parentStockCopy.article" [readonly]="true" *ngIf="parentStockCopy.article && parentStockCopy.article.type == 2"></info-block>

    </div>

</div>
