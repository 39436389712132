import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

    private static instance: LoadingComponent = null;
    public messages: string[] = [];
    //public count: number = 0;
    public get count() {
        return this.messages.length;
    }
    public get loadingText() {
        if (this.messages.length == 0) return "Veuillez patienter ...";
        return this.messages[this.messages.length - 1];
    }

    constructor() {
        LoadingComponent.instance = this;
    }

    ngOnInit() {
    }

    public static push(message: string = null)
    {
        //++LoadingComponent.instance.count;
        LoadingComponent.instance.messages.push(message ? message : "Veuillez patienter ...");
    }
    public static pop()
    {
        //--LoadingComponent.instance.count;
        LoadingComponent.instance.messages.pop();
    }

}
