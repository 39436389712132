import { Component, ElementRef, ViewChild } from '@angular/core';

import { Toolbar } from '../../components/toolbar/classes/Toolbar.class';
import { ViewBaseComponent } from '../../components/views/view-base.component';

@Component({
  selector: 'app-interop-view',
  templateUrl: './interop-view.component.html',
  styleUrls: ['./interop-view.component.scss'],
})
export class InteropViewComponent extends ViewBaseComponent {
  public toolbar: Toolbar = {
    class: 'toolbar-big',
    data: this,
    interopComponentName: 'NomenclaturesListToolbar',
  };

  @ViewChild('interopContainer') interopContainer: ElementRef<HTMLDivElement>;

  componentName = '';
  interopKey = Math.random() * 1000000;

  initView(componentName): void {
    console.log('[Interop] init interop view for', componentName);
    this.componentName = componentName;
  }

  ngAfterViewInit(): void {
    console.log('[Interop] ngAfterViewInit, injecting interop...');
    window.reactInterop.injectComponent(
      `interop-${this.interopKey}`,
      this.interopContainer.nativeElement,
      this.componentName
    );
  }
}
