import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoicesListViewComponent } from './views/invoices-list-view/invoices-list-view.component';
import { InvoiceViewComponent } from './views/invoice-view/invoice-view.component';
import { ModuleBase } from '../module-base.class';
import { ClassManager } from 'src/app/classes/objects/ClassManager.class';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { Invoice } from './classes/Invoice.class';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components/components.module';
import { PrintingModule } from '../printing/printing.module';
import { ReimbursementViewComponent } from './views/reimbursement-view/reimbursement-view.component';
import { ReimbursementsListViewComponent } from './views/reimbursements-list-view/reimbursements-list-view.component';
import { Reimbursement } from './classes/Reimbursement.class';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    PrintingModule
  ],
  declarations: [
    InvoicesListViewComponent,
    InvoiceViewComponent,
    ReimbursementViewComponent,
    ReimbursementsListViewComponent
  ],
  exports: [
    InvoicesListViewComponent,
    InvoiceViewComponent,
    ReimbursementViewComponent,
    ReimbursementsListViewComponent
  ],
  entryComponents: [
    InvoicesListViewComponent,
    InvoiceViewComponent,
    ReimbursementViewComponent,
    ReimbursementsListViewComponent
  ]
})
export class InvoicingModule extends ModuleBase {

  public static moduleName = 'invoicing';

  public ngOnInit()
  {
      ClassManager.registerClass('Invoice', Invoice);
      ClassManager.registerClass('Reimbursement', Reimbursement);

      HomepageViewComponent.registerGroups({
          'logistics_invoicing': {
              'name': "Facturation logistique",
              'fontSize': '13pt',
              'height': '44px',
              'lineHeight': '28px',
              'backColor': 'rgb(0, 51, 205)',
              'textColor': 'white',
              'permission': 'home_groupe_facturation_log',
              'children': [
                  {
                      'name': "Facture logistique",
                      'backColor': 'rgb(102,102,255)',
                      'textColor': 'white',
                      'permission': 'home_bouton_facturationlog_facture',
                      'component': InvoiceViewComponent,
                      'getArgs': () => [ null, true ]
                  },
                  {
                      'name': "Facturier logistique",
                      'backColor': 'rgb(102,102,255)',
                      'textColor': 'white',
                      'permission': 'home_bouton_facturationlog_facturier',
                      'component': InvoicesListViewComponent,
                      'getArgs': () => [ true ]
                  },
                  {
                      'name': "N.C. logistique",
                      'backColor': 'rgb(0,176,240)',
                      'textColor': 'black',
                      'permission': 'home_bouton_facturationlog_nc',
                      'component': ReimbursementViewComponent,
                      'getArgs': () => [ null, true ]
                  },
                  {
                      'name': "Livre des N.C. logistiques",
                      'backColor': 'rgb(0,176,240)',
                      'textColor': 'black',
                      'permission': 'home_bouton_facturationlog_livrenc',
                      'component': ReimbursementsListViewComponent,
                      'getArgs': () => [ true ]
                  }
              ]
          },
          'invoicing': {
              'name': "Facturation commerciale",
              'fontSize': '13pt',
              'height': '44px',
              'lineHeight': '28px',
              'backColor': 'rgb(149, 55, 53)',
              'textColor': 'white',
              'permission': 'home_groupe_facturation_com',
              'children': [
                  {
                      'name': "Facture commerciale",
                      'permission': 'home_bouton_facturationcom_facture',
                      'component': InvoiceViewComponent
                  },
                  {
                      'name': "Facturier commercial",
                      'permission': 'home_bouton_facturationcom_facturier',
                      'component': InvoicesListViewComponent
                  },
                  {
                      'name': "N.C. commerciale",
                      'backColor': 'rgb(217, 150, 148)',
                      'textColor': 'black',
                      'permission': 'home_bouton_facturationcom_nc',
                      'component': ReimbursementViewComponent,
                      'getArgs': () => [ null, false ]
                  },
                  {
                      'name': "Livre des N.C.",
                      'backColor': 'rgb(217, 150, 148)',
                      'textColor': 'black',
                      'permission': 'home_bouton_facturationcom_livrenc',
                      'component': ReimbursementsListViewComponent,
                      'getArgs': () => [ false ]
                  }
              ]
          }
      });

      return super.ngOnInit();
  }
}
