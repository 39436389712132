<div class="data-grid">
  <div class="settings" [ngStyle]="{ 'background-color': headerBackColor, color: headerTextColor }">
    <div class="settings-group">
      <div class="buttons">
        <button class="btn btn-default" (click)="clearFilters()" style="color: red">
          <i class="fas fa-filter"></i>
        </button>
        <button class="btn btn-default" (click)="toggleTotals()">f<sub>x</sub></button>
        <button class="btn btn-default" (click)="copyTable(true, true)"><i class="fas fa-copy"></i></button>
      </div>
    </div>
    <div class="settings-group">
      <div class="table-actions">
        <button *ngFor="let action of actions" class="btn btn-default" (click)="actionButtonClick(action, $event)">
          <i [class]="action.iconClass"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="table-container" #tableContainer [ngClass]="{ editable: editable == true, readonly: editable === false }">
    <div class="header__container">
      <div class="fixed-cell" #fixedCell>
        <table>
          <colgroup *ngIf="useColGroup == true">
            <col *ngIf="selectable === true" [ngStyle]="{ width: '30px' }" />
            <col
              *ngFor="let column of fixedHeaderColumns; let index = index"
              [ngStyle]="{ width: (column.width || 150) + 'px' }"
            />
          </colgroup>
          <tr *ngFor="let html of fixedPrependHeadersHtml" [innerHTML]="html"></tr>
          <tr class="header-row">
            <th class="selectionColumn" *ngIf="selectable === true">
              <span
                class="select-all"
                [ngClass]="{ checked: allSelected === true, indeterminate: allSelected === undefined }"
                (click)="toggleAllSelected($event)"
              ></span>
              <!-- </label> -->
            </th>
            <th
              *ngFor="let column of fixedHeaderColumns; let index = index"
              [ngStyle]="{
                'background-color': column.headerBackColor,
                color: column.headerTextColor,
                width: (column.width || 150) + 'px'
              }"
              [attr.colspan]="column.colspan || 1"
              [attr.rowspan]="column.rowspan || 1"
            >
              <span class="cell-content" [innerHTML]="column.title"></span>

              <div
                class="filter-button"
                (click)="showFilter(index)"
                [ngClass]="{ 'active-filter': filterIndex === index + fixedColumnsCount }"
              ></div>
              <data-grid-filter
                *ngIf="filterIndex === index"
                [filter]="filters[index]"
                (onClose)="closeFilter()"
                (onChange)="onFilterChanged($event)"
                (onSortActionClicked)="onFilterSortClicked($event)"
              >
              </data-grid-filter>

              <div *ngIf="resizable == true" class="resize-grip" (mousedown)="resizeGripCapture($event, column)"></div>
            </th>
          </tr>
          <tr *ngFor="let html of fixedAdditionalHeadersHtml" [innerHTML]="html"></tr>
          <tr class="totals-row" *ngIf="showTotals">
            <th class="selectionColumn" *ngIf="selectable === true">&nbsp;</th>
            <th *ngFor="let column of fixedColumns; let index = index">
              <select
                *ngIf="showTotalsFunctions"
                class="total-function"
                [(ngModel)]="columnTotalFunc[index]"
                (change)="calculateTotal(index, filteredItems, column)"
              >
                <option [ngValue]="null">(Aucune)</option>
                <option *ngFor="let funcName of totalFunctionNames" [ngValue]="funcName">{{ funcName }}</option>
              </select>
              <div class="total-result">
                {{ columnTotalFunc[index] && columnTotalValue[index] ? columnTotalValue[index] : '&nbsp;' }}
              </div>
            </th>
          </tr>
          <!-- <tr *ngFor="let html of additionalFixedHeadersHtml" [innerHTML]="html"></tr> -->
        </table>
      </div>
      <header #headerElem>
        <table>
          <colgroup *ngIf="useColGroup == true">
            <col
              *ngFor="let column of scrollableHeaderColumns; let index = index"
              [ngStyle]="{ width: (column.width || 150) + 'px' }"
            />
          </colgroup>
          <tr *ngFor="let html of prependHeadersHtml" [innerHTML]="html"></tr>
          <tr class="header-row">
            <th
              *ngFor="let column of scrollableHeaderColumns; let index = index"
              [ngStyle]="{
                'background-color': column.headerBackColor,
                color: column.headerTextColor,
                width: (column.width || 150) + 'px'
              }"
              [attr.colspan]="column.colspan || 1"
              [attr.rowspan]="column.rowspan || 1"
            >
              <span class="cell-content" [innerHTML]="column.title"></span>

              <div
                class="filter-button"
                (click)="showFilter(index + fixedColumnsCount)"
                [ngClass]="{ 'active-filter': filterIndex === index + fixedColumnsCount }"
              ></div>
              <data-grid-filter
                *ngIf="filterIndex === index + fixedColumnsCount"
                [filter]="filters[index + fixedColumnsCount]"
                (onClose)="closeFilter()"
                (onChange)="onFilterChanged($event)"
                (onSortActionClicked)="onFilterSortClicked($event)"
              >
              </data-grid-filter>

              <div *ngIf="resizable == true" class="resize-grip" (mousedown)="resizeGripCapture($event, column)"></div>
            </th>
          </tr>
          <tr *ngFor="let html of additionalHeadersHtml" [innerHTML]="html"></tr>
          <tr class="totals-row" *ngIf="showTotals">
            <th *ngFor="let column of scrollableColumns; let index = index">
              <select
                *ngIf="showTotalsFunctions"
                class="total-function"
                [(ngModel)]="columnTotalFunc[index + fixedColumnsCount]"
                (change)="calculateTotal(index + fixedColumnsCount, filteredItems, column)"
              >
                <option [ngValue]="null">(Aucune)</option>
                <option *ngFor="let funcName of totalFunctionNames" [ngValue]="funcName">{{ funcName }}</option>
              </select>
              <div class="total-result">
                {{
                  columnTotalFunc[index + fixedColumnsCount] && columnTotalValue[index + fixedColumnsCount]
                    ? columnTotalValue[index + fixedColumnsCount]
                    : '&nbsp;'
                }}
              </div>
            </th>
          </tr>
        </table>
      </header>
    </div>
    <div class="body__container">
      <aside #asideElem>
        <div class="filler"></div>
        <table>
          <colgroup *ngIf="useColGroup == true">
            <col *ngIf="selectable === true" [ngStyle]="{ width: '30px' }" />
            <col
              *ngFor="let column of fixedColumns; let index = index"
              [ngStyle]="{ width: (column.width || 150) + 'px' }"
            />
          </colgroup>
          <tr *ngFor="let html of fixedFooterRowsHtml" [innerHTML]="html"></tr>
        </table>
        <canvas #fixedCanvas></canvas>
      </aside>
      <div class="table-body" #tableBody>
        <div class="scroll__container">
          <div class="filler"></div>
          <footer #footerElem>
            <table>
              <colgroup *ngIf="useColGroup == true">
                <col
                  *ngFor="let column of scrollableColumns; let index = index"
                  [ngStyle]="{ width: (column.width || 150) + 'px' }"
                />
              </colgroup>
              <tr *ngFor="let html of footerRowsHtml" [innerHTML]="html"></tr>
            </table>
          </footer>
        </div>
        <canvas #bodyCanvas></canvas>
      </div>
    </div>
  </div>

  <div
    class="resize-overlay"
    *ngIf="resizing"
    (mouseup)="resizeGripRelease($event)"
    (mousemove)="resizeGripMove($event)"
  ></div>
  <!-- <div class="filter-overlay" *ngIf="filterColumn != null" (click)="filterColumn = null"></div> -->
  <div class="row-actions-overlay" *ngIf="rowActionsItem != null" (click)="rowActionsItem = null"></div>

  <div class="editing-overlay" [hidden]="!isEditing" (click)="onStopEdit()">
    <div class="editing-container editable" #editContainer>
      <div *ngIf="showEditingField" class="cell-input" (click)="onEditingInputClicked($event)">
        <input
          *ngIf="
            editingColumn.type == 'email' ||
            editingColumn.type == 'text' ||
            editingColumn.type == 'phone' ||
            !editingColumn.type
          "
          type="text"
          [(ngModel)]="editingValue"
        />
        <input *ngIf="editingColumn.type == 'date'" type="date" [(ngModel)]="editingValue" />
        <number-field
          *ngIf="editingColumn.type == 'number'"
          [(ngModel)]="editingValue"
          [decimalsCount]="editingColumn.decimalsCount"
          [unit]="editingColumn.unit"
          [negativeColor]="editingColumn.negativeColor"
          [currency]="editingColumn.currencyField ? editingItem[editingColumn.currencyField] : null"
        ></number-field>
        <!-- <number-field *ngIf="editingColumn.type == 'percentage'" [(ngModel)]="editingValue" unit=" %" decimalsCount="1" [calculateButton]="editingColumn.calculateButton" (calculate)="calculate(editingItem, editingColumn)"></number-field> -->
        <div
          *ngIf="editingColumn.type == 'color'"
          [ngStyle]="{ 'background-color': editingValue }"
          class="color-input"
          (click)="colorClick(editingItem, editingColumn)"
        ></div>
        <foreign-list
          *ngIf="editingColumn.type == 'foreing-list' && editingColumn.listItems != null"
          [(ngModel)]="editingValue"
          [multiSelect]="editingColumn.multiSelect"
          [objects]="editingColumn.listItems.items"
          [fieldName]="editingColumn.listField"
          [nullText]="editingColumn.nullText"
          [allowNull]="editingColumn.allowNull"
          [getOptionStyle]="editingColumn.getOptionStyle"
        ></foreign-list>
        <!-- (mousedown)="callFieldFunc('mousedown', block, field, $event)" (change)="callFieldFunc('change', block, field, $event)"  -->
        <!-- <input [(colorPicker)]="editingValue" [style.background]="editingValue" [cpDialogDisplay]="'popup'"/> -->
        <!-- </div> -->
        <input *ngIf="editingColumn.type == 'checkbox'" type="checkbox" [(ngModel)]="editingValue" />
        <span *ngIf="editingColumn.type == 'checkbox'" class="checkbox-label" (click)="checkboxLabelClick($event)">{{
          editingValue == true
            ? editingColumn.checkedValue
              ? editingColumn.checkedValue
              : 'Oui'
            : editingColumn.uncheckedValue
            ? editingColumn.uncheckedValue
            : 'Non'
        }}</span>
      </div>
    </div>
  </div>
</div>
