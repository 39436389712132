import { ReportColumn } from "./ReportColumn.class";
import { ReportRow } from "./ReportRow.class";
import { ReportHeaderRow } from "./ReportHeaderRow.class";
import { ReportCell } from "./ReportCell.class";
import { ReportSetting } from "./ReportSetting.class";
import {LoadingPromise} from "../../../classes/objects/LoadingPromise.class";

export class ReportModel
{
    public title: string = null;

    public settings: ReportSetting[] = null;

    public tableStyle: any = {};

    public headerRows: ReportHeaderRow[] = [];
    public rows: ReportRow[] = [];

    public cells: any = {};
    public gridItems: any[] = null;
    public gridColumns: any[] = null;
    public gridStyle: any = {};

    public charts: any[] = [];

    public emptyCell: ReportCell = null;

    constructor()
    {
        this.emptyCell = new ReportCell();
        this.emptyCell.value = '&nbsp;';
    }

    public regenerate(): Promise<any>
    {
      return LoadingPromise.create<any>((resolve, reject) => {
          reject(false);
      });
    }

    public getSetting(name: string): ReportSetting
    {
        if (this.settings)
        {
            for(let i=0; i<this.settings.length; ++i)
            {
                if (this.settings[i].name == name) return this.settings[i];
            }
        }
        return null;
    }

    public getRowWidth(row: ReportHeaderRow | ReportRow)
    {
        let row_width: number = 0;
        if (Array.isArray(row.cells)) for(let j=0; j<row.cells.length; ++j)
        {
            row_width += row.cells[j] ? (row.cells[j].colSpan || 1) : 1;
        }
        return row_width;
    }

    public getTableWidth()
    {
        let max_width: number = 0;
        for(let i=0; i<this.headerRows.length; ++i)
        {
            let row_width: number = this.getRowWidth(this.headerRows[i]);
            if (row_width > max_width) max_width = row_width;
        }
        for(let i=0; i<this.rows.length; ++i)
        {
            let row_width: number = this.getRowWidth(this.rows[i]);
            if (row_width > max_width) max_width = row_width;
        }
        console.log('table width:', max_width);
        return max_width;
    }

    public fillTableRow(row: ReportHeaderRow | ReportRow, max_width: number = null)
    {
        if (!row.cells) row.cells = [];
        if (max_width == null) max_width = row.cells.length;
        let j: number = 0;
        for(let i=0; i<max_width && j<max_width; ++i) {
            if (!row.cells[i]) row.cells[i] = this.emptyCell;
            j += (row.cells[i].colSpan || 1);
        }
    }

    public createEmptyCells()
    {
        let max_width: number = this.getTableWidth();
        for(let i=0; i<this.headerRows.length; ++i) this.fillTableRow(this.headerRows[i], max_width);
        for(let i=0; i<this.rows.length; ++i) this.fillTableRow(this.rows[i], max_width);
    }

    public get hasRowTitles()
    {
        for(let i=0; i<this.rows.length; ++i)
        {
            if (this.rows[i].title) return true;
        }
        return false;
    }
}
