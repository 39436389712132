import {Component, OnInit, ViewChild} from '@angular/core';
import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { Charge } from '../../classes/Charge.class';
import { InfoBlock } from 'src/app/components/info-block/classes/InfoBlock.class';
import { ViewsComponent } from 'src/app/components/views/views.component';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { ChargeType } from '../../classes/ChargeType.class';
import { Merchant } from 'src/app/modules/other-data/classes/Merchant.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { CurrenciesService } from 'src/app/services/currencies/currencies.service';
import {LoadingPromise} from '../../../../classes/objects/LoadingPromise.class';
import {Form} from '../../../../classes/forms/Form.class';
import {FormValidator} from '../../../../classes/forms/FormValidator.class';
import {InfoBlockComponent} from '../../../../components/info-block/info-block.component';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { DateTimeUtil } from 'src/app/modules/utils/classes/DateTimeUtil.class';

@Component({
  selector: 'app-charge-view',
  templateUrl: './charge-view.component.html',
  styleUrls: ['./charge-view.component.css']
})
export class ChargeViewComponent extends AccessViewComponent {

    public toolbar: Toolbar = {
        class: 'toolbar-big',
        viewTitle: 'Charge',
        data: this,
        elements: [
            { type: 'separator' },
            {
                type: 'button',
                name: 'newChargeButton',
                text: 'Nouvelle<br/>charge',
                icon: ' far fa-file',
                click: function(view: ChargeViewComponent) {
                    DialogsComponent.display({
                        title: 'Créer une nouvelle charge ?',
                        message: 'Toute modification non enregistrée sera perdue !<br/>Êtes-vous sûr(e) de vouloir continuer ?',
                        icon: 'question',
                        buttons: DialogButton.yesNoButtons
                    }).then(
                        (result: any) => {
                            if (result == DialogButton.RESULT_YES) {
                                let charge: Charge = new Charge();
                                charge.accounting = AccountingsService.currentAccounting;
                                charge.currency = charge.accounting.default_currency;
                                charge.date = DateTimeUtil.toDateString(new Date());
                                charge.effective_date = charge.date;
                                view.initView(charge);
                            }
                        }
                    );
                },
                visible: true,
                access: this.writeAccess
            },
            { type: 'separator' },
            {
                type: 'button',
                name: 'saveButton',
                text: 'Enregistrer',
                icon: 'save',
                click: function (view: ChargeViewComponent) {
                    view.save2();
                },
                options: { visible: true }
            },
            { type: 'spacing' }
        ]
    };

    public charge: Charge = null;
    public chargeCopy: Charge = null;

    public chargeTypesData: any = { items: [] };
    public merchantsData: any = { items: [] };
    public accountingsData: any = { items: [] };
    public currenciesData: any = { items: [] };

    @ViewChild('chargeBlockComponent') chargeBlockComponent: InfoBlockComponent;

    public chargeBlock: InfoBlock = {
        title: 'Charge',
        backColor: 'rgb(127,127,127)',
        textColor: 'white',
        fields: [
            { field: 'date', title: 'Date de l\'OP', type: 'date' },
            { field: 'effective_date', title: 'Date effective', type: 'date' },
            { field: 'number', title: 'Numéro de l\'OP', type: 'number', decimalsCount: 0, textAlign: 'left' },
            { field: 'name', title: 'Bénéficiaire' },
            { field: 'type', title: 'Type de charge', type: 'foreign-list', listItems: this.chargeTypesData,
              listField: 'name', nullText: '(Aucun)' },
            { title: 'Représentant à qui\nimputer la charge', field: 'merchant', type: 'foreign-list',
              multiSelect: false, listItems: this.merchantsData, listField: 'numberAndName', nullText: '(Aucun)', allowBlankValues: true },
            { field: 'converted_amount', title: 'Montant de la charge', type: 'number', decimalsCount: 2, textAlign: 'left', currencyField: 'currency' },
            { field: 'converted_vat', title: 'TVA à récupérer', type: 'number', decimalsCount: 2, textAlign: 'left', currencyField: 'currency' },
            { field: 'remarks', title: 'Remarques', type: 'textarea' },
        ]
    }

    public accountingBlock: InfoBlock = {
        title: '',
        backColor: "rgb(64,0,64)",
        textColor: "white",
        fields: [
            { title: 'Comptabilité', field: 'accounting', type: 'foreign-list',
              multiSelect: false, listItems: this.accountingsData, listField: 'fullDescription', allowBlankValues: false,
              comment: 'Sélectionnez ici la comptabilité liée à ce dossier'
            },
            { title: 'Devise', field: 'currency', type: 'foreign-list',
              multiSelect: false, listItems: this.currenciesData, listField: 'fullDescription', allowBlankValues: false,
              comment: 'Sélectionnez ici la devise à utiliser pour cette charge',
              change: () => {
                  this.chargeCopy.xrate = this.chargeCopy.currency.rate;
              }
            },
            { title: 'Taux de change', field: 'xrate', type: 'number', decimalsCount: 6, textAlign: 'left' },
        ]
    };

    ngOnInit() {
        this.accountingsData.items = AccountingsService.accountings;
        this.currenciesData.items = CurrenciesService.currencies;
        ChargeType.load(null, ['name']).then(
            (result) => { this.chargeTypesData.items = result; },
            (err) => { console.error(err); }
        );
        Merchant.load(null, ['number']).then(
            (result) => { this.merchantsData.items = result; },
            (err) => { console.error(err); }
        );
    }

    initView(charge: Charge)
    {
        if (!charge)
        {
            charge = new Charge();
            //charge.number = charge.getNextNumber();
        }
        this.charge = charge;
        this.chargeCopy = this.charge.clone(true);
        this.updateView(this.chargeCopy);
    }

    updateView(charge: Charge)
    {
    }

    validateForm()
    {
        return LoadingPromise.create<any>((resolve, reject) => {
            let form: Form = {
                fields: [
                    {
                        name: 'Type de charge',
                        type: 'foreign-list',
                        data: this.chargeCopy,
                        field: 'type',
                        pattern: null,
                        element: this.chargeBlockComponent.getElement('type')
                    }
                ]
            };
            let result = FormValidator.validateForm(form);
            FormValidator.showFormInvalidFields(form, result);
            if (result !== true)
            {
                FormValidator.showNotification(result);
                reject(result);
            }
            else resolve(true);
        });
    }

    save2()
    {
        return new Promise<any>((resolve, reject) => {
            this.validateForm().then((result) => {
                this.chargeCopy.save2().then(
                    (result) => {
                        this.chargeCopy.clone(true, this.charge);
                        this.initView(this.charge);
                        resolve(result);
                    },
                    (err) => {
                        reject(err);
                    }
                );
            });
        });
    }
}
