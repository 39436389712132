import { Component, OnInit, Input } from '@angular/core';
import { ReportModel } from '../../classes/ReportModel.class';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

    @Input() model: ReportModel;
    @Input() chart: any;
    @Input() gridItems: any[] = null;
    @Input() gridColumns: any[] = null;

    constructor() { }

    ngOnInit() {
    }

}
