import { Component, OnInit, ViewChild } from '@angular/core';

import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { Article } from '../../classes/Article.class';
import { ArticleBase } from '../../classes/ArticleBase.class';
import { ArticleInfoBlocks } from '../../classes/ArticleInfoBlocks.class';
import { CurrenciesService } from 'src/app/services/currencies/currencies.service';
import { DateTimeUtil } from 'src/app/modules/utils/classes/DateTimeUtil.class';
import { Device } from '../../classes/Device.class';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { ExternalService } from 'src/app/services/external/external.service';
import { Form } from 'src/app/classes/forms/Form.class';
import { FormValidator } from 'src/app/classes/forms/FormValidator.class';
import { InfoBlock } from 'src/app/components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from 'src/app/components/info-block/info-block.component';
import { InfoBlockField } from 'src/app/components/info-block/classes/InfoBlockField.class';
import { Nomenclature } from '../../classes/Nomenclature.class';
import { ObjectUtil } from '../../../utils/classes/ObjectUtil.class';
import { RollingDirection } from 'src/app/modules/other-data/classes/RollingDirection.class';
import { Sale } from 'src/app/modules/sales/classes/Sale.class';
import { SaleViewComponent } from 'src/app/modules/sales/views/sale-view/sale-view.component';
import { ServerApi } from '../../../../classes/api/ServerApi.class';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from 'src/app/components/views/views.component';

@Component({
  selector: 'app-article-view',
  templateUrl: './article-view.component.html',
  styleUrls: ['./article-view.component.css'],
})
export class ArticleViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_basededonnees_fichearticle';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Article',
    data: this,
    onPrevPage: () => {
      if (this.articleCopy && this.articleCopy.changed) {
        DialogsComponent.display({
          icon: 'question',
          title: 'Enregistrer les modifications',
          message: 'Des modifications ont été effectuées.<br/>Voulez-vous enregistrer les modifications ?',
          buttons: DialogButton.yesNoCancelButtons,
        }).then((result: any) => {
          if (result === DialogButton.RESULT_YES) {
            this.save().then((result2: any) => {
              ViewsComponent.closeView();
            });
          } else if (result !== DialogButton.RESULT_CANCEL) ViewsComponent.closeView();
        });
        return false;
      }
      return true;
    },
    elements: [
      { type: 'separator' },
      {
        type: 'button',
        name: 'saveButton',
        text: 'Enregistrer',
        icon: 'save',
        click: function (view: ArticleViewComponent) {
          view.save().then((result) => {
            view.updateView(view.articleCopy);
          });
        },
        visible: true,
        disabled: !this.writeAccess,
      },
      { type: 'separator-large' },
      {
        name: 'updateArticleButton',
        type: 'button',
        text: "Modifier<br/>l'article",
        icon: 'edit',
        click: function (view: ArticleViewComponent) {
          DialogsComponent.display({
            title: 'Avertissement !',
            message:
              "Les modifications effectuées sur l'article se répercuteront sur tous les dossiers commerciaux liés à cet article.<br/><br/>" +
              "Modifiez l'article uniquement en cas d'erreur d'encodage !<br/>" +
              'Si vous devez changer un paramètre pour cet article (couleur, format, pinfeed, ...), il faut créer un nouvel article !',
            buttons: DialogButton.okCancelOnlyButtons,
            icon: 'warning',
          }).then((result) => {
            if (result == DialogButton.RESULT_OK) view.updateArticle();
          });
        },
        visible: false,
        disabled: !this.writeAccess,
      },
      { type: 'separator' },
      {
        name: 'copyArticleButton',
        type: 'button',
        text: "Copier<br/>l'article",
        icon: 'copy',
        click: function (view: ArticleViewComponent) {
          DialogsComponent.display({
            title: 'Avertissement !',
            message:
              "Vous êtes sur le point de créer un nouvel article, en recopiant les valeurs de l'ancien.<br/><br/>" +
              'La désignation devra être différente afin de bien distinguer les articles.<br/>' +
              'Veuillez revérifier que toutes les valeurs sont bien correctes pour créer le nouvel article.',
            buttons: DialogButton.okCancelOnlyButtons,
            icon: 'warning',
          }).then((result) => {
            if (result == DialogButton.RESULT_OK) view.copyArticle();
          });
        },
        visible: false,
        disabled: !this.writeAccess,
      },
      { type: 'separator-large' },
      {
        name: 'createSaleButton',
        type: 'button',
        text: 'Créer un dossier<br/>commercial',
        icon: 'paste',
        click: function (view: ArticleViewComponent) {
          if (view.article.id != 0) {
            let sale: Sale = new Sale();
            sale.article = view.article as ArticleBase;
            sale.date = DateTimeUtil.toDateString(new Date());
            sale.creation_date = sale.date;
            sale.accounting = AccountingsService.currentAccounting;
            sale.currency = sale.accounting.default_currency || CurrenciesService.defaultCurrency;
            sale.xrate = sale.currency ? sale.currency.rate : 1;
            ViewsComponent.openView(SaleViewComponent, sale);
          }
        },
        disabled: !this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        name: 'cancelButton',
        text: 'Annuler',
        icon: 'times',
        click: function (view: ArticleViewComponent) {
          //view.cancelChanges();
        },
        visible: false,
      },
      { type: 'spacing' },
    ],
  };

  public article: ArticleBase = null;
  public articleCopy: ArticleBase = null;

  public nomenclaturesData: any = { items: [] };
  public rollingsData: any = { items: [] };

  public editMode: boolean = false;

  public typeBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(0,0,0)',
    textColor: 'white',
    fields: [
      {
        title: "Type d'article",
        field: 'type',
        type: 'select',
        selectOptions: [
          { text: 'Article consommable', value: 0 },
          { text: 'Article matériel', value: 1 },
          { text: 'Service', value: 2 },
        ],
      },
    ],
  };

  public notesBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(0,32,64)',
    textColor: 'white',
    fields: [{ title: 'Notes internes', field: 'internal_notes', type: 'textarea' }],
  };

  public old_prices: any[] = [];
  public oldPricesColumns: any[] = [
    { title: 'N° de dossier', field: 'number', width: 180 },
    { title: 'Date', type: 'date', field: 'date', width: 100 },
    { title: 'Quantité', field: 'quantity', type: 'number', decimalsCount: 2, width: 100 },
    { title: 'Unité', field: 'unit', width: 150 },
    { title: 'Client', field: 'customer', width: 150 },
    { title: 'Fournisseur', field: 'supplier', width: 150 },
    { title: "Prix d'achat", field: 'unit_price', type: 'number', decimalsCount: 8, unit: ' €', width: 120 },
    { title: 'Prix de vente', field: 'sell_price', type: 'number', decimalsCount: 8, unit: ' €', width: 120 },
  ];

  @ViewChild('articleBlockComponent') articleBlockComponent: InfoBlockComponent;
  @ViewChild('deviceBlockComponent') deviceBlockComponent: InfoBlockComponent;
  @ViewChild('serviceBlockComponent') serviceBlockComponent: InfoBlockComponent;

  public articleBlock: InfoBlock = {};
  public deviceBlock: InfoBlock = {};
  public serviceBlock: InfoBlock = {};

  public get blockComponents(): InfoBlockComponent[] {
    return [this.articleBlockComponent, this.deviceBlockComponent, this.serviceBlockComponent];
  }

  ngOnInit() {
    let self = this;
    let loadingPromises: any[] = [];
    loadingPromises.push(
      Nomenclature.load(null, ['name']).then(
        function (result: Nomenclature[]) {
          self.nomenclaturesData.items = result.filter((value: Nomenclature, index, array) => {
            return value.name ? value.name.match(/^[0-9A-Z]{3}/) : false;
          });
        },
        function (err) {
          console.log(err);
        }
      ),
      RollingDirection.load(null, ['name']).then(
        function (result: RollingDirection[]) {
          self.rollingsData.items = result;
        },
        function (err) {
          console.log(err);
        }
      )
    );
    Promise.all(loadingPromises).then((result) => {
      this.articleBlock = ArticleInfoBlocks.generateArticleBlock(this.nomenclaturesData, this.rollingsData);
      this.deviceBlock = ArticleInfoBlocks.generateDeviceBlock(this.nomenclaturesData);
      this.serviceBlock = ArticleInfoBlocks.generateServiceBlock(this.nomenclaturesData);
      this.updateView(this.articleCopy);
    });
  }

  initView(article: ArticleBase) {
    console.log('article init view with:', article);
    if (article == null) {
      article = new ArticleBase();
      article.type = 0;
    }
    this.article = article;
    this.articleCopy = ObjectUtil.cloneObjectOrLodash(this.article);
    this.editMode = false;
    this.updateView(this.articleCopy);
  }
  updateView(article: ArticleBase) {
    Toolbar.getToolbarItem(this.toolbar, 'updateArticleButton').visible =
      this.editMode == false && this.articleCopy.id != 0;
    Toolbar.getToolbarItem(this.toolbar, 'copyArticleButton').visible =
      this.editMode == false && this.articleCopy.id != 0;

    if (!(this.articleCopy instanceof ArticleBase) || this.articleCopy.id == 0) this.old_prices = [];
    else
      ServerApi.callModule('sales', 'articlePrices', { id_article: this.articleCopy.fullId }).then(
        (result) => {
          this.old_prices = Array.isArray(result.details) ? result.details : [];
          console.log('old_prices:', this.old_prices);
        },
        (err) => {
          this.old_prices = [];
        }
      );
  }

  updateArticle() {
    this.editMode = true;
    this.updateView(this.articleCopy);
  }

  copyArticle() {
    this.article = this.article.clone(true);
    this.article.id = 0;
    this.initView(this.article);
  }

  priceDblClick(event, price) {
    Sale.load([price.id_sale], null, null, false).then(
      (result) => {
        ViewsComponent.openView(SaleViewComponent, result[0]);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  paste(text: string) {
    alert(text);
  }

  validateForm() {
    console.log('validating form for article:', this.articleCopy);
    console.log('block components:', this.blockComponents);
    let form: Form = {
      fields: [
        {
          name: "Désignation de l'article",
          type: 'string',
          data: this.articleCopy,
          field: 'designation',
          pattern: /.+/g,
          element: this.blockComponents[this.articleCopy.type].getElement('designation'),
        },
        {
          name: "Nomenclature de l'article",
          type: 'function',
          data: this.articleCopy,
          field: 'nomenclature',
          pattern: null,
          function: (art: ArticleBase) => {
            console.log('checking nomenclature of:', art);
            if (!art.nomenclature) return false;
            else return art.nomenclature.locked != 1;
          },
          element: this.blockComponents[this.articleCopy.type].getElement('nomenclature'),
        },
      ],
    };
    let result = FormValidator.validateForm(form);
    FormValidator.showFormInvalidFields(form, result);
    if (result !== true) {
      FormValidator.showNotification(result);
      return false;
    } else return true;
  }

  save() {
    return new Promise<any>((resolve, reject) => {
      let db_old_id: number = null;
      let old_id: number = null;
      if (this.validateForm() !== true) reject('invalid form');
      else {
        let promises: Promise<any>[] = [];
        if (this.article.id != 0) {
          db_old_id = this.article.db_id;
          old_id = this.article.id;
          promises.push(this.article.moveToTrash());
        }
        Promise.all(promises).then(
          (result) => {
            console.log('promises done, saving article:', this.articleCopy);
            this.articleCopy.db_old_id = db_old_id;
            this.articleCopy.old_id = old_id;
            this.articleCopy.clone(false, this.article);
            console.log('saving article:', this.article);
            this.article.save2().then(
              (result) => {
                this.initView(this.article);
                resolve(result);
              },
              (err) => {
                reject(err);
              }
            );
          },
          (err) => {
            console.error(err);
          }
        );
      }
    });
  }
}
