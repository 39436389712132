import { ObjectDefinition } from "../../../classes/objects/ObjectDefinition.class";
import { ObjectModel2 } from "../../../classes/objects/ObjectModel2.class";
import { User } from "src/app/classes/credentials/User.class";
import { Signature } from "./Signature";
import { ObjectModel3 } from "src/app/classes/objects/ObjectModel3.class";

export class Merchant extends ObjectModel3 {
  public name: string = null;
  public number: string = null;
  public margin: number = 50;
  public base: number = null;
  public is_employee: number = null;
  public start_margin: number = null;
  public color: string = null;
  public email: string = null;
  public phone: string = null;
  public outlook_signature: string = null;
  public user: User = null;
  public archived: number = 0;

  constructor() {
    super(Merchant);
  }

  get numberAndName() {
    return (this.number ? this.number : '') + (this.number && this.name ? ' ' : '') + (this.name ? this.name : '');
  }

  /* ObjectModel FUNCTIONS */

  // public static instances: { [id: number]: Merchant; } = {};

  public static definition: ObjectDefinition = {
    trashDelete: true,
    database: {
      table: 'merchants',
      id: 'id',
      db_id: 'db_id'
    },
    values: {
      'name': { type: 'string' },
      'number': { type: 'string' },
      'margin': { type: 'number' },
      'base': { type: 'number' },
      'is_employee': { type: 'number' },
      'start_margin': { type: 'number' },
      'color': { type: 'string' },
      'email': { type: 'string' },
      'phone': { type: 'string' },
      'outlook_signature': { type: 'string' },
      'archived': { type: 'number' },
    },
    children: {
      'user': { type: 'User', clone: false, save: false, delete: false }
    },
    links: {}
  };
}
