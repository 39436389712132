import { Component, OnInit, ViewChild } from '@angular/core';

import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { ArrayUtil } from 'src/app/modules/utils/classes/ArrayUtil.class';
import { DataGridComponent } from 'src/app/components/data-grid/data-grid.component';
import { DataGridRowAction } from '../../../../components/data-grid/classes/DataGridRowAction.class';
import { DateTimeUtil } from 'src/app/modules/utils/classes/DateTimeUtil.class';
import { Incident } from '../../classes/Incident.class';
import { IncidentViewComponent } from '../incident-view/incident-view.component';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from 'src/app/components/views/views.component';

@Component({
  selector: 'app-incidents-list-view',
  templateUrl: './incidents-list-view.component.html',
  styleUrls: ['./incidents-list-view.component.css'],
})
export class IncidentsListViewComponent extends AccessViewComponent {
  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Liste des fichiers incidents',
    data: this,
    elements: [
      {
        type: 'button',
        text: 'Créer une<br/>fiche incident',
        icon: 'exclamation',
        click: function (view: IncidentsListViewComponent) {
          view.createIncident();
        },
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Supprimer',
        icon: 'times',
        click: function (view: IncidentsListViewComponent) {
          view.deleteIncidents();
        },
      },
      { type: 'spacing' },
    ],
  };

  public incidents: Incident[] = [];
  public incidentsGridColumns = [
    { title: 'Date', field: 'date', type: 'date', width: 80 },
    { title: 'Numéro de commande', field: 'order.number', width: 200 },
    { title: 'Numéro de dossier', field: 'sale.number', width: 200 },
    { title: 'Client', field: 'customer.nameWithIdentifier', width: 200 },
    { title: 'Fournisseur', field: 'supplier.name', width: 200 },
    { title: 'Désignation', field: 'article.designation', width: 500 },
    { title: 'Quantité', field: 'quantityAndUnit', width: 150 },
    { title: 'Montant estimé', field: 'amount', type: 'number', unit: ' €', decimalsCount: 2, width: 130 },
    { title: 'Clôturé', field: 'closed', type: 'checkbox', width: 100 },
  ];

  @ViewChild('grid') grid: DataGridComponent;
  public gridRowActions: DataGridRowAction[] = [
    {
      name: 'Afficher',
      icon: 'search',
      click: (event: any, item: any) => {
        this.incidentDblClick(event, item);
      },
    },
  ];

  ngOnInit() {
    let self = this;
    Incident.load(null, ['~date']).then(
      function (result) {
        self.incidents = result;
      },
      function (err) {
        console.error(err);
      }
    );
  }

  createIncident() {
    let incident: Incident = new Incident();
    incident.date = DateTimeUtil.toDateString(new Date());
    incident.last_update = DateTimeUtil.toDateString(new Date());
    this.incidents.push(incident);
    ViewsComponent.openView(IncidentViewComponent, incident);
  }

  deleteIncidents() {
    let incidents: Incident[] = this.grid.selectedItems as Incident[];
    if (Array.isArray(incidents) && incidents.length > 0) {
      let promises: any[] = [];
      for (let i = 0; i < incidents.length; ++i) promises.push(incidents[i].delete());
      Promise.all(promises).then(
        (result) => {
          NotificationsComponent.push({
            type: 'success',
            title: 'Fiches incidents supprimées',
            summary: 'Les fiches incidents ont été supprimés avec succès',
          });
          ArrayUtil.removeElements(this.incidents, incidents);
        },
        (err) => {
          NotificationsComponent.push({
            type: 'error',
            title: 'Erreur lors de la suppression',
            summary: "Une erreur s'est produite lors de la suppression",
            content: err,
          });
          console.error(err);
        }
      );
    }
  }

  incidentDblClick(event, incident) {
    ViewsComponent.openView(IncidentViewComponent, incident);
  }
}
