import { ObjectDefinition } from "../../../classes/objects/ObjectDefinition.class";
import { ObjectModel3 } from "src/app/classes/objects/ObjectModel3.class";
import { Order } from "../../orders/classes/Order.class";
import { Invoice } from "../../invoicing/classes/Invoice.class";
import * as moment from "moment";
import { DateTimeUtil } from "../../utils/classes/DateTimeUtil.class";
import { StockMove } from "../../stocks/classes/StockMove.class";


export class DeviceInstance extends ObjectModel3
{
    public brand: string = null;
    public model: string = null;
    public serial: string = null;
    public manufacture_date: string = null;

    public invoice: Invoice = null;

    public product_reference: string = null;
    public months: number = null;

    public remarks: string = null;


    public get brandAndModel()
    {
        let arr: string[] = [];
        if (this.brand) arr.push(this.brand);
        if (this.model) arr.push(this.model);
        return arr.join(' ');
    }
    public get serialBrandAndModel()
    {
        let arr: string[] = [];
        arr.push(this.serial || '(S/N NON SPÉCIFIÉ)');
        if (this.brandAndModel && this.brandAndModel.length > 0) arr.push(this.brandAndModel);
        return arr.join(' - ');
    }

    public get order(): Order { return this.invoice ? this.invoice.order : null; }
    public get move(): StockMove { return this.invoice ? this.invoice.move : null; }

    public get customer_reference(): string { return this.move ? this.move.customer_reference : (this.order ? this.order.customer_reference : null); }
    public get order_number(): string { return this.move ? this.move.fullNumber : (this.order ? this.order.number : null); }
    public get delivery_date(): string { return this.move ? this.move.cfollowup_real_delivery_date : (this.order ? this.order.followup_delivery_date : null); }

    public get end_warranty_date(): string {
        if (!this.invoice || !this.months) return null;
        var date = moment(this.invoice.date).add(this.months, 'months').subtract(1, 'days').toDate();
        return DateTimeUtil.toDateString(date);
    }
    public get is_under_warranty(): number {
        if (!this.end_warranty_date) return 0;
        return moment().isBefore(this.end_warranty_date) ? 1 : 0;
    }

    constructor()
    {
        super(DeviceInstance);
    }

    /* ObjectModel FUNCTIONS */

    // public static instances: { [id: number]: Address; } = {};

    public static definition: ObjectDefinition = {
        trashDelete: true,
        database: {
            table: 'devices',
            id: 'id',
			db_id: 'db_id'
        },
        values: {
            'brand': { type: 'string' },
            'model': { type: 'string' },
            'serial': { type: 'string' },
            'manufacture_date': { type: 'string' },
            'product_reference': { type: 'string' },
            'months': { type: 'number' },
            'remarks': { type: 'string' },
        },
        children: {
            'invoice': { type: 'Invoice', clone: false, save: false, delete: false }
        },
        links: {}
    };

}
