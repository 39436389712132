import { NotificationsComponent } from "../../components/notifications/notifications.component";
import { ObjectDefinition } from "./ObjectDefinition.class";
import { ObjectModel3 } from "./ObjectModel3.class";

export class ClassManager
{
    public static instances: any = {};

    public static registerClass(name: string, object: any)
    {
        ClassManager.instances[name] = object;
        return true;
    }

    public static registerClasses(classes: any)
    {
        for (const key in classes) {
            if (classes.hasOwnProperty(key)) {
                const item = classes[key];
                ClassManager.registerClass(key, item);
            }
        }
        return true;
    }

    public static getClassName(obj: ObjectModel3)
    {
        return Object.getPrototypeOf(obj).constructor.name;
    }

    public static getClass(name: string)
    {
        if (!ClassManager.instances[name])
        {
            NotificationsComponent.push({
                type: 'error',
                title: 'Class introuvable',
                summary: 'Impossible de trouver la classe <b>' + name + '</b>'
            });
            console.error('ClassManager: Cannot find class', name);
        }
        return ClassManager.instances[name];
    }

    public static getInstances(currentClass: any)
    {
        if (typeof(currentClass) === 'string') currentClass = ClassManager.getClass(currentClass);
        if (!currentClass.instances) currentClass.instances = {};
        return currentClass.instances;
    }

    public static getDefinition(classParam: string | ObjectModel3): ObjectDefinition
    {
        try
        {
            let className: string = (typeof(classParam) === 'string') ? classParam : ClassManager.getClassName(classParam);
            let currentClass: any = ClassManager.getClass(className);
            let definition: ObjectDefinition = currentClass.definition || null;
            if (!definition) throw ("No definition found for class " + className);
            return definition;
        }
        catch(ex)
        {
            NotificationsComponent.push({
                type: 'error',
                title: 'Définition introuvable',
                summary: 'Impossible de trouver la définition pour la classe : ' + ex.toString()
            });
            console.error("Can't get object definition for", classParam, ":", ex);
            return null;
        }
    }

    public static cast<T>(obj: any, cl: { new(...args): T }): T {
        obj.__proto__ = cl.prototype;
        return obj;
    }
}
