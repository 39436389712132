import { Component, OnInit, ViewChild } from '@angular/core';

import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { ApiService } from '../../../../services/api/api.service';
import { ArrayUtil } from '../../../utils/classes/ArrayUtil.class';
import { DataGridComponent } from '../../../../components/data-grid/data-grid.component';
import { DateTimeUtil } from '../../../utils/classes/DateTimeUtil.class';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { NotificationsComponent } from '../../../../components/notifications/notifications.component';
import { Order } from '../../classes/Order.class';
import { OrderViewComponent } from '../order-view/order-view.component';
import { SaveSequence } from 'src/app/classes/objects/SaveSequence.class';
import { StockMove } from 'src/app/modules/stocks/classes/StockMove.class';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from '../../../../components/views/views.component';

// import { OrderCreateViewComponent } from '../order-create-view/order-create-view.component';

@Component({
  selector: 'app-followup-list-view',
  templateUrl: './orders-followup-view.component.html',
  styleUrls: ['./orders-followup-view.component.css'],
})
export class OrdersFollowupViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_commandes_suivicommandes';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Suivi de commande',
    data: this,
    elements: [
      { type: 'separator' },
      {
        type: 'button',
        text: 'Enregistrer',
        icon: 'save',
        click: function (view: OrdersFollowupViewComponent) {
          view.saveOrders();
        },
        access: this.writeAccess,
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Archiver',
        icon: 'archive',
        click: function (view: OrdersFollowupViewComponent) {
          view.archiveItems();
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Désarchiver',
        icon: 'archive',
        click: function (view: OrdersFollowupViewComponent) {
          view.archiveItems(false);
        },
        access: this.writeAccess,
      },
      { type: 'separator-large' },
      {
        type: 'toggle-button',
        text: 'Afficher les<br/>éléments archivés',
        icon: 'filter',
        value: false,
        click: function (view: OrdersFollowupViewComponent) {
          view.filterOrders = !this.value;
          view.reloadOrders();
          // if (view.filterOrders) view.grid.clearSelection();
        },
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Activer/désactiver<br/>pour rappel',
        icon: 'alarm-clock',
        click: function (view: OrdersFollowupViewComponent) {
          view.toggleReminder();
        },
        access: this.writeAccess,
      },
      { type: 'spacing' },
    ],
  };

  private _ordersType: number = 0;
  public get ordersType() {
    return this._ordersType;
  }
  public set ordersType(val: number) {
    this._ordersType = val;
    setTimeout(() => {
      this.grid.resizeTableBody();
    }, 0);
  }
  public filterOrders: boolean = true;
  public headersBackColor: string[] = ['rgb(255, 223, 42)', 'rgb(26, 141, 248)', 'rgb(196, 196, 196)'];

  public orders: (Order | StockMove)[][] = [[], []];
  public ordersGridColumns = [
    {
      title: 'Numéro de\ncommande',
      field: 'number',
      textColor: (item: Order) => {
        return;
      },
      readonly: true,
      width: 200,
    },
    { title: 'Client', field: 'customer.nameWithIdentifier', readonly: true, width: 180 },
    { title: 'Date', type: 'date', field: 'date', readonly: true, width: 90 },
    { title: 'Quantité', field: 'quantityAndUnit', readonly: true, width: 150 },
    { title: 'Désignation', field: 'article.designation', readonly: true, width: 420 },
    { title: 'Fournisseur', field: 'supplier.name', readonly: true, width: 150 },
    {
      title: 'Total de la commande',
      field: 'converted_totalAmount',
      type: 'number',
      decimalsCount: 2,
      textAlign: 'left',
      currencyField: 'sell_currency',
      readonly: true,
      width: 130,
    },
    { title: 'N° de commande\ndu client', field: 'customer_reference', readonly: true, width: 170 },
    { title: 'Délai de livraison prévu', field: 'delivery_time', readonly: true, width: 250 },
    { title: 'Etat de la commande / Remarque', field: 'followup_remarks', width: 350 },
    // { title: 'Date de livraison', type: 'date', field: 'followup_delivery_date', width: 130 },
    { title: 'Création', field: 'followup_creation', type: 'checkbox', width: 110 },
    { title: 'En attente\naccord client', field: 'followup_waiting_customer', type: 'checkbox', width: 110 },
    { title: 'Acceptation et\nenvoi au fourn.', field: 'followup_accepted', type: 'checkbox', width: 110 },
    { title: 'N° de commande\nfournisseur', field: 'followup_supplier_reference', width: 150 },
  ];
  public movesGridColumns = [
    { title: "Numéro d'appel", field: 'fullNumber', readonly: true, width: 200 },
    { title: "N° d'appel\ndu client", field: 'customer_reference', readonly: true, width: 200 },
    { title: 'Client', field: 'customer.nameWithIdentifier', readonly: true, width: 180 },
    { title: 'Quantité', field: 'quantityAndUnit', readonly: true, width: 160 },
    { title: 'Désignation', field: 'article.designation', readonly: true, width: 420 },
    {
      title: 'Total de la commande HTVA',
      field: 'converted_totalAmount',
      type: 'number',
      decimalsCount: 2,
      textAlign: 'left',
      currencyField: 'sell_currency',
      readonly: true,
      width: 150,
    },
    { title: "Date d'appel\nsur stock", field: 'date', readonly: true, width: 90 },
    { title: 'Date de livraison prévue', field: 'delivery_date', readonly: true, width: 90 },
    { title: 'Jours de\nretard', field: 'delayDays', readonly: true, width: 80 },
    { title: 'Fournisseur', field: 'supplier.name', readonly: true, width: 170 },
    { title: 'Etat de la commande / Remarque', field: 'followup_remarks', width: 300 },
  ];
  public reminderGridColumns = [
    { title: 'Numéro', field: 'fullNumber', readonly: true, width: 200 },
    { title: 'Client', field: 'customer.nameWithIdentifier', readonly: true, width: 180 },
    { title: 'Date', type: 'date', field: 'date', readonly: true, width: 90 },
    { title: 'Quantité', field: 'quantityAndUnit', readonly: true, width: 160 },
    { title: 'Désignation', field: 'article.designation', readonly: true, width: 420 },
    { title: 'Fournisseur', field: 'supplier.name', readonly: true, width: 170 },
    {
      title: 'Total de la commande HTVA',
      field: 'converted_totalAmount',
      type: 'number',
      decimalsCount: 2,
      textAlign: 'left',
      currencyField: 'sell_currency',
      readonly: true,
      width: 150,
    },
    { title: 'Etat de la commande / Remarque', field: 'followup_remarks', width: 300 },
  ];

  @ViewChild('grid0') grid0: DataGridComponent;
  @ViewChild('grid1') grid1: DataGridComponent;
  @ViewChild('grid2') grid2: DataGridComponent;
  @ViewChild('grid3') grid3: DataGridComponent;

  ngOnInit() {
    this.reloadOrders();
  }

  public get grid() {
    if (this.ordersType == 3) return this.grid3;
    else if (this.ordersType == 2) return this.grid2;
    else if (this.ordersType == 1) return this.grid1;
    else return this.grid0;
  }

  onAccountingChanged(accounting: Accounting) {
    this.reloadOrders();
  }

  reloadOrders() {
    const conditions = ['followup_reminder = 0'];
    if (this.filterOrders) conditions.push('followup_archived = 0');
    let conditions_string = conditions.length > 0 ? ' AND (' + conditions.join(' AND ') + ')' : '';

    Order.load(null, ['~number'], null, false, '(invoicings <= 1 AND deliveries <= 1)' + conditions_string, true).then(
      (result) => {
        this.orders[0] = result.sort((a: Order, b: Order) => {
          if ((b.date >= '2019-01-01' && a.date >= '2019-01-01') || (b.date < '2019-01-01' && a.date < '2019-01-01')) {
            return b.number.localeCompare(a.number);
          } else if (b.date >= '2019-01-01') {
            return 1;
          } else if (a.date >= '2019-01-01') {
            return -1;
          }
        });
      },
      (err) => {
        console.error(err);
      }
    );
    Order.load(null, ['~number'], null, false, '(invoicings > 1 OR deliveries > 1)' + conditions_string, true).then(
      (result) => {
        this.orders[1] = result.sort((a: Order, b: Order) => {
          if ((b.date >= '2019-01-01' && a.date >= '2019-01-01') || (b.date < '2019-01-01' && a.date < '2019-01-01')) {
            return b.number.localeCompare(a.number);
          } else if (b.date >= '2019-01-01') {
            return 1;
          } else if (a.date >= '2019-01-01') {
            return -1;
          }
        });
      },
      (err) => {
        console.error(err);
      }
    );
    StockMove.load(null, ['~number'], null, false, '1' + conditions_string, true).then(
      (result) => {
        this.orders[2] = result.sort((a: StockMove, b: StockMove) => {
          if ((b.date >= '2019-01-01' && a.date >= '2019-01-01') || (b.date < '2019-01-01' && a.date < '2019-01-01')) {
            return b.fullNumber.localeCompare(a.fullNumber);
          } else if (b.date >= '2019-01-01') {
            return 1;
          } else if (a.date >= '2019-01-01') {
            return -1;
          }
        });
      },
      (err) => {
        console.error(err);
      }
    );

    let _orders = [];
    const promises: Promise<any>[] = [];
    conditions[0] = 'followup_reminder = 1';
    conditions_string = conditions.length > 0 ? conditions.join(' AND ') : '';

    promises.push(
      Order.load(null, ['~number'], null, false, conditions_string, true).then(
        (result) => {
          console.log('orders result:', result);
          _orders.push(
            ...result.sort((a: Order, b: Order) => {
              if (
                (b.date >= '2019-01-01' && a.date >= '2019-01-01') ||
                (b.date < '2019-01-01' && a.date < '2019-01-01')
              ) {
                return b.number.localeCompare(a.number);
              } else if (b.date >= '2019-01-01') {
                return 1;
              } else if (a.date >= '2019-01-01') {
                return -1;
              }
            })
          );
        },
        (err) => {
          console.error(err);
        }
      )
    );
    promises.push(
      StockMove.load(null, ['~number'], null, false, conditions_string, true).then(
        (result) => {
          console.log('moves result:', result);
          _orders.push(
            ...result.sort((a: StockMove, b: StockMove) => {
              if (
                (b.date >= '2019-01-01' && a.date >= '2019-01-01') ||
                (b.date < '2019-01-01' && a.date < '2019-01-01')
              ) {
                return b.fullNumber.localeCompare(a.fullNumber);
              } else if (b.date >= '2019-01-01') {
                return 1;
              } else if (a.date >= '2019-01-01') {
                return -1;
              }
            })
          );
        },
        (err) => {
          console.error(err);
        }
      )
    );

    Promise.all(promises).then((result) => (this.orders[3] = _orders));
  }

  // public get filteredOrders(): (Order|StockMove)[][]
  // {
  //     if (!this.filterOrders) return this.orders;
  //     else return [
  //         this.orders[0] ? this.orders[0].filter((value: Order, index: number, array: Order[]) => !value.followup_archived || value.followup_archived != 1) : [],
  //         this.orders[1] ? this.orders[1].filter((value: Order, index: number, array: Order[]) => !value.followup_archived || value.followup_archived != 1) : [],
  //         this.orders[2] ? this.orders[2].filter((value: StockMove, index: number, array: StockMove[]) => !value.followup_archived || value.followup_archived != 1) : [],
  //     ];
  // }

  saveOrders() {
    let orders_to_save: (Order | StockMove)[] = [];
    for (let j = 0; j <= 2; ++j) {
      for (let i = 0; i < this.orders[j].length; ++i) {
        if (this.orders[j][i].changed === true) orders_to_save.push(this.orders[j][i]);
      }
    }
    if (orders_to_save.length > 0) {
      let ss: SaveSequence = new SaveSequence(orders_to_save);
      ss.save().then(
        (result) => {
          NotificationsComponent.push({
            type: 'success',
            title: 'Enregistrement effectué',
            summary: '<b>' + orders_to_save.length + ' commandes</b> ont été enregistrées.',
          });
        },
        (err) => {
          console.error(err);
          NotificationsComponent.push({
            type: 'error',
            title: "Echec de l'enregistrement",
            summary: "Les commandes n'ont pas pu être enregistrées.",
          });
        }
      );
    }
  }

  archiveItems(archive = true) {
    if (this.ordersType == 2) return this.archiveMoves(archive);
    else return this.archiveOrders(archive);
  }

  toggleReminder() {
    const items: (Order | StockMove)[] = this.grid.selectedItems;
    let orders_ids: string[] = [];
    let moves_ids: string[] = [];
    if (items && items.length > 0) {
      const ids: string[] = [];
      orders_ids = items.filter((i) => i instanceof Order).map((o) => o.fullId);
      moves_ids = items.filter((i) => i instanceof StockMove).map((o) => o.fullId);
      let promises: Promise<any>[] = [];
      if (orders_ids && orders_ids.length)
        promises.push(
          ApiService.callModule('orders', 'followup_reminder', { ids: orders_ids, value: this.ordersType == 3 ? 0 : 1 })
        );
      if (moves_ids && moves_ids.length)
        promises.push(
          ApiService.callModule('moves', 'followup_reminder', { ids: moves_ids, value: this.ordersType == 3 ? 0 : 1 })
        );
      Promise.all(promises).then(
        (result) => {
          NotificationsComponent.push({
            type: 'success',
            title: 'Rappel modifié',
            summary: 'Le rappel a été modifié avec succès sur les commandes',
          });
          this.grid.clearSelection();
          this.reloadOrders();
          // ArrayUtil.removeElements(this.orders, orders);
        },
        (err) => {
          NotificationsComponent.push({
            type: 'error',
            title: 'Erreur lors du rappel',
            summary: "Une erreur s'est produite lors changement du rappel sur les commandes",
            content: err,
          });
          console.error(err);
        }
      );
    }
  }

  archiveOrders(archive = true) {
    const orders: Order[] = this.grid.selectedItems as Order[];
    if (Array.isArray(orders) && orders.length > 0) {
      const ids: string[] = [];
      for (let i = 0; i < orders.length; ++i) {
        // orders[i].followup_archived = (archive === false ? 0 : 1);
        // promises.push(orders[i].save2());
        ids.push(orders[i].fullId);
      }
      ApiService.callModule('orders', 'followup_archive', { ids: ids, archive: archive ? 1 : 0 }).then(
        (result) => {
          NotificationsComponent.push({
            type: 'success',
            title: 'Commandes archivées',
            summary: 'Les commandes ont été ' + (archive === false ? 'dés' : '') + 'archivées avec succès',
          });
          this.grid.clearSelection();
          this.reloadOrders();
          // ArrayUtil.removeElements(this.orders, orders);
        },
        (err) => {
          NotificationsComponent.push({
            type: 'error',
            title: "Erreur lors de l'archivage",
            summary:
              "Une erreur s'est produite lors " + (archive === false ? 'du dés' : "de l'") + 'archivage des commandes',
            content: err,
          });
          console.error(err);
        }
      );
    }
  }

  archiveMoves(archive = true) {
    const moves: StockMove[] = this.grid.selectedItems as StockMove[];
    if (Array.isArray(moves) && moves.length > 0) {
      const ids: string[] = [];
      for (let i = 0; i < moves.length; ++i) {
        // orders[i].followup_archived = (archive === false ? 0 : 1);
        // promises.push(orders[i].save2());
        ids.push(moves[i].fullId);
      }
      ApiService.callModule('moves', 'followup_archive', { ids: ids, archive: archive ? 1 : 0 }).then(
        (result) => {
          NotificationsComponent.push({
            type: 'success',
            title: 'Appels archivés',
            summary: 'Les appels ont été ' + (archive === false ? 'dés' : '') + 'archivés avec succès',
          });
          this.grid.clearSelection();
          this.reloadOrders();
          // ArrayUtil.removeElements(this.orders, orders);
        },
        (err) => {
          NotificationsComponent.push({
            type: 'error',
            title: "Erreur lors de l'archivage",
            summary:
              "Une erreur s'est produite lors " + (archive === false ? 'du dés' : "de l'") + 'archivage des appels',
            content: err,
          });
          console.error(err);
        }
      );
    }
  }

  orderDblClick(event, order) {
    ViewsComponent.openView(OrderViewComponent, order);
  }

  gridRowStyle(item: Order | StockMove) {
    return item && item.followup_archived == 1
      ? {
          color: 'gray',
          'font-style': 'italic',
        }
      : null;
  }
}
