import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { ModuleBase } from '../module-base.class';
import { PrintPreviewViewComponent } from './views/print-preview-view/print-preview-view.component';
import { ComponentsModule } from '../../components/components.module';
import { FormsModule } from '@angular/forms';
import { PrintableElementComponent } from './components/printable-element/printable-element.component';
import { PrintablePageComponent } from './components/printable-page/printable-page.component';
import { ButtonsModule } from 'ngx-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    ButtonsModule.forRoot()
  ],
  declarations: [
      PrintPreviewViewComponent,
      PrintableElementComponent,
      PrintablePageComponent
  ],
  exports: [
      PrintPreviewViewComponent,
      PrintableElementComponent,
      PrintablePageComponent
  ],
  entryComponents: [
      PrintPreviewViewComponent
  ]
})
export class PrintingModule extends ModuleBase {
    
    public static moduleName = 'printing';
    
}

