import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { Dialog } from './classes/Dialog.class';
import { DialogComponent } from './dialog/dialog.component';

@Component({
  selector: 'app-dialogs',
  templateUrl: './dialogs.component.html',
  styleUrls: ['./dialogs.component.css']
})
export class DialogsComponent implements OnInit {

    private static instance: DialogsComponent = null;

    constructor(private modalService: BsModalService) {
        DialogsComponent.instance = this;
    }

    ngOnInit() {
    }

    public static display(dialog: Dialog): Promise<any>
    {
        let modal: BsModalRef = DialogsComponent.instance.modalService.show(DialogComponent,
            {
                ignoreBackdropClick: true,
                initialState: {dialog: dialog}
            });
        let dialogComp: DialogComponent = modal.content as DialogComponent;
        return dialogComp.promise;
    }

    public static displayComponent(componentClass: any, initialState: any = null, dialogClass: string = null)
    {
        let modal: BsModalRef = DialogsComponent.instance.modalService.show(componentClass,
            {
                ignoreBackdropClick: true,
                initialState: initialState,
                class: dialogClass
            });
        let dialogComp: DialogComponent = modal.content as DialogComponent;
        console.log('comp=', dialogComp);
        if (dialogComp instanceof DialogComponent) {
            // console.log('state=', initialState);
            // Object.assign(dialogComp, initialState ||{});
            // console.log('AFTER:', dialogComp);
            return dialogComp.promise;
        }
        else return null;
    }

}
